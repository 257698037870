
import {
  React,
  Paper,
  useMemo,
  Tabs,
  Tab,
  useState,
  Box,
  Typography,
  Button,
  Select,
  MenuItem,
  clsx,
  useEffect,
  ListItemIcon,
  FiberManualRecord,
  Rectangle,
  Backdrop,
  CircularProgress,
  useNavigate,
  useLocation,
} from "../../../../app.module";
import { ResponsiveBar } from "@nivo/bar";
import { ResponsivePie } from "@nivo/pie";
import { useStyle } from "./BarChartStyle";

import {
  getVisualizationData,
  getVisualizationType,
  getPossibalVisualization,
  getPieChartVisualizationData,
  getVisualizationState,
  gePieChartVisualizationData,
} from "./ChartsService";
import {
  getUserDetailsFromCookies,
  getQueryIdFromCookies,
} from "../../../services";
import { Colors, Images } from "../../../constants";

const PieChart = React.memo((props: any) => {
  const [pieChartJson, setPieChartJson] = useState<any>([]);
  const [isLoading, setIsLoading] = useState(true);
  const [isScrolling, setIsScrolling] = useState(false);

  useEffect(() => {
    let { data, pageError } = props;
    console.log("PIE COMPONENT DATA", data);
    setPieChartJson(data);
  }, [pieChartJson]);

  let scrollTimeout: NodeJS.Timeout | undefined;
  
  const handleMouseMove = () => {
    setIsScrolling(true);
    if (scrollTimeout) clearTimeout(scrollTimeout);
  };
  
  const handleMouseLeave = () => {
    if (scrollTimeout) clearTimeout(scrollTimeout);
    scrollTimeout = setTimeout(() => setIsScrolling(false), 1000) as NodeJS.Timeout;
  };

  return (
    <div
      key="CHART101"
      style={{
        backgroundColor: Colors?.background.backgroundColorTheme3,
        height: "87vh",
        width: "75%",

        boxShadow: "0px 0px 4px 0px #00000040",
        borderRadius: "10px 0px 0px 10px",

        top: 0,
      }}
      onMouseMove={handleMouseMove}
        onMouseLeave={handleMouseLeave}
    >
      <div
        style={{
          display: "flex",
          justifyItems: "right",
          marginLeft: "10px",
          marginTop: "10px",
        }}
      >
        Datasource name :<label>{props?.dbsource}</label>
      </div>
      <div
        key="CHART102"
        style={{
          height: "85vh",

          display: "flex",
        }}
      >
        {props?.pageError !== "" ? (
          <div
            key="TAB101"
            style={{
              display: "flex",
              height: "87vh",
              width: "100%",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <img
              style={{
                height: "auto",
                width: "300px",
              }}
              src={Images.no_data_img}
            />
          </div>
        ) : (
          <ResponsivePie
            data={pieChartJson?.data ? pieChartJson?.data : []}
            margin={{ top: 40, right: 80, bottom: 80, left: 80 }}
            innerRadius={0.5} 
            padAngle={0.7} 
            cornerRadius={3}
            activeOuterRadiusOffset={8}
            borderWidth={1}
            borderColor={{
              from: "color",
              modifiers: [["darker", 0.2]],
            }}
            arcLinkLabelsSkipAngle={10}
            arcLinkLabelsTextColor="#333333"
            arcLinkLabelsThickness={2}
            arcLinkLabelsColor={{ from: "color" }}
            arcLabelsSkipAngle={10}
            arcLabelsTextColor={{
              from: "color",
              modifiers: [["darker", 2]],
            }}
            defs={[
              {
                id: "dots",
                type: "patternDots",
                background: "inherit",
                color: "rgba(255, 255, 255, 0.3)",
                size: 4,
                padding: 1,
                stagger: true,
              },
              {
                id: "lines",
                type: "patternLines",
                background: "inherit",
                color: "rgba(255, 255, 255, 0.3)",
                rotation: -45,
                lineWidth: 6,
                spacing: 10,
              },
            ]}
          
          />
        )}
      </div>
    </div>
  );
});

export default PieChart;
