import {
  makeStyles,
  TextField,
  styled,
  OutlinedInput,
  useContext,
  React,
} from "../../../app.module";
import { ThemeContext } from "../../context/ThemeContext";
import { AppThemes, Fonts } from "../../constants";
export const CssOutlinedInput = styled(OutlinedInput)({
  "& label.Mui-focused": {
    color: "red",
  },
  "& .MuiInput-underline:after": {
    borderBottomColor: "green",
  },

  "& .MuiOutlinedInput-root": {
    "& fieldset": {
      borderColor: "#F9F9F9",
      //backgroundColor: "#F9F9F9",
    },
    "&:hover fieldset": {
      border: "1px solid #909922",
    },
    "&.Mui-focused fieldset": {
      border: "1px solid #909922",
    },
  },
});
export const CssConfirmOtpTextField = styled(TextField)({
  "& label.Mui-focused": {
    color: "#000",
  },
  "& .MuiInput-underline:after": {
    borderBottomColor: "red",
  },

  "& .MuiOutlinedInput-root": {
    "& fieldset": {
      borderColor: "#F9F9F9",
      borderRadius: "10px",
      //backgroundColor: "#F9F9F9",
    },
    "&:hover fieldset": {
      // border: "1px solid #000",
      border: "1px solid #1158CE",
      borderRadius: "10px",
    },
    "&.Mui-focused fieldset": {
      //border: "1px solid #000",
      border: "1px solid #1158CE",
      borderRadius: "10px",
    },
  },
});
export const ConfirmEmailStyle = makeStyles()((theme?: any) => {
  console.log("AppThemes", Fonts.Inter.regular);
  return {
    root: {
      backgroundColor: "#f9f9f9",
    },
    labelstyle: {
      fontFamily: "'Arial'",
      fontStyle: "normal",
      fontWeight: "400",
      fontSize: "18px",
      lineHeight: "21px",
      color: "#000000",
      "&.Mui-focused": {
        color: "orange",
      },
    },
    confirmEmailLabelStyle: {
      fontFamily: Fonts.Inter.regular,
      fontStyle: "normal",
      fontWeight: "400",
      fontSize: "18px",
      lineHeight: "21px",
      color: "#000000",
    },
    logoConfirmCodeStyle: {
      height: "100px",
      width: "100px",
    },
    labelstyle1: {
      color: "lightgray",
      "&.Mui-focused": {
        color: "orange",
      },
    },
    formContainer: {
      display: "flex",
      flex: 1,
      flexDirection: "column",
    },
    textField: {
      transition: "none",
      "&.Mui-focused": {
        border: "1px solid #000",
      },
    },
    margin: {
      margin: theme.spacing(1),
      color: "red",
    },
    CardContainer: {
      padding: "20px",
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
    },
    confirmInput: {
      backgroundColor: "#f9f9f9",
      fontFamily: Fonts.Inter.regular,
      fontStyle: "normal",
      fontWeight: "400",
      fontSize: "18px",
      lineHeight: "21px",
      borderRadius: "10px", // border before focusing the text field
      //height: "16px",

      //textAlign: "center",
      color: "#000000",
      //height: "20px",
      "&::placeholder": {
        color: "#ADADAD",
      },
    },
    inputOutline: {
      backgroundColor: "#f9f9f9",
      fontFamily: "Arial",
      fontStyle: "normal",
      fontWeight: "400",
      fontSize: "14px",
      lineHeight: "21px",
      borderRadius: "10px",
      height: "16px",
      //textAlign: "center",
      color: "#000000",
      //height: "20px",
      //border: "1px solid #000",
      border: "1px solid #1158CE",
      "&::placeholder": {
        color: "#ADADAD",
      },
      ".MuiOutlinedInput-notchedOutline": {
        //border: "1px solid #f9f9f9",
        border: "1px solid #1158CE",
      },
      "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
        //border: "1px solid #000",
        border: "1px solid #1158CE",
      },
      "&:hover .MuiOutlinedInput-notchedOutline": {
        //border: "1px solid #000",
        border: "1px solid #1158CE",
      },
    },
    inputOtp: {
      backgroundColor: "#f9f9f9",
      fontFamily: "Arial",
      fontStyle: "normal",
      fontWeight: "400",
      fontSize: "14px",
      lineHeight: "21px",
      borderRadius: "10px",
      height: "16px",
      //textAlign: "center",
      color: "#000000",
      //height: "20px",
      "&::placeholder": {
        color: "#ADADAD",
      },
    },
    labelStyle: {
      fontFamily: "'Arial'",
      fontStyle: "normal",
      fontWeight: "400",
      fontSize: "18px",
      lineHeight: "21px",
      color: "#000000",
      whiteSpace: "pre-line", // line will break according to width
    },
    timerStyle: {
      fontFamily: "'Arial'",
      fontStyle: "normal",
      fontWeight: "400",
      fontSize: "16px",
      lineHeight: "18px",
      color: "#4F4F4F",
      marginTop: "7px",
    },
    linkResendStyle: {
      fontFamily: "'Arial'",
      fontStyle: "normal",
      fontWeight: "700",
      fontSize: "16px",
      lineHeight: "18px",
      color: "#4F4F4F",
      textTransform: "capitalize",
      //cursor: "pointer",
    },
    emailStyle: {
      fontFamily: "'Arial'",
      fontStyle: "normal",
      fontWeight: "700",
      fontSize: "16px",
      lineHeight: "18px",
      color: "#4F4F4F",

      //cursor: "pointer",
    },
    haventRecevieLabelStyle: {
      fontFamily: "'Arial'",
      fontStyle: "normal",
      fontWeight: "400",
      fontSize: "18px",
      lineHeight: "21px",
      color: "#000000",
      whiteSpace: "pre-line",
    },
    resendContainer: {
      display: "flex",
      flex: 1,
      justifyContent: "space-between",
      marginBottom: "20px",
    },
    CmpDetailsHeading: {
      fontFamily: "'Arial'",
      fontStyle: "normal",
      fontWeight: "700",
      fontSize: AppThemes.typography.h1.fontSize,
      lineHeight: "46px",
      textAlign: "center",
      color: "#000000",
      marginBottom: "30px",
    },
    twoConfirmOtpFieldsContainer: {
      display: "flex",
      flex: 1,
      justifyContent: "space-between",
      marginBottom: "20px",
    },
    textConfirmOtpFiledLabelContainer: {
      display: "flex",
      marginRight: "20px",
      flex: 1,
      flexDirection: "column",
    },
    textFiledLabelContainerRight: {
      //display: "flex",
      //flex: 1,
      width: "500px",

      flexDirection: "column",
    },
    ConfirmEmailBtn: {
      //backgroundColor: "#8e8e8e",
      background:
        "linear-gradient(90deg, #5EB0FA 0%, #1157CD 50.54%, #9C90FA 100%)",
      color: "#fff",
      textTransform: "capitalize",
      height: "50px",
      fontFamily: "'Arial'",
      fontStyle: "normal",
      fontWeight: "700",
      fontSize: "18px",
      lineHeight: "21px",
      marginTop: "10px",
      marginBottom: "20px",
      "&:hover": {
        background:
          "linear-gradient(90deg, #5EB0FA 0%, #1157CD 50.54%, #9C90FA 100%)",

        //"#8e8e8e",
      },
    },
  };
});
