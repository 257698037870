class WingsbiTheme {
  font_family: any;
  fontfamily_name: string;
  primary_color: {
    main: string;
    zIndex: {
      drawer: number;
    };
    palette: {
      error: {
        main: string;
      };
      success: {
        main: string;
      };
      label: {
        main: string;
      };
      lightbg: {
        theme1: string;
      };
      darkTitle: { theme1: string };
    };
    border: {
      borderColor: string;
      plan: { details: string; borderColor: string; backgroundColor: string };
      light: { borderColor: string };
    };
    tilebg: {
      backgroundColor: string;
    }
    tilebackground: {
      background: string;
    }
    colotex:{
      colortext:string;
    }
    background: {
      backgroundColorTheme1: string;
      backgroundColorTheme2: string;
      backgroundColorTheme3: string;
    };
    title: { light: string };
    path: { light: string };
  };
  color_type: string;
  constructor() {
    this.font_family = {
      NunitoSans: {
        black: "NunitoSansBlack",
        bold: "NunitoSansExtraBold",
        regular: "Nunito Sans",
      },
      Inter: {
        medium: "InterMedium",
        bold: "NunitoSansExtraBold",
        regular: "Inter",
      },
    };
    this.fontfamily_name = "NunitoSans";
    this.primary_color = {
      main: "#1158CE",
      zIndex: {
        drawer: 1200,
      },
      palette: {
        error: { main: "#d32f2f" },
        success: { main: "#00CB52" },
        label: { main: "#000" },
        lightbg: { theme1: "#fff" },
        darkTitle: { theme1: "#000" },
      },
      border: {
        borderColor: "#0B55CC",
        plan: {
          details: "#0B55CC",
          borderColor: "#D1D1D1",
          backgroundColor: "#F2F5FD",
        },
        light: { borderColor: "#D1DFF6" },
      },
      background: {
        backgroundColorTheme1: "#F8FAFF",
        backgroundColorTheme2: "#D1DFF6",
        backgroundColorTheme3: "#F2F5FD",
      },
      tilebackground: {
        background:" linear-gradient(161deg, #52A2F3 0%, #165DD0 41.09%, #8085F1 80.49%)",
      },
      tilebg: {
        backgroundColor: "white"
      },
      colotex:{
        colortext: "#FFFFFF"
      },

      title: { light: "#A9A9A9" },
      path: { light: "#ADADAD" },
    };
    this.color_type = "primary";
  }

  getFontFamily = () => {
    return this.font_family;
  };

  setFontFamily = (name: any) => {
    this.fontfamily_name = name;
  };
  getColor = () => {
    if (this.color_type === "primary") return this.primary_color;
  };

  setColor = (name: any) => {
    this.color_type = name;
  };
}

export default new WingsbiTheme();
