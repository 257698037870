import { getData, postData } from "../../services";
import {
  Subscriptionplans,
  PlanAddons,
  ApplyCoupon,
  Purschase,
} from "../../constants/Endpoints";
let TOKEN: string = "";
export const getSubscriptionPlan = async () => {
  return await getData(Subscriptionplans).then((result) => {
    let planset = result?.plans?.map((p: any) => new PlanResult(p));
    let features_result = result?.features?.map(
      (p: any) => new FeaturesResult(p)
    );
    return { data: { plan: planset, features: features_result } };
  });
};
export const getPlanAddons = async () => {
  return await getData(PlanAddons).then((result) => {
    let planaddons_result = result?.map((p: any) => new AddonsResult(p));
    return planaddons_result;
  });
};
export const applyCoupon = async (plan_details: any) => {
  return await postData(ApplyCoupon, plan_details).then((response) => {
    return response?.result;
  });
};

export const purschasePlan = async (plan_details: any) => {
  return await postData(Purschase, plan_details).then((response) => {
    return response?.result;
  });
};
export function useCompanyService() {
  const fetcher = {};
  return [fetcher];
}
export class PlanResult {
  planId?: number;
  monthlyPrice?: number;
  annualPrice?: number;
  durationInDays?: number;
  planName?: string;
  description?: string;
  priority?: number;
  minUserCount?: number;
  maxUserCount?: number;
  constructor(data: any) {
    Object.assign(this, {
      planId: data.planId,
      annualPrice: data.annualPrice,
      monthlyPrice: data.monthlyPrice,
      duration: data.duration,
      planName: data.planName,
      description: data.description,
      priority: data.priority,
      maxUserCount: data.maxUserCount,
      minUserCount: data.minUserCount,
    });
  }
}
export class AddonsResult {
  addonId?: number;
  addonprice?: number;
  maxLimit?: number;
  minLimit?: number;
  addonName?: string;
  addonDescription?: string;
  planId?: number;
  //addonsIds?: number[]; // specify the type argument for the array
  isAdded?: boolean;
  constructor(data: any) {
    Object.assign(this, {
      addonId: data.addonId,
      maxLimit: data.maxLimit,
      addonprice: data.addonprice,
      minLimit: data.minLimit,
      addonName: data.addonName,
      addonDescription: data.addonDescription,
      planId: data.planId,
      isAdded: false,
    });
  }
}
export class FeaturesResult {
  featureId?: number;
  minLimit?: number;
  appliesTo?: Array<Number>;
  durationInDays?: number;
  featureDescription?: string;
  featureName?: string;
  maxLimit?: number;
  constructor(data: any) {
    Object.assign(this, {
      featureId: data.featureId,
      appliesTo: data.appliesTo,
      minLimit: data.minLimit,
      durationInDays: data.durationInDays,
      featureDescription: data.featureDescription,
      featureName: data.featureName,
      maxLimit: data.maxLimit,
    });
  }
}
