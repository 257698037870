import {
  makeStyles,
  Switch,
  TextField,
  styled,
  Button,
} from "../../../app.module";
import { Colors, Fonts } from "../../constants";
export const StyledTableActionButton = styled(Button)({
  backgroundColor: "#fff",
  color: "#fff",
  padding: "6px 12px",
  "&:hover": {
    backgroundColor: "#fff",
  },
});
export const TableHeaderTextField = styled(TextField)({
  "& label.Mui-focused": {
    color: "#000",
  },

  "& .MuiOutlinedInput-root": {
    "& fieldset": {
      borderColor: "#fff",
      borderRadius: "0px",
      borderBottom: `1px solid ${Colors?.border.plan.borderColor}`,
    },
    "&:hover fieldset": {
      border: `0px solid #fff`,
      borderRadius: "0px",
    },
    "&.Mui-focused fieldset": {
      border: `0px solid #fff`,
    },
  },
});
export const useStyle = makeStyles()((theme?: any) => {
  return {
    tableStyle: {
      borderCollapse: "collapse",
    },
    circularPlanContainer: {
      color: Colors?.main,
      zIndex: parseInt(`${Colors?.zIndex?.drawer}+${1}`),
    },
    circularPlanView: {
      color: Colors?.main,
    },
    tableHeaderLabelStyle: {
      fontFamily: Fonts.NunitoSans.regular,
      fontStyle: "normal",
      fontWeight: 700,
      fontSize: "16px",
      lineHeight: "24px",
      color: "#000",
      padding: "0px",
      margin: "0px",
    },
    tableHeaderInputContainer: {
      display: "flex",
      flex: 1,
    },
    tableHeaderInput: {
      fontFamily: Fonts.NunitoSans.regular,
      fontStyle: "normal",
      fontWeight: 700,
      fontSize: "18px",
      lineHeight: "24px",
      color: "#000",
      padding: "0px",
      margin: "0px",
      "&::placeholder": {
        color: "#ADADAD",
      },
    },
    iconButton: {
      position: "absolute",
      top: "15.4ch",
      transition: "transform 0.3s ease-in-out",
    },

    scaled: {
      transform: "scale(0.8)", 
    },
    moduleTitle: {
      color: "#000",
    },
    moduleButton: {
      textTransform: "initial",
      padding: 0,
      borderRadius: "10px",
      "&:hover": {
        backgroundColor: "transparent",
      },
    },
    tableResetBtnLabelStyle: {
      fontFamily: Fonts.NunitoSans.regular,
      fontStyle: "normal",
      fontWeight: 700,
      fontSize: "18px",
      lineHeight: "25px",
      backgroundCo: "#fff",
      color: "#000",
      textTransform: "capitalize",
      height: "57px",
      width: "185px",
      marginRight: "15px",
      marginBottom: "20px",
      "&:hover": {
        background: "#fff",
      },
      "&:focus": {
        background: "#fff",
      },
    },
    saveTableActionBtn: {
      background:
        "linear-gradient(90deg, #5EB0FA 0%, #1157CD 50.54%, #9C90FA 100%)",
      color: "#fff",
      fontFamily: Fonts.NunitoSans.regular,
      fontStyle: "normal",
      fontWeight: 700,
      fontSize: "18px",
      lineHeight: "25px",
      textTransform: "capitalize",
      height: "57px",
      width: "185px",
      marginBottom: "20px",
    },
    queryBtnTestContainer: {
      display: "flex",
      justifyContent: "center",
      marginRight: "18%",
    },
    tableActionBtncontainer: {
      display: "flex",
      marginTop: "20px",
      backgroundColor: "#fff",
      justifyContent: "flex-end",
      alignItems: "flex-end",
    },
    columnDeleteBtncontainer: {
      display: "flex",
      flexDirection: "row",
      justifyContent: "center",
      marginTop: "10px",
    },
    columnDeleteBtnLabelStyle: {
      fontFamily: Fonts.NunitoSans.regular,
      fontStyle: "normal",
      fontWeight: 700,
      fontSize: "14px",
      lineHeight: "25px",
      backgroundColor: "#fff",
      color: "#000",
      textTransform: "capitalize",
      "&:hover": {
        background: "#fff",
      },
      "&:focus": {
        background: "#fff",
      },
    },
    columnDeleteBtnYes: {
      background:
        "linear-gradient(90deg, #5EB0FA 0%, #1157CD 50.54%, #9C90FA 100%)",
      color: "#fff",
      fontFamily: Fonts.NunitoSans.regular,
      fontStyle: "normal",
      fontWeight: 700,
      fontSize: "14px",
      lineHeight: "25px",
      marginLeft: "15px",
      textTransform: "capitalize",
      "&:hover": {
        background: "#9ecffc",
      },
      "&:focus": {
        background: "#9ecffc",
      },
    },
    errorModalBtn: {
      background:
        "linear-gradient(90deg, #5EB0FA 0%, #1157CD 50.54%, #9C90FA 100%)",
      color: "#fff",
      fontFamily: Fonts.NunitoSans.regular,
      fontStyle: "normal",
      fontWeight: 700,
      fontSize: "14px",
      lineHeight: "25px",
      marginLeft: "15px",
      textTransform: "capitalize",
    },
  };
});
