import { getData, postData, getUserDetailsFromCookies,putData } from "../../services";

export const getModuleListForDasboard = async () => {
  return await getData(
    `/${getUserDetailsFromCookies().identifier}/api/project/${
      getUserDetailsFromCookies().projectId
    }/module`
  ).then((result: any) => {
    return result;
  });
};



export const getModuleListData = async (payload: { projectId: number; moduleIds: number[] }) => {
  const { identifier, projectId } = getUserDetailsFromCookies();
  return await postData(
    `/${identifier}/api/project/${projectId}/module/getmodules`, payload
  ).then((result: any) => {
    return result;
  });
};

export const getModuleChartForDasboard = async (moduleId: number) => {
  return await getData(
    `/${getUserDetailsFromCookies().identifier}/api/project/${
      getUserDetailsFromCookies().projectId
    }/module/module/${moduleId}`
  ).then((result: any) => {
    return result;
  });
};

export const buildDashboardFromModules = async (requestBody: number) => {
  return await postData(
    `/${getUserDetailsFromCookies().identifier}/api/project/${
      getUserDetailsFromCookies().projectId
    }/dashboard`,
    requestBody
  ).then((result: any) => {
    return result;
  });
};

export const showDash = async (dashboardId: any) => {
  return await getData(
    `/${getUserDetailsFromCookies().identifier}/api/project/${
      getUserDetailsFromCookies().projectId
    }/dashboard/${dashboardId}`
  ).then((result) => {
    return result;
  });
};

export const DashboardUpdate = async (dashboardId: any, requestBody: any) => {
  return await putData(
    `/${getUserDetailsFromCookies().identifier}/api/project/${ getUserDetailsFromCookies().projectId }/dashboard/${dashboardId}`,
    requestBody
  ).then((result: any) => {
    console.log("reskut",result)
    return result;
  });
};


