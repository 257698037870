import React, { useEffect, useState } from 'react';
import SpeechRecognition, { useSpeechRecognition } from 'react-speech-recognition';
import { Images } from '../../constants';
import '../chart_dashboard/DashboardListStyles.css';
type VoiceWidgetProps = {
  fetchVoiceData: (text: string) => void;
};

const VoiceWidget: React.FC<VoiceWidgetProps> = ({ fetchVoiceData }) => {
  const { transcript, resetTranscript, listening } = useSpeechRecognition();
  const [isListening, setIsListening] = useState(false);

  useEffect(() => {
    if (listening) {
      // fetchVoiceData(transcript);
      //resetTranscript();
    }
  }, [listening, transcript, fetchVoiceData, resetTranscript]);

  const startListening = () => SpeechRecognition.startListening();

  const stopListening = () => {
    SpeechRecognition.stopListening();
    fetchVoiceData(transcript);
    resetTranscript();

  };
  const handleMicClick = () => {
    if (!isListening) {
      startListening();
    } else {
      stopListening();
    }
    setIsListening(!isListening);
  };

  if (!SpeechRecognition.browserSupportsSpeechRecognition()) {
    return <div>Browser does not Support Speech Recognition</div>;
  }
  const micImage = isListening ? Images.Mic2 : Images.Mic1;
  return (
    <div>
      <button onClick={handleMicClick} className='mic-button-bot'>
        <img src={micImage} alt="Toggle Speech Input" className='mic-image-bot' />
      </button>
    </div>
  );
};

export default VoiceWidget;

