import { getData, putData, postData, deleteData, getUserDetailsFromCookies } from "../../../../services";
import {
  DatabaseType,
  ConnectionString,
  DatabaseProvider,
  CreateDatabase,
  GetDatabase,
  UpdateDatabase,
} from "../../../../constants/Endpoints";
import {useMsal } from "../../../../../app.module";
import { InteractionRequiredAuthError } from "@azure/msal-browser";
let TOKEN: string = "";

export function useGetToken() {
  const { instance, accounts } = useMsal();
  if (TOKEN === "") {
    const tokenRequest = {
      account: accounts[0],
      scopes: [
        "openid",
        "profile",
        process.env.REACT_APP_B2C_SCOPES as string,
      ],
    };
    instance
      .acquireTokenSilent(tokenRequest)
      .then((response) => {
        TOKEN = response.accessToken;
      })
      .catch(async (e) => {
        if (e instanceof InteractionRequiredAuthError) {
          await instance.acquireTokenRedirect(tokenRequest);
        }

        throw e;
      });
  }
  const fetcher = {};
  return [fetcher];
}
export const getDatabaseTypes = async () => {
  return await getData(DatabaseType).then((result) => {
    let dataset = result?.map((p: any) => new ProjectResult(p));
    return dataset;
  });
};
export const getDatabaseProvider = async () => {
  return await getData(DatabaseProvider).then((result) => {
    let dataset = result?.map((p: any) => new ProjectResult(p));
    return dataset;
  });
};


export const getConnection = async (conn_string: string) => {
  let encodedUrl = encodeURIComponent(conn_string);
  return await getData(
    `/${getUserDetailsFromCookies().identifier}${ConnectionString}${encodedUrl}`
  ).then((result) => {
    return result;
  });
};

export const dogetdatabasepro = async (id: number) => {
  console.log("dogetdatabase TOKEN ", TOKEN);
  return await getData(
    `/${getUserDetailsFromCookies().identifier}/api/project/${id}/database`
  ).then((result) => {
    return result;
  });
};
export const dogetdatabase = async () => {
  console.log("dogetdatabase TOKEN ", TOKEN);
  return await getData(
    `/${getUserDetailsFromCookies().identifier}/api/project/${getUserDetailsFromCookies().projectId
    }/database`
  ).then((result) => {
    return result;
  });
};
export const connectDatabasenew = async (conn_payload: any, projectId: number) => {
  return await postData(

    `/${getUserDetailsFromCookies().identifier}/api/project/${projectId}/schema/validate`,
    conn_payload
  ).then((result) => {
    console.log("Test Conn resp", result);
    return result;
  });
};
export const connectDatabaseexist = async (conn_payload: any, projectId: number) => {
  return await postData(
    `/${getUserDetailsFromCookies().identifier}/api/project/${getUserDetailsFromCookies().projectId
    }/schema/validate`,
    conn_payload
  ).then((result) => {
    console.log("Test Conn resp", result);
    return result;
  });
};
export const postConnection = async (conn_payload: any) => {
  console.log(
    conn_payload,
    "Requet API",
    `/${getUserDetailsFromCookies().identifier}/api/project/${getUserDetailsFromCookies().projectId
    }/schema/validate`
  );
  return await postData(
    `/${getUserDetailsFromCookies().identifier}/api/project/${getUserDetailsFromCookies().projectId
    }/schema/validate`,
    conn_payload
  ).then((result) => {
    console.log("Test Conn resp", result);
    return result;
  });
};



export const doDeleteDatabase = async (projectId: number | undefined, databaseID: number | undefined) => {
  return await deleteData(
    `/${getUserDetailsFromCookies().identifier}/api/project/${projectId}/database/${databaseID}`
  ).then((result) => {
    return result;
  });
};

export const createNewDatabase = async (
  database_details: any,
) => {
  return await postData(
    `/${getUserDetailsFromCookies().identifier}${CreateDatabase}/${getUserDetailsFromCookies().projectId
    }/database`,
    database_details
  ).then((result) => {
    console.log("DDBDBDB SERVICE", result);
    return result;
  });
};

export const updateProject = async (project_details: any) => {
  return await putData(
    `/${getUserDetailsFromCookies().identifier}/api/project/${project_details.id}`,
    project_details).then((result) => {
      return result;
    });
};
export const updateDatabase = async (database_details: any) => {
  console.log("database-details: ", database_details)
  return await putData(
    `/${getUserDetailsFromCookies().identifier}/api/project/${database_details.projectId}/database/${database_details.id}`,
    { name: database_details.name }
  ).then((result) => {
    return result;
  });
};

export const defaultProject = async (project_details: any) => {
  console.log("project details..............", project_details)
  return await postData(
    `/${getUserDetailsFromCookies().identifier}/api/project/usermanagement/users/${getUserDetailsFromCookies().id}/setdefaultproject/${project_details.projectId}`,
    project_details).then((result) => {
      return result;
    })
}
export class ProjectResult {
  id: number | undefined;
  industrySize: string | undefined;
  isActive: boolean | undefined;
  industryType: string | undefined;
  countryName: string | undefined;
  phoneCode: string | undefined;
  name: string | undefined;
  flag: string | undefined;
  cityName: string | undefined;
  stateId: string | undefined;
  constructor(data: any) {
    Object.assign(this, {
      id: data.id,
      isActive: data.isActive,
      industrySize: data.industrySize,
      industryType: data.industryType,
      countryName: data.countryName,
      phoneCode: data.phoneCode,
      name: data.name,
      flag: data.flag,
      cityName: data.cityName,
      stateId: data.stateId,
    });
  }
}



export class GetDatabasesResults {
  id: number | undefined;
  name: string | undefined;
  description: string | undefined;
  connectionString: string | undefined;
  databaseTypeId: number | undefined;
  databaseProviderId: number | undefined;
  createdBy: string | undefined;
  createdDate: Date | undefined;
  projectId: number | undefined;
  constructor(data: any) {
    this.createdDate = new Date(data.createdDate);
    Object.assign(this, {
      id: data.id,
      name: data.name,
      description: data.description,
      connectionString: data.connectionString,
      databaseTypeId: data.databaseTypeId,
      databaseProviderId: data.databaseProviderId,
      createdBy: data.createdBy,
      createdDate: data.createdDate,
      projectId: data.projectId,
    });
  }
  getFormattedCreatedDate(): string {
    const options = { day: "2-digit", month: "long", year: "2-digit" };
    return this.createdDate?.toLocaleDateString(undefined) || "";
  }
}
