import { getData, postData } from "../../services";
import {
  IndustryType,
  IndustrySize,
  Countries,
  States,
  Cities,
  CreateCompany,
} from "../../constants/Endpoints";
import { useMsal } from "../../../app.module";
let msalDetails: any = "";
export const getCompanyTypes = async () => {
  //console.log("TOKEN", msalDetails.idToken);
  return await getData(IndustryType).then((result) => {
    let dataset = result?.map((p: any) => new CompanyResult(p));
    return dataset;
  });
};
export const getIndustrySize = async () => {
  return await getData(IndustrySize).then((result) => {
    let dataset = result?.map((p: any) => new CompanyResult(p));
    return dataset;
  });
};
export const getCountries = async () => {
  return await getData(Countries).then((result) => {
    let dataset = result?.map((p: any) => new CompanyResult(p));
    return dataset;
  });
};
export const getState = async (id: number) => {
  return await getData(States + id).then((result) => {
    let dataset = result?.map((p: any) => new CompanyResult(p));
    return dataset;
  });
};
export const getCities = async (id: number) => {
  return await getData(Cities + id).then((result) => {
    let dataset = result?.map((p: any) => new CompanyResult(p));
    return dataset;
  });
};
export const setCompanyDetails = async (company_details: any) => {
  return await postData(CreateCompany, company_details).then((result) => {
    return result;
  });
};
export function useCompanyService() {
  const { instance, inProgress } = useMsal();
  msalDetails = instance.getActiveAccount();
  const fetcher = { msalDetails };
  return [fetcher];
}
export class CompanyResult {
  id?: number;
  industrySize?: string;
  isActive?: boolean;
  industryType?: string;
  countryName?: string;
  phoneCode?: string;
  name?: string;
  flag?: string;
  cityName?: string;
  stateId?: string;
  constructor(data: any) {
    Object.assign(this, {
      id: data.id,
      isActive: data.isActive,
      industrySize: data.industrySize,
      industryType: data.industryType,
      countryName: data.countryName,
      phoneCode: data.phoneCode,
      name: data.name,
      flag: data.flag,
      cityName: data.cityName,
      stateId: data.stateId,
    });
  }
}
