import {
  useNavigate,
  useEffect,
  useState,
  React,
  Box,
  useLocation,
  Typography,
} from "../../../app.module";
import { useStyle1 } from "./ModuleDashboardListStyle";

import { Responsive, WidthProvider } from "react-grid-layout";
import "react-grid-layout/css/styles.css";
import "react-resizable/css/styles.css";
import BarChartForDashboard from "../modules/dasboard_charts/BarChartForDashboard";
import PieChartForDashboard from "../modules/dasboard_charts/PieChartForDashboard";
import LineChartForDashboard from "../modules/dasboard_charts/LineChartForDashboard";
import BumpChartForDashboard from "../modules/dasboard_charts/BumpChartForDashboard";
import AreaBumpForDashboard from "../modules/dasboard_charts/AreaBumpForDashboard";
import ScatterPlotForDashboard from "../modules/dasboard_charts/ScatterPlotForDashboard";
import RadialChartForDashboard from "../modules/dasboard_charts/RadialChartForDashboard";
import CardChartForDashboard from "../modules/dasboard_charts/CardChartForDashboard";
import TableChartForDashboard from "../modules/dasboard_charts/TableChartForDashboard";
import { showDash } from "./DashboardListService";
import RadarChartForDashboard from "../modules/dasboard_charts/RadarChartForDashboard";
import AreaChartForDashboard from "../modules/dasboard_charts/AreaChartForDashboard";
import { Button, Menu } from "@mui/material";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import { DashboardUpdate } from "../modules/ModulesService";
import { getUserDetailsFromCookies, postData } from "../../services";
import CreateDashboard from "../../components/modules/nivocharts/CreateDashboardFromModulesUpdated";

import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import { DialogTitle } from "@mui/material";
import { Images } from "../../constants/Images";
import { timer } from "d3-timer";

const ResponsiveGridLayout = WidthProvider(Responsive);
type ModuleData = {
  moduleId: string;
  moduleName: string;
  visualizationData: string;

  moduleHeight: number;
  moduleWidth: number;
  xaxis: number;
  yaxis: number;
};

type LayoutItem = {
  i: string;
  x: number;
  y: number;
  w: number;
  h: number;
  minW?: number;
  minH?: number;
};

interface ChartMenuProps {
  onEdit: () => void;
  onDelete: () => void;
}

type Layout = { i: string; x: number; y: number; w: number; h: number }[];
const DashboardShow = (props: any) => {
  console.log("Props of Show Dashboard", props);
  const descLength = 10;
  const { classes } = useStyle1();
  const location = useLocation();
  const [modules, setModules] = useState([]);
  const [dashDet, setDashDet] = useState(location.state?.dashboardId);
  const [moduleList, setModuleList] = useState<any>([]);
  const [isLoading, setIsLoading] = useState(true);
  const [list, setList] = useState();
  const [dragging, setDragging] = useState(false);
  const [selectedItem, setSelectedItem] = useState("");
  const [size, setSize] = useState({ x: 250, y: 200 });
  const [isExpanded, setIsExpanded] = useState(false);
  const [fetched, setIsFetched] = useState(false);
  const [position, setPosition] = useState({ x: 0, y: 0 });
  const [dashboardName, setDashboardName] = useState("");
  const [dashboardNameError, setDashboardNameError] = useState(false);
  const [showGrid, setShowGrid] = useState(false);
  const [layout, setLayout] = useState<{ lg: LayoutItem[] }>({ lg: [] });
  const [currentLayout, setCurrentLayout] = useState<Layout>([]);

  const [openDialog, setOpenDialog] = useState(false);
 
  const handleOpenDialog = () => {
    setOpenDialog(true);
    setTimeout(() => {
      setOpenDialog(false);
    }, 3000); // Closes the dialog after 3 seconds
  };

  const [isLayoutChanged, setIsLayoutChanged] = useState(false);
  const onLayoutChange = (layout: Layout) => {
    setIsLayoutChanged(true);
    setCurrentLayout(layout);
    console.log("Updated layout Siddesh:", layout);
  };
  const updateDash = (dashboardId: number) => {
    setIsLayoutChanged(false);
    console.log("dashboardIddere", dashboardId);
    let coordinates = currentLayout.map((layoutItem: any) => ({
      moduleId: parseInt(layoutItem.i),
      xaxis: layoutItem.x,
      yaxis: layoutItem.y,
      moduleWidth: layoutItem.w,
      moduleHeight: layoutItem.h,
      
    }));

    const payload = {
      name: dashboardName,
      description: "", 
      projectId: getUserDetailsFromCookies().projectId,
      UpdateModuleCoordinates: coordinates,
    };

    DashboardUpdate(dashboardId, payload);
  };

  const navigate = useNavigate();
  const dashboardId = location.state.dashboardId;
  const dashboardNamee = location.state.dashboardName;

  console.log("dashboardname", dashboardNamee);
  console.log("dashboardId", dashboardId);

  const toggleExpand = () => {
    setIsExpanded(!isExpanded);
  };

  useEffect(() => {
    const fetchModulesAndSetLayout = async () => {
      setIsLoading(true);
      try {
        const response = await showDash(dashboardId);
        if (response && !response.isError) {
          
          setModules(response.modules);

          const layoutItems: LayoutItem[] = response.modules.map(
            (module: ModuleData) => ({
              i: module.moduleId.toString(),
              x: module.xaxis ?? 0,
              y: module.yaxis ?? 0,
              w: module.moduleWidth ?? 2,
              h: module.moduleHeight ?? 2,
              minW: 2,
              minH: 2,
            })
          );

          console.log("Layout items:", layoutItems);
         

          setLayout({ lg: layoutItems });
        } else {
          console.error(
            "Response from showDash indicates an error or no modules."
          );
        }
      } catch (error) {
        console.error("Error fetching dashboard data:", error);
      } finally {
        setIsLoading(false);
      }
    };

    if (dashboardId) {
      fetchModulesAndSetLayout();
    } else {
      console.error("Dashboard ID is undefined.");
    }
  }, [dashboardId]);
  const buttonStyle = {
    background: !isLayoutChanged ? "#ecedf2" : "linear-gradient(90deg, #5EB0FA 0%, #1157CD 50.54%, #9C90FA 100%)",
  
    fontFamily: "Nunito Sans, sans-serif",
    fontStyle: "normal",
    fontWeight: 700,
    fontSize: "18px",
    lineHeight: "25px",
    borderRadius: "5px",
    textTransform: "capitalize",
    height: "50px",
    width: "155px",
  };
  const EditbuttonStyle = {
    background:
      "linear-gradient(90deg, #5EB0FA 0%, #1157CD 50.54%, #9C90FA 100%)",
    fontFamily: "Fonts.NunitoSans.regular", 
    fontStyle: "normal",
    fontWeight: 700,
    fontSize: "18px",
    lineHeight: "25px",
    borderRadius: "5px",
    textTransform: "capitalize",
    height: "50px",
    width: "155px",
    right: "-5%",

    "&:disabled": {
      background: "#ecedf2",
    },
  };

  const dialogStyle : React.CSSProperties = {
    width: "622px",
    height: "99px",
    borderRadius: "10px",
    border: "1px solid #61CE8D",
   backgroundColor: "#CBEBD8",
   overflow: "hidden",
    display: "flex",
    position: "absolute", // Use fixed if you want it to be in the center regardless of scrolling
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)", // This centers the dialog
    zIndex: 1000, // Ensure
  };
  

  const dialogTitleStyle: React.CSSProperties = {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-start',
    position: 'absolute',
    top: '50%',
    transform: 'translateY(-50%)',
    zIndex: 999,
    marginLeft: '90px',
    fontSize: '24px',
    fontFamily: 'Nunito Sans, sans-serif',
  
  };

 const getModuleListData = async (payload: { projectId: number; moduleIds: number[] }) => {
    const { identifier, projectId } = getUserDetailsFromCookies();
    return await postData(
      `/${identifier}/api/project/${projectId}/module/getmodules`, payload
    ).then((result: any) => {
      return result;
    });
  };

  const handleEdit = async () => {
    const userDetails = getUserDetailsFromCookies();
    const projectId = userDetails.projectId;
    const moduleIds = layout.lg.map(layoutItem => parseInt(layoutItem.i));
    const payload = {
      projectId: projectId,
      moduleIds: moduleIds,
    };
    console.log("payload to be send Siddesh:",payload)

    try {
      const response = await getModuleListData(payload);
      console.log("Response from backend:", response);
      navigate(`/${userDetails.identifier}/projects/editdashboard`, {
        state: {
          dashboardId: dashboardId,
          dashboardName: dashboardName,
          response: response,
        },
      });
    } catch (error) {
      console.error("Failed to send payload to backend:", error);
    }
  };
  const renderChart = (module: any) => {
    console.log("module", module);
    const visualizationData = JSON.parse(module.visualizationData);

  
    console.log("modname", module.moduleName);
    console.log("visualizationData", visualizationData);
    console.log("visualizationDataDATA", visualizationData.data);
    console.log("visualizationDataDATADDDD", module.visualizationData.data);
    console.log("modulesss", module);

    console.log("Module id's",module.moduleId)

    switch (visualizationData.chartType) {
      case "BarChart":
        console.log("bard", module.id);
        console.log("switch", visualizationData.chartType);
        console.log("DAta for bar chart",visualizationData.data);
        return (
          <Box 
            sx={{
              position: "relative",
              bgcolor: "rgb(242,245,253)",
              boxShadow: "0 4px 8px 0 rgba(31, 38, 135, 0.37)",
              borderRadius: "10px",
              mt: 2, 
              mr: 2,
              mb: 7, 
              ml: 2, 
              width: "100%", 
              height: "100%",

             }}

          >
            <Typography
              sx={{
                position: "absolute",
                top: 16, 
                left: 16, 
                zIndex: 10, 
                backgroundColor: "rgb(242,245,253)", 
                padding: "4px", 
                borderRadius: "4px", 
              }}
            >
              {module.moduleName}
            </Typography>
            <BarChartForDashboard
              dbsource={"selecteddb"}
              data={visualizationData}
              pageError={""}
              bardata={visualizationData.data}
            />
          </Box>
        );
      case "PieChart":
        return (
          <Box
            sx={{
              position: "relative",
              bgcolor: "rgb(242,245,253)",
              boxShadow: "0 4px 8px 0 rgba(31, 38, 135, 0.37)", 
              borderRadius: "10px", 
              p: 0.5, 
              mt: 2,
              mr: 2, 
              mb: 7, 
              ml: 2,
              width: "100%", 
             height: "100%",
            }}
          >
            <Typography
              sx={{
                position: "absolute",
                top: 16, 
                left: 16, 
                zIndex: 10, 
                backgroundColor: "rgb(242,245,253)", 
                padding: "4px", 
                borderRadius: "4px", 
              }}
            >
              {module.moduleName}
            </Typography>
            <PieChartForDashboard
              dbsource={"selecteddb"}
              data={visualizationData}
              pageError={""}
              bardata={visualizationData.data}
            />
          </Box>
        );

      case "LineChart":
        return (
          <Box
            sx={{
              position: "relative",
              bgcolor: "rgb(242,245,253)",
              boxShadow: "0 4px 8px 0 rgba(31, 38, 135, 0.37)", 
              borderRadius: "10px", 
              p: 0.5, 
              mt: 2, 
              mr: 2, 
              mb: 7, 
              ml: 2, 
              width: "100%", 
              height: "100%",
            }}
          >
            <Typography
              sx={{
                position: "absolute",
                top: 16, 
                left: 16, 
                zIndex: 10, 
                backgroundColor: "rgb(242,245,253)", 
                padding: "4px", 
                borderRadius: "4px", 
              }}
            >
              {module.moduleName}
            </Typography>
            <LineChartForDashboard
              dbsource={"selecteddb"}
              data={visualizationData.data}
              pageError={""}
              bardata={visualizationData.data[0].data}
            />
          </Box>
        );
      case "BumpChart":
        return (
          <Box
            sx={{
              position: "relative",
              bgcolor: "rgb(242,245,253)",
              boxShadow: "0 4px 8px 0 rgba(31, 38, 135, 0.37)", 
              borderRadius: "10px", 
              p: 0.5, 
              mt: 2, 
              mr: 2, 
              mb: 7, 
              ml: 2, 
              width: "100%", 
              height: "100%",
            }}
          >
            <Typography
              sx={{
                position: "absolute",
                top: 16, 
                left: 16, 
                zIndex: 10, 
                backgroundColor: "rgb(242,245,253)", 
                padding: "4px", 
                borderRadius: "4px", 
              }}
            >
              {module.moduleName}
            </Typography>
            <BumpChartForDashboard
              dbsource={"selecteddb"}
              data={visualizationData}
              pageError={""}
              bardata={visualizationData.data}
            />
          </Box>
        );
      case "RadialBarChart":
        return (
          <Box
            sx={{
              position: "relative",
              bgcolor: "rgb(242,245,253)",
              boxShadow: "0 4px 8px 0 rgba(31, 38, 135, 0.37)", 
              borderRadius: "10px", 
              p: 0.5, 
              mt: 2, 
              mr: 2, 
              mb: 7, 
              ml: 2,
              width: "100%", 
              height: "100%",
            }}
          >
            <Typography
              sx={{
                position: "absolute",
                top: 16,
                left: 16, 
                zIndex: 10, 
                backgroundColor: "rgb(242,245,253)", 
                padding: "4px", 
                borderRadius: "4px", 
              }}
            >
              {module.moduleName}
            </Typography>
            <RadialChartForDashboard
              dbsource={"selecteddb"}
              data={visualizationData}
              pageError={""}
              bardata={visualizationData.data}
            />
          </Box>
        );

      case "CardChart":
        return (
          <Box onClick={() => setShowGrid(false)}
            sx={{
              position: "relative",
              bgcolor: "rgb(242,245,253)",
              boxShadow: "0 4px 8px 0 rgba(31, 38, 135, 0.37)",
              borderRadius: "10px", // 
               p: 0.5, 
              mt: 2, 
              mr: 2, 
              mb: 7, 
              ml: 2, 
              width: "100%", 
              height: "100%",
             }} 
           >
            <Typography
              sx={{
                position: "absolute",
                top: 16, 
                left: 16, 
                zIndex: 10, 
                backgroundColor: "rgb(242,245,253)", 
                padding: "4px", 
                borderRadius: "4px", 
              }}
            >
              {module.moduleName}
            </Typography>
            <CardChartForDashboard
              dbsource={"selecteddb"}
              data={visualizationData}
              pageError={""}
              bardata={visualizationData.data}
            />
          </Box>
        );
        case "TableChart":
        return (
          <Box onClick={() => setShowGrid(false)}
            sx={{
              position: "relative",
              bgcolor: "rgb(242,245,253)",
              boxShadow: "0 4px 8px 0 rgba(31, 38, 135, 0.37)",
              borderRadius: "10px", // 
               p: 0.5, 
              mt: 2, 
              mr: 2, 
              mb: 7, 
              ml: 2, 
              width: "100%", 
              height: "100%",
             }} 
           >
            <Typography
              sx={{
                position: "absolute",
                top: 0, 
                left: 16, 
                // zIndex: 10, 
                backgroundColor: "rgb(242,245,253)", 
                padding: "4px", 
                borderRadius: "4px", 
              }}
            >
              {module.moduleName}
            </Typography>
            <TableChartForDashboard
              dbsource={"selecteddb"}
              data={visualizationData}
              pageError={""}
              bardata={visualizationData.data}
            />
          </Box>
        );

      case "RadarChart":
        console.log(visualizationData.data);
        return (
          <Box
            sx={{
              position: "relative",
              bgcolor: "rgb(242,245,253)",
              boxShadow: "0 4px 8px 0 rgba(31, 38, 135, 0.37)", 
              borderRadius: "10px",
              p: 0.5, 
              mt: 2, 
              mr: 2, 
              mb: 7, 
              ml: 2, 
              width: "100%", 
              height: "100%",
            }}
            position="absolute"
            top={0}
            right={0}
          >
            <Typography
              sx={{
                position: "absolute",
                top: 16, 
                left: 16, 
                zIndex: 10, 
                backgroundColor: "rgb(242,245,253)", 
                padding: "4px", 
                borderRadius: "4px", 
              }}
            >
              {module.moduleName}
            </Typography>

            <RadarChartForDashboard
              dbsource={"selecteddb"}
              data={visualizationData}
              pageError={""}
              bardata={visualizationData.data}
            />
          </Box>
        );
        case "AreaChart":
          console.log(visualizationData.data);
          return (
            <Box
              sx={{
                position: "relative",
                bgcolor: "rgb(242,245,253)",
                boxShadow: "0 4px 8px 0 rgba(31, 38, 135, 0.37)", 
                borderRadius: "10px",
                p: 0.5, 
                mt: 2, 
                mr: 2, 
                mb: 7, 
                ml: 2, 
                width: "100%", 
                height: "100%",
              }}
              position="absolute"
              top={0}
              right={0}
            >
              <Typography
                sx={{
                  position: "absolute",
                  top: 0, 
                  left: 16, 
                  // zIndex: 10, 
                  backgroundColor: "rgb(242,245,253)", 
                  padding: "4px", 
                  borderRadius: "4px", 
                }}
              >
                {module.moduleName}
              </Typography>
  
              <AreaChartForDashboard
                dbsource={"selecteddb"}
                data={visualizationData}
                pageError={""}
                bardata={visualizationData.data}
              />
            </Box>
          );
      case "AreaBumpChart":
        return (
          <Box
            sx={{
              position: "relative",
              bgcolor: "rgb(242,245,253)",
              boxShadow: "0 4px 8px 0 rgba(31, 38, 135, 0.37)", 
              borderRadius: "10px", 
              p: 0.5, 
              mt: 2, 
              mr: 2, 
              mb: 7,
              ml: 2, 
              width: "100%", 
              height: "100%",
             
            }}
          
          >
            <Typography
              sx={{
                position: "absolute",
                top: 16, 
                left: 16, 
                zIndex: 10,
                backgroundColor: "rgb(242,245,253)",
                padding: "4px", 
                borderRadius: "4px", 
              }}
            >
              {module.moduleName}
            </Typography>
            <AreaBumpForDashboard
              dbsource={"selecteddb"}
              data={visualizationData}
              pageError={""}
              bardata={visualizationData.data}
            />
          </Box>
        );
      case "ScatterPlot":
        return (
          <Box
            sx={{
              position: "relative",
              bgcolor: "rgb(242,245,253)",
              boxShadow: "0 4px 8px 0 rgba(31, 38, 135, 0.37)", 
              borderRadius: "10px", 
              p: 0.5,
              mt: 2, 
              mr: 2, 
              mb: 7, 
              ml: 2, 
              width: "100%", 
              height: "100%",
            }}
          >
            <Typography
              sx={{
                position: "absolute",
                top: 16, 
                left: 16, 
                zIndex: 10, 
                backgroundColor: "rgb(242,245,253)", 
                padding: "4px", 
                borderRadius: "4px", 
              }}
            >
              {module.moduleName}
            </Typography>
            <ScatterPlotForDashboard
              dbsource={"selecteddb"}
              data={visualizationData}
              pageError={""}
              bardata={visualizationData.data}
            />
          </Box>
        );
    }
  };
return (
  <div style={{  backgroundColor: "rgb(255,255,255)", position: "relative", minHeight:"87vh" }}>
  <div style={{ display: "flex", justifyContent: "space-between"}}>
    <h1
      style={{
        fontWeight: 700,
        fontSize: "23px",
        marginLeft: "30px",
        marginTop: "15px",
      
      }}
    >
      {dashboardNamee}
    </h1>
    <div style={{ position: "absolute", top: "15px", right: "30px", marginBottom:"20px" }}>
      <Button
        variant="contained"
        color="primary"
        style={buttonStyle as React.CSSProperties}
        onClick={() => {
          updateDash(dashboardId);
          setOpenDialog(true);
          handleOpenDialog(); 
          // navigate(-1);
          console.log("Update button clicked");
        }}
        disabled={!isLayoutChanged}
        
      >
        Save
      </Button>
      <Dialog
  open={openDialog}
  onClose={() => setOpenDialog(false)}
  PaperProps={{
    style: dialogStyle,
    display: "flex",
  }}
  BackdropProps={{
    style: {
      backgroundColor: "transparent",
    
    },
    invisible: true,
  }}
>
  <img src={Images.success} alt="Alert" style={{ width: '55px', height: '55px', marginLeft: '5%',marginTop: '3%',}} />
  <DialogTitle style={dialogTitleStyle}>
    <div >Success</div>
    <div style={{ fontSize: '16px', marginTop: '4px', fontFamily: 'Nunito Sans, sans-serif' }}>
      Your dashboard changes have been saved!
    </div>
  </DialogTitle>
</Dialog>
      <Button
        variant="contained"
        color="primary"
        style={EditbuttonStyle as React.CSSProperties}
        onClick={handleEdit}
      >
        Edit
      </Button>
    </div>
  </div>
 
  <ResponsiveGridLayout
    layouts={layout}
    breakpoints={{ lg: 1200, md: 996, sm: 768, xs: 480, xxs: 0 }}
    cols={{ lg: 12, md: 10, sm: 6, xs: 4, xxs: 2 }}
    rowHeight={160}
    containerPadding={[15, 15]}
    autoSize={true}
    onDragStart={() => setShowGrid(true)}
    onDragStop={(newLayout) => {
      setShowGrid(false);
      onLayoutChange(newLayout);
    }}
    onResizeStart={() => setShowGrid(true)}
    onResizeStop={(newLayout) => {
      setShowGrid(false);
      onLayoutChange(newLayout);
    }}
    className={showGrid ? classes.showGridBackgroundStyle : ""}
  >
    {modules.map((module: ModuleData, index: number) => (
      <div key={module.moduleId} onClick={() => setShowGrid(false)}>{renderChart(module)}</div>
      
    ))}
    
  </ResponsiveGridLayout>
</div>

);
};


export default DashboardShow;
