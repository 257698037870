import {
  React,
  Card,
  CardContent,
  CardActions,
  Box,
  Typography,
  Divider,
  InputLabel,
  parse,
  clsx,
  Grid,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Collapse,
  IconButton,
  ExpandMore,
  ExpandLess,
  ChevronRight,
  useNavigate,
  Backdrop,
  CircularProgress,
  useEffect,
  useState,
  Toolbar,
  styled,
  Drawer,
  Button,
  List,
  ListItem,
  useContext,
  AppStatesContainer,
  ListItemText,
  Modal,
  useTheme,
  useRef,
  useMsal,
  CssBaseline,
  Search,
  Popover,
  Cookies,
} from "../../../app.module";
import { getUserDetailsFromCookies } from "../../services";
import { Images, Colors } from "../../constants";
import {
  useStyle,
  StyledButton,
  CssPlanTextField,
  CustomSwitch,
} from "./PlanStyle";
import {
  getPlanAddons,
  getSubscriptionPlan,
  subscribeToPlan,
} from "./PlanService";
import {
  Main,
  DrawerHeader,
  WingsBIAppBar,
  StyledInputBase,
} from "../../shared_component/Drawer/CustomDrawerStyle";
import { Theme, CSSObject, AppBarProps, Menu } from "@mui/material";

interface Addon {
  addonName: string;
  addonprice: number;
  addonDescription?: string;
}


const Plan = (props: any) => {
  const { classes } = useStyle();
  const { instance } = useMsal();
  const navigate = useNavigate();
  const [monthly, setMonthly] = useState(true);
  const [usersCount, setUsersCount] = useState("");
  const [checked, setChecked] = React.useState(false);
  const [productPlan, setProductPlan] = useState([]);
  // const [productPlanAddons, setProductPlanAddons] = useState([]);
  const [productPlanAddons, setProductPlanAddons] = useState<Addon[]>([]);
  const [addonsIds, setAddonsIds] = useState<number[]>([]);
  const [productFeatures, setProductFeatures] = useState([]);
  const [productFeaturesCnt, setProductFeaturesCnt] = useState<number[]>([]);
  const [expanded, setExpanded] = useState(0);
  const [isLoading, setIsLoading] = useState(true);
  const [open, setOpen] = React.useState(false);
  const [modalOpen, setModalOpen] = useState(false);
  const [currentIndex, setCurrentIndex] = useState(2);
  const [currentDashboardPath, setCurrrentDashboardPath] = useState([]);
  const [previousIndex, setPreviousIndex] = useState(2);
  const [addonQuantities, setAddonQuantities] = useState<{ [key: number]: number }>({});
  const [description, setPlanDescription] = useState(
    `<b>Lifetime</b> free <br><br> Single user <br><br> Free <b>1 project</b> creation <br><br> Run <b> 1 query</b> <br><br> Create upto <b>1 dashboard</b><br><br> upto to <b>10 MB project data</b>`
  );
  useEffect(() => {
    getSubscriptionPlan().then((resp) => {
      console.log("Responsesid",resp)
      let plan_result = resp?.data?.plan;
      console.log("plan_name:",plan_result)
      let f_arr = [];
      for (let feature = 0; feature < plan_result.length; feature++) {
        f_arr.push(feature + 1);
      }
      setProductFeaturesCnt([...f_arr]);
      //console.log("DATAFD array", f_arr);
      setProductPlan(plan_result);
      setProductFeatures(resp.data.features);
      getPlanAddons().then((resp:any) => {
        setProductPlanAddons(resp);
        setIsLoading(false);
      });
    });
  }, []);

console.log("Productplanstate",productPlan)
  
  const handleChange = (event: any) => {
    setMonthly(event.target.checked);
  };

  const handleUserChange = (event: any) => {
    setUsersCount(event.target.value);
  };
  const findAppliesTo = (applies: Array<Number>, index: number) => {
    return applies.includes(index + 1);
  };
  const handleExpandClick = (index: number) => {
    expanded === index + 1 ? setExpanded(0) : setExpanded(index + 1);
  };

  const addAddonsToPlan = (id: number = 0) => {
    let previousAddonsIds = addonsIds;
    // is element is present inside the array or not
    if (previousAddonsIds.includes(id)) {
      previousAddonsIds.splice(previousAddonsIds.indexOf(id), 1);
    } else {
      previousAddonsIds.push(id);
    }
    // Rerender the component with the updated data
    setAddonsIds([...previousAddonsIds]);
  };
  
  const getAddonsId = (id: number) => {
    return addonsIds.find((element) => element === id);
  };

  
  const updateAddonQuantity = (addonId: number, change: number) => {
    setAddonQuantities(prev => {
        const newCount = (prev[addonId] || 0) + change;
        if (newCount < 1) {  // Ensure the count doesn't go below zero
            const updatedQuantities = { ...prev };
            delete updatedQuantities[addonId];
            return updatedQuantities;
        }
        return { ...prev, [addonId]: newCount };
    });
};

const generateArrayFromQuantities = () => {
  const result = [];
  for (const [id, count] of Object.entries(addonQuantities)) {
      for (let i = 0; i < count; i++) {
          result.push(Number(id));
      }
  }
  return result;
};

  const currentAddonArray = generateArrayFromQuantities();
console.log("Siddesh id are",currentAddonArray);

  
const formatAddonsForSubmission = () => {
  const formattedAddons = [];
  for (const [id, count] of Object.entries(addonQuantities)) {
      if (count > 0) {  // Ensure we only include addons with a positive count
          formattedAddons.push({
              Id: Number(id),
              Count: count
          });
      }
  }
  return formattedAddons;  // Return the array directly
};


const formattedAddonsArray = formatAddonsForSubmission();
console.log("Addons in arrayobject format: ",JSON.stringify(formattedAddonsArray, null, 2));



  const handleSubscription = (plan: {
    id: number;
    monthlyPrice: number;
    annualPrice: number;
    duration: number;
    name: string;
    description: string;
    priority: number;
  }) => {
    let planDetails = {
      planId: plan.id,
      userId: getUserDetailsFromCookies().id,
      couponcode: "",
      // planType: monthly ? "Monthly" : "Yearly",
    };

    // console.log("plandet", planDetails);
    subscribeToPlan(planDetails).then((subscribedPlan: any) => {
      console.log("Subscribed to plan", subscribedPlan);
      navigate("/order", {
        state: {
          data: subscribedPlan,
          planType: monthly ? "/Month" : "/Year",
          planDetails: planDetails,
        },
      });
    });
  };



  const getUserCountLimit = (min: number, max: number): boolean => {
    return Number(usersCount) >= min && Number(usersCount) <= max;
  };
  

  const userEnterprise = (name: string): boolean => {
    return (
      // name == "Enterprise" &&
      // Number(usersCount) > 25 &&
      // Number(usersCount) <= 50000
      name == "Enterprise" && Number(usersCount) > 0
    );
  };

  const handleClose = () => {
    setIsLoading(false);
  };
  const handleModalOpen = () => {
    setModalOpen(true);
  };
  const SwipableDrawer = styled(Drawer, {
    shouldForwardProp: (prop) => prop !== "open",
  })(({ theme, open }) => ({
    width: drawerWidth,
    flexShrink: 0,
    whiteSpace: "nowrap",
    boxSizing: "border-box",
    ...(open && {
      ...openedMixin(theme),
      "& .MuiDrawer-paper": openedMixin(theme),
    }),
    ...(!open && {
      ...closedMixin(theme),
      "& .MuiDrawer-paper": closedMixin(theme),
    }),
  }));
  const drawerWidth = 160;
  const openedMixin = (theme: Theme): CSSObject => ({
    width: drawerWidth,
    transition: theme.transitions.create("width", {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
    overflow: "auto",
  });
  const closedMixin = (theme: Theme): CSSObject => ({
    transition: theme.transitions.create("width", {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    overflow: "auto",
    width: `calc(${theme.spacing(8.5)} + 1px)`, // spacing between drawer and Header bar
    [theme.breakpoints.up("sm")]: {
      width: `calc(${theme.spacing(8.5)} + 1px)`,
    },
  });
  const handleDrawerClose = () => {
    setOpen((prev) => !prev);
  };
  const handleModalClose = () => {
    setModalOpen(false);
  };

  // const handleSignOut = () => {
  //   const cookieNames = Object.keys(Cookies.get());
  //   // Iterate through the cookie names and remove each cookie
  //   instance.logoutRedirect();
  //   Cookies.remove("user_details");
  //   navigate("/");
  // }
  // console.log("de re",getUserDetailsFromCookies());
  const [drawerItems, setDrawerItems] = useState([]);
  const handleBorderRadius = (index: number, isActive: boolean) => {
    if (index === 1 && currentIndex === 0) {
      return Colors?.background.backgroundColorTheme2;
    }
    return currentIndex - 1 === index || (currentIndex + 1 === index && index)
      ? Colors?.background.backgroundColorTheme3
      : Colors?.background.backgroundColorTheme2;
    //  return  currentIndex - 1 === index ||
    //                   (currentIndex + 1 === index && index)
    //                     ? Colors?.background.backgroundColorTheme3
    //                     : Colors?.background.backgroundColorTheme2,
  };

  const path = useContext(AppStatesContainer);
  const handleCursor = (index: number) => {
    if (index === 0 || index + 1 === drawerItems.length) {
      return false;
    }
    return true;
    // index + 1 != drawerItems.length || index !== 0;
  };
  const scrollToSection = (sectionId: any) => {
    const section = document.getElementById(sectionId);
    if (section) {
      section.scrollIntoView({ behavior: "smooth" });
    }
  };

  const handleOpen = () => {
    try {
      const userDetails = getUserDetailsFromCookies();

      if (userDetails) {
        const firstName = userDetails.firstName;
        const lastName = userDetails.lastName;

        // Check if firstName and lastName are lowercase
        if (firstName === firstName.toLowerCase() && lastName === lastName.toLowerCase()) {
          // Convert to uppercase if they are lowercase
          const firstNameUppercase = firstName.toUpperCase();
          const lastNameUppercase = lastName.toUpperCase();

          return firstNameUppercase[0] + lastNameUppercase[0];
        } else {
          // Use the first letters as they are
          return firstName[0] + lastName[0];
        }
      } else {
        return "";
      }
    } catch (error) {
      console.error("Error parsing userDetails cookie:", error);
      return "";
    }
  };
  const handleSignOut = () => {
    const cookieNames = Object.keys(Cookies.get());
    cookieNames.forEach((cookieName) => {
      console.log("Cookies resets", cookieNames);
      Cookies.remove(cookieName);
    });
    instance.logoutRedirect();
    Cookies.remove("user_details");
    navigate("/");
  };

  const capitalizeFirstLetter = (string: string): string => {
    return string.charAt(0).toUpperCase() + string.slice(1).toLowerCase();
  };


  const handleSubscribeClick = (plan: any) => {
    if (plan.name === 'On-Premise') {
      window.open("https://www.wingsbi.com/", "_blank");
    } else {
      handleSubscription(plan);
    }
  };
  
  return (
    <div style={{ overflowX: "hidden"}}>
      <Modal
        keepMounted
        open={modalOpen}
        onClose={handleModalClose}
        closeAfterTransition
        aria-labelledby="keep-mounted-modal-title"
        aria-describedby="keep-mounted-modal-description"
      >
        <Box
          sx={{
            position: "absolute" as "absolute",
            top: "10ch",
            left: "93%",
            transform: "translate(-50%, -50%)",
            width: 120,
            backgroundColor: "#fff",
            borderRadius: "10px",
            border: `1px solid ${Colors?.border.plan.borderColor}`,
            boxShadow: "0px 0px 4px rgba(0, 0, 0, 0.25)",
            p: 0.8,
          }}
        >
          <Button
            onClick={() => {
              handleSignOut();
            }}
            className={classes.userActionTitle}
          >
            Sign Out
          </Button>
        </Box>
      </Modal>
      <Main className={classes.dasboardContainer} open={open}>
        <WingsBIAppBar className={classes.appBarStyle} open={open}>
          <Toolbar className={classes.toolBarStyle}>
            <div
              style={{
                display: "flex",
                flex: 1,
                flexDirection: "column",
              }}
            >
              <div
                style={{
                  display: "flex",
                  flex: 1,
                  justifyContent: "space-between",
                }}
              >
                <div className={classes.userProfileContainer}>
                  <div className={classes.userProfileImg}>
                    <div className={classes.userProfileTxt}>{handleOpen()}</div>
                  </div>
                 
                  <div>
                    <Typography className={classes.userNameStyle}>
                      {/* {capitalizeFirstLetter(
                        getUserDetailsFromCookies().firstName
                      )}{" "} */}
                      {getUserDetailsFromCookies().firstName}{" "}
                      {getUserDetailsFromCookies().lastName}
                    </Typography>
                    <Typography className={classes.userRoleTitle}>
                      {getUserDetailsFromCookies().roleName || "Company Admin"}
                    </Typography>
                  </div>
                  <IconButton sx={{ padding: 0, paddingLeft: "2px" }}>
                    <ExpandMore
                      onClick={() => {
                        handleModalOpen();
                      }}
                      className={classes.userProfileDetailsCollaps}
                    />
                  </IconButton>
                </div>
              </div>
            </div>
          </Toolbar>
        </WingsBIAppBar>
        <SwipableDrawer
          classes={{
            paper: classes.drawerPaper,
          }}
          variant="permanent"
          open={open}
        >
          <img
            className={classes.logoCloseStyle}
            src={Images.logomark_svg}
            alt="mySvgImage"
          />
        </SwipableDrawer>

        <Box className={classes.planContainer}>
          {/* <div className={classes.header}>
        <div className={classes.text}>
        <img
          className={classes.planHeading}
          alt="Header Wingsbi logo"
          src={Images.marketing_logo_name}
        />
      </div>
      <div className="username">
      </div>
      </div> */}

          <Backdrop
            className={classes.circularPlanContainer}
            open={isLoading}
            onClick={handleClose}
          >
            <CircularProgress className={classes.circularPlanView} />
          </Backdrop>
          <Box className={classes.planTopContainer}>
            <Box className={classes.PlanBoxContainer}>
              <Typography className={classes.planTopLabel} gutterBottom>
                Select a plan
              </Typography>
            </Box>
            <Box
              style={{
                display: "flex",
                flex: 1,
                justifyContent: "center",
                alignItems: "center",
                flexWrap: "wrap",
              }}
            >
          
            </Box>
            <Box className={clsx(classes.PlanBoxContainer, classes.Planmargin)}>
              {/* <Typography variant="h5" gutterBottom className={classes.godown}>
                <a style={{ textDecoration: "none" }} href="#addons" onClick={() => scrollToSection("addons")}>
                  go to addons
                </a>
              </Typography> */}

              <InputLabel className={classes.PlanUserLabel}>
                {" "}
                Billing Cycle: {!monthly ? parse("<b>Annual<b>") : "Annual"}
              </InputLabel>
              <CustomSwitch
                checked={monthly}
                onChange={handleChange}
                name="checked"
                color="primary"
              />
              <InputLabel className={classes.PlanUserLabel}>
                {monthly ? parse("<b>Monthly<b>") : "Monthly"}
              </InputLabel>
            </Box> 
                  
            <Grid
              justifyContent="center"
              alignItems="center"
              flexDirection={"row"}
              container
              width={"105%"}
            >
              {/* {productPlan?.map((plan: any, index: Number) => ( */}
{/* 
              {productPlan.filter((plan: any) => 
    monthly ? plan.planType === "Monthly plan" : plan.planType === "Annualy plan"
  ).map((plan: any, index: Number) => ( */}
  {productPlan.filter((plan: any) => 
    plan.planType === "Free plan" || (monthly ? plan.planType === "Monthly plan" : plan.planType === "Annualy plan")
  ).map((plan: any, index: Number) => (
                
                <Grid padding={2} justifyContent="center" alignItems="center"  marginTop="40px" marginBottom="33px" >
                  <Grid item xs={12} sm={6} md={3} >
                    <Card
                      sx={{
                        maxWidth: 310,
                        minWidth: 290,
                        minHeight: 500,
                        maxHeight: 500,
                        padding: 1,
                        marginTop: "-30%",
                        border: `1px solid ${getUserCountLimit(
                          plan?.minUserCount,
                          plan?.maxUserCount
                        )
                          ? Colors?.border.borderColor
                          : Colors?.border?.light.borderColor
                          }`,
                        borderTop: 10,
                        borderTopColor: getUserCountLimit(
                          plan?.minUserCount,
                          plan?.maxUserCount
                        )
                          ? Colors?.border.borderColor
                          : Colors?.border?.light.borderColor,

                        backgroundColor: userEnterprise(plan.name)
                          ? Colors?.tilebackground
                          : Colors?.tilebg,

                        WebkitTextFillColor: userEnterprise(plan.name)
                          ? "#fff"
                          : "#000",
                      }}
                    >
                    <CardContent>
          <Typography className={classes.planTopLabel} gutterBottom>
            {plan.name}
          </Typography>
          {plan.name.toLowerCase() !== 'on-premise' ? (
            <Typography className={classes.planTopPriceLabel} gutterBottom>
              ₹ {plan.totalPrice}
            </Typography>
          ) : (
            <Typography className={classes.planTopPriceLabel} gutterBottom style={{ fontSize: '0.875rem' }}>
              Please contact our support team.
            </Typography>
          )}
        </CardContent>

                      <CardActions>
                      
                        <StyledButton
  onClick={() => {
    handleSubscribeClick(plan);
  }}
  className={clsx(
    getUserCountLimit(
      plan?.minUserCount,
      plan?.maxUserCount
    )
    ? classes.btnPlanLabelStyle
    : classes.btnInactivePlanLabelStyle,

    userEnterprise(plan.name)
    ? classes.btnInactivePlanLabelStyle
    : Colors?.tilebg
  )}
  variant="contained"
  size="medium"
>
  {plan.name.toLowerCase() === 'free' ? 'Subscribe for Free' :
   plan.name.toLowerCase() === 'on-premise' ? 'Contact Us' : 'Try Here'}
</StyledButton>

                        
                      </CardActions>
                      
                      <Divider style={{ border: "0.5px solid" ,marginBottom:"10px"}} />
                      <CardContent>
                        <Typography
                          className={classes.planDescriptionStyle}
                          gutterBottom
                        >
                          {parse(plan?.descriptionInhtml)}
                        </Typography>
                      </CardContent>
                    </Card>
                  </Grid>
                </Grid>
              ))}
            </Grid>
          </Box>
          

        </Box>
      </Main>
    </div>
  );
};

export default Plan;

