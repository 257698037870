import React, { createContext } from "react";

export const ThemeContext = createContext<any>({
  palette: {
    mode: "dark",
    primary: {
      main: "#979756",
    },
  },
  typography: {
    h1: {
      fontFamily: "Arial",
      fontSize: "40px",
    },
  },

  // mode: "light",
  // theme: {
  //   primary: {
  //     main: "#d1dff6",
  //   },
  //   typography: {
  //     h1: {
  //       fontFamily: "Arial",
  //       fontSize: "40px",
  //     },
  //   },
  // },

  undefined,
});
