import {
  getData,
  postData,
  postToPythonServer,
  putData,
  getUserDetailsFromCookies,
  getQueryIdFromCookies,
} from "../../../services";
import {
  GetVisualizationType,
  GetQuery,
  CommonProject,
  GetPossibleVisualization,
  GetVisualizationState,
  GetVisualizationResponse,
  Date,
  Month,
  Day,
  recurrencetype,
} from "../../../constants/Endpoints";
import { Cookies } from "../../../../app.module";
let TOKEN: string = "";

export const getSelectedChartDetails = () => {};

export const getSelectedChartFromPossibleVisualization = (
  pv: any,
  type: string
) => {
  return pv.find((obj: any) => obj.chartType === type);
};
export const getVisualizationPayLoad = async (
  type: string,
  selectedModuleId: number,
  resp: any,
  xValue: any,
  yValue: any,
  states: any,
  axisFlag: number
) => {
  console.log("Render Count Value ", axisFlag);
  switch (type) {
    case "BarChart":
      let foundObject = getSelectedChartFromPossibleVisualization(resp, type);
      let parameterXIdx =
        axisFlag === 0 || axisFlag === 2
          ? foundObject?.chartParameter[0]?.parameterValue.indexOf(xValue)
          : 0;
      let parameterYIdx =
        axisFlag === 0 || axisFlag === 2
          ? foundObject?.chartParameter[1]?.parameterValue.indexOf(yValue)
          : 0;
      let payload_visulaization = {
        id: getQueryIdFromCookies(),
        moduleId: selectedModuleId,
        userId: getUserDetailsFromCookies().id,
        chartTypeId: foundObject?.chartTypeId,
        chartType: foundObject?.chartType,
        chartParameter: [
          {
            parameterId: foundObject?.chartParameter[0]?.parameterId,
            parameterValueIndex: parameterXIdx,
            parameterType: "X-Axis",
            parameterValue:
              axisFlag === 1
                ? foundObject?.chartParameter[0]?.parameterValue[0]
                : xValue,
          },
          {
            parameterId: foundObject?.chartParameter[1]?.parameterId,
            parameterValueIndex: parameterYIdx,
            parameterType: "Y-Axis",
            parameterValue:
              axisFlag === 1
                ? foundObject?.chartParameter[1]?.parameterValue[0]
                : yValue,
          },
        ],
      };
      console.log(
        "DATATA BarChart payload_visulaization",
        payload_visulaization
      );
      let barResp = await getBarChartVisualizationData(
        "BarChart",
        payload_visulaization
      );
      if (!barResp.isError) {
        return {
          visulaization_type_payload: payload_visulaization,
          possibleVisualization: foundObject,
          response: barResp,
        };
      } else {
        return barResp;
      }

    case "CardChart":
      let foundCardObject = getSelectedChartFromPossibleVisualization(
        resp,
        type
      );
      let card_parameterXIdx =
        axisFlag === 0 || axisFlag === 2
          ? foundCardObject?.chartParameter[0]?.parameterValue.indexOf(xValue)
          : 0;
      let card_payload_visulaization = {
        id: getQueryIdFromCookies(),
        moduleId: selectedModuleId,
        userId: getUserDetailsFromCookies().id,
        chartTypeId: foundCardObject?.chartTypeId,
        chartType: foundCardObject?.chartType,
        chartParameter: [
          {
            parameterId: foundCardObject?.chartParameter[0]?.parameterId,
            parameterValueIndex: card_parameterXIdx,
            parameterType: "X-Axis",
            parameterValue:
              axisFlag === 1
                ? foundCardObject?.chartParameter[0]?.parameterValue[0]
                : xValue,
          },
        ],
      };
      console.log("Card Payload Visualization ID:", getQueryIdFromCookies());
      console.log(
        "DATA CardChart payload_visulaization",
        card_payload_visulaization
      );
      let cardResp = await getCardChartVisualizationData(
        "CardChart",
        card_payload_visulaization
      );
      if (!cardResp.isError) {
        return {
          visulaization_type_payload: card_payload_visulaization,
          possibleVisualization: foundCardObject,
          response: cardResp,
        };
      } else {
        return cardResp;
      }

    case "TableChart":
      let foundTableObject = getSelectedChartFromPossibleVisualization(
        resp,
        type
      );
      let table_parameterXIdx =
        axisFlag === 0 || axisFlag === 2
          ? foundTableObject?.chartParameter[0]?.parameterValue.indexOf(xValue)
          : 0;
      let table_parameterYIdx =
        axisFlag === 0 || axisFlag === 2
          ? foundTableObject?.chartParameter[1]?.parameterValue.indexOf(yValue)
          : 0;
      let table_payload_visulaization = {
        id: getQueryIdFromCookies(),
        moduleId: selectedModuleId,
        userId: getUserDetailsFromCookies().id,
        chartTypeId: foundTableObject?.chartTypeId,
        chartType: foundTableObject?.chartType,
        chartParameter: [
          {
            parameterId: foundTableObject?.chartParameter[0]?.parameterId,
            parameterValueIndex: table_parameterXIdx,
            parameterType: "X-Axis",
            parameterValue:
              axisFlag === 1
                ? foundTableObject?.chartParameter[0]?.parameterValue[0]
                : xValue,
          },
          {
            parameterId: foundTableObject?.chartParameter[1]?.parameterId,
            parameterValueIndex: table_parameterYIdx,
            parameterType: "Y-Axis",
            parameterValue:
              axisFlag === 1
                ? foundTableObject?.chartParameter[1]?.parameterValue[0]
                : yValue,
          },
        ],
      };
      console.log("Table Payload Visualization ID:", getQueryIdFromCookies());
      console.log(
        "DATA TableChart payload_visulaization",
        table_payload_visulaization
      );
      let tableResp = await getRadialChartVisualizationData(
        "TableChart",
        table_payload_visulaization
      );
      if (!tableResp.isError) {
        return {
          visulaization_type_payload: table_payload_visulaization,
          possibleVisualization: foundTableObject,
          response: tableResp,
        };
      } else {
        return tableResp;
      }

    case "RadialBarChart":
      let foundRadialObject = getSelectedChartFromPossibleVisualization(
        resp,
        type
      );
      let radial_parameterXIdx =
        axisFlag === 0 || axisFlag === 2
          ? foundRadialObject?.chartParameter[0]?.parameterValue.indexOf(xValue)
          : 0;
      let radial_parameterYIdx =
        axisFlag === 0 || axisFlag === 2
          ? foundRadialObject?.chartParameter[1]?.parameterValue.indexOf(yValue)
          : 0;
      let radial_payload_visulaization = {
        id: getQueryIdFromCookies(),
        moduleId: selectedModuleId,
        userId: getUserDetailsFromCookies().id,
        chartTypeId: foundRadialObject?.chartTypeId,
        chartType: foundRadialObject?.chartType,
        chartParameter: [
          {
            parameterId: foundRadialObject?.chartParameter[0]?.parameterId,
            parameterValueIndex: radial_parameterXIdx,
            parameterType: "X-Axis",
            parameterValue:
              axisFlag === 1
                ? foundRadialObject?.chartParameter[0]?.parameterValue[0]
                : xValue,
          },
          {
            parameterId: foundRadialObject?.chartParameter[1]?.parameterId,
            parameterValueIndex: radial_parameterYIdx,
            parameterType: "Y-Axis",
            parameterValue:
              axisFlag === 1
                ? foundRadialObject?.chartParameter[1]?.parameterValue[0]
                : yValue,
          },
        ],
      };
      console.log("Radial Payload Visualization ID:", getQueryIdFromCookies());
      console.log(
        "DATA RadialChart payload_visulaization",
        radial_payload_visulaization
      );
      let radialResp = await getRadialChartVisualizationData(
        "RadialChart",
        radial_payload_visulaization
      );
      if (!radialResp.isError) {
        return {
          visulaization_type_payload: radial_payload_visulaization,
          possibleVisualization: foundRadialObject,
          response: radialResp,
        };
      } else {
        return radialResp;
      }

    case "LineChart":
      let foundLineObject = getSelectedChartFromPossibleVisualization(
        resp,
        type
      );
      let line_parameterXIdx =
        axisFlag === 0 || axisFlag === 2
          ? foundLineObject?.chartParameter[0]?.parameterValue.indexOf(xValue)
          : 0;
      let line_parameterYIdx =
        axisFlag === 0 || axisFlag === 2
          ? foundLineObject?.chartParameter[1]?.parameterValue.indexOf(yValue)
          : 0;
      let line_payload_visulaization = {
        id: getQueryIdFromCookies(),
        moduleId: selectedModuleId,
        userId: getUserDetailsFromCookies().id,
        chartTypeId: foundLineObject?.chartTypeId,
        chartType: foundLineObject?.chartType,
        chartParameter: [
          {
            parameterId: foundLineObject?.chartParameter[0]?.parameterId,
            parameterValueIndex: line_parameterXIdx,

            parameterType: "X-Axis",
            parameterValue:
              axisFlag === 1
                ? foundLineObject?.chartParameter[0]?.parameterValue[0]
                : xValue,
          },
          {
            parameterId: foundLineObject?.chartParameter[1]?.parameterId,
            parameterValueIndex: line_parameterYIdx,
            parameterType: "Y-Axis",
            parameterValue:
              axisFlag === 1
                ? foundLineObject?.chartParameter[1]?.parameterValue[0]
                : yValue,
          },
          {
            parameterId: foundLineObject?.chartParameter[2]?.parameterId,
            parameterValueIndex: line_parameterYIdx,
            parameterType: "Legend",
            parameterValue:
              axisFlag === 1
                ? foundLineObject?.chartParameter[1]?.parameterValue[0]
                : yValue,
          },
        ],
      };
      let lineresponse = await getLineChartVisualizationData(
        type,
        line_payload_visulaization
      );
      if (!lineresponse.isError) {
        return {
          visulaization_type_payload: line_payload_visulaization,
          possibleVisualization: foundLineObject,
          response: lineresponse,
        };
      } else {
        return lineresponse;
      }
    case "PieChart":
      let foundPieObject = getSelectedChartFromPossibleVisualization(
        resp,
        type
      );
      let pie_parameterXIdx =
        axisFlag === 0 || axisFlag === 2
          ? foundPieObject?.chartParameter[0]?.parameterValue.indexOf(xValue)
          : 0;
      let pie_parameterYIdx =
        axisFlag === 0 || axisFlag === 2
          ? foundPieObject?.chartParameter[1]?.parameterValue.indexOf(yValue)
          : 0;
      let pie_payload_visulaization = {
        id: getQueryIdFromCookies(),
        moduleId: selectedModuleId,
        userId: getUserDetailsFromCookies().id,
        chartTypeId: foundPieObject?.chartTypeId,
        chartType: foundPieObject?.chartType,
        chartParameter: [
          {
            parameterId: foundPieObject?.chartParameter[0]?.parameterId,
            parameterValueIndex: pie_parameterXIdx,
            parameterType: "Legend",
            parameterValue:
              axisFlag === 1
                ? foundPieObject?.chartParameter[0]?.parameterValue[0]
                : xValue,
          },
          {
            parameterId: foundPieObject?.chartParameter[1]?.parameterId,
            parameterValueIndex: pie_parameterYIdx,
            parameterType: "Values",
            parameterValue:
              axisFlag === 1
                ? foundPieObject?.chartParameter[1]?.parameterValue[0]
                : yValue,
          },
        ],
      };
      let pieresponse = await gePieChartVisualizationData(
        type,
        pie_payload_visulaization
      );
      if (!pieresponse.isError) {
        return {
          visulaization_type_payload: pie_payload_visulaization,
          possibleVisualization: foundPieObject,
          response: pieresponse,
        };
      } else {
        return pieresponse;
      }

    case "RadarChart":
      let foundRadarObject = getSelectedChartFromPossibleVisualization(
        resp,
        type
      );
      let radar_parameterXIdx =
        axisFlag === 0 || axisFlag === 2
          ? foundRadarObject?.chartParameter[0]?.parameterValue.indexOf(xValue)
          : 0;
      let radar_parameterYIdx =
        axisFlag === 0 || axisFlag === 2
          ? foundRadarObject?.chartParameter[1]?.parameterValue.indexOf(yValue)
          : 0;
      let radar_payload_visulaization = {
        id: getQueryIdFromCookies(),
        moduleId: selectedModuleId,
        userId: getUserDetailsFromCookies().id,
        chartTypeId: foundRadarObject?.chartTypeId,
        chartType: foundRadarObject?.chartType,
        chartParameter: [
          {
            parameterId: foundRadarObject?.chartParameter[0]?.parameterId,
            parameterValueIndex: radar_parameterXIdx,
            parameterType: "X-Axis",
            parameterValue:
              axisFlag === 1
                ? foundRadarObject?.chartParameter[0]?.parameterValue[0]
                : xValue,
          },
          {
            parameterId: foundRadarObject?.chartParameter[1]?.parameterId,
            parameterValueIndex: radar_parameterYIdx,
            parameterType: "Y-Axis",
            parameterValue:
              axisFlag === 1
                ? foundRadarObject?.chartParameter[1]?.parameterValue[0]
                : yValue,
          },
        ],
      };
      console.log(
        "DATATA RadarChart payload_visulaization",
        radar_payload_visulaization
      );
      let radarResp = await getRadarChartVisualizationData(
        "RadarChart",
        radar_payload_visulaization
      );
      if (!radarResp.isError) {
        return {
          visulaization_type_payload: radar_payload_visulaization,
          possibleVisualization: foundRadarObject,
          response: radarResp,
        };
      } else {
        return radarResp;
      }

    case "AreaChart":
      let foundAreaObject = getSelectedChartFromPossibleVisualization(
        resp,
        type
      );
      let area_parameterXIdx =
        axisFlag === 0 || axisFlag === 2
          ? foundAreaObject?.chartParameter[0]?.parameterValue.indexOf(xValue)
          : 0;
      let area_parameterYIdx =
        axisFlag === 0 || axisFlag === 2
          ? foundAreaObject?.chartParameter[1]?.parameterValue.indexOf(yValue)
          : 0;
      let area_payload_visulaization = {
        id: getQueryIdFromCookies(),
        moduleId: selectedModuleId,
        userId: getUserDetailsFromCookies().id,
        chartTypeId: foundAreaObject?.chartTypeId,
        chartType: foundAreaObject?.chartType,
        chartParameter: [
          {
            parameterId: foundAreaObject?.chartParameter[0]?.parameterId,
            parameterValueIndex: area_parameterXIdx,
            parameterType: "X-Axis",
            parameterValue:
              axisFlag === 1
                ? foundAreaObject?.chartParameter[0]?.parameterValue[0]
                : xValue,
          },
          {
            parameterId: foundAreaObject?.chartParameter[1]?.parameterId,
            parameterValueIndex: area_parameterYIdx,
            parameterType: "Y-Axis",
            parameterValue:
              axisFlag === 1
                ? foundAreaObject?.chartParameter[1]?.parameterValue[0]
                : yValue,
          },
        ],
      };
      console.log(
        "DATATA AreaChart payload_visulaization",
        area_payload_visulaization
      );
      let areaResp = await getAreaChartVisualizationData(
        "AreaChart",
        area_payload_visulaization
      );
      if (!areaResp.isError) {
        return {
          visulaization_type_payload: area_payload_visulaization,
          possibleVisualization: foundAreaObject,
          response: areaResp,
        };
      } else {
        return areaResp;
      }

    case "ScatterPlot":
      let foundScatterPlotObject = resp.find(
        (obj: any) => obj.chartType === type
      );
      let scatterPlot_parameterXIdx =
        foundScatterPlotObject?.chartParameter[0]?.parameterValue.indexOf(
          xValue
        );
      let scatterPlot_parameterYIdx =
        foundScatterPlotObject?.chartParameter[1]?.parameterValue.indexOf(
          yValue
        );
      let scatterPlot_payload_visulaization = {
        id: getQueryIdFromCookies(),
        moduleId: selectedModuleId,
        userId: getUserDetailsFromCookies().id,
        chartTypeId: foundScatterPlotObject?.chartTypeId,
        chartType: foundScatterPlotObject?.chartType,
        chartParameter: [
          {
            parameterId: foundScatterPlotObject?.chartParameter[0]?.parameterId,
            parameterValueIndex:
              scatterPlot_parameterXIdx !== -1
                ? scatterPlot_parameterXIdx
                : parseInt(states?.chartParameter.split(",")[0]),
            parameterType: "X-Axis",
            parameterValue:
              xValue === ""
                ? foundScatterPlotObject?.chartParameter[0]?.parameterValue[
                    parseInt(states?.chartParameter.split(",")[0])
                  ]
                : xValue,
          },
          {
            parameterId: foundScatterPlotObject?.chartParameter[1]?.parameterId,
            parameterValueIndex:
              scatterPlot_parameterYIdx !== -1
                ? scatterPlot_parameterYIdx
                : parseInt(states?.chartParameter.split(",")[1]),
            parameterType: "Y-Axis",
            parameterValue:
              yValue === ""
                ? foundScatterPlotObject?.chartParameter[1]?.parameterValue[
                    parseInt(states?.chartParameter.split(",")[1])
                  ]
                : yValue,
          },
          {
            parameterId: foundScatterPlotObject?.chartParameter[2]?.parameterId,
            parameterValueIndex:
              scatterPlot_parameterYIdx !== -1
                ? scatterPlot_parameterYIdx
                : parseInt(states?.chartParameter.split(",")[1]),
            parameterType: "Legend",
            parameterValue:
              yValue === ""
                ? foundScatterPlotObject?.chartParameter[1]?.parameterValue[
                    parseInt(states?.chartParameter.split(",")[1])
                  ]
                : yValue,
          },
        ],
      };
      let scatterPlotresponse = await getScatterPlotVisualizationData(
        type,
        scatterPlot_payload_visulaization
      );
      if (!scatterPlotresponse.isError) {
        return {
          visulaization_type_payload: scatterPlot_payload_visulaization,
          possibleVisualization: foundScatterPlotObject,
          response: scatterPlotresponse,
        };
      } else {
        return scatterPlotresponse;
      }
    case "AreaBumpChart":
      let foundAreaBumpObject = getSelectedChartFromPossibleVisualization(
        resp,
        type
      );
      let areaBump_parameterXIdx =
        axisFlag === 0 || axisFlag === 2
          ? foundAreaBumpObject?.chartParameter[0]?.parameterValue.indexOf(
              xValue
            )
          : 0;
      let areaBump_parameterYIdx =
        axisFlag === 0 || axisFlag === 2
          ? foundAreaBumpObject?.chartParameter[1]?.parameterValue.indexOf(
              yValue
            )
          : 0;
      console.log("foundAreaBumpObject AreaBumpChart", foundAreaBumpObject);
      let areaBump_payload_visulaization = {
        id: getQueryIdFromCookies(),
        moduleId: selectedModuleId,
        userId: getUserDetailsFromCookies().id,
        chartTypeId: foundAreaBumpObject?.chartTypeId,
        chartType: foundAreaBumpObject?.chartType,
        chartParameter: [
          {
            parameterId: foundAreaBumpObject?.chartParameter[0]?.parameterId,
            parameterValueIndex: areaBump_parameterXIdx,
            parameterType: "X-Axis",
            parameterValue:
              axisFlag === 1
                ? foundAreaBumpObject?.chartParameter[0]?.parameterValue[0]
                : xValue,
          },
          {
            parameterId: foundAreaBumpObject?.chartParameter[1]?.parameterId,
            parameterValueIndex: areaBump_parameterYIdx,
            parameterType: "Y-Axis",
            parameterValue:
              axisFlag === 1
                ? foundAreaBumpObject?.chartParameter[1]?.parameterValue[0]
                : yValue,
          },
          {
            parameterId: foundAreaBumpObject?.chartParameter[2]?.parameterId,
            parameterValueIndex: areaBump_parameterYIdx,
            parameterType: "Legend",
            parameterValue:
              axisFlag === 1
                ? foundAreaBumpObject?.chartParameter[1]?.parameterValue[0]
                : yValue,
          },
        ],
      };
      console.log(
        "DATATA AreaBumpChart areaBump_payload_visulaization",
        areaBump_payload_visulaization
      );
      let areaBumpresponse = await getAreaBumpVisualizationData(
        type,
        areaBump_payload_visulaization
      );
      if (!areaBumpresponse.isError) {
        return {
          visulaization_type_payload: areaBump_payload_visulaization,
          possibleVisualization: foundAreaBumpObject,
          response: areaBumpresponse,
        };
      } else {
        return areaBumpresponse;
      }
    case "BumpChart":
      let foundBumpObject = getSelectedChartFromPossibleVisualization(
        resp,
        type
      );
      let bump_parameterXIdx =
        axisFlag === 0 || axisFlag === 2
          ? foundBumpObject?.chartParameter[0]?.parameterValue.indexOf(xValue)
          : 0;
      let bump_parameterYIdx =
        axisFlag === 0 || axisFlag === 2
          ? foundBumpObject?.chartParameter[1]?.parameterValue.indexOf(yValue)
          : 0;
      console.log("foundAreaBumpObject AreaBumpChart", foundBumpObject);
      let bump_payload_visulaization = {
        id: getQueryIdFromCookies(),
        moduleId: selectedModuleId,
        userId: getUserDetailsFromCookies().id,
        chartTypeId: foundBumpObject?.chartTypeId,
        chartType: foundBumpObject?.chartType,
        chartParameter: [
          {
            parameterId: foundBumpObject?.chartParameter[0]?.parameterId,
            parameterValueIndex: bump_parameterXIdx,
            parameterType: "X-Axis",
            parameterValue:
              axisFlag === 1
                ? foundBumpObject?.chartParameter[0]?.parameterValue[0]
                : xValue,
          },
          {
            parameterId: foundBumpObject?.chartParameter[1]?.parameterId,
            parameterValueIndex: bump_parameterYIdx,
            parameterType: "Y-Axis",
            parameterValue:
              axisFlag === 1
                ? foundBumpObject?.chartParameter[1]?.parameterValue[0]
                : yValue,
          },
          {
            parameterId: foundBumpObject?.chartParameter[2]?.parameterId,
            parameterValueIndex: bump_parameterYIdx,
            parameterType: "Legend",
            parameterValue:
              axisFlag === 1
                ? foundBumpObject?.chartParameter[1]?.parameterValue[0]
                : yValue,
          },
        ],
      };
      let bumpresponse = await getAreaBumpVisualizationData(
        type,
        bump_payload_visulaization
      );
      if (!bumpresponse.isError) {
        return {
          visulaization_type_payload: bump_payload_visulaization,
          possibleVisualization: foundBumpObject,
          response: bumpresponse,
        };
      } else {
        return bumpresponse;
      }
  }
};

export const getVisualizationType = async () => {
  return await getData(`${GetVisualizationType}`).then((result) => {
    return result;
  });
};
export const getBoxPlotVisualizationData = async (
  type: any,
  ChartParameterArray: any
) => {
  console.log(
    "/${getUserDetailsFromCookies().identifier}/api/visualization",
    getQueryIdFromCookies(),
    ChartParameterArray
  );
  return await postData(
    `/${getUserDetailsFromCookies().identifier}/api/visualization`,
    {
      Id: getQueryIdFromCookies(),
      ChartType: type,
      ChartParameter: ChartParameterArray,
    }
  ).then((result) => {
    console.log("DATA SETS getVisualizationData", result);
    return result;
  });
};
export const getPieChartVisualizationData = async (
  type: any,
  ChartParameterArray: any
) => {
  console.log(
    "/${getUserDetailsFromCookies().identifier}/api/visualization",
    getQueryIdFromCookies(),
    ChartParameterArray
  );
  return await postData(
    `/${getUserDetailsFromCookies().identifier}/api/visualization`,
    {
      Id: getQueryIdFromCookies(),
      ChartType: type,
      ChartParameter: ChartParameterArray,
    }
  ).then((result) => {
    console.log("DATA SETS getVisualizationData", result);
    return result;
  });
};

export const getRadarChartVisualizationData = async (
  type: any,
  ChartParameterArray: any
) => {
  console.log(
    "/${getUserDetailsFromCookies().identifier}/api/visualization",
    getQueryIdFromCookies(),
    ChartParameterArray
  );
  return await postData(
    `/${getUserDetailsFromCookies().identifier}${CommonProject}${
      getUserDetailsFromCookies().projectId
    }${GetVisualizationResponse}`,
    ChartParameterArray
  ).then((result) => {
    console.log("DATA SETS getVisualizationData", result);
    return result;
  });
};

export const getAreaChartVisualizationData = async (
  type: any,
  ChartParameterArray: any
) => {
  console.log(
    "/${getUserDetailsFromCookies().identifier}/api/visualization",
    getQueryIdFromCookies(),
    ChartParameterArray
  );
  return await postData(
    `/${getUserDetailsFromCookies().identifier}${CommonProject}${
      getUserDetailsFromCookies().projectId
    }${GetVisualizationResponse}`,
    ChartParameterArray
  ).then((result) => {
    console.log("DATA SETS getVisualizationData", result);
    return result;
  });
};

export const getBarChartVisualizationData = async (
  type: any,
  ChartParameterArray: any
) => {
  console.log(
    "/${getUserDetailsFromCookies().identifier}/api/visualization",
    getQueryIdFromCookies(),
    ChartParameterArray
  );
  return await postData(
    `/${getUserDetailsFromCookies().identifier}${CommonProject}${
      getUserDetailsFromCookies().projectId
    }${GetVisualizationResponse}`,
    ChartParameterArray
  ).then((result) => {
    console.log("DATA SETS getVisualizationData", result);
    return result;
  });
};

export const getRadialChartVisualizationData = async (
  type: any,
  ChartParameterArray: any
) => {
  console.log(
    "/${getUserDetailsFromCookies().identifier}/api/visualization",
    getQueryIdFromCookies(),
    ChartParameterArray
  );
  return await postData(
    `/${getUserDetailsFromCookies().identifier}${CommonProject}${
      getUserDetailsFromCookies().projectId
    }${GetVisualizationResponse}`,
    ChartParameterArray
  ).then((result) => {
    console.log("DATA SETS getVisualizationData", result);
    return result;
  });
};

export const getCardChartVisualizationData = async (
  type: any,
  ChartParameterArray: any
) => {
  console.log(
    "/${getUserDetailsFromCookies().identifier}/api/visualization",
    getQueryIdFromCookies(),
    ChartParameterArray
  );
  return await postData(
    `/${getUserDetailsFromCookies().identifier}${CommonProject}${
      getUserDetailsFromCookies().projectId
    }${GetVisualizationResponse}`,
    ChartParameterArray
  ).then((result) => {
    console.log("DATA SETS getVisualizationData", result);
    return result;
  });
};

export const getTableChartVisualizationData = async (
  type: any,
  ChartParameterArray: any
) => {
  console.log(
    "/${getUserDetailsFromCookies().identifier}/api/visualization",
    getQueryIdFromCookies(),
    ChartParameterArray
  );
  return await postData(
    `/${getUserDetailsFromCookies().identifier}${CommonProject}${
      getUserDetailsFromCookies().projectId
    }${GetVisualizationResponse}`,
    ChartParameterArray
  ).then((result) => {
    console.log("DATA SETS getVisualizationData", result);
    return result;
  });
};

export const getLineChartVisualizationData = async (
  type: any,
  ChartParameterArray: any
) => {
  console.log(
    "/${getUserDetailsFromCookies().identifier}/api/visualization",
    getQueryIdFromCookies(),
    ChartParameterArray
  );
  return await postData(
    `/${getUserDetailsFromCookies().identifier}${CommonProject}${
      getUserDetailsFromCookies().projectId
    }${GetVisualizationResponse}`,
    ChartParameterArray
  ).then((result) => {
    console.log("DATA SETS getVisualizationData", result);
    return result;
  });
};
export const gePieChartVisualizationData = async (
  type: any,
  ChartParameterArray: any
) => {
  return await postData(
    `/${getUserDetailsFromCookies().identifier}${CommonProject}${
      getUserDetailsFromCookies().projectId
    }${GetVisualizationResponse}`,
    ChartParameterArray
  ).then((result) => {
    console.log("DATA SETS getVisualizationData", result);
    return result;
  });
};
export const getScatterPlotVisualizationData = async (
  type: any,
  ChartParameterArray: any
) => {
  return await postData(
    `/${getUserDetailsFromCookies().identifier}${CommonProject}${
      getUserDetailsFromCookies().projectId
    }${GetVisualizationResponse}`,
    ChartParameterArray
  ).then((result) => {
    console.log("DATA SETS getVisualizationData", result);
    return result;
  });
};
export const getScatterPlotVisualizationData1 = async (
  type: any,
  ChartParameterArray: any
) => {
  console.log(
    "/${getUserDetailsFromCookies().identifier}/api/visualization",
    getQueryIdFromCookies(),
    ChartParameterArray
  );
  return await postData(
    `/${getUserDetailsFromCookies().identifier}/api/visualization`,
    {
      Id: getQueryIdFromCookies(),
      ChartType: type,
      ChartParameter: ChartParameterArray,
    }
  ).then((result) => {
    console.log("DATA SETS getVisualizationData", result);
    return result;
  });
};
export const getAreaBumpVisualizationData = async (
  type: any,
  ChartParameterArray: any
) => {
  console.log(
    "/${getUserDetailsFromCookies().identifier}/api/visualization",
    getQueryIdFromCookies(),
    ChartParameterArray
  );
  return await postData(
    `/${getUserDetailsFromCookies().identifier}${CommonProject}${
      getUserDetailsFromCookies().projectId
    }${GetVisualizationResponse}`,
    ChartParameterArray
  ).then((result) => {
    console.log("DATA SETS getAreaBumpVisualizationData", result);
    return result;
  });
};

export const getVisualizationData = async (type: any) => {
  console.log(
    "/${getUserDetailsFromCookies().identifier}/api/visualization",
    getQueryIdFromCookies()
  );
  return await postData(
    `/${getUserDetailsFromCookies().identifier}${CommonProject}${
      getUserDetailsFromCookies().projectId
    }${GetPossibleVisualization}`,
    {
      Id: getQueryIdFromCookies(),
      ChartType: type,
      ChartParameter: [
        {
          ParameterType: "SegmentNames",
          ParameterValue: "balance",
        },
        {
          ParameterType: "SegmentValues",
          ParameterValue: "first_name",
        },
      ],
    }
  ).then((result) => {
    console.log("DATA SETS getVisualizationData", result);
    return result;
  });
};
export const getVisualizationState = async () => {
  return await postData(
    `/${getUserDetailsFromCookies().identifier}${CommonProject}${
      getUserDetailsFromCookies().projectId
    }${GetVisualizationState}`,
    { queryId: getQueryIdFromCookies(), userId: getUserDetailsFromCookies().id }
  ).then((result) => {
    console.log("Result of Statws", result);
    return result.result;
  });
};
export const getPossibalVisualization = async (id: number) => {
  return await postData(
    // `/${getUserDetailsFromCookies().identifier}${CommonProject}${
    //   getUserDetailsFromCookies().projectId
    // }${GetPossibleVisualization}`,
    `/visualization/${getUserDetailsFromCookies().identifier}/query/${id}`,
    { queryId: id, userId: getUserDetailsFromCookies().id }
  ).then((result) => {
    console.log("BarChart getPossibalVisualization ", result);
    return result.result;
  });
};

export const getDates = async () => {
  return await getData(`${Date}`).then((result) => {
    return result;
  });
};
export const getMonths = async () => {
  return await getData(`${Month}`).then((result) => {
    return result;
  });
};
export const getDays = async () => {
  return await getData(`${Day}`).then((result) => {
    return result;
  });
};
export const getrecurrencetype = async () => {
  return await getData(`${recurrencetype}`).then((result) => {
    return result;
  });
};
export function useCompanyService() {
  return [];
}
export class ProjectResult {
  id: number | undefined;
  industrySize: string | undefined;
  isActive: boolean | undefined;
  industryType: string | undefined;
  countryName: string | undefined;
  phoneCode: string | undefined;
  name: string | undefined;
  flag: string | undefined;
  cityName: string | undefined;
  stateId: string | undefined;
  constructor(data: any) {
    Object.assign(this, {
      id: data.id,
      isActive: data.isActive,
      industrySize: data.industrySize,
      industryType: data.industryType,
      countryName: data.countryName,
      phoneCode: data.phoneCode,
      name: data.name,
      flag: data.flag,
      cityName: data.cityName,
      stateId: data.stateId,
    });
  }
}


