import React, { useContext, useState } from "react";
import styles from "./client_dashboard.module.scss";
import { ThemeContext } from "../../context/ThemeContext";
function ClientDashboard() {
  const { theme, setTheme } = useContext(ThemeContext);
  const [isAuthenticated, setisAuthenticated] = useState(true);
  console.log("Them Context details", theme);
  return (
    <div className={`${styles.dashboard_container} ${styles[theme]}`}>
      <p className={`${styles["text"]}`}>Wings BI</p>
      {/* <button onClick={() => setTheme(theme === "light" ? "dark" : "light")}>
        Switch Theme
      </button> */}
      <div className="card">
        <div className="card-body">
          <h5 className="card-title">Welcome !!!</h5>
          <p className="card-text">
            React Demo Application protected by OpenId Connect
          </p>
          {!isAuthenticated && (
            <button
              type="button"
              className="btn btn-primary"
              //onClick={() => login("/company")}
            >
              Login
            </button>
          )}
          {isAuthenticated && (
            <button
              type="button"
              className="btn btn-primary"
              //onClick={() => logout()}
            >
              logout
            </button>
          )}
          {isAuthenticated && (
            <button
              type="button"
              className="btn btn-primary"
              // onClick={() => renewTokens()}
            >
              renewTokens
            </button>
          )}
          {/* <p className="card-text">{JSON.stringify(accessToken)}</p>
          <p className="card-text">{JSON.stringify(accessTokenPayload)}</p> */}
        </div>
      </div>
    </div>
  );
}

export default ClientDashboard;
