import React, { useState, useEffect } from "react";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  IconButton,
  Menu,
  MenuItem,
  styled,
  tableCellClasses,
  Button,
  Box,
  Modal,
} from "@mui/material";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import { useDatabaseStyle } from "./DatabaseStyle";
import { getUserDetailsFromCookies } from "../../../../services";
import { dogetdatabasepro, doDeleteDatabase } from "./DatabaseService";
import clsx from "clsx";
import EditDatabase from "./EditDatabase";

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: "#F6F8FF",
    color: "#000",
    fontSize: 14,
    fontWeight: "bold",
    border: "1px solid",
    padding: 4,
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 12,
    padding: 0,
  },
  textAlign: "center",
  cursor: "pointer",
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
  "&:nth-of-type(odd)": {
    backgroundColor: "#fff",
  },
  // hide last border
  "&:last-child td, &:last-child th": {
    border: 0,
  },
}));

interface DatabasesListProps {
  projectDetails: any;
}

const DatabasesList: React.FC<DatabasesListProps> = ({ projectDetails }) => {
  const [databaseList, setDatabaseList] = useState<any[]>([]);
  const [isLoading, setIsLoading] = useState(true);
  const [databaseAnchorEl, setDatabaseAnchorEl] = useState<null | HTMLElement>(
    null
  );
  const [selectedDatabaseIndex, setSelectedDatabaseIndex] = useState<
    number | null
  >(null);
  const [
    isDeleteDatabaseConfirmationOpen,
    setIsDeleteDatabaseConfirmationOpen,
  ] = useState(false);
  const [editDatabaseVisible, setEditDatabaseVisible] =
    useState<boolean>(false);
  const [selectedDatabaseId, setSelectedDatabaseId] =
    useState<DatabaseIdType | null>(null);
  const [selectedDatabase, setSelectedDatabase] = useState<any | null>(null);
  const [selectedDbRowIndex, setSelectedDbRowIndex] = useState(0);
  const [editDatabaseId, setEditDatabaseId] = useState<number | null>(null);
  const userRole = getUserDetailsFromCookies().roleName;
  const { classes } = useDatabaseStyle(userRole)();

  useEffect(() => {
    if (projectDetails) {
      fetchDatabases();
    }
  }, [projectDetails]);

  const fetchDatabases = async () => {
    setIsLoading(true);
    try {
      const databases = await dogetdatabasepro(projectDetails.id);
      setDatabaseList(databases);
    } catch (error) {
      console.error("Error fetching databases:", error);
    }
    setIsLoading(false);
  };

  const handleDatabaseMenuOpen = (
    event: React.MouseEvent<HTMLElement>,
    index: number
  ) => {
    setDatabaseAnchorEl(event.currentTarget);
    setSelectedDbRowIndex(index); // Update selected row index here
  };
  
  const handleEditDatabaseOpen = () => {
    const selectedDatabase = databaseList[selectedDbRowIndex];
    setDatabaseAnchorEl(null);
   setSelectedDatabase (selectedDatabase);
    setEditDatabaseVisible(true);
  };

  const handleDatabaseMenuClose = () => {
    setDatabaseAnchorEl(null);
    setSelectedDatabaseIndex(null);
  };

  const handleDeleteDatabase = () => {
    const projectId = projectDetails.id;
    const databaseId = databaseList[selectedDbRowIndex].id;

    console.log("Deleting database with ID:", databaseId, "from project:", projectId);

    doDeleteDatabase(projectId, databaseId)
      .then((response) => {
        if (response.isError) {
          console.error("Error deleting database:", response.message);
          return;
        }
        // Update the database list to remove the deleted database
        const updatedDatabaseList = databaseList.filter(
          (database) => database.id !== databaseId
        );
        setDatabaseList(updatedDatabaseList);
        setIsDeleteDatabaseConfirmationOpen(false);
      })
      .catch((error) => console.error("Error during database deletion:", error));
  };

  const handleCancelDelete = () => {
    setIsDeleteDatabaseConfirmationOpen(false);
  };


  const closeDatabasePage = () => {
    setEditDatabaseVisible(false);
    setDatabaseAnchorEl(null);
    fetchDatabases(); 
  };

  const handleSelectDatabase = (database: any, index: number) => {
    if (databaseAnchorEl === null) {
      console.log("Clicked row: ", index);
      setSelectedDatabase(database);
      setSelectedDbRowIndex(index);
    }
  };

  type DatabaseIdType = number;

  if (isLoading) {
    return (
      <div
        style={{
          color: "#007bff",
          fontSize: "18px",
          textAlign: "center",
          padding: "20px",
          marginTop: "5%",
        }}
      >
        Loading databases...
      </div>
    );
  }

  return (
    <div className={classes.parent}>
      {editDatabaseVisible && selectedDatabase && (
        <EditDatabase
          projectId={projectDetails.id} // Pass the projectId
          databaseDetails={selectedDatabase}
          databaseId={selectedDatabase.id} // Pass the databaseId
          closeDatabaseModal={closeDatabasePage}
        />
      )}

      {isDeleteDatabaseConfirmationOpen && (
        <Modal
          open={isDeleteDatabaseConfirmationOpen}
          onClose={handleCancelDelete}
          aria-labelledby="delete-confirmation-title"
          aria-describedby="delete-confirmation-description"
        >
          <Box
            sx={{
              position: "absolute" as "absolute",
              top: "50%",
              left: "50%",
              transform: "translate(-50%, -50%)",
              width: 400,
              display: "flex",
              flexDirection: "column",
              bgcolor: "background.paper",
              justifyContent: "center",
              boxShadow: 24,
              borderRadius: "5px",
              pt: 2,
              px: 4,
              pb: 3,
            }}
          >
            <h4
              style={{
                textAlign: "center",
              }}
              id="child-modal-title"
            >
              Do you want to delete this Database?
            </h4>
            <div className={clsx(classes.columnDeleteBtncontainer)}>
              <div>
                <Button
                  onClick={handleCancelDelete}
                  variant="contained"
                  className={classes.columnDeleteBtnLabelStyle}
                >
                  No
                </Button>
                <Button
                  onClick={() => {
                    handleDeleteDatabase();
                    setIsDeleteDatabaseConfirmationOpen(false);
                  }}
                  variant="contained"
                  className={classes.columnDeleteBtnYes}
                >
                  Yes
                </Button>
              </div>
            </div>
          </Box>
        </Modal>
      )}
      <TableContainer className={classes.dblist}>
        <Table sx={{ marginTop: "2.5%" }}>
          <TableHead>
            <TableRow>
              <StyledTableCell>Sr. no</StyledTableCell>
              <StyledTableCell>Database</StyledTableCell>
              <StyledTableCell>Hostname</StyledTableCell>
              <StyledTableCell>Database Type</StyledTableCell>
              <StyledTableCell>Provider</StyledTableCell>
              <StyledTableCell>Created By</StyledTableCell>
              <StyledTableCell>Created Date</StyledTableCell>
              <StyledTableCell>Action</StyledTableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {databaseList.map((database, index) => (
              <StyledTableRow
                key={database.id}
                onClick={() => handleSelectDatabase(database, index)}
                style={{
                  backgroundColor:
                    index === selectedDbRowIndex ? "#cce6ff" : "white",
                }}
              >
                <StyledTableCell>{index + 1}</StyledTableCell>
                <StyledTableCell>{database.name}</StyledTableCell>
                <StyledTableCell>{database.hostName}</StyledTableCell>
                <StyledTableCell>{database.databaseTypeName}</StyledTableCell>
                <StyledTableCell>
                  {database.databaseProviderName}
                </StyledTableCell>
                <StyledTableCell>{database.createdbyName}</StyledTableCell>
                <StyledTableCell>
                  {new Date(database.createdDate).toLocaleDateString()}
                </StyledTableCell>
                <StyledTableCell>
                  <IconButton
                    aria-label="more"
                    aria-controls={`project-menu-${index}`}
                    aria-haspopup="true"
                    onClick={(e) => handleDatabaseMenuOpen(e, index)}
                  >
                    <MoreVertIcon />
                  </IconButton>

                  <Menu
                    id={`project-menu-${index}`}
                    anchorEl={databaseAnchorEl}
                    keepMounted
                    open={Boolean(databaseAnchorEl)}
                    onClose={handleDatabaseMenuClose}
                  >
                    <MenuItem
                      onClick={() => {
                        handleEditDatabaseOpen();
                      }}
                      disabled={
                        getUserDetailsFromCookies().roleName === "Viewer"
                          ? true
                          : false
                      }
                    >
                      Edit
                    </MenuItem>
                    <MenuItem
                      onClick={() => {
                        setIsDeleteDatabaseConfirmationOpen(true);
                      }}
                      disabled={
                        getUserDetailsFromCookies().roleName === "Contributor" ||
                        getUserDetailsFromCookies().roleName === "Viewer"
                          ? true
                          : false
                      }
                    >
                      Delete
                    </MenuItem>
                  </Menu>
                </StyledTableCell>
              </StyledTableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </div>
  );
};

export default DatabasesList;
