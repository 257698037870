import {
  React,
  Drawer,
  List,
  ListItem,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  styled,
  useState,
  useLocation,
  ChevronRight,
  ChevronLeft,
  IconButton,
  useTheme,
  Button,
  clsx,
  AppBar,
  useMemo,
  Box,
  Typography,
  CssBaseline,
  Toolbar,
  Divider,
  Mail,
  Inbox,
  Routes,
  Route,
  useNavigate,
  Search,
  ExpandMore,
  Modal,
  useContext,
  AppStatesContainer,
  useEffect,
  Popover,
  useRef,
  Close,
  Fade,
  Backdrop,
  useMsal,
} from "../../../app.module";
import {
  Theme,
  CSSObject,
  AppBarProps,
  Menu,
  MenuItem,
  InputLabel,
} from "@mui/material";
import Tooltip from "@mui/material/Tooltip";
import { Fonts } from "../../constants";
import {
  useStyle,
  Main,
  DrawerHeader,
  // popOutStyle,
  WingsBIAppBar,
  StyledInputBase,
} from "./CustomDrawerStyle";
import { Images, Colors } from "../../constants";
import { getUserDetailsFromCookies } from "../../services";
import { ModuleList, CompanyDetails } from "../../components";
import NavigationPath from "../navigation_history/NavigationPath";
// import ObservableCharts from "../../components/modules/ObservableCharts";
import D3Chart from "../../components/modules/D3Chart";
// import Charts from "../../components/modules/Charts";
// import SequenceSunburst from "../../components/modules/SequenceSunburst";
import BarChart from "../../components/modules/nivocharts/BarChart";
import PieChart from "../../components/modules/nivocharts/PieChart";
import LineChart from "../../components/modules/nivocharts/LineChart";
import HeatMap from "../../components/modules/nivocharts/HeatMap";
import ScatterPlot from "../../components/modules/nivocharts/ScatterPlot";
// import GeoMap from "../../components/modules/nivocharts/GeoMap";
import BoxPlot from "../../components/modules/nivocharts/BoxPlot";
import ProjectsList from "../../components/Main_Project/project/ProjectsList";
// import HomePage from "../../components/LandingPage/HomePage";
import MainProject from "../../components/Main_Project/MainProject";
import User from "../../components/user/user";
import LineChartSeries from "../../components/modules/nivocharts/LineChartSeries";
import RadialChart from "../../components/modules/nivocharts/RadialChart";
import AnalyzeModules from "../../components/modules/nivocharts/AnalyzeModules";
import CreateDashboardFromModules from "../../components/modules/CreateDashboardFromModules";
import Cookies from "js-cookie";
import DashboardList from "../../components/chart_dashboard/DashboardList";
import RadarChart from "../../components/modules/nivocharts/RadarrChart";
import { ShowDashboard } from "../../constants/Endpoints";
import DashboardShow from "../../components/chart_dashboard/DashboardDesc";
import ChatBot from "../../components/chatbot/ChatBot";
import Organization from "../../components/Organization_Details/Organization";
import CreateDashboard from "../../components/modules/nivocharts/CreateDashboardFromModulesUpdated";
import EditDashboard from "../../components/modules/nivocharts/EditButtonCreateDashboardFromModulesUpdated";
import ChatBot1 from "../../components/custom_ChatBot/ChatBot1";
import LandingPage from "../../components/HomePage/LandingPage";




const drawerWidth = 160;
const openedMixin = (theme: Theme): CSSObject => ({
  width: drawerWidth,
  transition: theme.transitions.create("width", {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.enteringScreen,
  }),
  overflowX: "hidden",
});

const closedMixin = (theme: Theme): CSSObject => ({
  transition: theme.transitions.create("width", {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  overflowX: "hidden",
  width: `calc(${theme.spacing(8.5)} + 1px)`, // spacing between drawer and Header bar
  [theme.breakpoints.up("sm")]: {
    width: `calc(${theme.spacing(8.5)} + 1px)`,
  },
});
const customTooltipStyle = {
  backgroundColor: "blue", // Change the background color
  color: "white", // Change the font color
  fontSize: "16px", // Change the font size
};
const SwipableDrawer = styled(Drawer, {
  shouldForwardProp: (prop) => prop !== "open",
})(({ theme, open }) => ({
  width: drawerWidth,
  flexShrink: 0,
  whiteSpace: "nowrap",
  boxSizing: "border-box",
  ...(open && {
    ...openedMixin(theme),
    "& .MuiDrawer-paper": openedMixin(theme),
  }),
  ...(!open && {
    ...closedMixin(theme),
    "& .MuiDrawer-paper": closedMixin(theme),
  }),
}));



const CustomDrawer = () => {
  const theme = useTheme();
  const { instance } = useMsal();
  const { classes } = useStyle();
  const navigate = useNavigate();
  const [open, setOpen] = React.useState(false);
  const [currentIndex, setCurrentIndex] = useState(1); // here 1 is the current index of drawerItems
  const [previousIndex, setPreviousIndex] = useState(1);

  const [searchText, setSearchText] = useState("");
  const inputRef = useRef(null); // Reference to the InputBase component
  const [anchorEl, setAnchorEl] = React.useState(null);
  const companyName = getUserDetailsFromCookies().companyName;
  const userRole = getUserDetailsFromCookies().roleName;
  const [isWindowOpen, setIsWindowOpen] = useState(false);
  const [searchResults, setSearchResults] = useState([
    { title: "sdfsdf1", id: 1 },
    { title: "sdfsdf", id: 1 },
    { title: "sdfsdf", id: 1 },
    { title: "sdfsdf", id: 1 },
    { title: "sdfsdf", id: 1 },
    { title: "sdfsdf", id: 1 },
  ]);
  // const [anchorEl, setAnchorEl] = useState(null);
  const [isInputFocused, setInputFocused] = useState(false);

  const location = useLocation();
  const isCreateDashboardPage = location.pathname.includes("createdashboard");
  const isEditDashboardPage = location.pathname.includes("editdashboard");


  const drawerItemsWithRoles = [
    {
      name: "",
      in_active_img: "",
      active_img: "",
      isActive: false,
      navigate: "",
      hover: "",
    },
    {
      name: "Project",
      in_active_img: Images.inactive_project,
      active_img: Images.active_project,
      isActive: true,
      navigate: "",
    },
    {
      name: "Dashboard",
      in_active_img: Images.inactive_module,
      active_img: Images.active_module,
      isActive: false,
      navigate: "Dashboard",
    },
    {
      name: "Modules",
      in_active_img: Images.inactive_home,
      active_img: Images.active_home,
      isActive: false,
      navigate: "modules",
    },
    {
      name: "Users",
      in_active_img: Images.inactive_user,
      active_img: Images.active_user,
      isActive: false,
      navigate: "user",
      roles: ["Company Admin", "Project Admin"],
    },
   
    {
      name: "ChatBot",
      in_active_img: Images.inactive_bot,
      active_img: Images.active_bot,
      isActive: false,
      navigate: "bot",
      roles: [""],
    },
    {
      name: "",
      in_active_img: "",
      active_img: "",
      isActive: false,
      navigate: "",
    },
  ];
  const filteredDrawerItems = drawerItemsWithRoles.filter(
    (item) => !item.roles || item.roles.includes(userRole)
  );
  const [drawerItems, setDrawerItems] = useState(filteredDrawerItems);
  const [modalOpen, setModalOpen] = useState(false);
  const [currentDashboardPath, setCurrrentDashboardPath] = useState([]);
  const path = useContext(AppStatesContainer);
  useEffect(() => {}, [open, currentDashboardPath]);
  useEffect(() => {
    setIsWindowOpen(true);
  }, []);
  const toggleWindow = () => {
    setIsWindowOpen(!isWindowOpen);
  };



  const handleModalOpen = () => {
    setModalOpen(true);
  };

  const handleModalClose = () => {
    setModalOpen(false);
  
  };

  const handleSignOut = () => {
    const cookieNames = Object.keys(Cookies.get());
    // Iterate through the cookie names and remove each cookie
    cookieNames.forEach((cookieName) => {
      console.log("Cookies resets", cookieNames);
      Cookies.remove(cookieName);
    });
    instance.logoutRedirect();
    //Cookies.remove("user_details");
    navigate("/");
  };
  const handleDrawerOpen = () => {
    setOpen(true);
  };

  const handleDrawerClose = () => {
    setOpen((prev) => !prev);
  };
  const handleDrawerMenu = (index: number) => {
    console.log("current Drawer Tab", path.pathState);
    let menus = drawerItems;
    let current_path = path.pathState.path;
    if (current_path) {
      current_path.push({ name: menus[index].navigate, isActive: true });
      path.pathAactions.setPathName(current_path);
      console.log("current paths paths", current_path);
    } else {
      path.pathAactions.setPathName([
        { name: menus[index].navigate, isActive: true },
      ]);
    }
    console.log("Selected current_path", current_path);
    setCurrrentDashboardPath(current_path);
    if (currentIndex != index) {
      setCurrentIndex(index);
      menus[index].isActive = true;
      menus[previousIndex].isActive = false;
      setDrawerItems([...menus]);
      setPreviousIndex(index);
      navigate(
        `/${getUserDetailsFromCookies()?.identifier}/projects/${
          menus[index].navigate
        }`
      );
    }
  };

  const handleBorderRadius = (index: number, isActive: boolean) => {
    if (index === 1 && currentIndex === 0) {
      return Colors?.background.backgroundColorTheme2;
    }
    return currentIndex - 1 === index || (currentIndex + 1 === index && index)
      ? Colors?.background.backgroundColorTheme3
      : Colors?.background.backgroundColorTheme2;

  };
  // Function to handle search input change
  const handleSearchChange = (event: any) => {
    const text = event.target.value;
    setSearchText(text);

    // Perform search logic here and update searchResults state accordingly
    // You can make API calls or filter data based on the search text
    const results = "sdfsdf";
    //performSearchLogic(text);
    setSearchResults([
      { title: "sdfsdf", id: 1 },
      { title: "sdfsdf", id: 1 },
      { title: "sdfsdf", id: 1 },
      { title: "sdfsdf", id: 1 },
      { title: "sdfsdf", id: 1 },
      { title: "sdfsdf", id: 1 },
    ]);
    // Open the popover when there are search results and the input is focused
    if (results.length > 0 && isInputFocused) {
      setAnchorEl(inputRef.current);
    } else {
      setAnchorEl(null);
    }
  };
  // Function to handle input focus
  const handleInputFocus = () => {
    setInputFocused(true);
  };
  // Function to handle input blur
  const handleInputBlur = () => {
    setInputFocused(false);
  };
  const capitalizeFirstLetter = (string: string): string => {
    return string.charAt(0).toUpperCase() + string.slice(1).toLowerCase();
  };
  const dashboardDescStyle = {
    // Define specific dimensions for DashboardDesc
    width: "auto", // or any specific width
    height: "auto", // or any specific height
    // ... any other style properties you need
  };
  const openImageUrl = "path/to/your/open-image.png";
  const minimizeImageUrl = "path/to/your/minimize-image.png";
  return (
    <>
      <Modal
        keepMounted
        open={modalOpen}
        onClose={handleModalClose}
        closeAfterTransition
        aria-labelledby="keep-mounted-modal-title"
        aria-describedby="keep-mounted-modal-description"
      >
        <Box
          sx={{
            position: "absolute" as "absolute",
            top: "9ch",
            left: "92%",
            transform: "translate(-50%, -50%)",
            width: 120,
            backgroundColor: "#fff",
            borderRadius: "10px",
            border: `1px solid ${Colors?.border.plan.borderColor}`,
            boxShadow: "0px 0px 4px rgba(0, 0, 0, 0.25)",
            p: 0.8,
            textAlign: "center",
          }}
        >
          {userRole === "Company Admin" && (
            <Button
              onClick={() => {
                navigate("Organization_Details");
                handleModalClose();
              }}
              className={classes.userActionTitle}
            >
              Subscription
            </Button>
          )}
          <Button
            onClick={() => {
              handleSignOut();
              console.log("sadfjahsdjkfh");
            }}
            className={classes.userActionTitle}
          >
            Sign Out
          </Button>
        </Box>
      </Modal>
      <Box
        className={classes.scrol}
        sx={{
          display: "flex",
          //minWidth: "1000px",
          height: "100vh",
          backgroundColor: Colors?.background.backgroundColorTheme3, // parent background color
          // Colors?.background.backgroundColorTheme3, // parent background color
          //Colors?.background.backgroundColorTheme2,
        }}
      >
        {/* {isWindowOpen ? (
        {/* {isWindowOpen ? (
          <div
            style={{
              position: "absolute",
              zIndex: 10,
              bottom: "19.5%",
              right: "6%",
              width: "26%",
            }}
          >
            <ChatBot1 />
          </div>
        ) : null} */}
        {/* <div
        ) : null} */}
        {/* <div
          style={{
            position: "absolute",
            zIndex: 10,
            bottom: "20px",
            right: "20px",
          }}
        >
          
            <img
              onClick={toggleWindow}
              src={isWindowOpen ? Images.Bot : Images.Bot}
              alt={isWindowOpen ? "Minimize" : "Open"}
              style={{ width: "65px", height: "65px", cursor:"pointer" }} // Adjust size as needed
            />
          
        </div> */}
       
        <CssBaseline />
        <WingsBIAppBar
          className={classes.appBarStyle}
          position="fixed"
          open={open}
        >
          <Toolbar className={classes.toolBarStyle}>
            <div
              style={{
                display: "flex",
                flex: 1,
                flexDirection: "column",
              }}
            >
              <div
                style={{
                  display: "flex",
                  flex: 1,
                  justifyContent: "space-between",
                }}
              >
                <div
                  style={{
                    display: "flex",
                    alignItems: "center",
                    position: "relative",
                  }}
                >
                  <Typography
                    style={{
                      fontFamily: Fonts.NunitoSans.regular,
                      fontSize: "22px",
                      color: "#2a54cc",
                      fontWeight: "bolder",
                      cursor: "pointer",
                      textTransform: "initial",
                    }}
                  >
                    {companyName}
                  </Typography>
                  <div
                    className={classes.appBarSearch}
                    style={{ marginTop: "12px" }}
                  >
                    <div className={classes.searchIconWraper}>
                      <Search
                        style={{
                          color: "#000",
                        }}
                      />
                    </div>
                    <StyledInputBase
                      onFocus={handleInputFocus}
                      onBlur={handleInputBlur}
                      inputRef={inputRef}
                      placeholder="Search…"
                      value={searchText}
                      onChange={handleSearchChange}
                      //inputProps={{ "aria-label": "search" }}
                      className={classes.searchInput}
                    />
                  </div>
                 
                </div>
                <div className={classes.userProfileContainer}>
                  <div className={classes.userProfileImg}>
                    <div className={classes.userProfileTxt}>
                      {getUserDetailsFromCookies().firstName[0].toUpperCase()}
                      {getUserDetailsFromCookies().lastName[0].toUpperCase()}
                    </div>
                  </div>
                  <div>
                    <Typography className={classes.userNameStyle}>
                      {/* {capitalizeFirstLetter(
                        getUserDetailsFromCookies().firstName
                      )}{" "} */}
                      {getUserDetailsFromCookies().firstName}{" "}
                      {getUserDetailsFromCookies().lastName}
                    </Typography>
                    <Typography className={classes.userRoleTitle}>
                      {getUserDetailsFromCookies().roleName || "Company Admin"}
                    </Typography>
                  </div>

                  <IconButton sx={{ padding: 0, paddingLeft: "2px" }}>
                    <ExpandMore
                      onClick={() => {
                        handleModalOpen();
                      }}
                      className={classes.userProfileDetailsCollaps}
                    />
                  </IconButton>
                </div>
              </div>
            </div>
          </Toolbar>
        </WingsBIAppBar>
        <SwipableDrawer
          classes={{
            paper: classes.drawerPaper,
          }}
          variant="permanent"
          open={open}
        >
          <div
            style={{
              //backgroundColor: Colors?.background.backgroundColorTheme3,
              backgroundColor: Colors?.background.backgroundColorTheme2,
            }}
            className={classes.logoContainer}
          >
            <div
              style={{
                width: drawerWidth,
                borderRadius: currentIndex === 0 ? "0px 0px 20px 0px" : "",
                backgroundColor: Colors?.background.backgroundColorTheme2,
              }}
            >
              <Button
                className={classes.logoBtnStyle}
                onClick={handleDrawerClose}
              >
                {open ? (
                  // <img
                  //   className={classes.logoStyle}
                  //   alt="Header Wingsbi logo"
                  //   src={Images.marketing_logo_name}
                  // />
                  <div
                    onClick={() => {
                      // handleScrollToTop();
                    }}
                    style={{
                      padding: 0,
                      display: "flex",
                      flex: 1,
                      justifyContent: "center",
                      alignItems: "center",
                    }}
                  >
                    <div>
                      <img
                        style={{
                          objectFit: "contain",
                          // marginTop: "1px",
                          marginRight: "3px",
                          height: "40px",
                          width: "55px",
                        }}
                        src={Images.logomark_svg}
                      />
                    </div>
                    <div
                      style={{
                        // backgroundColor: "red",
                        display: "flex",
                        flex: 1,
                        justifyContent: "center",
                        alignItems: "center",
                      }}
                    >
                      <InputLabel className={classes.logoTxtStyle}>
                        WingsBI
                      </InputLabel>
                    </div>
                  </div>
                ) : (
                  <img
                    className={classes.logoCloseStyle}
                    src={Images.logomark_svg}
                    alt="mySvgImage"
                  />
                )}
              </Button>
            </div>
          </div>
          {/* <Divider /> */}
          <List
            style={{
              padding: "0px",
            }}
          >
            {drawerItems?.map((item, index) => (
              <div
                style={{
                  backgroundColor: handleBorderRadius(index, item.isActive),
                }}
              >
                <Tooltip title={item.name} placement="right" arrow>
                  <ListItem
                    key={index + ""}
                    // onMouseEnter={() => {
                    //   // handleModalOpen();
                    //   handleDrawerClose();
                    // }}
                    // onMouseLeave={() => {
                    //   handleDrawerClose();
                    // }}
                    onClick={() => {
                      if (index + 1 !== drawerItems.length && index !== 0)
                        handleDrawerMenu(index);
                    }}
                    style={{
                      //backgroundColor: item.isActive ? "red" : "yellow",
                      padding: open ? "16px" : "20px",
                      // cursor: handleCursor(index) ? "pointer" : "initial",
                      backgroundColor:
                        item.isActive && index !== 0
                          ? Colors?.background.backgroundColorTheme3
                          : Colors?.background.backgroundColorTheme2,
                      borderRadius: item.isActive
                        ? "40px 0px 0px 40px" //top left, top right, bottom right, bottom left
                        : currentIndex - 1 === index
                        ? "0px 0px 20px 0px"
                        : currentIndex + 1 === index
                        ? "0px 20px 0px 0px"
                        : "0px 40px 40px 0px",

                      //0px 55px 55px 0px
                    }}
                    className={classes.listItemContainer}
                    button
                  >
                    {index + 1 != drawerItems.length && index != 0 && (
                      <img
                        className={classes.menuIcons}
                        src={
                          item.isActive ? item.active_img : item.in_active_img
                        }
                      />
                    )}

                    <ListItemText
                      style={{
                        padding: "0px",
                      }}
                      classes={{
                        primary: item.isActive
                          ? clsx(
                              classes.listItemTextContainer,
                              classes.drawerTitleColor
                            )
                          : classes.listItemTextContainer,
                      }}
                      primary={open && item.name}
                    />
                  </ListItem>
                </Tooltip>
              </div>
            ))}
          </List>
          <Divider />
        </SwipableDrawer>

        <div
  className={classes.scrol}
  style={{
    display: "flex",
    flex: 1,
    flexDirection: "column",
   backgroundColor: Colors?.background.backgroundColorTheme3, // parent background color
    marginTop: "65px",
    overflowX: "hidden",

     
  overflowY: isCreateDashboardPage || isEditDashboardPage ? "hidden" : "auto", // Conditionally apply overflowY style
  }}
>
          <NavigationPath updatedPath={currentDashboardPath} />
          <Main className={classes.dasboardContainer}
           style={{
            ...(isCreateDashboardPage ? { backgroundColor: "rgb(242,245,253)"} : {}),
            ...(isEditDashboardPage ? { backgroundColor: "rgb(242,245,253)" } : {}),
          }}
          open={open}>
            {/* <DrawerHeader /> */}
            <Routes>
              
              <Route path="/" element={<MainProject/>} />
              <Route path="/user" element={<User />} />
              <Route path="modules" element={<ModuleList />} />
              <Route path="landing" element={<LandingPage />} />
              <Route path="dashboard" element={<DashboardList />} />
              <Route path="viewdashboard" element={<div style={dashboardDescStyle}><DashboardShow /></div>} />
              {/* <Route path="create_dashboard" element={<CreateDashboardFromModules />} /> */}
              <Route path={"Organization_Details"} element={<Organization />} />
              <Route path="bot" element={<ChatBot />} />
              <Route path="company" element={<CompanyDetails />} />
              {/* <Route path="linechart" element={<ObservableCharts />} /> */}
              <Route path="D3Chart" element={<D3Chart />} />
              {/* <Route path="sequence" element={<SequenceSunburst />} /> */}
              {/* <Route path="charts" element={<Charts />} /> */}
              <Route path="barchart" element={<BarChart />} />
              <Route path="piechart" element={<PieChart />} />
              {/* <Route path="radial" element={<RadialBar />} /> */}
              <Route path="linechart" element={<LineChart />} />
              <Route path="lineserieschart" element={<LineChartSeries />} />
              <Route path="heatmap" element={<HeatMap />} />
              <Route path="scatterplot" element={<ScatterPlot />} />
              {/* <Route path="geomap" element={<GeoMap />} /> */}
              <Route path="boxplot" element={<BoxPlot />} />
              <Route path="radar" element={<RadarChart />} />
              <Route path="radial" element={<RadialChart />} />
              <Route path="visualization" element={<AnalyzeModules />} />
              <Route path="createdashboard" element={<CreateDashboard />} />
              <Route path="editdashboard" element={<EditDashboard />} />
            </Routes>
          </Main>
        </div>
      </Box>
    </>
  );
};

export default CustomDrawer;
