import {
  React,
  Card,
  CardContent,
  CardActions,
  Box,
  Typography,
  Divider,
  InputLabel,
  parse,
  clsx,
  Grid,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Select,
  MenuItem,
  Collapse,
  IconButton,
  ExpandMore,
  ExpandLess,
  ChevronRight,
  useNavigate,
  Backdrop,
  CircularProgress,
  useEffect,
  useState,
  Toolbar,
  styled,
  Drawer,
  Button,
  List,
  ListItem,
  useContext,
  AppStatesContainer,
  ListItemText,
  Modal,
  useTheme,
  useRef,
  useMsal,
  CssBaseline,
  Search,
  Popover,
  Cookies,
} from "../../../app.module";
import Menu from "@mui/material/Menu";
import { FormControl, FormHelperText } from "@mui/material";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogActions from "@mui/material/DialogActions";
import { Images, Colors } from "../../constants";
import { useUserStyle, StyledProjectButton, CssTextField } from "./userstyle";
import { tableCellClasses } from "@mui/material/TableCell";
import {
  addUsers,
  getUserRoles,
  doGetUsers,
  gUserRoles,
  GetUsersRes,
  setCompanyDetails,
  doGetUsersByRole,
  doGetUsersByRoleProj,
} from "./userservice";
import { getUserDetailsFromCookies } from "../../services";
import { InviteUser, UserRole } from "../../constants/Endpoints";
import {
  dogetproject,
} from "../Main_Project/project/ProjectService";
import ProjectsList from "../Main_Project/project/ProjectsList";
import { isError } from "lodash";
import { red } from "@mui/material/colors";
const style = {
  width: "90%",
  maxWidth: 360,
  bgcolor: "background.paper",
  padding: "10px",
};

// import{doGetUsersByRole} from "./userservice";
const User = (props: any) => {
  const { classes } = useUserStyle();
  const [userList, setUserList] = useState<any[]>([]);
  const [value, setValue] = useState("none");
  const [showPlaceholder, setShowPlaceholder] = useState(value === "none");
  const [userRole, setUserRole] = useState([]);
  const [projects, setProjects] = useState<any[]>([]);
  const [isInviteOpen, setIsInviteOpen] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [isButtonDisabled, setIsButtonDisabled] = useState(true);
  const [showDialog, setShowDialog] = useState(false);
  const [dialogMessage, setDialogMessage] = useState("");
  const [errorMessage, setErrorMessage] = useState("");
  const [showError, setShowError] = useState(false);
  const [selectedUserRole, setSelectedUserRole] = useState<string | null>(null);
  const [selectedCardIndex, setSelectedCardIndex] = useState<number | null>(
    null
  );
  const [useranchorEl, setUserAnchorEl] = useState<null | HTMLElement>(null);

  const [allRoles, setAllRoles] = useState([]);

  const [selected, setSelected] = useState<null | undefined>(null);
  const [projectlist, setProjectlist] = useState<any[]>([]);
  const [selectedUserIndex, setSelectedUserIndex] = useState(null);
  const [editMode, setEditMode] = useState(null);
  const [isFormValid, setIsFormValid] = useState(false);
  const [editedFirstName, setEditedFirstName] = useState("");
  const [editedLastName, setEditedLastName] = useState("");
  const [selectedUserRoleForInvite, setSelectedUserRoleForInvite] = useState<
    string | null
  >(null);
  const [selectedUserRoleForSorting, setSelectedUserRoleForSorting] = useState<
    string | null
  >(null);
  const [invitationStatus, setInvitationStatus] = useState<string | null>(null);
  // const [isFormValid, setIsFormValid] = useState(false);

  interface FormValues {
    [key: string]: {
      value: string;
      error: boolean;
      errorMessage: string;
      id?: number;
    };
  }

  useEffect(() => {
    // When your component mounts, fetch all roles and set to allRoles
    getUserRoles("").then((role_list: any) => {
      setAllRoles(role_list);
      setUserRole(role_list); // If needed elsewhere
    });
  }, []);

  const resetFormValues = () => {
    setFormValues({
      first_name: {
        value: "",
        error: false,
        errorMessage: "You must enter your First Name",
      },
      last_name: {
        value: "",
        error: false,
        errorMessage: "You must enter your Last Name",
      },
      project_list: {
        value: "none",
        id: 0,
        error: false,
        errorMessage: "Select Project",
      },
      email: {
        value: "",
        error: false,
        errorMessage: "Enter your Email Address",
      },
      user_role: {
        value: "none",
        id: 0,
        error: false,
        errorMessage: "Select User Role",
      },
    });
  };

  const [formValues, setFormValues] = useState({
    first_name: {
      value: "",
      error: false,
      errorMessage: "You must enter your First Name",
    },
    last_name: {
      value: "",
      error: false,
      errorMessage: "You must enter your Last Name",
    },
    project_list: {
      value: "none",
      id: 0,
      error: false,
      errorMessage: "Select Project",
    },
    email: {
      value: "",
      error: false,
      errorMessage: "Enter your Email Address",
    },
    user_role: {
      value: "none",
      id: 0,
      error: false,
      errorMessage: "Select User Role",
    },
  });
  console.log("USER LIST", userList);

  const validateName = (name: string): boolean => {
    const regex = /^[a-zA-Z\s]+$/;
    return regex.test(name);
  };
  const validateEmail = (email: string) => {
    return email.includes("@");
  };

  const handleFirstNameChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const newValue = e.target.value;
    const isValid = validateName(newValue);

    setFormValues((prevState) => ({
      ...prevState,
      first_name: {
        ...prevState.first_name,
        value: newValue,
        error: !isValid,
        errorMessage: isValid ? "" : "First Name can only contain letters",
      },
    }));
  };
  const handleLastNameChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const newValue = e.target.value;
    const isValid = validateName(newValue); // Assuming you're using the same validation rules

    setFormValues((prevState) => ({
      ...prevState,
      last_name: {
        ...prevState.last_name,
        value: newValue,
        error: !isValid,
        errorMessage: isValid ? "" : "Last Name can only contain letters",
      },
    }));
  };
  const handleEmailChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const newValue = e.target.value;
    const isValid = validateEmail(newValue);

    setFormValues((prevState) => ({
      ...prevState,
      email: {
        ...prevState.email,
        value: newValue,
        error: !isValid,
        errorMessage: isValid ? "" : "Email must contain @",
      },
    }));
  };
  const validateAllFields = () => {
    const errors = {
      first_name: validateName(formValues.first_name.value),
      last_name: validateName(formValues.last_name.value),
      email: validateEmail(formValues.email.value),

      // project_list: formValues.project_list.value !== "none",  

      user_role: formValues.user_role.value !== "none",
    };

    // Update the state with the errors for the respective fields
    setFormValues((prevState) => ({
      ...prevState,
      first_name: {
        ...prevState.first_name,
        error: !errors.first_name,
        errorMessage: errors.first_name
          ? ""
          : "First Name can only contain letters.",
      },
      last_name: {
        ...prevState.last_name,
        error: !errors.last_name,
        errorMessage: errors.last_name
          ? ""
          : "Last Name can only contain letters.",
      },
      email: {
        ...prevState.email,
        error: !errors.email,
        errorMessage: errors.email ? "" : "Email must contain @",
      },
      user_role: {
        ...prevState.user_role,
        error: !errors.user_role,
        errorMessage: errors.user_role ? "" : "Please select a user role.",
      },
      // ... update other fields similarly
    }));

    // Return false if there are any errors
    return !Object.values(errors).some((isValid) => !isValid);
  };
  const handleStateChange = (e: any) => {
    console.log("message");
    const { name, value } = e.target;
    switch (name) {
      case "first_name":
        setFormValues({
          ...formValues,
          ["first_name"]: {
            value,
            // error: value.trim() === "",
            error: false,
            errorMessage: "",
          },
        });
        break;
      case "last_name":
        setFormValues({
          ...formValues,
          ["last_name"]: {
            value,
            // error: value.trim() === "",
            error: false,
            errorMessage: "",
          },
        });
        break;
      case "project_list":
        console.log(" project value", value);
        getUserRoles("Project").then((role_list: any) => {
          console.log("rolls sang projectlist", role_list);
          setUserRole(role_list);
        });
        let selected_project: any = projectlist.find(
          (type: any) => type.name === value
        );
        setFormValues({
          ...formValues,
          ["project_list"]: {
            value,
            error: false,
            errorMessage: "",
            id: selected_project.id,
          },
        });
        break;
      case "user_role":
        let selected_role: any = userRole.find(
          (type: any) => type.roleName === value
        );
        setFormValues({
          ...formValues,
          ["user_role"]: {
            value,
            error: false,
            errorMessage: "",
            id: selected_role.id,
          },
        });
        break;

      case "email":
        setFormValues({
          ...formValues,
          ["email"]: {
            value,
            // error: value.trim() === "",
            error: false,
            errorMessage: "",
          },
        });
        break;
    }

  };
  const StyledTableCell = styled(TableCell)(({ theme }) => ({
    [`&.${tableCellClasses.head}`]: {
      backgroundColor: "#fff",
      color: "#000",
      fontSize: 14,
      fontWeight: "bold",
      border: "1px solid",
      padding: 10,
    },
    [`&.${tableCellClasses.body}`]: {
      fontSize: 15,
      padding:2,
    },
    textAlign: "center",
    cursor: "pointer",
  }));

  const StyledTableRow = styled(TableRow)(({ theme }) => ({
    "&:nth-of-type(odd)": {
      backgroundColor: "#fff",
    },
    // hide last border
    "&:last-child td, &:last-child th": {
      border: 0,
    },
  }));

  const handleEdit = (selectedIndex: any) => {
    setEditMode(selectedIndex);
    setEditedFirstName(userList[selectedIndex].firstName);
    setEditedLastName(userList[selectedIndex].lastName);
  };
  const handleSaveEdit = () => {
    if (selectedUserIndex !== null) {
      const updatedUserList = [...userList];
      updatedUserList[selectedUserIndex].firstName = editedFirstName;
      updatedUserList[selectedUserIndex].lastName = editedLastName;
      setUserList(updatedUserList);
      setSelectedUserIndex(null);
    }
  };

  const handleDelete = (selectedIndex: any) => {
    // Implement your delete project logic here using the selectedIndex

    if (selectedUserIndex !== null) {
      // Perform the deletion logic here
      const userToDelete = userList[selectedUserIndex]; // Get the user to delete
      // Call your delete user API or function here
      // For example: deleteUser(userToDelete.id)

      // Update the user list in the state to reflect the deletion
      const updatedUserList = [...userList];
      updatedUserList.splice(selectedUserIndex, 1); // Remove the selected user
      setUserList(updatedUserList);

      // Close the context menu
      setUserAnchorEl(null);
      setSelected(null);
      setSelectedUserIndex(null);
    };
  }

  const handleDotMenuOpen = (event: any, selectedIndex: any) => {
    console.log("current event targer", event.currentTarget);
    setUserAnchorEl(event.currentTarget);
    setSelected(selectedIndex); // Store the selected index
    // setSelectedUserIndex(selectedIndex);
  };
  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setUserAnchorEl(event.currentTarget);
  };

  // Handle the closing of the menu
  const handleMenuClose = () => {
    setUserAnchorEl(null);
    setSelected(null);
    // setSelectedUserIndex(null);
  };

  const userinvite = async () => {
    // Validation logic remains unchanged
    if (
      !formValues.first_name ||
      !formValues.last_name ||
      !formValues.email ||
      formValues.user_role.id === null
    ) {
      setDialogMessage("Please fill in all the necessary fields.");
      setShowDialog(true);
      setTimeout(() => setShowDialog(false), 2000);
      return;
    }

    // Prepare your invite data
    const users_inv = {
      firstName: formValues.first_name.value,
      lastName: formValues.last_name.value,
      emailAddress: formValues.email.value,
      projectId:formValues.project_list.id,
      roleId: formValues.user_role.id,
      invitedByUser: getUserDetailsFromCookies().id,
    };
    setIsLoading(true);
    try {
      const response = await addUsers(users_inv);
      // Assuming 'response' is an object that includes a boolean 'isError' property
      if (response.isError) {
        setErrorMessage(
          "User already exits, please use another email address to invite an user."
        );
        setShowError(true);
        setTimeout(() => {
          setShowError(false);
        }, 5000); // Hide error after 5 seconds
      } else {
        setDialogMessage("Your invitation has been sent successfully.");
        setShowDialog(true);
        setIsInviteOpen(false); // Close the backdrop only on success
        resetFormValues();
        // Set a timeout to hide the dialog after showing the message, only on success

        doGetUsers().then((user_list: any) => {
          setUserList(user_list);
        });

        setTimeout(() => {
          setShowDialog(false);
        }, 5000);
      }
    } catch (error) {
      // Network or other asynchronous errors
      setErrorMessage(
        "Failed to send invitation. Please check your connection and try again."
      );
      setShowError(true);
      setShowDialog(false);
    } finally {
      setIsLoading(false); // End loading
    }
  };


  const sortUserListByUserRole = () => {
    if (selectedUserRoleForSorting) {
      const filteredUserList = userList.filter(
        (user) => user.roleName === selectedUserRoleForSorting
      );
      setUserList(filteredUserList);
    }
  };
  useEffect(() => {
    sortUserListByUserRole();
  }, [selectedUserRoleForSorting]);

  useEffect(() => {
    const isDisabled =
      formValues.first_name.value.trim() === "" ||
      formValues.last_name.value.trim() === "" ||
      formValues.email.value.trim() === "" ||
      formValues.user_role.value === "none" || // Assuming "none" means no role selected
      formValues.user_role.id === 0; // Assuming id 0 means no role selected

    setIsButtonDisabled(isDisabled);
  }, [
    formValues.first_name,
    formValues.last_name,
    formValues.email,
    formValues.user_role,
  ]);
  // Dependency array includes all form fields that affect the button's disabled state

  useEffect(() => {
    // Declare timeoutId with a specific type
    let timeoutId: number | null = null;

    if (showDialog) {
      timeoutId = window.setTimeout(() => {
        setShowDialog(false);
      }, 2000);
    }

    // Cleanup function to clear the timeout
    return () => {
      if (timeoutId !== null) {
        clearTimeout(timeoutId);
      }
    };
  }, [showDialog]);

  useEffect(() => {
    doGetUsers().then((user_list: any) => {
      setUserList(user_list);
      console.log("userlist", user_list);
    });

    getUserRoles("").then((role_list: any) => {
      console.log("rolls sang", role_list);
      setUserRole(role_list);
    });

    dogetproject().then((project_list: any) => {
      setProjectlist([...project_list]);
      console.log("projectlist", projectlist);
    });
  }, []);


  useEffect(() => { }, [formValues, userRole]);
  const handleInviteClick = () => {
    getUserRoles("Org").then((role_list: any) => {
      console.log("rolls sang", role_list);
      setUserRole(role_list);
    });
    setIsInviteOpen(true); // Open the invite box when the button is clicked
  };

  const handleCloseInvite = () => {
    setIsInviteOpen(false); // Close the invite box
    resetFormValues(); // Reset the form values
  };

  // Handle the Resend Email action
  const handleResendEmail = (index: any) => {
    // Implement the Resend Email logic here using the index
    if (selectedUserIndex !== null) {
      // Get the user to resend the email to
      const userToResendEmail = userList[selectedUserIndex];
      // Call your email resend API or function here
      // Replace the following line with your actual email resend logic
      console.log(`Resending email to ${userToResendEmail.email}`);

      // Close the context menu
      setUserAnchorEl(null);
      setSelected(null);
      setSelectedUserIndex(null); // Clear the selected user index
    }
    handleMenuClose(); // Close the menu
  };

  // Handle the Suspend User action
  const handleSuspendUser = (index: any) => {
    // Implement the Suspend User logic here using the index
    // handleMenuClose(); // Close the menu
  };
  const handleUserRoleClick = (roleName: string) => {
    setSelectedUserRole(roleName); // Update the selected user role
    // You can also perform any other actions here if needed
  };
  const handleCardClick = (index: any) => {
    setSelectedCardIndex(index);
  };
  const handleUserRoleCardClick = (
    roleName: string,
    index: any,
    roleType: string,
    roleId: number
  ) => {
    console.log("give ", getUserDetailsFromCookies());
    setSelectedUserRoleForSorting(roleName);
    setSelectedCardIndex(index);
    if (
      roleName === "Project Admin" ||
      roleName === "Contributor" ||
      roleName === "Viewer"
    ) {
      doGetUsersByRole(getUserDetailsFromCookies()?.projectId, roleId).then(
        (user_list: any) => {
          if (!user_list?.isError) {
            setUserList(user_list);
            console.log("userlist", user_list);
          } else {
            setUserList([]);
          }
        }
      );
    } else {
      doGetUsersByRoleProj(roleId).then((user_list: any) => {
        setUserList(user_list);
        console.log("userlist", user_list);
      });
    }
  };

  useEffect(() => {
    sortUserListByUserRole();
  }, [selectedUserRoleForSorting]);

  return (
    <div className={classes.parent}>
      <div className={classes.roles}>
        <div style={{ display: "flex", justifyContent: "space-between", }}>
          <div style={{ fontWeight: 700, padding: "20px", fontSize: "18px" }}>
            Roles available
          </div>

          <div
            className={clsx(
              classes.btnProjectTestContainer,
              classes.marginNewPro
            )}
          >
            <StyledProjectButton
              onClick={handleInviteClick}
              startIcon={<img src={Images.createproject_placeholder} />}
              className={classes.btnProjectTestLabelStyle}
              variant="contained"
              size="medium"
              disabled={getUserDetailsFromCookies().roleName == 'Contributor' || getUserDetailsFromCookies().roleName == 'Viewer' ? true:false}
              style={{
                // position: "fixed",

                right: "6px", // Adjust the position as needed
             //  zIndex: 1000, // Adjust the z-index as needed
              }}
            >
              Invite User
            </StyledProjectButton>
          </div>
        </div>
        <div>
          <Grid
            justifyContent="center"
            alignItems="center"
            flexDirection={"row"}
            container
          >
            {allRoles?.map((result: any, index: Number) => (
              <Grid
                padding={2}
                justifyContent="center"
                alignItems="center"
                key={result.id}
              >
                <Grid item xs={12} sm={6} md={3}>
                  <Card
                    onClick={() =>
                      handleUserRoleCardClick(
                        result.roleName,
                        index,
                        result.roleType,
                        result.id
                      )
                    }
                    sx={{
                      width:"202px",
                      height:"130px",
                      // padding: 1,
                      radius:"8px",
                      border:"1px",
                      // backgroundColor: getUserRoles(result.roleName) ? Colors?.tilebackground : selectedUserRole === result.roleName ? 'blue' : Colors?.tilebg,
                      backgroundColor:
                        selectedCardIndex === index
                          ? {
                            background:
                              "linear-gradient(99.85deg, #5CAEF9 -12.22%, #135ACF 52.22%, #9A90FA 111.99%)",
                          }
                          : "white",
                    }}
                  // {handleUserRoleClick()}
                  >
                    <CardContent>
                      <Typography
                        className={classes.planTopLabel}
                        // gutterBottom
                        style={{
                          color:
                            selectedCardIndex === index ? "white" : "black",
                          
                          alignItems:"center",
                        }}
                      >
                        {result.roleName}
                      </Typography>

                    </CardContent>
                    {
                      //err
                    }
                    <Divider style={{ border: "0.3px solid" }} />
              
                  </Card>
                </Grid>
              </Grid>
            ))}
          </Grid>
        </div>
        <div >
          {isInviteOpen && (
            < Backdrop open={isInviteOpen} > 
        
              <Box
                sx={{
                  display: "flex",
                  flex: 1,

                  maxWidth: "100%",
                  marginLeft: "20%",
                  marginRight: "20%",
                  padding: "20px",
                  borderRadius: "10px",

                  flexDirection: "column",
                  backgroundColor: "aliceblue",
                  position: "relative",
                }}
              >
                <div
                  style={{ display: "flex", justifyContent: "spaceBetween" }}
                >
              
                </div>

                <div className={classes.twoFieldsContainer}>
                  <div className={classes.textFiledLabelContainer}>
                    <InputLabel className={classes.labelStyle}>
                      First name <span className={classes.asterix}> *</span>
                    </InputLabel>
                    <CssTextField
                      required
                      fullWidth
                      onChange={handleFirstNameChange} // Modified to use the new handler
                      name="first_name"
                      value={formValues.first_name.value}
                      error={formValues.first_name.error}
                      helperText={
                        formValues.first_name.error
                          ? formValues.first_name.errorMessage
                          : ""
                      }
                      placeholder="First name"
                      inputProps={{
                        maxLength: 50,
                      }}
                      InputProps={{
                        classes: { input: classes.input },
                      }}
                      id="custom-css-standard-input"
                    />
                  </div>

                  <div className={classes.textFiledLabelContainerRight}>
                    <InputLabel className={classes.labelStyle}>
                      Last name <span className={classes.asterix}> *</span>
                    </InputLabel>
                    <CssTextField
                      fullWidth
                      name="last_name"
                      onChange={handleLastNameChange} // Changed to use the handleLastNameChange function
                      value={formValues.last_name.value}
                      error={formValues.last_name.error}
                      helperText={
                        formValues.last_name.error
                          ? formValues.last_name.errorMessage
                          : ""
                      }
                      placeholder="Last name"
                      inputProps={{
                        maxLength: 253,
                      }}
                      InputProps={{
                        classes: { input: classes.input },
                      }}
                      id="custom-css-standard-input"
                    />
                  </div>
                </div>

                <div className={classes.twoFieldsContainer}>
                  <div
                    className={clsx(
                      classes.selectFlex,
                      classes.textFiledLabelContainer
                    )}
                  >
                    <InputLabel
                      className={classes.labelStyle}
                      id="demo-simple-select-helper-label"
                    >
                      Projects
                    </InputLabel>
                    {isLoading && (
                      <Box className={classes.loaderContainer}>
                        <CircularProgress />
                      </Box>
                    )}

                    {/* FormControl starts here */}
                    <FormControl
                      error={formValues.project_list.error}
                      fullWidth
                    >
                      <Select
                        id="demo-simple-select-helper-type"
                        value={formValues.project_list.value}
                        error={formValues.project_list.error}
                        name="project_list"
                        className={clsx(
                          classes.select,
                          formValues.project_list.value === "none"
                            ? classes.selectdisabled
                            : null
                        )}
                        onFocus={(e) => setShowPlaceholder(false)}
                        onChange={handleStateChange}
                        onClose={(e) => {
                          setShowPlaceholder(
                            (e.target as HTMLInputElement).value === undefined
                          );
                        }}
                        defaultValue="none"
                      >
                        <MenuItem
                          className={clsx(
                            classes.menuitem,
                            !showPlaceholder ? classes.menuitemhidden : null
                          )}
                          key="0"
                          disabled
                          value={"none"}
                        >
                          Projects
                        </MenuItem>

                        {projectlist?.map((item: any) => (
                          <MenuItem
                            key={item?.id}
                            className={classes.menuitem}
                            value={item?.name}
                          >
                            {item?.name}
                          </MenuItem>
                        ))}
                      </Select>

                      {/* FormHelperText for error message */}
                      {formValues.project_list.error && (
                        <FormHelperText>
                          {formValues.project_list.errorMessage}
                        </FormHelperText>
                      )}
                    </FormControl>
                   
                  </div>
                </div>

                <div className={classes.twoFieldsContainer}>
                  <div className={classes.textFiledLabelContainer}>
                    <InputLabel className={classes.labelStyle}>
                      Email <span className={classes.asterix}> *</span>
                    </InputLabel>
                    <CssTextField
                      fullWidth
                      value={formValues.email.value}
                      error={formValues.email.error}
                      onChange={handleEmailChange} // Updated to use handleEmailChange
                      helperText={
                        formValues.email.error
                          ? formValues.email.errorMessage
                          : "" // Updated to show the specific error message
                      }
                      name="email"
                      placeholder="Email"
                      inputProps={{
                        maxLength: 225,
                      }}
                      InputProps={{
                        classes: { input: classes.input },
                      }}
                      id="custom-css-standard-input"
                    />
                  </div>
                  <div
                    className={clsx(
                      classes.selectFlex,
                      classes.textFiledLabelContainer
                    )}
                  >
                    <InputLabel
                      className={classes.labelStyle}
                      id="demo-simple-select-helper-label"
                    >
                      User Role <span className={classes.asterix}> *</span>
                    </InputLabel>

                    {/* FormControl starts here */}
                    <FormControl error={formValues.user_role.error} fullWidth>
                      <Select
                        id="demo-simple-select-helper-type"
                        value={formValues.user_role.value}
                        error={formValues.user_role.error}
                        name="user_role"
                        className={clsx(
                          classes.select,
                          formValues.user_role.value === "none"
                            ? classes.selectdisabled
                            : null
                        )}
                        onFocus={(e) => setShowPlaceholder(false)}
                        onChange={(e) => handleStateChange(e)}
                        onClose={(e) => {
                          setShowPlaceholder(
                            (e.target as HTMLInputElement).value === undefined
                          );
                        }}
                        defaultValue="none"
                      >
                        <MenuItem
                          className={clsx(
                            classes.menuitem,
                            !showPlaceholder ? classes.menuitemhidden : null
                          )}
                          key="0"
                          disabled
                          value="none"
                        >
                          User Role
                        </MenuItem>
                        {userRole.map((item: any) => (
                          <MenuItem
                            key={item?.id}
                            className={classes.menuitem}
                            value={item?.roleName}
                          >
                            {item?.roleName}
                          </MenuItem>
                        ))}
                      </Select>

                      {/* FormHelperText for error message */}
                      {formValues.user_role.error && (
                        <FormHelperText>
                          {formValues.user_role.errorMessage}
                        </FormHelperText>
                      )}
                    </FormControl>
                    {/* FormControl ends here */}
                  </div>
                </div>
                {/* ... other components ... */}

                {/* ... other components ... */}

                <div
                  className={clsx(
                    classes.btnProjectTestContainer,
                    classes.marginNewPro
                  )}
                >
                  {/* Buttons and error message container with column direction */}
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "column",
                      alignItems: "center",
                      width: "100%",
                    }}
                  >
                    {/* Button container with row direction */}
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "center",
                        marginBottom: "10px",
                      }}
                    >
                      {/* Cancel Button */}
                      <StyledProjectButton
                        onClick={handleCloseInvite}
                        className={classes.btnProjectTestLabelStyle}
                        variant="contained"
                        size="medium"
                        style={{ marginRight: "10px" }} // Add margin to separate buttons
                      >
                        Cancel
                      </StyledProjectButton>

                      {/* Send Invite Button */}
                      <StyledProjectButton
                        onClick={() => {
                          if (validateAllFields()) {
                            userinvite();
                          }
                        }}
                        startIcon={
                          <img
                            src={Images.user_invite_button}
                            alt="Send Invite"
                           className={isButtonDisabled ? classes.disabledIcon : ''}
                            
                          />
                        }
                        className={`${classes.btnProjectTestLabelStyle} ${classes.btnSendInvite}`} // Added btnSendInvite class
                        variant="contained"
                        size="medium"
                        disabled={isButtonDisabled}
                      >
                        Send Invite
                      </StyledProjectButton>
                   
                    </div>

                    {/* Error message */}
                    {showError && (
                      <div
                        style={{
                          color: "red",
                          textAlign: "center",
                          width: "100%",
                        }}
                      >
                        {errorMessage}
                      </div>
                    )}
                  </div>
                </div>
              </Box>
              {invitationStatus && <div>{invitationStatus}</div>}
            </ Backdrop>
          )}

          <Dialog
            open={showDialog}
            onClose={() => setShowDialog(false)}
            PaperProps={{
              style: {
                background: "#9DC7F6",
                width: "622px", // Set the width of the dialog
                minHeight: "35px", // Set the minimum height of the dialog
                display: "flex", // Enable flexbox for centering content
                flexDirection: "column", // Stack children vertically
                justifyContent: "center", // Center content vertically
                alignItems: "center", // Center content horizontally
                padding: "25px", // Add some padding
                boxShadow: "none", // Remove shadow
                border: "none", // Remove border
                overflowY: "hidden", // Hide the overflow
              },
            }}
          >
            <DialogContent style={{ padding: 0, overflowY: "visible" }}>
              {" "}
              {/* Remove padding */}
              <DialogContentText
                style={{ color: "#000000", textAlign: "center" }}
              >
                {dialogMessage}
              </DialogContentText>
            </DialogContent>
          </Dialog>

          {showDialog && <div className="dialog-box"></div>}
        </div>
        <div className={classes.middle}></div>
        <div className={classes.table}>
          <TableContainer>
            <Table sx={{ minWidth: 700 }} aria-label="customized table">
              <TableHead>
                <TableRow>
                  <StyledTableCell>Sr. no</StyledTableCell>
                  <StyledTableCell>Name</StyledTableCell>
                  <StyledTableCell>Email</StyledTableCell>
                  <StyledTableCell>Project Name</StyledTableCell>
                  <StyledTableCell>Role Name</StyledTableCell>
                  <StyledTableCell>Status</StyledTableCell>
                  <StyledTableCell>Action</StyledTableCell>
                </TableRow>
              </TableHead>
              {/* <TableBody>
                <TableRow>
                <TableCell component="th" scope="row">
                        { 1}
               </TableCell>
               <TableCell>
                shivani 
               </TableCell>
               <TableCell>
                shivani 
               </TableCell> <TableCell>
                shivani 
               </TableCell> <TableCell>
                shivani 
               </TableCell> <TableCell>
                shivani 
               </TableCell> <TableCell>
               <div>
      <Button
        id="basic-button"
        aria-controls={Boolean(useranchorEl) ? 'basic-menu' : undefined}
        aria-haspopup="true"
        aria-expanded={Boolean(useranchorEl) ? 'true' : undefined}
        onClick={handleClick}
      >
        Dashboard
      </Button>
      <Menu
        id="basic-menu"
        anchorEl={useranchorEl}
        open={Boolean(useranchorEl)}
       // onClose={handleClose}
        MenuListProps={{
          'aria-labelledby': 'basic-button',
        }}
      >
        <MenuItem >Profile</MenuItem>
        <MenuItem >My account</MenuItem>
        <MenuItem>Logout</MenuItem>
      </Menu>
    </div>
               </TableCell>
                </TableRow>
              </TableBody> */}
              <TableBody >
                {userList?.length > 0 ? (
                  userList?.map((result: any, index: any) => (
                    <TableRow key={result.id} >
                      <TableCell component="th" scope="row" style={{ textAlign:"center"}}>
                        {index + 1}
                      </TableCell>

                      <TableCell style={{ textAlign:"center"}}>
                        {result.userName ?? "N/A"}
                      </TableCell>
                      <TableCell style={{ textAlign:"center"}}>{result.email ?? "N/A"}</TableCell>
                      <TableCell style={{ textAlign:"center"}}>
                        {result.projectName ?? "N/A"}
                      </TableCell>
                      <TableCell style={{ textAlign:"center"}}>
                        {result.roleName ?? "N/A"}
                      </TableCell>
                      <TableCell style={{ textAlign:"center"}}>{"Active"}</TableCell>

                      <TableCell style={{ textAlign:"center"}}>
                        <IconButton
                          aria-label="more"
                          aria-describedby={`user-menu-${index}`}
                          aria-haspopup="true"
                         onClick={(e) => handleDotMenuOpen(e, result)}
                        //  id = "basic-button"
                        //   onClick={handleClick}
                        >
                          <MoreVertIcon />
                        </IconButton>
                        <Menu
                         id={`user-menu-${index}`}

                          anchorEl={useranchorEl}
                       //   keepMounted
                          open={Boolean(useranchorEl)}
                          onClose={handleMenuClose}
                        >
                          <MenuItem onClick={() => handleEdit(index)}>
                            Edit
                          </MenuItem> 
                          <MenuItem onClick={() => handleDelete(index)}>
                            Delete
                          </MenuItem>
                          <MenuItem onClick={() => handleSuspendUser(index)}>
                            Suspend User
                          </MenuItem>
                          <MenuItem onClick={() => handleResendEmail(index)}>
                            Resend Email
                          </MenuItem>
                        </Menu>
                      </TableCell>
                    </TableRow>
                  ))
                ) : (
                  <TableRow>
                    <TableCell colSpan={7}>
                      No users with the specified role found.
                    </TableCell>
                  </TableRow>
                )}
              </TableBody>
            </Table>
          </TableContainer>
        
        </div>
      </div>
      {invitationStatus && (
        <div className={`${classes.invitationStatusMessage} `}>
          {invitationStatus}
        </div>
      )}
    </div>
  );
};

export default User;
