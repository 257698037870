import React, { useEffect, useState } from 'react';
import { Card, CardContent, Typography } from '@mui/material'; // Import necessary components
import { Colors, Images } from '../../../constants';

const CardChartForDashboard = React.memo((props: any) => { 
    const [cardChartJson, setCardChartJson] = useState<any>({});
    const [isLoading, setIsLoading] = useState(true);

    useEffect(() => {
        if (props.data !== cardChartJson) {
          setCardChartJson(props.data);
          setIsLoading(false); // Data is loaded, set loading to false
        }
      }, [props.data]);

      if (!cardChartJson.data || cardChartJson.data.length === 0) {
        return (
          <div
            style={{
              display: "flex",
              height: "100%",
              width: "100%",
              justifyContent: "center",
              alignItems: "center",
              backgroundColor: Colors?.background.backgroundColorTheme3,
              boxShadow: "0px 0px 4px 0px #00000040",
              borderRadius: "10px 0px 0px 10px",
            }}
          >
            <img
              style={{
                height: "auto",
                width: "300px",
              }}
              src={Images.no_data_img}
              alt="No data"
            />
          </div>
        );
    }

    const chartData = cardChartJson.data[0];
    const cardTitle = Object.keys(chartData)[0]; // Get the name dynamically
     const cardValue = chartData[cardTitle]; // Get the value dynamically


    return (
        <div
          key="PIE01"
          style={{

            
            height: "100%",
            width: "100%",
            overflowX: "auto",
            // overflow: "scroll",
            justifyContent: "center",
            alignItems: "center",
            display: "flex",
            top: 0,
          }}
        >
          {props?.pageError !== "" ? (
            <div
              key="TAB101"
              style={{
                display: "flex",
                height: "300px",
                width: "100%",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <img
                style={{
                  height: "auto",
                  width: "300px",
                }}
                src={Images.no_data_img}
              />
            </div>
          ) : (
            <div
              key="CARD108"
              style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
               width: "100%",
               height: "100%",
              }}
            >
<Card
  sx={{
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-between",
    maxWidth: '100%', // Keep the card within the bounds of the parent
    width: '70%', // The card will expand to 100% of the parent's width
    height: '70%', // The card will expand to 100% of the parent's height
    padding: '1%', // Use percentage for responsive padding
    borderRadius: "10px",
    opacity: 0.9,
    boxShadow: "0px 4px 10px rgba(0, 0, 0, 0.1)",
    background: "#f5f5f5",
    color: "#333",
    border: "2px solid #ccc",
    fontSize: '1vw', // Use viewport width for responsive font size
  }}
>
  <CardContent
    style={{
      display: "flex",
      flex: 1,
      flexDirection: "column",
      justifyContent: "center",
      alignItems: "center",
      padding: '1%', // Use percentage for responsive padding inside the card content
    }}
  >
    <Typography variant="h4" component="div" style={{ marginBottom: '1%', fontWeight: 'bold', fontSize: '1.5vw' }}>
      {cardTitle}
    </Typography>
    <hr style={{ width: "70%", border: "1px solid #ccc", margin: '1% 0' }} />
    <Typography variant="h5" style={{ fontSize: '1.2vw' }}>
      {cardValue.toLocaleString()}
    </Typography>
  </CardContent>
</Card>

            </div>
          )}
        </div>
      );

})

export default CardChartForDashboard;