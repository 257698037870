import React, { useState, useEffect } from 'react';
import {
  Table, TableBody, TableCell, TableContainer, TableHead, TableRow, IconButton, Menu, MenuItem, tableCellClasses, styled, Box, Button, Modal, TextField, InputLabel
} from "@mui/material";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import { getUserDetailsFromCookies } from "../../../../services";
import { getFileList, doDeleteDocument, updateDocument } from "./CsvService";
import { StyledButton } from '../../../guide/GuidedStyle';
import { useProjectStyle } from '../../MainProjectStyle';
import { CssProjectTextField } from '../DatasourceStyle';

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: "#F6F8FF",
    color: "#000",
    fontSize: 14,
    fontWeight: "bold",
    border: "1px solid",
    padding: 4,
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 12,
    padding: 0,
  },
  textAlign: "center",
  cursor: "pointer",
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
  "&:nth-of-type(odd)": {
    backgroundColor: "#fff",
  },
  "&:last-child td, &:last-child th": {
    border: 0,
  },
}));

interface DocumentsListProps {
  projectDetails: any;
}

const FileList: React.FC<DocumentsListProps> = ({ projectDetails }) => {
  const [fileList, setFileList] = useState<any[]>([]);
  const [isLoading, setIsLoading] = useState(true);
  const [documentAnchorEl, setDocumentAnchorEl] = useState<null | HTMLElement>(null);
  const [selectedDocumentIndex, setSelectedDocumentIndex] = useState<number | null>(null);
  const [isDeleteDocumentConfirmationOpen, setIsDeleteDocumentConfirmationOpen] = useState(false);
  const [isEditDocumentOpen, setIsEditDocumentOpen] = useState(false);
  const [editedName, setEditedName] = useState("");
  const userRole = getUserDetailsFromCookies().roleName;
  const { classes } = useProjectStyle(userRole)();

  useEffect(() => {
    if (projectDetails) {
      fetchDocuments();
    }
  }, [projectDetails]);

  const fetchDocuments = async () => {
    setIsLoading(true);
    try {
      const files = await getFileList(projectDetails.id);
      setFileList(files);
    } catch (error) {
      console.error("Error fetching documents:", error);
    }
    setIsLoading(false);
  };

  const handleDocumentMenuOpen = (event: React.MouseEvent<HTMLElement>, index: number) => {
    setDocumentAnchorEl(event.currentTarget);
    setSelectedDocumentIndex(index);
  };

  const handleDocumentMenuClose = () => {
    setDocumentAnchorEl(null);
  };

  const handleEditClick = () => {
    if (selectedDocumentIndex !== null) {
      const documentToEdit = fileList[selectedDocumentIndex];
      setEditedName(documentToEdit.name);
      setIsEditDocumentOpen(true);
    }
    handleDocumentMenuClose();
  };

  const handleDeleteDocument = async () => {
    if (selectedDocumentIndex !== null) {
      try {
        const documentToDelete = fileList[selectedDocumentIndex].id;
        await doDeleteDocument(getUserDetailsFromCookies()?.projectId, documentToDelete); 
        fetchDocuments();
        setIsDeleteDocumentConfirmationOpen(false);
      } catch (error) {
        console.error("Error deleting document:", error);
      }
    } else {
      console.error("No document selected for deletion.");
    }
    setSelectedDocumentIndex(null);
  };

  const handleUpdateDocument = async () => {
    if (selectedDocumentIndex !== null) {
      const documentToEdit = fileList[selectedDocumentIndex];
      try {
        const editPayload = {
          Name: editedName,
          ProjectId: getUserDetailsFromCookies()?.projectId,
          Id: documentToEdit.id
        };
        await updateDocument(editPayload);
        fetchDocuments();
      } catch (error) {
        console.error("Error editing document:", error);
      }
      setIsEditDocumentOpen(false);
    }
  };

  if (isLoading) {
    return (
      <div style={{ color: '#007bff', fontSize: '18px', textAlign: 'center', padding: '20px', marginTop: '5%' }}>
        Loading documents...
      </div>
    );
  }

  return (
    <>
      {/* Delete Confirmation Modal */}
      <Modal
        open={isDeleteDocumentConfirmationOpen}
        onClose={() => setIsDeleteDocumentConfirmationOpen(false)}
        aria-labelledby="delete-confirmation-title"
        aria-describedby="delete-confirmation-description"
      >
        <Box
          sx={{
            position: "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            width: 400,
            display: "flex",
            flexDirection: "column",
            bgcolor: "rgb(255, 255, 255)",
            justifyContent: "center",
            boxShadow: 24,
            borderRadius: "5px",
            pt: 2,
            px: 4,
            pb: 3,
          }}
        >
          <h4 style={{ textAlign: "center" }} id="delete-confirmation-title">
            Do you want to delete this document?
          </h4>
          <div className={classes.columnDeleteBtncontainer}>
            <div>
              <Button
                onClick={() => setIsDeleteDocumentConfirmationOpen(false)}
                variant="contained"
                className={classes.columnDeleteBtnLabelStyle}
              >
                No
              </Button>
              <Button
                onClick={() => {
                  handleDeleteDocument();
                }}
                variant="contained"
                className={classes.columnDeleteBtnYes}
              >
                Yes
              </Button>
            </div>
          </div>
        </Box>
      </Modal>

      {/* Edit Document Modal */}
      <Modal
        open={isEditDocumentOpen}
        onClose={() => setIsEditDocumentOpen(false)}
        aria-labelledby="edit-document-title"
        aria-describedby="edit-document-description"
      >
        <Box
          sx={{
            position: "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            width: 450,
            height: 360,
            display: "flex",
            flexDirection: "column",
            bgcolor: "background.paper",
            justifyContent: "center",
            boxShadow: 24,
            borderRadius: "5px",
            pt: 2,
            px: 4,
            pb: 3,
          }}
        >
          <h4
            style={{
              textAlign: "center",
            }}
            id="edit-document-title"
          >
            Edit Document
          </h4>
          <div className={classes.updateProjectLabelContainer}>
            <div className={classes.textFiledLabelContainer}>
              <InputLabel className={classes.projectLabelStyle}
                style={{ marginTop: "14px", marginLeft: "-5px", fontWeight: 700 }}>
                Datasource Name <span style={{ color: 'red' }}>*</span>
              </InputLabel>
              <CssProjectTextField
                id="datasource-name"
                variant="outlined"
                size="small"
                value={editedName}
                onChange={(e) => setEditedName(e.target.value)}
                placeholder="Datasource Name"
                style={{
                  marginTop: "5px",
                  width: "90%",
                  height: "2.8375em",
                  marginLeft: "-1%",
                }}
              />
              <StyledButton
                onClick={() => setIsEditDocumentOpen(false)}
                className={classes.projectCancelBtnLabelStyle}
                variant="contained"
                style={{ marginLeft: '6%', marginTop: '25%' }}
              >
                Cancel
              </StyledButton>
              <StyledButton
                onClick={handleUpdateDocument}
                className={classes.nextProBtn}
                variant="contained"
                style={{ marginLeft: '50%', marginTop: '-20%' }}
              >
                Update
              </StyledButton>
            </div>
          </div>
        </Box>
      </Modal>
      <TableContainer className={classes.dblist}>
        <Table sx={{ marginTop: '2.4%' }}>
          <TableHead>
            <TableRow>
              <StyledTableCell>Sr. no</StyledTableCell>
              <StyledTableCell>Name</StyledTableCell>
              <StyledTableCell>Document Name</StyledTableCell>
              <StyledTableCell>Document Type</StyledTableCell>
              <StyledTableCell>Created By</StyledTableCell>
              <StyledTableCell>Created Date</StyledTableCell>
              <StyledTableCell>Action</StyledTableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {fileList.map((document, index) => (
              <StyledTableRow
                key={document.id}
                onClick={() => setSelectedDocumentIndex(index)}
                style={{
                  backgroundColor: index === selectedDocumentIndex ? "#cce6ff" : "white",
                }}
              >
                <StyledTableCell>{index + 1}</StyledTableCell>
                <StyledTableCell>{document.name}</StyledTableCell>
                <StyledTableCell>{document.fileName}</StyledTableCell>
                <StyledTableCell>{document.fileType}</StyledTableCell>
                <StyledTableCell>{document.createdBy}</StyledTableCell>
                <StyledTableCell>{new Date(document.createdDate).toLocaleDateString()}</StyledTableCell>
                <StyledTableCell>
                  <IconButton
                    onClick={(event) => handleDocumentMenuOpen(event, index)}
                  >
                    <MoreVertIcon />
                  </IconButton>
                </StyledTableCell>
              </StyledTableRow>
            ))}
          {/* </TableBody> */}
        {/* </Table>
      </TableContainer> */}

      <Menu
        anchorEl={documentAnchorEl}
        open={Boolean(documentAnchorEl)}
        onClose={handleDocumentMenuClose}
      >
        <MenuItem onClick={handleEditClick}>Edit</MenuItem>
        <MenuItem
          onClick={() => {
            setIsDeleteDocumentConfirmationOpen(true);
            handleDocumentMenuClose();
          }}
        >
          Delete
        </MenuItem>
      </Menu>
      </TableBody>
        </Table>
      </TableContainer>
    </>
  );
};

export default FileList;
