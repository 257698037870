import React, { useState } from "react";
import { Tabs, Tab, Button, Modal, Box } from "@mui/material";
import { useProjectStyle, StyledMainProjectButton } from "./MainProjectStyle";
import { getUserDetailsFromCookies } from "../../services";
import ProjectsList from "./project/ProjectsList";
import FilesList from "./datasource/csv_file/FilesList";
import DatabasesList from "./datasource/database/DatabaseList";
import { Images } from "../../constants";
import Guided from "../guide/Guided";
import clsx from "clsx";
import DataSource from "./datasource/DataSource";
import ProjectWizard from "./wizard/Wizard";
import AddDatabase from "./datasource/database/AddDatabase";
import { StyledProjectButton } from "./project/ProjectStyle";


const ProjectDetails: React.FC = () => {
  const [isWizardOpen, setIsWizardOpen] = useState(false);
  const [selectedTab, setSelectedTab] = useState(0);
  const [selectedProject, setSelectedProject] = useState<any>(null);
  const [isProjectCreated, setIsProjectCreated] = useState(false);
  const [isDatabaseCreated, setIsDatabaseCreated] = useState(false);
  const [showGuide, setShowGuide] = useState(
    getUserDetailsFromCookies()?.projectId ? false : true
  );
  const [showDataSource, setShowDataSource] = useState(false);
  const [showAddDatabase, setShowAddDatabase] = useState(false);
  const [isFileUploaded, setIsFileUploaded] = useState(false);
  const [isDatasourceImageSelected, setIsDatasourceImageSelected] =
    useState(false);
  const [guidedTourName, setGuidedTourName] = useState("guide1");
  const userRole = getUserDetailsFromCookies().roleName;
  const { classes } = useProjectStyle(userRole)();

  const handleOpenWizard = () => setIsWizardOpen(true);
  const handleCloseWizard = () => setIsWizardOpen(false);

  const handleTabChange = (event: React.SyntheticEvent, newValue: number) => {
    setSelectedTab(newValue);
  };

  const handleProjectSelect = (project: any) => {
    setSelectedProject(project);
  };

  const handleCloseProjectModal = () => {
    setIsProjectCreated(!isProjectCreated);
  };

  const handleOpenDataSourceModal = () => {
    setShowDataSource(true);
    setIsFileUploaded(false);
    setIsDatasourceImageSelected(false);
  };

  const handleCloseDataSourceModal = () => {
    setShowDataSource(false);
    setIsFileUploaded(false);
    setIsDatasourceImageSelected(false);
  };

  const handleFileUpload = () => {
    setIsFileUploaded(true);
  };

  const handleCloseDatabaseModal = () => {
    setIsDatabaseCreated(false);
    setShowAddDatabase(false);
    setIsDatabaseCreated(!isDatabaseCreated);
  };

  const handleDataSourceSelect = (selected: any) => {
    setIsDatasourceImageSelected(!!selected);
  };
  const handleNext = () => {
    if (isDatasourceImageSelected && isFileUploaded) {
      // Close AddDataSource modal and open AddDatabase modal
      setShowAddDatabase(true);
      setShowDataSource(false);
      console.log("Transitioning to AddDatabase page");
    } else {
      // Log message if conditions are not met
      console.log("Cannot transition to AddDatabase page. Ensure datasource image is selected and file is uploaded.");
    }
  };
  

  return (
    <div className={classes.parent}>
      {showAddDatabase && (
        <Modal
          open={showAddDatabase}
          onClose={() => setShowAddDatabase(false)}
          aria-labelledby="add-database-modal-title"
          aria-describedby="add-database-modal-description"
        >
          <Box
            sx={{
              position: "absolute",
              top: "50%",
              left: "50%",
              transform: "translate(-50%, -50%)",
              width: 1165,
              height: 570,
              bgcolor: "background.paper",
              boxShadow: 24,
              p: 4,
              borderRadius: 2,
            }}
          >
            <AddDatabase
              onCancel={handleCloseDatabaseModal}
              onNext={handleNext}
            />
          </Box>
        </Modal>
      )}
      {showDataSource && (
        <Modal
          open={showDataSource}
          onClose={handleCloseDataSourceModal}
          aria-labelledby="add-datasource-modal-title"
          aria-describedby="add-datasource-modal-description"
        >
          <Box
            sx={{
              position: "absolute",
              top: "50%",
              left: "50%",
              transform: "translate(-50%, -50%)",
              width: 860,
              height: 590,
              bgcolor: "background.paper",
              boxShadow: 24,
              p: 4,
              borderRadius: 2,
            }}
          >
            <DataSource
              onDatabaseSelection={handleDataSourceSelect}
              onCsvSelection={handleFileUpload}
              onNext={handleNext}
              onCancel={handleCloseDataSourceModal}
              onFinish={handleCloseDataSourceModal}
            />
          </Box>
        </Modal>
      )}
      
      {getUserDetailsFromCookies().roleName !== "Viewer" &&
        getUserDetailsFromCookies().roleName !== "Contributor" &&
        showGuide && (
          <Guided
            handleCloseProjectModal={handleCloseProjectModal}
            name={guidedTourName}
            handleNext={handleNext}
          />
        )}

      <div className={classes.forproject}>
        <div className={classes.datahead}>
          My Projects
          <div
            className={clsx(
              classes.btnProjectTestContainer,
              classes.marginNewPro
            )}
          >
            {getUserDetailsFromCookies().roleName !== "Viewer" &&
              getUserDetailsFromCookies().roleName !== "Contributor" && (
                <StyledMainProjectButton
                  onClick={handleOpenWizard}
                  startIcon={<img src={Images.createproject_placeholder} />}
                  className={classes.btnProjectTestLabelStyle}
                  variant="contained"
                  size="medium"
                >
                  New Project
                </StyledMainProjectButton>
              )}
            <ProjectWizard open={isWizardOpen} onClose={handleCloseWizard} />
          </div>
        </div>
        <ProjectsList onProjectSelect={handleProjectSelect} />
      </div>

      <div className={classes.middle}></div>

      <div className={classes.fordatabase}>
        <div className={classes.datahead}>
          My Datasources
          <Box
            sx={{
              borderBottom: 1,
              position: "absolute",
              borderColor: "divider",
              borderRadius: "5px",
              fontFamily: "Fonts.NunitoSans.regular",
              fontStyle: "normal",
              fontSize: "18px",
              fontWeight: 700,
              display: "flex",
              justifyContent: "center",
              color: "#000000",
              lineHeight: "25px",
              backgroundColor: "#fff",
              padding: "-5px",
              boxShadow: "0 2px 4px rgba(0,0,0,0.1)",
              marginTop: "5%",
              marginLeft: "-2%",
              "&:hover": {
                background: "#fff",
              },
              "&:focus": {
                color: "#ffffff",
                backgroundColor: "#87CEEB",
              },
            }}
          >
            <Tabs
              value={selectedTab}
              onChange={handleTabChange}
              aria-label="datasource tabs"
            >
              <Tab label="Databases" />
              <Tab label="Documents" />
            </Tabs>
          </Box>
          <div
            className={clsx(
              classes.btnProjectTestContainer,
              classes.marginNewPro
            )}
          >
            {getUserDetailsFromCookies().roleName !== "Viewer" && (
              <StyledMainProjectButton
                onClick={handleOpenDataSourceModal}
                startIcon={<img src={Images.createproject_placeholder} />}
                className={classes.btnDatasourceTestLabelStyle}
                variant="contained"
                size="medium"
              >
                Add Datasource
              </StyledMainProjectButton>
            )}
          </div>
        </div>
        {selectedTab === 0 && <DatabasesList projectDetails={selectedProject} />}
        {selectedTab === 1 && <FilesList projectDetails={selectedProject} />}
      </div>
    </div>
  );
};

export default ProjectDetails;
