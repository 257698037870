import {
  styled,
  Button,
  TextareaAutosize,
  makeStyles,
  TextField,
} from "../../../../app.module";
import { Fonts} from "../../../constants";
export const useWizardStyle = () =>
  makeStyles()((theme?: any) => {
    return {
      wizardContainer: {
        position: "absolute" as "absolute",
        top: "50%",
        left: "50%",
        transform: "translate(-50%, -50%)",
        width: "100%",
        boxShadow: "24px",
        p: 4,
        padding: "10%",
        outline: "none",
      },
      leftPanel: {
        backgroundColor: "#f5f5f5",
        padding: "20px",
        borderTopLeftRadius: "5px",
        borderBottomLeftRadius: "5px",
      },
      stepItem: {
        display: "flex",
        alignItems: "center",
        marginBottom: "32px",
        fontSize:"18px"
      },
      stepIcon: {
        width: "20px",
        height: "20px",
        marginRight: "15px",
        marginTop:"8%"
      },
      stepLabel: {
        fontFamily: theme.typography.fontFamily,
        fontWeight: 700,
        fontSize: "18px",
        color: "#000",
        marginTop:"10%"
      },
      contentPanel: {
        display: "flex",
        flexDirection: "column",
        padding: "20px",
      },
      contentContainer: {
        flex: 1,
        overflowY: "auto",
      },
      buttonContainer: {
        display: "flex",
        justifyContent: "flex-end",
        marginTop: "20px",
      },
      DbContainer: {
        scrollbarWidth: "none",
        "&::-webkit-scrollbar": {
          width: "0.5rem",
        },
        "&::-webkit-scrollbar-track": {
          backgroundColor: "transparent",
        },
        "&::-webkit-scrollbar-thumb": {
          backgroundColor: "transparent",
        },
      },
      backButton: {
        fontFamily: Fonts.NunitoSans.regular,
        fontStyle: "normal",
        fontWeight: 700,
        fontSize: "18px",
        lineHeight: "25px",
        backgroundCo: "#fff",
        color: "#000",
        textTransform: "capitalize",
        height: "57px",
        width: "155px",
        borderRadius: "5px",
        marginRight: "15px",
        marginBottom: "20px",
        marginLeft: "800px",
        "&:hover": {
          background: "#fff",
        },
        "&:focus": {
          background: "#fff",
        },
      },
      nextButton: {
        background:
          "linear-gradient(90deg, #5EB0FA 0%, #1157CD 50.54%, #9C90FA 100%)",
        fontFamily: Fonts.NunitoSans.regular,
        fontStyle: "normal",
        fontWeight: 700,
        fontSize: "18px",
        lineHeight: "25px",
        borderRadius: "5px",
        textTransform: "capitalize",
        textColor: "white",
        height: "57px",
        width: "155px",
        marginBottom: "20px",
        "&:disabled": {
          background: "#ecedf2",
          color: "#cccdd2",
        },
      },
      closeButton: {
        maxWidth: "20px",
        height: "20px",
        padding: "1px",
        marginRight: "15px",
        marginTop: "2px",
      },
    };
  });

export const StyledWizardButton = styled(Button)({
  backgroundColor: "#fff",
  color: "#fff",
  padding: "6px 12px",
  "&:hover": {
    backgroundColor: "#fff",
  },
});
