// ProjectWizard.jsx
import React, { useState } from "react";
import { Modal, Typography, IconButton, Grid } from "@mui/material";
import { Close } from "@mui/icons-material";
import CreateProject from "../project/CreateProject";
import DataSource from "../datasource/DataSource";
import AddDatabase from "../datasource/database/AddDatabase";
import { Images } from "../../../constants";
import { useWizardStyle } from "./WizardStyle";
import FinishProject from "../project/FinishProject";

const initialStep = [
  { key: "project", label: "New project" },
  { key: "datasource", label: "Datasource" },
  { key: "getstarted", label: "Get started" },
];
const Databasesteps = [
  { key: "project", label: "New project" },
  { key: "datasource", label: "Datasource" },
  { key: "database", label: "Database" },
  { key: "getstarted", label: "Get started" },
];

const ProjectWizard = ({
  open,
  onClose,
}: {
  open: boolean;
  onClose: () => void;
}) => {
  const { classes } = useWizardStyle()();
  const [currentStep, setCurrentStep] = useState("project");
  const [steps, setSteps] = useState(initialStep);

  const resetWizardState = () => {
    setSteps(initialStep);
    setCurrentStep("project");
    onClose();
  };
  const handleCsvSelection = (isCsvSelected: boolean) => {
    if (isCsvSelected) {
    } else {
    }
  };

  const handleDatabaseSelection = (isDatabaseSelected: boolean) => {
    if (isDatabaseSelected) {
      setSteps(Databasesteps);
    } else {
      setSteps(initialStep);
    }
  };

  const handleNextFromDatasource = () => {
    if (steps.some((step) => step.key === "database")) {
      setCurrentStep("database");
    } else {
      setCurrentStep("getstarted");
    }
  };

  const handleBack = () => {
    setCurrentStep("datasource");
  };
  const handleNext = () => {
    const currentIndex = steps.findIndex((step) => step.key === currentStep);
    if (currentIndex < steps.length - 1) {
      if (
        currentStep === "datasource" &&
        !steps.some((step) => step.key === "database")
      ) {
        setCurrentStep("getstarted");
      } else {
        setCurrentStep(steps[currentIndex + 1].key);
      }
    } else {
      onClose();
    }
  };

  const handleFinishFromDatasource = () => {
    setCurrentStep("getstarted");
  };

  const renderStepContent = () => {
    switch (currentStep) {
      case "project":
        return <CreateProject onNext={handleNext} onCancel={onClose} />;
      case "datasource":
        return (
          <DataSource
            onNext={handleNextFromDatasource}
            onCancel={onClose}
            onDatabaseSelection={handleDatabaseSelection}
            onCsvSelection={handleCsvSelection}
            onFinish={handleFinishFromDatasource}
          />
        );
      case "database":
        return <AddDatabase onNext={handleNext} onCancel={handleBack} />;
      case "getstarted":
        return <FinishProject onCancel={resetWizardState} />;
      default:
        return null;
    }
  };

  return (
    <Modal open={open} onClose={onClose}>
      <div className={classes.wizardContainer}>
        <Grid container spacing={1}>
          <Grid
            style={{
              backgroundColor: "#fff",
              display: "flex",
              flex: 1,
              flexDirection: "column",
              justifyContent: "flex-start",
              alignItems: "flex-start",
              paddingTop: "55px",
              paddingLeft: "60px",
              borderTopLeftRadius: 5,
              borderBottomLeftRadius: 5,
              height: "94vh",
            }}
            item
            xs={6}
          >
            {steps.map((step, index) => (
              <div key={step.key} className={classes.stepItem}>
                <img
                  src={
                    index <= steps.findIndex((s) => s.key === currentStep)
                      ? Images.checked
                      : Images.not_checked
                  }
                  alt={step.key}
                  className={classes.stepIcon}
                />
                <Typography className={classes.stepLabel}>
                  {step.label}
                </Typography>
              </div>
            ))}
          </Grid>

          <Grid
            style={{
              background: "#fff",
              display: "flex",
              flex: 3,
              flexDirection: "column",
              paddingTop: "55px",
              maxHeight: "100vh",
            }}
            className={classes.DbContainer}
            item
            xs={8}
          >
            {renderStepContent()}
          </Grid>
          <div
            style={{
              display: "flex",
              flex: 0.01,
              padding: "10px",
              background: "#fff",
              borderTopRightRadius: 5,
              borderBottomRightRadius: 5,
            }}
          >
            <IconButton className={classes.closeButton} onClick={onClose}>
              <Close />
            </IconButton>
          </div>
        </Grid>
      </div>
    </Modal>
  );
};

export default ProjectWizard;
