//@ts-nocheck
import {
  React,
  Paper,
  useMemo,
  Tabs,
  Tab,
  useState,
  Box,
  Typography,
  Button,
  Select,
  MenuItem,
  clsx,
  useEffect,
  ListItemIcon,
  FiberManualRecord,
  Rectangle,
  Backdrop,
  CircularProgress,
  useNavigate,
  useLocation,
} from "../../../../app.module";
import { ResponsiveAreaBump } from "@nivo/bump";
import { useStyle } from "./DashboardBarChartStyle";
import { Colors, Images } from "../../../constants";

interface AxisBottom {
  orient: any;
  tickSize: any;
  tickPadding: any;
  tickRotation: any;
  legend: any;
  legendPosition: any;
  legendOffset: any;
}

const AreaBumpForDashboard = React.memo((props: any) => {
  const [areaBumpJson, setAreaBumpJson] = useState<any>([]);
  const [isLoading, setIsLoading] = useState(true);
  const [activeTab, setActiveTab] = React.useState(0);
  const { classes } = useStyle({ isYaxis: false, isXaxis: true, activeTab });

  useEffect(() => {
    let { data } = props.data;
    console.log("AreaBump CHART", data);
    setAreaBumpJson(data);
  }, []);
  const typeAxisBottom: AxisBottom = {
    orient: "bottom",
    tickSize: 5,
    tickPadding: 5,
    tickRotation: 0,
    legend: "weight",
    legendPosition: "middle",
    legendOffset: 46,
  };
  const typeAxisLeft: AxisBottom = {
    orient: "left",
    tickSize: 5,
    tickPadding: 5,
    tickRotation: 0,
    legend: "size",
    legendPosition: "middle",
    legendOffset: -60,
  };

  return (
    <div
      key="AREABUMPCHART101"
      style={{
       
        //Colors?.background.backgroundColorTheme3,
        height: "100%",
        width: "100%",
        overflowX: "auto",
        // boxShadow: "0px 0px 4px 0px #00000040",
        // borderRadius: "10px 0px 0px 10px",
        overflow: "scroll",
        top: 0,
      }}
    >
      {props?.pageError !== "" ? (
        <div
          key="TAB101"
          style={{
            display: "flex",
            height: "87vh",
            width: "100%",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <img
            style={{
              height: "auto",
              width: "300px",
            }}
            src={Images.no_data_img}
          />
        </div>
      ) : (
        <div
          key="CHART1005"
          style={{
            width: "300%",
            height: "100%",
          }}
        >
          {props.name}
          <ResponsiveAreaBump
            data={areaBumpJson}
            margin={{ top: 40, right: 100, bottom: 40, left: 100 }}
            spacing={8}
            colors={{ scheme: "nivo" }}
            blendMode="multiply"
            defs={[
              {
                id: "dots",
                type: "patternDots",
                background: "inherit",
                color: "#38bcb2",
                size: 4,
                padding: 1,
                stagger: true,
              },
              {
                id: "lines",
                type: "patternLines",
                background: "inherit",
                color: "#eed312",
                rotation: -45,
                lineWidth: 6,
                spacing: 10,
              },
            ]}
            startLabel="id"
            endLabel="id"
            axisTop={{
              tickSize: 5,
              tickPadding: 5,
              tickRotation: 0,
              legend: "",
              legendPosition: "middle",
              legendOffset: -36,
            }}
            axisBottom={{
              tickSize: 5,
              tickPadding: 5,
              tickRotation: 0,
              legend: "",
              legendPosition: "middle",
              legendOffset: 32,
            }}
          />
        </div>
      )}
    </div>
  );
});

export default AreaBumpForDashboard;
