import React, { useState, useEffect } from 'react';
import { Table, TableBody, TableCell, TableContainer, TableHead, TableRow, IconButton, Menu, MenuItem, styled, tableCellClasses, Button, Modal, Box } from '@mui/material';
import MoreVertIcon from "@mui/icons-material/MoreVert";
import { useProjectStyle } from "./ProjectStyle";
import { dogetproject, doDeleteProject, defaultProject, GetProjectsResults } from "./ProjectService";
import clsx from 'clsx';
import { result } from 'lodash';
import { index } from 'd3-array';
import Cookies from 'js-cookie';
import UpdateProject from './UpdateProject';
import { getUserDetailsFromCookies } from '../../../services';



const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: "#F6F8FF",
    color: "#000",
    fontSize: 14,
    fontWeight: "bold",
    border: "1px solid",
    padding: 4,
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 12,
    padding: 0,
  },
  textAlign: "center",
  cursor: "pointer",
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
  "&:nth-of-type(odd)": {
    backgroundColor: "#fff",
  },
  // hide last border
  "&:last-child td, &:last-child th": {
    border: 0,
  },
}));


interface ProjectsListProps {
  onProjectSelect: (project: any) => void;
}

const ProjectsList: React.FC<ProjectsListProps> = ({ onProjectSelect }) => {
  const [projectList, setProjectList] = useState<any[]>([]);
  const [selectedProjectIndex, setSelectedProjectIndex] = useState(0);
  const [projectAnchorEl, setProjectAnchorEl] = useState<null | HTMLElement>(null);
  const [isDeleteProjectConfirmationOpen, setIsDeleteProjectConfirmationOpen] = useState(false);
  const [editProjectName, setEditProjectName] = useState("");
  const [editedDescription, setEditedDescription] = useState("");
  const [isEditClicked, setIsEditClicked] = useState(false);
  const [editedProject, setEditedProject] = useState(null);
  const [isProjectUpdated, setIsProjectUpdated] = useState(false);
  const userRole = getUserDetailsFromCookies().roleName;
  const [selectedProjectId, setSelectedProjectId] = useState(null);
  const [projectMenuAnchorEl, setProjectMenuAnchorEl] = useState<{ [key: number]: HTMLElement | null }>({});
  const { classes } = useProjectStyle(userRole)();


  const selectedProject = projectList.find((p) => p.id === selectedProjectId);
  useEffect(() => {
    fetchProjects();
  }, []);

  const fetchProjects = async () => {
    try {
      const projects = await dogetproject();
      setProjectList(projects);
      if (projects.length > 0) {
        onProjectSelect(projects[0]);
      }
    } catch (error) {
      console.error("Error fetching projects:", error);
    }
  };

  const handleProjectSelect = (project: any, index: number) => {
    setSelectedProjectIndex(index);
    let userDetails = getUserDetailsFromCookies();
    userDetails.projectId = project.id;
    Cookies.set("user_details", JSON.stringify(userDetails));
    console.log("projectindex", index);
    console.log("selected project ID", project.id);
    onProjectSelect(project);
  };

  console.log("cokieessss", getUserDetailsFromCookies())

  const handleProjectMenuOpen = (event: any, index: number) => {
    // setProjectAnchorEl(event.currentTarget);

    setProjectMenuAnchorEl((prevState) => ({
      ...prevState,
      [index]: event.currentTarget,
    }));
  };


  const handleProjectMenuClose = (index: number) => {
    setProjectMenuAnchorEl((prevState) => ({
      ...prevState,
      [index]: null,
    }));
  };
  const handleEditProject = (project: any) => {
    console.log("editproject", projectAnchorEl)
    setProjectAnchorEl(null);
    setIsProjectUpdated(true);
    
  };

  console.log("Cookiesss", getUserDetailsFromCookies())

  const handleDeleteProject = async () => {
    console.log("deleteindex", projectList[selectedProjectIndex])
    const projectToDelete = projectList[selectedProjectIndex];
    console.log("projectToDelete", projectToDelete);
    await doDeleteProject(projectToDelete.id);
    fetchProjects();
    handleProjectMenuClose(90);
  };


  const handleCloseUpdateProjectModal = () => {
    console.log("uppppdateeee", isProjectUpdated);
    setIsProjectUpdated(!isProjectUpdated);
  };



  const handleCancelDelete = () => {
    setIsDeleteProjectConfirmationOpen(false);
  };


  const defProject = async () => {
    console.log("selected row index................", selectedProjectIndex);
    let defaultproj = {
      userId: getUserDetailsFromCookies().id,
      projectId: projectList[selectedProjectIndex].id,
    };
    defaultProject(defaultproj)
      .then((resp) => {
        if (!resp?.isError) {
          let userDetails = getUserDetailsFromCookies();
          userDetails.projectId = defaultproj.projectId;
          Cookies.set("user_details", JSON.stringify(userDetails));
        } else {
          console.error("Error selecting default project", resp);
        }
      })
      .catch((error) => {
        console.error("response error", error);
      });
    setProjectAnchorEl(null);
  };

  console.log("selected project index", selectedProjectIndex)
  console.log("deleteindexbut", projectList[selectedProjectIndex])
  return (
    <div className={classes.parent}>
      {isProjectUpdated && (
        <UpdateProject
          projectDetails={projectList[selectedProjectIndex]}
          closeDatabaseModal={handleCloseUpdateProjectModal}
        />
      )}

      {isDeleteProjectConfirmationOpen && (
        <Modal
          open={isDeleteProjectConfirmationOpen}
          onClose={handleCancelDelete}
          aria-labelledby="delete-confirmation-title"
          aria-describedby="delete-confirmation-description"
        >
          <Box
            sx={{
              position: "absolute" as "absolute",
              top: "50%",
              left: "50%",
              transform: "translate(-50%, -50%)",
              width: 400,
              display: "flex",
              flexDirection: "column",
              bgcolor: "background.paper",
              justifyContent: "center",
              boxShadow: 24,
              borderRadius: "5px",
              pt: 2,
              px: 4,
              pb: 3,
            }}
          >
            <h4
              style={{
                textAlign: "center",
              }}
              id="child-modal-title"
            >
              Do you want to delete { } project?
            </h4>
            <div className={clsx(classes.columnDeleteBtncontainer)}>
              <div>
                <Button
                  onClick={handleCancelDelete}
                  variant="contained"
                  className={classes.columnDeleteBtnLabelStyle}
                >
                  No
                </Button>
                <Button
                  onClick={() => {
                    console.log("deleteindexbut", projectList[selectedProjectIndex])
                    handleDeleteProject();
                    setIsDeleteProjectConfirmationOpen(false);
                  }}
                  variant="contained"
                  className={classes.columnDeleteBtnYes}
                >
                  Yes
                </Button>
              </div>
            </div>
          </Box>
        </Modal>
      )}

      <TableContainer className={classes.prolist}>
        <Table sx={{ marginTop: '-0%' }} aria-label="customized table" >
          <TableHead>
            <TableRow>
              <StyledTableCell>Sr. no</StyledTableCell>
              <StyledTableCell>Projects</StyledTableCell>
              <StyledTableCell>Project Description</StyledTableCell>
              <StyledTableCell>Project Admin</StyledTableCell>
              <StyledTableCell>Created By</StyledTableCell>
              <StyledTableCell>Created Date</StyledTableCell>
              <StyledTableCell>Action</StyledTableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {projectList.map((project: any, index: number) => (
              <StyledTableRow
                key={project.id}
                onClick={() => handleProjectSelect(project, index)}
                style={{
                  backgroundColor: index === selectedProjectIndex ? "#cce6ff" : "white",
                }}
              >
                <StyledTableCell>{index + 1}</StyledTableCell>
                <StyledTableCell>{project?.name}</StyledTableCell>
                <StyledTableCell>{project?.description}</StyledTableCell>
                <StyledTableCell>{project?.createdbyName}</StyledTableCell>
                <StyledTableCell>{project?.createdbyName}</StyledTableCell>
                <StyledTableCell>{new Date(project?.createdDate).toLocaleDateString()}</StyledTableCell>
                <StyledTableCell>
                  <IconButton
                    aria-label="more"
                    aria-controls={`project-menu-${index}`}
                    aria-haspopup="true"
                    onClick={(e) => handleProjectMenuOpen(e, index)}
                  >
                    <MoreVertIcon />
                  </IconButton>

                  <Menu
                    id={`project-menu-${index}`}
                    anchorEl={projectMenuAnchorEl[index]}
                    keepMounted
                    open={Boolean(projectMenuAnchorEl[index])}
                    onClose={() => handleProjectMenuClose(index)}
                  >
                    <MenuItem
                      onClick={() => {
                        setEditProjectName(project?.name);
                        setEditedDescription(project?.description);
                        handleEditProject(index);
                        setIsEditClicked(true);
                        // console.log("result", result.name);
                      }}
                      disabled={getUserDetailsFromCookies().roleName == 'Contributor' || getUserDetailsFromCookies().roleName == 'Viewer' ? true : false}
                    >
                      Edit
                    </MenuItem>

                    <MenuItem
                      onClick={() => {
                        console.log("projectid........", result);
                        setIsDeleteProjectConfirmationOpen(true);
                        setProjectAnchorEl(null);
                      }}
                      disabled={getUserDetailsFromCookies()?.roleName == 'Contributor' || getUserDetailsFromCookies()?.roleName == 'Project Admin' || getUserDetailsFromCookies().roleName == 'Viewer' ? true : false}
                    >
                      Delete
                    </MenuItem>
                    <MenuItem
                      onClick={() => {
                        console.log("projectid........", result);
                        onclick = () => {
                          defProject();
                          setProjectAnchorEl(null);
                        };
                      }}
                    >
                      Set as Default
                    </MenuItem>
                  </Menu>
                </StyledTableCell>
              </StyledTableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </div>
  );
};

export default ProjectsList;