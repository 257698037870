import {
  useEffect,
  useState,
  InputLabel,
  Grid,
  DialogContent,
  Typography,
  DialogContentText,
  Dialog,
  Button,
} from "../../../../app.module";
import {
  useProjectStyle,
  CssProjectTextField,
  CssProjectTextareaAutosize,
  StyledProjectButton,
} from "./ProjectStyle";
import { getUserDetailsFromCookies } from "../../../services";
import { createNewProject } from "./ProjectService";
import { Images } from "../../../constants";
import Cookies from "js-cookie";

const CreateProject = ({
  onNext,
  onCancel,
}: {
  onNext: () => void;
  onCancel: () => void;
}) => {
  const userRole = getUserDetailsFromCookies().roleName;
  const { classes } = useProjectStyle(userRole)();
  const [isDialogOpen, setIsDialogOpen] = useState<boolean>(false);
  const [exceptionMessage, setexceptionMessage] = useState("");

  const [formValues, setFormValues] = useState({
    project_name: {
      value: "",
      error: false,
      errorMessage: "You must enter project name",
    },
    description: {
      value: "",
      error: false,
      errorMessage: "You must enter project description",
    },
  });

  const validateCompanyDetails = (label: {
    project_name?: string;
    description?: string;
    database_type?: string;
    database_name?: string;
    database_provider?: string;
  }) => {
    let validate_pro_data = formValues;
    let isError = false;

    Object.entries(validate_pro_data).forEach(([key, value]) => {
      let cmp_key = key as keyof typeof validate_pro_data;
      if (cmp_key === label?.project_name || cmp_key === label?.description) {
        if (value.value === "" || value.value === "none") {
          validate_pro_data[cmp_key].error = true;
          isError = true;
        } else {
          validate_pro_data[cmp_key].error = false;
        }
      }
      if (
        label.database_name === cmp_key ||
        label.database_provider === cmp_key ||
        label.database_type === cmp_key
      ) {
        if (value.value === "" || value.value === "none") {
          validate_pro_data[cmp_key].error = true;
          isError = true;
          console.log("key value", label?.project_name);
        } else {
          validate_pro_data[cmp_key].error = false;
        }
      }
    });

    setFormValues({ ...validate_pro_data });
    return isError;
  };
  const createProject = async () => {
    if (
      !validateCompanyDetails({
        project_name: "project_name",
        description: formValues.description.value,
        database_name: "",
        database_provider: "",
        database_type: "",
      })
    ) {
      let projectDetails = {
        name: formValues.project_name.value,
        description: formValues.description.value,
        createdBy: getUserDetailsFromCookies().id,
      };

      try {
        const resp = await createNewProject(projectDetails);
        console.log("createNewProject", resp);
        if (resp.statusCode !== 200) {
          setexceptionMessage(resp.responseException.exceptionMessage);
          setIsDialogOpen(true);
          setTimeout(() => {
            setIsDialogOpen(false);
          }, 5000);
          return;
        }
        onNext();
        let userDetails = getUserDetailsFromCookies();
        userDetails.projectId = resp?.result?.id;
        Cookies.set("user_details", JSON.stringify(userDetails));
      } catch (error) {
        console.error("Error creating project", error);
        setIsDialogOpen(true);
        setTimeout(() => {
          setIsDialogOpen(false);
          onCancel();
        }, 5000);
      }
    }
  };

  const handleProjectStateChange = (e: any) => {
    const { name, value } = e.target;

    switch (name) {
      case "project_name":
        setFormValues({
          ...formValues,
          ["project_name"]: {
            ...formValues["project_name"],
            value,
            error: value.length >= 30,
            errorMessage:
              value.length >= 30
                ? "Project name cannot exceed 30 characters"
                : "",
          },
        });

        break;

      case "description":
        setFormValues({
          ...formValues,
          ["description"]: {
            ...formValues["description"],
            value,
            error: value.length >= 1000,
            errorMessage:
              value.length >= 1000 ? "You can enter only 1000 characters" : "",
          },
        });
        break;
    }
  };

  return (
    <div>
      <div className={classes.textFiledLabelContainer}>
        <InputLabel className={classes.projectLabelStyle}>
          Project name <span className={classes.asterix}>*</span>
        </InputLabel>
        <CssProjectTextField
          required
          fullWidth
          onChange={handleProjectStateChange}
          value={formValues.project_name.value}
          name="project_name"
          error={formValues.project_name.error}
          helperText={
            formValues.project_name.error
              ? "You can enter only 30 characters"
              : ""
          }
          placeholder="Project name"
          inputProps={{
            maxLength: 30,
          }}
          InputProps={{
            classes: { input: classes.proInput },
          }}
          id="project-name-custom-css-standard-input"
        />
      </div>
      <div className={classes.textFiledLabelContainer}>
        <InputLabel className={classes.projectLabelStyle}>
          Description
        </InputLabel>
        <CssProjectTextareaAutosize
          required
          onChange={handleProjectStateChange}
          value={formValues.description.value}
          name="description"
          className={classes.proTextareaInput}
          placeholder="Description"
          minRows={6}
          maxLength={1000}
          id="pro-description"
          aria-label="maximum height"
        />
      </div>
      <div className={classes.btns}>
        <StyledProjectButton
          onClick={() => {
            onCancel();
          }}
          variant="contained"
          className={classes.projectCancelBtnLabelStyle}
        >
          Cancel
        </StyledProjectButton>

        <Button
          onClick={createProject}
          variant="contained"
          className={classes.nextProBtn}
          disabled={formValues.project_name.value === ""}
        >
          Next
        </Button>
      </div>{" "}
      <Dialog
        open={isDialogOpen}
        onClose={() => setIsDialogOpen(false)}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        PaperProps={{
          style: {
            width: "650px",
            height: "120px",
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
            justifyContent: "flex-start",
            backgroundColor: "#FBCED6",
            borderRadius: "10px",
            border: "1px solid #FC6286",
            overflow: "hidden",
          },
        }}
      >
        <img
          src={Images.cancel}
          alt="Send Invite"
          style={{
            marginRight: "16px",
          }}
        />
        <Grid
          container
          direction="column"
          justifyContent="flex-start"
          style={{ flex: 1 }}
        >
          <Grid item>
            <Typography
              variant="h6"
              component="h2"
              style={{
                fontWeight: "bold",
                fontSize: "1.2rem",
                color: "black",
                textAlign: "left",
                marginLeft: "1px",
                marginBottom: "0px",
              }}
            >
              Error
            </Typography>
          </Grid>
          <Grid item>
            <DialogContent style={{ padding: "0px" }}>
              <DialogContentText
                id="alert-dialog-description"
                style={{
                  fontSize: "1rem",
                  color: "black",
                  wordBreak: "break-word",
                  textAlign: "left",
                  marginTop: "4px",
                }}
              >
                {exceptionMessage}
              </DialogContentText>
            </DialogContent>
          </Grid>
        </Grid>
      </Dialog>
    </div>
  );
};

export default CreateProject;
