import "./theme/scss/_main.scss";
import { AppContext } from "./app/context/AppContext";
import {
  Router,
  Routes,
  Route,
  React,
  createTheme,
  ThemeProvider,
  CssBaseline,
  useLocation,
  useIsAuthenticated,
  useMsal,
  InteractionRequiredAuthError,
  protectedResources,
  useState,
  UnauthenticatedTemplate,
  AuthenticatedTemplate,
  Cookies,
} from "./app.module";
import { Layout, CircularLoader } from "./app/shared_component";
import {
  CompanyDetails,
  Marketing,
  ClientDashboard,
  ConfirmEmail,
  Contactus,
  Guided,
  Plan,
  OrderSummary,
  CustomDrawer,
} from "./app/components";
import AppNavigation from "./app/routing/AppNavigation";
// import ObservableCharts from "./app/components/modules/ObservableCharts";
import { MsalProvider } from "@azure/msal-react";
import { PublicClientApplication } from "@azure/msal-browser";
import { MsalAuthProvider } from "./app/services/msal/MsalAuthProvider";
import { nav } from "../src/app/shared_component/navigation_history/ProtectedRoutes";
import { useEffect } from "react";
import { getUserDetailsFromCookies } from "./app/services";
import NotFound from "./app/shared_component/NotFound";
import ProtectedRoute from "./app/components/auth/ProtectedRoute";
import BoxPlot from "./app/components/modules/nivocharts/BoxPlot";
import RadialChart from "./app/components/modules/nivocharts/RadialChart";
interface NavItem {
  path: string;
  element: React.ReactNode;
  isPrivate: boolean;
  isMenu: boolean;
  name: string;
}
const Loading = () => <CircularLoader />;
function App() {
  //const location = useLocation();
  const isAuthenticated = useIsAuthenticated();
  const { instance, accounts } = useMsal();
  const [getTempUserId, setTempUserId] = useState(0);
  const [getUserStatus, setUserStatus] = useState(true);
  const [getProjectStatus, setProjectStatus] = useState(true);
  const [getDatabaseStatus, setDatabaseStatus] = useState(true);
  const [isAuthUser, setIsAuthUser] = useState<boolean | undefined>(false);
  const isUser = getUserDetailsFromCookies()?.id;
  const identifier = getUserDetailsFromCookies()?.identifier;
  //? true : false;
  const theme_mui = createTheme({
    palette: {
      error: {
        main: "#d32f2f",
      },
    },
    typography: {
      fontFamily: ['"Nunito Sans"'].join(","),
    },
  });

  useEffect(() => {
    // const fetchUserDetails = async () => {
    //   try {
    //     setTimeout(() => {
    //       setIsAuthUser(getUserDetailsFromCookies()?.id ? true : false);
    //       console.log(
    //         "USER STATE",
    //         getUserDetailsFromCookies()?.id,
    //         isAuthUser
    //       );
    //     }, 2000);
    //   } catch (error) {
    //     console.error("Error parsing userDetails cookie:", error);
    //   }
    // };
    // fetchUserDetails();
  }, []);
  return (
    <ThemeProvider theme={theme_mui}>
      <CssBaseline />
      <AppContext>
        <MsalAuthProvider>
          <Router>
            <Routes>
              {/* <Route path="/" element={<Marketing />} /> */}
              {/* <Route
                path="auth/*"
                element={
                  <Authentication>
                    <Routes>
                      {nav?.map((r: NavItem, i: number) => {
                        return (
                          <Route key={i} path={r.path} element={r.element} />
                        );
                      })}
                    </Routes>
                  </Authentication>
                }
              /> */}
              {/* {nav?.map((r: NavItem, i: number) => {
                if (r.isPrivate && isAuthUser && identifier) {
                  return <Route key={i} path={r.path} element={r.element} />;
                } else if (r.isPrivate && isUser) {
                  return <Route key={i} path={r.path} element={r.element} />;
                } else if (!r.isPrivate) {
                  return <Route key={i} path={r.path} element={r.element} />;
                } else return null;
              })} */}

              <Route
                path="/"
                //element={<Marketing />}
                element={<ProtectedRoute Component={Marketing} />}
              />
              <Route
                path="/:tenantId/projects/*"
                //element={<AppNavigation />}
                element={
                  getUserDetailsFromCookies()?.identifier ? (
                    <AppNavigation />
                  ) : (
                    // <ProtectedRoute Component={AppNavigation} />
                    <ProtectedRoute Component={AppNavigation} />
                  )
                }
              />
              <Route path="contactus" element={<Contactus />} />
              <Route
                path="company"
                element={<CompanyDetails />}
                //element={<ProtectedRoute Component={CompanyDetails} />}
              />
              <Route path="confirmmail" element={<ConfirmEmail />} />
              {/* <Route path="linechart" element={<ObservableCharts />} /> */}
              <Route path="radial" element={<RadialChart />} />
              <Route
                element={<ProtectedRoute Component={Plan} />}
                path="plan"
                //element={<Plan />}
              />
              <Route
                path="order"
                element={<ProtectedRoute Component={OrderSummary} />}
                //element={<OrderSummary />}
              />
              {/* <Route path="boxplot" element={<BoxPlot />} /> */}
              <Route path="*" element={<NotFound />} />
            </Routes>
          </Router>
        </MsalAuthProvider>
      </AppContext>
    </ThemeProvider>
  );
}

export default App;
{
}
