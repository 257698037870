import {
    useEffect,
    useState,
    Modal,
    InputLabel,
    Grid,
} from "../../../../app.module";
import Button from "@mui/material/Button";
import {
    useProjectStyle,
    StyledProjectButton,
    CssProjectTextField,
    CssProjectTextareaAutosize,
} from "./ProjectStyle";
import { updateProject } from "./ProjectService";
import { getUserDetailsFromCookies } from "../../../services";
const UpdateProject = (props: any) => {
    const userRole = getUserDetailsFromCookies().roleName;
    const {classes} = useProjectStyle(userRole)();
    const [openProject, setOpenProject] = useState(false);
    const [formValues, setFormValues] = useState({
        project_name: {
            value: "",
            error: false,
            errorMessage: "You must enter project name",
        },
        description: {
            value: "",
            error: false,
            errorMessage: "You must enter project description",
        },
    });

    const projupdate = async () => {
        let upp = {
            id: props.projectDetails.id,
            name: formValues.project_name.value,
            description: formValues.description.value,
            modifiedBy: getUserDetailsFromCookies().id,
        };
        updateProject(upp).then((project_details) => {
            console.log("uppdata", upp);
            props.closeDatabaseModal();
        });
    };
    const handleProjectStateChange = (e: any) => {
        const { name, value } = e.target;
        switch (name) {
            case "project_name":
                setFormValues({
                    ...formValues,
                    ["project_name"]: {
                        ...formValues["project_name"],
                        value,
                        error: false,
                        errorMessage: "",
                    },
                });
                break;
            case "description":
                setFormValues({
                    ...formValues,
                    ["description"]: {
                        ...formValues["description"],
                        value,
                        error: false,
                        errorMessage: "",
                    },
                });
        };
    }
    const handleOpenProject = () => setOpenProject(true);
    const handleCloseProject = () => {
        const { closeDatabaseModal } = props;
        console.log("Handle Close pro", openProject);
        setOpenProject(false);
        closeDatabaseModal();
    };
    useEffect(() => {
        console.log("PRO DETAILAS", props.projectDetails)
        let pro_data = formValues;
        pro_data.project_name.value = props.projectDetails.name
        pro_data.description.value = props.projectDetails.description
        setFormValues(pro_data);
        handleOpenProject();

    }, []);
    return (
        <Modal
            open={openProject}
            onClose={handleCloseProject}
            aria-labelledby="simple-modal-title"
            aria-describedby="simple-modal-description"
        >
            <div className={classes.updateProjectBoxContainer}>
                <Grid container spacing={1} 
                sx={{
                   
                    justifyContent: "center",
                     alignItems: "center",   
                }}
                item
                         xs={5}>
                    
                    <Grid
                        style={{
                             backgroundColor: "#fff",
                            display: "flex",
                            flex: 1,
                            flexDirection: "column",
                            justifyContent: "flex-start",
                            alignItems: "flex-start",
                              paddingTop: "30px",
                              paddingLeft: "20px",
                            borderRadius: 5,
                            height:"400px",
                            
                        }}
                        item
                    >
                        <div className={classes.updateProjectLabelContainer}>
                            <div className={classes.textFiledLabelContainer}>
                                <InputLabel className={classes.projectLabelStyle}>
                                    Project name <span className={classes.asterix}> *</span>
                                </InputLabel>
                                <CssProjectTextField
                                    required
                                    fullWidth
                                    onChange={handleProjectStateChange}
                                    value={formValues.project_name.value}
                                    name="project_name"
                                    error={formValues.project_name.error}
                                    helperText={
                                        formValues.project_name.error
                                            ? "Please enter project name"
                                            : ""
                                    }
                                    placeholder="Project name" 
                                    inputProps={
                                        {
                                        }
                                    }
                                    InputProps={{
                                        classes: { input: classes.proInput },
                                    }}
                                    id="project-name-custom-css-standard-input"
                                />
                            </div>
                            <div className={classes.twoFieldsContainer}>
                                <div className={classes.textFiledLabelContainer}>
                                    <InputLabel className={classes.projectLabelStyle}>
                                        Description
                                    </InputLabel>
                                    <CssProjectTextareaAutosize
                                        required
                                        onChange={handleProjectStateChange}
                                        value={formValues.description.value}
                                        name="description"
                                        className={classes.proTextareaInput}
                                        minRows={5}
                                        placeholder="Description"
                                        id="pro-description"
                                    />
                                </div>
                            </div>
                            <StyledProjectButton
                                onClick={() => {
                                    handleCloseProject();
                                }}
                                variant="contained"
                                className={classes.projectCancelBtnLabelStyle}
                                sx={{
                                    marginTop: "-15%",
                                  }}
                            >
                                Cancel
                            </StyledProjectButton>
                            <Button
                                onClick={() => {
                                    projupdate();
                                    handleCloseProject();
                                }}
                                variant="contained"
                                className={classes.nextProBtn}
                                sx={{
                                    marginTop: "-15%",
                                  }}
                            >
                                Update
                            </Button>{" "}

                        </div>
                        
                    </Grid>
                </Grid>
            </div >
        </Modal>
    )
}

export default UpdateProject;