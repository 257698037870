import {
  getData,
  postData,
  getUserDetailsFromCookies,
} from "../../services";
import {
  CreateDatabase,
  GetQuery,
  CommonProject,
  CreateModule,
  GetPossibleVisualization,
  GetVisualizationState,
} from "../../constants/Endpoints";
import { Cookies } from "../../../app.module";
let TOKEN: string = "";

export const getQueryData = async (id: number) => {
  let query_id = id ? id : 1;
  return await getData(`/SdYmT9Ur/${GetQuery}/${query_id}`).then((result) => {
    console.log("asdjaskjdas", result);
    return result;
  });
};

export const getModuleListData = async () => {
  return await getData(
    `/${getUserDetailsFromCookies().identifier}/api/project/${
      getUserDetailsFromCookies().projectId
    }/module`
  ).then((result) => {
    return result;
  });
};


export const getPossibalVisualization = async (id: number) => {
  return await postData(
    // `/${getUserDetailsFromCookies().identifier}${CommonProject}${
    //   getUserDetailsFromCookies().projectId
    // }${GetPossibleVisualization}`,
    `/visualization/${getUserDetailsFromCookies().identifier}/query/${id}`,
    { queryId: id, userId: getUserDetailsFromCookies().id }
  ).then((result) => {
    return result;
  });
};

export const getDashboardListData = async () => {
  return await getData(
    `/${getUserDetailsFromCookies().identifier}/api/project/${
      getUserDetailsFromCookies().projectId
    }/dashboard`
  ).then((result) => {
    return result;
  });
};

export const autoSuggestion = async (databaseId: number | undefined) => {
  console.log(
    `/${getUserDetailsFromCookies().identifier}/api/project/${getUserDetailsFromCookies().projectId}/database/database/${databaseId}`,
  );
  return await getData(
    `/${getUserDetailsFromCookies().identifier}/api/project/${getUserDetailsFromCookies().projectId}/database/database/${databaseId}`
  ).then((result) => {
    return result;
  });
};


export const createQuery = async (query_details: any) => {
  console.log(
    `/${getUserDetailsFromCookies().identifier}${GetQuery}`,
    "DATA SETS",
    query_details
  );
  return await postData(
    `/${getUserDetailsFromCookies().identifier}/process`,
    query_details
  ).then((result) => {
    console.log("DTADFADA,", result);
    return result;
  });
};



export const updateModuleQuery = async (id: number, payload: any) => {
  console.log(
    `/${getUserDetailsFromCookies().identifier}/api/project/${
      getUserDetailsFromCookies().projectId
    }/query/${id}`,
    "DATA SETS"
  );
  return await postData(
    `/${getUserDetailsFromCookies().identifier}/api/project/${
      getUserDetailsFromCookies().projectId
    }/query/${id}`,
    payload
  ).then((result) => {
    return result;
  });
};


export const getVisualizationStateForRedirect = async (id: number) => {
  return await postData(
    `/${getUserDetailsFromCookies().identifier}${CommonProject}${
      getUserDetailsFromCookies().projectId
    }${GetVisualizationState}`,
    { queryId: id, userId: getUserDetailsFromCookies().id }
  ).then((result) => {
    console.log("Result of Statws", result);
    return result.result;
  });
};

export const createDashboardModule = async (module_details: any) => {
  return await postData(
    `/${getUserDetailsFromCookies().identifier}${CommonProject}${
      getUserDetailsFromCookies().projectId
    }${CreateModule}`,
    module_details
  ).then((result) => {
    return result;
  });
};

export const createNewDatabase = async (database_details: any) => {
  return await postData(
    `/${Cookies.get("identifier")}${CreateDatabase}`,
    database_details
  ).then((result) => {
    return result;
  });
};

export const showDash  = async (dashboardId:any) => {
  return await getData(
    `/${getUserDetailsFromCookies().identifier}/api/project/${
      getUserDetailsFromCookies().projectId
    }/dashboard/${dashboardId}`
  ).then((result) => {
    return result;
  });
};

export function useCompanyService() {
  return [];
}
export class ProjectResult {
  id: number | undefined;
  industrySize: string | undefined;
  isActive: boolean | undefined;
  industryType: string | undefined;
  countryName: string | undefined;
  phoneCode: string | undefined;
  name: string | undefined;
  flag: string | undefined;
  cityName: string | undefined;
  stateId: string | undefined;
  constructor(data: any) {
    Object.assign(this, {
      id: data.id,
      isActive: data.isActive,
      industrySize: data.industrySize,
      industryType: data.industryType,
      countryName: data.countryName,
      phoneCode: data.phoneCode,
      name: data.name,
      flag: data.flag,
      cityName: data.cityName,
      stateId: data.stateId,
    });
  }
}
