import { getData, putData, postData, deleteData, getUserDetailsFromCookies } from "../../../services";
import {
  fileupload,
  datasource,
} from "../../../constants/Endpoints";
import { Cookies, useMsal } from "../../../../app.module";
export const getdatasourcetype = async () => {
    try {
      const result = await getData(datasource);
      console.log("datasource result", result);
      return result;
    } catch (error) {
      console.error("Error fetching data:", error);
      return null;  // or you might want to throw the error or handle it differently
    }
  };