import {
  React,
  Grid,
  Card,
  CardContent,
  Typography,
  Button,
  clsx,
  Modal,
  InputLabel,
  useRef,
  useEffect,
  useNavigate,
  useLocation,
  useMediaQuery,
  Backdrop,
  CircularProgress,
  IconButton,
  AppStatesContainer,
  useContext,
  Close,
  Box,
  Select,
  MenuItem,
} from "../../../app.module";
import Autocomplete from "@mui/material/Autocomplete";
import TextField from "@mui/material/TextField";
import { GetProjectsResults } from "../Main_Project/project/ProjectService";
import {
  GetDatabasesResults,
  dogetdatabase,
} from "../Main_Project/datasource/database/DatabaseService";
import "./DashboardListStyles";

import { Colors, Images } from "../../constants";
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import DataTable from "../Table/DataTable";
import {
  useStyle,
  CssModuleTextField,
  StyledQueryButton,
} from "./DashboardListStyles";
import {
  createQuery,
  updateModuleQuery,
  getPossibalVisualization,
  createDashboardModule,
  getModuleListData,
  getVisualizationStateForRedirect,
  autoSuggestion,
} from "./DashboardListService";
import { useState, RefObject } from "react";
import {
  getUserDetailsFromCookies,
  getQueryIdFromCookies,
} from "../../services";
import Cookies from "js-cookie";
interface ChildMethods {
  childMethod: () => void;
  onSaveData: (data: any) => void;
  resetchildMethod: () => void;
}
const ModuleList = (props: any) => {
  const path = useContext(AppStatesContainer);
  const isVSmallScreen = useMediaQuery(
    "(max-height:400px) and (max-height:500px)"
  );
  const isSmallScreen = useMediaQuery(
    "(max-height:501px) and (max-height:600px)"
  );
  const isMediumScreen = useMediaQuery(
    "(min-height:601px) and (max-height:700px)"
  );
  const XXXL = window.matchMedia(
    "(min-height: 1200px) and (max-height: 1920px)"
  ).matches;
  const XXL = window.matchMedia(
    "(min-height: 960px) and (max-height: 1199px)"
  ).matches;
  const XL = window.matchMedia(
    "(min-height: 800px) and (max-height: 959px)"
  ).matches;
  const LG = window.matchMedia(
    "(min-height: 700px) and (max-height: 799px)"
  ).matches;
  const MD = window.matchMedia(
    "(min-height: 600px) and (max-height: 699px)"
  ).matches;
  const SM = window.matchMedia(
    "(min-height: 500px) and (max-height: 599px)"
  ).matches;
  const XM = window.matchMedia(
    "(min-height: 400px) and (max-height: 499px)"
  ).matches;
  const SMM = window.matchMedia(
    "(min-height: 300px) and (max-height: 399px)"
  ).matches;

  const [databaselist, setDatabaselist] = useState<GetDatabasesResults[]>([]);
  const [project, setProjectlist] = useState<GetProjectsResults[]>([]);
  const [filteredDatabaseLists, setFilteredDatabaseLists] = useState<
    GetDatabasesResults[]
  >([]);
  const [selectedDB, setSelectedDB] = useState();
  const [value, setValue] = useState("none");
  const [showPlaceholder, setShowPlaceholder] = useState(true);
  const [query, setqQuery] = useState("");
  const [queryId, setQueryId] = useState(0);
  const [moduleName, setModuleName] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [moduleNameRequiredDialogOpen, setModuleNameRequiredDialogOpen] =
    useState(false);
  const [tableIsLoading, setTableIsLoading] = useState(false);
  const [selectedDatabase, setSelectedDatabase] = useState("none");
  const [selectedDataSource, setSelectedDataSource] = useState<string>("");

  const childRef: RefObject<ChildMethods> = useRef<ChildMethods>(null);
  const contentRef = useRef<HTMLDivElement | null>(null);
  const [pageError, setPageError] = useState("Nodata");
  const [isGenerateButtonDisabled, setIsGenerateButtonDisabled] =
    useState(true);
  const [isButtonEnabled, setIsButtonEnabled] = useState(false);
  const [dataChanged, setDataChanged] = useState(false);
  const [isGenerateButtonClicked, setIsGenerateButtonClicked] = useState(false);
  const [originalHeadings, setOriginalHeadings] = useState([]);
  const [generateErrorModal, setGenerateErrorModal] = useState(false);
  const [errorModal, setErrorModal] = useState(false);
  const handleDatabaseSelect = (event: any) => {
    const selectedId = event.target.value;
    setSelectedDB(selectedId);
  };
  const [moduleValues, setFormValues] = useState({
    module_name: {
      value: "",
      error: false,
      errorMessage: "You must enter a module name",
    },
    query_input: {
      value: "",
      error: false,
      errorMessage: "",
    },
  });

  const [moduleList, setModuleList] = useState<any>([]);
  const [isModalOpen, setTableModal] = useState(false);

  const moduleListLength = moduleList.length;
  const { classes } = useStyle({ moduleListLength, XL, LG, MD, SM, XM, SMM });
  const navigate = useNavigate();
  const [windowHeight, setWindowHeight] = useState(window.innerHeight);
  const [columns, setColumn] = useState<string[]>([]);
  const [rowData, setRowData] = useState<any[]>([]);
  const [apiCallCount, setApiCallCount] = useState(0);
  const [isErrorDialogOpen, setIsErrorDialogOpen] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const [backendErrorMessage, setBackendErrorMessage] = useState("");
  const [isInputFocused, setInputFocused] = useState(false);
  const [updateQuery, setUpdateQuery] = useState(0);
  const [originalColumns, setOriginalColumns] = useState<any[]>([]);
  const [updateColumns, setUpdateColumns] = useState<any[]>([]);
  const [columnIndexes, setColumnIndexes] = useState<any[]>([]);
  const [isBackdropOpen, setIsBackdropOpen] = useState(false);

  const [suggest, setSuggest] = useState<any>([]);
  console.log("modulelist", moduleList);
  console.log("modulelist", moduleList);
  useEffect(() => {
    dogetdatabase().then((database_list: any) => {
      if (!database_list?.isError) {
        setDatabaselist(database_list);
        console.log("databaselist", database_list);
      }
    });

    console.log("existing path", path.pathState);
    setIsLoading(true);
    setIsGenerateButtonClicked(false);

    getModuleListData()
      .then((resp) => {
        console.log("Data loaded successfully.");
        resp.push({ name: "" });
        console.log("Module List", resp);
        setModuleList([...resp]);
        setIsGenerateButtonClicked(true);
        setIsLoading(false);
        setTableIsLoading(false);
        setOriginalHeadings(resp?.map((item: { name: string }) => item.name));
      })
      .catch((error) => {
        console.error("Error loading data:", error);
        setIsGenerateButtonClicked(false);
        setIsLoading(false);
        setTableIsLoading(false);
        setIsBackdropOpen(false);
      });
  }, []);

  useEffect(() => {
    if (selectedDB) {
      autoSuggestion(selectedDB)
        .then((resp: any) => {
          if (
            Array.isArray(resp.suggestionJson) &&
            resp.suggestionJson.length > 0
          ) {
            setSuggest(resp.suggestionJson);
            setBackendErrorMessage("");
          } else {
            setBackendErrorMessage(
              "Unavailable to see suggestion. Please enter your own query."
            );
            console.error("suggestionJson is empty or not an array");
          }
        })
        .catch((error) => {
          setBackendErrorMessage("An error occurred while fetching data.");
          console.error("Error fetching suggestions:", error);
        });
    }
  }, [selectedDB]);

  console.log("opop", suggest);
  useEffect(() => {
    if (isErrorDialogOpen) {
      const timeoutId = setTimeout(() => {
        setIsErrorDialogOpen(false);
      }, 5000);
      return () => {
        clearTimeout(timeoutId);
      };
    }
  }, [isErrorDialogOpen]);

  // useEffect(() => {
  //   const generateData = () => {
  //     let queryPayload = {
  //       plainText: moduleValues.query_input.value,
  //       databaseId: selectedDB,

  //       queryTypeId: 3,
  //       projectId: getUserDetailsFromCookies().projectId,
  //       createdBy: getUserDetailsFromCookies().id,
  //     };

  //     if (moduleValues.query_input.value.length > 0) {
  //       createQuery(queryPayload).then((resp) => {
  //         setIsBackdropOpen(false);
  //         if (!resp.isError) {
  //           let rawData = JSON.parse(resp.result.dataJson);
  //           let tableHeader = rawData.column?.map(
  //             (item: { name: string }) => item.name
  //           );
  //           const isDataChanged = !compareArrays(tableHeader, originalHeadings);

  //           if (isDataChanged) {
  //             setDataChanged(true);
  //           } else {
  //             setDataChanged(false);
  //           }

  //           setColumn([...tableHeader]);
  //           setRowData([...rawData.rowData]);
  //           setQueryId(resp.result.id);
  //           setOriginalColumns([...rawData.column]);
  //           setUpdateColumns([]);
  //           setIsLoading(false);
  //           setTableIsLoading(false);
  //           setPageError("");

  //           callSaveDataChildMethod({
  //             column: tableHeader,
  //             rowData: rawData.rowData,
  //           });

  //           setIsButtonEnabled(isDataChanged);
  //         } else {
  //           if (
  //             resp?.responseException?.exceptionMessage ===
  //             "Your subscription has expired. If you are a company administrator, you can renew it from the organization settings page. "
  //           ) {
  //             setPageError(resp?.responseException?.exceptionMessage);
  //           } else {
  //             setPageError("Data not found");
  //           }

  //           setIsLoading(false);
  //           setGenerateErrorModal(true);
  //           setTableIsLoading(false);
  //         }
  //       });
  //       console.log("Payload", queryPayload);
  //     }
  //   };

  //   generateData();
  // }, [apiCallCount]);
  useEffect(() => {
    const generateData = () => {
      let queryPayload = {
        plainText: moduleValues.query_input.value,
        databaseId: selectedDB,
        queryTypeId: 3,
        projectId: getUserDetailsFromCookies().projectId,
        createdBy: getUserDetailsFromCookies().id,
      };

      if (moduleValues.query_input.value.length > 0) {
        createQuery(queryPayload).then((resp) => {
          setIsBackdropOpen(false);
          if (!resp.isError) {
            // Check if dataJson is a string and parse it if true, otherwise use it directly
            let rawData =
              typeof resp.result.dataJson === "string"
                ? JSON.parse(resp.result.dataJson)
                : resp.result.dataJson;
            let tableHeader = rawData.column?.map(
              (item: { name: any }) => item.name
            );
            const isDataChanged = !compareArrays(tableHeader, originalHeadings);

            if (isDataChanged) {
              setDataChanged(true);
            } else {
              setDataChanged(false);
            }

            setColumn([...tableHeader]);
            setRowData([...rawData.rowData]);
            setQueryId(resp.result.id);
            setOriginalColumns([...rawData.column]);
            setUpdateColumns([]);
            setIsLoading(false);
            setTableIsLoading(false);
            setPageError("");

            callSaveDataChildMethod({
              column: tableHeader,
              rowData: rawData.rowData,
            });

            setIsButtonEnabled(isDataChanged);
          } else {
            if (
              resp?.responseException?.exceptionMessage ===
              "Your subscription has expired. If you are a company administrator, you can renew it from the organization settings page. "
            ) {
              setPageError(resp?.responseException?.exceptionMessage);
            } else {
              setPageError("Data not found");
            }

            setIsLoading(false);
            setGenerateErrorModal(true);
            setTableIsLoading(false);
          }
        });
        console.log("Payload", queryPayload);
      }
    };

    generateData();
  }, [apiCallCount]);

  const location = useLocation();
  useEffect(() => {
    if (location.state?.openModal) {
      setTableModal(true);
    }
  }, [location.state]);
  function compareArrays(arr1: any[], arr2: any[]): boolean {
    if (arr1.length !== arr2.length) {
      return false;
    }
    const sortedArr1 = [...arr1].sort();
    const sortedArr2 = [...arr2].sort();
    for (let i = 0; i < sortedArr1.length; i++) {
      const item1 = sortedArr1[i];
      const item2 = sortedArr2[i];
      for (const field in item1) {
        if (item1[field] !== item2[field]) {
          return false;
        }
      }
    }

    return true;
  }
  useEffect(() => {
    const updateQueryData = () => {
      if (columns.length > 0) {
      }
    };
  }, [updateQuery]);
  useEffect(() => {
    if (contentRef.current) {
      contentRef.current.scrollTop = 0;
    }
  }, [isModalOpen]);
  const handleAPICall = () => {
    setIsBackdropOpen(true);
    setTableIsLoading(true);

    setApiCallCount((prevCount) => prevCount + 1);
    setIsGenerateButtonClicked(true);
  };

  const createModule = () => {
    setIsLoading(true);
    Cookies.set("query_id", queryId + "");
    let modulePlayload = {
      name: moduleValues.module_name.value,
      queryId: queryId,
      projectId: getUserDetailsFromCookies().projectId,
      createdBy: getUserDetailsFromCookies().id,
    };
    setIsGenerateButtonClicked(true);
    console.log("Module modulePlayload", modulePlayload);

    createDashboardModule(modulePlayload).then((resp: any) => {
      console.log("Module lists", resp);
      if (!resp.isError) {
        getVisualizationStateForRedirect(queryId).then((states: any) => {
          console.log("POSSIBALE VISUALIZATION states", states);
          if (!states.isError) {
            setIsLoading(false);
            setTableIsLoading(false);
            let foundChart = resp?.result?.charts?.find(
              (obj: any) => obj.chartTypeId === states.chartTypeId
            );
            console.log("namanama", resp?.result?.moduleData.id);
            navigate(
              `/${
                getUserDetailsFromCookies()?.identifier
              }/projects/visualization`,
              {
                state: {
                  moduleId: resp?.result?.moduleData.id,
                  states,
                  possible_visualization: resp.result.charts,
                  selected_visualization: foundChart,
                  moduleType: foundChart?.chartType,
                  x: foundChart?.chartParameter[0]?.parameterValue[
                    parseInt(states?.chartParameter.split(",")[0])
                  ],
                  y: foundChart?.chartParameter[1]?.parameterValue[
                    parseInt(states?.chartParameter.split(",")[1])
                  ],
                },
              }
            );
          } else {
            setIsLoading(false);
            setTableIsLoading(false);
          }
        });
      } else {
        setIsLoading(false);
        setTableIsLoading(false);
        if (
          resp?.responseException?.exceptionMessage ===
          "Name is required parameter"
        ) {
          setErrorMessage("Module name is required");
          setIsErrorDialogOpen(true);
        } else if (
          resp?.responseException?.exceptionMessage.startsWith(
            "Module with name"
          )
        ) {
          const dynamicModuleName =
            resp?.responseException?.exceptionMessage.match(/'([^']+)'/);
          if (dynamicModuleName && dynamicModuleName.length > 1) {
            setErrorMessage(
              `Module with name '${dynamicModuleName[1]}' already exists. Please use a different name.`
            );
          } else {
            setErrorMessage(
              "Module with name already exists. Please use a different name."
            );
          }
          setIsErrorDialogOpen(true);
        } else {
          setIsLoading(false);
          setTableIsLoading(false);
          console.error(
            "Unexpected error:",
            resp?.responseException?.exceptionMessage
          );
        }
      }
    });
  };

  const handleUpdateQuery = () => {
    if (updateColumns.length >= 0) {
      console.log(
        "UPDATE QUERY PAYLOAD",
        queryId,
        JSON.stringify({
          DataJson: JSON.stringify(updateColumns),
        })
      );

      setIsLoading(true);
      updateModuleQuery(queryId, {
        DataJson: JSON.stringify(updateColumns),
      }).then((resp: any) => {
        console.log("UPDATED RESPONSE", resp);
        let rawData = JSON.parse(resp.result.dataJson);
        console.log("DATA SETS rawData", rawData);
        let processedRowData = rawData.rowData.map(
          (row: any, index: number) => ({
            ...row,
            "Sr.No.": index + 1,
          })
        );

        let table_header = [
          "Sr.No.",
          ...rawData.column?.map((item: any) => item.name),
        ];
        console.log("DATA SETS", JSON.parse(resp.result.dataJson));

        setColumn([...table_header]);
        setRowData([...processedRowData]);
        setQueryId(resp.result.id);
        setOriginalColumns([...rawData.column]);
        setUpdateColumns([]);
        setIsLoading(false);
        setTableIsLoading(false);
        callSaveDataChildMethod({
          column: table_header,
          rowData: processedRowData,
        });
      });
    } else {
      console.error("No data to send. updateColumns is empty.");
    }
    setIsLoading(false);
    setTableIsLoading(false);
  };
  const handleRemoveColumn = (column: any) => {
    console.log(`Removing column: ${column}`);
  };
  const handleErrorModal = () => {
    setErrorModal(false);
  };
  interface Suggestion {
    suggestionJson: string;
  }

  interface ModuleValues {
    module_name: {
      value: string;
      error: boolean;
      errorMessage: string;
    };
    query_input: {
      value: string;
      error: boolean;
      errorMessage: string;
    };
  }
  const handleHeaderRename = (
    headers: string[],
    oldHeader: string,
    newHeader: string,
    index: number
  ) => {
    let temp_colm_Obj = originalColumns[index];
    console.log("indeeex", index);
    temp_colm_Obj.name = headers[index];
    temp_colm_Obj.id = index;
    let updated_columns_header: any = updateColumns;
    const foundIndex = updateColumns.findIndex((obj) => obj.id === index);
    if (foundIndex !== -1) {
      updated_columns_header[foundIndex].name = temp_colm_Obj.name;
    } else {
      updated_columns_header.push(temp_colm_Obj);
    }
    setUpdateColumns([...updated_columns_header]);
    console.log("skjzfhaskjd", temp_colm_Obj, updateColumns);
  };

  const saveModuleData = (data: any) => {
    console.log(`Removing column: ${data}`);
  };
  const handleModules = async (queryId: number, moduleId: number) => {
    console.log("moduleId", moduleId);
    console.log("sdfsdfs", getQueryIdFromCookies());
    console.log("cookies", getUserDetailsFromCookies());
    console.log("idofquery", queryId);
    if (queryId) {
      setIsLoading(true);
      Cookies.set("query_id", queryId + "");
      getVisualizationStateForRedirect(queryId).then((states: any) => {
        getPossibalVisualization(queryId).then((resp: any) => {
          console.log("POSSIBALE states", states);
          console.log("POSSIBALE VISUALIZATION", resp);
          let foundChart = resp.find(
            (obj: any) => obj.chartTypeId === states.chartTypeId
          );
          console.log(
            "POSSIBALE chartParameter",
            foundChart?.chartParameter[0]?.parameterValue[
              parseInt(states?.chartParameter.split(",")[0])
            ],
            foundChart?.chartParameter[1]?.parameterValue[
              parseInt(states?.chartParameter.split(",")[1])
            ]
          );
          setIsLoading(false);
          navigate(
            `/${
              getUserDetailsFromCookies()?.identifier
            }/projects/visualization`,
            {
              state: {
                moduleId: moduleId,
                states,
                possible_visualization: resp,
                selected_visualization: foundChart,
                moduleType: foundChart?.chartType,
                x: foundChart?.chartParameter[0]?.parameterValue[
                  parseInt(states?.chartParameter.split(",")[0])
                ],
                y: foundChart?.chartParameter[1]?.parameterValue[
                  parseInt(states?.chartParameter.split(",")[1])
                ],
              },
            }
          );
        });
      });
    }
  };

  const handleTableModal = () => {
    setFormValues({
      module_name: {
        ...moduleValues.module_name,
        value: "",
        error: false,
        errorMessage: "",
      },
      query_input: {
        ...moduleValues.query_input,
        value: "",
        error: false,
        errorMessage: "",
      },
    });
    setRowData([]);
    setColumn([]);

    // Reset other related states
    setPageError("Nodata");
    setDataChanged(false);
    setSelectedDataSource("none");
    setIsButtonEnabled(false);
    setIsGenerateButtonDisabled(true);
    setTableModal(false);
  };

  const callChildMethod = () => {
    if (childRef.current) {
      childRef.current.childMethod();
    }
  };
  const callSaveDataChildMethod = (data: any) => {
    if (childRef.current) {
      childRef.current.onSaveData(data);
    }
  };
  const callParentResetchildMethod = () => {
    if (childRef.current) {
      setColumnIndexes([]);
      setUpdateColumns([]);
      childRef.current.resetchildMethod();
    }
  };
  const dialogStyle = {
    width: "622px",
    height: "99px",
    borderRadius: "10px",
    border: "1px solid #F69E49",
    backgroundColor: "#F4DAB2",
    overflow: "hidden",
    display: "flex",
  };

  const imageStyle = {
    marginLeft: "16px",
    marginTop: "20px",
  };

  const contentStyle = {
    flex: 1,
    padding: "8px",
  };
  const handleProjectStateChange = (e: any) => {
    const { name, value } = e.target;
    const updatedModuleValues = { ...moduleValues };

    switch (name) {
      case "module_name":
        updatedModuleValues.module_name.value = value;
        break;
      case "query_input":
        updatedModuleValues.query_input.value = value;
        break;
      default:
        break;
    }
    const isDisabled =
      updatedModuleValues.query_input.error ||
      !updatedModuleValues.query_input.value.trim();
    setFormValues(updatedModuleValues);
    setIsGenerateButtonDisabled(isDisabled);
  };
  const handleClose = () => {
    setIsLoading(false);
    setTableIsLoading(false);
  };
  const spanStyle: React.CSSProperties = {
    color: "red",
  };
  const updatedRowData = rowData.map((row, index) => ({
    ...row,
    "Sr.No.": index + 1,
  }));

  //  const updatedColumns = ["Sr.No.", ...columns];

  const updatedColumns = React.useCallback(() => {
    return columns.length > 0 ? ["Sr.No.", ...columns] : [];
  }, [columns]);

  const handleGenerateErrorModal = () => {
    return (
      <React.Fragment>
        <Modal
          open={generateErrorModal}
          onClose={() => {
            setGenerateErrorModal(!generateErrorModal);
          }}
          aria-labelledby="child-modal-title"
          aria-describedby="child-modal-description"
        >
          <Box
            sx={{
              position: "absolute" as "absolute",
              top: "50%",
              left: "50%",
              transform: "translate(-50%, -50%)",
              width: 300,
              display: "flex",
              flexDirection: "column",
              bgcolor: "background.paper",
              justifyContent: "center",
              boxShadow: 24,
              borderRadius: "5px",
              pt: 2,
              px: 4,
              pb: 3,
            }}
          >
            <h4
              style={{
                textAlign: "center",
              }}
              id="child-modal-title"
            >
              {pageError}
            </h4>

            <div className={clsx(classes.generateModalBtncontainer)}>
              <div>
                <Button
                  onClick={() => {
                    setGenerateErrorModal(false);
                  }}
                  variant="contained"
                  className={classes.generateModalBtn}
                >
                  Okay
                </Button>{" "}
              </div>
            </div>
          </Box>
        </Modal>
      </React.Fragment>
    );
  };

  return (
    <div className={classes.dashBoardContainer}>
      <div
        style={{
          fontWeight: 700,
          fontSize: "23px",
          marginLeft: "30px",
          marginTop: "15px",
        }}
      >
        All Modules
      </div>

      <Backdrop
        className={classes.circularPlanContainer}
        open={isLoading}
        onClick={handleClose}
      >
        <CircularProgress className={classes.circularPlanView} />
      </Backdrop>
      {handleGenerateErrorModal()}
      {moduleList.length === 0 && (
        <div
          style={{
            display: "flex",
            flexGrow: 1,
            height: "100vh",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <img
            style={{
              height: "auto",
              width: "400px",
            }}
            src={Images.no_data_img}
          />
        </div>
      )}
      <Grid
        alignItems="flex-start"
        justifyContent="left"
        flexDirection={"row"}
        container
        sx={{
          borderRadius: "20px",
        }}
      >
        {moduleList?.map(
          (module: any, index: Number) =>
            (module.name !== "" ||
              getUserDetailsFromCookies().roleName !== "Viewer") && (
              <Grid
                columns={{ xs: 12, sm: 6, md: 4, lg: 3, xl: 2 }}
                justifyContent="center"
                padding={2}
                alignItems="center"
              >
                <Grid item>
                  <Button
                    className={classes.moduleButton}
                    onClick={() => handleModules(module.queryId, module.id)}
                  >
                    <Card
                      sx={{
                        display: "flex",
                        maxWidth: 251.8,
                        minWidth: 251.8,
                        minHeight: 160,
                        maxHeight: 160,
                        padding: 1.5,
                        borderRadius: "10px",
                        opacity: 0.9,
                        background: module.name
                          ? `url(${Images.dashoboard_list_placeholder}) center center no-repeat`
                          : Colors?.background.backgroundColorTheme3,
                      }}
                    >
                      <CardContent
                        onClick={() => {
                          if (
                            module.name === "" &&
                            getUserDetailsFromCookies().roleName !== "Viewer"
                          ) {
                            setTableModal(true);
                          }
                        }}
                        style={{
                          display: "flex",
                          flex: 1,
                          flexDirection: "column",
                          padding: 5,
                        }}
                      >
                        <div
                          style={{
                            display: "flex",
                            flex: 0.1,
                            justifyContent: "flex-start",
                            alignItems: "flex-start",
                          }}
                        >
                          <Typography
                            className={
                              module.name === ""
                                ? clsx(
                                    classes.dashBoardListTopLabel,
                                    classes.moduleTitle
                                  )
                                : clsx(classes.dashBoardListTopLabel)
                            }
                          >
                            {module.name ? module.name : "Add New"}
                          </Typography>
                        </div>
                        <div
                          style={{
                            display: "flex",
                            flex: 1,
                            justifyContent: "center",
                            alignItems: "center",
                            overflowX: "hidden",
                            paddingBottom: "25px",
                          }}
                        >
                          {module.name === "" &&
                            getUserDetailsFromCookies().roleName !==
                              "Viewer" && (
                              <img
                                style={{
                                  height: "30px",
                                  width: "30px",
                                }}
                                src={Images.add_img}
                              />
                            )}
                        </div>
                      </CardContent>
                    </Card>
                  </Button>
                </Grid>
              </Grid>
            )
        )}
      </Grid>
      <div
        style={{
          margin: 20,
        }}
      >
        <Modal
          className={classes.modalStyle}
          open={isModalOpen}
          aria-labelledby="simple-modal-title"
          aria-describedby="simple-modal-description"
        >
          <div className={classes.tableModalContainer}>
            <div
              style={{
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
                backgroundColor: "#fff",
                border: "solid 2px #fff",
                overflowX: "hidden",
              }}
            >
              <div style={{ marginLeft: "25px", fontWeight: "bold" }}>
                Module Name <span style={spanStyle}>*</span>
              </div>
              <IconButton
                className={classes.closeModalImgStyle}
                onClick={() => handleTableModal()}
              >
                <Close
                  style={{
                    borderBottom: "0px",
                  }}
                />
              </IconButton>
            </div>
            <div
              style={{
                backgroundColor: "#fff",
                minWidth: "700px",
                overflowX: "hidden",
              }}
            >
              <div className={classes.moduleFiledLabelContainer}>
                <CssModuleTextField
                  fullWidth
                  value={moduleValues.module_name.value}
                  onChange={(e: any) => handleProjectStateChange(e)}
                  autoFocus
                  name="module_name"
                  placeholder="Enter module name"
                  InputProps={{
                    classes: { input: classes.moduleInput },
                  }}
                  id="module-input"
                />

                <div>
                  <Select
                    id={selectedDB}
                    onChange={(e) => {
                      handleDatabaseSelect(e);
                      setSelectedDataSource(e.target.value);
                    }}
                    onFocus={(e) => setShowPlaceholder(false)}
                    onClose={(e) => {
                      setShowPlaceholder(
                        (e.target as HTMLInputElement).value === undefined
                      );
                    }}
                    defaultValue="none"
                    className={classes.datasourceStyle}
                    style={{
                      width: "185px",
                      height: "47px",
                      border: "1px solid #ccc",
                      borderRadius: "4px",
                      marginLeft: "40% !important",
                      pointerEvents: "auto",
                      backgroundColor: "white",
                      color: "black",
                    }}
                  >
                    <MenuItem key="0" value="none">
                      Select Datasource
                    </MenuItem>
                    {databaselist?.map((item: any) => (
                      <MenuItem key={item?.id} value={item?.id}>
                        {item?.name}
                      </MenuItem>
                    ))}
                  </Select>
                </div>
              </div>
              <div className={classes.twoTableFieldsContainer}>
                <div className={classes.textTableFiledLabelContainer}>
                  <InputLabel className={classes.moduleTableLabelStyle}>
                    Enter your query/question below
                  </InputLabel>
                  <Autocomplete
                    freeSolo
                    options={backendErrorMessage ? [] : suggest}
                    inputValue={moduleValues.query_input.value}
                    //onChange={() => setPageError("Nodata")}
                    onInputChange={(event, newInputValue, reason) => {
                      const isInputEmpty = newInputValue.trim() === "";

                      //  setPageError("Nodata")

                      setFormValues((prev) => ({
                        ...prev,
                        query_input: {
                          ...prev.query_input,
                          value: newInputValue,

                          error: isInputEmpty,
                          errorMessage: isInputEmpty
                            ? "Input cannot be empty"
                            : "",
                        },
                      }));
                      setIsGenerateButtonDisabled(isInputEmpty);
                    }}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        fullWidth
                        error={moduleValues.query_input.error}
                        // onChange={() => setPageError("Nodata")}

                        helperText={
                          moduleValues.query_input.value === "" ? (
                            <span
                              style={{ color: "#ff9f00", fontSize: "1.3em" }}
                            >
                              {backendErrorMessage ||
                                moduleValues.query_input.errorMessage}
                            </span>
                          ) : null
                        }
                        placeholder="Please enter your query"
                        onFocus={() => setInputFocused(true)}
                        onBlur={() => setInputFocused(false)}
                        id="query-input"
                        disabled={
                          selectedDataSource === "none" ||
                          selectedDataSource === ""
                        }
                      />
                    )}
                  />
                </div>

                <div className={classes.txtLabelTableContainerRight}>
                  <InputLabel className={classes.generateBtnLabel}>
                    ""
                  </InputLabel>
                  <div style={{ display: "flex", flexDirection: "row" }}>
                    <div className={classes.queryBtnTestContainer}>
                      <StyledQueryButton
                        //                        onChange={() => setPageError("Nodata")}

                        onClick={handleAPICall}
                        className={classes.queryGenerateBtnLabelStyle}
                        variant="contained"
                        size="medium"
                        disabled={isGenerateButtonDisabled}
                      >
                        Generate
                      </StyledQueryButton>
                    </div>
                  </div>
                </div>
              </div>
              <div
                style={{
                  display: "flex",
                }}
              >
                <InputLabel
                  style={{
                    marginLeft: "25px",
                    marginRight: "2px",
                  }}
                  className={classes.moduleTableLabelStyle}
                >
                  Data Preview
                </InputLabel>
                <InputLabel
                  className={clsx(
                    classes.moduleTableLabelStyle,
                    classes.moduleTableNoteLabelStyle
                  )}
                >
                  (Reference data of 100 samples is shown in below table based
                  on your query response)
                </InputLabel>
              </div>

              <div
                style={{
                  display: "flex",
                  justifyContent: "center",
                  overflowX: "hidden",
                }}
              >
                <div
                  style={{
                    backgroundColor: Colors?.background.backgroundColorTheme1,
                    padding: 20,
                    width: "95%",
                    height: rowData.length > 0 ? "700px" : "400px",
                    justifyContent: "center",
                    alignItems: "center",
                    borderRadius: "10px",
                    overflowX: "hidden",
                    position: "relative", // Add this to make it a reference for positioning the dialog
                  }}
                >
                  <div
                    style={{
                      backgroundColor: Colors?.background.backgroundColorTheme1,
                      overflow: "auto",
                      width: "100%",
                      height: rowData.length > 0 ? "660px" : "360px",
                      paddingTop: "0px",
                      overflowX: "hidden",
                    }}
                  >
                    {pageError === "" ||
                    pageError === "Module name is required" ? (
                      <>
                        <Backdrop
                          open={isBackdropOpen}
                          style={{
                            backgroundColor: "rgba(128, 128, 128, 0.5)",
                            zIndex: 10, // Ensure this is above the table but below modal/dialog elements
                            position: "absolute",
                            top: 0,
                            left: 0,
                            width: "100%",
                            height: "100%",
                          }}
                        >
                          <div
                            style={{
                              position: "absolute",
                              top: "50%",
                              left: "50%",
                              transform: "translate(-50%, -50%)",
                              height: "12vh",
                              width: "57%",
                              background: "#9DC7F6",
                              marginTop: "0%",
                            }}
                          >
                            <DialogContent>
                              <div
                                style={{
                                  display: "flex",
                                  alignItems: "center",
                                }}
                              >
                                <CircularProgress
                                  className={classes.generateBtnCircularLoader}
                                  size={50}
                                  style={{
                                    color: "#theColorOfYourLoader",
                                    marginRight: "10px",
                                  }}
                                />
                                <div
                                  style={{
                                    marginLeft: "10px",
                                    marginRight: "10px",
                                    color: "#000",
                                    fontFamily: "Nunito Sans, sans-serif",
                                    alignItems: "center",
                                  }}
                                >
                                  Sit back and relax, our engine is generating
                                  the data.
                                </div>
                              </div>
                            </DialogContent>
                          </div>
                        </Backdrop>

                        <DataTable
                          ref={childRef}
                          data={updatedRowData}
                          columns={updatedColumns()}
                          onRemoveColumn={handleRemoveColumn}
                          onRenameHeader={handleHeaderRename}
                          handleErrorModal={handleErrorModal}
                          isLoading={isLoading}
                          tableIsLoading={tableIsLoading}
                          errorModal={errorModal}
                          handleClose={handleClose}
                        />
                        {/* //  </div> */}
                      </>
                    ) : (
                      <div
                        style={{
                          display: "flex",
                          width: "100%",
                          height: "360px",
                          justifyContent: "center",
                          alignItems: "center",
                          overflowX: "hidden",
                        }}
                      >
                        <img
                          style={{
                            height: "auto",
                            width: "300px",
                          }}
                          src={Images.no_data_img}
                          alt="No data"
                        />

                        {tableIsLoading ? (
                          <Dialog
                            open={tableIsLoading}
                            maxWidth="xs"
                            fullWidth
                            PaperProps={{
                              style: {
                                background: "#9DC7F6",
                                marginTop: "0%",
                              },
                            }}
                            style={{
                              position: "absolute",
                              top: "59%",
                              left: "50%",
                              transform: "translate(-50%, -50%)",
                              height: "54vh",
                              width: "57%",
                            }}
                            BackdropProps={{
                              style: {
                                backgroundColor: "rgba(128, 128, 128, 0.5)",
                                position: "absolute",
                                top: "0%",
                                left: 0,
                                width: "100%",
                                height: "100%",
                              },
                              invisible: false,
                            }}
                          >
                            <DialogContent>
                              <div
                                style={{
                                  display: "flex",
                                  alignItems: "center",
                                }}
                              >
                                <CircularProgress
                                  className={classes.generateBtnCircularLoader}
                                  size={50}
                                  style={{
                                    color: "#theColorOfYourLoader",
                                    marginRight: "10px",
                                  }}
                                />
                                <div
                                  style={{
                                    marginLeft: "10px",
                                    marginRight: "10px",
                                    color: "#000",
                                    fontFamily: "Nunito Sans, sans-serif",
                                    alignItems: "center",
                                  }}
                                >
                                  Sit back and relax, our engine is generating
                                  the data.
                                </div>
                              </div>
                            </DialogContent>
                          </Dialog>
                        ) : null}
                      </div>
                    )}
                  </div>
                </div>
              </div>

              <div className={clsx(classes.queryBtncontainer)}>
                <div>
                  <Button
                    onClick={() => {
                      handleUpdateQuery();
                    }}
                    variant="contained"
                    className={classes.saveTableBtn}
                    disabled={!dataChanged}
                  >
                    Save
                  </Button>

                  <StyledQueryButton
                    onClick={() => {
                      callParentResetchildMethod();
                    }}
                    variant="contained"
                    className={classes.queryGenerateBtnLabelStyle}
                    disabled={!dataChanged}
                  >
                    Reset
                  </StyledQueryButton>

                  <Button
                    onClick={createModule}
                    variant="contained"
                    className={classes.saveTableBtn}
                    disabled={!isButtonEnabled}
                  >
                    Create
                  </Button>
                  <Dialog
                    open={isErrorDialogOpen}
                    PaperProps={{
                      style: dialogStyle,
                      display: "flex",
                    }}
                    BackdropProps={{
                      style: {
                        backgroundColor: "transparent",
                      },
                      invisible: true,
                    }}
                  >
                    <div style={{ flex: 1 }}>
                      <img src={Images.alert} alt="Alert" style={imageStyle} />
                      <DialogTitle
                        style={{
                          marginLeft: "90px",
                          position: "absolute",
                          top: "-4px",
                          zIndex: "999",
                          fontSize: "24px",
                          fontFamily: "Nunito Sans, sans-serif",
                        }}
                      >
                        Alert
                      </DialogTitle>

                      <DialogContent
                        style={{
                          ...contentStyle,
                          marginTop: "40px",
                          marginLeft: "105px",
                          position: "absolute",
                          top: "0",
                        }}
                      >
                        {errorMessage}
                      </DialogContent>
                    </div>
                  </Dialog>
                </div>
              </div>
            </div>
          </div>
        </Modal>
      </div>
    </div>
  );
};

export default ModuleList;
function showDialogBox(arg0: string) {
  throw new Error("Function not implemented.");
}
