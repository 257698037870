import React, { useReducer, useMemo } from "react";
import { AuthReducer } from "./AuthReducer";
import { ThemeReducer } from "./ThemeReducer";
import { PathReducers } from "./PathReducers";
import { AppStatesContainer } from "./AppStatesContainer";
import {
  RESTORE_TOKEN,
  SIGN_IN,
  SIGN_UP,
  SIGN_OUT,
  THEME_NAME,
  SET_PATH,
} from "./AppActionConst";
//const AppContext = createContext();

export const AppContext = (props: any) => {
  const initialState = {
    isLoading: true,
    isSignout: false,
    userToken: null,
    userData: "laxman",
  };
  const initialThemeState = {
    theme: "light",
  };
  const dashboard_paths: any = [];
  const [authState, dispatch] = useReducer(AuthReducer, initialState);
  const [themeState, dispatchtheme] = useReducer(
    ThemeReducer,
    initialThemeState
  );
  const [pathState, setPath] = useReducer(PathReducers, dashboard_paths);
  const authActions = useMemo(
    () => ({
      signIn: () => {
        dispatch({ type: SIGN_IN });
      },
      setTheme: (user: any) => {
        dispatch({ type: SIGN_IN, payload: user });
      },
      signOut: () => {
        dispatch({ type: SIGN_OUT });
      },
    }),
    []
  );
  const themeActions = useMemo(
    () => ({
      setTheme: (type: any) => {
        dispatchtheme({ type: THEME_NAME, payload: type });
      },
    }),
    []
  );
  // project> dashboard> module> specific_module
  const pathAactions = useMemo(
    () => ({
      setPathName: (payload: any) => {
        setPath({ type: SET_PATH, payload });
      },
    }),
    []
  );
  // const pathAactions = useMemo(()=>({
  //   setPathName: (type:any)=>{
  //     setPath({});
  //   }
  // }))
  return (
    <AppStatesContainer.Provider
      value={{
        authState,
        authActions,
        themeActions,
        themeState,
        dispatch,
        dispatchtheme,
        pathAactions,
        pathState,
      }}
    >
      {props.children}
    </AppStatesContainer.Provider>
  );
};
