import { RESTORE_TOKEN, SIGN_IN, SIGN_UP, SIGN_OUT } from "./AppActionConst";
export const AuthReducer = (prevState: any, action: any) => {
  switch (action.type) {
    case RESTORE_TOKEN:
      return {
        ...prevState,
        userToken: action.token,
        isLoading: false,
      };
    case SIGN_IN:
      return {
        ...prevState,
        isSignout: false,
      };
    case SIGN_UP:
      return {
        ...prevState,
        userData: action.payload,
        isSignout: false,
      };
    case SIGN_OUT:
      return {
        ...prevState,
        isSignout: true,
        userData: "",
      };
  }
};
