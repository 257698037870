export const AppThemes = {
  palette: {
    mode: "dark",
    primary: {
      main: "#1158CE",
    },
  },
  typography: {
    h1: {
      fontFamily: "Arial",
      fontSize: "40px",
    },
  },
  zIndex: {
    drawer: 1200,
  },
};
