import React, { useEffect, useState } from 'react';
import {
  RadialBarChart,
  RadialBar,
  Legend,
  ResponsiveContainer,
  Tooltip,
} from 'recharts';
// import { useStyle } from "./ScatterPlotStyle";
import { Colors, Images } from '../../../constants';
import { active } from 'd3-transition';

const style = {
  top: '50%',
  right: 0,
  transform: 'translate(0, -50%)',
  lineHeight: '24px',
};
interface CustomTooltipProps {
  active?: boolean;
  payload?: Array<{ value: number; payload: any }>;
}

interface DataItem {
  [key: string]: any; 
}


const CustomTooltip: React.FC<CustomTooltipProps> = ({ active, payload }) => {
  if (active && payload && payload.length) {
    const dataItem = payload[0].payload;

   

    const tooltipStyle = {
      backgroundColor: dataItem.fill,
      padding: '10px', // Smaller padding
      border: '1px solid #ccc',
      borderRadius: '5px',
      boxShadow: '0px 0px 5px rgba(0, 0, 0, 0.1)', // Smaller shadow
      color: 'white',
      width: '120px', // Smaller fixed width
      fontSize: '10px', // Smaller font size
    };

    // Dynamically generate parameter names and their values
    const parameterNames = Object.keys(dataItem)
      .filter(key => key !== 'fill'); // Exclude the 'fill' key

    return (
      <div style={tooltipStyle}>
        {parameterNames.map((paramName, index) => (
          <p key={index}>{`${paramName}: ${dataItem[paramName]}`}</p>
        ))}
      </div>
    );
  }

  return null;
};

const RadialChartForDashboard = React.memo((props: any) => {
  const [RadialChartJson, setRadialChartJson] = useState<any>([]);
  const [isLoading, setIsLoading] = useState(true);

  console.log("Props for RadialDashboard", props);

  useEffect(() => {
    let bdata = props.data;
    console.log("RADIAL COMPONENT DATA", bdata);
    setRadialChartJson(bdata);
    setIsLoading(false);
  }, [props.data]);


  const chartSize = 480; // This should be dynamic or based on the available space
  const dataLength = RadialChartJson && RadialChartJson.data ? RadialChartJson.data.length : 0;

  // Now use dataLength to compute barSize and other related properties
  // Note: The rest of the code assumes that RadialChartJson.data is an array
  const maxBarThickness = 40; // Set this to your preference
  const minBarThickness = 10; // Set this to your preference
  const barSize = dataLength > 0 ? Math.max(minBarThickness, maxBarThickness / Math.log(dataLength + 1)) : 10;

  const maxOuterRadius = chartSize / 2 - 20 + dataLength * 5; // Try increasing the value being subtracted here

  const outerRadius = Math.min(maxOuterRadius, chartSize / 2 - barSize * dataLength);

  // Adjust the innerRadius to allow more space for the bars to be thicker
  const innerRadius = Math.max(10, outerRadius - barSize * dataLength);

  // Calculate the font size for labels dynamically (optional)
  const labelFontSize = dataLength > 20 ? 8 : 12;


  return (
    <div
      key="Radial01"
      style={{
        // backgroundColor: "rgb(242,245,253)",
        // height: "300px",
        // width: "95%",
        // overflowX: "auto",
        // // overflow: "scroll",
        // justifyContent: "center",
        // alignItems: "center",
        // display: "flex",
        // top: 0,
        display: "flex",
        width: "100%", // Adjusted to 100%
        height: "100%", //
        justifyContent: "center",
        alignItems: "center",
      }}
    >
      {props?.pageError !== "" ? (
        <div
          key="TAB101"
          style={{
            display: "flex",
            height: "300px",
            width: "100%",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <img
            style={{
              height: "auto",
              width: "300px",
            }}
            src={Images.no_data_img}
          />
        </div>
      ) : (
        <div
          key="PIE102"
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            // width:
            //   RadialChartJson?.data?.length >= 10
            //     ? `${50 * RadialChartJson?.data?.length}px`
            //     : "100%",
            // height: "300px", //40vh
            width: "100%", // Adjusted to 100%
            height: "100%",
          }}
        >
          {props.name}
          <ResponsiveContainer width="100%" height="100%">
            <RadialBarChart

              cx="50%"
              cy="51%"

              width={chartSize}
              height={chartSize}
              innerRadius={innerRadius}
              outerRadius="100%"
              barSize={barSize}
              data={RadialChartJson.data}

            >
              <RadialBar
                background
                dataKey={RadialChartJson.datakey_Y}
              // Remove or comment out the label prop to remove the numbers inside the bars
              // label={false}
              />
              <Tooltip content={<CustomTooltip />} />
            </RadialBarChart>
          </ResponsiveContainer>
        </div>
      )}
    </div>
  );
})

export default RadialChartForDashboard;