import React, { useState } from 'react';
import { MenuItem, InputLabel, Select, FormControl, TextField, Button } from '@mui/material';
import {
  LandingPageContainer,
  StyledTextField,
  StyledFormControl,
  ButtonContainer,
  StyledButton,
  SelectDatasourceContainer,
} from './LandingPageStyle';

const LandingPage = () => {
  const [query, setQuery] = useState('');
  const [selectedDatasource, setSelectedDatasource] = useState('');

  // Example datasources
  const datasources = ['Datasource 1', 'Datasource 2', 'Datasource 3'];

  const handleQueryChange = (e: { target: { value: React.SetStateAction<string>; }; }) => setQuery(e.target.value);
  const handleDatasourceChange = (e: { target: { value: React.SetStateAction<string>; }; }) => setSelectedDatasource(e.target.value);

  const handleGetSummary = () => {
    console.log('Getting summary for:', query, selectedDatasource);
  };

  const handleCreateVisualization = () => {
    console.log('Creating visualization for:', query, selectedDatasource);
  };

  return (
    <LandingPageContainer>
      <InputLabel style={{ fontWeight: 700, marginBottom: "10px" }}>
        {/* Add label text if needed */}
      </InputLabel>

      <StyledTextField
        id="query-input"
        variant="outlined"
        size="small"
        value={query}
        onChange={handleQueryChange}
        placeholder="Please enter your query"
      />

      <SelectDatasourceContainer>
        <StyledFormControl variant="outlined" size="small" fullWidth>
          <InputLabel id="datasource-select-label">Select Datasource</InputLabel>
          <Select
            labelId="datasource-select-label"
            id="datasource-select"
            value={selectedDatasource}
            onChange={handleDatasourceChange}
            label="Select Datasource"
          >
            {datasources.map((datasource, index) => (
              <MenuItem key={index} value={datasource}>
                {datasource}
              </MenuItem>
            ))}
          </Select>
        </StyledFormControl>
      </SelectDatasourceContainer>

      <ButtonContainer>
        <StyledButton
          variant="contained"
          color="primary"
          onClick={handleGetSummary}
        >
          Get Summary
        </StyledButton>
        <StyledButton
          variant="contained"
          color="secondary"
          onClick={handleCreateVisualization}
        >
          Create
        </StyledButton>
      </ButtonContainer>
    </LandingPageContainer>
  );
};

export default LandingPage;
