import { createChatBotMessage } from 'react-chatbot-kit';
// import OptionsComponent from './Options';/
import Avatar from '../chatbot/Avatar'; // Make sure to import the Avatar component from the correct path
import UserAvatar from '../chatbot/userAvatar';
import OptionsComponent from './Options';
import DataSourceOptionsComponent from './DatasourceOptions';
import { getUserDetailsFromCookies } from '../../services';
import VoiceWidget from './VoiceWidget';
import ActionProvider from './ActionProvider'; 

const firstName = () => {
    try {
        const userDetails = getUserDetailsFromCookies();
        if (userDetails) {
            const firstName = userDetails.firstName;
            return firstName;
        } else {
            return "";
        }
    } catch (error) {
        console.error("Error parsing userDetails cookie:", error);
        return "";
    }
}
const config = {
    initialMessages: [
        createChatBotMessage(`Hi ${firstName()}, I am WiBi, your virtual assistant😊.
        Please select any of the below options.`, { widget: "options" })
    ],
    botName: "WiBi",
    // ... other configuration options
    widgets: [
        {
            widgetName: "options",
            widgetFunc: (props: any) => <OptionsComponent selectOption={props.actions.selectOption} />,
        },
        {
            widgetName: "datasourceoptions",
            widgetFunc: (props: any) => <DataSourceOptionsComponent selectOption={props.actions.selectOption} handleDatabaseSelection={props.actions.handleDatabaseSelection} />,
        },
        {  
            widgetName: 'voice',    
            widgetFunc: (props:any) => <VoiceWidget {...props} fetchVoiceData={props.actions.fetchVoiceData} />,  
            mapStateToProps: ["fetchVoiceData"]    
          }, 
    ],
    actionProvider: ActionProvider,
    customComponents: {
        botAvatar: () => <Avatar />,
        userAvatar: () => <UserAvatar />
    }
};

export default config;
