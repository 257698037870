import {
  React,
  Paper,
  useMemo,
  Tabs,
  Tab,
  useState,
  Box,
  Typography,
  Button,
  Select,
  MenuItem,
  clsx,
  useEffect,
  ListItemIcon,
  FiberManualRecord,
  Rectangle,
  Backdrop,
  CircularProgress,
  useNavigate,
  useLocation,
} from "../../../../app.module";
import { ResponsiveScatterPlot } from "@nivo/scatterplot";
import { useStyle } from "./DashboardBarChartStyle";
import { Colors, Images } from "../../../constants";
//import PieChartLocalJson from "./PiechartResponseJsonData.json";
interface AxisBottom {
  orient: any;
  tickSize: any;
  tickPadding: any;
  tickRotation: any;
  legend: any;
  legendPosition: any;
  legendOffset: any;
}
const ScatterPlotChartForDashboard = React.memo((props: any) => {
  const [scatterPlotChartJson, setScatterPlotChartJson] = useState<any>([]);
  const [isLoading, setIsLoading] = useState(true);
  const [shouldRotateLabels, setShouldRotateLabels] = useState(false);
  
  useEffect(() => {
    let { data, pageError } = props;

    setScatterPlotChartJson(data?.data);
    console.log(
      "ScatterPlot COMPONENT DATA",
      scatterPlotChartJson[0]?.data?.length
    );
  }, [scatterPlotChartJson]);

  const chartWidth = scatterPlotChartJson?.data?.length >= 10
  ? `${50 * scatterPlotChartJson?.data?.length}px`
  : "400%";
const minWidth = "300px";


useEffect(() => {
  // Inject custom scrollbar styles
  const style = document.createElement('style');
  style.type = 'text/css';
  style.innerHTML = `
    #ScatterChartScroll::-webkit-scrollbar {
      width: 12px;
      height: 12px;
      background-color: transparent; /* make scrollbar background transparent */
    }
    #ScatterChartScroll:hover::-webkit-scrollbar-thumb {
      border-radius: 10px;
      -webkit-box-shadow: inset 0 0 6px rgba(0,0,0,.3);
      background-color: #D3D3D3; /* only show scrollbar thumb on hover */
    }
  `;
  document.head.appendChild(style);

  // ... useEffect logic ...

  return () => {
    document.head.removeChild(style);
  };
}, []);

const truncateLabel = (label: string) => {
  const maxLength = 10; // Maximum number of characters to show before truncating
  if (label.length > maxLength) {
    return `${label.substring(0, maxLength)}...`; // Truncate the label
  }
  return label;
};


const renderTick = (tick: any) => {
  const { x, y, value } = tick;
  const isRotated = shouldRotateLabels;

  return (
    <g transform={`translate(${x},${y})`}>
      <text
        textAnchor={isRotated ? "end" : "middle"}
        dominantBaseline="middle"
        style={{
          fill: "#333333",
          fontSize: "12px",
        }}
        transform={`translate(${isRotated ? -5 : 0},${isRotated ? 10 : 0}) rotate(${isRotated ? -45 : 0})`}
      >
        {truncateLabel(value)}
        <title>{value}</title> {/* SVG title tag for tooltip */}
      </text>
    </g>
  );
};


type Tick = {
  value: string | number;
  x: number;
  y: number;
};

// Custom left axis tick component
const CustomLeftTick = (tick: Tick) => {
  // Convert the value to string in case it's a number
  const labelValue = tick.value.toString();
  const MAX_LENGTH = 5; // Maximum number of characters before truncating
  const label = labelValue.length > MAX_LENGTH ? `${labelValue.substring(0, MAX_LENGTH)}...` : labelValue;

  return (
    <g transform={`translate(${tick.x - 20},${tick.y})`}>
      <text
        textAnchor="end"
        dominantBaseline="middle"
        style={{
          fill: '#333',
          fontSize: '12px',
        }}
      >
        <title>{labelValue}</title> {/* Tooltip for the full label */}
        {label}
      </text>
    </g>
  );
};



  return (
   
    <div
    key="ScatterChartWrapper"
  
    style={{
        height: "100%", 
        width: "100%", 
        overflow: "hidden", 
        overflowY:"hidden",
      }}
      onMouseOver={() => {
        // Add scrollbars when hovered
        const div = document.getElementById('ScatterChartScroll');
        if (div) {
          div.style.overflowX = "auto";
          div.style.overflowY = "auto";
        }
      }}
      onMouseOut={() => {
        // Remove scrollbars when not hovered
        const div = document.getElementById('ScatterChartScroll');
        if (div) {
          div.style.overflowX = "hidden";
          div.style.overflowY = "hidden";
        }
      }}
   
  >
   
    <div
    id="ScatterChartScroll"
      key="scatterPlot01"
     
      style={{
        height: "100%",
          width: "100%", // Ensure the chart stretches to the full width and height of its container
          overflowX: "hidden", // Hide horizontal scrollbar by default
          // overflowY: "hidden",
      }}
    
    >
      {props?.pageError !== "" ? (
        <div
          key="TAB101"
          style={{
            display: "flex",
            height: "300px",
            width: "100%",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <img
            style={{
              height: "auto",
              width: "300px",
            }}
            src={Images.no_data_img}
          />
        </div>
      ) : (
     
        <div
        key="scatterPlot102"
        style={{
      

        width: chartWidth, // Use the calculated chart width
    minWidth: minWidth, // Ensure the chart has a minimum width
    height: "100%", //40vh
        
        overflowY:"hidden",
     


        }}
      >
          {props.name}
          <ResponsiveScatterPlot
            data={scatterPlotChartJson}
            // margin={{ top: 60, right: 140, bottom: 70, left: 90 }}
            margin={{ top: 60, right: 140, bottom: 50, left: 80 }}

          
            blendMode="multiply"
            axisTop={null}
            axisRight={null}
            axisBottom={{
  tickSize: 5,
  tickPadding: 5,
  tickRotation: 0,
  legend: "weight",
  legendPosition: "middle",
  legendOffset: 40,
            }}
            axisLeft={{
              // orient: "left",
              tickSize: 5,
              tickPadding: 10,
              tickRotation: 0,
              legend: "size",
              legendPosition: "middle",
              legendOffset: -75,
              renderTick: CustomLeftTick,
            }}

            // legends={[
            //   {
            //     anchor: "bottom-right",
            //     direction: "column",
            //     justify: false,
            //     translateX: -730,
            //     translateY: 0,
            //     itemWidth: 100,
            //     itemHeight: 12,
            //     itemsSpacing: 5,
            //     itemDirection: "left-to-right",
            //     symbolSize: 12,
            //     symbolShape: "circle",
            //     effects: [
            //       {
            //         on: "hover",
            //         style: {
            //           itemOpacity: 1,
            //         },
            //       },
            //     ],
            //   },
            // ]}
          />
        </div>
      )}
    </div>
    </div>
  );
});

export default ScatterPlotChartForDashboard;
