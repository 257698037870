import { THEME_NAME } from "./AppActionConst";
export const ThemeReducer = (prevState: any, action: any) => {
  switch (action.type) {
    case THEME_NAME:
      return {
        ...prevState,
        isSignout: true,
        theme: action.payload,
      };
  }
};
