import {
  React,
  useNavigate,
  useEffect,
  useState,
  useRef,
  CircularProgress,
} from "../../../../app.module";
import { Images, Fonts } from "../../../constants";
import {
  getModuleListForDasboard,
  getModuleChartForDasboard,
} from "../ModulesService";
import "../ModulesStyle";
import {
  useStyle,
  CssDashboardTextField,
  StyledQueryButton,
} from "../CreateDashboardFromModulesStyle";
import BarChartForDashboard from "../dasboard_charts/BarChartForDashboard";
import PieChartForDashboard from "../dasboard_charts/PieChartForDashboard";
import LineChartForDashboard from "../dasboard_charts/LineChartForDashboard";
import BumpChartForDashboard from "../dasboard_charts/BumpChartForDashboard";
import AreaBumpForDashboard from "../dasboard_charts/AreaBumpForDashboard";
import ScatterPlotForDashboard from "../dasboard_charts/ScatterPlotForDashboard";
import RadialChartForDashboard from "../dasboard_charts/RadialChartForDashboard";
import RadarChartForDashboard from "../dasboard_charts/RadarChartForDashboard";
import AreaChartForDashboard from "../dasboard_charts/AreaChartForDashboard";
import CardChartForDashboard from "../dasboard_charts/CardChartForDashboard";
import TableChartForDashboard from "../dasboard_charts/TableChartForDashboard";
import { Card, CardContent, Typography } from "../../../../app.module";
import { Responsive, WidthProvider } from "react-grid-layout";
import CloseIcon from "@mui/icons-material/Close";
import { useStyle1 } from "../../chart_dashboard/ModuleDashboardListStyle";
import "react-grid-layout/css/styles.css";
import "react-resizable/css/styles.css";
import { getUserDetailsFromCookies, postData } from "../../../services";
import Dialog from "@mui/material/Dialog";
import { DialogTitle } from "@mui/material";
import "../../chart_dashboard/DashboardListStyles";
const ResponsiveGridLayout = WidthProvider(Responsive);
type Module = {
  h: number;
  w: number;
  y: number;
  x: number;
  id: string;
  name: string;
};
type LayoutModulesData = {
  [key: string]: Module;
};

interface ChartItemData {
  id: number;
  name: string;
  description: {
    chartType: string;
    data: any;
  } | null;
}

const CreateDashboard = () => {
  const [moduleList, setModuleList] = useState<any[]>([]);
  const [layouts, setLayouts] = useState<{ [key: string]: any[] }>({ lg: [] });
  const [showGrid, setShowGrid] = useState(false);
  const navigate = useNavigate();
  const [layoutModulesData, setLayoutModulesData] = useState<LayoutModulesData>(
    {}
  );
  const [dashboardLayouts, setDashboardLayouts] = useState<DashboardLayouts>({
    lg: [],
  });
  const [isLoading, setIsLoading] = useState(true);
  const [dashboardName, setDashboardName] = useState("");
  const [dashboardNameError, setDashboardNameError] = useState(false);
  const [currentBreakpoint, setCurrentBreakpoint] = useState("lg");
  const [windowWidth, setWindowWidth] = useState(window.innerWidth);
  const [forceRerender, setForceRerender] = useState(0);
  const gridContainerRef = useRef<HTMLDivElement>(null);
  const [isDragging, setIsDragging] = useState(false);
  const [openDialog, setOpenDialog] = useState(false);

  const updateGridHeight = () => {
    if (gridContainerRef.current) {
      let maxBottom = 0;
      Array.from(gridContainerRef.current.childNodes).forEach((child: any) => {
        if (child instanceof HTMLElement) {
          const rect = child.getBoundingClientRect();
          maxBottom = Math.max(maxBottom, rect.bottom);
        }
      });

      const viewportHeight = window.innerHeight;
      if (maxBottom > viewportHeight * 0.87) {
        const newHeight = maxBottom + 20;
        gridContainerRef.current.style.height = `${newHeight}px`;
      } else {
        gridContainerRef.current.style.height = "87vh";
      }
    }
  };

  const getCols = (width: number) => {
    return {
      lg: 12,
      md: 10,
      sm: 8,
      xs: 6,
      xxs: 4,
    };
  };
  useEffect(() => {
    const handleResize = () => {
      setWindowWidth(window.innerWidth);
    };

    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  const cols = getCols(windowWidth);

  const onBreakpointChange = (newBreakpoint: string) => {
    setCurrentBreakpoint(newBreakpoint);
  };


  useEffect(() => {
    // Log the current screen width when the component mounts
    console.log("Current screen width:", window.innerWidth);

    // Determine and log the current breakpoint based on the screen width
    const currentBreakpoint = getCurrentBreakpoint(window.innerWidth);
    console.log("Current screen size breakpoint:", currentBreakpoint);
  }, []);

  const { classes } = useStyle();
  const { classes: classes1 } = useStyle1();

  useEffect(() => {
    const savedLayout = localStorage.getItem("dashboardLayout");
    if (savedLayout) {
      setDashboardLayouts({ lg: JSON.parse(savedLayout) });
    }
  }, []);

  const handleSaveLayout = () => {
    const currentLayout = dashboardLayouts["lg"];
    localStorage.setItem("dashboardLayout", JSON.stringify(currentLayout));
    alert("Dashboard layout saved!");
  };

  useEffect(() => {
    const fetchModuleList = async () => {
      setIsLoading(true);
      try {
        const modules = await getModuleListForDasboard();
        setModuleList(modules);
      } catch (error) {
        console.error("Error fetching module list: ", error);
      } finally {
        setIsLoading(false);
      }
    };
    fetchModuleList();
  }, []);

  const onDragStart = (
    event: React.DragEvent<HTMLDivElement>,
    module: Module
  ) => {
    console.log("Module being dragged:", module);
    event.dataTransfer.setData("module", JSON.stringify(module));
    //  setShowGrid(true);
  };

  const handleCloseDialog = () => {
    setOpenDialog(false);
    setIsDragging(false);
  };

  type ModuleDescription = {
    chartType: string;
    data: any;
  };

  type Module = {
    id: string;
    name: string;
    description: ModuleDescription;
  };

  interface LayoutItem {
    i: string;
    x: number;
    y: number;
    w: number;
    h: number;
  }

  interface DashboardLayouts {
    lg: LayoutItem[];
    //
  }

  const buildDashboardFromModules = async (requestBody: DashboardPayload) => {
    return await postData(
      `/${getUserDetailsFromCookies().identifier}/api/project/${
        getUserDetailsFromCookies().projectId
      }/dashboard`,
      requestBody
    ).then((result: any) => {
      return result;
    });
  };

  interface DashboardPayload {
    name: string;
    description: string;
    projectId: number;
    ModuleCoordinateRequests: {
      moduleId: number;
      xaxis: number;
      yaxis: number;
      width: number;
      height: number;
    }[];
  }

  
  const buildPayload = () => {
    console.log("Current Breakpoint:", currentBreakpoint); // Debug log
    console.log("Layouts:", layouts);

    const userDetails = getUserDetailsFromCookies();
    const currentWidth = window.innerWidth; // Get the current window width
    const activeBreakpoint = getCurrentBreakpoint(currentWidth); // Determine the active breakpoint

    // Use the layout of the active breakpoint to build the payload
    const coordinates = layouts[activeBreakpoint].map((layoutItem) => ({
      moduleId: parseInt(layoutItem.i),
      xaxis: layoutItem.x,
      yaxis: layoutItem.y,
      width: layoutItem.w,
      height: layoutItem.h,
    }));

    const payload = {
      name: dashboardName,
      description: "",
      projectId: userDetails.projectId,
      ModuleCoordinateRequests: coordinates,
    };

    return payload;
  };


  const handleInputChange = (e: any) => {
    setDashboardName(e.target.value);

    if (e.target.value.trim()) {
      setDashboardNameError(false); // Reset error if input is not empty
    } else {
      setDashboardNameError(true);
    }
  };

  const handleSaveDashboard = async () => {
    const payload = buildPayload();
    console.log("Payload to be sent:", payload);
    if (!dashboardName.trim()) {
      setDashboardNameError(true); // Set error state to show the error message
      return; // Prevent the dashboard from being saved
    }

    try {
      const response = await buildDashboardFromModules(payload);
      console.log("Dashboard creation response:", response);
      setOpenDialog(true);
      setIsDragging(false);
      const userDetails = getUserDetailsFromCookies();
      if (userDetails && userDetails.identifier) {
        setTimeout(() => {
          setOpenDialog(false); // Close the dialog
          // Navigate to the new dashboard or another appropriate route
          navigate(
            `/${getUserDetailsFromCookies()?.identifier}/projects/Dashboard`
          );
        }, 3000);
      } else {
        console.error("User details not found. Cannot navigate.");
      }
    } catch (error) {
      console.error("Error creating dashboard:", error);
    }
  };

  const onCancelClick = (moduleId: string) => {
    setLayouts((prevLayouts) => {
      const newLayouts = { ...prevLayouts };
      newLayouts.lg = newLayouts.lg.filter((item) => item.i !== moduleId);
      return newLayouts;
    });
    setLayoutModulesData((prevData) => {
      const moduleData = prevData[moduleId];
      if (moduleData) {
        setModuleList((prevModuleList) => [...prevModuleList, moduleData]);
        const newData = { ...prevData };
        delete newData[moduleId];
        return newData;
      } else {
        console.error("Module data not found for id:", moduleId);
        return prevData;
      }
    });
  };
  useEffect(() => {
    const updateLayoutsWithChartData = async () => {
      let updated = false;

      for (const layoutItem of layouts.lg) {
        if (!layoutItem.chartsdata) {
          try {
            const visualizationData = await getModuleChartForDasboard(
              layoutItem.i
            );
            const data = JSON.parse(visualizationData.visualizationData);
            layoutItem.chartsdata = {
              id: layoutItem.i,
              name: layoutItem.i,
              description: {
                chartType: data.chartType,
                data: data.data,
              },
            };
            updated = true;
          } catch (error) {
            console.error(
              "Error fetching chart data for module:",
              layoutItem.i,
              error
            );
          }
        }
      }

      if (updated) {
        setLayouts({ lg: [...layouts.lg] });
      }
    };

    if (layouts.lg.length > 0) {
      updateLayoutsWithChartData();
    }
  }, [layouts.lg]);

  type ScreenSize = "lg" | "md" | "sm" | "xs" | "xxs";

  const onDrop = async (
    layout: any[],
    layoutItem: any,
    event: DragEvent
  ): Promise<void> => {
    console.log("Drop event triggered with layoutItem:", layoutItem);

    if (event.dataTransfer) {
      const moduleData = event.dataTransfer.getData("module");
      console.log("Dropped module data (raw):", moduleData);

      if (moduleData) {
        try {
          const module = JSON.parse(moduleData);
          console.log("Parsed dropped module:", module);
          setShowGrid(false);

          const visualizationData = await getModuleChartForDasboard(module.id);
          console.log(
            "Fetched visualization data for module:",
            module.id,
            visualizationData
          );

          const data = JSON.parse(visualizationData.visualizationData);
          console.log("dataaaa", data);
          if (!data.chartType || !data.data) {
            console.error("Invalid data format for chart:", data);
            return;
          }

          const sizeConfigurations: Record<
            ScreenSize,
            { x: number; y: number; w: number; h: number }
          > = {
            lg: { x: 3, y: 4, w: 4, h: 2 },
            md: { x: 3, y: 4, w: 4, h: 2 },
            sm: { x: 1, y: 2, w: 2, h: 2 },
            xs: { x: 0, y: 1, w: 1, h: 3 },
            xxs: { x: 0, y: 0, w: 1, h: 2 },
          };

          setLayouts((currentLayouts) => {
            const updatedLayouts = { ...currentLayouts };
            Object.keys(sizeConfigurations).forEach((sizeKey) => {
              const size = sizeKey as ScreenSize;
              const config = sizeConfigurations[size];
              const newItem = {
                ...layoutItem,

                i: module.id.toString(),
                ...config,
                chartsdata: {
                  id: module.id,
                  name: module.name,
                  description: data,
                },
              };

              if (updatedLayouts[size]) {
                updatedLayouts[size] = [...updatedLayouts[size], newItem];
              } else {
                updatedLayouts[size] = [newItem];
              }
            });

            return updatedLayouts;
          });

          setModuleList((currentModuleList) =>
            currentModuleList.filter((m) => m.id !== module.id)
          );

          setLayoutModulesData((prevData) => {
            const newData = { ...prevData };
            newData[module.id] = module;
            return newData;
          });

          console.log(
            "Layouts and visualization data updated for module:",
            module.id
          );
        } catch (error) {
          console.error("Error during onDrop operation:", error);
        }
      } else {
        console.log("No module data found in the drop event dataTransfer.");
      }
    }
  };

  console.log("module_list", moduleList);
  console.log("Layouts", layouts);

  const ChartsData = (chartsdata: ChartItemData) => {
    console.log("dataaa", chartsdata);
    console.log("ChartsData Siddesh", ChartsData);
    if (
      !chartsdata ||
      !chartsdata.description ||
      !chartsdata.description.chartType
    ) {
      console.error("Invalid or missing description for item:", chartsdata);
    }

    switch (chartsdata?.description?.chartType) {
      case "BarChart":
        console.log("name", chartsdata.name);
        return (
          <BarChartForDashboard
            dbsource={"selecteddb"}
            data={chartsdata?.description}
            pageError={""}
            bardata={chartsdata?.description?.data}
          />
        );
      case "PieChart":
        console.log("Data for Pie  is ", chartsdata?.description?.data);
        return (
          <PieChartForDashboard
            dbsource={"selecteddb"}
            data={chartsdata?.description}
            pageError={""}
            bardata={chartsdata?.description?.data}
          />
        );
      case "RadialBarChart":
        console.log("Item", chartsdata);
        return (
          <RadialChartForDashboard
            dbsource={"selecteddb"}
            data={chartsdata?.description}
            pageError={""}
            bardata={chartsdata?.description?.data}
          />
        );

      case "CardChart":
        console.log("Item", chartsdata);
        return (
          <CardChartForDashboard
            dbsource={"selecteddb"}
            data={chartsdata?.description}
            pageError={""}
            bardata={chartsdata?.description?.data}
          />
        );
      case "TableChart":
        console.log("Item", chartsdata);
        return (
          <TableChartForDashboard
            dbsource={"selecteddb"}
            data={chartsdata?.description}
            pageError={""}
            bardata={chartsdata?.description?.data}
          />
        );
      case "RadarChart":
        console.log("Data for RadarChart is ", chartsdata?.description?.data);
        return (
          <RadarChartForDashboard
            dbsource={"selecteddb"}
            data={chartsdata?.description}
            pageError={""}
            bardata={chartsdata?.description?.data}
          />
        );
      case "AreaChart":
        console.log("Data for AreaChart is ", chartsdata?.description?.data);
        return (
          <AreaChartForDashboard
            dbsource={"selecteddb"}
            data={chartsdata?.description}
            pageError={""}
            bardata={chartsdata?.description?.data}
          />
        );
      case "LineChart":
        console.log("Data for LineChart is ", chartsdata?.description);
        return (
          <LineChartForDashboard
            dbsource={"selecteddb"}
            data={chartsdata?.description?.data}
            pageError={""}
            bardata={chartsdata?.description?.data}
          />
        );
      case "BumpChart":
        return (
          <BumpChartForDashboard
            dbsource={"selecteddb"}
            data={chartsdata?.description}
            pageError={""}
            bardata={chartsdata?.description?.data}
          />
        );
      case "AreaBumpChart":
        return (
          <AreaBumpForDashboard
            dbsource={"selecteddb"}
            data={chartsdata?.description}
            pageError={""}
            bardata={chartsdata?.description?.data}
          />
        );
      case "ScatterPlot":
        return (
          <ScatterPlotForDashboard
            dbsource={"selecteddb"}
            data={chartsdata?.description}
            pageError={""}
            bardata={chartsdata?.description?.data}
          />
        );
    }
  };

  console.log("Layouts.lg:", layouts.lg);
  console.log("Current layouts state:", layouts);

  const onModuleClick = () => {
    setShowGrid(false);
  };


  const showSuccessMessage = () => {
    setOpenDialog(true);
    setIsDragging(false); // Disable the "Create" button again after showing the success message
  };

  const onLayoutChange = (
    newLayout: LayoutItem[],
    allLayouts: { [key: string]: LayoutItem[] }
  ) => {
    setLayouts(allLayouts);
    setLayoutModulesData((prevData: LayoutModulesData) => {
      const newData: LayoutModulesData = { ...prevData };
      Object.values(allLayouts)
        .flat()
        .forEach((layoutItem: LayoutItem) => {
          // Iterate over all layouts
          if (newData[layoutItem.i]) {
            newData[layoutItem.i].x = layoutItem.x;
            newData[layoutItem.i].y = layoutItem.y;
            newData[layoutItem.i].w = layoutItem.w;
            newData[layoutItem.i].h = layoutItem.h;
          }
        });
      return newData;
    });
  };

  const onResizeStop = (
    layout: any[],
    oldItem: any,
    newItem: any,
    placeholder: any,
    e: any,
    element: any
  ) => {
    setShowGrid(false);
    let newHeight = 0;
    layout.forEach((item) => {
      const bottomPosition = item.y + item.h;
      if (bottomPosition > newHeight) {
        newHeight = bottomPosition;
      }
    });
    const newHeightVh = newHeight * 10;

    if (gridContainerRef.current) {
      gridContainerRef.current.style.height = `${newHeightVh}px`;
    }
  };

  const chartContainerStyle: React.CSSProperties = {
    backgroundColor: "white",
    borderRadius: "10px",
    boxShadow: "0 2px 4px rgba(0, 0, 0, 0.05)",
    padding: "15px",
    position: "relative",
    overflow: "hidden",
  };

  const moduleNameStyle: React.CSSProperties = {
    position: "absolute",
    fontFamily: Fonts.NunitoSans.regular,
    fontWeight: 700,
    top: 0,
    left: 0,
    padding: "10px",
    zIndex: 10,
    color: "black",
  };
  const dialogStyle: React.CSSProperties = {
    width: "622px",
    height: "99px",
    borderRadius: "10px",
    border: "1px solid #61CE8D",
    backgroundColor: "#CBEBD8",
    overflow: "hidden",
    display: "flex",
    position: "absolute", // Use fixed if you want it to be in the center regardless of scrolling
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)", // This centers the dialog
    zIndex: 1000, // Ensure
  };

  const dialogTitleStyle: React.CSSProperties = {
    display: "flex",
    flexDirection: "column",
    alignItems: "flex-start",
    position: "absolute",
    top: "50%",
    transform: "translateY(-50%)",
    zIndex: 999,
    marginLeft: "90px",
    fontSize: "24px",
    fontFamily: "Nunito Sans, sans-serif",
  };

  return (
    <div>
      <div
        style={{
          display: "flex",
          marginTop: "13.5px",
          alignItems: "center",
          marginLeft: "10px",
        }}
      >
        <CssDashboardTextField
          fullWidth
          value={dashboardName}
          onChange={handleInputChange}
          //onChange={(e) => setDashboardName(e.target.value)}
          error={dashboardNameError}
          // helperText={dashboardNameError ? "Please Enter dashboard name" : ""}
          autoFocus
          name="dashboard_name"
          placeholder="Enter dashboard name"
          InputProps={{
            classes: { input: classes.dashboardInput },
            style: {
              backgroundColor: "rgb(249,249,249)",
              border: "1px solid rgb(211,211,211)",
              borderRadius: "10px",
              width: "50%",
            },
          }}
          id="module-input"
        />
        <StyledQueryButton
          onClick={handleSaveDashboard}
          className={classes.createDashboardBtnLabelStyle}
          variant="contained"
          size="medium"
          style={{ marginLeft: "-48%", marginTop: "1.5%" }}
          disabled={isDragging || !dashboardName.trim()}
        >
          Create
          <CircularProgress
            className={classes.generateBtnCircularLoader}
            size={false ? 10 : 0}
          />
        </StyledQueryButton>
        <Dialog
          open={openDialog}
          onClose={handleCloseDialog}
          PaperProps={{
            style: dialogStyle,
          }}
          BackdropProps={{
            style: {
              backgroundColor: "transparent",
            },
            invisible: true,
          }}
        >
          <img
            src={Images.success}
            alt="Alert"
            style={{
              width: "55px",
              height: "55px",
              marginLeft: "5%",
              marginTop: "3%",
            }}
          />
          <DialogTitle style={dialogTitleStyle}>
            <div>Success</div>
            <div
              style={{
                fontSize: "16px",
                marginTop: "4px",
                fontFamily: "Nunito Sans, sans-serif",
              }}
            >
              Your dashboard has been created Successfully
            </div>
          </DialogTitle>
        </Dialog>
      </div>

      <div style={{ display: "flex", height: "87vh" }}>
        <div
          className="custom-scroll-div"
          style={{
            width: "80%",
            backgroundColor: "rgb(242,245,253)",
            overflowY: "auto",
            overflowX: "hidden",
            justifyContent: "center",
            height: "82vh",
          }}
        >
          <ResponsiveGridLayout
            key={forceRerender}
            style={{
              minHeight: "98vh",
              width: "100%",
              backgroundColor: "rgb(242,245,253)",
            }}
            layouts={layouts}
            onBreakpointChange={onBreakpointChange}
            onLayoutChange={(newLayout, allLayouts) =>
              onLayoutChange(newLayout, allLayouts)
            }
            onDrop={onDrop}
            isDroppable={true}
            isResizable={true}
            measureBeforeMount={false}
            useCSSTransforms={false}
            onDragStart={() => setShowGrid(true)}
            onDragStop={() => setShowGrid(false)}
            onResizeStart={() => setShowGrid(true)}
            onResizeStop={onResizeStop}
            preventCollision={true}
            breakpoints={{ lg: 2200, md: 996, sm: 768, xs: 480, xxs: 0 }}
            // cols={{ lg: 10, md: 10, sm: 12, xs: 8, xxs: 4 }}

            cols={cols}
            className={showGrid ? classes1.showGridBackgroundStyle : ""}
          >
            {layouts.lg.map((layoutItem, index) => (
              <div
                key={layoutItem.i || index}
                onClick={onModuleClick}
                style={chartContainerStyle}
              >
                <div style={moduleNameStyle}>{layoutItem.chartsdata.name}</div>
                {ChartsData(layoutItem.chartsdata)}
                <CloseIcon
                  onClick={() => onCancelClick(layoutItem.i)}
                  style={{
                    cursor: "pointer",
                    position: "absolute",
                    top: 10,
                    right: 10,
                  }}
                />
              </div>
            ))}
          </ResponsiveGridLayout>
        </div>

        <style>
          {`
          .custom-scroll-div::-webkit-scrollbar {
            width: 12px;
            height: 12px;
            background-color: transparent; /* make scrollbar background transparent */
          }

          .custom-scroll-div:hover::-webkit-scrollbar-thumb {
            border-radius: 10px;
            -webkit-box-shadow: inset 0 0 6px rgba(0,0,0,.3);
            background-color: #D3D3D3; /* only show scrollbar thumb on hover */
          }
        `}
        </style>

        <div
          className="custom-scroll-div"
          style={{
            width: "20%",
            backgroundColor: "#fff",
            overflowY: "auto",
            overflowX: "hidden",
            borderRadius: "2%",
            margin: "0px",
            justifyContent: "center",
            height: "82vh",
          }}
        >
          <div
            style={{
              position: "relative",
              fontSize: "20px",
              fontFamily: Fonts.NunitoSans.regular,
              fontWeight: 700,
              top: "1%",
              left: "8%",
              color: "black",
            }}
          >
            Modules
          </div>
          {isLoading ? (
            <CircularProgress />
          ) : (
            moduleList.map((module) => (
              <Card
                key={module.id}
                draggable={true}
                onDragStart={(e) => onDragStart(e, module)}
                onClick={onModuleClick}
                style={{ margin: "10px", padding: "10px" }}
                sx={{
                  display: "flex",
                  maxWidth: 250,
                  minWidth: 240,
                  minHeight: 160,
                  maxHeight: 200,
                  padding: 1.5,
                  borderRadius: "20px",
                  opacity: 0.9,
                  background: `url(${Images.dashoboard_list_placeholder}) center center no-repeat`,
                  backgroundSize: "cover",
                  color: "white",
                }}
              >
                <CardContent>
                  <Typography variant="h5" component="h2">
                    {module.name}
                  </Typography>
                </CardContent>
              </Card>
            ))
          )}
          <Card
            sx={{
              display: "flex",
              maxWidth: 240,
              minWidth: 240,
              minHeight: 160,
              maxHeight: 160,
              marginLeft: "16px",
              marginBottom: "8px",
              padding: 1.5,
              borderRadius: "10px",
              opacity: 0.9,
              backgroundColor: "rgb(243,246,253)",
            }}
          >
            <CardContent
              onClick={() => {
                navigate(
                  `/${
                    getUserDetailsFromCookies()?.identifier
                  }/projects/modules`,
                  {
                    state: { openModal: true },
                  }
                );
              }}
              style={{
                display: "flex",
                flex: 1,
                flexDirection: "column",
                padding: 5,
              }}
            >
              <div
                style={{
                  display: "flex",
                  flex: 0.1,
                  justifyContent: "flex-start",
                  alignItems: "flex-start",
                }}
              >
                <Typography
                  style={{
                    fontFamily: "Nunito Sans, sans-serif",
                    fontStyle: "normal",
                    fontWeight: 700,
                    fontSize: "20px",
                    lineHeight: "28px",
                    color: "#000",
                    textAlign: "left",
                  }}
                >
                  {"Add New Module"}
                </Typography>
              </div>
              <div
                style={{
                  display: "flex",
                  flex: 1,
                  justifyContent: "center",
                  alignItems: "center",
                  overflowX: "hidden",
                }}
              >
                <img
                  style={{
                    height: "30px",
                    width: "30px",
                  }}
                  src={Images.add_img}
                />
              </div>
            </CardContent>
          </Card>
        </div>
      </div>
    </div>
  );
};
export default CreateDashboard;
const getCurrentBreakpoint = (width: number) => {
  if (width >= 2200) return "lg"; // Example breakpoint for 'lg'
  if (width >= 996) return "md"; // Example breakpoint for 'md'
  if (width >= 768) return "sm"; // Example breakpoint for 'sm'
  if (width >= 480) return "xs"; // Example breakpoint for 'xs'
  return "xxs"; // Default to 'xxs'
};
