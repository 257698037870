//@ts-nocheck
import {
  React,
  useState,
  useEffect,
} from "../../../../app.module";
import { ResponsiveAreaBump } from "@nivo/bump";
import { useStyle } from "./ScatterPlotStyle";
import {
  getVisualizationData,
  getVisualizationType,
  getPossibalVisualization,
  getAreaBumpVisualizationData,
} from "./ChartsService";
import { Colors, Images } from "../../../constants";

import {
  getUserDetailsFromCookies,
  getQueryIdFromCookies,
} from "../../../services";
import { legend } from "@observablehq/plot";

interface AxisBottom {
  orient: any;
  tickSize: any;
  tickPadding: any;
  tickRotation: any;
  legend: any;
  legendPosition: any;
  legendOffset: any;
}

const AreaBump = React.memo((props: any) => {
  const [areaBumpJson, setAreaBumpJson] = useState<any>([]);
  const [isLoading, setIsLoading] = useState(true);
  const [activeTab, setActiveTab] = React.useState(0);
  const { classes } = useStyle({ isYaxis: false, isXaxis: true, activeTab });
  const [isScrolling, setIsScrolling] = useState(false);
  const [chartMargins, setChartMargins] = useState({ top: 40, right: 100, bottom: 40, left: 100 });

  const [bottomLegend, setBottomLegend] = useState("");
  const [leftLegend, setLeftLegend] = useState("");
  
  
  useEffect(() => {
    let data  = props.data;
    console.log("onlydata",data);
    console.log("data.data", data.data);
    setAreaBumpJson(data.data);
  }, []);

  useEffect(() => {
    let { data, axis_bottom_legend, axis_left_legend } = props.data;
    const bottomLegend = axis_bottom_legend;
    const leftLegend = axis_left_legend;
  
    setAreaBumpJson(data); 
    setBottomLegend(bottomLegend);
    setLeftLegend(leftLegend);
  }, [props.data]);
  console.log("areabumpjson",areaBumpJson);
  let scrollTimeout: NodeJS.Timeout | undefined;
  const handleMouseMove = () => {
    setIsScrolling(true);
    if (scrollTimeout) clearTimeout(scrollTimeout);
  };
  
  const handleMouseLeave = () => {
    if (scrollTimeout) clearTimeout(scrollTimeout);
    scrollTimeout = setTimeout(() => setIsScrolling(false), 1000) as NodeJS.Timeout;
  };

  const theme = {
    
    axis: {
      ticks: {
        text: {
          fontSize: 10, 
        }
      }
    },
  };

  const isStringIndexForAreaBump = () => {
    const data = areaBumpJson;
  
    if (data && data.length > 0 && data[0].data && data[0].data.length > 0) {
      return typeof data[0].data[0].x === 'string';
    }
  
    return false;
  };
  const calculateBottomMargin = (data) => {
    const longestLabelLength = data.reduce((max, series) => {
      const longestLabelInSeries = Math.max(...series.data.map(point => point.x.toString().length));
      return Math.max(max, longestLabelInSeries);
    }, 0);
    const marginPerCharacter = 5; 
    const baseMargin = 20; 
    const calculatedMargin = baseMargin + (longestLabelLength * marginPerCharacter);
    return calculatedMargin;
  };

  const dynamicBottomMargin = calculateBottomMargin(areaBumpJson);

  return (
    <div
      key="CHART101"
      style={{
        backgroundColor: Colors?.background.backgroundColorTheme3,
        height: "87vh",
        width: "75%",
        overflowX: isScrolling ? "auto" : "hidden",
          overflowY: isScrolling ? "auto" : "hidden",
        boxShadow: "0px 0px 4px 0px #00000040",
        borderRadius: "10px 0px 0px 10px",
        overflow: "scroll",
        top: 0,
      }}
      onMouseMove={handleMouseMove}
        onMouseLeave={handleMouseLeave}
    >
      <div className={classes.datasourceStyle}>
        Datasource name :<label>{props?.dbsource}</label>
      </div>
      {props?.pageError !== "" ? (
        <div
          key="TAB101"
          style={{
            display: "flex",
            height: "87vh",
            width: "100%",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <img
            style={{
              height: "auto",
              width: "300px",
            }}
            src={Images.no_data_img}
          />
        </div>
      ) : (
        <div
          key="CHART1005"
          style={{
            width:
              areaBumpJson[0]?.data?.length > 10
                ? `${100 * areaBumpJson[0]?.data?.length}px`
                : "100%",
            
            height: "78vh",
          }}
        >
        
          <ResponsiveAreaBump
            data={areaBumpJson}
            margin={{ top: 80, right: 130, bottom: dynamicBottomMargin, left: 140 }}
            spacing={8}
            colors={{ scheme: "nivo" }}
            theme={theme}
            axisTop={null}
            blendMode="multiply"
            defs={[
              {
                id: "dots",
                type: "patternDots",
                background: "inherit",
                color: "#38bcb2",
                size: 4,
                padding: 1,
                stagger: true,
              },
              {
                id: "lines",
                type: "patternLines",
                background: "inherit",
                color: "#eed312",
                rotation: -45,
                lineWidth: 6,
                spacing: 10,
              },
            ]}
            startLabel="id"
            endLabel="id"
            axisBottom={{
              tickSize: 5,
              tickPadding: 5,
              tickRotation: isStringIndexForAreaBump() ? -45 : 0,
              legendPosition: "middle",
              legendOffset: 132,
            }}
          />
        </div>
      )}
    </div>
  );
});

export default AreaBump;
