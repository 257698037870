import { getData, postData } from "../../services";
import {
  Subscriptionplans,
  PlanAddons,
  ApplyCoupon,
} from "../../constants/Endpoints";
let TOKEN: string = "";
// export const getSubscriptionPlan = async () => {
//   return await getData(Subscriptionplans).then((result) => {
//     let planset = result?.plans?.map((p: any) => new PlanResult(p));
//     let features_result = result?.features?.map(
//       (p: any) => new FeaturesResult(p)
//     );
//     return { data: { plan: planset, features: features_result } };
//   });
// };
export const getSubscriptionPlan = async () => {
  return await getData(Subscriptionplans).then((result) => {
    let planset = result?.plans?.map((p: any) => {
      console.log("PlanTypeSid", p.planType); 
      return {
        ...new PlanResult(p), 
        planType: p.planType,
        totalPrice: p.totalPrice 
      };
    });

    let features_result = result?.features?.map(
      (p: any) => new FeaturesResult(p)
    );

    return {
      data: {
        plan: planset,
        features: features_result
      }
    };
  });
};


export const getPlanAddons = async () => {
  return await getData(PlanAddons).then((result) => {
    let planaddons_result = result?.map((p: any) => new AddonsResult(p));
    return planaddons_result;
  });
};
export const subscribeToPlan = async (plan_details: any) => {
  return await postData(ApplyCoupon, plan_details).then((response) => {
    return response?.result;
  });
};

export function useCompanyService() {
  const fetcher = {};
  return [fetcher];
}
export class PlanResult {
  id?: number;
  monthlyPrice?: number;
  annualPrice?: number;
  duration?: number;
  name?: string;
  description?: string;
  priority?: number;
  minUserCount?: number;
  maxUserCount?: number;
  descriptionInhtml?: string;
  constructor(data: any) {
    Object.assign(this, {
      id: data.id,
      annualPrice: data.annualPrice,
      monthlyPrice: data.monthlyPrice,
      duration: data.duration,
      name: data.name,
      description: data.description,
      priority: data.priority,
      maxUserCount: data.maxUserCount,
      minUserCount: data.minUserCount,
      descriptionInhtml: data.descriptionInhtml,
    });
  }
}
export class AddonsResult {
  addonId?: number;
  addonprice?: number;
  maxUserCount?: number;
  minUserCount?: number;
  addonName?: string;
  addonDescription?: string;
  descriptionInhtml?: string;
  id?: number;
  isAdded?: boolean;
  constructor(data: any) {
    Object.assign(this, {
      addonId: data.addonId,
      maxUserCount: data.maxUserCount,
      addonprice: data.addonprice,
      minUserCount: data.minUserCount,
      addonName: data.addonName,
      addonDescription: data.addonDescription,
      id: data.id,
      isAdded: false,
      descriptionInhtml: data.descriptionInhtml,
    });
  }
}
export class FeaturesResult {
  featureId?: number;
  minUserCount?: number;
  appliesTo?: Array<Number>;
  durationInDays?: number;
  featureDescription?: string;
  featureName?: string;
  maxUserCount?: number;
  constructor(data: any) {
    Object.assign(this, {
      featureId: data.featureId,
      appliesTo: data.appliesTo,
      minUserCount: data.minUserCount,
      durationInDays: data.durationInDays,
      featureDescription: data.featureDescription,
      featureName: data.featureName,
      maxUserCount: data.maxUserCount,
    });
  }
}
