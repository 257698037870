import {
  makeStyles,
  Switch,
  TextField,
  styled,
  Button,
  InputBase,
  AppBar,
} from "../../../../app.module";
import { Colors, Fonts } from "../../../constants";
interface BarChartProps {
  isYaxis: boolean;
  isXaxis: boolean;
  activeTab: number;
}

export const useStyle = makeStyles<BarChartProps>()(
  (_theme, { isYaxis, isXaxis, activeTab }) => ({
    root: {
      borderBottom: "none", // Hide the bottom border of the Tab bar
    },
    circularPlanContainer: {
      color: Colors?.main,
      zIndex: parseInt(`${Colors?.zIndex?.drawer}+${1}`),
    },
    circularPlanView: {
      color: Colors?.main,
    },
    indicator: {
      display: "none", // Hide the bottom indicator of the active tab
    },
    tabLabe00: {
      width: "100%", // Set the desired width for the label
      whiteSpace: "nowrap", // Prevent label from wrapping
      overflow: "hidden", // Hide overflowed content
      textOverflow: "ellipsis", // Show ellipsis for overflowed content
    },
    tabLabe01: {
      width: "100%", // Set the desired width for the label
      whiteSpace: "nowrap", // Prevent label from wrapping
      overflow: "hidden", // Hide overflowed content
      textOverflow: "ellipsis", // Show ellipsis for overflowed content
    },
    tabLabel: {
      color: activeTab === 0 || activeTab === 1 ? "#000" : "red",
      textTransform: "initial",
      fontFamily: Fonts.NunitoSans.regular,
      fontSize: "1rem",
      fontWeight: "700",
   
    },
    visualizationSelectFlex: {
      flex: 6,
    },
    visualizationRecuuranceSelectFlex: {
      marginTop: "20px",
      flex: 0.5,
    },
    twoDropDownContainer: {
      display: "flex",
      flex: 1,
      justifyContent: "space-between",
      marginBottom: "10px",
    },
    dropDownFiledLabelContainer: {
      display: "flex",
      marginRight: "10px",
      flex: 1,
      flexDirection: "column",
    },
    dropDownFiledLabelContainerRight: {
      display: "flex",
      flex: 0.5,
      flexDirection: "column",
      marginTop: "20px",
    },
    dropDownRecurranceFiledLabelContainerRight: {
      display: "flex",
      flex: 1.5,
      flexDirection: "column",
      marginTop: "20px",
    },
    visualizationSelect: {
      backgroundColor: "#f9f9f9",
      fontFamily: Fonts.Inter.regular,
      fontStyle: "normal",
      fontWeight: "400",
      fontSize: "14px",
      lineHeight: "21px",
      borderRadius: "10px",
      marginBottom: "10px",
      display: "flex",
      //height: "56px",
      ".MuiOutlinedInput-notchedOutline": {
        border: "1px solid #f9f9f9",
      },
      "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
        border: `1px solid ${Colors?.main}`,
      },
      "&:hover .MuiOutlinedInput-notchedOutline": {
        border: `1px solid ${Colors?.main}`,
      },
    },
    saveTableBtn: {
      background:
        "linear-gradient(90deg, #5EB0FA 0%, #1157CD 50.54%, #9C90FA 100%)",
      color: "#fff",
      fontFamily: Fonts.NunitoSans.regular,
      fontStyle: "normal",
      fontWeight: 700,
      fontSize: "18px",
      lineHeight: "25px",
      textTransform: "capitalize",
      height: "47px",
      width: "125px",
      marginTop: "20px",
    },
    visualizationLable: {
      fontFamily: Fonts.NunitoSans.regular,
      fontStyle: "normal",
      fontWeight: "400",
      fontSize: "16px",
      lineHeight: "21px",
      color: "#000",
      marginBottom: "10px",
    },
    recurranceLable: {
      fontFamily: Fonts.NunitoSans.regular,
      fontStyle: "normal",
      fontWeight: "400",
      fontSize: "16px",
      lineHeight: "21px",
      color: "#000",
      marginTop: "10px",
    },
    visualizationSelectdisabled: {
      fontFamily: Fonts.NunitoSans.regular,
      fontStyle: "normal",
      fontWeight: "400",
      fontSize: "18px",
      lineHeight: "21px",
      color: "#ADADAD",
    },
    visualizationMenuitem: {
      fontFamily: Fonts.NunitoSans.regular,
      fontStyle: "normal",
      fontWeight: "400",
      fontSize: "18px",
      lineHeight: "21px",
      color: "#000",
    },
    pieVisualizationMenuitem: {
      fontFamily: Fonts.NunitoSans.regular,
      fontStyle: "normal",
      fontWeight: "400",
      fontSize: "14px",
      lineHeight: "21px",
      color: "#000",
    },
    visualizationMenuitemhidden: {
      display: "none",
      fontFamily: Fonts.NunitoSans.regular,
      fontStyle: "normal",
      fontWeight: "400",
      fontSize: "18px",
      lineHeight: "21px",
      color: "#ADADAD",
    },
    prjDropdownErrorStyle: {
      fontFamily: Fonts.NunitoSans.regular,
      fontStyle: "normal",
      fontWeight: 400,
      fontSize: "12px",
      lineHeight: "19px",
      color: Colors?.palette.error.main,
      marginLeft: "15px",
      marginTop: "3px",
      display: "flex",
    },
    visualizationBtncontainer: {
      display: "flex",
      justifyContent: "flex-end",
      alignItems: "flex-end",
      //padding: "50px",
    },
    drawerPaper: {
      // width: "150px",
      // border: "0px solid #A0A8B5",
      // backgroundColor: Colors?.background.backgroundColorTheme2, // set your desired background color here
      height: "87vh",
      //width: 200,
      boxShadow: "0 2px 8px rgba(0, 0, 0, 0.1)",
      //borderRadius: "8px",
      padding: "16px",
      backgroundColor: "#fff",
      transition: "box-shadow 0.3s ease-in-out",
      borderRadius: "0px 0px 10px 0px",
      overflow: "scroll",
      top: 92,
      //right: 100,
    },
    logoStyle: {
      height: "60px",
      width: "125px",
      objectFit: "contain",
      //marginBottom: "20px",
    },
    logoCloseStyle: {
      // height: "50px",
      // width: "70px",
      // height: "50px",
      // width: "60px"
      objectFit: "contain",
      marginTop: "1px",
      height: "40px",
      width: "55px",
    },
    logoTxtStyle: {
      marginTop: "5px",
      fontFamily: Fonts.Inter.regular,
      fontSize: "22px",
      color: "#1F2354",
      fontWeight: "bolder",
      //textAlign: "center",
      //marginTop: "20px",
      cursor: "pointer",
      textTransform: "initial",
    },
    logoBtnStyle: {
      "&:hover": {
        backgroundColor: "transparent", // Set the hover background color to transparent
      },
    },
    logoContainer: {
      justifyContent: "center",
      alignItems: "center",
      display: "flex",
      marginTop: "0px",
    },
    datasourceStyle: {
      marginLeft: "10px",
      marginTop: "10px",
    },
  })
);
