const Images = {
  add_img: require("../../assets/images/dashboard/Vector.png"),
  no_data_img: require("../../assets/images/dashboard/No data-rafiki 1 (1).png"),
  wingsbi_log: require("../../assets/images/wingsbi_logo.jpeg"),
  active_project: require("../../assets/images/drawer/project1.png"),
  inactive_project: require("../../assets/images/drawer/project0.png"),
  active_home: require("../../assets/images/drawer/home1.png"),
  inactive_home: require("../../assets/images/drawer/home0.png"),
  active_module: require("../../assets/images/drawer/module1.png"),
  inactive_module: require("../../assets/images/drawer/module0.png"),
  active_user: require("../../assets/images/drawer/Vector.png"),
  inactive_user: require("../../assets/images/drawer/Vector (3).png"),
  active_setting: require("../../assets/images/drawer/setting0.png"),
  inactive_setting: require("../../assets/images/drawer/Vector (2).png"),
  active_bot:require("../../assets/images/drawer/bot 2.png"),
  inactive_bot:require("../../assets/images/drawer/bot1.png"),
  keypad: require("../../assets/images/Keypad.png"),
  marketing_right_img: require("../../assets/images/marketing/marketing_right.png"),
  logo_name: require("../../assets/images/marketing/logo_name.png"),
  logomark_svg: require("../../assets/images/marketing/wings_cmp_logo.png"),
  marketing_logo_name: require("../../assets/images/Logo Assets/cropedWingsBI_LogoWordmark_NoBackground.png"),
  triangle: require("../../assets/images/guided/Vector 349.png"),
  guide2: require("../../assets/images/guided/Group 421.png"),
  guide3: require("../../assets/images/guided/Group 579.png"),
  guide4: require("../../assets/images/guided/Group 581 (1).png"),
  checked: require("../../assets/images/guided/Vector.png"),
  not_checked: require("../../assets/images/guided/Vector Not checked.png"),
  multiply: require("../../assets/images/guided/Multiply.png"),
  plan_right: require("../../assets/images/subscription/Vector.png"),
  order_summary_circle: require("../../assets/images/subscription/Ellipse 46.png"),
  dashoboard_list_placeholder: require("../../assets/images/dashboard/dashboard_list.png"),
  createproject_placeholder: require("../../assets/images/dashboard/createproject.png"),
  user_invite_button: require("../../assets/images/dashboard/User-Invite-Button.png"),
  cancel: require("../../assets/images/dashboard/Cancel.png"),
  alert: require("../../assets/images/dashboard/Alert.png"),
  Wingsbi_Logo: require("../../assets/images/Logo Assets/Wingsbi Logo.png"),
  Mic1:require("../../assets/images/mmmm.png"),
  Mic2:require("../../assets/images/mmm.gif"),
  Org:require("../../assets/images/Frame.png"),
  Bot:require("../../assets/images/bot5.png"),
  Minimize:require("../../assets/images/minimize.png"),
  success:require("../../assets/images/dashboard/Success.png"),
  Database:require("../../assets/images/db.png"),
  Csv:require("../../assets/images/csv.png"),
  // Arrow:require("../../assets/images/Arrow.png"),
  Upload:require("../../assets/images/upload.png"),
};

export { Images };
