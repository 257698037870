import { useEffect } from "react";
import {
  Card,
  Box,
  Avatar,
  Grid,
  InputLabel,
  FormControl,
  IconButton,
  InputAdornment,
  Button,
  Typography,
  useState,
  Paper,
  useNavigate,
  OutlinedInput,
  VisibilityOff,
  useContext,
  TextField,
} from "../../../app.module";
import { Images, AppThemes, Colors, WingsbiTheme } from "../../constants";
import {
  ConfirmEmailStyle,
  CssOutlinedInput,
  CssConfirmOtpTextField,
} from "./ConfirmEmailStyle";
//import { ThemeContext } from "../../context/ThemeContext";
import { AppStatesContainer } from "../../context/AppStatesContainer";
const ConfirmEmail = (props: any) => {
  const navigate = useNavigate();
  const [confirmEmail, setConfirmEmail] = useState("");
  const [status, setStatus] = useState(false);
  const { classes } = ConfirmEmailStyle();
  const [seconds, setSeconds] = useState(30);

  //const { theme } = useContext(ThemeContext);
  const theme = useContext(AppStatesContainer);
  useEffect(() => {
    //    console.log("Payload details, ", theme);
    //setConfirmEmail(accessTokenPayload?.email);
  }, []);
  useEffect(() => {
    let myInterval = setInterval(() => {
      if (seconds > 0) {
        setSeconds(seconds - 1);
      }
      if (seconds === 0) {
        clearInterval(myInterval);
      }
    }, 1000);
    return () => {
      clearInterval(myInterval);
    };
  });
  const setAppTheme = () => {
    //AppThemes.typography.h1.fontSize = "70px";
    //setTheme(AppThemes);
    //setTheme(AppThemes);
    // theme.themeActions.setTheme("dark");
    //WingsbiTheme.setColor("#987999");
    // setStatus(true);
    //console.log("THEME OF CONTEXT", theme.themeState);
  };

  const check = () => {
    //console.log("THEME OF CONTEXT", theme.themeState);
    navigate("/company");
  };
  return (
    <Grid
      container
      spacing={0}
      direction="column"
      alignItems={"center"}
      justifyContent={"center"}
      style={{ minHeight: "100vh", backgroundColor: "##F5F5F5" }}
    >
      <div
        style={{
          justifyContent: "center",
          alignItems: "center",
          display: "flex",
        }}
      >
        <Avatar
          className={classes.logoConfirmCodeStyle}
          alt="Header Wingsbi logo"
          src={Images.wingsbi_log}
        />
      </div>
      <Grid item xs={2}>
        <Paper
          elevation={0}
          //variant="outlined"
          className={classes.CardContainer}
        >
          <div>
            <div
              style={{
                justifyContent: "center",
                alignItems: "center",
                display: "flex",
              }}
            >
              <h2 className={classes.CmpDetailsHeading}>Confirm your mail</h2>
            </div>

            <div className={classes.twoConfirmOtpFieldsContainer}>
              <div className={classes.textFiledLabelContainerRight}>
                <Typography
                  className={classes.confirmEmailLabelStyle}
                  variant={"h5"}
                >
                  A verification code has been sent to you at
                  <Typography className={classes.emailStyle} display={"inline"}>
                    &nbsp;{confirmEmail}
                  </Typography>
                  . Enter the code below:
                </Typography>
                <CssConfirmOtpTextField
                  fullWidth
                  //onChange={onChange}
                  label="Verification code"
                  InputProps={{
                    classes: { input: classes.confirmInput },
                  }}
                  id="custom-css-standard-input"
                />
              </div>
            </div>
            <div className={classes.resendContainer}>
              <InputLabel className={classes.timerStyle}>
                You can resend the code in 00:{seconds}
              </InputLabel>
              <Button
                onClick={() => {
                  check();
                }}
                className={classes.linkResendStyle}
              >
                Resend link
              </Button>
            </div>
            <div className={classes.twoConfirmOtpFieldsContainer}>
              <Button
                // style={{
                //   backgroundColor: "#8e8e8e",
                //   color: "#fff",
                //   textTransform: "capitalize",
                //   height: "50px",
                // }}
                onClick={() => {
                  //  navigate("/company")

                  setAppTheme();
                }}
                className={classes.ConfirmEmailBtn}
                fullWidth
                variant="contained"
                size="large"
              >
                Confirm Email
              </Button>
            </div>
            <div className={classes.twoConfirmOtpFieldsContainer}>
              <div className={classes.textFiledLabelContainerRight}>
                <InputLabel className={classes.haventRecevieLabelStyle}>
                  Haven’t received an email? Please check your spam folder or
                  contact us at info@wingsbi.com
                </InputLabel>
              </div>
            </div>
          </div>
        </Paper>
      </Grid>
    </Grid>
  );
};

export default ConfirmEmail;
