import { BASE_URL } from "../../config/WingsbiConfig";
import { getUserAccessTokenFromCookies } from "../cookie/cookies";
import { refreshTokenBeforeExpire } from "../msal/RefreshToken";
export const postData = async (path: string, data: any) => {
  //await refreshTokenBeforeExpire();
  const response = fetch(BASE_URL + path, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      Authorization: "Bearer " + getUserAccessTokenFromCookies(),
    },
    body: JSON.stringify(data),
  })
    .then((response) => response.json())
    .then((responseJson) => {
      return responseJson;
    })
    .catch((error) => console.log(error)); //to catch the errors if any
  console.log("sdjfjads", response);
  return response;
};

export const postToPythonServer = (path: string, data: any) => {
  const response = fetch(
    "https://query-executor-dev.azurewebsites.net" + path,
    {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        //Authorization: "Bearer " + token,
      },
      body: JSON.stringify(data),
    }
  )
    .then((response) => response.json())
    .then((responseJson) => {
      return responseJson;
    })
    .catch((error) => console.log(error)); //to catch the errors if any
  console.log("sdjfjads", response);
  return response;
};

export const postEncodedUrlData = (path: string, data: any) => {
  const params = new URLSearchParams();
  const response = fetch(encodeURIComponent(BASE_URL + path), {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      //Authorization: "Bearer " + token,
    },
    body: JSON.stringify(data),
  })
    .then((response) => response.json())
    .then((responseJson) => {
      console.log("sdjfjads", response);
      return responseJson;
    })
    .catch((error) => console.log(error)); //to catch the errors if any
  console.log("sdjfjads", response);
  return response;
};
