import {
  makeStyles,
  Switch,
  TextField,
  styled,
  Button,
} from "../../../app.module";
import { Colors, Fonts } from "../../constants";

export const StyledButton = styled(Button)({
  color: "#fff",
  padding: "6px 12px",
});
export const CustomSwitch = styled(Switch)({
  switchBase: {
    color: "#52af77",
    "&$checked": {
      color: "#52af77",
    },
    "&$checked + $track": {
      backgroundColor: "#52af77",
    },
  },
  checked: {},
  track: {},
});
export const CssPlanTextField = styled(TextField)({
  "& label.Mui-focused": {
    color: "black",
    borderRadius: "10px",
  },
  "& .MuiInput-underline:after": {
    borderBottomColor: "red",
    borderRadius: "10px",
  },

  "& .MuiOutlinedInput-root": {
    "& fieldset": {
      border: `1px solid ${Colors?.border.plan.borderColor}`,
      borderRadius: "10px",
    },
    "&:hover fieldset": {
      border: `1px solid ${Colors?.main}`,
      borderRadius: "10px",
    },
    "&.Mui-focused fieldset": {
      border: `1px solid ${Colors?.main}`,
      borderRadius: "10px",
    },
  },
});
export const useStyle = makeStyles()((theme?: any) => {
  return {
    circularPlanContainer: {
      color: Colors?.main,
      zIndex: parseInt(`${Colors?.zIndex?.drawer}+${1}`),
    },
    circularPlanView: {
      color: Colors?.main,
    },
    planContainer: {
      backgroundColor: "##fff",
    },
    lockedScrollbar: {
      overflow: 'hidden',  // Prevents scrolling
      scrollbarWidth: 'none',  // Firefox
      '-ms-overflow-style': 'none',  // Internet Explorer 10+
      '&::-webkit-scrollbar': {
        display: 'none'  // Chrome, Safari, Opera
      }
    },
    planTopContainer: {
      backgroundColor: Colors?.border.plan.backgroundColor,
      display: "flex",
      flexDirection: "column",
    },
    text: {
      color: "white",
    },
    planCardContainer: {
      borderTop: 10,
      maxWidth: 345,
    },
    planHeading: {
      height: "auto",
      width: "15%",
      marginTop: "10px",
      marginLeft: "50px",
      backgroundColor: "white",
      borderRadius: "20px",
    },
    colortile: {
      backgroundColor: "#0B55CC",
      WebkitTextFillColor: "white",
    },
    
    planTopLabel: {
      fontFamily: Fonts.NunitoSans.regular,
      fontStyle: "normal",
      fontWeight: "700",
      fontSize: "28px",
      // lineHeight: "38px",
      color: Colors?.palette.label.main,
      // marginBottom: "20px",
    },
    
    planTopPriceLabel: {
      fontFamily: Fonts.NunitoSans.regular,
      fontStyle: "normal",
      fontWeight: "700",
      fontSize: "28px",
      lineHeight: "38px",
      color: Colors?.palette.label.main,
      marginBottom: "10px",
      marginTop:'-10px',
    },
    PlanUserLabel: {
      fontFamily: Fonts.Inter.regular,
      fontStyle: "normal",
      fontWeight: "400",
      fontSize: "18px",
      lineHeight: "22px",
      textAlign: "center",
      color: Colors?.palette.label.main,
    },
    AddonsContainer: {
      display: "flex",
      justifyContent: "space-between",
      alignItems: "flex-start",
      marginBottom: "30px",
      flexDirection: "row",
      boxShadow: "none",
      border: `1px solid ${Colors?.border.plan.borderColor}`,
      borderRadius: 17,
    },
    planAddonsTitlel: {
      fontFamily: Fonts.NunitoSans.regular,
      fontStyle: "normal",
      fontWeight: "700",
      fontSize: "20px",
      lineHeight: "27px",
      textAlign: "left",
      color: Colors?.palette.label.main,
      marginLeft: "20px",
      width: "200px",
    },
    planAddonsDescription: {
      fontFamily: Fonts.Inter.regular,
      fontStyle: "normal",
      fontWeight: "400",
      fontSize: "16px",
      lineHeight: "19px",
      textAlign: "left",
      marginLeft: "2px",
      color: Colors?.palette.darkTitle.theme1,
    },
    planAddonsPrice: {
      fontFamily: Fonts.NunitoSans.regular,
      fontStyle: "normal",
      fontWeight: "700",
      fontSize: "32px",
      lineHeight: "38px",
      color: Colors?.palette.label.main,
      textAlign: "left",
    },
    CardContentWithoutPadding: {
      padding: 0,
      "&:last-child": {
        paddingBottom: 0,
      },
    },
    planAddonsDetailsCollaps: {
      fontFamily: Fonts.Inter.regular,
      fontStyle: "normal",
      fontWeight: "600",
      fontSize: "10px",
      lineHeight: "12px",
      color: Colors?.border.plan.details,
    },
    PlanAddonsDetails: {
      display: "flex",
      alignItems: "flex-start",
      cursor: "pointer",
      flexDirection: "column",
    },
    planAddonsDirection: {
      flexDirection: "row",
      alignItems: "center",
      justifyContent: "flex-start",
      marginLeft: "20px",
      width: "150px",
    },
    planTextFiledLabelContainer: {
      display: "flex",
      flex: 0.13,
      flexDirection: "column",
      marginLeft: "10px",
      borderRadius: "10px",
      overflow: "auto",
    },
    Planmargin: {
      marginTop: "0px", 
    },
    PlanBoxContainer: {
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      marginTop: "46px",
    },
    PlanAddonsBoxContainer: {
      display: "flex",
      flexDirection: "column",
      justifyContent: "center",
      alignItems: "flex-start",
      marginTop: "60px",
      marginLeft: "60px",
      marginRight: "60px",
    },
    btnPlanAddonLabelStyle: {
      fontFamily: Fonts.NunitoSans.regular,
      fontStyle: "normal",
      fontWeight: 700,
      fontSize: "18px",
      lineHeight: "25px",
      borderRadius: "10px",
      background:
        "linear-gradient(90deg, #5EB0FA -8.73%, #1157CD 51.67%, #9C90FA 110.24%)",
      color: "#fff",
      textTransform: "capitalize",
      height: "50px",
      width: "185px",
    },
    tilecolor: {
      backgroundColor: "white",
    },
    btnInactivePlanAddonsLabelStyle: {
      fontFamily: Fonts.NunitoSans.regular,
      fontStyle: "normal",
      fontWeight: 700,
      fontSize: "18px",
      lineHeight: "25px",
      background: Colors?.palette.lightbg.theme1,
      color: "#000",
      textTransform: "capitalize",
      height: "50px",
      width: "185px",
      borderRadius: "10px",
      border: `1px solid ${Colors?.border.plan.borderColor}`,
      "&:hover": {
        background: Colors?.palette.lightbg.theme1,
      },
      "&:focus": {
        background: Colors?.palette.lightbg.theme1,
      },
    },
    btnPlanLabelStyle: {
      fontFamily: Fonts.NunitoSans.regular,
      fontStyle: "normal",
      fontWeight: 700,
      fontSize: "18px",
      lineHeight: "25px",
      borderRadius: "10px",
      background:
        "linear-gradient(90deg, #5EB0FA -8.73%, #1157CD 51.67%, #9C90FA 110.24%)",
      color: "#fff",
      textTransform: "capitalize",
      height: "50px",
      width: "185px",
      marginBottom: "30px",
    },
    webkitTextFillColor1: {
      color: "#fff",
    },
    webkitTextFillColor2: {
      color: "#000",
    },
    btnInactivePlanLabelStyle: {
      fontFamily: Fonts.NunitoSans.regular,
      fontStyle: "normal",
      fontWeight: 700,
      fontSize: "18px",
      lineHeight: "25px",
      background: Colors?.palette.lightbg.theme1,
      WebkitTextFillColor: "#000",
      textTransform: "capitalize",
      height: "50px",
      width: "185px",
      borderRadius: "10px",
      marginBottom: "30px",
      marginTop: '-15px',
      border: `1px solid ${Colors?.border.plan.borderColor}`,
      "&:hover": {
        background: Colors?.palette.lightbg.theme1,
      },
      "&:focus": {
        background: Colors?.palette.lightbg.theme1,
      },
    },
    planDescriptionStyle: {
      fontFamily: Fonts.Inter.regular,
      fontStyle: "normal",
      fontWeight: "400",
      fontSize: "16px",
      lineHeight: "19px",
      color: "#000000",
    },
    planInput: {
      backgroundColor: "#fff",
      fontFamily: Fonts.NunitoSans.regular,
      fontStyle: "normal",
      fontWeight: "400",
      fontSize: "18px",
      lineHeight: "21px",
      borderRadius: "10px",
      height: "auto",
      color: "#000000",
      "&::placeholder": {
        color: "#ADADAD",
      },
    },
    PlanCard: {
      marginTop: theme.spacing(2),
      [theme.breakpoints.down("md")]: {
        marginTop: theme.spacing(6),
      },
    },
    noTopBorder: {
      border: "1px solid #fff",
      "& > td, & > th": {
        borderTop: "none",
      },
    },
    noBottomBorder: {
      border: "1px solid #fff",
      "& > td, & > th": {
        borderBottom: "none",
      },
    },
    PlanTableCell: {
      width: "250px",
      borderLeftWidth: "1px",
      borderColor: Colors?.border.plan.borderColor,
    },
    PlanBoxTableContainer: {
      marginLeft: "45px",
      marginRight: "55px",
    },
    PlanHeader: {
      display: "flex",
      alignItems: "center",
      cursor: "pointer",
    },

    Title: {
      marginRight: theme.spacing(1),
      fontFamily: Fonts.NunitoSans.regular,
      fontStyle: "normal",
      fontWeight: "700",
      fontSize: "16px",
      lineHeight: "22px",
      color: Colors?.palette.darkTitle.theme1,
      width: "150px",
    },
    headerTitle: {
      fontSize: "20px",
      lineHeight: "27px",
    },
    featuresDescription: {
      lineHeight: "19px",
      fontWeight: 400,
      fontFamily: Fonts.Inter.regular,
    },
    CollapsContent: {
      padding: theme.spacing(2),
    },
    userProfileContainer: {
      display: "flex",
      justifyContent: "flex-end",
      alignItems: "flex-end",
      marginLeft:"84%"
    },
    userProfileTxt:{
      fontSize:"18px",
      marginTop:"1px",
      fontFamily: Fonts.NunitoSans.regular,
    },
    godown:{
      marginRight:"200px",
    },
    userNameStyle: {
      fontFamily: Fonts.NunitoSans.regular,
      fontStyle: "normal",
      fontWeight: "700",
      fontSize: "17px",
      lineHeight: "26px",
      textAlign: "left",
      color: Colors?.palette.label.main,
    },
    userRoleTitle: {
      fontFamily: Fonts.Inter.regular,
      fontStyle: "normal",
      fontWeight: "400",
      fontSize: "14px",
      lineHeight: "17px",
      color: Colors?.palette.label.main,
    },
    appBarStyle: {
      boxShadow: "none",
      backgroundColor: Colors?.background.backgroundColorTheme2,
      height: "65px",
    },
    toolBarStyle: {
      boxShadow: "none",
      backgroundColor: Colors?.background.backgroundColorTheme3,
      display: "flex",
      flex: 1,
      height: "90px",
      borderRadius: "20px 0px 0px 0px",
    },
    userProfileDetailsCollaps: {
      fontFamily: Fonts.Inter.regular,
      fontStyle: "normal",
      fontWeight: "600",
      fontSize: "20px",
      lineHeight: "16px",
      color: Colors?.palette.label.main,
    },
    drawerPaper: {
      width: "150px",
      border: "0px solid #A0A8B5",
      backgroundColor: Colors?.background.backgroundColorTheme2, 
    },
    logoContainer: {
      justifyContent: "center",
      alignItems: "center",
      display: "flex",
      marginTop: "0px",
    },
    appBarSearch: {
      display: "flex",
      borderRadius: "30px",
      height: "40px",
      backgroundColor: "#FFF",
      marginLeft: 0,
    },
    userActionTitle: {
      fontFamily: Fonts.Inter.regular,
      fontStyle: "normal",
      fontWeight: "400",
      fontSize: "16px",
      lineHeight: "19px",
      textTransform: "initial",
      color: Colors?.palette.label.main,
      padding: 5,
    },
    modalOverlay: {
      backgroundColor: "rgba(0, 0, 0, 0)", 
      zIndex: theme.zIndex.modal + 1,
    },
    userProfileImg: {
      height: "36px",
      width: "36px",
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      borderRadius: "18px",
      marginRight: "10.5px",
      marginBottom:"3px",
      background:"linear-gradient(202.64deg, #E24A95 21.95%, #4A2BA3 92.07%)",
    },
    searchIconWraper: {
      padding: "8px",
      height: "100%",
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
    },

    listItemTextContainer: {
      fontFamily: Fonts.NunitoSans.regular,
      fontStyle: "normal",
      fontWeight: "600",
      fontSize: "16px",
      lineHeight: "22px",
      color: "#A0A8B5",
    },
    drawerTitleColor: {
      color: "#000000",
    },
    listItemContainer: {
      display: "flex",
      padding: "15px",
      paddingLeft: "25px",
    },
    searchInput: {
      fontFamily: Fonts.NunitoSans.regular,
      fontStyle: "normal",
      fontWeight: "400",
      fontSize: "16.6957px",
      lineHeight: "19px",
      color: Colors?.path.light,
    },
    menuIcons: {
      height: "20px",
      width: "20px",
      marginRight: "5px",
    },
    logoCloseStyle: {
      height: "50px",
      width: "60px",
      objectFit: "contain",
      marginTop: "3px",
      marginLeft: "2px",
    },
    logoTxtStyle: {
      marginTop: "5px",
      fontFamily: Fonts.Inter.regular,
      fontSize: "22px",
      color: "#1F2354",
      fontWeight: "bolder",
      cursor: "pointer",
      textTransform: "initial",
    },
    logoBtnStyle: {
      "&:hover": {
        backgroundColor: "transparent", 
      },
    },
    dasboardContainer: {
      backgroundColor: "#fff", 
      margin: "10px 0px 10px 20px", 
      borderRadius: "10px",
      padding: 0,
  
    },
  };
});
