import {
  Card,
  Typography,
  CardActions,
  Button,
  Box,
  CardContent,
  useEffect,
  Avatar,
  useContext,
  useLocation,
  Link,
} from "../../../app.module";
import { ContactusStyle } from "./ContactusStyle";
import { ThemeContext } from "../../context/ThemeContext";
import { Images, AppThemes } from "../../constants";
import clsx from "clsx";
const Contactus = (props: any) => {
  //const { classes } = useStyle();
  const { theme, setTheme } = useContext(ThemeContext);
  const location = useLocation();
  console.log("Them Context details", theme);
  const { classes } = ContactusStyle();
  // useEffect(() => {
  //   console.log("DATA SET");
  //   let tdaya = theme;
  //   tdaya.palette.primary.main = "red";
  //   setTheme(tdaya);
  // }, [theme]);
  const setApplicationTheme = () => {
    //palette.primary.main = "#000";
    // useStyle().theme.palette.primary.main = "#983432";
    //useTheme().palette.mode = "light";
    let tdaya = theme;
    //tdaya.palette.primary.main = "red";
    setTheme(tdaya);
  };
  return (
    <div>
      <div className={classes.marketingHeader}>
        <div>
          <div className={classes.marketingMargin}>
            <Link style={{ textDecoration: "none" }} to="/">
              <Typography className={classes.marketingMenuStyle}>
                HOME
              </Typography>
            </Link>
          </div>
        </div>
        <div>
          <div className={clsx(classes.barWidth, classes.marketingBar)}></div>

          <Link style={{ textDecoration: "none" }} to="/contactus">
            <Typography className={classes.marketingContactustyle}>
              CONTACT US
            </Typography>
          </Link>
        </div>
      </div>
      <div className={classes.marketingContainer}>
        <div className={classes.marketingMargin}>
          <div className={clsx(classes.direction, classes.container)}>
            <div className={classes.container}>
              <Avatar
                className={classes.marketingHeading}
                alt="Header Wingsbi logo"
                src={Images.wingsbi_log}
              />
              <Typography className={classes.wingsStyle}>WingsBI</Typography>
            </div>
            <Typography className={classes.emailStyle}>
              Get Power of AI for your Business
            </Typography>
          </div>
          <div className={clsx(classes.container)}>
            <CardActions>
              <Button
                onClick={() => {
                  //  login("/company")
                  setApplicationTheme();
                }}
                variant="contained"
                style={{
                  background:
                    "linear-gradient(90deg, #5EB0FA 0%, #1157CD 50.54%, #9C90FA 100%)",
                  color: "#fff",
                  textTransform: "capitalize",
                  height: "50px",
                  width: "150px",
                }}
              >
                Log in
              </Button>
            </CardActions>
            <CardActions>
              <Button
                style={{
                  background:
                    "linear-gradient(90deg, #5EB0FA 0%, #1157CD 50.54%, #9C90FA 100%)",
                  color: "#fff",
                  textTransform: "capitalize",
                  height: "50px",
                  width: "150px",
                }}
                // onClick={() => login("/confirmmail")}
                variant="contained"
              >
                Sign up
              </Button>
            </CardActions>
          </div>
        </div>
        <div className={classes.rightImg}>
          <img
            className="img-fluid"
            style={{ height: "350px" }}
            alt="Header Wingsbi logo"
            src={Images.marketing_right_img}
          />
        </div>
      </div>
      <div className={clsx(classes.direction, classes.vcontainer)}>
        <div style={{ marginTop: "30px" }}>
          <Typography className={classes.aiTitleStyle}>
            How AI is helping data visualization
          </Typography>
        </div>
        <div className={classes.videoDiv}>
          <iframe
            className={classes.iframeDiv}
            //https://www.youtube.com/watch?v=i4p_Z2aTG9w
            //https://youtu.be/sMi3JLUhAIc
            src="https://www.youtube.com/embed/sMi3JLUhAIc"
            //width={1000}
            //height={500}
            title="A youtube video on React hooks"
          ></iframe>
        </div>
      </div>
    </div>
  );
};

export default Contactus;
