import React, { useEffect, useRef } from "react";
import * as d3 from "d3";

const D3Chart = () => {
  const chartRef = useRef(null);

  useEffect(() => {
    // D3 chart code
    const svg = d3
      .select(chartRef.current)
      .append("svg")
      .attr("width", 400)
      .attr("height", 300);

    svg
      .append("circle")
      .attr("cx", 200)
      .attr("cy", 150)
      .attr("r", 50)
      .style("fill", "blue");
  }, []);

  return (
    <div
      ref={chartRef}
      style={{ width: "400px", height: "300px", border: "1px solid black" }}
    ></div>
  );
};

export default D3Chart;
