import {
  React,
  Grid,
  Card,
  CardContent,
  Typography,
  Button,
  clsx,
  Modal,
  useRef,
  useEffect,
  Backdrop,
  CircularProgress,
  useNavigate,
  Box,
} from "../../../app.module";

import "./DashboardListStyles";
import { Colors, Images } from "../../constants";
import DataTable from "../Table/DataTable";
import {
  useStyle,
} from "./ModuleDashboardListStyle";
import {
  getDashboardListData,
} from "./DashboardListService";
import { useState, RefObject } from "react";
import {
  getUserDetailsFromCookies,
  getQueryIdFromCookies,
} from "../../services";

interface ChildMethods {
  childMethod: () => void;
  onSaveData: (data: any) => void;
  resetchildMethod: () => void;
}

const DashboardList = () => {
  const [isLoading, setIsLoading] = useState(false);
  const childRef: RefObject<ChildMethods> = useRef<ChildMethods>(null);
  const contentRef = useRef<HTMLDivElement | null>(null);
  const [pageError, setPageError] = useState("Nodata");
  const [isGenerateButtonDisabled, setIsGenerateButtonDisabled] =
    useState(true);

  const [isButtonEnabled, setIsButtonEnabled] = useState(false);
  const [generateErrorModal, setGenerateErrorModal] = useState(false);
  const [errorModal, setErrorModal] = useState(false);
  const [moduleValues, setFormValues] = useState({
    module_name: {
      value: "",
      error: false,
      errorMessage: "You must enter a module name",
    },
    query_input: {
      value: "",
      error: false,
      errorMessage: "",
    },
  });

  const [dashboardItems, setDashboardItems] = useState<any>([]);
  const dashboardListLength = dashboardItems.length;
  const { classes } = useStyle(dashboardListLength);
  const navigate = useNavigate();
  useEffect(() => {
    setIsLoading(true);
    getDashboardListData()
      .then((resp) => {
        console.log("Data loaded successfully.", resp);
        resp.push({ dashboardName: "" });
        setDashboardItems([...resp]);
      })
      .catch((error) => {
        console.error("Error loading data:", error);
      })
      .finally(() => setIsLoading(false));
  }, []);

  const handleDashboard = async (dashboardId: number, dashboardName: string) => {
    navigate(
      `/${getUserDetailsFromCookies()?.identifier}/projects/viewdashboard`,
      {
        state: {
          dashboardId:dashboardId,
          dashboardName:dashboardName,
        },
      }
    );
  };

  const handleClose = () => {
    setIsLoading(false);
  };

  const handleGenerateErrorModal = () => {
    return (
      <React.Fragment>
        <Modal
          open={generateErrorModal}
          onClose={() => {
            setGenerateErrorModal(!generateErrorModal);
          }}
          aria-labelledby="child-modal-title"
          aria-describedby="child-modal-description"
        >
          <Box
            sx={{
              position: "absolute" as "absolute",
              top: "50%",
              left: "50%",
              transform: "translate(-50%, -50%)",
              width: 300,
              display: "flex",
              flexDirection: "column",
              bgcolor: "background.paper",
              justifyContent: "center",
              boxShadow: 24,
              borderRadius: "5px",
              pt: 2,
              px: 4,
              pb: 3,
            }}
          >
            <h4
              style={{
                textAlign: "center",
              }}
              id="child-modal-title"
            >
              {pageError}
            </h4>
            <div className={clsx(classes.generateModalBtncontainer)}>
              <div>
                <Button
                  onClick={() => {
                    setGenerateErrorModal(false);
                  }}
                  variant="contained"
                  className={classes.generateModalBtn}
                >
                  Okay
                </Button>{" "}
              </div>
            </div>
          </Box>
        </Modal>
      </React.Fragment>
    );
  };
  console.log("dashboardItems",dashboardItems);
  return (
    <div className={classes.dashBoardContainer} >
       <div style={{ fontWeight: 700, fontSize: '23px', marginLeft: '30px', marginTop: '15px' }} >All Dashboards</div>
      <Backdrop
        className={classes.circularPlanContainer}
        open={isLoading}
        onClick={handleClose}
      >
        <CircularProgress className={classes.circularPlanView} />
      </Backdrop>
      {handleGenerateErrorModal()}
      {dashboardItems.length === 0 && (
        <div
          style={{
            display: "flex",
                          width: "100%",
                          height: "360px",
                          justifyContent: "center",
                          alignItems: "center",
                          overflowX: "hidden",
          }}
        >
          <img
            style={{
              height: "auto",
              width: "400px",
            }}
            src={Images.no_data_img}
          />
        </div>
      )}
      <Grid 
      
         justifyContent="left"
         alignItems="row"
        flexDirection={"row"}
        container
        sx={{
       
          borderRadius: "20px",
         
        }}
      >
        {dashboardItems?.map((item: any, index: Number) => (
  (item.dashboardName !== "" || (getUserDetailsFromCookies().roleName !== 'Viewer')) && (
      // <Grid  columns={{  xs: 12, sm: 6, md: 4, lg: 3, xl:2  }} justifyContent = "center" padding={2} alignItems="center" >  
<Grid  columns={{  xs: 12, sm: 6, md: 4, lg: 3, xl:2  }} justifyContent = "center" padding={2} alignItems="center" 
  >   
      <Grid  item >
          <Button
          className={classes.moduleButton}
          onClick={() => {
            if (item.dashboardId) {
              handleDashboard(item.dashboardId, item.dashboardName);
            }
          }}
        >
          <Card
            sx={{
            
               display: "flax",
              backgroundColor:"red",
              maxWidth: 251.8,
              minWidth: 251.8,
              minHeight: 160,
              maxHeight: 160,
              padding: 1.5,
              borderRadius: "10px",
              opacity: 0.9,
             
              background: item.dashboardName
                ? `url(${Images.dashoboard_list_placeholder}) center center no-repeat`
                : Colors?.background.backgroundColorTheme3,
             }}
          >
            <CardContent
              onClick={(event) => {
                if (item.dashboardName === "") {
                  event.stopPropagation();
                  navigate(
                    `/${getUserDetailsFromCookies()?.identifier}/projects/createdashboard`
                  );
                }
              }}
              style={{
                display: "flex",
                flex: 1,
                flexDirection: "column",
                padding: 5,
              
              }}
            >
              <div
                style={{
                  display: "flex",
                  flex: 0.1,
                  justifyContent: "flex-starct",
                  alignItems: "flex-start",
                 
                  
                }}
              >
                <Typography
                  className={
                    item.dashboardName === ""
                      ? clsx(
                          classes.dashBoardListTopLabel,
                          classes.moduleTitle
                        )
                      : clsx(classes.dashBoardListTopLabel)
                  }
                 
                >
                  {item.dashboardName ? item.dashboardName : "Add New "}
                </Typography>
              </div>
              <div
                style={{
                  display: "flex",
                  flex: 1,
                  justifyContent: "center",
                  alignItems: "center",
                  overflowX: "hidden",
                 padding:"12px 20px 10px",
                 
                }}
              >
                {item.dashboardName === "" && (
                  <img
                    style={{
                      height: "30px",
                      width: "30px",
                   
                    }}
                    src={Images.add_img}
                  />
                )}
              </div>
            </CardContent>
          </Card>
        </Button>
      </Grid>
    </Grid>
  )
))}
      </Grid>
    </div>
  );
};

export default DashboardList;
