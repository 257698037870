import { TextField, styled, makeStyles } from "../../../app.module";

import { Fonts, Colors } from "../../constants";
export const CssTextField = styled(TextField)({
  "& label.Mui-focused": {
    color: "#000",
    borderRadius: "10px",
  },
  "& .MuiInput-underline:after": {
    borderBottomColor: "red",
    borderRadius: "10px",
  },

  "& .MuiOutlinedInput-root": {
    "& fieldset": {
      borderColor: "#F9F9F9",
      borderRadius: "10px",
    },
    "&:hover fieldset": {
      border: `1px solid ${Colors?.main}`,
      borderRadius: "10px",
    },
    "&.Mui-focused fieldset": {
      border: `1px solid ${Colors?.main}`,
      borderRadius: "10px",
    },
  },
});

export const useStyles = makeStyles()((theme?: any) => {
  theme.shape.borderRadius = 10;
  theme.typography.h6.fontSize = 3;
  return {
    root: {
      backgroundColor: "#F8FAFF",
    },
    labelstyle: {
      color: "lightgray",
      marginTop: "10px",
      "&.Mui-focused": {
        color: "orange",
      },
    },
    asterix:{
      color:"red"
    },
    margin: {
      margin: theme.spacing(1),
      color: "red",
    },
    input: {
      backgroundColor: "#F8FAFF",
      fontFamily: Fonts.NunitoSans.regular,
      fontStyle: "normal",
      fontWeight: "400",
      fontSize: "18px",
      lineHeight: "21px",
      borderRadius: "10px", // border before focusing the text field
      height: "auto",
      color: "#000000",
      "&::placeholder": {
        color: "#ADADAD",
      },
    },
    countryInput: {
      "& .MuiOutlinedInput-root": {
        "& fieldset": {
          borderColor: "#F9F9F9",
          borderRadius: "10px",
        },
        "&:hover fieldset": {
          border: `1px solid #f9f9f9`,
          borderRadius: "10px",
        },
        "&.Mui-focused fieldset": {
          border: `1px solid #f9f9f9`,
          borderRadius: "10px",
        },
      },
    },
    flagInput: {
      backgroundColor: "#F8FAFF",
      fontFamily: Fonts.NunitoSans.regular + ", Twemoji Country Flags, Segoe UI Emoji, Apple Color Emoji, Segoe UI Symbol ",
      fontStyle: "normal",
      fontWeight: "400",
      fontSize: "18px",
      lineHeight: "21px",
      borderRadius: "10px", // border before focusing the text field
      height: "auto",
      color: "#000000",
      "&::placeholder": {
        color: "#ADADAD",
      },
    },

    labelStyle: {
      fontFamily: Fonts.NunitoSans.regular,
      fontStyle: "normal",
      fontWeight: 700,
      fontSize: "18px",
      lineHeight: "21px",
      color: "#000",
      marginBottom: "10px",
    },
    dropdownErrorStyle: {
      fontFamily: Fonts.NunitoSans.regular,
      fontStyle: "normal",
      fontWeight: 400,
      fontSize: "12px",
      lineHeight: "19px",
      color: Colors?.palette.error.main,
      marginLeft: "15px",
      marginTop: "3px",
      display: "flex",
    },
    dropdownStyle: {
      backgroundColor: "#F8FAFF",
      border: "1px solid #f9f9f9",
      borderRadius: "10px",
      height: "53px",
      justifyContent: "center",
      alignItems: "center",
      display: "flex",
      flex: 1,
    },
    dropdownBorder: {
      border: `1px solid ${Colors?.palette.error.main}`,
    },
    dropdownFlex: { flex: 0.3 },
    autoCompleteStyle: {
      backgroundColor: "#F8FAFF",
      border: "1px solid #f9f9f9",
      borderRadius: "10px",
      height: "53px",
      justifyContent: "center",
      alignItems: "center",
      display: "flex",
      flex: 1,
    },
    autoErrorCompleteStyle: {
      backgroundColor: "#F8FAFF",
      border: `1px solid ${Colors?.palette.error.main}`,
      borderRadius: "10px",
      height: "53px",
      justifyContent: "center",
      alignItems: "center",
      display: "flex",
      flex: 1,
    },
    grayedOutButton :{
      backgroundColor:" #ccc",/* Gray background color */ /* Gray background color */
      color: "#888", /* Gray text color */
      cursor: "notAllowed" /* Change cursor to 'not-allowed' */
    },
    cicularContainer: {
      color: Colors?.main,
      zIndex: parseInt(`${Colors?.zIndex?.drawer}+${1}`),
    },
    cicularView: {
      color: Colors?.main,
    },
    btnLabelStyle: {
      fontFamily: Fonts.NunitoSans.regular,
      fontStyle: "normal",
      fontWeight: 700,
      fontSize: "18px",
      lineHeight: "21px",
      color: "#000",
    },
   
    CmpDetailsHeading: {
      fontFamily: Fonts.NunitoSans.regular,
      fontStyle: "normal",
      fontWeight: "700",
      fontSize: "35px",

      lineHeight: "41px",
      color: "#000000",
      marginBottom: "20px",
    },
    twoFieldsContainer: {
      display: "flex",
      flex: 1,
      justifyContent: "space-between",
      marginBottom: "10px",
    },
    btnConfirmContainer: {
      display: "flex",
      flex: 1,
      justifyContent: "space-between",
    },
    textFiledLabelContainer: {
      display: "flex",
      marginRight: "20px",
      flex: 1,
      flexDirection: "column",
    },
    textFiledLabelContainerRight: {
      display: "flex",
      flex: 1,
      flexDirection: "column",
    },
    companyHeaderCodeStyle: {
      height: "70px",
      width: "100px",
    },
    select: {
      backgroundColor: "#F8FAFF",
      fontFamily: Fonts.NunitoSans.regular,
      fontStyle: "normal",
      fontWeight: "400",
      fontSize: "18px",
      lineHeight: "21px",
      borderRadius: "10px",
      height: "53px",

      ".MuiOutlinedInput-notchedOutline": {
        border: "1px solid #f9f9f9",
      },
      "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
        border: `1px solid ${Colors?.main}`,
      },
      "&:hover .MuiOutlinedInput-notchedOutline": {
        border: `1px solid ${Colors?.main}`,
      },
    },
    selectFlex: {
      flex: 2,
    },
    menuitem: {
      fontFamily: Fonts.NunitoSans.regular,
      fontStyle: "normal",
      fontWeight: "400",
      fontSize: "18px",
      lineHeight: "21px",
      color: "#000",
    },
    menuitemhidden: {
      display: "none",
      fontFamily: Fonts.NunitoSans.regular,
      fontStyle: "normal",
      fontWeight: "400",
      fontSize: "18px",
      lineHeight: "21px",
      color: "#ADADAD",
    },
    selectdisabled: {
      fontFamily: Fonts.NunitoSans.regular,
      fontStyle: "normal",
      fontWeight: "400",
      fontSize: "18px",
      lineHeight: "21px",
      color: "#ADADAD",
    },
    
  };
});
