import { LogLevel } from "@azure/msal-browser";
/**
 * Enter here the user flows and custom policies for your B2C application
 * To learn more about user flows, visit: https://docs.microsoft.com/en-us/azure/active-directory-b2c/user-flow-overview
 * To learn more about custom policies, visit: https://docs.microsoft.com/en-us/azure/active-directory-b2c/custom-policy-overview
 */

export const b2cPolicies = {
  names: {
    signUpSignIn: process.env.REACT_APP_B2C_SIGNUP_SIGNIN
      ? process.env.REACT_APP_B2C_SIGNUP_SIGNIN
      : "",
    forgotPassword: process.env.REACT_APP_B2C_SIGNUP_SIGNIN,
    editProfile: "B2C_1_edit_profile_v2",
  },
  authorities: {
    signUpSignIn: {
      authority:
        process.env.REACT_APP_B2C_AUTHORITY_LINK as string,
    },
    signUp: {
      authority:
      process.env.REACT_APP_SIGNUP_B2C_AUTHORITY_LINK as string,
    },
    forgotPassword: {
      authority:
      process.env.REACT_APP_FORGOT_PASSWORD_AUTHORITY_LINK as string,
    },
    editProfile: {
      authority:
        "https://fabrikamb2c.b2clogin.com/fabrikamb2c.onmicrosoft.com/b2c_1_edit_profile_v2",
    },
  },
  authorityDomain: process.env.REACT_APP_B2C_AUTHORITY_DOMAIN as string,
};
/**
 * Configuration object to be passed to MSAL instance on creation.
 * For a full list of MSAL.js configuration parameters, visit:
 * https://github.com/AzureAD/microsoft-authentication-library-for-js/blob/dev/lib/msal-browser/docs/configuration.md
 */
export const msalConfig = {
  auth: {
    clientId: process.env.REACT_APP_B2C_CLIENT_ID
      ? process.env.REACT_APP_B2C_CLIENT_ID
      : "", // This is the ONLY mandatory field that you need to supply.
    authority: b2cPolicies.authorities.signUpSignIn.authority, // Choose SUSI as your default authority.
    knownAuthorities: [b2cPolicies.authorityDomain], // Mark your B2C tenant's domain as trusted.
    redirectUri: process.env.REACT_APP_B2C_REDIRECT_URI
      ? process.env.REACT_APP_B2C_REDIRECT_URI
      : "", // You must register this URI on Azure Portal/App Registration. Defaults to window.location.origin
    postLogoutRedirectUri: "/", // Indicates the page to navigate after logout.
    navigateToLoginRequestUrl: true, // If "true", will navigate back to the original request location before processing the auth code response.
  },
  cache: {
    cacheLocation: "sessionStorage", // Configures cache location. "sessionStorage" is more secure, but "localStorage" gives you SSO between tabs.
    storeAuthStateInCookie: false, // Set this to "true" if you are having issues on IE11 or Edge
  },
  // system: {
  //   loggerOptions: {
  //     loggerCallback: (level: any, message: any, containsPii: any) => {
  //       if (containsPii) {
  //         return;
  //       }
  //       switch (level) {
  //         case LogLevel.Error:
  //           console.error(message);
  //           return;
  //         case LogLevel.Info:
  //           console.info(message);
  //           return;
  //         case LogLevel.Verbose:
  //           console.debug(message);
  //           return;
  //         case LogLevel.Warning:
  //           console.warn(message);
  //           return;
  //         default:
  //           return;
  //       }
  //     },
  //   },
  // },
};

/**
 * Add here the endpoints and scopes when obtaining an access token for protected web APIs. For more information, see:
 * https://github.com/AzureAD/microsoft-authentication-library-for-js/blob/dev/lib/msal-browser/docs/resources-and-scopes.md
 */
export const protectedResources = {
  apiTodoList: {
    endpoint: "http://localhost:5000/api/todolist",
    scopes: {
      read: [
        process.env.REACT_APP_B2C_SCOPES
          ? process.env.REACT_APP_B2C_SCOPES
          : "",
      ],
      write: [
        process.env.REACT_APP_B2C_SCOPES
          ? process.env.REACT_APP_B2C_SCOPES
          : "",
      ],
    },
  },
};

/**
 * Scopes you add here will be prompted for user consent during sign-in.
 * By default, MSAL.js will add OIDC scopes (openid, profile, email) to any login request.
 * For more information about OIDC scopes, visit:
 * https://docs.microsoft.com/en-us/azure/active-directory/develop/v2-permissions-and-consent#openid-connect-scopes
 */
export const loginRequest = {
  scopes: [
    ...protectedResources.apiTodoList.scopes.read,
    ...protectedResources.apiTodoList.scopes.write,
  ],
};
