import {
  styled,
  Button,
  TextareaAutosize,
  makeStyles,
  TextField,
} from "../../../app.module";
import { Fonts, Colors } from "../../constants";
export const useUserStyle = makeStyles()((theme?: any) => {
  return {
    parent: {
      height: "87vh",
      overflowX:"hidden",
    
      scrollbar: "none",
      scrollbarWidth: "none", // Firefox
      "-ms-overflow-style": "none", // IE and Edge
      "&::-webkit-scrollbar": {
        width: '0',
        height: '0',
      },
    },
    roles: {
      height: "30vh",
     
    },

    middle:{
      height: "5vh",
      backgroundColor: "#F6F8FF",
    },

    table: {
      height: "50vh",
      overflowX: "hidden",
      scrollbarWidth: "none", 
      "-ms-overflow-style": "none", 
      "&::-webkit-scrollbar": {
        width: "0",
        height: "0",
      },
    },
    btnProjectTestContainer: {
      display: "flex",
      // flex: 1,
      justifyContent: "center",
      marginRight: "3%",
    },
    marginNewPro: {
      marginRight: 0,
      marginTop: "20px",
    },
    btnProjectTestLabelStyle: {
      fontFamily: Fonts.NunitoSans.regular,
      fontStyle: "normal",
      fontWeight: 700,
      fontSize: "14px",
      
        
      lineHeight: "25px",
      background: "#fff", // Changed from backgroundColor to background for consistency
      color: "#000",
      textTransform: "capitalize",
      
      height: "40px",
      width: "120px",
      marginRight: "15px",
      marginBottom: "20px",
      //position: "fixed",
    //  right:"38px",
    
      "&:hover": {
        background: "#fff", // Presuming you want the same background color on hover
      },
      "&:focus": {
        background: "#fff", // Presuming you want the same background color on focus
      },
    },
    disabledIcon: {
      opacity: 0.3,/* or another styling to indicate disabled state */
    },
    btnSendInvite: {
      fontFamily: Fonts.NunitoSans.regular,
      fontStyle: "normal",
      fontWeight: 700,
      fontSize: "14px",
      lineHeight: "25px",
      background: "linear-gradient(161deg, #52A2F3 0%, #165DD0 41.09%, #8085F1 80.49%)",
      color: "#fff",
      textTransform: "capitalize",
      height: "40px",
      width: "120px",
      marginRight: "15px",
      marginBottom: "20px",
      "&:hover": {
        background: "linear-gradient(161deg, #52A2F3 0%, #165DD0 41.09%, #8085F1 80.49%)",
      },
      "&:focus": {
        background: "linear-gradient(161deg, #52A2F3 0%, #165DD0 41.09%, #8085F1 80.49%)",
      },
      "&.Mui-disabled": {
        background: "#ecedf2", // Grey background for the disabled state
        color: "#cccdd2", // Grey text for the disabled state
      },
    },
    
    
    CmpDetailsHeading: {
      fontFamily: Fonts.NunitoSans.regular,
      fontStyle: "normal",
      fontWeight: "700",
      fontSize: "35px",

      lineHeight: "41px",
      color: "#000000",
      marginBottom: "20px",
    },
    twoFieldsContainer: {
      display: "flex",
      flex: 1,
      justifyContent: "space-between",
      marginBottom: "10px",
    },
  textFiledLabelContainer: {
      display: 'flex',
      marginRight: '10px',
      flexBasis: 'calc(50% - 5px)', 
      flexDirection: 'column',
    
    },
  textFiledLabelContainerRight: {
      display: 'flex',
      flexBasis: 'calc(50% - 5px)', 
      flexDirection: 'column',
    },
    loaderContainer: {
      position: 'fixed', // or 'absolute' if you want it to be relative to a container
      top: '50%',
      left: '50%',
      transform: 'translate(-50%, -50%)',
      zIndex: 1000, // Ensure it's above other content
    },
    labelStyle: {
      fontFamily: Fonts.NunitoSans.regular,
      fontStyle: "normal",
      fontWeight: 700,
      fontSize: "18px",
      lineHeight: "21px",
      color: "#000",
      marginBottom: "10px",
    },
    asterix:{
      color:"red"
    },
    input: {
      // #F8FAFF
      backgroundColor: "#fff",
      fontFamily: Fonts.NunitoSans.regular,
      fontStyle: "normal",
      fontWeight: "400",
      fontSize: "18px",
      lineHeight: "21px",
      borderRadius: "10px", // border before focusing the text field
      height: "auto",
      color: "#000000",
      "&::placeholder": {
        color: "#ADADAD",
      },
    },
    selectFlex: {
      flex: 2,
    },
    selectdisabled: {
      fontFamily: Fonts.NunitoSans.regular,
      fontStyle: "normal",
      fontWeight: "400",
      fontSize: "18px",
      lineHeight: "21px",
      color: "#ADADAD",
    },
    select: {
      // #F8FAFF
      backgroundColor: "#fff",
      fontFamily: Fonts.NunitoSans.regular,
      fontStyle: "normal",
      fontWeight: "400",
      fontSize: "18px",
      lineHeight: "21px",
      borderRadius: "10px",
      height: "53px",

      ".MuiOutlinedInput-notchedOutline": {
        border: "1px solid #f9f9f9",
      },
      "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
        border: `1px solid ${Colors?.main}`,
      },
      "&:hover .MuiOutlinedInput-notchedOutline": {
        border: `1px solid ${Colors?.main}`,
      },
    },
    menuitem: {
      fontFamily: Fonts.NunitoSans.regular,
      fontStyle: "normal",
      fontWeight: "400",
      fontSize: "18px",
      lineHeight: "21px",
      color: "#000",
    },
    dialogBox: {
      position: 'fixed',
      top: '50%',
      left: '50%',
      transform: 'translate(-50%, -50%)',
      zIndex: 1000,
      background: 'white',
      padding: '20px',
      borderRadius: '5px',
      boxShadow: '0 2px 10px rgba(0, 0, 0, 0.3)',
      // Add more styles as needed
    },
    menuitemhidden: {
      display: "none",
      fontFamily: Fonts.NunitoSans.regular,
      fontStyle: "normal",
      fontWeight: "400",
      fontSize: "18px",
      lineHeight: "21px",
      color: "#ADADAD",
    },
    dropdownErrorStyle: {
      fontFamily: Fonts.NunitoSans.regular,
      fontStyle: "normal",
      fontWeight: 400,
      fontSize: "12px",
      lineHeight: "19px",
      color: Colors?.palette.error.main,
      marginLeft: "15px",
      marginTop: "3px",
      display: "flex",
    },
    planTopLabel: {
      fontFamily: Fonts.NunitoSans.regular,
      fontStyle: "normal",
      fontWeight: "700",
      fontSize: "20px",
      // lineHeight: "38px",
      color: Colors?.palette.label.main,
      // marginBottom: "20px",
      
    },
    invitationStatusMessage: {
      position: "fixed",
      top: "50%",
      left: "50%",
      transform: "translate(-50%, -50%)",
      backgroundColor:"#4CAF50", /* Green background color */
      color: "white", /* White text color */
      padding: "16px 32px", /* Padding for the message box */
      borderRadius: "4px",
      boxShadow:" 0px 0px 10px 2px rgba(0, 0, 0, 0.2)",
      textAlign: "center",
      zIndex: 999, /* Ensure it appears above other elements */
      opacity: 0, /* Initially invisible */
    
      /* Animation */
      transition: "opacity 0.3s, transform 0.3s",
    },
    
    // invitationStatusMessage.show :{
    //   opacity: 1, /* Make it visible */
    //   transform:" translate(-50%, -50%) scale(1)", /* Apply a scale effect for animation */
    // },
  }  
});
export const StyledProjectButton = styled(Button)({
  backgroundColor: "#fff",
  color: "#fff",
  padding: "6px 12px",
  "&:hover": {
    backgroundColor: "#fff",
  },
});
export const CssTextField = styled(TextField)({
  "& label.Mui-focused": {
    color: "#000",
    borderRadius: "10px",
  },
  "& .MuiInput-underline:after": {
    borderBottomColor: "red",
    borderRadius: "10px",
  },

  "& .MuiOutlinedInput-root": {
    "& fieldset": {
      borderColor: "#F9F9F9",
      borderRadius: "10px",
    },
    "&:hover fieldset": {
      border: `1px solid ${Colors?.main}`,
      borderRadius: "10px",
    },
    "&.Mui-focused fieldset": {
      border: `1px solid ${Colors?.main}`,
      borderRadius: "10px",
    },
  },
});