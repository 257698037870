import {
  useEffect,
  useState,
  Modal,
  InputLabel,
  Select,
  Grid,
  clsx,
} from "../../../../../app.module";
import Button from "@mui/material/Button";
import {
  useDatabaseStyle,
  StyledDatabaseButton,
  CssDatabaseTextField,
} from "./DatabaseStyle";
import { getUserDetailsFromCookies } from "../../../../services";
import {
  getDatabaseTypes,
  getDatabaseProvider,
  updateDatabase,
} from "./DatabaseService";
import MenuItem from "@mui/material/MenuItem";
interface EditDatabaseProps {
  databaseDetails: any; 
  closeDatabaseModal: () => void;
}
type FormValues = {
  database_source_name: {
    value: string;
    error: boolean;
    errorMessage: string;
  };
};
const EditDatabase = (props: any) => {
  const [value, setValue] = useState("none");
  const [databaseType, setDatabaseType] = useState([]);
  const [databaseProvider, setDatabaseProvider] = useState([]);
  const [openDatabase, setOpenDatabase] = useState(false);
  const [isLoadingConnection, setIsLoadingConnection] = useState(false);
  const [showPlaceholder, setShowPlaceholder] = useState(value === "none");
  const [isDatabaseCreated, setIsDatabaseCreated] = useState(false);

  const userRole = getUserDetailsFromCookies().roleName;
  const {classes} = useDatabaseStyle(userRole)();
  const { projectId } = props;
  console.log("props", props);
  const setDatabaseTypeInfo = async () => {
    setDatabaseType(await getDatabaseTypes());
    const databaseProviderData = await getDatabaseProvider();
    setDatabaseProvider(databaseProviderData);
  };
  const [formValues, setFormValues] = useState({
    database_name: {
      value: "",
      error: false,
      errorMessage: "You must enter a database name",
    },
    project_name: {
      value: "",
      error: false,
      errorMessage: "You must enter project name",
    },
    description: {
      value: "",
      error: false,
      errorMessage: "You must enter project description",
    },

    database_type: {
      value: "none",
      id: 0,
      error: false,
      errorMessage: "You must choose your  database type",
    },
    database_provider: {
      value: "none",
      id: 0,
      error: false,
      errorMessage: "You must choose your database provider",
    },

    connection: {
      value: "",
      error: false,
      isTested: false,
      errorMessage: "You must test the connection string",
    },
    host_name: {
      value: "",
      error: false,
      errorMessage: "You must enter host name",
    },
    port_number: {
      value: "",
      error: false,
      errorMessage: "You must enter port number",
    },
    password: {
      value: "",
      error: false,
      errorMessage: "You must enter password",
    },
    username: {
      value: "",
      error: false,
      errorMessage: "You must enter username",
    },
    database_source_name: {
      value: "",
      error: false,
      errorMessage: "You must enter  database source name",
    },
  });
  const [project_resp, setProject] = useState({
    result: {
      connectionString: String,
      description: String,
      name: String,
      tenantId: String,
      id: Number,
      createdBy: Number,
    },
  });
  const handleInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = event.target;
    setFormValues(prevState => ({
      ...prevState,
      [name]: {
        ...prevState[name as keyof FormValues], // Cast name to keyof FormValues
        value: value,
        error: !value,
      },
    }));
  };

  const databaseupdate = async () => {
    let updatedb = {

      name: formValues.database_source_name.value,
      id: props.databaseDetails.id,
      projectId: props.databaseDetails.projectId,
    };
    updateDatabase(updatedb).then((databaseDetails) => {
      console.log("database Details: ", databaseDetails)
      if (!databaseDetails.isError) {
        props.closeDatabaseModal();
        handleCloseDatabase();
      }
      else {
        console.log("", databaseDetails)
      }
    });
  };
  const handleProjectStateChange = (e: any) => {
    const { name, value } = e.target;
    switch (name) {
      case "database_name":
        setFormValues({
          ...formValues,
          ["database_name"]: {
            ...formValues["database_name"],
            value,
            error: false,
            errorMessage: "",
          },
        });
        break;
      case "database_type":
        let db_id: any = databaseType.find((db: any) => db.name === value);

        setFormValues({
          ...formValues,
          ["database_type"]: {
            ...formValues["database_type"],
            value,
            id: db_id.id,
            error: false,
            errorMessage: "",
          },
        });
        break;
      case "database_provider":
        let db_provider_id: any = databaseProvider.find(
          (db: any) => db.name === value
        );
        setFormValues({
          ...formValues,
          ["database_provider"]: {
            ...formValues["database_provider"],
            value,
            id: db_provider_id.id,
            error: false,
            errorMessage: "",
          },
        });
        break;
      case "project_name":
        setFormValues({
          ...formValues,
          ["project_name"]: {
            ...formValues["project_name"],
            value,
            error: false,
            errorMessage: "",
          },
        });
        break;
      case "description":
        setFormValues({
          ...formValues,
          ["description"]: {
            ...formValues["description"],
            value,
            error: false,
            errorMessage: "",
          },
        });
        break;
      case "connection":
        setFormValues({
          ...formValues,
          ["connection"]: {
            ...formValues["connection"],
            value,
            error: false,
            isTested: false,
            errorMessage: "",
          },
        });

        break;
      case "host_name":
        setFormValues({
          ...formValues,
          ["host_name"]: {
            ...formValues["host_name"],
            value,
            error: false,
            errorMessage: "",
          },
        });
        break;
      case "port_number":
        setFormValues({
          ...formValues,
          ["port_number"]: {
            ...formValues["port_number"],
            value,
            error: false,
            errorMessage: "",
          },
        });
        break;
      case "username":
        setFormValues({
          ...formValues,
          ["username"]: {
            ...formValues["username"],
            value,
            error: false,
            errorMessage: "",
          },
        });
        break;
      case "password":
        setFormValues({
          ...formValues,
          ["password"]: {
            ...formValues["password"],
            value,
            error: false,
            errorMessage: "",
          },
        });
        break;
      case "database_source_name":
        setFormValues({
          ...formValues,
          ["database_source_name"]: {
            ...formValues["database_source_name"],
            value,
            error: false,
            errorMessage: "",
          },
        });
        break;
    }
  };


  const handleOpenDatabase = () => setOpenDatabase(true);
  const handleCloseDatabase = () => {
    const { closeDatabaseModal } = props;
    console.log("Handle Close pro", openDatabase);
    setOpenDatabase(false);
    closeDatabaseModal();
  };
  useEffect(() => {
    handleOpenDatabase();
    setDatabaseTypeInfo();
  }, []);


  useEffect(() => {
    console.log("Database Details:", props.databaseDetails);

    setFormValues(currentFormValues => ({
      ...currentFormValues,
      "database_source_name": {
        ...currentFormValues["database_source_name"],
        value: props.databaseDetails?.name || '',
        error: false,
        errorMessage: "",
      },
      "host_name": {
        ...currentFormValues["host_name"],
        value: props.databaseDetails?.hostName || '',
        error: false,
        errorMessage: "",
      },
      "username": {
        ...currentFormValues["username"],
        value: props.databaseDetails?.userName || '',
        error: false,
        errorMessage: "",
      },
      "port_number": {
        ...currentFormValues["port_number"],
        value: props.databaseDetails?.port || '',
        error: false,
        errorMessage: "",
      },
      "database_type": {
        ...currentFormValues["database_type"],
        value: props.databaseDetails?.databaseTypeName || '',
        error: false,
        errorMessage: "",
      },
      "database_name": {
        ...currentFormValues["database_name"],
        value: props.databaseDetails?.databaseName || '',
        error: false,
        errorMessage: "",
      },
      "database_provider": { 
        ...currentFormValues["database_provider"],
        value: props.databaseDetails?.databaseProviderName || '',
        error: false,
        errorMessage: "",
      },
    }));
  }, [props.databaseDetails]);





  useEffect(() => {
    handleOpenDatabase();
  }, [isLoadingConnection]);
  const validateCompanyDetails = (label: {
    project_name?: string;
    description?: string;
    database_type?: string;
    database_name?: string;
    database_provider?: string;
  }) => {
    let validate_pro_data = formValues;
    let isError = false;

    Object.entries(validate_pro_data).forEach(([key, value]) => {
      let cmp_key = key as keyof typeof validate_pro_data;
      if (cmp_key === label?.project_name || cmp_key === label?.description) {
        if (value.value === "" || value.value === "none") {
          validate_pro_data[cmp_key].error = true;
          isError = true;
        } else {
          validate_pro_data[cmp_key].error = false;
        }
      }
      if (
        label.database_name === cmp_key ||
        label.database_provider === cmp_key ||
        label.database_type === cmp_key
      ) {
        if (value.value === "" || value.value === "none") {
          validate_pro_data[cmp_key].error = true;
          isError = true;
          console.log("key value", label?.project_name);
        } else {
          validate_pro_data[cmp_key].error = false;
        }
      }
    });

    setFormValues({ ...validate_pro_data });
    return isError;
  };
  return (
    <Modal
      open={openDatabase}
      onClose={handleCloseDatabase}
      aria-labelledby="simple-modal-title"
      aria-describedby="simple-modal-description"
    >
      <div className={classes.createProjectBoxContainer}>
        <Grid container spacing={1} style={{ justifyContent: "center" }}>
          <Grid
            style={{
              background: "#fff",
              display: "flex",
              flex: 3,
              flexDirection: "column",
              padding: "20px",
              overflowY: "auto",
              maxHeight: "95vh",
              maxWidth: "90%",
              borderRadius: 5,
            }}
            className={classes.DbContainer}
            item
            xs={8}
          >
            <div>
              <div className={classes.twoFieldsContainer}>
                <div className={clsx(classes.textFiledLabelContainer)}>
                  <InputLabel className={classes.projectLabelStyle}>
                    Source name <span className={classes.asterix}>*</span>
                  </InputLabel>
                  <CssDatabaseTextField
                    required
                    fullWidth
                    onChange={handleInputChange}
                    value={formValues.database_source_name.value}
                    name="database_source_name"
                    error={formValues.database_source_name.error}
                    helperText={
                      formValues.database_source_name.error
                        ? formValues.database_source_name.errorMessage
                        : ""
                    }
                    placeholder="Database source name"
                    InputProps={{
                      classes: { input: classes.proInput },
                    }}
                    id="database_source_name-css-standard-input"
                  />
                </div>

                <div className={clsx(classes.textFiledProviderContainer)}>
                  <InputLabel
                    className={classes.projectLabelStyle}
                    id="demo-simple-select-helper-labell"
                  >
                    Database type <span className={classes.asterix}> *</span>
                  </InputLabel>
                  <Select
                    id="demo-simple-select-helper-typee"
                    value={formValues.database_type.value}
                    error={formValues.database_type.error}
                    name="database_type"
                    className={clsx(
                      classes.projectSelect,
                      formValues.database_type.value === "none"
                        ? classes.projectSelectdisabled
                        : null
                    )}
                    onFocus={(e) => setShowPlaceholder(false)}
                    onChange={handleProjectStateChange}
                    onClose={(e) => {
                      setShowPlaceholder(
                        (e.target as HTMLInputElement).value === undefined
                      );
                    }}
                    defaultValue="none"
                    disabled 
                    style={{ cursor: 'default' }} 
                  >
                    <MenuItem
                      className={clsx(
                        classes.projectMenuitem,
                        !showPlaceholder
                          ? classes.projectMenuitemhidden
                          : null
                      )}
                      key="0"
                      disabled
                      value="none"
                    >
                      Database type
                    </MenuItem>
                    {databaseType.map((item: any) => (
                      <MenuItem
                        key={item?.id}
                        className={classes.projectMenuitem}
                        value={item?.name}
                        style={{ cursor: 'default' }}
                      >
                        {item?.name}
                      </MenuItem>
                    ))}
                  </Select>
                  <InputLabel className={classes.prjDropdownErrorStyle}>
                    {formValues.database_type.error
                      ? "Please select database type"
                      : ""}
                  </InputLabel>
                </div>
              </div>
              <div className={classes.twoFieldsContainer}>
                <div className={classes.textFiledLabelContainer}>
                  <InputLabel className={classes.projectLabelStyle}>
                    Host name <span className={classes.asterix}> *</span>
                  </InputLabel>
                  <CssDatabaseTextField
                    fullWidth
                    value={formValues.host_name.value}
                    error={formValues.host_name.error ? true : false}
                    onChange={(e: any) => handleProjectStateChange(e)}
                    name="host_name"
                    placeholder="Host name"
                    InputProps={{
                      classes: { input: classes.proInput },
                      disabled: true,
                      style: { pointerEvents: 'none' }
                    }}
                    id="host-css-standard-input"
                  />
                </div>
                <div className={classes.portNumberContainerRight}>
                  <InputLabel className={classes.projectLabelStyle}>
                    Port number <span className={classes.asterix}> *</span>
                  </InputLabel>
                  <CssDatabaseTextField
                    fullWidth
                    value={formValues.port_number.value}
                    error={formValues.port_number.error ? true : false}
                    onChange={(e: any) => handleProjectStateChange(e)}
                    name="port_number"
                    placeholder="Port number"
                    InputProps={{
                      classes: { input: classes.proInput },
                      disabled: true,
                      style: { pointerEvents: 'none' } 
                    }}
                    id="port-css-standard-input"
                  />
                </div>
              </div>
              <div className={classes.twoFieldsContainer}>
                <div className={clsx(classes.textFiledLabelContainer)}>
                  <InputLabel className={classes.projectLabelStyle}>
                    Database name <span className={classes.asterix}> *</span>
                  </InputLabel>
                  <CssDatabaseTextField
                    required
                    fullWidth
                    onChange={handleProjectStateChange}
                    value={formValues.database_name.value}
                    name="database_name"
                    error={formValues.database_name.error}
                    helperText={
                      formValues.database_name.error
                        ? "Please enter database name"
                        : ""
                    }
                    placeholder="Database name"
                    InputProps={{
                      classes: { input: classes.proInput },
                      disabled: true, 
                      style: { pointerEvents: 'none' } 
                    }}
                    id="custom-css-standard-input"
                  />
                </div>

                <div className={clsx(classes.textFiledLabelContainer)}>
                  <InputLabel
                    className={classes.projectLabelStyle}
                    id="demo-simple-select-helper-label"
                  >
                    Database provider <span className={classes.asterix}> *</span>
                  </InputLabel>
                  <Select
                    id="demo-simple-select-helper-type"
                    value={formValues.database_provider.value}
                    error={formValues.database_provider.error}
                    name="database_provider"
                    className={clsx(
                      classes.projectSelect,
                      formValues.database_provider.value === "none"
                        ? classes.projectSelectdisabled
                        : null
                    )}
                    onChange={handleProjectStateChange}
                    defaultValue="none"
                    disabled 
                    style={{ cursor: 'default' }}
                  >
                    <MenuItem
                      className={clsx(
                        classes.projectMenuitem,
                        !showPlaceholder
                          ? classes.projectMenuitemhidden
                          : null
                      )}
                      key="0"
                      disabled
                      value="none"
                    >
                      Database provider
                    </MenuItem>

                    {databaseProvider.map((item: any) => (
                      <MenuItem
                        key={item?.id}
                        className={classes.projectMenuitem}
                        value={item?.name}
                        style={{ cursor: 'default' }} 
                      >
                        {item?.name}
                      </MenuItem>
                    ))}
                  </Select>
                  <InputLabel className={classes.prjDropdownErrorStyle}>
                    {formValues.database_provider.error
                      ? "Please select database provider"
                      : ""}
                  </InputLabel>
                </div>
              </div>
              <div className={classes.twoFieldsContainer}>
                <div className={classes.textFiledLabelContainer}>
                  <InputLabel className={classes.projectLabelStyle}>
                    Username <span className={classes.asterix}> *</span>
                  </InputLabel>
                  <CssDatabaseTextField
                    fullWidth
                    value={formValues.username.value}
                    error={formValues.username.error ? true : false}
                    onChange={(e: any) => handleProjectStateChange(e)}
                    name="username"
                    placeholder="Username"
                    InputProps={{
                      classes: { input: classes.proInput },
                      disabled: true, 
                      style: { pointerEvents: 'none' }
                    }}
                    id="username-css-standard-input"
                  />
                </div>
                <div className={classes.txtLabelProjectContainerRight}>
                  <InputLabel className={classes.projectLabelStyle}>
                    Password <span className={classes.asterix}> *</span>
                  </InputLabel>
                  <CssDatabaseTextField
                    fullWidth
                    value={formValues.password.value}
                    error={formValues.password.error ? true : false}
                    onChange={(e: any) => handleProjectStateChange(e)}
                    name="password"
                    type="password"
                    placeholder="Password"
                    InputProps={{
                      classes: { input: classes.proInput },
                      disabled: true,
                      style: { pointerEvents: 'none' } 
                    }}
                    id="password-css-standard-input"
                  />
                </div>
              </div>
            </div>
            <div className={clsx(classes.proBtncontainer)}>
              <div
                style={{
                  display: "flex",
                  marginTop: "5px",
                  justifyContent: "center", 
                  flex: 1,
                }}
              >
                <StyledDatabaseButton
                  onClick={() => {
                    handleCloseDatabase();
                  }}
                  variant="contained"
                  className={classes.projectCancelBtnLabelStyle}
                >
                  Cancel
                </StyledDatabaseButton>
                <Button
                  onClick={() => {
                    databaseupdate();

                  }}
                  variant="contained"
                  className={classes.nextProBtn}
                  disabled={!formValues.database_source_name.value} 
                >
                  Update
                </Button>{" "}
              </div>
            </div>

          </Grid>
        </Grid>
      </div>
    </Modal>
  )
}
export default EditDatabase;