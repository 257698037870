import {
  Typography,
  AppStatesContainer,
  useContext,
  useState,
  useEffect,
} from "../../../app.module";
import { useStyle } from "./NavigationPathStyle";
interface MyObject {
  name: string;
  isActive: boolean;
}
const NavigationPath = (props: any) => {
  const { classes } = useStyle();
  const navigation = useContext(AppStatesContainer);
  const [nestedPath, setNestedPath] = useState<MyObject[]>([]);
  useEffect(() => {
    console.log("updatedPath", props);
    const objectArray: MyObject[] = navigation?.pathState;
    const regularArray: MyObject[] = Object.values(objectArray);
    console.log(
      typeof regularArray,
      "navigation?.pathState",
      regularArray,
      props.updatedPath
    );
  });
  useEffect(() => {
    if (props.updatedPath) {
      setNestedPath(props.updatedPath);
    }
  }, [props.updatedPath]);
  return (
    <div
      style={{
        display: "flex",
        flex: 1,
        flexDirection: "row",
        justifyContent: "flex-start",
        alignItems: "center",
        // marginTop: "6px",
        marginLeft: "24px",
        // backgroundColor: "red",
      }}
    >
      {/* {navigation?.pathState?.path.map((item: any, index: number) => {
        <Typography className={classes.dashboardPathText}>
          Project{" > "}
        </Typography>;
      })} */}
      <Typography className={classes.dashboardPathText}>
        {/* Project{" > "} */}
        {/* <Link to="/">Projects{" > "}</Link>
        <Link to="dashboard">Modules{" > "}</Link>
        <Link to="user">User</Link> */}
      </Typography>
      <Typography
        style={{
          color: "#0B55CC",
          marginLeft: "4px",
        }}
        className={classes.dashboardPathText}
      >
        {/* <Link to="/modules">Modules</Link>
        <Link to="/user">User</Link> */}
        {/* Dashboard */}
      </Typography>
      {/* <Typography className={classes.dashboardPathText}>
        {JSON.stringify(nestedPath)}
      </Typography> */}
    </div>
  );
};

export default NavigationPath;
