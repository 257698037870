//@ts-nocheck
import { React, useState, useEffect } from "../../../../app.module";
import { ResponsiveBump } from "@nivo/bump";
import { useStyle } from "./DashboardBarChartStyle";
import { Colors, Images } from "../../../constants";
interface AxisBottom {
  orient: any;
  tickSize: any;
  tickPadding: any;
  tickRotation: any;
  legend: any;
  legendPosition: any;
  legendOffset: any;
}

const BumpChartForDashboard = React.memo((props: any) => {
  const [bumpJson, setBumpJson] = useState<any>([]);
  const [isLoading, setIsLoading] = useState(true);
  const [activeTab, setActiveTab] = React.useState(0);
  const { classes } = useStyle({ isYaxis: false, isXaxis: true, activeTab });
  const [shouldRotateLabels, setShouldRotateLabels] = useState(false);
  const [bottomLegend, setBottomLegend] = useState("");
  const [leftLegend, setLeftLegend] = useState("");

  useEffect(() => {
    let { data } = props.data;
    console.log("Bump Bump CHART", data[0]?.data?.length);
    setBumpJson(data);

    const maxLabelLength = Math.max(...data.map((item: any) => item.id.length));
    const threshold = 5; // for example, rotate labels if they are longer than 10 characters
    if (maxLabelLength > threshold) {
      setShouldRotateLabels(true);
    }
  }, []);

  useEffect(() => {
    let { data, axis_bottom_legend, axis_left_legend } = props.data;
    
    // Assuming the legends are directly under props.data
    const bottomLegend = axis_bottom_legend;
    const leftLegend = axis_left_legend;
  
    setBumpJson(data); // Set the bumpJson as before
    setBottomLegend(bottomLegend);
    setLeftLegend(leftLegend);
  }, [props.data]);


  const chartWidth = bumpJson?.data?.length >= 10
  ? `${50 * bumpJson?.data?.length}px`
  : "500%";
const minWidth = "300px";


const truncateLabel = (label: string) => {
  const maxLength = 10; // Maximum number of characters to show before truncating
  if (label.length > maxLength) {
    return `${label.substring(0, maxLength)}...`; // Truncate the label
  }
  return label;
};
const renderTick = (tick: any) => {
  const { x, y, value } = tick;
  const isRotated = shouldRotateLabels;

  return (
    <g transform={`translate(${x},${y})`}>
      <text
        textAnchor={isRotated ? "end" : "middle"}
        dominantBaseline="middle"
        style={{
          fill: "#333333",
          fontSize: "12px",
        }}
        transform={`translate(${isRotated ? -5 : 0},${isRotated ? 10 : 0}) rotate(${isRotated ? -45 : 0})`}
      >
        {truncateLabel(value)}
        <title>{value}</title> {/* SVG title tag for tooltip */}
      </text>
    </g>
  );
};

// Custom left axis tick component
// Define a type for the tick parameter
// Define a type for the tick parameter
type Tick = {
  value: string | number;
  x: number;
  y: number;
};

// Custom left axis tick component
const CustomLeftTick = (tick: Tick) => {
  // Convert the value to string in case it's a number
  const labelValue = tick.value.toString();
  const MAX_LENGTH = 5; // Maximum number of characters before truncating
  const label = labelValue.length > MAX_LENGTH ? `${labelValue.substring(0, MAX_LENGTH)}...` : labelValue;

  return (
    <g transform={`translate(${tick.x - 10},${tick.y})`}>
      <text
        textAnchor="end"
        dominantBaseline="middle"
        style={{
          fill: '#333',
          fontSize: '12px',
        }}
      >
        <title>{labelValue}</title> {/* Tooltip for the full label */}
        {label}
      </text>
    </g>
  );
};

useEffect(() => {
  // Inject custom scrollbar styles
  const style = document.createElement('style');
  style.type = 'text/css';
  style.innerHTML = `
    .BumpChartScroll::-webkit-scrollbar {
      width: 12px;
      height: 12px;
      background-color: transparent; /* make scrollbar background transparent */
    }
    .BumpChartScroll:hover::-webkit-scrollbar-thumb {
      border-radius: 10px;
      -webkit-box-shadow: inset 0 0 6px rgba(0,0,0,.3);
      background-color: #D3D3D3; /* only show scrollbar thumb on hover */
    }
  `;
  document.head.appendChild(style);

  // Ensure that we remove the style on cleanup
  return () => {
    document.head.removeChild(style);
  };
}, []);

const BumpChartScrollClassName = "BumpChartScroll";

const chartMargin = {
  top: 30,
  right: 110,
  bottom: shouldRotateLabels ? 80 : 70, // Increase bottom margin if labels are rotated
  left: 80,
};

  return (
    <div
    key="BumpChartWrapper"
  
    style={{
        height: "100%", 
        width: "100%", 
        overflow: "hidden", 
        overflowY:"hidden",
      }}
     
   
  >
   
      <div
  className={BumpChartScrollClassName}
  key="BumpChart01"
  style={{
    height: "100%",
    width: "100%", // Ensure the chart stretches to the full width and height of its container
    overflowX: "hidden", // Hide horizontal scrollbar by default
  }}
  onMouseOver={(e) => {
    // Add scrollbars when hovered
    e.currentTarget.style.overflowX = "auto";
    e.currentTarget.style.overflowY = "auto";
  }}
  onMouseOut={(e) => {
    // Remove scrollbars when not hovered
    e.currentTarget.style.overflowX = "hidden";
    e.currentTarget.style.overflowY = "hidden";
  }}
>
      {props?.pageError !== "" ? (
        <div
          key="TAB101"
          style={{
            display: "flex",
            height: "87vh",
            width: "100%",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <img
            style={{
              height: "auto",
              width: "300px",
            }}
            src={Images.no_data_img}
          />
        </div>
      ) : (
    
        <div
        key="CHART1005"
        style={{
      

        width: chartWidth, // Use the calculated chart width
    minWidth: minWidth, // Ensure the chart has a minimum width
    height: "100%", //40vh
        
        overflowY:"hidden",
     


        }}
      >
          {props.name}
          <ResponsiveBump
            data={bumpJson}
            colors={{ scheme: "spectral" }}
            lineWidth={3}
            activeLineWidth={6}
            inactiveLineWidth={3}
            inactiveOpacity={0.15}
            pointSize={10}
            activePointSize={16}
            inactivePointSize={0}
            pointColor={{ theme: "background" }}
            pointBorderWidth={3}
            activePointBorderWidth={3}
            pointBorderColor={{ from: "serie.color" }}
           axisTop={null}
            axisBottom={{
              tickSize: 5,
              tickPadding: 5,
              tickRotation: shouldRotateLabels ? -45 : 0,
              legend: bottomLegend,
              legendPosition: "middle",
              // legendOffset: 32,
              legendOffset: shouldRotateLabels ? 70 : 60,
              renderTick: renderTick,
            }}
            axisLeft={{
              tickSize: 5,
              tickPadding: 5,
              tickRotation: 0,
              legend: leftLegend,
              legendPosition: "middle",
              legendOffset: shouldRotateLabels ? -75 : 60,

              renderTick: CustomLeftTick,
            }}
            // margin={{ top: 40, right: 100, bottom: 40, left: 60 }}
            margin={chartMargin}
            axisRight={null}
          />
        </div>
      )}
    </div>
    </div>
  );
});

export default BumpChartForDashboard;
