import { useState, useRef, useEffect } from "react";
import { Images, Fonts } from "../../../constants";
import axios from "axios";
import {
  getUserAccessTokenFromCookies,
  getUserDetailsFromCookies,
} from "../../../services/cookie/cookies";

import {
  Dialog,
  Typography,
  Button,
  Box,
  InputLabel,
  CircularProgress,
  DialogContent,
  DialogContentText,
} from "../../../../app.module";
import { getdatasourcetype } from "./DatasourceService";
import {
  StyledDatasourceButton,
  useDatasourceStyle,
  CssProjectTextField,
} from "./DatasourceStyle";
interface Datasource {
  id: number;
  name: string;
  description: string;
  isactive: boolean;
  createdBy: number;
  createdDate: string;
  modifiedBy: number;
  modifiedDate: string;
}
const DataSource = ({
  onDatabaseSelection,
  onCsvSelection,
  onNext,
  onCancel,
  onFinish,
}: {
  onDatabaseSelection: (isDatabaseSelected: boolean) => void;
  onCsvSelection: (isCsvSelected: boolean) => void;
  onNext: () => void;
  onCancel: () => void;
  onFinish: () => void;
}) => {
  const [showDropdown, setShowDropdown] = useState(false);
  const [isDialogOpen, setIsDialogOpen] = useState(false);
  const [dialogState, setDialogState] = useState<
    "loading" | "success" | "error"
  >("loading");
  const [dialogMessage, setDialogMessage] = useState("");
  const fileInputRef = useRef<HTMLInputElement>(null);
  const [file, setFile] = useState(null);
  const [dataSourceName, setDataSourceName] = useState("");
  const [fileName, setFileName] = useState("");
  const [datasourceType, setDatasourceType] = useState<Datasource[]>([]);
  const [selectedImage, setSelectedImage] = useState<"csv" | "db" | null>(null);
  const [successMessage, setSuccessMessage] = useState("");
  const [errorMessage, setErrorMessage] = useState("");
  const [isCSVHovered, setIsCSVHovered] = useState(false);
  const [isDBHovered, setIsDBHovered] = useState(false);
  const { classes } = useDatasourceStyle()();
  const [isTextFieldEnabled, setIsTextFieldEnabled] = useState(false);
  const [isUploadButtonEnabled, setIsUploadButtonEnabled] = useState(false);
  const [isNextButtonEnabled, setIsNextButtonEnabled] = useState(false);
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    const fetchDatasources = async () => {
      setIsLoading(true);
      setIsDialogOpen(true);
      setDialogState("loading");
      setDialogMessage("Loading Datasources");
      try {
        const resp = await getdatasourcetype();
        const sortedData = resp.sort((a: any, b: any) => {
          if (a.name === "CSV") return -1;
          if (b.name === "CSV") return 1;
          return 0;
        });
        setDatasourceType(sortedData);
        setIsDialogOpen(false);
        setIsLoading(false);
      } catch (error) {
        console.error("Error fetching datasources:", error);
        setDialogState("error");
        setDialogMessage(`${error}`);
      } 
    };

    fetchDatasources();
  }, []);
  const handleAddClick = () => {
    if (selectedImage === "csv") {
      onFinish();
    } else {
      onNext();
    }
  };
  const handleCSVClick = () => {
    setSelectedImage("csv");
    setShowDropdown(true);
    onDatabaseSelection(false);
    onCsvSelection(true);
    resetStates();
  };

  const handleDatabaseClick = () => {
    resetStates();
    setSelectedImage("db");
    setShowDropdown(false);
    setSuccessMessage("");
    onDatabaseSelection(true);
    onCsvSelection(false);
    setIsNextButtonEnabled(true);
  };

  const resetStates = () => {
    setFile(null);
    setFileName("");
    setDataSourceName("");
    setIsTextFieldEnabled(false);
    setIsUploadButtonEnabled(false);
    setIsNextButtonEnabled(false);
    setSuccessMessage("");
    setErrorMessage("");
  };

  const handleFileChange = (event: any) => {
    const uploadedFile = event.target.files ? event.target.files[0] : null;
    if (uploadedFile) {
      const fileExtension = uploadedFile.name.split(".").pop()?.toLowerCase();
      if (["csv", "xlsx", "xls"].includes(fileExtension)) {
        setFile(uploadedFile);
        setFileName(uploadedFile.name);
        setIsTextFieldEnabled(true);
        setSuccessMessage("");
      } else {
        setIsDialogOpen(true);
      }
    }
  };

  const handleFileUpload = async () => {
    setIsUploadButtonEnabled(false);
    setIsDialogOpen(true);
    setDialogState("loading");
    setDialogMessage("Uploading file...");
    if (file && dataSourceName) {
      const userDetails = getUserDetailsFromCookies();
      const formData = new FormData();
      formData.append("file", file);
      formData.append("ProjectId", userDetails.projectId);
      formData.append("Name", dataSourceName);
      formData.append("UserId", userDetails.id);
      formData.append("FileType", "2");

      const config = {
        headers: {
          "Content-Type": "multipart/form-data",
          Authorization: "Bearer " + getUserAccessTokenFromCookies(),
        },
      };
      try {
        const response = await axios.post(
          `https://wingsbi-dev-apigateway-app.azurewebsites.net/${
            getUserDetailsFromCookies().identifier
          }/file_upload`,
          formData,
          config
        );
        setDialogState("success");
        setDialogMessage("File uploaded successfully!");
        setIsNextButtonEnabled(true);
        console.log("fileresponse", response);

        setTimeout(() => {
          setIsDialogOpen(false);
        }, 5000);
      } catch (error) {
        setDialogState("error");
        setDialogMessage("Failed to upload file.");
        console.error("Error uploading file:", error);

        setTimeout(() => {
          setIsDialogOpen(false);
        }, 5000);
      }
    }
  };

  useEffect(() => {
    if (file && dataSourceName) {
      setIsUploadButtonEnabled(true);
    } else {
      setIsUploadButtonEnabled(false);
    }
  }, [file, dataSourceName]);

  return (
    <div className={classes.containerStyle}>
      {!isLoading && (
        <div className={classes.imageContainerStyle}>
          {datasourceType.map((source) => (
            <Box
              key={source.id}
              sx={{
                position: "relative",
                bgcolor: "rgb(242,245,253)",
                boxShadow: "0 5px 10px 0 rgba(31, 38, 135, 0.37)",
                borderRadius: "10px",
                mt: 2,
                mr: 10,
                mb: 7,
                ml: 1,
                width: "15vw",
                height: "22vh",
                transform:
                  (source.name === "CSV" && isCSVHovered) ||
                  (source.name === "DATABASE" && isDBHovered) ||
                  selectedImage === source.name.toLowerCase()
                    ? "scale(1.1)"
                    : "scale(1)",
                transition: "transform 0.3s ease-in-out",
                borderWidth: "2px",
                borderStyle: "solid",
                borderImageSlice: 1,
              }}
              onMouseEnter={() =>
                source.name === "CSV"
                  ? setIsCSVHovered(true)
                  : setIsDBHovered(true)
              }
              onMouseLeave={() =>
                source.name === "CSV"
                  ? setIsCSVHovered(false)
                  : setIsDBHovered(false)
              }
              onClick={() =>
                source?.name === "CSV"
                  ? handleCSVClick()
                  : handleDatabaseClick()
              }
            >
              <div className={classes.imageWrapperStyle}>
                <img
                  src={source.name === "CSV" ? Images.Csv : Images.Database}
                  alt={source.name}
                  className={
                    source.name === "CSV"
                      ? classes.csvImageStyle
                      : classes.dbImageStyle
                  }
                />
                <p className={classes.labelStyle}>
                  {source.name === "CSV" ? "File Upload" : source.name}
                </p>
              </div>
            </Box>
          ))}
        </div>
      )}

      {showDropdown && (
        <div className={classes.dropdownStyle}>
          <input
            type="file"
            ref={fileInputRef}
            onChange={handleFileChange}
            style={{ display: "none" }}
          />
          <div
            onClick={() => fileInputRef.current?.click()}
            style={{
              display: "flex",
              alignItems: "center",
              marginBottom: "20px",
            }}
          >
            <Button
              component="span"
              variant="contained"
              startIcon={
                <img
                  src={Images.Upload}
                  alt="Upload Icon"
                  style={{ width: "18px", height: "18px", marginRight: "1px" 
                }}
                />
              }
              disabled={successMessage !== ""}
              className={classes.addFileButton}
            >
              Add File
            </Button>
            {fileName && (
              <p
                style={{
                  marginLeft: "10px",
                  fontFamily: Fonts.NunitoSans.regular,
                }}
              >
                {fileName}
              </p>
            )}
          </div>
          <InputLabel style={{ fontWeight: 700, marginBottom: "10px" }}>
            Datasource Name <span style={{ color: "red" }}>*</span>
          </InputLabel>
          <CssProjectTextField
            id="datasource-name"
            variant="outlined"
            size="small"
            value={dataSourceName}
            onChange={(e) => setDataSourceName(e.target.value)}
            placeholder="Datasource Name"
            disabled={!isTextFieldEnabled}
          />
        </div>
      )}

      <div className={classes.proBtncontainer}>
        <Button
          onClick={handleFileUpload}
          disabled={!isUploadButtonEnabled}
          className={classes.uploadButton}
        >
          Upload
        </Button>

        <StyledDatasourceButton
          onClick={() => {
            onCancel();
          }}
          variant="contained"
          className={classes.projectCancelBtnLabelStyle}
        >
          Cancel
        </StyledDatasourceButton>

        <Button
          onClick={() => {
            if (selectedImage === "csv") {
              handleAddClick(); 
            } else {
              onNext(); 
            }
          }}
          variant="contained"
          className={classes.nextProBtn}
          disabled={!isNextButtonEnabled}
        >
          Add
        </Button>
      </div>

      {successMessage && (
        <Typography
          variant="body1"
          style={{ color: "green", marginTop: "10px" }}
        >
          {successMessage}
        </Typography>
      )}
      {errorMessage && (
        <Typography variant="body1" style={{ color: "red", marginTop: "10px" }}>
          {errorMessage}
        </Typography>
      )}
      <Dialog
        open={isDialogOpen}
        onClose={() => setIsDialogOpen(false)}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        PaperProps={{
          style: {
            width: "650px",
            height: "120px",
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
            justifyContent: "flex-start",
            backgroundColor:
              dialogState === "loading"
                ? "#E3F2FD"
                : dialogState === "success"
                ? "#E8F5E9"
                : dialogState === "error"
                ? "#FBCED6"
                : "#FFFFFF",
            borderRadius: "10px",
            border:
              dialogState === "loading"
                ? "1px solid #2196F3"
                : dialogState === "success"
                ? "1px solid #4CAF50"
                : dialogState === "error"
                ? "1px solid #FC6286"
                : "1px solid #000000",
            overflow: "hidden",
          },
        }}
      >
        <DialogContent>
          <Box display="flex" alignItems="center">
            {dialogState === "loading" && (
              <CircularProgress
                style={{ color: "#2196F3", marginRight: "20px" }}
              />
            )}
            {dialogState === "success" && (
              <img
                src={Images?.success}
                alt="Success"
                style={{ width: "24px", height: "24px", marginRight: "20px" }}
              />
            )}
            {dialogState === "error" && (
              <img
                src={Images?.cancel}
                alt="Error"
                style={{ width: "24px", height: "24px", marginRight: "20px" }}
              />
            )}
            <DialogContentText id="alert-dialog-description">
              {dialogMessage}
            </DialogContentText>
          </Box>
        </DialogContent>
      </Dialog>
    </div>
  );
};

export default DataSource;
