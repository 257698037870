import {
  React,
  useState,
  Box,
  Button,
  Modal,
  Fade,
  Grid,
  Typography,
  Backdrop,
  styled,
  Paper,
  GroupAdd,
  useEffect,
} from "../../../app.module";
import { useStyles, StyledButton } from "./GuidedStyle";
import BubbleChat from "./BubbleChat";
import { InputLabel } from "@mui/material";
import { Images } from "../../constants";

const Item = styled(Paper)(({ theme }) => ({
  backgroundColor: theme.palette.mode === "dark" ? "#1A2027" : "#fff",
  ...theme.typography.body2,
  padding: theme.spacing(1),
  textAlign: "center",
  color: theme.palette.text.secondary,
}));
const Guided = (props: any) => {
  const { classes } = useStyles();
  const [open, setOpen] = useState(true);
  const [guide, setGuide] = useState("guide1");
  const handleOpen = () => setOpen(true);
  const handleClose = () => {
    const { handleCloseProjectModal } = props;
    setOpen(false);
  };

  const guided_tour: any = [
    { text: "Hello, how are you?", sender: "Alice", time: "10:00 AM" },
    { text: "I am doing well, thanks!", sender: "Bob", time: "10:01 AM" },
    { text: "What about you?", sender: "Bob", time: "10:02 AM" },
    { text: "I am doing great!", sender: "Alice", time: "10:03 AM" },
  ];
  useEffect(() => {
    console.log("aslfdaksfdas", props.name);

    //setGuide(props.name);
  }, [guide]);
  useEffect(() => {
    handleOpen();
  }, []);
  const { handleCloseProjectModal } = props;
  return (
    <div>
      <div>
        {/* <Button onClick={handleOpen}>Open modal</Button> */}
        <Modal
          style={{ background: "rgba(0, 0, 0, 0.6)" }}
          aria-labelledby="transition-modal-title"
          aria-describedby="transition-modal-description"
          open={open}
          // onClose={handleClose}
          closeAfterTransition
          slots={{ backdrop: Backdrop }}
          slotProps={{
            backdrop: {
              timeout: 500,
            },
          }}
        >
          {guide === "guide1" ? (
            <Fade in={open}>
              <div>
                <Box className={classes.boxContainer}>
                  <Grid container columns={1}>
                    <Grid
                      item
                      xs={8}
                      style={{
                        display: "flex",
                        flex: 1,
                        justifyContent: "flex-end",
                      }}
                    >
                      <Item className={classes.btnGuideNewProjectStyle}>
                        <GroupAdd className={classes.labelGuideLabelStyle} />
                        <InputLabel className={classes.labelGuideLabelStyle}>
                          New Project
                        </InputLabel>
                      </Item>
                    </Grid>
                  </Grid>
                  <Box>
                    <Grid className={classes.grideGuideContainer}>
                      <div className={classes.rightAngleTriangle} />
                    </Grid>
                  </Box>
                  <Grid item className={classes.grideGuideContainer}>
                    <Item
                      style={{
                        background: "#D1DFF6",
                        width: "260px",
                        borderTopRightRadius: "0px",
                      }}
                    >
                      <Typography className={classes.guideNoteStyle}>
                        Click on new project to add 'Project" anytime to your
                        account
                      </Typography>
                    </Item>
                  </Grid>
                </Box>
                <Box className={classes.boxNoteContainer}>
                  <div className={classes.btnGuideContainer}>
                    <StyledButton
                      onClick={() => {
                        //  createOrganization()
                        //props.handleNext();
                        setGuide("guide2");
                      }}
                      style={
                        {
                          //background: "#fff",
                        }
                      }
                      className={classes.btnGuideLabelStyle}
                      variant="contained"
                      size="medium"
                    >
                      Next{" >>"}
                    </StyledButton>
                  </div>
                </Box>
              </div>
            </Fade>
          ) : guide === "guide2" ? (
            <Fade in={open}>
              <div>
                <Box className={classes.boxContainer02}>
                  <Grid container columns={1}>
                    <Grid
                      item
                      xs={8}
                      style={{
                        display: "flex",
                        flex: 1,
                        justifyContent: "center",
                      }}
                    >
                      <img
                        src={`${Images.guide2}?w=164&h=164&fit=crop&auto=format`}
                        srcSet={`${Images.guide2}?w=164&h=164&fit=crop&auto=format&dpr=2 2x`}
                        //alt={item.title}
                        loading="lazy"
                        className={classes.guideImageStyle}
                      />

                      <Grid
                        item
                        xs={12}
                        sm={12}
                        md={12}
                        lg={6}
                        xl={4}
                        className={classes.grideGuide2Container}
                      >
                        <Grid className={classes.grideGuide2TriangleContainer}>
                          <div className={classes.rightAngleTriangleGuide2} />
                        </Grid>
                        <Item
                          style={{
                            background: "#D1DFF6",
                            width: "280px",
                            height: "100px",
                            // marginTop: "250px",
                            borderTopLeftRadius: "0px",
                          }}
                        >
                          <Typography className={classes.guideNoteStyle}>
                            Add projects details to be able to easily identify
                            the project later
                          </Typography>
                        </Item>
                      </Grid>
                    </Grid>
                  </Grid>
                </Box>
                <Box className={classes.boxNoteContainer}>
                  <div className={classes.btnGuideContainer}>
                    <StyledButton
                      onClick={() => {
                        //  createOrganization()
                        setGuide("guide3");
                      }}
                      style={
                        {
                          //background: "#fff",
                        }
                      }
                      className={classes.btnGuideLabelStyle}
                      variant="contained"
                      size="medium"
                    >
                      Next{" >>"}
                    </StyledButton>
                  </div>
                </Box>
              </div>
            </Fade>
          ) : guide === "guide3" ? (
            <Fade in={open}>
              <div>
                <Box className={classes.boxContainer02}>
                  <Grid container columns={1}>
                    <Grid
                      item
                      xs={8}
                      style={{
                        display: "flex",
                        flex: 1,
                        justifyContent: "center",
                      }}
                    >
                      <img
                        src={`${Images.guide3}?w=164&h=164&fit=crop&auto=format`}
                        srcSet={`${Images.guide3}?w=164&h=164&fit=crop&auto=format&dpr=2 2x`}
                        //alt={item.title}
                        loading="lazy"
                        className={classes.guideImageStyle}
                      />

                      <Grid
                        item
                        xs={12}
                        sm={12}
                        md={12}
                        lg={6}
                        xl={4}
                        className={classes.grideGuide2Container}
                      >
                        <Grid className={classes.grideGuide2TriangleContainer}>
                          <div className={classes.rightAngleTriangleGuide2} />
                        </Grid>
                        <Item
                          style={{
                            background: "#D1DFF6",
                            width: "280px",
                            height: "100px",
                            // marginTop: "250px",
                            borderTopLeftRadius: "0px",
                          }}
                        >
                          <Typography className={classes.guideNoteStyle}>
                            Attach a Database to the project by providing the
                            below information
                          </Typography>
                        </Item>
                      </Grid>
                    </Grid>
                  </Grid>
                </Box>
                <Box className={classes.boxNoteContainer}>
                  <div className={classes.btnGuideContainer}>
                    <StyledButton
                      onClick={() => {
                        //  createOrganization()
                        setGuide("guide4");
                      }}
                      style={
                        {
                          //background: "#fff",
                        }
                      }
                      className={classes.btnGuideLabelStyle}
                      variant="contained"
                      size="medium"
                    >
                      Next{" >>"}
                    </StyledButton>
                  </div>
                </Box>
              </div>
            </Fade>
          ) : (
            <Fade in={open}>
              <div>
                <Box className={classes.boxContainer02}>
                  <Grid container columns={1}>
                    <Grid
                      item
                      xs={8}
                      style={{
                        display: "flex",
                        flex: 1,
                        justifyContent: "center",
                      }}
                    >
                      <img
                        src={`${Images.guide4}?w=164&h=164&fit=crop&auto=format`}
                        srcSet={`${Images.guide4}?w=164&h=164&fit=crop&auto=format&dpr=2 2x`}
                        //alt={item.title}
                        loading="lazy"
                        className={classes.guideImageStyle}
                      />

                      <Grid
                        item
                        xs={12}
                        sm={12}
                        md={12}
                        lg={6}
                        xl={4}
                        className={classes.grideGuide2Container}
                      >
                        <Grid className={classes.grideGuide2TriangleContainer}>
                          <div className={classes.rightAngleTriangleGuide2} />
                        </Grid>
                        <Item
                          style={{
                            background: "#D1DFF6",
                            width: "280px",
                            height: "100px",
                            // marginTop: "250px",
                            borderTopLeftRadius: "0px",
                          }}
                        >
                          <Typography className={classes.guideNoteStyle}>
                            Once done, we'll process the database, to be able to
                            help you with your questions
                          </Typography>
                        </Item>
                      </Grid>
                    </Grid>
                  </Grid>
                </Box>
                <Box className={classes.boxNoteContainer}>
                  <div className={classes.btnGuideContainer}>
                    <StyledButton
                      onClick={() => {
                        handleCloseProjectModal();
                        handleClose();
                      }}
                      style={
                        {
                          //background: "#fff",
                        }
                      }
                      className={classes.btnGuideLabelStyle}
                      variant="contained"
                      size="medium"
                    >
                      Finish
                    </StyledButton>
                  </div>
                </Box>
              </div>
            </Fade>
          )}
        </Modal>
      </div>
    </div>
  );
};

export default Guided;
