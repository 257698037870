import {
  React,
  Paper,
  useMemo,
  Tabs,
  Tab,
  useState,
  Box,
  Typography,
  Button,
  Select,
  MenuItem,
  clsx,
  useEffect,
  ListItemIcon,
  FiberManualRecord,
  Rectangle,
  Backdrop,
  CircularProgress,
  useNavigate,
  useLocation,
} from "../../../../app.module";
// @ts-ignore
import { ResponsiveBoxPlot } from "@nivo/boxplot";
import { useStyle } from "./BoxPlotStyle";
import {
  getVisualizationData,
  getVisualizationType,
  getPossibalVisualization,
  getBoxPlotVisualizationData,
} from "./ChartsService";
import { Colors } from "../../../constants";
import {
  getUserDetailsFromCookies,
  getQueryIdFromCookies,
} from "../../../services";
interface TabPanelProps {
  children?: React.ReactNode;
  index: number;
  value: number;
  visualizationTypeList: any[];
  boxPlotDetails: any;
  isY: boolean;
  classes: any;
  handleChartType: (name: string) => void; 
  handleAxisChanged: (x: any, y: any) => void;
}

const CustomTabPanel = (props: TabPanelProps) => {
  const {
    children,
    handleChartType,
    handleAxisChanged,
    visualizationTypeList,
    boxPlotDetails,
    value,
    classes,
    index,
    isY,
    ...other
  } = props;
  const location = useLocation();
  const [yaxis, setYaxis] = useState(
    boxPlotDetails?.chartParameter?.Y_Axis
      ? boxPlotDetails?.chartParameter?.Y_Axis
      : "none"
  ); 
  const [xaxis, setXaxis] = useState(
    boxPlotDetails?.chartParameter?.X_Axis[0]
      ? boxPlotDetails?.chartParameter?.X_Axis[0]
      : "none"
  );
  const [isMounted, setIsMounted] = useState<boolean>(false);
  const [moduleType, setModuleType] = useState(
    location.state?.moduleType ? location.state?.moduleType : "none"
  );

  const [showPlaceholder, setShowPlaceholder] = useState(moduleType === "none");
  const [moduleTypeList, setModuleTypeList] = useState<any>([]);
  const [moduleColumnHeaders, setModuleColumnHeaders] = useState([
    { name: "Donuts", id: 1 },
    { name: "Fries", id: 2 },
    { name: "Kebab", id: 3 },
    { name: "Sandwich", id: 4 },
  ]);
  const [colorType, setColorType] = useState("none");
  const [colorTypeList, setColorTypeList] = useState([
    { color: "red", id: 1, name: "##89D280" },
    { color: "blue", id: 2, name: "##FBDB38" },
    { color: "green", id: 3, name: "##EF6C6C" },
    { color: "yellow", id: 4, name: "###66C8FF" },
  ]);
  const [moduleHeaderType, setModuleHeaderType] = useState("Fries");
  const navigate = useNavigate();
  useEffect(() => {
    setTimeout(() => {
      setXaxis(boxPlotDetails?.chartParameter?.X_Axis[0]);
      setYaxis(boxPlotDetails?.chartParameter?.Y_Axis[0]);
      setIsMounted(true);
    }, 1000);
  }, [moduleType, isMounted]);
  const handleModuleStateChange = (e: any) => {
    const { name, value } = e.target;
    handleChartType(value);
    setModuleType(value);
    switch (value) {
      case "BoxPlot":
        navigate(
          `/${getUserDetailsFromCookies()?.identifier}/projects/boxplot`,
          { state: { moduleType: "BoxPlot" } }
        );
        break;
      case "LineChart":
        navigate(
          `/${getUserDetailsFromCookies()?.identifier}/projects/linechart`,
          { state: { moduleType: "LineChart" } }
        );
        break;
      case "PieChart":
        navigate(
          `/${getUserDetailsFromCookies()?.identifier}/projects/piechart`,
          { state: { moduleType: "PieChart" } }
        );
        break;
      case "Heatmap":
        navigate(
          `/${getUserDetailsFromCookies()?.identifier}/projects/heatmap`,
          { state: { moduleType: "Heatmap" } }
        );
        break;
    }
  };
  const handleXaxisStateChange = (e: any) => {
    const { name, value } = e.target;
    setXaxis(value);
  };
  const handleYaxisStateChange = (e: any) => {
    const { name, value } = e.target;
    setYaxis(value);
  };
  const handleColumnHeaderStateChange = (e: any) => {
    const { name, value } = e.target;
    setModuleHeaderType(value);
  };

  const handleColorStateChange = (e: any) => {
    const { name, value } = e.target;
    setColorType(value);
  };

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      <Box sx={{ p: 3 }}>
        <Typography className={classes.visualizationLable}>
          Module type
        </Typography>
        <Select
          id="demo-simple-select-helper-type"
          value={moduleType}
          name="module_type"
          className={clsx(
            classes.visualizationSelect
          )}
          onFocus={(e) => setShowPlaceholder(false)}
          onChange={handleModuleStateChange}
          onClose={(e) => {
            setShowPlaceholder(
              (e.target as HTMLInputElement).value === undefined
            );
          }}

        >
          <MenuItem

            key="0"
            disabled
            value={moduleType}
          >
            Select module
          </MenuItem>

          {visualizationTypeList?.map((item: any) => (
            <MenuItem
              key={item?.chartType + index}
              className={classes.visualizationMenuitem}
              value={item?.chartType}
            >
              {item?.chartType}
            </MenuItem>
          ))}
        </Select>
        <Typography className={classes.visualizationLable}>X-Axis</Typography>
        <Select
          id="demo-simple-select-helper-type"
          value={xaxis}
          name="xaxis"
          className={clsx(
            classes.visualizationSelect
          )}
          onFocus={(e) => setShowPlaceholder(false)}
          onChange={handleXaxisStateChange}
          defaultValue={xaxis ? xaxis : ""}
        >
          <MenuItem
            key="0"
            disabled
            value={xaxis}
          >
            Select
          </MenuItem>

          {boxPlotDetails?.chartParameter?.X_Axis?.map(
            (item: any, index: number) => (
              <MenuItem
                key={index + ""}
                className={classes.visualizationMenuitem}
                value={item}
              >
                {item}
              </MenuItem>
            )
          )}
        </Select>

        <Typography className={classes.visualizationLable}>Y-Axis</Typography>
        <Select
          id="demo-simple-select-helper-type"
          value={yaxis}
          name="yaxis"
          className={clsx(
            classes.visualizationSelect
          )}
          onFocus={(e) => setShowPlaceholder(false)}
          onChange={handleYaxisStateChange}
          defaultValue="none"
        >
          <MenuItem
            key="0"
            disabled
            value={yaxis}
          >
            Select
          </MenuItem>

          {boxPlotDetails?.chartParameter?.Y_Axis?.map(
            (item: any, index: number) => (
              <MenuItem
                key={index + ""}
                className={classes.visualizationMenuitem}
                value={item}
              >
                {item}
              </MenuItem>
            )
          )}
        </Select>
        <Button
          onClick={() => {
            handleAxisChanged(xaxis, yaxis);
          }}
          variant="contained"
          className={classes.saveTableBtn}
        >
          Analyze
        </Button>
      </Box>
    </div>
  );
};
const CustomTabPanel1 = (props: TabPanelProps) => {
  const { children, value, classes, index, isY, ...other } = props;
  const [updateModue, setUpdateModue] = useState("Every week");
  const [updateType, setUpdateType] = useState("none");
  const [updateTypeList, setUpdateTypeList] = useState([
    { id: 1, name: "Week" },
    { id: 2, name: "Month" },
    { id: 3, name: "Year" },
    { id: 4, name: "Daily" },
  ]);
  const [reccuranceList, setReccuranceList] = useState([
    { id: 1, name: "1" },
    { id: 2, name: "2" },
    { id: 3, name: "3" },
    { id: 4, name: "4" },
  ]);
  const [reccurance, setReccurance] = useState("1");
  const [showPlaceholder, setShowPlaceholder] = useState(
    updateModue === "none"
  );
  const [updateModueTypeList, setUpdateModueTypeList] = useState([
    { name: "Every week", id: 1 },
    { name: "Every month", id: 2 },
  ]);
  useEffect(() => {}, [updateModue]);
  const handleModuleStateChange = (e: any) => {
    const { name, value } = e.target;
    setUpdateModue(value);
  };
  const handleUpdatesStateChange = (e: any) => {
    const { name, value } = e.target;
    setUpdateType(value);
  };
  const handleReccuranceStateChange = (e: any) => {
    const { name, value } = e.target;
    setReccurance(value);
  };
  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          <Typography className={classes.visualizationLable}>
            Update module
          </Typography>
          <Select
            id="demo-simple-select-helper-type"
            value={updateModue}
            name="module_type"
            className={clsx(
              classes.visualizationSelect
            )}
            onFocus={(e) => setShowPlaceholder(false)}
            onChange={handleModuleStateChange}
            onClose={(e) => {
              setShowPlaceholder(
                (e.target as HTMLInputElement).value === undefined
              );
            }}
            defaultValue="none"
          >
            <MenuItem
              key="0"
              disabled
              value="none"
            >
              Select update module type
            </MenuItem>

            {updateModueTypeList.map((item: any) => (
              <MenuItem
                key={item?.id}
                className={classes.visualizationMenuitem}
                value={item?.name}
              >
                {item?.name}
              </MenuItem>
            ))}
          </Select>
          <Typography className={classes.recurranceLable}>
            Repeat every
          </Typography>
          <div className={classes.twoDropDownContainer}>
            <div
              className={clsx(
                classes.visualizationRecuuranceSelectFlex,
                classes.dropDownFiledLabelContainer
              )}
            >
              <Select
                id="demo-simple-select-helper-type"
                value={reccurance}
                name="module_type"
                className={clsx(
                  classes.visualizationSelect
                )}
                onFocus={(e) => setShowPlaceholder(false)}
                onChange={handleReccuranceStateChange}
                onClose={(e) => {
                  setShowPlaceholder(
                    (e.target as HTMLInputElement).value === undefined
                  );
                }}
                defaultValue="none"
              >
                <MenuItem
                  key="0"
                  disabled
                  value="none"
                >
                  Reccurance
                </MenuItem>

                {reccuranceList.map((item: any) => (
                  <MenuItem
                    key={item?.id}
                    className={classes.visualizationMenuitem}
                    value={item?.name}
                  >
                    {item?.name}
                  </MenuItem>
                ))}
              </Select>
            </div>
            <div className={classes.dropDownRecurranceFiledLabelContainerRight}>
              <Select
                id="demo-simple-select-helper-type"
                value={updateType}
                name="module_type"
                className={clsx(
                  classes.visualizationSelect
                )}
                style={{
                  height: "54px",
                }}
                onFocus={(e) => setShowPlaceholder(false)}
                onChange={handleUpdatesStateChange}
                onClose={(e) => {
                  setShowPlaceholder(
                    (e.target as HTMLInputElement).value === undefined
                  );
                }}
                defaultValue="none"
              >
                <MenuItem

                  key="0"
                  disabled
                  value="none"
                >
                  Select Type
                </MenuItem>
                {updateTypeList.map((item: any) => (
                  <MenuItem
                    key={item?.id}
                    className={classes.visualizationMenuitem}
                    value={item?.name}
                  >
                    {item?.name}
                  </MenuItem>
                ))}
              </Select>
            </div>
          </div>
        </Box>
      )}
    </div>
  );
};
const a11yProps = (index: number) => {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
};

const BoxPlot = React.memo((props: any) => {
  const [value, setValue] = useState<number>(0); 
  const [isYaxis, setIsYaxis] = useState<boolean>(false);
  const [isXaxis, setIsXaxis] = useState<boolean>(false);
  const [boxPlotJson, setBoxPlotJson] = useState<any>([]);
  const [customBarColors, setCustomBarColors] = useState<any>([]);
  const [barIndexValue, setBarIndexValue] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [isAxisChanged, setIsAxisChanged] = useState(true);
  const [selectedModuleType, setSelectedModuleType] = useState("Bar Chart");
  const [visualizationTypes, setVisualizationTypes] = useState<any>([]);
  const [barVisualization, setBarVisualization] = useState<any>({});
  const [defsColor, setDefsColor] = useState("red");
  const [activeTab, setActiveTab] = React.useState(0);
  const [xValue, setXValue] = useState<any>("");
  const [yValue, setYValue] = useState<any>("");
  const { classes } = useStyle({ isYaxis: false, isXaxis: true, activeTab });
  useEffect(() => {
    const getBoxPlotDatat = (outputArray: any) => {
      getBoxPlotVisualizationData("BoxPlot", outputArray).then((resp) => {
        const { result } = resp;
        const boxPlot = JSON.parse(result);
        let customColors = boxPlot.data.map((item: any) => item.balanceColor);
        setCustomBarColors([...customColors]);
        setBoxPlotJson(boxPlot);
        setBarIndexValue(boxPlot.indexBy);
        setTimeout(() => {
          setIsLoading(false);
        }, 1000);

        console.log("boxPlot data ", boxPlot);
      });
    };
    getPossibalVisualization(getQueryIdFromCookies()).then((resp: any) => {
      setVisualizationTypes([...resp]);
      const foundObject = resp.find((obj: any) => obj.chartType === "BoxPlot");
      console.log("Tyles Data", foundObject);
      const inputArray: string[] = foundObject.chartParameter.X_Axis;
      const outputArray = [
        {
          ParameterType: "X_Axis",
          ParameterValue:
            xValue === "" ? foundObject.chartParameter.X_Axis[0] : xValue,
        },
        {
          ParameterType: "Y_Axis",
          ParameterValue:
            yValue === "" ? foundObject.chartParameter.Y_Axis[0] : yValue,
        },
      ];

      console.log("outputArray for visulaization data", outputArray);
      setBarVisualization(foundObject);
      setSelectedModuleType(resp[0]?.chartType);
      getBoxPlotDatat(outputArray);
    });

    //}
  }, [isLoading, isAxisChanged]);
  const handleTabChange = (event: React.SyntheticEvent, newValue: number) => {
    setValue(newValue);
    setIsYaxis(!isYaxis);
    setActiveTab(newValue);
  };
  const handleChartType = (name: string) => {
    setSelectedModuleType(name);
    setDefsColor("green");
    console.log("Name: ", name);
  };
  const handleAxisChanged = (x: any, y: any) => {
    console.log("XAxis, YAxis", x, y);
    if (x !== y) {
      if (x !== "none") {
        setXValue(x);
      }
      if (y !== "none") {
        setYValue(y);
      }

      setIsLoading(true);
      setIsAxisChanged(!isAxisChanged);
    } else {
      alert("X and Y axis should not be same.");
    }
  };
  const chartsComponent = () => {
    return (
      <div
        key="CHART101"
        style={{
          backgroundColor: Colors?.background.backgroundColorTheme3,
          height: "87vh",
          width: "75%",
          overflowX: "auto",
          boxShadow: "0px 0px 4px 0px #00000040",
          borderRadius: "10px 0px 0px 10px",
          overflow: "scroll",
          top: 0,
        }}
      >
        <div
          key="CHART102"
          style={{
            width: `${80 * boxPlotJson?.data?.length}px`,
            height: "75vh",
          }}
        >
          {!isLoading ? (
            <ResponsiveBoxPlot
              margin={{ top: 60, right: 140, bottom: 60, left: 60 }}
              minValue={1}
              maxValue={11}
              quantiles={[0.15, 0.35, 0.5, 0.75, 0.85]}
              padding={0.12}
              enableGridX={true}
              axisTop={{
                tickSize: 5,
                tickPadding: 5,
                tickRotation: 0,
                legend: "",
                legendOffset: 36,
              }}
              axisRight={{
                tickSize: 5,
                tickPadding: 5,
                tickRotation: 0,
                legend: "",
                legendOffset: 0,
              }}
              axisBottom={{
                tickSize: 5,
                tickPadding: 5,
                tickRotation: 0,
                legendPosition: "middle",
                legendOffset: 32,
              }}
              axisLeft={{
                tickSize: 5,
                tickPadding: 5,
                tickRotation: 0,
                legendPosition: "middle",
                legendOffset: -40,
              }}
              colors={{ scheme: "nivo" }}
              borderWidth={1}
              borderColor={{
                from: "color",
                modifiers: [["darker", "1.3"]],
              }}
              medianWidth={12}
              medianColor={{
                from: "color",
                modifiers: [["darker", "0.9"]],
              }}
              whiskerWidth={4}
              whiskerEndSize={0.75}
              whiskerColor={{
                from: "color",
                modifiers: [["darker", 0.3]],
              }}
              animate={false}
              motionConfig="molasses"
              legends={[
                {
                  anchor: "right",
                  direction: "column",
                  justify: false,
                  translateX: 154,
                  translateY: 0,
                  itemWidth: 60,
                  itemHeight: 20,
                  itemsSpacing: 3,
                  itemTextColor: "#999",
                  itemDirection: "left-to-right",
                  symbolSize: 27,
                  symbolShape: "square",
                  effects: [
                    {
                      on: "hover",
                      style: {
                        itemTextColor: "#000",
                      },
                    },
                  ],
                },
              ]}
            />
          ) : (
            <div>Loading</div>
          )}
        </div>
      </div>
    );
  };
  const handleClose = () => {
    setIsLoading(false);
  };
  return (
    <div
      style={{
        backgroundColor: Colors?.background.backgroundColorTheme3,
        display: "flex",
        flexDirection: "row",
      }}
    >
      <Backdrop
        className={classes.circularPlanContainer}
        open={isLoading}
        onClick={handleClose}
      >
        <CircularProgress className={classes.circularPlanView} />
      </Backdrop>
      {chartsComponent()}
      <div
        key="TAB101"
        style={{
          height: "87vh",
          width: "25%",
          boxShadow: "0 2px 8px rgba(0, 0, 0, 0.1)",
          backgroundColor: "#fff",
          transition: "box-shadow 0.3s ease-in-out",
          borderRadius: "0px 10px 10px 0px",
          overflow: "scroll",
          top: 0,
        }}
      >
        <div
          style={{
            backgroundColor: "transparent",

            width: "100%",

          }}
        >
          <Tabs
            value={value}
            indicatorColor="primary"
            textColor="primary"
            onChange={handleTabChange}
            aria-label="disabled tabs example"
            classes={{ root: classes.root, indicator: classes.indicator }}
          >
            <Tab
              className={classes.tabLabel}
              style={{
                width: "50%",
                color: activeTab === 0 ? "#000" : "#DADADA",
                backgroundColor:
                  activeTab === 0
                    ? "transparent"
                    : Colors?.background.backgroundColorTheme3,
                padding: "8px 16px",
              }}
              {...a11yProps(0)}
              label="Visulization"
            />
            <Tab
              className={classes.tabLabel}
              style={{
                width: "50%",
                color: activeTab === 1 ? "#000" : "#DADADA",
                backgroundColor:
                  activeTab === 1
                    ? "transparent"
                    : Colors?.background.backgroundColorTheme3,
                padding: "8px 16px",
              }}
              {...a11yProps(1)}
              label="Reccurence"
            />
          </Tabs>
        </div>
        <CustomTabPanel
          handleChartType={handleChartType}
          handleAxisChanged={handleAxisChanged}
          classes={classes}
          isY={isYaxis}
          value={value}
          visualizationTypeList={visualizationTypes}
          boxPlotDetails={barVisualization}
          index={0}
        ></CustomTabPanel>
        <CustomTabPanel1
          handleChartType={handleChartType}
          handleAxisChanged={handleAxisChanged}
          classes={classes}
          isY={isYaxis}
          value={value}
          visualizationTypeList={visualizationTypes}
          boxPlotDetails={barVisualization}
          index={1}
        ></CustomTabPanel1>
      </div>
    </div>
  );
});

export default BoxPlot;
