import React, { PureComponent, useEffect, useState } from 'react';
import {
  Radar, RadarChart, PolarGrid, PolarAngleAxis,
  PolarRadiusAxis, ResponsiveContainer, Tooltip
} from 'recharts';
// import { useStyle } from "./ScatterPlotStyle";
// import { Colors, Images } from '../../../constants';
import { active } from 'd3-transition';
import { Colors, Images } from '../../../constants';
// import { Tooltip } from '@mui/material';



type CustomTooltipProps = {
  active?: boolean;
  payload?: any[];
  label?: string;
};



const CustomTooltip: React.FC<CustomTooltipProps> = ({ active, payload }) => {
  if (active && payload && payload.length) {
    // Extract the dynamic payload data that the tooltip will use
    const tooltipData = payload[0].payload; // This accesses the nested payload object which contains your data

    return (
      <div className="custom-tooltip" style={{
        backgroundColor: '#fff',
        padding: '10px',
        border: '1px solid #ccc',
        boxShadow: '0px 0px 10px 0px rgba(0, 0, 0, 0.25)',
        borderRadius: '5px',
      }}>
        {/* Iterate over the payload object's own properties to display them */}
        {Object.keys(tooltipData).map((key, index) => (
          <p key={`item-${index}`} className="intro">
            {`${key}: ${tooltipData[key]}`}
          </p>
        ))}
      </div>
    );
  }

  return null;
};

const RadarrChart = React.memo((props: any) => { 
  const [radarChartJson, setRadarChartJson] = useState<any>([]);
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    if (props.data !== radarChartJson) {
      setRadarChartJson(props.data);
      setIsLoading(false); // Data is loaded, set loading to false
    }
  }, [props.data]);

  if (isLoading) {
    return <div>Loading...</div>;
  }

  if (!radarChartJson.data || radarChartJson.data.length === 0) {
    return (
      <div
        style={{
          display: "flex",
          height: "87vh",
          width: "75%",
          justifyContent: "center",
          alignItems: "center",
          backgroundColor: Colors?.background.backgroundColorTheme3,
          boxShadow: "0px 0px 4px 0px #00000040",
          borderRadius: "10px 0px 0px 10px",
        }}
      >
        <img
          style={{
            height: "auto",
            width: "300px",
          }}
          src={Images.no_data_img}
          alt="No data"
        />
      </div>
    );
  }

  return (
    <div
      key="CHART112"
      style={{
        backgroundColor: Colors?.background.backgroundColorTheme3,
        height: "87vh",
        width: "75%",
        overflowX: "auto",
        boxShadow: "0px 0px 4px 0px #00000040",
        borderRadius: "10px 0px 0px 10px",
        // overflow: "scroll",
        top: 0,
      }}
    >
      Datasource name: <label>{props?.dbsource}</label>
      <div
        key="CHART1005"
        style={{
          width: "100%",
          height: "75vh",
        }}
      >
       <ResponsiveContainer width="100%" height="100%">
       <RadarChart cx="50%" cy="50%" outerRadius="80%" data={radarChartJson.data}>
  <PolarGrid stroke="#777" /> {/* Slightly lighter grid lines */}
  <PolarAngleAxis
    dataKey={radarChartJson.datakey_X}
    stroke="#777"
    tick={{ dy: 4 }} // Adds space between the labels and the web
  />
  <PolarRadiusAxis 
    axisLine={{ stroke: '#777' }} 
    tick={{ fill: '#555' }} // Darker text for the tick labels
  />
            <Radar
  name={radarChartJson.datakey_Y}
  dataKey={radarChartJson.datakey_Y}
  stroke="#8884d8"
  fill="#8884d8"
  fillOpacity={0.8}
  // Custom hover effect
  onMouseOver={(event) => {
    const target = event.target as SVGElement; // Type cast to SVGElement
    target.setAttribute('fill-opacity', '0.9');
  }}
  onMouseOut={(event) => {
    const target = event.target as SVGElement; // Type cast to SVGElement
    target.setAttribute('fill-opacity', '0.8');
  }}
/>
            {/* Custom Tooltip */}
            <Tooltip content={CustomTooltip} />
          </RadarChart>
        </ResponsiveContainer>
      </div>
    </div>
  );
        });
export default RadarrChart;