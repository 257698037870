import {
  makeStyles,
  Switch,
  TextField,
  styled,
  Button,
} from "../../../app.module";
import { Colors, Fonts } from "../../constants";
export const StyledQueryButton = styled(Button)({
  backgroundColor: "#fff",
  color: "#fff",
  padding: "6px 12px",
  "&:hover": {
    backgroundColor: "#fff",
  },
});
export const CssDashboardTextField = styled(TextField)({
  "& label.Mui-focused": {
    color: "#000",
    borderRadius: "10px",
  },
  "& .MuiInput-underline:after": {
    borderBottomColor: "red",
    borderRadius: "10px",
  },

  "& .MuiOutlinedInput-root": {
    "& fieldset": {
      borderColor: "#F9F9F9",
      borderRadius: "10px",
    },
    "&:hover fieldset": {
      border: `1px solid ${Colors?.main}`,
      borderRadius: "10px",
    },
    "&.Mui-disabled:hover fieldset": {
      // Apply the same styles as the non-hover disabled state
      border: "1px solid #F9F9F9",
      borderRadius: "10px",
    },
    "&.Mui-disabled fieldset": {
      // Make sure this matches the hover style for disabled state
      border: "1px solid #F9F9F9",
      borderRadius: "10px",
    },
    "&.Mui-focused fieldset": {
      border: `1px solid ${Colors?.main}`,
      borderRadius: "10px",
    },
    "& .MuiInputBase-input": {
      height: "16px", // Adjust the height value as needed
    },
  },
});

export const useStyle = makeStyles()((_theme) => ({
  twoTableFieldsContainer: {
    display: "flex",
    flex: 1,
    // backgroundColor: Colors?.background?.backgroundColorTheme3,
    // paddingLeft: "25px",
    justifyContent: "space-between",
    backgroundColor: "White",
    borderRadius:5,
  },
  dashboardInput: {
    //backgroundColor: "#fff",
    fontFamily: Fonts.NunitoSans.regular,
    fontStyle: "normal",
    fontWeight: "400",
    fontSize: "18px",
    color: "#000000",
    "&::placeholder": {
      color: "#ADADAD",
    },
  },
  textTableFiledLabelContainer: {
    display: "flex",
    marginRight: "20px",
    flex: 1,
    flexDirection: "column",
    marginBottom: "10px",        // Enable Flexbox
    alignItems: 'center',    // Center vertically
    justifyContent: 'center', // Center horizontally, if you also want horizontal centering
    height: '100%',
  },
  tableQueryInput: {
    backgroundColor: "#f9f9f9",
    fontFamily: Fonts.NunitoSans.regular,
    fontStyle: "normal",
    fontWeight: "400",
    fontSize: "18px",
    lineHeight: "21px",
    borderRadius: "10px", // border before focusing the text field
    //height: "auto",
    color: "#000000",
    "&::placeholder": {
      color: "#ADADAD",
    },
  },
  moduleTableLabelStyle: {
    fontFamily: Fonts.NunitoSans.regular,
    fontStyle: "normal",
    fontWeight: 700,
    fontSize: "16px",
    lineHeight: "25px",
    color: "#000",
    marginBottom: "10px",
  },
  txtLabelTableContainerRight: {
    display: "flex",
    flex: 1,
    flexDirection: "column",
  },
  generateBtnLabel: {
    fontFamily: Fonts.NunitoSans.regular,
    fontStyle: "normal",
    fontWeight: 700,
    fontSize: "18px",
    lineHeight: "25px",
    color: "#fff",
    marginBottom: "10px",
  },
  queryBtnTestContainer: {
    display: "flex",
    //flex: 1,
    justifyContent: "center",
    marginRight: "18%",
  },
  createDashboardBtnLabelStyle: {
    fontFamily: Fonts.NunitoSans.regular,
    fontStyle: "normal",
    fontWeight: 700,
    fontSize: "18px",
    lineHeight: "25px",
    color: "#fff",
    textTransform: "capitalize",
    height: "47px",
    width: "182px",
    marginLeft: "13px",
    marginRight: "22px",
    marginBottom: "20px",
   
    // "&:hover": {
    //   background: "#fff",
    // },
    "&:focus": {
      background: "linear-gradient(90deg, #5EB0FA 0%, #1157CD 50.54%, #9C90FA 100%)",
    },
    "&:disabled": {
      background: "#ecedf2",
    },
    background: "linear-gradient(90deg, #5EB0FA 0%, #1157CD 50.54%, #9C90FA 100%)",
  },
  generateBtnCircularLoader: {
    color: Colors?.main,
    marginLeft: "5px",
  },
}));
