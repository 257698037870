
import React, { useEffect, useState } from 'react';
import { Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper } from '@mui/material';
import { Colors, Images } from '../../../constants';

const TableChart = React.memo((props: any) => {
  const [tableData, setTableData] = useState<any>({});

  useEffect(() => {
    if (props.data !== tableData) {
      setTableData(props.data);
    }
  }, [props.data, tableData]);
  

  useEffect(() => {
    // Inject custom scrollbar styles
    const style = document.createElement('style');
    style.type = 'text/css';
    style.innerHTML = `
      .TableChartScroll::-webkit-scrollbar {
        width: 12px;
        height: 12px;
        background-color: transparent; /* make scrollbar background transparent */
      }
      .TableChartScroll:hover::-webkit-scrollbar-thumb {
        border-radius: 10px;
        -webkit-box-shadow: inset 0 0 6px rgba(0,0,0,.3);
        background-color: #D3D3D3; /* only show scrollbar thumb on hover */
      }
    `;
    document.head.appendChild(style);
  
    // Ensure that we remove the style on cleanup
    return () => {
      document.head.removeChild(style);
    };
  }, []);
  
  const TableChartScrollClassName = "TableChartScroll";

  if (!tableData.data || tableData.data.rowData.length === 0) {
            return (
                <div
                style={{
                  display: "flex",
                  height: "100%",
                  width: "100%",
                  justifyContent: "center",
                  alignItems: "center",
                  backgroundColor: Colors?.background.backgroundColorTheme3,
                  boxShadow: "0px 0px 4px 0px #00000040",
                  borderRadius: "10px 0px 0px 10px",
                }}
              >
                <img
                  style={{
                    height: "auto",
                    width: "300px",
                  }}
                  src={Images.no_data_img}
                  alt="No data"
                />
              </div>
            );
          }

  const columns = tableData.data.column;
  const rowData = tableData.data.rowData;

  const cellStyle = {
    textAlign: 'center',
    whiteSpace: 'nowrap', // This will ensure the text doesn't get cut off
  };

  const headerCellStyle = {
    backgroundColor: "#9ECFFC",
    color: "#000",
    fontWeight: 'bold' as 'bold', // cast to the 'bold' type
    textAlign: 'center' as 'center', // cast to the 'center' type
    // whiteSpace: 'nowrap' as 'nowrap',
    borderRight: '1px solid rgba(224, 224, 224, 1)',
  };

  const bodyCellStyle = {
    textAlign: 'center' as 'center', // cast to the 'center' type
    // whiteSpace: 'nowrap' as 'nowrap', 
    borderRight: '1px solid rgba(224, 224, 224, 1)',
  };

  const getRowBackgroundColor = (index: number) => {
        return index % 2 ? '##F8FAFF' : '##F8FAFF'; // Lighter for even, darker for odd
      };
    

  return (
    <div
      key="TABLE112"
     
    //   style={{
    //     // backgroundColor: Colors?.background.backgroundColorTheme3,
    //     height: "300px",
    //     width: "100%",
    //     overflowX: "auto",
    //     boxShadow: "0px 4px 8px rgba(0, 0, 0, 0.1)", // subtle shadow for depth
    //     borderRadius: "8px", // rounded corners
    //     top: 0,
    //     padding: '20px', // Added padding for better spacing
        
    //   }}
    style={{
        // boxShadow: "0px 4px 8px rgba(0, 0, 0, 0.1)",
        borderRadius: "8px",
        top: 0,
        padding: '20px',
        display: 'flex', // Make sure the parent is flex
        flexDirection: 'column', // Stack children vertically
        height: '100%', // Take full height of the parent container
      }}
    >
      {/* Datasource name: <label>{props?.dbsource}</label> */}
      {/* Your existing elements here */}
      <TableContainer component={Paper}  
className={TableChartScrollClassName}
//      sx={{
//     maxHeight: '75vh',
//     overflow: 'hidden', // hide the scrollbar by default
//     borderRadius: "8px",
//     border: "1px solid #e0e0e0",
//     boxShadow: "0px 2px 4px rgba(0, 0, 0, 0.05)",
//     marginTop: '10px',
   
//     '&:hover': {
//       overflowY: 'auto' // show the scrollbar when hovering
//     }
//   }}
sx={{
    height: '100%', // Use 100% of the parent div's height
    overflow: 'hidden', // Allow scrolling if the content overflows
    borderRadius: "8px",
    border: "1px solid #e0e0e0",
    boxShadow: "0px 2px 4px rgba(0, 0, 0, 0.05)",
    marginTop: '10px',

    '&:hover': {
      overflowY: 'auto' // show the scrollbar when hovering
    }
  }}
    
    >
        <Table stickyHeader aria-label="sticky table" style={{
        borderCollapse: 'separate', // this can help with the spacing of the border and shadow
      }}>
          <TableHead>
            <TableRow>
              {columns.map((column: any) => (
                <TableCell key={column.id} style={headerCellStyle}>
                  {column.name}
                </TableCell>
              ))}
            </TableRow>
          </TableHead>
          <TableBody>
            {rowData.map((row: any, index: number) => (
              <TableRow key={index} style={{ backgroundColor: getRowBackgroundColor(index) }}>
                 {columns.map((column: any, colIndex: number) => {
    // Check if it's the last column and remove the border
    const isLastColumn = colIndex === columns.length - 1;
    const cellStyles = isLastColumn
      ? { ...bodyCellStyle, borderRight: 'none' }
      : bodyCellStyle;

    return (
      <TableCell key={`${index}-${column.id}`} style={cellStyles}>
        {row[column.name]}
      </TableCell>
    );
  })}
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </div>
  );
});

export default TableChart;
