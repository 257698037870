import { getData, getUserDetailsFromCookies, postData } from "../../services";
import {
    IndustryType,
    IndustrySize,
    Countries,
    States,
    Cities,
    CreateCompany,
    Subscription,
    PlanAddons,
} from "../../constants/Endpoints";
import { useMsal } from "../../../app.module";
import { subscribe } from "diagnostics_channel";
import { getuid } from "process";
let msalDetails: any = "";
export const getCompanyTypes = async () => {
    //console.log("TOKEN", msalDetails.idToken);
    return await getData(IndustryType).then((result) => {
        let dataset = result?.map((p: any) => new CompanyResult(p));
        return dataset;
    });
};
export const getIndustrySize = async () => {
    return await getData(IndustrySize).then((result) => {
        let dataset = result?.map((p: any) => new CompanyResult(p));
        return dataset;
    });
};
export const getCountries = async () => {
    return await getData(Countries).then((result) => {
        let dataset = result?.map((p: any) => new CompanyResult(p));
        return dataset;
    });
};
export const getState = async (id: number) => {
    return await getData(States + id).then((result) => {
        let dataset = result?.map((p: any) => new CompanyResult(p));
        return dataset;
    });
};
export const getCities = async (id: number) => {
    return await getData(Cities + id).then((result) => {
        let dataset = result?.map((p: any) => new CompanyResult(p));
        return dataset;
    });
};
export const setCompanyDetails = async (company_details: any) => {
    return await postData(CreateCompany, company_details).then((result) => {
        return result;
    });
};
export const getCompany = async () => {
    return await getData(`/api/company/${getUserDetailsFromCookies().companyId}`).then((result: any) => {
        return result;
    })
}
export function useCompanyService() {
    const { instance, inProgress } = useMsal();
    msalDetails = instance.getActiveAccount();
    const fetcher = { msalDetails };
    return [fetcher];
}
export const getPlanAddons = async () => {
    return await getData(PlanAddons).then((result) => {
        let planaddons_result = result?.map((p: any) => new AddonsResult(p));
        return planaddons_result;
    });
};
export const getSubscription = async () => {
    return await getData(`/${getUserDetailsFromCookies().identifier}/api/subscriptiondetails?Id=${getUserDetailsFromCookies().subscriptionId}`).then((result:any) => {
        console.log("result",result);
        return result;
    })
}
export class AddonsResult {
    addonId?: number;
    addonprice?: number;
    maxUserCount?: number;
    minUserCount?: number;
    addonName?: string;
    addonDescription?: string;
    descriptionInhtml?: string;
    id?: number;
    //addonsIds?: number[]; // specify the type argument for the array
    isAdded?: boolean;
    constructor(data: any) {
        Object.assign(this, {
            addonId: data.addonId,
            maxUserCount: data.maxUserCount,
            addonprice: data.addonprice,
            minUserCount: data.minUserCount,
            addonName: data.addonName,
            addonDescription: data.addonDescription,
            id: data.id,
            //addonsIds: [],
            isAdded: false,
            descriptionInhtml: data.descriptionInhtml,
        });
    }
}
export class CompanyResult {
    id?: number;
    industrySize?: string;
    isActive?: boolean;
    industryType?: string;
    countryName?: string;
    phoneCode?: string;
    name?: string;
    flag?: string;
    cityName?: string;
    stateId?: string;
    constructor(data: any) {
        Object.assign(this, {
            id: data.id,
            isActive: data.isActive,
            industrySize: data.industrySize,
            industryType: data.industryType,
            countryName: data.countryName,
            phoneCode: data.phoneCode,
            name: data.name,
            flag: data.flag,
            cityName: data.cityName,
            stateId: data.stateId,
        });
    }
}
