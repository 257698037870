import {
  React,
  Paper,
  useMemo,
  Tabs,
  Tab,
  useState,
  Box,
  Typography,
  Button,
  Select,
  MenuItem,
  clsx,
  useEffect,
  ListItemIcon,
  FiberManualRecord,
  Rectangle,
  Backdrop,
  CircularProgress,
  useNavigate,
  useLocation,
} from "../../../../app.module";
import { ResponsiveScatterPlot } from "@nivo/scatterplot";
import { useStyle } from "./ScatterPlotStyle";
import {
  getVisualizationData,
  getVisualizationType,
  getPossibalVisualization,
  getScatterPlotVisualizationData,
} from "./ChartsService";
import { Colors, Images } from "../../../constants";

import {
  getUserDetailsFromCookies,
  getQueryIdFromCookies,
} from "../../../services";

interface AxisBottom {
  orient: any;
  tickSize: any;
  tickPadding: any;
  tickRotation: any;
  legend: any;
  legendPosition: any;
  legendOffset: any;
}

const ScatterPlot = React.memo((props: any) => {

  const [scatterPlotJson, setScatterPlotJson] = useState<any>([]);
  const [isLoading, setIsLoading] = useState(true);
  const [activeTab, setActiveTab] = React.useState(0);
  const { classes } = useStyle({ isYaxis: false, isXaxis: true, activeTab });
  const [isScrolling, setIsScrolling] = useState(false);

  const [bottomLegend, setBottomLegend] = useState("");
  const [leftLegend, setLeftLegend] = useState("");


  useEffect(() => {
    let { data } = props.data;
    console.log("ScatterPlot CHART", data);
    setScatterPlotJson(data);
  }, []);

console.log("scatter props",props.data)
  
  useEffect(() => {
    let { data, axis_bottom_legend, axis_left_legend } = props.data;
    
    // Assuming the legends are directly under props.data
    const bottomLegend = axis_bottom_legend;
    const leftLegend = axis_left_legend;
  
    setScatterPlotJson(data); // Set the bumpJson as before
    setBottomLegend(bottomLegend);
    setLeftLegend(leftLegend);
  }, [props.data]);



  console.log("Props.data",props.data)

  let scrollTimeout: NodeJS.Timeout | undefined;

  
  const handleMouseMove = () => {
    setIsScrolling(true);
    if (scrollTimeout) clearTimeout(scrollTimeout);
  };
  
  const handleMouseLeave = () => {
    if (scrollTimeout) clearTimeout(scrollTimeout);
    scrollTimeout = setTimeout(() => setIsScrolling(false), 1000) as NodeJS.Timeout;
  };



  return (
    <div
      key="CHART101"
      style={{
        backgroundColor: Colors?.background.backgroundColorTheme3,
        height: "87vh",
        width: "75%",

        boxShadow: "0px 0px 4px 0px #00000040",
        borderRadius: "10px 0px 0px 10px",
        // overflow: "scroll",
        top: 0,
      }}
    >
      {props?.pageError !== "" ? (
        <div
          key="TAB101"
          style={{
            display: "flex",
            height: "87vh",
            width: "100%",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <img
            style={{
              height: "auto",
              width: "300px",
            }}
            src={Images.no_data_img}
          />
        </div>
      ) : (
        <div
          key="CHART1005"
          style={{
            width: "100%",
            //`${80 * scatterPlotJson?.data?.length}px`,
            height: "75vh",
          }}
        >
          <ResponsiveScatterPlot
            data={scatterPlotJson}
            margin={{ top: 60, right: 140, bottom: 70, left: 90 }}
            xScale={{ type: "linear", min: 0, max: "auto" }}
            xFormat=">-.2f"
            yScale={{ type: "linear", min: 0, max: "auto" }}
            yFormat=">-.2f"
            blendMode="multiply"
            axisTop={null}
            axisRight={null}
            // axisBottom={axisBottom}
            // axisLeft={axisLeft}

            axisBottom={{
              tickSize: 5,
              tickPadding: 5,
              tickRotation: 0,
              legend: bottomLegend,
              legendPosition: 'middle',
              legendOffset: 46
          }}
          axisLeft={{
              tickSize: 5,
              tickPadding: 5,
              tickRotation: 0,
              legend: leftLegend,
              legendPosition: 'middle',
              legendOffset: -70
          }}
          

            // legends={[
            //   {
            //     anchor: "bottom-right",
            //     direction: "column",
            //     justify: false,
            //     translateX: 130,
            //     translateY: 0,
            //     itemWidth: 100,
            //     itemHeight: 12,
            //     itemsSpacing: 5,
            //     itemDirection: "left-to-right",
            //     symbolSize: 12,
            //     symbolShape: "circle",
            //     effects: [
            //       {
            //         on: "hover",
            //         style: {
            //           itemOpacity: 1,
            //         },
            //       },
            //     ],
            //   },
            // ]}
          />
        </div>
      )}
    </div>
  );
});

export default ScatterPlot;
