import React, { useState, ReactNode, FC } from "react";
import { datasrcBot, genericBot } from "./botService";
import { getUserDetailsFromCookies } from "../../services";
import { createClientMessage } from "react-chatbot-kit";

interface ActionProviderProps {
  createChatBotMessage: (message: string, options?: object) => any; // Define a more specific return type if possible
  setState: React.Dispatch<React.SetStateAction<any>>;
  children: ReactNode;
}

const ActionProvider: FC<ActionProviderProps> = ({
  createChatBotMessage,
  setState,
  children,
}) => {
  const [msgoption, setMsgoption] = useState("general");
  const [dbId, setDbId] = useState<number>();
  const [sessioinIdGen, setSessionIdGen] = useState<any>();
  const [sessioinIdDB, setSessionIdDB] = useState<any>();

  const generateRandomSessionId = () => {
    // Generate a random string for the session ID
    const randomString = Math.random().toString(36).substring(2, 15);
    return randomString;
  };

  const selectOption = (option: string) => {
    let message: any;
    const sessionId = generateRandomSessionId();
    if (option === "general") {
      message = createChatBotMessage(
        "You have selected Generic Search. Please ask your question",
        {
          widget: "voice",
          id: 1,
        }
      );
      setSessionIdGen(sessionId);

      //  handleEndOfOptions()
    } else if (option === "datasource") {
      message = createChatBotMessage(
        "Please select the Datasource for answering the question.",
        {
          widget: "datasourceoptions",
        }
      );
      setSessionIdDB(sessionId);
    }
    setMsgoption(option);
    if (message) {
      setState((prev: any) => ({
        ...prev,
        messages: [...prev.messages, message],
      }));
    }
  };

  interface Database {
    name: string;
    id: number;
    // Include other properties of the database objects here
  }
  interface ChatState {
    messages: any[]; // Replace 'any' with a more specific type if possible
    // Include other state properties here if there are any
  }
  const handleDatabaseSelection = async (selectedDatabase: Database) => {
    if (dbId === selectedDatabase.id) {
      // If the selected database is already selected, inform the user
      const message = createChatBotMessage(
        `You have already selected the ${selectedDatabase.name} database.`
      );
      setState((prev: ChatState) => ({
        ...prev,
        messages: [...prev.messages, message],
      }));
    } else {
      // Update the selected database ID and inform the user
      setDbId(selectedDatabase.id);
      try {
        const message = createChatBotMessage(
          `You have selected database: ${selectedDatabase.name}. You can ask questions now.`,
          {
            widget: "voice",
            id: 1,
          }
        );
        setState((prev: ChatState) => ({
          ...prev,
          messages: [...prev.messages, message],
        }));
      } catch (error) {
        console.error("Error handling database selection", error);
        // Optionally handle the error
      }
    }
    //  handleEndOfOptions()
  };

  const fetchData = async (response: string) => {
    console.log("mainresponce", response);
    let message: any;

    if (msgoption === "general") {
      const GenResponse = await GenBot(response);
      console.log("genresponce", GenResponse);

      if (GenResponse && GenResponse.result) {
        message = createChatBotMessage(GenResponse.result);
      } else {
        // Handle the case when GenResponse is null or doesn't have a result
        message = createChatBotMessage(
          "No relevant information found for your query."
        );
      }
    } else if (msgoption === "datasource") {
      const DBResponse = await DBBot(response);
      console.log("dbresponce", DBResponse);

      if (DBResponse && DBResponse.result) {
        message = createChatBotMessage(DBResponse.result);
      } else {
        // Handle the case when DBResponse is null or doesn't have a result
        message = createChatBotMessage(
          "No relevant information found for your query."
        );
      }
    }

    setState((prev: any) => ({
      ...prev,
      messages: [...prev.messages, message],
    }));
  };

  const handleEndOfOptions = () => {
    const message = createChatBotMessage(" ", {
      widget: "voice",
      id: 1,
    });

    setState((prev: any) => ({
      ...prev,
      messages: [...prev.messages, message],
    }));
  };

  const fetchVoiceData = (text: string) => {
    const message = createClientMessage(text, {});

    setState((prev: any) => ({
      ...prev,
      messages: [...prev.messages, message],
    }));

    fetchData(text);

    // Here you can add your API call or any other action you want to perform with the user's message
  };

  const GenBot = async (msg: any) => {
    let GBotData = {
      sessionId: sessioinIdGen,
      userId: getUserDetailsFromCookies().id,
      message: msg,
      messageType: "datasource",
      projectId: getUserDetailsFromCookies().projectId,
    };
    return genericBot(GBotData).then((botdetails: any) => {
      console.log("uppdata", botdetails);
      return botdetails;
    });
  };

  const DBBot = async (msg: any) => {
    let DBBotData = {
      sessionId: sessioinIdDB,
      userId: getUserDetailsFromCookies().id,
      dataSourceId: dbId,
      message: msg,
      messageType: "DataSource",
      projectId: getUserDetailsFromCookies().projectId,
    };
    return datasrcBot(DBBotData).then((botdetails: any) => {
      console.log("uppdata", botdetails);
      return botdetails;
    });
  };

  return (
    <div>
      {React.Children.map(children, (child) => {
        return React.cloneElement(child as React.ReactElement, {
          actions: {
            handleDatabaseSelection,
            fetchData,
            selectOption,
            fetchVoiceData,
          },
        });
      })}
    </div>
  );
};

export default ActionProvider;
