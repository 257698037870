import { BASE_URL } from "../../config/WingsbiConfig";
import { getUserAccessTokenFromCookies } from "../cookie/cookies";
import { refreshTokenBeforeExpire } from "../msal/RefreshToken";
export const putData = async (path: string, data: any) => {
 // await refreshTokenBeforeExpire();
  const response = fetch(BASE_URL + path, {
    method: "PUT",
    headers: {
      "Content-Type": "application/json",
      Authorization: "Bearer " + getUserAccessTokenFromCookies(),
    },
    body: JSON.stringify(data),
  })
    .then((response) => response.json())
    .then((responseJson) => {
      return responseJson;
    })
    .catch((error) => console.log(error)); //to catch the errors if any
  console.log("sdjfjads", response);
  return response;
};
