import { height } from "@mui/system";
import { makeStyles, TextField, styled, Button } from "../../../app.module";
import { Colors, Fonts } from "../../constants";
export const CssOrderTextField = styled(TextField)({
  "& label.Mui-focused": {
    color: "#000",
    borderRadius: "10px",
    border: `1px solid ${Colors?.main}`,
  },
  "& .MuiInput-underline:after": {
    borderBottomColor: "red",
    borderRadius: "10px",
  },

  "& .MuiOutlinedInput-root": {
    "& fieldset": {
      border: `1px solid ${Colors?.main}`,
      borderRadius: "10px",
    },
    "&:hover fieldset": {
      border: `1px solid ${Colors?.main}`,
      borderRadius: "10px",
    },
    "&.Mui-focused fieldset": {
      border: `1px solid ${Colors?.main}`,
      borderRadius: "10px",
    },
  },
});

export const useStyle = makeStyles()((theme?: any) => {
  return {
    OrderContainer: {
      marginLeft: "20px",
      overflow: 'hidden',
    },
    OrderTopLabel: {
      fontFamily: Fonts.NunitoSans.regular,
      fontStyle: "normal",
      fontWeight: "700",
      fontSize: "28px",
      lineHeight: "38px",
      color: Colors?.palette.label.main,
      marginBottom: "18px",
    },
    planHeading: {
      height: "auto",
      width: "15%",
      position: "fixed",
      marginTop: "10px",
      marginLeft: "50px",
      backgroundColor: "white",
      borderRadius: "20px",
      //marginBottom: "20px",
    },
    OrderBoxContainer: {
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
    },
    OrderSummaryBoxContainer: {
      display: "flex",
      flexDirection: "column",
      justifyContent: "center",
      alignItems: "flex-start",
      marginTop: "70px",
      marginLeft: "60px",
      marginRight: "60px",
      overflow: 'hidden',
    },
    OrderSummaryTermsContainer: {
      marginTop: "40px",
      display: "flex",
      flexDirection: "row",
      justifyContent: "space-between",
      alignItems: "center",
      marginRight: "60px",
    },
    OrderSummaryBtnContainer: {
      marginTop: "80px",
      display: "flex",
      flexDirection: "row",
      justifyContent: "flex-end",
      alignItems: "center",
      marginLeft: "20px",
      marginRight: "10px",
    },
    OrderSummaryTermsCheckContainer: {
      display: "flex",
      flexDirection: "row",
      justifyContent: "center",
      alignItems: "center",
    },
    OrderSummaryLeftContainer: {
      display: "flex",
      flex: 1,
      justifyContent: "space-between",
      flexDirection: "row",
    },

    PlanAddonsBoxContainer: {
      display: "flex",
      flexDirection: "column",
      justifyContent: "center",
      alignItems: "flex-start",
      marginTop: "60px",
      marginLeft: "60px",
      marginRight: "60px",
    },
    planTopLabel: {
      fontFamily: Fonts.NunitoSans.regular,
      fontStyle: "normal",
      fontWeight: "700",
      fontSize: "28px",
      lineHeight: "38px",
      color: Colors?.palette.label.main,
      marginBottom: "20px",
    },
    AddonsContainer: {
      display: "flex",
      justifyContent: "space-between",
      alignItems: "flex-start",
      marginBottom: "30px",
      flexDirection: "row",
      boxShadow: "none",
      border: `1px solid ${Colors?.border.plan.borderColor}`,
      borderRadius: 17,
      width:"85%",
    },
    PlanAddonsDetails: {
      display: "flex",
      alignItems: "flex-start",
      cursor: "pointer",
      flexDirection: "column",
    },
    planAddonsTitlel: {
      fontFamily: Fonts.NunitoSans.regular,
      fontStyle: "normal",
      fontWeight: "700",
      fontSize: "20px",
      lineHeight: "27px",
      textAlign: "left",
      color: Colors?.palette.label.main,
      marginLeft: "20px",
      width: "200px",
    },
    planAddonsDirection: {
      flexDirection: "row",
      alignItems: "center",
      justifyContent: "flex-start",
      marginLeft: "20px",
      width: "150px",
    },
    planAddonsDetailsCollaps: {
      fontFamily: Fonts.Inter.regular,
      fontStyle: "normal",
      fontWeight: "600",
      fontSize: "10px",
      lineHeight: "12px",
      color: Colors?.border.plan.details,
    },
    CollapsContent: {
      padding: theme.spacing(2),
    },
    planAddonsDescription: {
      fontFamily: Fonts.Inter.regular,
      fontStyle: "normal",
      fontWeight: "400",
      fontSize: "16px",
      lineHeight: "19px",
      textAlign: "left",
      marginLeft: "2px",
      color: Colors?.palette.darkTitle.theme1,
    },
    planAddonsPrice: {
      fontFamily: Fonts.NunitoSans.regular,
      fontStyle: "normal",
      fontWeight: "700",
      fontSize: "32px",
      lineHeight: "38px",
      color: Colors?.palette.label.main,
      textAlign: "left",
      marginLeft:"-45%",
    },
    CardContentWithoutPadding: {
      padding: 0,
      "&:last-child": {
        paddingBottom: 0,
      },
    },
    btnPlanAddonLabelStyle: {
      fontFamily: Fonts.NunitoSans.regular,
      fontStyle: "normal",
      fontWeight: 700,
      fontSize: "18px",
      lineHeight: "25px",
      borderRadius: "10px",
      background:
        "linear-gradient(90deg, #5EB0FA -8.73%, #1157CD 51.67%, #9C90FA 110.24%)",
      color: "#fff",
      textTransform: "capitalize",
      height: "50px",
      width: "185px",
    },
    
    OrderTextFiledLabelContainer: {
      display: "flex",
      flex: 1,
      flexDirection: "column",
      marginRight: "20px",
      borderRadius: "10px",
      overflow: "auto", // if Textfield value selected automatically or selected from predection then we will apply style to its parent div
    },
    OrderPaddingLeft: {
      paddingLeft: "30px",
      paddingTop: "30px",
      borderLeftColor: Colors?.border?.plan.borderColor,
    },
    OrderHeaderContainer: {
      display: "flex",
      justifyContent: "space-between",
      alignItems: "center",
      flexDirection: "row",
      boxShadow: "none",
      borderRadius: "0px",
      borderBottomColor: Colors?.border?.plan.borderColor,
      paddingBottom: "5px",
    },
    OrderHeaderRightContainer: {
      display: "flex",
      justifyContent: "space-between",
      alignItems: "center",
      flexDirection: "row",
      boxShadow: "none",
      borderRadius: "0px",
      borderLeftColor: Colors?.border?.plan.borderColor,
      paddingLeft: "30px",
      paddingTop: "10px",
    },
    OrderCouponContainer: {
      display: "flex",
      justifyContent: "space-between",
      alignItems: "center",
      flexDirection: "row",
      boxShadow: "none",
      borderRadius: "0px",
      borderLeftColor: Colors?.border?.plan.borderColor,
      paddingLeft: "30px",
    },
    OrderListIteamContainer: {
      display: "flex",
      justifyContent: "space-between",
      alignItems: "center",
      flexDirection: "row",
      boxShadow: "none",
      borderRadius: "0px",
      borderBottomColor: Colors?.border?.plan.borderColor,
      marginTop: "10px",
    },
    BottomBorderStyle: {
      marginBottom: "10px",
      paddingBottom: "10px",
    },
    OrderHeaderTitlel: {
      fontFamily: Fonts.NunitoSans.regular,
      fontStyle: "normal",
      fontWeight: "700",
      fontSize: "16px",
      lineHeight: "27px",
      textAlign: "left",
      color: Colors?.palette.label.main,
    },
    FeatureTitlel: {
      fontFamily: Fonts.NunitoSans.regular,
      fontStyle: "normal",
      fontWeight: "700",
      fontSize: "20px",
      lineHeight: "27px",
      textAlign: "left",
      color: Colors?.title.light,
    },
    FeatureDescriptionTitlel: {
      fontFamily: Fonts.Inter.regular,
      fontStyle: "normal",
      fontWeight: "400",
      fontSize: "16px",
      lineHeight: "19px",
      textAlign: "left",
      color: Colors?.palette.label.main,
    },

    BillingTitle: {
      color: Colors?.palette.label.main,
    },
    ApplyCouponHeaderTitlel: {
      fontFamily: Fonts.NunitoSans.regular,
      fontStyle: "normal",
      fontWeight: "700",
      fontSize: "22px",
      lineHeight: "32px",
      textAlign: "left",
      color: Colors?.palette.label.main,
    },
    GstTitle: {
      fontFamily: Fonts.NunitoSans.regular,
      fontStyle: "normal",
      fontWeight: "700",
      fontSize: "12px",
      lineHeight: "16px",
      textAlign: "left",
      color: Colors?.title.light,
      marginLeft: "2px",
    },
    ApplyCouponDiscountTitlel: {
      fontFamily: Fonts.NunitoSans.regular,
      fontStyle: "normal",
      fontWeight: "700",
      fontSize: "12px",
      lineHeight: "16px",
      textAlign: "left",
      color: Colors?.main,
    },

    CartItems: {
      color: Colors?.title.light,
      fontSize: "18px",
    },

    ItemHeaderWidth: {
      width: "300px",
    },
    PriceHeader: {
      marginRight: "110px",
    },

    PlanTypeColor: {
      height: "20px",
      width: "20px",
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      borderRadius: "10px",
      marginRight: "10px",
      backgroundColor: Colors?.palette.success.main,
    },
    OrderSummaryPlanTitle: {
      fontFamily: Fonts.NunitoSans.regular,
      fontStyle: "normal",
      fontWeight: "700",
      fontSize: "18px",
      lineHeight: "23px",
      textAlign: "left",
      color: Colors?.palette.label.main,
      opacity: 0.9,
    },
    OrderSummaryPlanTitleFont: {
      fontSize: "16px",
    },
    OrderSummaryPlanNameFont: {
      fontSize: "20px",
    },
    OrderSummaryPlanDescriptionTitle: {
      fontFamily: Fonts.NunitoSans.regular,
      fontStyle: "normal",
      fontWeight: "700",
      fontSize: "14px",
      lineHeight: "17px",
      textAlign: "left",
      color: Colors?.palette.label.main,
    },
    OrderSummaryCardContent: {
      padding: 0,
      "&:last-child": {
        paddingBottom: 0,
      },
    },
    OrderSummaryCardContentCenter: {
      padding: 0,
      "&:last-child": {
        paddingBottom: 0,
        marginRight: "30px",
      },
      width: "150px",
    },
    OrderSummaryCardContentCenterCount: {
      padding: 10,
      "&:last-child": {
        paddingBottom: 0,
        marginRight: "30px",
      },
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      backgroundColor: Colors?.background.backgroundColorTheme1,
      borderRadius: "10px",
      border: `1px solid ${Colors?.border.plan.borderColor}`,
      minWidth: "60px",
    },

    OrderSummaryCardContentRight: {
      padding: 0,
      "&:last-child": {
        paddingBottom: 0,
      },
      width: "145px",
    },
    // orderSummaryContainer: {
    //   height: 'calc(100vh - 100px)',
    //   overflowY: 'auto',
    //   // '&::-webkit-scrollbar': {
    //   //   display: 'none',
    //   // },
    //   // scrollbarWidth: 'none', // Firefox
    //   // '-ms-overflow-style': 'none', // IE and Edge
    // },
    orderSummaryContainer: {
      height: 'calc(100vh - 100px)',
      overflowY: 'auto',
      '&::-webkit-scrollbar': {
        width: '6px',  // Adjust width based on your design
        backgroundColor: 'transparent',  // Initially transparent
      },
      '&::-webkit-scrollbar-thumb': {
        backgroundColor: 'transparent',  // Initially transparent
      },
      // '&:hover': {
      //   '&::-webkit-scrollbar-thumb': {
      //     backgroundColor: '#c1c1c1',  // Visible color on hover
      //   }
      // },
      scrollbarWidth: 'thin',  // For Firefox
      scrollbarColor: 'transparent transparent',  // For Firefox, initially transparent
      '&:hover': {
        scrollbarColor: '#c1c1c1 transparent'  // Color on hover for Firefox
      },
      '-ms-overflow-style': 'auto',  // Standard scrolling for IE and Edge
    },    
    cartSummaryContainer: {
      position: 'sticky',
      top: 0,
      height: 'fit-content',
    },
    CouponCardContentHeader: {
      padding: 0,
      "&:last-child": {
        paddingBottom: 0,
      },
      width: "145px",
      marginLeft: "30px",
    },
    OrderSummaryCardContentLeftItems: {
      padding: 0,
      "&:last-child": {
        paddingBottom: 0,
      },
      display: "flex",
      flexDirection: "row",
      justifyContent: "flex-start",
      alignItems: "center",
      //paddingTop: "15px",
      width: "310px",
    },

    OrderAddonsDetailsCollaps: {
      fontFamily: Fonts.Inter.regular,
      fontStyle: "normal",
      fontWeight: "600",
      fontSize: "12px",
      lineHeight: "16px",
      color: Colors?.border.plan.details,
    },
    OrderAddonsDetails: {
      display: "flex",
      alignItems: "flex-start",
      cursor: "pointer",
      flexDirection: "column",
    },
    OrderAddonsDirection: {
      flexDirection: "row",
      alignItems: "center",
      justifyContent: "flex-start",
      width: "150px",
      marginLeft: "30px",
    },
    btnOrderLabelStyle: {
      fontFamily: Fonts.NunitoSans.regular,
      fontStyle: "normal",
      fontWeight: 700,
      fontSize: "18px",
      lineHeight: "25px",
      borderRadius: "10px",
      marginBottom: "20px",
      background:
        "linear-gradient(90deg, #5EB0FA -8.73%, #1157CD 51.67%, #9C90FA 110.24%)",
      textTransform: "initial",
      height: "50px",
      width: "180px",
    },
    disabledButton:{
    background: "#ecedf2 ", 
    cursor: "not-allowed", 
    },
    
    
    userProfileContainer: {
      display: "flex",
      //backgroundColor: "pink",
      justifyContent: "flex-end",
      alignItems:"flex-end",

      marginLeft: "80.80%",
      // marginTop: "1%",
    },
    userProfileImg: {
      height: "36px",
      width: "36px",
      display: "flex",
      justifyContent: "center",
      marginLeft: "40px",
      alignItems: "right",
      borderRadius: "18px",
      marginRight: "9px",
      marginBottom:"3px",
      background: "linear-gradient(202.64deg, #E24A95 21.95%, #4A2BA3 92.07%)",
    },
    asterix:{
      color:"red"
    },
    userProfileTxt:{
     
      fontSize:"18px",
      fontFamily: Fonts.NunitoSans.regular,
      marginTop:"5px"
      
    },
    userNameStyle: {
      fontFamily: Fonts.NunitoSans.regular,
      fontStyle: "normal",
      fontWeight: "700",
      fontSize: "17px",
      lineHeight: "26px",
      textAlign: "left",
      // marginLeft: "15px",
      // marginTop: "5px",
      color: Colors?.palette.label.main,
    },
    userRoleTitle: {
      fontFamily: Fonts.Inter.regular,
      fontStyle: "normal",
      fontWeight: "400",
      fontSize: "14px",
      lineHeight: "17px",
      // marginLeft: "15px",
      // marginTop: "5px",
      color: Colors?.palette.label.main,
    },
    userProfileDetailsCollaps: {
      fontFamily: Fonts.Inter.regular,
      fontStyle: "normal",
      fontWeight: "600",
      fontSize: "20px",
      lineHeight: "16px",
      color: Colors?.palette.label.main,
    },
    logo_name: {
      position: "fixed",
      marginLeft: "50px",
    },
    btnInactiveOrderLabelStyle: {
      fontFamily: Fonts.NunitoSans.regular,
      fontStyle: "normal",
      fontWeight: 700,
      fontSize: "18px",
      lineHeight: "21px",
      background: Colors?.palette.lightbg.theme1,
      color: "#000",
      textTransform: "capitalize",
      height: "50px",
      width: "180px",
      borderRadius: "10px",
      border: `1px solid ${Colors?.border.plan.borderColor}`,
      "&:hover": {
        background: Colors?.palette.lightbg.theme1,
      },
      "&:focus": {
        background: Colors?.palette.lightbg.theme1,
      },
      marginRight: "10px",
      marginBottom: "20px",
    },
      btnApplyCoupon: {
        fontFamily: Fonts.NunitoSans.regular,
        fontStyle: "normal",
        fontWeight: 700,
        fontSize: "18px",
        lineHeight: "25px",
        borderRadius: "10px",
        textTransform: "capitalize",
        height: "50px",
        width: "140px",
        // "&:hover": {
        //   backgroundColor: "#b3cbcb",
        // },
      },
    removeCouponButton:{
      fontFamily:Fonts.NunitoSans.regular,
      color:"red",
      border:"none",
      // cursor: "pointer",
    },
    removeCouponButtonHover:{
      backgroundColor:"#fff",
      opacity:0,
    },
    btnApplyCouponInactive: {
      fontFamily: Fonts.NunitoSans.regular,
      fontStyle: "normal",
      fontWeight: 700,
      fontSize: "18px",
      lineHeight: "25px",
      borderRadius: "10px",
      textTransform: "capitalize",
      height: "50px",
      width: "140px",
      background: "#fff",
      color: "",

    },
    appliedStatus: {
      fontFamily: Fonts.Inter.regular,
      fontStyle: "normal",
      fontWeight: "400",
      fontSize: "12px",
      lineHeight: "19px",
      color: Colors?.palette.success.main,
    },

    OrderInput: {
      backgroundColor: "#f9f9f9",
      fontFamily: Fonts.NunitoSans.regular,
      fontStyle: "normal",
      fontWeight: "400",
      fontSize: "18px",
      lineHeight: "21px",
      borderRadius: "10px", // border before focusing the text field

      height: "auto",
      color: "#000000",
      "&::placeholder": {
        color: "#ADADAD",
      },
    },

    appBarStyle: {
      boxShadow: "none",
      backgroundColor: Colors?.background.backgroundColorTheme2,
      height: "65px",
      //zIndex: theme.zIndex.drawer + 1, // add a higher zIndex than the Drawer
      //borderRadius: "40px 0px 0px 0px",
      //margin: 0,
      // Colors?.background.backgroundColorTheme2
    },
    toolBarStyle: {
      boxShadow: "none",
      backgroundColor: "#fff",
      display: "flex",
      //flexDirection: "column",
      //justifyContent: "space-between",
      flex: 1,
      //Colors?.background.backgroundColorTheme3,

      height: "90px",
      borderRadius: "20px 0px 0px 0px",
      //margin: 0,
    },

    drawerPaper: {
      width: "150px",
      border: "0px solid #A0A8B5",
      backgroundColor: Colors?.background.backgroundColorTheme2, // set your desired background color here
    },
    logoContainer: {
      justifyContent: "center",
      alignItems: "center",
      display: "flex",
      marginTop: "0px",
    },
    header: {
      // position:"fixed",
      // backgroundColor:"#F2F5FD",
      height: "70px",
      width: "1350px",
      transition: " top 0.2s ease-in-out",
      boxShadow: "0 10px 10px rgba(0, 0, 0, 0.12)",
      // zIndex: "10" ,
      background: "#F2F5FD",
    },
    listItemTextContainer: {
      fontFamily: Fonts.NunitoSans.regular,
      fontStyle: "normal",
      fontWeight: "600",
      fontSize: "16px",
      lineHeight: "22px",
      color: "#A0A8B5",
    },
    drawerTitleColor: {
      color: "#000000",
    },
    listItemContainer: {
      display: "flex",
      padding: "15px",
      paddingLeft: "25px",
      //border: "1px solid #A0A8B5",
      // borderRadius: "55px 0px 0px 55px",
    },
    menuIcons: {
      height: "20px",
      width: "20px",
      marginRight: "5px",
    },
    logoCloseStyle: {
      // height: "50px",
      // width: "70px",
      // height: "50px",
      // width: "60px"
      objectFit: "contain",
      marginTop: "1px",
      height: "50px",
      width: "60px",
    },
    modalOverlay: {
      backgroundColor: "rgba(0, 0, 0, 0)", // Completely transparent overlay
      zIndex: theme.zIndex.modal + 1, // Ensure the modal is above other elements
    },
    userActionTitle: {
      fontFamily: Fonts.Inter.regular,
      fontStyle: "normal",
      fontWeight: "400",
      fontSize: "16px",
      lineHeight: "19px",
      textTransform: "initial",
      color: Colors?.palette.label.main,
      padding: 5,
    },
    logoTxtStyle: {
      marginTop: "5px",
      fontFamily: Fonts.Inter.regular,
      fontSize: "22px",
      color: "#1F2354",
      fontWeight: "bolder",
      //textAlign: "center",
      //marginTop: "20px",
      cursor: "pointer",
      textTransform: "initial",
    },
    logoBtnStyle: {
      "&:hover": {
        backgroundColor: "transparent", // Set the hover background color to transparent
      },
    },
    dasboardContainer: {
      backgroundColor: "#fff", // Scrollable view background color
      margin: "10px 20px 10px 20px", // top, right, bottom, left
      //margin: "10px 20px 20px 20px", // top, right, bottom, left
      borderRadius: "10px",
      //Colors?.background.backgroundColorTheme3,
      padding: 0,
      //Colors?.background.backgroundColorTheme3,
    },
  };
});

export const StyledOrderButton = styled(Button)({
  color: "#fff",
  padding: "6px 12px",
});
