import {
  Typography,
  CardActions,
  Button,
  useLocation,
  useContext,
  useNavigate,
  loginRequest,
  useEffect,
  Backdrop,
  useState,
  CircularProgress,
  Cookies,
} from "../../../app.module";
import { getUserDetails } from "./MarketingService";
import { MarketingStyle } from "./MarketingStyle";
import { ThemeContext } from "../../context/ThemeContext";
import { Images } from "../../constants";
import clsx from "clsx";
import { useMsal } from "@azure/msal-react";
const Marketing = (props: any) => {
  const { instance, inProgress } = useMsal();
  const { theme, setTheme } = useContext(ThemeContext);
  const [isLoading, setIsLoading] = useState(false);
  const location = useLocation();
  const navigate = useNavigate();
  const { classes } = MarketingStyle();
  const handleLogout = () => {
    const token = instance.logoutRedirect();
  };
  const handleSignupRedirect = async () => {
    Cookies.remove("user_details");
    //await instance.loginRedirect(loginRequest);
    //REACT_APP_B2C_REDIRECT_URI=http://localhost:4200
    instance
      .loginRedirect({
        scopes: [
          "openid",
          "profile",
          process.env.REACT_APP_B2C_SCOPES as string,
        ],
        authority:
        process.env.REACT_APP_SIGNUP_B2C_AUTHORITY_LINK as string,
      })
      .catch((error) => console.log(error));
  };
  const handleLoginRedirect = async () => {
    Cookies.remove("user_details");
    instance
      .loginRedirect({
        scopes: [
          "openid",
          "profile",
         process.env.REACT_APP_B2C_SCOPES as string,
        ],

        authority:
          process.env.REACT_APP_B2C_AUTHORITY_LINK as string,
      })
      .then((response) => {
        console.log("Wait for response", response);
      })
      .catch((error) => console.log(error));
  };
  const getAccountdetails = () => {
    console.log("ACcount details", instance.getActiveAccount());
  };

  const handleMsalProcess = () => {
    if (!inProgress) {
      // Start the login process only if there is no operation in progress
      try {
        console.log("MSAL user can Login");
        // Handle the response
      } catch (error) {
        console.log("Error while login", error);
        // Handle errors
      }
    } else {
      console.log("Error while inProgress", inProgress);
      // An authentication operation is already in progress, handle accordingly
    }
  };

  const setApplicationTheme = () => {
    //palette.primary.main = "#000";
    // useStyle().theme.palette.primary.main = "#983432";
    //useTheme().palette.mode = "light";
    let tdaya = theme;
    //tdaya.palette.primary.main = "red";
    setTheme(tdaya);
  };
  const handleClose = () => {
    setIsLoading(false);
  };
  return (
    <div>
      <Backdrop
        className={classes.marketingCicularContainer}
        open={isLoading}
        onClick={handleClose}
      >
        <CircularProgress className={classes.marketingCicularView} />
      </Backdrop>
      <div className={classes.marketingHeader}>
        <div>
          {location.pathname === "/" && (
            <div className={classes.marketingBar}></div>
          )}
          <div className={classes.marketingMargin}>
            <Typography className={classes.marketingMenuStyle}>HOME</Typography>
          </div>
        </div>
        {/* <div>
          {location.pathname === "contactus" && (
            <div className={clsx(classes.barWidth, classes.marketingBar)}></div>
          )}
          <Link style={{ textDecoration: "none" }} to="contactus">
            <Typography className={classes.marketingContactustyle}>
              CONTACT US
            </Typography>
          </Link>
        </div> */}
      </div>
      <div className={classes.marketingContainer}>
        <div className={classes.marketingMargin}>
          <div className={clsx(classes.direction, classes.container)}>
            <div className={classes.container}>
              <img
                className={classes.marketingHeading}
                alt="Header Wingsbi logo"
                src={Images.marketing_logo_name}
              />
              {/* <Typography className={classes.wingsStyle}>WingsBI</Typography> */}
            </div>
            <Typography className={classes.marketingTitleStyle}>
              Get Power of AI for your Business
            </Typography>
          </div>
          <div className={clsx(classes.container)}>
            <CardActions>
              <Button
                onClick={() => {
                  //handleLogout();
                  handleLoginRedirect();
                  //handleMsalProcess();
                  //getAccountdetails();
                }}
                variant="contained"
                style={{
                  background:
                    "linear-gradient(90deg, #5EB0FA 0%, #1157CD 50.54%, #9C90FA 100%)",
                  color: "#fff",
                  textTransform: "capitalize",
                  height: "50px",
                  width: "150px",
                }}
              >
                Log in
              </Button>
            </CardActions>
            <CardActions>
              <Button
                style={{
                  background:
                    "linear-gradient(90deg, #5EB0FA 0%, #1157CD 50.54%, #9C90FA 100%)",
                  color: "#fff",
                  textTransform: "capitalize",
                  height: "50px",
                  width: "150px",
                }}
                onClick={() => {
                  handleSignupRedirect();
                  //handleLogout();
                }}
                variant="contained"
              >
                Sign up
              </Button>
            </CardActions>
          </div>
        </div>
        <div className={classes.rightImg}>
          <img
            className="img-fluid"
            style={{ height: "350px" }}
            alt="Header Wingsbi logo"
            src={Images.marketing_right_img}
          />
        </div>
      </div>
      {/* <div className={clsx(classes.direction, classes.vcontainer)}>
        <div style={{ marginTop: "30px" }}>
          <Typography className={classes.aiTitleStyle}>
            How AI is helping data visualization
          </Typography>
        </div>
        <div className={classes.videoDiv}>
          <iframe
            className={classes.iframeDiv}
            //https://www.youtube.com/watch?v=i4p_Z2aTG9w
            //https://youtu.be/sMi3JLUhAIc
            src="https://www.youtube.com/embed/sMi3JLUhAIc"
            //width={1000}
            //height={500}
            title="A youtube video on React hooks"
          ></iframe>
        </div>
      </div> */}
    </div>
  );
};

export default Marketing;
