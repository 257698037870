import React, { useState, useEffect } from 'react';
import { dogetdatabase } from '../Main_Project/datasource/database/DatabaseService';

interface DataSourceOptionsComponentProps {
    selectOption?: (option: string) => void;
    handleDatabaseSelection: (db: any) => void;
}

const DataSourceOptionsComponent: React.FC<DataSourceOptionsComponentProps> = ({ handleDatabaseSelection }) => {
    const [dbList, setDbList] = useState<any[]>([]);
    const [selectedDbId, setSelectedDbId] = useState<number | null>(null);
    const [hoverIndex, setHoverIndex] = useState<number | null>(null);

    useEffect(() => {
        dogetdatabase().then((database: any) => {
            setDbList(database);
        });
    }, []);

    const buttonHeight = 55; // Height of one button including space
    const maxVisibleButtons = 3; // Number of buttons visible before scrolling
    const containerBaseHeight = buttonHeight * maxVisibleButtons;

    // Calculate the dynamic height
    const containerHeight = Math.min(dbList.length * buttonHeight, containerBaseHeight);

    const containerStyle: React.CSSProperties = {
        display: 'grid',
        gridTemplateColumns: 'repeat(3, 1fr)', // Creates 3 columns of equal width
        gap: '18px',
        padding: '5px',
        width: '40%', // The container takes up the full width
        gridAutoRows: 'minmax(50px, auto)', // Specifies the minimum and maximum height of the rows
        marginLeft: '4%',
        marginBottom: '10px', // Adds a margin at the bottom of the container
    };


    const defaultButtonStyle: React.CSSProperties = {
        padding: '4px', // Adjust padding to reduce size
        borderRadius: '15px', // Adjust border radius to make it less round
        color: '#ffffff',
        fontSize: '14px', // Adjust font size to make it smaller
        cursor: 'pointer',
        height: '40px', // Adjust height to reduce overall size
        border: 'none',
        background: '#5B2EA1',
        boxShadow: '0px 2px 5px rgba(0, 0, 0, 0.2)',
        transition: 'transform 0.2s ease-in-out',
        whiteSpace: 'nowrap',
        overflow: 'hidden',
        textOverflow: 'ellipsis',
    };


    const hoverButtonStyle: React.CSSProperties = {
        ...defaultButtonStyle,
        transform: 'scale(1.05)',
    };

    const disabledButtonStyle: React.CSSProperties = {
        ...defaultButtonStyle,
        opacity: 0.2,
        cursor: 'not-allowed',
        background: '#grey',
    };

    const selectedButtonStyle: React.CSSProperties = {
        ...defaultButtonStyle,
        background: `#5B2EA1`,
        transform: 'scale(1.1)',
    };

    const handleButtonClick = (db: any) => {
        if (selectedDbId === db.id) {
        } else {
            setSelectedDbId(db.id);
            handleDatabaseSelection(db);
        }
    };

    const getButtonStyle = (db: any, index: number): React.CSSProperties => {
        if (db.id === selectedDbId) {
            return selectedButtonStyle;
        } else if (hoverIndex === index) {
            return hoverButtonStyle;
        } else if (selectedDbId !== null) {
            return disabledButtonStyle;
        }
        return defaultButtonStyle;
    };

    return (

        <div style={containerStyle}>
            {dbList.map((db, index) => (
                <button
                    style={getButtonStyle(db, index)}
                    key={index}
                    onMouseEnter={() => setHoverIndex(index)}
                    onMouseLeave={() => setHoverIndex(null)}
                    onClick={() => handleButtonClick(db)}
                    // disabled={selectedDbId !== null && db.id !== selectedDbId}
                    disabled={selectedDbId !== null && selectedDbId !== db.id}
                >
                    {db.name}
                </button>
            ))}
        </div>



    );
};

export default DataSourceOptionsComponent;
