import {
  Typography,
  Grid,
  Card,
  CardContent,
  clsx,
  useLocation,
} from "../../../app.module";

import { useStyle } from "./OrderSummaryStyle";

import { Colors } from "../../constants";

const OrderSummaryCart = ( orderDetails:any) => {
  const { classes } = useStyle();
 
  console.log("CartOrderdetails",orderDetails)

 let totalAmountForSubscription = orderDetails["orderDetails"]["totalAmountForSubscription"]
let totalAmountForAddOns = orderDetails["orderDetails"]["totalAmountForAddOns"]
let totalAmountBeforeTax = orderDetails["orderDetails"]["totalAmountBeforeTax"]
let totalTax = orderDetails["orderDetails"]["totalTax"]
let totalAmountPayable= orderDetails["orderDetails"]["totalAmountPayable"]
let couponCodeDescription = orderDetails["orderDetails"]["couponCodeDescription"]

  return (
    <div>
      <Grid
        sx={{
          borderLeft: 1,
        }}
        className={classes.OrderPaddingLeft}
        item
      >
        <Typography className={classes.ApplyCouponHeaderTitlel}>
          {"Cart summary"}
        </Typography>
        <Card className={classes.OrderListIteamContainer}>
          <CardContent className={classes.OrderSummaryCardContentCenter}>
            <Typography
              className={clsx(
                classes.ApplyCouponHeaderTitlel,
                classes.CartItems
              )}
            >
              {"Subscription Total"}
            </Typography>
          </CardContent>
          <CardContent className={classes.OrderSummaryCardContentRight}>
            <div style={{ display: "flex" }}>
              <Typography className={classes.ApplyCouponHeaderTitlel}>
                ₹{" "}
                {totalAmountForSubscription}
              </Typography>
            </div>
          </CardContent>
        </Card>
        <Card
          style={{
            marginBottom: "10px",
          }}
          className={classes.OrderListIteamContainer}
        >
          <CardContent className={classes.OrderSummaryCardContentCenter}>
            <Typography
              className={clsx(
                classes.ApplyCouponHeaderTitlel,
                classes.CartItems
              )}
            >
              {"Add-ons total"}
            </Typography>
          </CardContent>
          <CardContent className={classes.OrderSummaryCardContentRight}>
            <Typography className={classes.ApplyCouponHeaderTitlel}>
              ₹ {totalAmountForAddOns}
            </Typography>
          </CardContent>
        </Card>
        <Card className={classes.OrderListIteamContainer}>
          <CardContent className={classes.OrderSummaryCardContentCenter}>
            <Typography
              className={clsx(
                classes.ApplyCouponHeaderTitlel,
                classes.CartItems
              )}
            >
              {"Total Amount"}
            </Typography>

            <Typography
              style={{ marginLeft: "3px" }}
              className={classes.ApplyCouponDiscountTitlel}
            >
              <span style={{ color: "#00CB52" }}>
                {orderDetails?.couponCodeDescription ? "Discount" : ""}
              </span>
            </Typography>
          </CardContent>
          <CardContent className={classes.OrderSummaryCardContentRight}>
            <div style={{ display: "flex" }}>
              <Typography className={classes.ApplyCouponHeaderTitlel}>
                ₹ {totalAmountBeforeTax}
              </Typography>

              {orderDetails?.discount ? (
                <Typography
                  style={{
                    marginLeft: "5px",
                    color: Colors?.title.light,
                    fontSize: "18px",
                    textDecorationLine: "line-through",
                  }}
                  className={classes.ApplyCouponHeaderTitlel}
                >
                  {orderDetails?.totalAmount}
                </Typography>
              ) : (
                ""
              )}
            </div>
            <Typography
              style={{ marginLeft: "2px" }}
              className={classes.ApplyCouponDiscountTitlel}
            >
              <span style={{ color: "#00CB52" }}>
                {/* {orderDetails?.couponCodeDescription
                  ? "- ₹ " + orderDetails?.discount
                  : ""} */}
                  {couponCodeDescription}
              </span>
            </Typography>
          </CardContent>
        </Card>

        <Card
          sx={{
            borderBottom: 1,
          }}
          className={clsx(
            classes.OrderListIteamContainer,
            classes.BottomBorderStyle
          )}
        >
          <CardContent
            style={{
              display: "flex",
              flexDirection: "row",
              justifyContent: "flex-start",
              alignItems: "center",
            }}
            className={classes.OrderSummaryCardContentCenter}
          >
            <Typography
              className={clsx(
                classes.ApplyCouponHeaderTitlel,
                classes.CartItems
              )}
            >
              {"Tax "}
            </Typography>
            <Typography className={clsx(classes.GstTitle)}>
              {"(18% GST)"}
            </Typography>
          </CardContent>
          <CardContent className={classes.OrderSummaryCardContentRight}>
            <Typography className={classes.ApplyCouponHeaderTitlel}>
              ₹ {totalTax}
            </Typography>
          </CardContent>
        </Card>

        <Card className={classes.OrderListIteamContainer}>
          <CardContent className={classes.OrderSummaryCardContentCenter}>
            <Typography
              className={clsx(
                classes.ApplyCouponHeaderTitlel,
                classes.CartItems
              )}
            >
              {"Grand total"}
            </Typography>
          </CardContent>
          <CardContent className={classes.OrderSummaryCardContentRight}>
            <Typography className={classes.ApplyCouponHeaderTitlel}>
              ₹ {totalAmountPayable}
            </Typography>
          </CardContent>
        </Card>
      </Grid>
    </div>
  );
};
export default OrderSummaryCart;
