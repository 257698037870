import {
  TextField,
  Select,
  styled,
  Button,
  makeStyles,
} from "../../../app.module";

import { AppThemes, Fonts, Colors } from "../../constants";
export const StyledButton = styled(Button)({
  backgroundColor: "#fff",
  color: "#fff",
  padding: "6px 12px",
  "&:hover": {
    backgroundColor: "#fff",
  },
  // "&:focus": {
  //   backgroundColor: "green",
  // },
});
export const useStyles = makeStyles()((theme?: any) => {
  return {
    guidContainer: {
      width: "500px",
      background: "white",
      border: "1px solid #ccc",
      // boxShadow: "-2rem 2rem 2rem rgba(black, 0.2)",
      filter: "blur(0)",
      transform: "scale(1)",
      opacity: "1",
      visibility: "visible",
    },
    btnGuideNewProjectStyle: {
      display: "flex",
      flexDirection: "row",
      height: "50px",
      width: "185px",
      justifyContent: "center",
      alignItems: "center",
    },
    boxContainer: {
      position: "absolute" as "absolute",
      top: "20%",
      left: "50%",
      transform: "translate(-50%, -50%)",
      width: "100%",
      //bgcolor: "background.paper",
      //border: "2px solid #000",
      boxShadow: "24px",
      p: 4,
      padding: "50px",
    },
    boxContainer02: {
      position: "absolute" as "absolute",
      top: "40%",
      left: "60%",
      transform: "translate(-50%, -50%)",
      width: "100%",
      //bgcolor: "background.paper",
      //border: "2px solid #000",
      boxShadow: "24px",
      p: 4,
      padding: "50px",
    },
    boxNoteContainer: {
      position: "absolute" as "absolute",
      // top: "50%",
      left: "50%",
      bottom: "25px",
      transform: "translate(-50%, -50%)",
      width: "100%",
      //background: "#fff",
      //border: "2px solid #000",
      boxShadow: "24px",

      p: 4,
    },
    btnGuideContainer: {
      display: "flex",
      //flex: 1,
      justifyContent: "center",
      marginRight: "18%",
    },
    labelGuideLabelStyle: {
      fontFamily: Fonts.NunitoSans.regular,
      fontStyle: "normal",
      fontWeight: 700,
      marginRight: "5px",
      fontSize: "18px",
      lineHeight: "25px",
      background: "#fff",
      color: "#000",
    },
    grideGuideContainer: {
      display: "flex",
      flex: 1,
      justifyContent: "flex-end",
      marginRight: "50px",
    },

    grideGuide2Container: {
      display: "flex",
      //flex: 1.1,
      justifyContent: "flex-start",
      alignItems: "center",
    },
    grideGuide2TriangleContainer: {
      display: "flex",
      flex: 0.1,
      justifyContent: "flex-start",
      alignItems: "center",
      //marginLeft: "40px",
    },
    grideGuide4Container: {
      display: "flex",
      flex: 1,
      justifyContent: "flex-end",
      marginRight: "100px",
    },
    grideGuide4RightAngleContainer: {
      display: "flex",
      flex: 1,
      justifyContent: "flex-end",
      marginRight: "360px",
    },

    rightAngleTriangle: {
      width: 0,
      height: 0,
      backgroundColor: "transparent",
      border: "0px solid red",
      borderRightWidth: 20,
      borderTopWidth: 20,
      borderRightColor: "transparent",
      borderTopColor: "#D1DFF6",
      transform: "rotate(180deg)",
    },
    rightAngleTriangleGuide2: {
      width: 0,
      height: 0,
      backgroundColor: "transparent",
      border: "0px solid red",
      borderRightWidth: 20,
      borderTopWidth: 30,
      borderRightColor: "transparent",
      borderTopColor: "#D1DFF6",
      transform: "rotate(90deg)",
      //marginBottom: "10px",
    },
    rightAngleTriangleGuide4: {
      width: 0,
      height: 0,
      backgroundColor: "transparent",
      border: "0px solid red",
      borderRightWidth: 20,
      borderTopWidth: 20,
      borderRightColor: "transparent",
      borderTopColor: "#D1DFF6",
      transform: "rotate(270deg)",
      //marginBottom: "10px",
    },
    guideNoteStyle: {
      padding: "5px",
      fontFamily: Fonts.NunitoSans.regular,
      textAlign: "left",
      fontStyle: "normal",
      fontWeight: 400,
      fontSize: "18px",
      lineHeight: "23px",
      background: "#D1DFF6",
      color: "#000",
    },
    guideImageStyle: {
      maxWidth: "65%",
      height: "auto",
      padding: "0px",
      margin: "0px",
      //marginLeft: "80px",
    },
    btnGuideLabelStyle: {
      fontFamily: Fonts.NunitoSans.regular,
      fontStyle: "normal",
      fontWeight: 700,
      fontSize: "18px",
      lineHeight: "25px",
      backgroundCo: "#fff",
      color: "#000",
      textTransform: "capitalize",
      height: "50px",
      width: "185px",
      "&:hover": {
        background: "#fff",
      },
      "&:focus": {
        background: "#fff",
      },
    },
  };
});
