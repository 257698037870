import {
  Card,
  CardContent,
  CardActions,
  Box,
  Typography,
  InputLabel,
  clsx,
  Grid,
  Collapse,
  IconButton,
  ExpandMore,
  ExpandLess,
  useNavigate,
  useEffect,
  useState,
  Button,
} from "../../../app.module";
import {
  getCompany,
  getPlanAddons,
  getSubscription,
} from "./OrganizationService";
import { Images, Fonts } from "../../constants";
import { useStyles, StyledButton } from "./OrganizationStyle";
import { LinearProgress } from "@mui/material";
const Organization = (props: any) => {
  const { classes } = useStyles();
  const [company, setCompany] = useState<any>();
  const [monthly, setMonthly] = useState(true);
  const [productPlanAddons, setProductPlanAddons] = useState([]);
  const [addonsIds, setAddonsIds] = useState<number[]>([]);
  const [isLoading, setIsLoading] = useState(true);
  const [expanded, setExpanded] = useState(0);
  const [usageData, setUsageData] = useState<any | undefined>();
  const [isDisabled, setIsDisabled] = useState(false);
  const handleExpandClick = (index: number) => {
    expanded === index + 1 ? setExpanded(0) : setExpanded(index + 1);
  };
  const addAddonsToPlan = (id: number = 0) => {
    let previousAddonsIds = addonsIds;
    // is element is present inside the array or not
    if (previousAddonsIds.includes(id)) {
      previousAddonsIds.splice(previousAddonsIds.indexOf(id), 1);
    } else {
      previousAddonsIds.push(id);
    }
    // Rerender the component with the updated data
    setAddonsIds([...previousAddonsIds]);
  };

  const getAddonsId = (id: number) => {
    return addonsIds.find((element) => element === id);
  };
  useEffect(() => {
    getCompany().then((resp: any) => {
      console.log("response", resp);
      // Directly set the response object to state
      setCompany(resp);
      getPlanAddons().then((resp: any) => {
        setProductPlanAddons(resp);
        setIsLoading(false);
      });
    });
    getSubscription().then((responce: any) => {
      setUsageData(responce);
      console.log("resp", responce);
    });
  }, []);
  console.log("company", company);
  console.log("usagedata", usageData);

  // const calculatePercentage = (total: number, available: number) => {
  //     return ((total - available) / total) * 100;
  // };
  const calculatePercentage = (total: number, available: number) => {
    return (total / available) * 100;
  };
  function formatDate(dateString: any) {
    const date = new Date(dateString);

    let day = date.getDate().toString();
    let month = (date.getMonth() + 1).toString(); // January is 0!
    let year = date.getFullYear().toString().substr(-2); // Get last two digits

    day = day.length < 2 ? "0" + day : day;
    month = month.length < 2 ? "0" + month : month;

    return day + " / " + month + " / " + year;
  }
  let formattedDate = "";
  if (usageData && usageData.nextRenewal) {
    formattedDate = formatDate(usageData.nextRenewal);
  }
  const cardHeight = 500;
  const cardWidth = 300;
  return (
    <div
      style={{ height: "87vh", backgroundColor: "#F2F5FD", overflow: "scroll" }}
    >
      <div
        style={{
          display: "flex",
          width: "100%",
          justifyContent: "space-between",
          flexDirection: "row",
        }}
      >
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "space-between",
          }}
        >
          <Grid
            justifyContent="center"
            alignItems="center"
            flexDirection={"row"}
            container
            spacing={2}
          >
            <Grid padding={2} justifyContent="center" alignItems="center">
              <Grid item xs={12} sm={6} md={6}>
                <Card
                  sx={{
                    // height: cardHeight,
                    // width: cardWidth,
                    maxWidth: "100%",
                    minWidth: 500,
                    minHeight: 200,
                    maxHeight: "100%",
                    padding: 1,
                    border: `0px solid `,
                    // minHeight: '200px',
                    // minWidth: '300px',
                    height: "100%",
                    width: "100%",
                    display: "flex",
                    flexDirection: "column",
                    justifyContent: "space-between",
                  }}
                >
                  <Box
                    sx={{
                      display: "flex",
                      flex: 1,

                      maxWidth: "100%",

                      marginLeft: "0%",
                      marginRight: "0%",
                      padding: "4%",
                      borderRadius: "0%",
                      paddingTop: "3%",
                      flexDirection: "column",
                      backgroundColor: "#fff",
                    }}
                  >
                    {usageData && (
                      <div>
                        <div
                          style={{
                            display: "flex",
                            justifyContent: "space-between",
                          }}
                        >
                          <div>
                            <InputLabel className={classes.labelStyle}>
                              Plan Name
                            </InputLabel>
                            <div
                              style={{
                                fontWeight: 700,
                                fontSize: "18px",
                                fontFamily: Fonts.NunitoSans.regular,
                              }}
                            >
                              {usageData.plan}
                            </div>
                          </div>
                          <div>
                            <InputLabel className={classes.labelStyle}>
                              Price
                            </InputLabel>
                            <div
                              style={{
                                fontWeight: 700,
                                fontSize: "18px",
                                fontFamily: Fonts.NunitoSans.regular,
                              }}
                            >
                              $ {usageData.price}
                            </div>
                          </div>
                          <div>
                            <InputLabel className={classes.labelStyle}>
                              Duration
                            </InputLabel>
                            <div
                              style={{
                                fontWeight: 700,
                                fontSize: "18px",
                                fontFamily: Fonts.NunitoSans.regular,
                              }}
                            >
                              {usageData.duration}
                            </div>
                          </div>
                          <div>
                            <InputLabel className={classes.labelStyle}>
                              Next Renewal
                            </InputLabel>
                            <div
                              style={{
                                fontWeight: 700,
                                fontSize: "18px",
                                fontFamily: Fonts.NunitoSans.regular,
                              }}
                            >
                              {formattedDate}
                            </div>
                          </div>
                        </div>
                        <Card className={classes.PlanUsage}>
                          <div
                            style={{
                              fontSize: "18px",
                              fontWeight: 700,
                              fontFamily: Fonts.NunitoSans.regular,
                              marginBottom: "3%",
                            }}
                          >
                            Plan Usage
                          </div>
                          <div
                            style={{
                              display: "flex",
                              justifyContent: "space-between",
                            }}
                          >
                            <Typography
                              style={{
                                fontSize: "14px",
                                fontWeight: 700,
                                fontFamily: Fonts.NunitoSans.regular,
                              }}
                            >
                              Users
                            </Typography>
                            <div>
                              {usageData.totalUser} of {usageData.availableUser}{" "}
                              Users Created
                            </div>
                          </div>
                          <LinearProgress
                            sx={{ height: "8px", borderRadius: 5 }}
                            variant="determinate"
                            value={calculatePercentage(
                              usageData.totalUser,
                              usageData.availableUser
                            )}
                          />

                          <div
                            style={{
                              display: "flex",
                              justifyContent: "space-between",
                            }}
                          >
                            <Typography
                              style={{
                                fontSize: "14px",
                                fontWeight: 700,
                                fontFamily: Fonts.NunitoSans.regular,
                              }}
                            >
                              Projects
                            </Typography>
                            <div>
                              {usageData.totalProject} of{" "}
                              {usageData.availableProject} Projects Created
                            </div>
                          </div>
                          <LinearProgress
                            sx={{ height: "8px", borderRadius: 5 }}
                            variant="determinate"
                            value={calculatePercentage(
                              usageData.totalProject,
                              usageData.availableProject
                            )}
                          />

                          <div
                            style={{
                              display: "flex",
                              justifyContent: "space-between",
                            }}
                          >
                            <Typography
                              style={{
                                fontSize: "14px",
                                fontWeight: 700,
                                fontFamily: Fonts.NunitoSans.regular,
                              }}
                            >
                              Datasourse
                            </Typography>
                            <div>
                              {usageData.totalDatabase} of{" "}
                              {usageData.availableDatabase} Datasourse Used
                            </div>
                          </div>
                          <LinearProgress
                            sx={{ height: "8px", borderRadius: 5 }}
                            variant="determinate"
                            value={calculatePercentage(
                              usageData.totalDatabase,
                              usageData.availableDatabase
                            )}
                          />

                          <div
                            style={{
                              display: "flex",
                              justifyContent: "space-between",
                            }}
                          >
                            <Typography
                              style={{
                                fontSize: "14px",
                                fontWeight: 700,
                                fontFamily: Fonts.NunitoSans.regular,
                              }}
                            >
                              Queries
                            </Typography>
                            <div>
                              {usageData.totalQuery} of{" "}
                              {usageData.availableQuery} Queries Used
                            </div>
                          </div>
                          <LinearProgress
                            sx={{ height: "8px", borderRadius: 5 }}
                            variant="determinate"
                            value={calculatePercentage(
                              usageData.totalQuery,
                              usageData.availableQuery
                            )}
                          />

                          <div
                            style={{
                              display: "flex",
                              justifyContent: "space-between",
                            }}
                          >
                            <Typography
                              style={{
                                fontSize: "14px",
                                fontWeight: 700,
                                fontFamily: Fonts.NunitoSans.regular,
                              }}
                            >
                              Modules
                            </Typography>
                            <div>
                              {usageData.totalModule} of{" "}
                              {usageData.availableModule} Modules Created
                            </div>
                          </div>
                          <LinearProgress
                            sx={{ height: "8px", borderRadius: 5 }}
                            variant="determinate"
                            value={calculatePercentage(
                              usageData.totalModule,
                              usageData.availableModule
                            )}
                          />

                          <div
                            style={{
                              display: "flex",
                              justifyContent: "space-between",
                            }}
                          >
                            <Typography
                              style={{
                                fontSize: "14px",
                                fontWeight: 700,
                                fontFamily: Fonts.NunitoSans.regular,
                              }}
                            >
                              Dashboards
                            </Typography>
                            <div>
                              {usageData.totalDashboard} of{" "}
                              {usageData.availableDashboard} Dashboards Created
                            </div>
                          </div>
                          <LinearProgress
                            sx={{ height: "8px", borderRadius: 5 }}
                            variant="determinate"
                            value={calculatePercentage(
                              usageData.totalDashboard,
                              usageData.availableDashboard
                            )}
                          />

                          {/* Repeat for each item (Database, Queries, Modules, Dashboards) */}
                        </Card>
                      </div>
                    )}
                  </Box>
                </Card>
              </Grid>
            </Grid>
          </Grid>
          <Grid
            justifyContent="center"
            alignItems="center"
            flexDirection={"row"}
            container
            marginBottom={"1.5%"}
          >
            {company && (
              <Grid padding={2} justifyContent="center" alignItems="center">
                <Grid item xs={12} sm={6} md={6}>
                  <Card
                    sx={{
                      // height: cardHeight,
                      // width: cardWidth,
                      maxWidth: "100%",
                      minWidth: 450,
                      minHeight: 200,
                      maxHeight: "100%",
                      padding: 1,
                      border: `0px solid `,
                      height: "100%",
                      width: "100%",
                      display: "flex",
                      flexDirection: "column",
                      justifyContent: "space-between",
                    }}
                  >
                    <Box
                      sx={{
                        display: "flex",
                        flex: 1,

                        maxWidth: "100%",

                        marginLeft: "0%",
                        marginRight: "0%",
                        padding: "5%",
                        borderRadius: "0%",
                        paddingBottom: "0%",
                        flexDirection: "column",
                        backgroundColor: "#fff",
                      }}
                    >
                      {/* <div style={{textAlign:"center",marginBottom:"10px"}}>
                                                <InputLabel className={classes.labelStyle}>
                                                    Organization Details
                                                </InputLabel>
                                            </div> */}

                      <div className={classes.twoFieldsContainer}>
                        <div>
                          <InputLabel className={classes.labelStyle}>
                            Organization Name
                          </InputLabel>
                          <div>{company.name}</div>
                        </div>

                        <div style={{ marginRight: "10%" }}>
                          <InputLabel className={classes.labelStyle}>
                            Domain Name
                          </InputLabel>
                          <div>{company.domainName}</div>
                        </div>
                      </div>

                      <div className={classes.twoFieldsContainer}>
                        <div>
                          <InputLabel className={classes.labelStyle}>
                            Industry Type
                          </InputLabel>
                          <div>{company.industryType}</div>
                        </div>

                        <div>
                          <InputLabel className={classes.labelStyle}>
                            Industry Size
                          </InputLabel>
                          <div>{company.industrySize}</div>
                        </div>
                      </div>

                      <div className={classes.twoFieldsContainer}>
                        <div>
                          <InputLabel className={classes.labelStyle}>
                            Address
                          </InputLabel>
                          <div>{company.address}</div>
                        </div>
                      </div>

                      <div className={classes.twoFieldsContainer}>
                        <div>
                          <InputLabel className={classes.labelStyle}>
                            Country
                          </InputLabel>
                          <div>{company.countryName}</div>
                        </div>

                        <div style={{ marginRight: "15%" }}>
                          <InputLabel className={classes.labelStyle}>
                            State :
                          </InputLabel>
                          <div>{company.stateName}</div>
                        </div>
                      </div>

                      <div className={classes.twoFieldsContainer}>
                        <div>
                          <InputLabel className={classes.labelStyle}>
                            City/Town
                          </InputLabel>
                          <div>{company.city}</div>
                        </div>

                        <div style={{ marginRight: "20%" }}>
                          <InputLabel className={classes.labelStyle}>
                            Pincode
                          </InputLabel>
                          <div>{company.pinCode}</div>
                        </div>
                      </div>

                      <div className={classes.twoFieldsContainer}>
                        <div>
                          <InputLabel className={classes.labelStyle}>
                            Email
                          </InputLabel>
                          <div>{company.emailAddress}</div>
                        </div>

                        <div style={{ marginRight: "4%" }}>
                          <InputLabel className={classes.labelStyle}>
                            Contact Number
                          </InputLabel>
                          <div>{company.contactNumber}</div>
                        </div>
                      </div>
                    </Box>
                  </Card>
                </Grid>
              </Grid>
            )}
          </Grid>
        </div>
        <div>
          <Grid
            justifyContent="center"
            alignItems="center"
            flexDirection={"row"}
            container
          >
            {company && (
              <Grid padding={2} justifyContent="center" alignItems="center">
                <Grid item xs={12} sm={6} md={3}>
                  <Card
                    sx={{
                      maxWidth: "100%",
                      minWidth: 310,
                      minHeight: 50,
                      maxHeight: "100%",
                      padding: 1,
                      border: `0px solid `,
                      height: "100%",
                      width: "100%",
                      display: "flex",
                      flexDirection: "column",
                      justifyContent: "space-between",
                    }}
                  >
                    <Box
                      sx={{
                        display: "flex",
                        flex: 1,

                        maxWidth: "100%",

                        marginLeft: "0%",
                        marginRight: "0%",
                        padding: "8%",
                        borderRadius: "0%",

                        flexDirection: "column",
                        backgroundColor: "#fff",
                      }}
                    >
                      {/* <div> {Images.Org}</div> */}
                      <img
                        style={{
                          height: "auto",
                          width: "auto",
                          marginLeft: "5%",
                        }}
                        src={Images.Org}
                      />

                      <div
                        style={{
                          display: "flex",
                          flexDirection: "column",
                          alignItems: "center",
                          marginTop: "5%",
                          marginBottom: "0%",
                        }}
                      >
                        <div>
                          <strong>You are currently using Basic plan.</strong>
                        </div>
                        <div>
                          <strong>
                            Upgrade to the advance plan to get more out of
                            WingsBI.
                          </strong>
                        </div>
                      </div>

                      <Button
                        // createOrganization();

                        // Cookies.remove("user_details");
                        // instance.logoutRedirect();
                        // navigate("/");
                        onClick={() => {
                          window.open("https://wingsbi.com/#contact", "_blank");
                        }}
                        style={{
                          background:
                            "linear-gradient(90deg, #5EB0FA 0%, #1157CD 50.54%, #9C90FA 100%)", // Color when enabled
                          color: "#fff",
                          textTransform: "capitalize",
                          height: "10%",
                          marginTop: "10%",
                        }}
                        className={classes.btnLabelStyle}
                        fullWidth
                        variant="contained"
                        size="large"
                      >
                        Contact Us
                      </Button>
                    </Box>
                  </Card>
                </Grid>
              </Grid>
            )}
          </Grid>
        </div>
      </div>
      <div
        style={{
          display: "flex",
          flexDirection: "row",
          justifyContent: "space-between",
        }}
      >
        <Grid
          justifyContent="center"
          alignItems="center"
          flexDirection={"row"}
          container
        >
          {company && isDisabled && (
            <Grid padding={2} justifyContent="center" alignItems="center">
              <Grid item xs={12} sm={6} md={3}>
                <Card
                  sx={{
                    maxWidth: "100%",
                    minWidth: 660,
                    minHeight: 200,
                    maxHeight: "90%",
                    padding: 1,
                    border: `0px solid `,
                  }}
                >
                  <Box
                    sx={{
                      display: "flex",
                      flex: 1,

                      maxWidth: "100%",

                      marginLeft: "0%",
                      marginRight: "0%",
                      padding: "10px",
                      borderRadius: "0px",

                      flexDirection: "column",
                      backgroundColor: "#fff",
                    }}
                  >
                    {/* <Box className={classes.PlanAddonsBoxContainer} id="addons"> */}
                    <Typography className={classes.planTopLabel} gutterBottom>
                      Add ons
                    </Typography>
                    <Grid flexDirection={"column"} container>
                      {productPlanAddons?.map((addons: any, index: number) => (
                        <Card className={classes.AddonsContainer}>
                          <CardContent style={{}}>
                            <Box
                              className={classes.PlanAddonsDetails}
                              onClick={() => {
                                handleExpandClick(index);
                              }}
                            >
                              <Typography className={classes.planAddonsTitlel}>
                                {addons?.addonName}
                              </Typography>
                              <Box
                                className={clsx(
                                  classes.PlanAddonsDetails,
                                  classes.planAddonsDirection
                                )}
                                onClick={() => {
                                  handleExpandClick(index);
                                }}
                              >
                                <Typography
                                  className={classes.planAddonsDetailsCollaps}
                                >
                                  {expanded === index + 1
                                    ? "Hide details"
                                    : "Show details"}
                                </Typography>
                                <IconButton>
                                  {expanded === index + 1 ? (
                                    <ExpandLess
                                      className={
                                        classes.planAddonsDetailsCollaps
                                      }
                                    />
                                  ) : (
                                    <ExpandMore
                                      className={
                                        classes.planAddonsDetailsCollaps
                                      }
                                    />
                                  )}
                                </IconButton>
                              </Box>
                            </Box>
                            <Collapse
                              in={expanded === index + 1 ? true : false}
                            >
                              <Box className={classes.CollapsContent}>
                                <Typography
                                  className={clsx(
                                    classes.planAddonsDescription
                                  )}
                                >
                                  {addons?.addonDescription}
                                </Typography>
                              </Box>
                            </Collapse>
                          </CardContent>
                          <CardContent
                            style={{
                              display: "flex",
                              justifyContent: "flex-start",
                              alignItems: "flex-start",
                              // backgroundColor: "red",
                              width: "150px",
                              padding: "12px",
                            }}
                          >
                            <Typography className={classes.planAddonsPrice}>
                              ₹{addons?.addonprice}
                              <sub>{monthly ? "/month" : "/year"}</sub>
                            </Typography>
                          </CardContent>
                          <CardContent
                            style={{ marginRight: "10px", padding: "15px" }}
                          >
                            <CardActions
                              className={classes.CardContentWithoutPadding}
                            >
                              <StyledButton
                                onClick={() => addAddonsToPlan(addons.addonId)}
                                //className={classes.btnPlanAddonLabelStyle}
                                className={
                                  getAddonsId(addons.addonId)
                                    ? classes.btnInactivePlanAddonsLabelStyle
                                    : classes.btnPlanAddonLabelStyle
                                }
                                variant="contained"
                                size="medium"
                              >
                                {getAddonsId(addons.addonId) ? "Remove" : "Add"}
                              </StyledButton>
                            </CardActions>
                          </CardContent>
                        </Card>
                      ))}
                      <StyledButton
                        onClick={() => {
                          window.open("https://wingsbi.com/#contact", "_blank");
                        }}
                        // className={`${classes.btnOrderLabelStyle} ${!isChecked ? classes.disabledButton : ""
                        // }`}
                        style={{
                          background:
                            "linear-gradient(90deg, #5EB0FA 0%, #1157CD 50.54%, #9C90FA 100%)", // Color when enabled
                          color: "#fff",
                          textTransform: "capitalize",
                          height: "50px",
                          // marginTop: "10px",
                        }}
                        size="medium"
                        className={classes.btnLabelStyle}
                        // disabled={!isChecked}
                      >
                        Contact Us
                      </StyledButton>
                    </Grid>
                    {/* </Box> */}
                  </Box>
                </Card>
              </Grid>
            </Grid>
          )}
        </Grid>
      </div>
    </div>
  );
};
export default Organization;
