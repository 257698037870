import {
  styled,
  Button,
  TextareaAutosize,
  makeStyles,
  TextField,
} from "../../../../app.module";
import { Fonts, Colors } from "../../../constants";
export const useDatasourceStyle = () =>
  makeStyles()((theme?: any) => {
    return {
      containerStyle: {
        width: "70%",
        height: "50%",
        border: "1px solid white",
        display: "flex",
        flexDirection: "column" as "column",
        alignItems: "center",
        justifyContent: "flex-start",
        position: "relative" as "relative",
        padding: "10px",
        marginLeft: "100px",
        marginRight: "-8px",
        marginTop: "-12px",
      },

      imageContainerStyle: {
        position: "absolute" as "absolute",
        top: "10px",
        left: "50%",
        transform: "translateX(-50%)",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
      },

      imageWrapperStyle: {
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        justifyContent: "center",
      },

      csvImageStyle: {
        marginTop: "4%",
        width: "60%",
        height: "60%",
      },

      dbImageStyle: {
        marginTop: "5%",
        width: "50%",
        height: "50%",
      },
      addFileButton: {
        padding: "6px 14px",
        borderRadius: "5px",
        border: "none",
        fontSize: "16px",
        fontStyle: "normal",
        fontWeight: 700,
        fontFamily: Fonts.NunitoSans.regular,
        textTransform: "capitalize",
        width: "10vw",
        display: "flex",
        alignItems: "center",
      },
      uploadButton: {
        background:
          "linear-gradient(90deg, #5EB0FA 0%, #1157CD 50.54%, #9C90FA 100%)",
        fontFamily: Fonts.NunitoSans.regular,
        fontStyle: "normal",
        fontWeight: 700,
        fontSize: "18px",
        lineHeight: "25px",
        borderRadius: "5px",
        textTransform: "capitalize",
        height: "57px",
        width: "155px",
        color: "#fff",
        // marginTop: "100%",
        "&:disabled": {
          background: "#ecedf2",
          color: "#cccdd2",
        },
      },
      btnProjectTestContainer: {
        display: "flex",
        justifyContent: "center",
      },
      proBtncontainer: {
        display: "flex",
        flex: 1,
        justifyContent: "space-between",
        marginTop: "91%",
        marginLeft: "50%",
      },
      twoFieldsContainer: {
        display: "flex",
        flex: 1,
        justifyContent: "space-between",
      },
      testDbConnContainerRight: {
        display: "flex",
        flex: 3,
        flexDirection: "column",
      },
      labelStyle: {
        marginTop: "2%",
        marginLeft: "6px",
        fontFamily: Fonts.NunitoSans.regular,
        fontStyle: "normal",
        fontWeight: "700",
        fontSize: "18px",
        backgroundCo: "#fff",
        color: "dark #000",
        borderRadius: "5px",
        "&:hover": {
          background: "#fff",
        },
        "&:focus": {
          background: "#fff",
        },
      },

      dropdownStyle: {
        fontFamily: Fonts.NunitoSans.regular,
        fontStyle: "normal" as "normal",
        fontSize: "18px",
        lineHeight: "10px",
        width: "280px",
        height: "35px",
        transform: "translateX(-50%)",
        background: "white",
        borderRadius: "10px",
        border: "1px solid white",
        cursor: "pointer",
        padding: "1px 4px",
        display: "inline-block",
        transition: "background .2s ease-in-out",
        position: "absolute",
        marginTop: "40%",
      },

      spanStyle: {
        color: "red", // Example style for the asterisk
        marginLeft: "-33%",
        marginTop: "-15%",
        marginBottom: "20%",
      },

      gridContainerStyle: {
        display: "flex",
        justifyContent: "space-between", // This ensures space between the child components
        padding: "20px", // Padding around the entire grid container
      },

      projectCancelBtnLabelStyle: {
        fontFamily: Fonts.NunitoSans.regular,
        fontStyle: "normal",
        fontWeight: 700,
        fontSize: "18px",
        lineHeight: "25px",
        backgroundCo: "#fff",
        color: "#000",
        textTransform: "capitalize",
        height: "57px",
        width: "155px",
        borderRadius: "5px",
        marginRight: "15px",
        // marginTop: "100%",
        marginLeft: "35px",
        "&:hover": {
          background: "#fff",
        },
        "&:focus": {
          background: "#fff",
        },
      },
      nextProBtn: {
        background:
          "linear-gradient(90deg, #5EB0FA 0%, #1157CD 50.54%, #9C90FA 100%)",
        fontFamily: Fonts.NunitoSans.regular,
        fontStyle: "normal",
        fontWeight: 700,
        fontSize: "18px",
        lineHeight: "25px",
        borderRadius: "5px",
        textTransform: "capitalize",
        height: "57px",
        width: "155px",
        // marginTop: "100%",
        "&:disabled": {
          background: "#ecedf2",
          color: "#cccdd2",
        },
      },
      generateBtnCircularLoader: {
        color: Colors?.main,
        marginLeft: "5px",
      },
      loaderContainer: {
        marginTop:"10%",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        height: "200px", // Adjust this value as needed
      },
    };
  });

export const CssProjectTextareaAutosize = styled(TextareaAutosize)({
  "& label.Mui-focused": {
    color: "#000",
    borderRadius: "10px",
  },
  "& .MuiInput-underline:after": {
    borderBottomColor: "red",
    borderRadius: "10px",
  },

  "& .MuiOutlinedInput-root": {
    "& fieldset": {
      borderColor: "#F9F9F9",
      borderRadius: "10px",
    },
    "&:hover fieldset": {
      border: `1px solid ${Colors?.main}`,
      borderRadius: "10px",
    },
    "&.Mui-focused fieldset": {
      border: `1px solid ${Colors?.main}`,
      borderRadius: "10px",
    },
  },
});

export const CssProjectTextField = styled(TextField)({
  "& label.Mui-focused": {
    color: "#000",
    borderRadius: "10px",
  },
  "& .MuiInput-underline:after": {
    borderBottomColor: "red",
    borderRadius: "10px",
  },

  "& .MuiOutlinedInput-root": {
    "& fieldset": {
      borderColor: "#F9F9F9",
      borderRadius: "10px",
    },
    "&:hover fieldset": {
      border: `1px solid ${Colors?.main}`,
      borderRadius: "10px",
    },
    "&.Mui-focused fieldset": {
      border: `1px solid ${Colors?.main}`,
      borderRadius: "10px",
    },
  },
});

export const StyledDatasourceButton = styled(Button)({
  backgroundColor: "#fff",
  color: "#fff",
  padding: "6px 12px",
  "&:hover": {
    backgroundColor: "#fff",
  },
});
