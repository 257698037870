import React, { useState } from 'react';

interface EndChatButtonProps {
  onEndChat: () => void;
}

const defaultButtonStyle: React.CSSProperties = {
    padding: '10px 20px',
    borderRadius: '10px',
    color: '#fff',
    fontSize: '18px',
    marginLeft: '86%',
    cursor: 'pointer',
    width: '175px',
    height: '45px',
    font: 'Nunito Sans',
    fontWeight:700,
    border: 'none',
    // background: 'rgba(251, 219, 56, 1)',
    background:"#E24A95",
    // boxShadow: '0px 2px 5px rgba(0, 0, 0, 0.2)',
    // transition: 'transform 0.2s ease-in-out',
    zIndex:10,
    position:'absolute',
};

const hoverButtonStyle: React.CSSProperties = {
    ...defaultButtonStyle,
    background: "#BF4398", // Change the background color on hover
    border: 'none',
    // transform: 'scale(1.05)', // Optional: Scale the button a bit
};

const EndChatButton: React.FC<EndChatButtonProps> = ({ onEndChat }) => {
  const [isHovering, setIsHovering] = useState(false);

  return (
    <button 
      style={isHovering ? hoverButtonStyle : defaultButtonStyle}
      // style={defaultButtonStyle}
      onMouseEnter={() => setIsHovering(true)}
      onMouseLeave={() => setIsHovering(false)}
      onClick={onEndChat}
    >
      <b>End Chat</b>
    </button>
  );
};

export default EndChatButton;
