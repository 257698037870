import React, { useEffect, useState } from 'react';
import { Card, CardContent, Typography } from '@mui/material'; 
import { Colors, Images } from '../../../constants';

const CardChart = React.memo((props: any) => { 
    const [cardChartJson, setCardChartJson] = useState<any>({});
  
    useEffect(() => {
      if (props.data !== cardChartJson) {
        setCardChartJson(props.data);
      }
    }, [props.data]);

    if (!cardChartJson.data || cardChartJson.data.length === 0) {
        return (
          <div
            style={{
              display: "flex",
              height: "87vh",
              width: "75%",
              justifyContent: "center",
              alignItems: "center",
              backgroundColor: Colors?.background.backgroundColorTheme3,
              boxShadow: "0px 0px 4px 0px #00000040",
              borderRadius: "10px 0px 0px 10px",
            }}
          >
            <img
              style={{
                height: "auto",
                width: "300px",
              }}
              src={Images.no_data_img}
              alt="No data"
            />
          </div>
        );
    }

    const chartData = cardChartJson.data[0];
        const cardTitle = Object.keys(chartData)[0];
         const cardValue = chartData[cardTitle]; 

    return (
        <div
        key="CHART112"
        style={{
          backgroundColor: Colors?.background.backgroundColorTheme3,
          height: "87vh",
          width: "75%",
          overflowX: "auto",
          boxShadow: "0px 0px 4px 0px #00000040",
          borderRadius: "10px 0px 0px 10px",
          top: 0,
        }}
      >
        Datasource name: <label>{props?.dbsource}</label>
        <div
          key="CHART1005"
          style={{
            width: "100%",
            height: "75vh",
          }}
        >
      <Card
  sx={{

    position: "absolute",
    top: "35vh",
    left: "40%",
    transform: "translateX(-50%)",
    width: "450px", 
    height: "300px",
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-between",
    borderRadius: "10px",
    opacity: 0.9,
    boxShadow: "0px 4px 10px rgba(0, 0, 0, 0.1)",
    background: "#f5f5f5",
    color: "#333",
    border: "2px solid #ccc",
  }}
>
        <CardContent
          style={{
            display: "flex",
            flex: 1,
            flexDirection: "column",
            justifyContent: "center",
            alignItems: "center",

          }}
        >
          <Typography variant="h4" component="div" style={{ marginBottom: 16, fontWeight: 'bold' }}>
                {cardTitle}
               </Typography>
               <hr style={{ width: "70%", border: "2px solid #ccc", margin: "10px 0" }} />
               <Typography variant="h5" style={{ fontSize: 24 }}>
                 {cardValue.toLocaleString()}
               </Typography>
        </CardContent>
      </Card>
      </div>
      </div>
    );
})

export default CardChart;
