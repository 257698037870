import React, { useEffect, useState } from 'react';
import {
  AreaChart, Area, XAxis, YAxis, CartesianGrid, Tooltip, ResponsiveContainer,
} from 'recharts';
import { Colors, Images, Fonts } from '../../../constants';


type CustomTooltipProps = {
    active?: boolean;
    payload?: any[];
    label?: string;
  };

const AreaChartComponent = React.memo((props:any) => {
    const [areaChartJson, setAreaChartJson] = useState<any>([]);
    const [isLoading, setIsLoading] = useState(true);
    const [isScrolling, setIsScrolling] = useState(false);

    const formatYAxisTick = (value: number | string) => {
      // Convert all values to string to handle both cases
      const valueStr = value.toString();
      return valueStr.length > 4 ? `${valueStr.substring(0, 4)}...` : valueStr;
  };
  
    useEffect(() => {
      if (props.data !== areaChartJson) {
        setAreaChartJson(props.data);
        setIsLoading(false); // Data is loaded, set loading to false
      }
    }, [props.data]);

    useEffect(() => {
        // Inject custom scrollbar styles
        const style = document.createElement('style');
        style.type = 'text/css';
        style.innerHTML = `
          .AreaChartScroll::-webkit-scrollbar {
            width: 12px;
            height: 12px;
            background-color: transparent; /* make scrollbar background transparent */
          }
          .AreaChartScroll:hover::-webkit-scrollbar-thumb {
            border-radius: 10px;
            -webkit-box-shadow: inset 0 0 6px rgba(0,0,0,.3);
            background-color: #D3D3D3; /* only show scrollbar thumb on hover */
          }
        `;
        document.head.appendChild(style);
      
        // Ensure that we remove the style on cleanup
        return () => {
          document.head.removeChild(style);
        };
      }, []);
      
      const AreaChartScrollClassName = "AreaChartScroll";

  if (!areaChartJson || areaChartJson.length === 0) {
    return (
      <div style={{ display: "flex", height: "87vh", width: "75%", justifyContent: "center", alignItems: "center", backgroundColor: Colors?.background.backgroundColorTheme3, boxShadow: "0px 0px 4px 0px #00000040", borderRadius: "10px 0px 0px 10px", }}>
        <img style={{ height: "auto", width: "300px" }} src={Images.no_data_img} alt="No data" />
      </div>
    );
  }

  
  const CustomTooltip: React.FC<CustomTooltipProps> = ({ active, payload }) => {
    if (active && payload && payload.length) {
      // Extract the dynamic payload data that the tooltip will use
      const tooltipData = payload[0].payload; // This accesses the nested payload object which contains your data
  
      return (
        <div className="custom-tooltip" style={{
          backgroundColor: '#fff',
          padding: '10px',
          border: '1px solid #ccc',
          boxShadow: '0px 0px 10px 0px rgba(0, 0, 0, 0.25)',
          borderRadius: '5px',
          fontFamily: Fonts.NunitoSans.regular,
        }}>
            
          {/* Iterate over the payload object's own properties to display them */}
          {Object.keys(tooltipData).map((key, index) => (
            <p key={`item-${index}`} className="intro">
              {`${key}: ${tooltipData[key]}`}
            </p>
          ))}
        </div>
      );
    }
  
    return null;
  };
  
  const CustomTick = (props:any) => {
    const { x, y, payload } = props;
  
    return (
      <g transform={`translate(${x},${y})`}>
        <text
          x={0}
          y={0}
          dy={16} // Adjust this value to position your label appropriately
          textAnchor="end"
          transform="rotate(-35)" // This rotates the tick labels
        //   style={{ cursor: 'default' }} // Changes the cursor to indicate hoverable text
        style={{ 
            cursor: 'default', 
            fontFamily: Fonts.NunitoSans.regular, // Set the font family here
          }}
        >
          {/* Wrap the displayed text in a tspan to enable the tooltip */}
          <tspan>
            {payload.value.length > 5 ? `${payload.value.substring(0, 5)}...` : payload.value}
            {/* Tooltip */}
            <title>{payload.value}</title>
          </tspan>
        </text>
      </g>
    );
  };

 
  return (
    <div
    className={AreaChartScrollClassName}
      key="CHART112"
      style={{
    
        // height: "100%",
        // width: "100%",
        
        // overflowX: "hidden", // Hide horizontal scrollbar by default
      
        // overflow: "scroll",
        // // boxShadow: "0px 0px 4px 0px #00000040",
        // borderRadius: "10px 0px 0px 10px",
        // top: 0,
        height: "100%",
    width: "100%", // Ensure the chart stretches to the full width and height of its container
    overflowX: "hidden", 
      }}
      onMouseOver={(e) => {
        // Add scrollbars when hovered
        e.currentTarget.style.overflowX = "auto";
        e.currentTarget.style.overflowY = "auto";
      }}
      onMouseOut={(e) => {
        // Remove scrollbars when not hovered
        e.currentTarget.style.overflowX = "hidden";
        e.currentTarget.style.overflowY = "hidden";
      }}
    >
      <div
        key="CHART1005"
        style={{
          width: "200%",
          height: "100%", //40vh
          overflowY:"hidden",
        }}
      >
        <ResponsiveContainer width="100%" height="100%">
          <AreaChart data={areaChartJson.data} margin={{ top: 30, right: 30, left: 0, bottom: 0 }}>
            <defs>
              <linearGradient id="colorCredit" x1="0" y1="0" x2="0" y2="1">
                <stop offset="5%" stopColor="#8884d8" stopOpacity={0.8}/>
                <stop offset="95%" stopColor="#8884d8" stopOpacity={0}/>
              </linearGradient>
            </defs>
            <CartesianGrid strokeDasharray="3 3" />
            {/* <XAxis dataKey={areaChartJson.datakey_X} /> */}
            <XAxis
  dataKey={areaChartJson.datakey_X}
  height={60} // Adjust height to accommodate rotated labels
  tick={<CustomTick />}
  
/>
             <YAxis
                tickFormatter={formatYAxisTick}
                // Add a tick component for custom rendering
                tick={({ x, y, payload }) => {
                  // Prepare the full label as a title for the tooltip on hover
                  const fullLabel = payload.value.toString();
                  return (
                    <g transform={`translate(${x},${y})`}>
                      <text  textAnchor="end">
                       {formatYAxisTick(payload.value)}
                        <title>{fullLabel}</title>
                      </text>
                    </g>
                  );
                }}
              />
            {/* <Tooltip /> */}
            <Tooltip content={CustomTooltip} />
            <Area type="monotone" dataKey={areaChartJson.datakey_Y} stroke="#8884d8" fillOpacity={1} fill="url(#colorCredit)" />
          </AreaChart>
        </ResponsiveContainer>
      </div>
    </div>
  );
});

export default AreaChartComponent;

