



// export default userAvatar;

import React from "react";
import {
  Typography,
  // other imports
} from "../../../app.module";
import { getUserDetailsFromCookies } from "../../services";
import { Colors, Fonts } from "../../constants";

const userAvatar = () => {
  const userDetails = getUserDetailsFromCookies();
  const firstNameInitial = userDetails.firstName ? userDetails.firstName.charAt(0) : "";
  const lastNameInitial = userDetails.lastName ? userDetails.lastName.charAt(0) : "";


  return (
    <div
      style={{
        fontFamily: Fonts.NunitoSans.regular,
        background: "linear-gradient(202.64deg, #E24A95 21.95%, #4A2BA3 92.07%)",
        color: "white",
        width: "50px",
        height: "50px",
        display: "grid",
        placeItems: "center",
        borderRadius: "100%",
        // boxShadow: "0px 0px 5px rgba(0, 0, 0, 0.5)",
        
      }}
    >
      <Typography>
        {firstNameInitial}{lastNameInitial}
      </Typography>
    </div>
  );
};

export default userAvatar;

