
import { result } from "lodash";
import {
  DatabaseType,
  ConnectionString,
  DatabaseProvider,
  CreateProject,
  CreateDatabase,
  GetDatabase,
  GetProject,
  GetUsers,
  InviteUser,
  CreateCompany,
  UserRole,
} from "../../constants/Endpoints";
import { getData, postData, getUserDetailsFromCookies } from "../../services";

export const doGetUsers = async () => {
  return await getData(`/${getUserDetailsFromCookies().identifier}/api/userinvite`).then((result) => {
    // `${getUserDetailsFromCookies().identifier}/api/userinvite`
    console.log("cookies", getUserDetailsFromCookies());
    return result;
  });
};

export const doGetUsersByRole = async (id: number, roleId: number) => {
  return await getData(`/${getUserDetailsFromCookies().identifier}/api/userinvite?RoleId=${roleId}&ProjectId=${id}`).then((result) => {
    // `${getUserDetailsFromCookies().identifier}/api/userinvite`
    console.log("cookies", getUserDetailsFromCookies());
    return result;
  });
};
export const doGetUsersByRoleProj = async (roleId: number) => {
  return await getData(`/${getUserDetailsFromCookies().identifier}/api/userinvite?RoleId=${roleId}`).then((result) => {
    // `${getUserDetailsFromCookies().identifier}/api/userinvite`
    console.log("cookies", getUserDetailsFromCookies());
    return result;
  });
};
console.log("dogetuserproj",doGetUsersByRoleProj);

export const getUserRoles = async (type: string) => {
  return await getData(`${UserRole}?roletype=${type}`).then((result) => {
    return result;
  });
};
export const gUserRoles = async (type: string) => {
  return await getData(UserRole).then((result) => {
    return result;
  });
};

export const addUsers = async (users_inv: any) => {
  return await postData(`/${getUserDetailsFromCookies().identifier}/api/userinvite`, users_inv).then((result) => {
    console.log("users result", result);
    return result;
  })
};

export const setCompanyDetails = async (company_details: any) => {
  return await postData(CreateCompany, company_details).then((result) => {
    console.log("company", company_details);
    return result;
  });
};


export class GetUsersRes {
  userId: number | undefined;
  userName: string | undefined;
  isActive: boolean | undefined;
  roleId: number | undefined;
  roleName: string | undefined;
  projectId: number | undefined;
  projectName: string | undefined;
  email: string | undefined;

  constructor(data: any) {
    Object.assign(this, {
      userId: data.userId,
      userName: data.userName,
      isActive: data.isActive,
      roleId: data.roleId,
      roleName: data.roleName,
      projectId: data.projectId,
      projectName: data.projectName,
      email: data.email,
    });
  }
}

export class GetUsersResults {
  id: number | undefined;
  firstName: string | undefined;
  // middleName: string|undefined;
  lastName: string | undefined;
  // dateOfBirth:undefined;
  // contactNumber: undefined;
  emailAddress: string | undefined;
  roleId: number | undefined;
  roleName: string | undefined;
  // createdDate: Date | undefined;
  // "address": null;
  // "city": null;
  // "state": null;
  // "country": null;
  // "pinCode": null;
  // "statusId": null;
  // "createdBy": null;
  // "createdDate": "2023-07-12T11:05:53.389";
  // "modifiedBy": 301;
  // "modifiedDate": "2023-07-12T11:05:53.389";
  // "companyId": 552;
  // "companyName": "Marvel";
  // "identifier": "nRgL0ZP1";
  // "tenantStateId": 2;
  // "projectId": 346;
  // "roleId": 6;
  // "roleName": ;
  constructor(data: any) {
    // this.createdDate = new Date(data.createdDate);
    Object.assign(this, {
      id: data.id,
      firstName: data.firstName,
      lastName: data.lastName,
      emailAddress: data.emailAddress,
      roleId: data.roleId,
      roleName: data.roleName,
    });
  }
}