import { deleteData, getData, getUserDetailsFromCookies, postData } from "../../../../services";
export const getFileList = async (id: number) => {
    console.log("logfile",`/${getUserDetailsFromCookies().identifier}/datasource/project/${id}`)
    return await getData(
      `/${getUserDetailsFromCookies().identifier}/datasource/project/${id}`).then((result) => {
      console.log("resuu", result);
      return result;
    });
  };

  export const updateDocument = async (document_details: any) => {
    try {
      const result = await postData(
        `/${getUserDetailsFromCookies().identifier}/fileUpdate`,
        document_details
      );
      console.log("Document update response:", result);
      return result;
    } catch (error) {
      console.error("Error updating document:", error);
      throw error;  // Re-throwing the error after logging it
    }
  };

  export const doDeleteDocument = async (projectId:number,Id:number) => {
    console.log("proid",projectId)
    console.log("idid",Id)
    return await deleteData(
      `/${getUserDetailsFromCookies().identifier}/fileDelete/project/${projectId}/file/${Id}`,
    ).then((result) => {
      return result;
    });
  };
