import {
  makeStyles,
} from "../../../app.module";
import { Colors, Fonts } from "../../constants";

export const useStyle = makeStyles()((theme?: any) => {
  return {
    dashboardPathText: {
      fontFamily: Fonts.NunitoSans.regular,
      fontStyle: "normal",
      fontWeight: "700",
      fontSize: "14",
      lineHeight: "19px",
      color: Colors?.path.light,
    },
  };
});
