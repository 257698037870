import { styled } from '@mui/material/styles';
import { Box, Button, TextField, FormControl, Select } from '@mui/material';

export const LandingPageContainer = styled(Box)(({ theme }) => ({
    padding: '10%',
    maxWidth: '900px',
    margin: '0 auto',
    [theme.breakpoints.down('sm')]: {
        padding: '10%',
    },
}));

export const StyledTextField = styled(TextField)({
    position: 'absolute',
    marginBottom: '20%',
    width: '50%',
    marginTop: '-8%',
    marginLeft: '-25%',
    // Adding the curved shape
    '& .MuiOutlinedInput-root': {
        borderRadius: '30px', // This gives the curved/rounded corners
    },
    '& .MuiOutlinedInput-notchedOutline': {
        borderColor: '#1157CD', // Customize border color if needed
    },
    '&:hover .MuiOutlinedInput-notchedOutline': {
        borderColor: '#5EB0FA', // Customize hover state border color
    },
    '& .MuiInputBase-input': {
        padding: '15px 20px', // Adjust padding for the input text
    },
});

export const StyledFormControl = styled(FormControl)({
    marginBottom: '30px',
    width: '100%',
});

export const ButtonContainer = styled(Box)({
    display: 'flex',
    justifyContent: 'flex-end', // Align items to the start of the container
    gap: '-15%', // Adds space between the buttons
    // marginLeft: '-5%', // Adds margin to the left of the container
    marginRight: '-45%',
    marginTop: '-25%',
  });

export const StyledButton = styled(Button)({
   
    padding: '15px 30px',
    marginTop: '59%',
    marginLeft: '20%',
    background:
        "linear-gradient(90deg, #5EB0FA 0%, #1157CD 50.54%, #9C90FA 100%)",
    color: "#fff",
    fontFamily: "Nunito Sans, sans-serif",
    fontStyle: "normal",
    fontWeight: 700,
    fontSize: "18px",
    lineHeight: "25px",
    textTransform: "capitalize",
    height: "47px",
    width: "185px",
    marginBottom: "20px",
    "&:disabled": {
        background: "#ecedf2",
        color: "##cccdd2",
    },
});

// New styled container for Select Datasource
export const SelectDatasourceContainer = styled(Box)({
    position: 'absolute',
    marginBottom: '30px',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-start',
    width: '15%',
    // Additional styling to adjust the positioning
    marginTop: '-8%',
    marginLeft: '49%',
    
});



