import {
  useEffect,
  useState,
  Modal,
  InputLabel,
  CircularProgress,
  Close,
  Done,
  Select,
  Grid,
  clsx,
  Cookies,
  DialogContent,
  DialogContentText,
  Dialog,
} from "../../../../../app.module";
import Button from "@mui/material/Button";
import {
  useDatabaseStyle,
  StyledDatabaseButton,
  CssDatabaseTextField,
} from "./DatabaseStyle";
import {
  getDatabaseTypes,
  getDatabaseProvider,
  createNewDatabase,
  connectDatabaseexist,
} from "./DatabaseService";
import MenuItem from "@mui/material/MenuItem";
import { getUserDetailsFromCookies } from "../../../../services";

const AddDatabase = ({
  onNext,
  onCancel,
}: {
  onNext: () => void;
  onCancel: () => void;
}) => {
  const [value, setValue] = useState("none");
  const [databaseType, setDatabaseType] = useState([]);
  const [databaseProvider, setDatabaseProvider] = useState([]);
  const [openDatabase, setOpenDatabase] = useState(false);
  const [isLoadingConnection, setIsLoadingConnection] = useState(false);
  const [showPlaceholder, setShowPlaceholder] = useState(value === "none");
  const [isDatabaseCreated, setIsDatabaseCreated] = useState(false);
  const userRole = getUserDetailsFromCookies().roleName;
  const { classes } = useDatabaseStyle(userRole)();
  const [isDialogOpen, setIsDialogOpen] = useState(false);
  const [dialogState, setDialogState] = useState<
    "loading" | "success" | "error"
  >("loading");
  const [dialogMessage, setDialogMessage] = useState("");


  useEffect(() => {
    const fetchDatabaseData = async () => {
      try {
        // Fetch database types
        const databaseTypes = await getDatabaseTypes();
        setDatabaseType(databaseTypes);
  
        // Fetch database providers
        const databaseProviderData = await getDatabaseProvider();
        setDatabaseProvider(databaseProviderData);
      } catch (error) {
        console.error('Error fetching database data:', error);
      }
    };
  
    // Call the combined function
    fetchDatabaseData();
  }, []);
  

  const [formValues, setFormValues] = useState({
    database_name: {
      value: "",
      error: false,
      errorMessage: "You must enter a database name",
    },
    project_name: {
      value: "",
      error: false,
      errorMessage: "You must enter project name",
    },
    description: {
      value: "",
      error: false,
      errorMessage: "You must enter project description",
    },

    database_type: {
      value: "none",
      id: 0,
      error: false,
      errorMessage: "You must choose your  database type",
    },
    database_provider: {
      value: "none",
      id: 0,
      error: false,
      errorMessage: "You must choose your database provider",
    },

    connection: {
      value: "",
      error: false,
      isTested: false,
      errorMessage: "You must test the connection string",
    },
    host_name: {
      value: "",
      error: false,
      errorMessage: "You must enter host name",
    },
    port_number: {
      value: "",
      error: false,
      errorMessage: "You must enter port number",
    },
    password: {
      value: "",
      error: false,
      errorMessage: "You must enter password",
    },
    username: {
      value: "",
      error: false,
      errorMessage: "You must enter username",
    },
    database_source_name: {
      value: "",
      error: false,
      errorMessage: "You must enter  database source name",
    },
  });
  const [project_resp, setProject] = useState({
    result: {
      connectionString: String,
      description: String,
      name: String,
      tenantId: String,
      id: Number,
      createdBy: Number,
    },
  });
  const handleProjectStateChange = (e: any) => {
    const { name, value } = e.target;
    switch (name) {
      case "database_name":
        setFormValues({
          ...formValues,
          ["database_name"]: {
            ...formValues["database_name"],
            value,
            error: false,
            errorMessage: "",
          },
        });
        break;
      case "database_type":
        let db_id: any = databaseType.find((db: any) => db.name === value);

        setFormValues({
          ...formValues,
          ["database_type"]: {
            ...formValues["database_type"],
            value,
            id: db_id.id,
            error: false,
            errorMessage: "",
          },
        });
        break;
      case "database_provider":
        let db_provider_id: any = databaseProvider.find(
          (db: any) => db.name === value
        );
        setFormValues({
          ...formValues,
          ["database_provider"]: {
            ...formValues["database_provider"],
            value,
            id: db_provider_id.id,
            error: false,
            errorMessage: "",
          },
        });
        break;
      case "project_name":
        setFormValues({
          ...formValues,
          ["project_name"]: {
            ...formValues["project_name"],
            value,
            error: false,
            errorMessage: "",
          },
        });
        break;
      case "description":
        setFormValues({
          ...formValues,
          ["description"]: {
            ...formValues["description"],
            value,
            error: false,
            errorMessage: "",
          },
        });
        break;
      case "connection":
        setFormValues({
          ...formValues,
          ["connection"]: {
            ...formValues["connection"],
            value,
            error: false,
            isTested: false,
            errorMessage: "",
          },
        });

        break;
      case "host_name":
        setFormValues({
          ...formValues,
          ["host_name"]: {
            ...formValues["host_name"],
            value,
            error: false,
            errorMessage: "",
          },
        });
        break;
      case "port_number":
        setFormValues({
          ...formValues,
          ["port_number"]: {
            ...formValues["port_number"],
            value,
            error: false,
            errorMessage: "",
          },
        });
        break;
      case "username":
        setFormValues({
          ...formValues,
          ["username"]: {
            ...formValues["username"],
            value,
            error: false,
            errorMessage: "",
          },
        });
        break;
      case "password":
        setFormValues({
          ...formValues,
          ["password"]: {
            ...formValues["password"],
            value,
            error: false,
            errorMessage: "",
          },
        });
        break;
      case "database_source_name":
        setFormValues({
          ...formValues,
          ["database_source_name"]: {
            ...formValues["database_source_name"],
            value,
            error: false,
            errorMessage: "",
          },
        });
        break;
    }
  };

  const handleTestConnection = async () => {
    let tetCon = {
      databaseType: formValues.database_type.value,
      port: parseInt(formValues.port_number.value),
      hostName: formValues.host_name.value,
      userName: formValues.username.value,
      password: formValues.password.value,
      databaseName: formValues.database_name.value,
    };
    let result = await connectDatabaseexist(
      tetCon,
      getUserDetailsFromCookies().projectId
    );
    console.log("databaseres", result);
    if (!result.isError) {
      setIsLoadingConnection(false);
      console.log("Testst", result.result);
      setFormValues({
        ...formValues,
        ["connection"]: {
          ...formValues["connection"],
          error: !result.result.isValidate,
          isTested: result.result.isValidate,
          value: "result.result.isValidate",
          errorMessage: "",
        },
      });
    } else {
      setFormValues({
        ...formValues,
        ["connection"]: {
          ...formValues["connection"],
          error: true,
          isTested: false,
          value: "result.result.isValidate",
          errorMessage: "",
        },
      });
      setIsLoadingConnection(false);
    }
  };
  const connectionStatus = () => {
    console.log("formValues.connection.error", formValues.connection.error);
    return formValues.connection.error ? (
      <div className={classes.testConnContainer}>
        {!isLoadingConnection && (
          <Close
            className={clsx(classes.textConLabelStyle, classes.errorColor)}
          />
        )}
        {!isLoadingConnection && (
          <InputLabel
            className={
              formValues.connection.error
                ? clsx(classes.textConLabelStyle, classes.errorColor)
                : clsx(classes.textConLabelStyle, classes.successColor)
            }
            id="failed-connection"
          >
            {formValues.connection.error
              ? "Connection Failed"
              : "Connection successful"}
          </InputLabel>
        )}
      </div>
    ) : formValues.connection.isTested && !isLoadingConnection ? (
      <div
        className={
          formValues.connection.value.length > 0
            ? classes.testConnContainer
            : ""
        }
      >
        {formValues.connection.value.length > 0 && (
          <>
            <Done
              className={clsx(classes.textConLabelStyle, classes.successColor)}
            />
            <InputLabel
              className={
                formValues.connection.error
                  ? clsx(classes.textConLabelStyle, classes.errorColor)
                  : clsx(classes.textConLabelStyle, classes.successColor)
              }
              id="successful-connection"
            >
              {!formValues.connection.error ? "Connection successful" : ""}
            </InputLabel>
          </>
        )}
      </div>
    ) : (
      <div></div>
    );
  };

  const createDatabase = async () => {
    if (
      !validateCompanyDetails({
        project_name: "",
        description: "",
        database_name: "database_name",
        database_provider: "database_provider",
        database_type: "database_type",
      })
    ) {
      let databse_details = {
        projectId: getUserDetailsFromCookies()?.projectId,
        name: formValues.database_source_name.value,
        description: "",
        port: formValues.port_number.value,
        hostName: formValues.host_name.value,
        userName: formValues.username.value,
        password: formValues.password.value,
        databaseTypeId: formValues.database_type.id,
        databasename: formValues.database_name.value,
        databaseProviderId: formValues.database_provider.id,
        createdBy: getUserDetailsFromCookies().id,
      };
      console.log("DBDBDBD payload", databse_details);
      await createNewDatabase(databse_details).then(async (resp: any) => {
        console.log("DBDBDBD resp coponent", resp);
        let user_details = getUserDetailsFromCookies();
        user_details.projectId = resp.result.projectId;
        user_details.createdBy = resp.result.createdBy;
        Cookies.set("user_details", JSON.stringify(user_details));
        var databaseTypeResponse = await getDatabaseTypes();
        setDatabaseType(databaseTypeResponse);
        var databaseProviderResponse = await getDatabaseProvider();
      });
    }
  };

  const handleNext = async () => {
    setIsDialogOpen(true);
    setDialogState("loading");
    setDialogMessage("Adding database...");

    try {
      await createDatabase();
      setDialogState("success");
      setDialogMessage("Database added successfully!");

      // Wait for 3 seconds before closing the dialog and moving to the next page
      setTimeout(() => {
        setIsDialogOpen(false);
        onNext(); // Move to the next page only after successful addition
      }, 3000);
    } catch (error) {
      console.error("Error adding database:", error);
      setDialogState("error");
      setDialogMessage("Failed to add database. Please try again.");

      // Wait for 3 seconds before closing the dialog
      setTimeout(() => {
        setIsDialogOpen(false);
      }, 3000);
    }
  };

  const validateCompanyDetails = (label: {
    project_name?: string;
    description?: string;
    database_type?: string;
    database_name?: string;
    database_provider?: string;
  }) => {
    let validate_pro_data = formValues;
    let isError = false;

    Object.entries(validate_pro_data).forEach(([key, value]) => {
      let cmp_key = key as keyof typeof validate_pro_data;
      if (cmp_key === label?.project_name || cmp_key === label?.description) {
        if (value.value === "" || value.value === "none") {
          validate_pro_data[cmp_key].error = true;
          isError = true;
        } else {
          validate_pro_data[cmp_key].error = false;
        }
      }
      if (
        label.database_name === cmp_key ||
        label.database_provider === cmp_key ||
        label.database_type === cmp_key
      ) {
        if (value.value === "" || value.value === "none") {
          validate_pro_data[cmp_key].error = true;
          isError = true;
          console.log("key value", label?.project_name);
        } else {
          validate_pro_data[cmp_key].error = false;
        }
      }
    });

    setFormValues({ ...validate_pro_data });
    return isError;
  };

  console.log("fofo", formValues);
  return (
    <div>
      <div className={classes.twoFieldsContainer}>
        <div className={clsx(classes.textFiledLabelContainer)}>
          <InputLabel className={classes.projectLabelStyle}>
            Source name <span className={classes.asterix}> *</span>
          </InputLabel>
          <CssDatabaseTextField
            required
            fullWidth
            onChange={handleProjectStateChange}
            value={formValues.database_source_name.value}
            name="database_source_name"
            error={formValues.database_name.error}
            helperText={
              formValues.database_source_name.error
                ? "Please enter database name"
                : ""
            }
            placeholder="Database source name"
            inputProps={{}}
            InputProps={{
              classes: { input: classes.proInput },
            }}
            id="database_source_name-css-standard-input"
          />
        </div>
        <div className={clsx(classes.textFiledProviderContainer)}>
          <InputLabel
            className={classes.projectLabelStyle}
            id="demo-simple-select-helper-labell"
          >
            Database type <span className={classes.asterix}> *</span>
          </InputLabel>
          <Select
            id="demo-simple-select-helper-typee"
            value={formValues.database_type.value}
            error={formValues.database_type.error}
            name="database_type"
            className={clsx(
              classes.projectSelect,

              formValues.database_type.value === "none"
                ? classes.projectSelectdisabled
                : null
            )}
            onFocus={(e) => setShowPlaceholder(false)}
            onChange={handleProjectStateChange}
            onClose={(e) => {
              setShowPlaceholder(
                (e.target as HTMLInputElement).value === undefined
              );
            }}
            defaultValue="none"
          >
            <MenuItem
              className={clsx(
                classes.projectMenuitem,
                !showPlaceholder
                  ? classes.projectMenuitemhidden
                  : null
              )}
              key="0"
              disabled
              value="none"
            >
              Database type
            </MenuItem>
            {databaseType.map((item: any) => (
              <MenuItem
                key={item?.id}
                className={classes.projectMenuitem}
                value={item?.name}
              >
                {item?.name}
              </MenuItem>
            ))}
          </Select>
          <InputLabel className={classes.prjDropdownErrorStyle}>
            {formValues.database_type.error
              ? "Please select database type"
              : ""}
          </InputLabel>
        </div>
      </div>
      <div className={classes.twoFieldsContainer}>
        <div className={classes.textFiledLabelContainer}>
          <InputLabel className={classes.projectLabelStyle}>
            Host name <span className={classes.asterix}> *</span>
          </InputLabel>
          <CssDatabaseTextField
            fullWidth
            value={formValues.host_name.value}
            error={formValues.host_name.error ? true : false}
            onChange={(e: any) => handleProjectStateChange(e)}
            name="host_name"
            placeholder="Host name"
            InputProps={{
              classes: { input: classes.proInput },
            }}
            id="host-css-standard-input"
          />
        </div>
        <div className={classes.portNumberContainerRight}>
          <InputLabel className={classes.projectLabelStyle}>
            Port number <span className={classes.asterix}> *</span>
          </InputLabel>
          <CssDatabaseTextField
            fullWidth
            value={formValues.port_number.value}
            error={formValues.port_number.error ? true : false}
            onChange={(e: any) => handleProjectStateChange(e)}
            name="port_number"
            placeholder="Port number"
            InputProps={{
              classes: { input: classes.proInput },
            }}
            id="port-css-standard-input"
          />
        </div>
      </div>
      <div className={classes.twoFieldsContainer}>
        <div className={clsx(classes.textFiledLabelContainer)}>
          <InputLabel className={classes.projectLabelStyle}>
            Database name <span className={classes.asterix}> *</span>
          </InputLabel>
          <CssDatabaseTextField
            required
            fullWidth
            onChange={handleProjectStateChange}
            value={formValues.database_name.value}
            name="database_name"
            error={formValues.database_name.error}
            helperText={
              formValues.database_name.error ? "Please enter database name" : ""
            }
            placeholder="Database name"
            inputProps={{}}
            InputProps={{
              classes: { input: classes.proInput },
            }}
            id="custom-css-standard-input"
          />
        </div>
        <div className={clsx(classes.textFiledLabelContainer)}>
          <InputLabel
            className={classes.projectLabelStyle}
            id="demo-simple-select-helper-label"
          >
            Database provider <span className={classes.asterix}> *</span>
          </InputLabel>
          <Select
            id="demo-simple-select-helper-type"
            value={formValues.database_provider.value}
            error={formValues.database_provider.error}
            name="database_provider"
            className={clsx(
              classes.projectSelect,

              formValues.database_provider.value === "none"
                ? classes.projectSelectdisabled
                : null
            )}
            onFocus={(e) => setShowPlaceholder(false)}
            onChange={handleProjectStateChange}
            onClose={(e) => {
              setShowPlaceholder(
                (e.target as HTMLInputElement).value === undefined
              );
            }}
            defaultValue="none"
          >
            <MenuItem
              className={clsx(
                classes.projectMenuitem,
                !showPlaceholder ? classes.projectMenuitemhidden : null
              )}
              key="0"
              disabled
              value="none"
            >
              Database provider
            </MenuItem>

            {databaseProvider.map((item: any) => (
              <MenuItem
                key={item?.id}
                className={classes.projectMenuitem}
                value={item?.name}
              >
                {item?.name}
              </MenuItem>
            ))}
          </Select>
          <InputLabel className={classes.prjDropdownErrorStyle}>
            {formValues.database_type.error
              ? "Please select database provider"
              : ""}
          </InputLabel>
        </div>
      </div>
      <div className={classes.twoFieldsContainer}>
        <div className={classes.textFiledLabelContainer}>
          <InputLabel className={classes.projectLabelStyle}>
            Username <span className={classes.asterix}> *</span>
          </InputLabel>
          <CssDatabaseTextField
            fullWidth
            value={formValues.username.value}
            error={formValues.username.error ? true : false}
            onChange={(e: any) => handleProjectStateChange(e)}
            name="username"
            placeholder="Username"
            InputProps={{
              classes: { input: classes.proInput },
            }}
            id="host-css-standard-input"
          />
        </div>
        <div className={classes.txtLabelProjectContainerRight}>
          <InputLabel className={classes.projectLabelStyle}>
            Password <span className={classes.asterix}> *</span>
          </InputLabel>
          <CssDatabaseTextField
            fullWidth
            value={formValues.password.value}
            error={formValues.password.error ? true : false}
            onChange={(e: any) => handleProjectStateChange(e)}
            name="password"
            type={"password"}
            placeholder="Password"
            InputProps={{
              classes: { input: classes.proInput },
            }}
            id="password-css-standard-input"
          />
        </div>
      </div>

      <div className={clsx(classes.proBtncontainer)}>
        <div
          style={{
            display: "flex",
            marginTop: "5px",
            flex: 1,
          }}
        >
          <div className={classes.twoFieldsContainer}>
            <div className={classes.testDbConnContainerRight}>
              <div
                style={{
                  display: "flex",
                  flexDirection: "row",
                }}
              >
                <div
                  style={{ display: "flex", flex: 1 }}
                  className={classes.btnProjectTestContainer}
                >
                  <StyledDatabaseButton
                    onClick={() => {
                      setIsLoadingConnection(true);
                      handleTestConnection();
                    }}
                    className={classes.testConnection}
                    variant="contained"
                    size="medium"
                    disabled={
                      formValues.database_type.value === "none" ||
                      formValues.database_provider.value === "none" ||
                      formValues.port_number.value === "" ||
                      formValues.host_name.value === "" ||
                      formValues.username.value === "" ||
                      formValues.password.value === "" ||
                      formValues.database_name.value === ""
                    }
                  >
                    Test Connection
                    <CircularProgress
                      className={classes.projectCicularView}
                      size={isLoadingConnection ? 10 : 0}
                    />
                  </StyledDatabaseButton>
                </div>
                <div
                  style={{
                    display: "flex",
                    flex: 1,
                    justifyContent: "flex-start",
                  }}
                >
                  {connectionStatus()}
                </div>
              </div>
            </div>
          </div>
          <StyledDatabaseButton
            onClick={() => {
              onCancel();
            }}
            variant="contained"
            className={classes.projectCancelBtnLabelStyle}
          >
            Back
          </StyledDatabaseButton>
          <Button
            onClick={() => {
              handleNext();
            }}
            variant="contained"
            className={classes.nextProBtn}
            disabled={!formValues.connection.isTested}
          >
            {isDialogOpen ? "Adding..." : "Add"}
          </Button>{" "}
        </div>
      </div>

      <Dialog
        open={isDialogOpen}
        onClose={() => setIsDialogOpen(false)}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        PaperProps={{
          style: {
            width: "650px",
            height: "120px",
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
            justifyContent: "flex-start",
            backgroundColor:
              dialogState === "loading"
                ? "#E3F2FD"
                : dialogState === "success"
                  ? "#E8F5E9"
                  : dialogState === "error"
                    ? "#FBCED6"
                    : "#FFFFFF",
            borderRadius: "10px",
            border:
              dialogState === "loading"
                ? "1px solid #2196F3"
                : dialogState === "success"
                  ? "1px solid #4CAF50"
                  : dialogState === "error"
                    ? "1px solid #FC6286"
                    : "1px solid #000000",
            overflow: "hidden",
          },
        }}
      >
        <DialogContent>
          <Grid container alignItems="center" spacing={2}>
            <Grid item>
              {dialogState === "loading" && (
                <CircularProgress style={{ color: "#2196F3" }} />
              )}
              {dialogState === "success" && (
                <Done style={{ color: "#4CAF50", fontSize: 24 }} />
              )}
              {dialogState === "error" && (
                <Close style={{ color: "#FC6286", fontSize: 24 }} />
              )}
            </Grid>
            <Grid item xs>
              <DialogContentText id="alert-dialog-description">
                {dialogMessage}
              </DialogContentText>
            </Grid>
          </Grid>
        </DialogContent>
      </Dialog>
    </div>
  );
};
export default AddDatabase;
