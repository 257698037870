import { Button, InputLabel } from "../../../../app.module";
import { Fonts } from "../../../constants";
const FinishProject = ({ onCancel }: { onCancel: () => void }) => {
  return (
    <div>
      <InputLabel
        style={{
          fontFamily: "'Nunito Sans'",
          fontStyle: "normal",
          fontWeight: "700",
          fontSize: "36px",
          lineHeight: "49px",
          color: "#000000",
        }}
      >
        New project created!
      </InputLabel>
      <p
        style={{
          fontFamily: Fonts.Inter.regular,
          fontStyle: "normal",
          fontWeight: "400",
          fontSize: "22px",
          lineHeight: "29px",
          color: "#000000",
          width: "90%",
        }}
      >
        Thank you for providing us with the required information. Your project
        is now set-up and you are ready to use WingsBI.
      </p>
      <Button
        onClick={() => {
          onCancel();
        }}
        variant="contained"
        style={{
          background:
            "linear-gradient(90deg, #5EB0FA 0%, #1157CD 50.54%, #9C90FA 100%)",
          fontFamily: Fonts.NunitoSans.regular,
          fontStyle: "normal",
          fontWeight: 700,
          fontSize: "18px",
          lineHeight: "25px",
          borderRadius: "5px",
          textTransform: "capitalize",
          height: "57px",
          width: "155px",
          marginTop: "48%",
          marginLeft:"78%"
        }}
      >
        Finish
      </Button>{" "}
    </div>
  );
};

export default FinishProject;
