import Cookies from "js-cookie";
export const getUserDetailsFromCookies = () => {
  let userDetailsString = Cookies.get("user_details");
  if (userDetailsString) {
    try {
      // Parse the cookie value as JSON
      //userDetails = JSON.parse(userDetailsString);
      // Now you can access properties in the userDetails object
      //console.log(userDetails.id); // Assuming there is a 'data' property inside userDetails
      return JSON.parse(userDetailsString);
    } catch (error) {
      console.error("Error parsing userDetails cookie:", error);
    }
  } else {
    console.log("No user_details cookie found.");
  }
};
export const getUserAccessTokenFromCookies = () => {
  let userAcccessToken = Cookies.get("access_token");
  if (userAcccessToken) {
    try {
      // Now you can access properties in the userDetails object
      return JSON.parse(userAcccessToken);
    } catch (error) {
      console.error("Error parsing userAcccessToken cookie:", error);
    }
  } else {
    console.log("No userAcccessToken cookie found.");
  }
};
export const getQueryIdFromCookies = () => {
  let userDetailsString = Cookies.get("query_id");
  if (userDetailsString) {
    try {
      // Parse the cookie value as JSON
      //userDetails = JSON.parse(userDetailsString);
      // Now you can access properties in the userDetails object
      //console.log(userDetails.id); // Assuming there is a 'data' property inside userDetails
      return JSON.parse(userDetailsString);
    } catch (error) {
      console.error("Error parsing userDetails cookie:", error);
    }
  } else {
    console.log("No user_details cookie found.");
  }
};

// export const getSignInOrSignupFromCookies = () => {
//   let authTypeString = Cookies.get("auth_type");
//   if (authTypeString) {
//     try {
//       // Parse the cookie value as JSON
//       //userDetails = JSON.parse(userDetailsString);
//       // Now you can access properties in the userDetails object
//       //console.log(userDetails.id); // Assuming there is a 'data' property inside userDetails
//       return JSON.parse(authTypeString);
//     } catch (error) {
//       console.error("Error parsing auth_type cookie:", error);
//     }
//   } else {
//     console.log("No user_details cookie found.");
//   }
// };
