import {
  React,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  IconButton,
  useState,
  useRef,
  useEffect,
  Close,
  Edit,
  clsx,
  Button,
  InputLabel,
  TableContainer,
  Modal,
  Box,
  Backdrop,
  CircularProgress,
} from "../../../app.module";
import { ForwardedRef, useImperativeHandle } from "react";
import {
  useStyle,
  StyledTableActionButton,
  TableHeaderTextField,
} from "./DataTableStyle";
interface ChildMethods {
  childMethod: () => void;
  onSaveData: (data: any) => void;
  resetchildMethod: () => void;
}

interface DataTableProps {
  data: Record<string, any>[];
  columns: string[];
  errorModal: boolean;
  handleErrorModal: () => void;
  onRemoveColumn: (column: string) => void;
  onRenameHeader: (
    headers: string[],
    oldHeader: string,
    newHeader: string,
    index: number
  ) => void;
  isLoading: boolean;
  tableIsLoading: boolean;
  handleClose: () => void;
}

const DataTable = React.forwardRef<ChildMethods, DataTableProps>(
  (props, ref: ForwardedRef<ChildMethods>) => {
    const { classes } = useStyle();
    const containerRef = useRef<HTMLInputElement | null>(null);
    const {
      data,
      columns,
      tableIsLoading,
      errorModal,
      handleClose,
      onRemoveColumn,
      handleErrorModal,
      onRenameHeader,
    } = props;
    const [isHovered, setIsHovered] = useState(false);
    const [hoveredColumn, setHoveredColumn] = useState(0);
    const [visibleColumns, setVisibleColumns] = useState<string[]>([
      ...columns,
    ]);
    const [virtualVisibleColumns, setVirtualVisibleColumns] = useState<
      string[]
    >([...columns]);
    const [selectedColumn, setSelectedColumn] = useState("");
    const [rowData, setRowData] = useState([...data]);
    const [editingHeader, setEditingHeader] = useState<string | null>(null);
    const [headerValues, setHeaderValues] = useState<{ [key: string]: string }>(
      {}
    );
    const [isChildModalOpen, setIsChildModalOpen] = useState(false);
    const [isResetChildModalOpen, setResetChildModalOpen] = useState(false);
    const [columnCursorPosition, setColumnCursorPosition] = useState(0);
    useEffect(() => {
      const { data, columns, onRemoveColumn, onRenameHeader } = props;
    }, [visibleColumns, editingHeader, rowData, virtualVisibleColumns]);
    useEffect(() => {
      if (containerRef.current) {
        containerRef.current.setSelectionRange(
          columnCursorPosition,
          columnCursorPosition
        );
      }
    }, [editingHeader, columnCursorPosition]);
    const childMethod = () => {
      console.log("Child method called");
      setVirtualVisibleColumns([...columns]);
      setRowData([...data]);
      setVisibleColumns([...columns]);
      setResetChildModalOpen(false);
    };
    const resetchildMethod = () => {
      setResetChildModalOpen(true);
    };
    const onSaveData = (data: any) => {
      setVisibleColumns([...data.column]);
      setVirtualVisibleColumns([...data.column]);
      setRowData([...data.rowData]);
      console.log("Updated Data Set", data);
    };
    useImperativeHandle(ref, () => ({
      childMethod,
      onSaveData,
      resetchildMethod,
    }));
    const deleteColomnData = () => {
      let keys = [...virtualVisibleColumns];
      let finalTableData = rowData.map((item) => {
        const newItem: any = {};
        for (const key in item) {
          if (keys.includes(key)) {
            continue;
          }
          newItem[key] = item[key];
        }
        return newItem;
      });

      setRowData([...finalTableData]);
    };
    const handleRemoveColumn = () => {
      const updatedColumns = visibleColumns.filter((col) => {
        return col !== selectedColumn;
      });
      setVisibleColumns([...updatedColumns]);
      setVirtualVisibleColumns([...updatedColumns]);
      onRemoveColumn(selectedColumn);
      handleCloseChild();
    };
    const handleHeaderRename = (oldHeader: string, newHeader: string) => {
      console.log("sdfmsbdmfd", oldHeader);
      const updatedHeaderValues = { ...headerValues, [oldHeader]: newHeader };
      setHeaderValues(updatedHeaderValues);
      onRenameHeader([], oldHeader, newHeader, 0);
      setEditingHeader(null);
    };

    const handleHeaderDoubleClick = (column: string) => {
      setEditingHeader(column);
    };
    const handleMouseEnter = (index: number) => {
      setIsHovered(true);
      setHoveredColumn(index);
    };

    const handleMouseLeave = (index: number) => {
      setIsHovered(false);
      setHoveredColumn(index);
    };
    const handleHeaderBlur = (index: number) => {
      console.log("asfmjagsfdas", index);
      setEditingHeader(null);
      let tempdata: Record<string, any>[] = rowData;
      if (visibleColumns[index] !== virtualVisibleColumns[index]) {
        const oldKey = visibleColumns[index] + "";
        const newKey = virtualVisibleColumns[index] + "";
        const renamedData = tempdata.map((item) => {
          const renamedItem = { ...item };

          if (renamedItem.hasOwnProperty(oldKey)) {
            renamedItem[newKey] = renamedItem[oldKey];
            delete renamedItem[oldKey];
          }

          return renamedItem;
        });
        setRowData([...renamedData]);
        setVisibleColumns([...virtualVisibleColumns]);
        onRenameHeader(virtualVisibleColumns, "oldHeader", "newHeader", index);
      }
    };
    const handleInputFocus = (event: any) => {
      setColumnCursorPosition(event.target.selectionStart);
    };
    const handleHeaderChange = (
      event: React.ChangeEvent<HTMLInputElement>,
      idx: number
    ) => {
      const { value } = event.target;
      let tempVisibleColumns = virtualVisibleColumns;
      tempVisibleColumns[idx] = value;
      setVirtualVisibleColumns([...tempVisibleColumns]);
      setEditingHeader(value);
      setColumnCursorPosition(event.target.selectionStart || 0);
    };
    const handleCloseChild = () => {
      setIsChildModalOpen(false);
    };
    const moduleErrorModal = () => {
      return (
        <React.Fragment>
          <Modal
            open={errorModal}
            onClose={() => {
              handleCloseChild();
            }}
            aria-labelledby="child-modal-title"
            aria-describedby="child-modal-description"
          >
            <Box
              sx={{
                position: "absolute" as "absolute",
                top: "50%",
                left: "50%",
                transform: "translate(-50%, -50%)",
                width: 300,
                display: "flex",
                flexDirection: "column",
                bgcolor: "background.paper",
                justifyContent: "center",
                boxShadow: 24,
                borderRadius: "5px",
                pt: 2,
                px: 4,
                pb: 3,
               
              }}
            >
              <h4
                style={{
                  textAlign: "center",
                }}
                id="child-modal-title"
              >
                Not able to create the modules using this data?
              </h4>
              <div className={clsx(classes.columnDeleteBtncontainer)}>
                <div>
                  <Button
                    onClick={() => {
                      handleErrorModal();
                    }}
                    variant="contained"
                    className={classes.errorModalBtn}
                  >
                    Try aonther query
                  </Button>{" "}
                </div>
              </div>
            </Box>
          </Modal>
        </React.Fragment>
      );
    };
    const ChildModal = () => {
      return (
        <React.Fragment>
          <Modal
            open={isChildModalOpen}
            onClose={() => {
              handleCloseChild();
            }}
            aria-labelledby="child-modal-title"
            aria-describedby="child-modal-description"
          >
            <Box
              sx={{
                position: "absolute" as "absolute",
                top: "50%",
                left: "50%",
                transform: "translate(-50%, -50%)",
                width: 300,
                display: "flex",
                flexDirection: "column",
                bgcolor: "background.paper",
                justifyContent: "center",
                boxShadow: 24,
                borderRadius: "5px",
                pt: 2,
                px: 4,
                pb: 3,
              }}
            >
              <h4
                style={{
                  textAlign: "center",
                }}
                id="child-modal-title"
              >
                Do you want to delete this column?
              </h4>
              <div className={clsx(classes.columnDeleteBtncontainer)}>
                <div>
                  <StyledTableActionButton
                    onClick={() => {
                      handleCloseChild();
                    }}
                    variant="contained"
                    className={classes.columnDeleteBtnLabelStyle}
                  >
                    No
                  </StyledTableActionButton>
                  <Button
                    onClick={() => {
                      handleRemoveColumn();
                    }}
                    variant="contained"
                    className={classes.columnDeleteBtnYes}
                  >
                    Yes
                  </Button>{" "}
                </div>
              </div>
            </Box>
          </Modal>
        </React.Fragment>
      );
    };
    const resetChildModal = () => {
      return (
        <React.Fragment>
          <Modal
            open={isResetChildModalOpen}
            onClose={() => {
              handleCloseChild();
            }}
            aria-labelledby="child-modal-title"
            aria-describedby="child-modal-description"
          >
            <Box
              sx={{
                position: "absolute" as "absolute",
                top: "50%",
                left: "50%",
                transform: "translate(-50%, -50%)",
                width: 300,
                display: "flex",
                flexDirection: "column",
                bgcolor: "background.paper",
                justifyContent: "center",
                boxShadow: 24,
                borderRadius: "5px",
                pt: 2,
                px: 4,
                pb: 3,
              }}
            >
              <h4
                style={{
                  textAlign: "center",
                }}
                id="child-modal-title"
              >
                Do you want to reset the table data?
              </h4>
              <div className={clsx(classes.columnDeleteBtncontainer)}>
                <div>
                  <StyledTableActionButton
                    onClick={() => {
                      setResetChildModalOpen(false);
                    }}
                    variant="contained"
                    className={classes.columnDeleteBtnLabelStyle}
                  >
                    No
                  </StyledTableActionButton>
                  <Button
                    onClick={() => {
                      childMethod();
                    }}
                    variant="contained"
                    className={classes.columnDeleteBtnYes}
                  >
                    Yes
                  </Button>{" "}
                </div>
              </div>
            </Box>
          </Modal>
        </React.Fragment>
      );
    };
    return (
      <TableContainer
        sx={{
          maxHeight: 660,
          marginRight: "30px",
        }}
      >       
        {ChildModal()}
        {moduleErrorModal()}
        {resetChildModal()}
        <Table stickyHeader aria-label="sticky table">
          <TableHead>
            <TableRow>
              {virtualVisibleColumns.map((column: any, index: number) => (
                <React.Fragment key={column}>
                  <TableCell
                    style={{
                      padding: "4px",
                      backgroundColor: "#9ecffc",
                      borderRight: "1px solid #ccc",
                      borderLeft: "1px solid #ccc",
                      borderTop: "1px solid #ccc",
                      border: "1px solid #ccc",
                      height: "30px",
                    }}
                    key={column}
                  >
                    {editingHeader === column ? (
                      <div className={classes.tableHeaderInputContainer}>
                        <TableHeaderTextField
                          value={column}
                          autoFocus
                          onFocus={(e) =>
                            setColumnCursorPosition(
                              e.target.selectionStart || 0
                            )
                          }
                          fullWidth
                          error={false}
                          helperText={""}
                          inputRef={containerRef} 
                          onBlur={() => handleHeaderBlur(index)}
                          onChange={(e: any) => handleHeaderChange(e, index)}
                          onKeyPress={(event: any) => {
                            if (event.key === "Enter") {
                              const newHeader = event.currentTarget.value;
                              handleHeaderRename(column, newHeader);
                            }
                          }}
                          InputProps={{
                            classes: { input: classes.tableHeaderInput },
                          }}
                          id="header-input"
                        />
                      </div>
                    ) : (
                      <>
                        <div
                          style={{
                            display: "flex",
                            flexDirection: "column",
                          }}
                        >
                          <div
                            style={{
                              display: "flex",
                              justifyContent: "flex-end",
                              alignItems: "flex-end",
                              textAlign: "right",
                              flex: 1,
                            }}
                          >
                            <Button
                              onMouseEnter={() => {
                                handleMouseEnter(index);
                              }}
                              onMouseLeave={() => {
                                handleMouseLeave(index);
                              }}
                              style={{
                                position: "relative",
                                height: "7px",
                                padding: "0px",
                                margin: "0px",
                                display: "flex",
                                marginTop: "4px",
                                backgroundColor: "#9ecffc",
                                justifyContent: "flex-end",
                              }}
                            >
                              {isHovered && index === hoveredColumn && (
                                <IconButton
                                  style={{
                                    width: "18px",
                                    height: "18px",

                                    padding: "0px",
                                    margin: "0px",
                                  }}
                                  onClick={() => {
                                    setSelectedColumn(column);
                                    setIsChildModalOpen(true);
                                  }}
                                >
                                  <Close
                                    style={{

                                      width: "15px",
                                      height: "15px",
                                      padding: "0px",
                                      margin: "0px",
                                    }}
                                  />
                                </IconButton>
                              )}
                            </Button>
                            <Button
                              onMouseEnter={() => {
                                handleMouseEnter(index);
                              }}
                              onMouseLeave={() => {
                                handleMouseLeave(index);
                              }}
                              style={{
                                position: "relative",
                                height: "7px",
                                padding: "0px",
                                margin: "0px",
                                display: "flex",
                                marginTop: "4px",
                                backgroundColor: "#9ecffc",
                                justifyContent: "flex-end",
                              }}
                            >
                              {isHovered && index === hoveredColumn && (
                                <IconButton
                                  style={{
                                    width: "18px",
                                    height: "18px",

                                    padding: "0px",
                                    margin: "0px",
                                  }}
                                  onClick={() => {
                                   handleHeaderDoubleClick(column)
                                  }}
                                >
                                  <Edit
                                    style={{
                                      width: "15px",
                                      height: "15px",
                                      padding: "0px",
                                      margin: "0px",
                                    }}
                                  />
                                </IconButton>
                              )}
                            </Button>
                          </div>
                          <div
                            style={{
                              display: "flex",
                              justifyContent: "center",
                              alignItems: "center",
                              paddingBottom: "7px",
                            }}
                          >
                            <InputLabel
                              className={classes.tableHeaderLabelStyle}
                            >
                              {headerValues[column] || column}
                            </InputLabel>
                          </div>
                        </div>
                      </>
                    )}
                  </TableCell>
                </React.Fragment>
              ))}
            </TableRow>
          </TableHead>

          <TableBody>
            {rowData.map((row: any, index: number) => (
              <TableRow key={index}>
                {visibleColumns.map((column: any) => (
                  <TableCell
                    style={{
                      padding: "8px",
                      textAlign: "center",
                      borderRight: "1px solid #ccc",
                      borderLeft: "1px solid #ccc",
                    }}
                    key={column}
                  >
                    {row[column]}
                  </TableCell>
                ))}
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    );
  }
);

export default DataTable;
