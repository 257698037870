
import {
  React,
  InputLabel,
  useNavigate,
  useEffect,
  useLocation,
  useState,
  useRef,
  CircularProgress,
} from "../../../../app.module";
import { Colors, Images, Fonts } from "../../../constants";
import {
  getModuleListForDasboard,
  getModuleChartForDasboard,
  buildDashboardFromModules,
  DashboardUpdate,
  getModuleListData,
} from "../ModulesService";
import "../ModulesStyle";
import {
  useStyle,
  CssDashboardTextField,
  StyledQueryButton,
} from "../CreateDashboardFromModulesStyle";
import BarChartForDashboard from "../dasboard_charts/BarChartForDashboard";
import PieChartForDashboard from "../dasboard_charts/PieChartForDashboard";
import LineChartForDashboard from "../dasboard_charts/LineChartForDashboard";
import BumpChartForDashboard from "../dasboard_charts/BumpChartForDashboard";
import AreaBumpForDashboard from "../dasboard_charts/AreaBumpForDashboard";
import ScatterPlotForDashboard from "../dasboard_charts/ScatterPlotForDashboard";
import RadialChartForDashboard from "../dasboard_charts/RadialChartForDashboard";
import RadarChartForDashboard from "../dasboard_charts/RadarChartForDashboard";
import AreaChartForDashboard from "../dasboard_charts/AreaChartForDashboard";
import CardChartForDashboard from "../dasboard_charts/CardChartForDashboard";
import TableChartForDashboard from "../dasboard_charts/TableChartForDashboard";
import { Card, CardContent, Typography } from "../../../../app.module";
import { Layouts, Responsive, WidthProvider } from "react-grid-layout";
import CancelIcon from "@mui/icons-material/Cancel";
import { useStyle1 } from "../../chart_dashboard/ModuleDashboardListStyle";
import "react-grid-layout/css/styles.css"; 
import "react-resizable/css/styles.css";
import { getUserDetailsFromCookies, postData } from "../../../services";
import { showDash } from "../../chart_dashboard/DashboardListService";
import Dialog from '@mui/material/Dialog';
import CloseIcon from '@mui/icons-material/Close';
import { DialogTitle } from "@mui/material";





const ResponsiveGridLayout = WidthProvider(Responsive);

type Module = {
  h: number;
  w: number;
  y: number;
  x: number;
  id: string;
  name: string;

};
type LayoutModulesData = {
  [key: string]: Module; 
};

interface ChartItemData {
  id: number;
  name: string;
  description: {
    chartType: string;
    data: any; 
  } | null;
}

type Layout = { i: string; x: number; y: number; w: number; h: number }[];
type ModuleData = {
  moduleId: number;
  moduleName: string;
  visualizationData: string;

  moduleHeight: number;
  moduleWidth: number;
  xaxis: number;
  yaxis: number;
};

type Coordinates = {
  moduleId: string;
  x: number;
  y: number;
  w: number;
  h: number;
};

type BreakpointCoordinates = {
  [key: string]: Coordinates[] | undefined;
};

const EditDashboard = () => {
  const [moduleList, setModuleList] = useState<any[]>([]);
  const [layouts, setLayouts] = useState<{ [key: string]: any[] }>({ lg: [] });
  const [breakpointCoordinates, setBreakpointCoordinates] = useState<BreakpointCoordinates>({});
 


  const [showGrid, setShowGrid] = useState(false);
  const navigate = useNavigate();
  const [modules, setModules] = useState([]);

  const [layoutModulesData, setLayoutModulesData] = useState<LayoutModulesData>(
    {}
  );
  const [dashboardChart, setdashboardChart] = useState<ChartItemData | null>();

  const [dashboardLayouts, setDashboardLayouts] = useState<DashboardLayouts>({
    lg: [],
  });

  const [isLoading, setIsLoading] = useState(true);

  const [dashboardName, setDashboardName] = useState("");
  const [dashboardNameError, setDashboardNameError] = useState(false);
  const [currentBreakpoint, setCurrentBreakpoint] = useState('lg');

  const [isModuleListVisible, setIsModuleListVisible] = useState(true);
  const location = useLocation();
  const [dashDet, setDashDet] = useState(location.state?.dashboardId);
  const [currentLayout, setCurrentLayout] = useState<Layout>([]);

  const [windowWidth, setWindowWidth] = useState(window.innerWidth);

  const [forceRerender, setForceRerender] = useState(0);

  const [gridHeight, setGridHeight] = useState("87vh");
  const [openDialog, setOpenDialog] = useState(false);
 // const [hasChanges, setHasChanges] = useState(false);
 const [isLayoutChanged, setIsLayoutChanged] = useState(false);

  const gridContainerRef = useRef<HTMLDivElement>(null);
  const updateGridHeight = () => {
    if (gridContainerRef.current) {
      let maxBottom = 0;
      Array.from(gridContainerRef.current.childNodes).forEach((child: any) => {
        if (child instanceof HTMLElement) {
          const rect = child.getBoundingClientRect();
          maxBottom = Math.max(maxBottom, rect.bottom);
        }
      });
  
      const viewportHeight = window.innerHeight;
      if (maxBottom > viewportHeight * 0.87) {
        const newHeight = maxBottom + 20; 
        gridContainerRef.current.style.height = `${newHeight}px`;
      } else {
        gridContainerRef.current.style.height = "87vh";
      }
    }
  };

  const handleOpenDialog = () => {
    setOpenDialog(true);
    setTimeout(() => {
      setOpenDialog(false);
    }, 3000); // Closes the dialog after 3 seconds
  };

 
 
  const dialogStyle : React.CSSProperties = {
    width: "622px",
    height: "99px",
    borderRadius: "10px",
    border: "1px solid #61CE8D",
   backgroundColor: "#CBEBD8",
   overflow: "hidden",
    display: "flex",
    position: "absolute", // Use fixed if you want it to be in the center regardless of scrolling
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)", // This centers the dialog
    zIndex: 1000, // Ensure
  };

  const dialogTitleStyle: React.CSSProperties = {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-start',
    position: 'absolute',
    top: '50%',
    transform: 'translateY(-50%)',
    zIndex: 999,
    marginLeft: '90px',
    fontSize: '24px',
    fontFamily: 'Nunito Sans, sans-serif',
  
  };
  

  const getBreakpoints = (width:number) => {
    return {
      lg: width - 1, 
      md: width > 996 ? 996 : width - 1,
      sm: width > 768 ? 768 : width - 1,
      xs: width > 480 ? 480 : width - 1,
      xxs: 0,
    };
  };
  const getCols = (width:number) => {
    return {
      lg: 12,
      md: 10,
      sm: 8,
      xs: 6,
      xxs: 4,
    };
  };

  useEffect(() => {
    const handleResize = () => {
      setWindowWidth(window.innerWidth);
    };

    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);
  const breakpoints = getBreakpoints(windowWidth);
  const cols = getCols(windowWidth);
  console.log("DashDet",dashDet)

  const onBreakpointChange = (newBreakpoint: string) => {
    setCurrentBreakpoint(newBreakpoint);
  };
  
  const { classes } = useStyle();
  const { classes: classes1 } = useStyle1();

  useEffect(() => {
    const savedLayout = localStorage.getItem("dashboardLayout");
    if (savedLayout) {
      setDashboardLayouts({ lg: JSON.parse(savedLayout) });
    }
  }, []);

  useEffect(() => {
    const updateBreakpointCoordinates = () => {
      const newBreakpointCoordinates: BreakpointCoordinates = {};
      Object.keys(layouts).forEach((breakpoint) => {
        newBreakpointCoordinates[breakpoint] = layouts[breakpoint]?.map((layout) => ({
          moduleId: layout.i,
          x: layout.x,
          y: layout.y,
          w: layout.w,
          h: layout.h,
        }));
      });
      setBreakpointCoordinates(newBreakpointCoordinates);
    };

    updateBreakpointCoordinates();
  }, [layouts]);

  useEffect(() => {
    console.log("Breakpoint Coordinates:", breakpointCoordinates);
  }, [breakpointCoordinates]);

  useEffect(() => {
    if (location.state && location.state.response) {
      const { response } = location.state;
      if (response && response.statusCode === 200 && !response.isError) {
        setModuleList(response.result); 
        setIsLoading(false); 
      } else {
        console.error("Unexpected response structure or error status: ", response);
      }
    } else {
      const fetchModuleList = async () => {
        setIsLoading(true);
        try {
          const modules = await getModuleListForDasboard(); 
          setModuleList(modules);
        } catch (error) {
          console.error("Error fetching module list: ", error);
        } finally {
          setIsLoading(false);
        }
      };
      fetchModuleList();
    }
  }, [location.state]); 
  
  const onDragStart = (
    event: React.DragEvent<HTMLDivElement>,
    module: Module
  ) => {
    console.log("Module being dragged:", module); 
    event.dataTransfer.setData("module", JSON.stringify(module));
  };

  type ModuleDescription = {
    chartType: string;
    data: any; 
  };

  type Module = {
    id: string;
    name: string;
    description: ModuleDescription; 
  };
  interface LayoutItem {
    i: string;
    x: number;
    y: number;
    w: number;
    h: number;
  }

  interface DashboardLayouts {
    lg: LayoutItem[];
  }

  const buildDashboardFromModules = async (requestBody: DashboardPayload) => {
    return await postData(
      `/${getUserDetailsFromCookies().identifier}/api/project/${
        getUserDetailsFromCookies().projectId
      }/dashboard`,
      requestBody
    ).then((result: any) => {
      return result;
    });
  };


  interface DashboardPayload {
    name: string;
    description: string;
    projectId: number; 
    ModuleCoordinateRequests: {
      moduleId: number;
      xaxis: number;
      yaxis: number;
      width: number;
      height: number;
    }[];
  }

  type ChartData = {
    chartType: string;
    data: any; 
  };

  type DashboardModule = {
    moduleId: number;
    moduleName: string;
    xaxis?: number;
    yaxis?: number;
    moduleWidth?: number;
    moduleHeight?: number;
  };

  const buildPayload = () => {
    const userDetails = getUserDetailsFromCookies();
    const coordinates = layouts[currentBreakpoint].map((layoutItem) => ({
      moduleId: parseInt(layoutItem.i),
      xaxis: layoutItem.x,
      yaxis: layoutItem.y,
      width: layoutItem.w,
      height: layoutItem.h,
    }));

   
      const payload = {
        name: dashboardName,
        description: "", 
        projectId: userDetails.projectId,
        ModuleCoordinateRequests: coordinates,
      };
  console.log("Payload to be send",payload)
    return payload;
  };
  const onCancelClick = (moduleId:string) => {
    setLayouts((prevLayouts) => ({
      ...prevLayouts,
      lg: prevLayouts.lg.filter((item) => item.i !== moduleId),
    }));
    const removedModule = layouts.lg.find((item) => item.i === moduleId);
    if (removedModule) {
      const moduleDataToRestore = {
        id: removedModule.i,
        name: removedModule.chartsdata.name,
      };
      setModuleList((prevModuleList) => [...prevModuleList, moduleDataToRestore]);
    }
  };
  
  const dashboardId = location?.state.dashboardId;
  const dashboardNamee = location?.state.dashboardName;


  useEffect(() => {
    
    const updateLayoutsWithChartData = async () => {
      let updated = false;

      for (const layoutItem of layouts.lg) {
        if (!layoutItem.chartsdata) {
          try {
            const visualizationData = await getModuleChartForDasboard(layoutItem.i);
            console.log("Visualization data",visualizationData)
            
            const data = JSON.parse(visualizationData.visualizationData);
            console.log("CHartType in useeffect",data.chartType)
            console.log("chart data in useeffect",data.data)
            console.log("layoutItem Siddesh",layoutItem)
            
            layoutItem.chartsdata = {
              id: layoutItem.i,
              name: layoutItem.i || undefined, 
              description: {
                chartType: data.chartType,
                data: data.data,
              },
            };
            updated = true;
          } catch (error) {
            console.error("Error fetching chart data for module:", layoutItem.i, error);
          }
        }
      }

      if (updated) {
        setLayouts({ lg: [...layouts.lg] }); 
      }
    };

    if (layouts.lg.length > 0) {
      updateLayoutsWithChartData();
    }
  }, [layouts.lg]);

  useEffect(() => {
    const fetchDashboardDataAndSetLayout = async () => {
      
      if (!dashDet) {
        console.error("Dashboard ID is missing.");
        return;
      }
        
      setIsLoading(true);
      try {
        const response = await showDash(dashDet);
        if (response && response.modules) {
          const layoutItems = response.modules.map((module:ModuleData) => ({
            i: module.moduleId.toString(),
            x: module.xaxis ,
            y: module.yaxis ,
            w: module.moduleWidth ,
            h: module.moduleHeight ,
            chartsdata: {
              id: module.moduleId,
              name: module.moduleName, 
              description: JSON.parse(module.visualizationData) 
            }
          }));
          console.log("Layout items:", layoutItems);

          const moduleIds = layoutItems.map((item: { i: any; }) => item.i);
          console.log("Module IDs:", moduleIds);
          setLayouts({ lg: layoutItems });
        } else {
          console.error("Failed to fetch dashboard data or no modules found");
        }
      } catch (error) {
        console.error("Error fetching dashboard data:", error);
      } finally {
        setIsLoading(false);
      }
    };
    if (dashDet) {
      fetchDashboardDataAndSetLayout();
    }
  }, [dashDet]);

  type ScreenSize = 'lg' | 'md' | 'sm' | 'xs' | 'xxs';

  const onDrop = async (
    layout: any[],
    layoutItem: any,
    event: DragEvent
  ): Promise<void> => {
    console.log("Drop event triggered with layoutItem:", layoutItem);
   // setHasChanges(true);
    if (event.dataTransfer) {
      const moduleData = event.dataTransfer.getData("module");
      console.log("Dropped module data (raw):", moduleData);
  
      if (moduleData) {
        try {
          const module = JSON.parse(moduleData);
          console.log("Parsed dropped module:", module);
  
          const visualizationData = await getModuleChartForDasboard(module.id);
          console.log("Fetched visualization data for module:", module.id, visualizationData);
  
          const data = JSON.parse(visualizationData.visualizationData);
          console.log("dataaaa", data);
          if (!data.chartType || !data.data) {
            console.error("Invalid data format for chart:", data);
            return;
          }
  
          const sizeConfigurations: Record<ScreenSize, { x: number; y: number; w: number; h: number }> = {
            lg: { x: 3, y: 4, w: 4, h: 2 },
            md: { x: 2, y: 3, w: 3, h: 2},
            sm: { x: 1, y: 2, w: 2, h: 2 },
            xs: { x: 0, y: 1, w: 1, h: 3 },
            xxs: { x: 0, y: 0, w: 1, h: 2 },
          };
  
          setLayouts((currentLayouts) => {
            const updatedLayouts = { ...currentLayouts };
            Object.keys(sizeConfigurations).forEach((sizeKey) => {
              // Assert the key as a ScreenSize to ensure type safety
              const size = sizeKey as ScreenSize;
              const config = sizeConfigurations[size];
              const newItem = {
                ...layoutItem,
                i: module.id.toString(),
                ...config,
                chartsdata: {
                  id: module.id,
                  name: module.name,
                  description: data, 
                },
              };
              if (updatedLayouts[size]) {
                updatedLayouts[size] = [...updatedLayouts[size], newItem];
              } else {
                updatedLayouts[size] = [newItem]; 
              }
            });
  
            return updatedLayouts;
          });
  
          setModuleList((currentModuleList) =>
            currentModuleList.filter((m) => m.id !== module.id)
          );
  
          setLayoutModulesData((prevData) => {
            const newData = { ...prevData };
            newData[module.id] = module;
            return newData;
          });
  
          console.log("Layouts and visualization data updated for module:", module.id);
        } catch (error) {
          console.error("Error during onDrop operation:", error);
        }
      } else {
        console.log("No module data found in the drop event dataTransfer.");
      }
    }
  };

  console.log("Current Layout Siddesh",currentLayout)
  console.log("module_list", moduleList);
  console.log("Layouts", layouts);

  const toggleModuleListVisibility = () => {
    setIsModuleListVisible((prevVisibility) => !prevVisibility);
  };
  


  const ChartsData = (chartsdata: ChartItemData) => {
    console.log("dataaa", chartsdata);
    if (
      !chartsdata ||
      !chartsdata.description ||
      !chartsdata.description.chartType
    ) {
      console.error("Invalid or missing description for item:", chartsdata);
      
    }


    switch (chartsdata?.description?.chartType) {
      case "BarChart":
        console.log("Data for BAR  is ", chartsdata?.description);
        return (
          <BarChartForDashboard
            dbsource={"selecteddb"}
            data={chartsdata?.description}
            pageError={""}
            bardata={chartsdata?.description?.data}
          />
        );
      case "PieChart":
        console.log("Data for Pie  is ", chartsdata?.description);
        return (
          <PieChartForDashboard
            dbsource={"selecteddb"}
            data={chartsdata?.description}
            pageError={""}
            bardata={chartsdata?.description?.data}
          />
        );
      case "RadialBarChart":
        console.log("Item", chartsdata);
        return (
          <RadialChartForDashboard
          dbsource={"selecteddb"}
          data={chartsdata?.description}
          pageError={""}
          bardata={chartsdata?.description?.data}
          />
        );

      case "CardChart":
        console.log("Item", chartsdata);
        return (
          <CardChartForDashboard
          dbsource={"selecteddb"}
          data={chartsdata?.description}
          pageError={""}
          bardata={chartsdata?.description?.data}
          />
        );
        case "TableChart":
          console.log("Item", chartsdata);
          return (
            <TableChartForDashboard
            dbsource={"selecteddb"}
            data={chartsdata?.description}
            pageError={""}
            bardata={chartsdata?.description?.data}
            />
          );
      case "RadarChart":
        console.log("Data for RadarChart is ", chartsdata?.description?.data);
        return (
          <RadarChartForDashboard
          dbsource={"selecteddb"}
          data={chartsdata?.description}
          pageError={""}
          bardata={chartsdata?.description?.data}
          />
        );
        case "AreaChart":
          console.log("Data for AreaChart is ", chartsdata?.description?.data);
          return (
            <AreaChartForDashboard
            dbsource={"selecteddb"}
            data={chartsdata?.description}
            pageError={""}
            bardata={chartsdata?.description?.data}
            />
          );
      case "LineChart":
        console.log("Data for LineChart is ", chartsdata?.description);
        return (
          <LineChartForDashboard
          dbsource={"selecteddb"}
          data={chartsdata?.description?.data}
          // name={chartsdata?.name}
          pageError={""}
          bardata={chartsdata?.description?.data}
          />
        );
      case "BumpChart":
        return (
          <BumpChartForDashboard
          dbsource={"selecteddb"}
          data={chartsdata?.description}
          pageError={""}
          bardata={chartsdata?.description?.data}
          />
        );
      case "AreaBumpChart":
        return (
          <AreaBumpForDashboard
          dbsource={"selecteddb"}
          data={chartsdata?.description}
          pageError={""}
          bardata={chartsdata?.description?.data}
          />
        );
      case "ScatterPlot":
        return (
          <ScatterPlotForDashboard
          dbsource={"selecteddb"}
          data={chartsdata?.description}
          pageError={""}
          bardata={chartsdata?.description?.data}
          />
        );
    }
  };

  console.log("Layouts.lg:", layouts.lg);
  console.log("Current layouts state:", layouts);
  useEffect(() => {
    // Save layout to local storage
    localStorage.setItem("dashboardLayout", JSON.stringify(layouts));
  }, [layouts]);
  
const onLayoutChange = (
  newLayout: LayoutItem[],
  allLayouts: { [key: string]: LayoutItem[] }
) => {
  setLayouts(allLayouts);
  setIsLayoutChanged(true);
 // setHasChanges(true);
  if (allLayouts.xs) {
    setLayoutModulesData((prevData: LayoutModulesData) => {
      const newData: LayoutModulesData = { ...prevData };
      newLayout.forEach((layoutItem: LayoutItem) => {
        if (newData[layoutItem.i]) {
          newData[layoutItem.i].x = layoutItem.x;
          newData[layoutItem.i].y = layoutItem.y;
          newData[layoutItem.i].w = layoutItem.w;
          newData[layoutItem.i].h = layoutItem.h;
        }
      });
      return newData;
    });
  }
  console.log("Updated layout Siddesh:", allLayouts);
};

// const updateDash = (dashboardId: number) => {
//   console.log("dashboardId here", dashboardId);

//   const currentBreakpointCoordinates = breakpointCoordinates[currentBreakpoint];

//   if (!currentBreakpointCoordinates) {
//     console.error("No coordinates available for the current breakpoint:", currentBreakpoint);
//     return; 
//   }
//   const coordinates = currentBreakpointCoordinates.map((layoutItem) => ({
//     moduleId: parseInt(layoutItem.moduleId),
//     xaxis: layoutItem.x,
//     yaxis: layoutItem.y,
//     moduleWidth: layoutItem.w,
//     moduleHeight: layoutItem.h,
//   }));
//   const payload = {
//     name: dashboardName,
//     description: "", 
//     projectId: getUserDetailsFromCookies().projectId,
//     UpdateModuleCoordinates: coordinates,
//   };
//   console.log("Payload before sending to DashboardUpdate:", payload);

//   DashboardUpdate(dashboardId, payload)
//     .then(() => {

//       navigate(`/${getUserDetailsFromCookies()?.identifier}/projects/Dashboard`);
//     })
//     .catch((error) => {

//       console.error("Error updating dashboard:", error);
//     });
// };

const updateDash = (dashboardId: number) => {
  console.log("dashboardId here", dashboardId);

  // Priority is given to 'md' coordinates, and fallback to 'lg' if 'md' is not available
  const preferredBreakpoint = breakpointCoordinates['md'] ? 'md' : 'lg';
  const currentBreakpointCoordinates = breakpointCoordinates[preferredBreakpoint];

  if (!currentBreakpointCoordinates) {
    console.error("No coordinates available for the preferred breakpoint:", preferredBreakpoint);
    return; 
  }

  const coordinates = currentBreakpointCoordinates.map((layoutItem) => ({
    moduleId: parseInt(layoutItem.moduleId),
    xaxis: layoutItem.x,
    yaxis: layoutItem.y,
    moduleWidth: layoutItem.w,
    moduleHeight: layoutItem.h,
  }));

  const payload = {
    name: dashboardName,
    description: "", 
    projectId: getUserDetailsFromCookies().projectId,
    UpdateModuleCoordinates: coordinates,
  };

  console.log("Payload before sending to DashboardUpdate:", payload);

  DashboardUpdate(dashboardId, payload)
    .then(() => {
      //navigate(`/${getUserDetailsFromCookies()?.identifier}/projects/Dashboard`);
     // setHasChanges(false);
     setIsLayoutChanged(false);
    })
    .catch((error) => {
      console.error("Error updating dashboard:", error);
    });
};



const chartContainerStyle: React.CSSProperties = {
  backgroundColor: "white", 
  borderRadius: '10px',     
  boxShadow: '0 2px 4px rgba(0, 0, 0, 0.05)',
  padding: '15px', 
  position: 'relative', 
  overflow: 'hidden'
};

const moduleNameStyle: React.CSSProperties = {
  position: 'absolute',
  fontFamily: Fonts.NunitoSans.regular,
  fontWeight: 700,
  top: 0,
  left: 0,
  padding: '10px',
  zIndex: 10, 
  color: 'black', 
};

const onResizeStop = (layout: any[], oldItem: any, newItem: any, placeholder: any, e: any, element: any) => {
  setShowGrid(false); 

  let newHeight = 0;
  layout.forEach(item => {
    const bottomPosition = item.y + item.h; 
    if (bottomPosition > newHeight) {
      newHeight = bottomPosition;
    }
  });
  const newHeightVh = newHeight * 10; 
  if(gridContainerRef.current) {
    gridContainerRef.current.style.height = `${newHeightVh}px`; // Set new height
  }
};
  return (
    <div style={{backgroundColor: "rgb(242,245,253)"}}>
         <div>
          <StyledQueryButton
            onClick={() => {
              updateDash(dashboardId);
              console.log("Update button clicked");
              setOpenDialog(true);
              handleOpenDialog(); // Open the dialog and set to close after 3 seconds
            }}
            className={classes.createDashboardBtnLabelStyle}
            variant="contained"
            size="medium"
            style={{
               position: "absolute",
              top: "5%", right: "19.5%", 
              zIndex: 9999, width: "155px",
              height: "50px",
             background: !isLayoutChanged ? "#ecedf2" : "linear-gradient(90deg, #5EB0FA 0%, #1157CD 50.54%, #9C90FA 100%)",
            }}
           // disabled={!hasChanges}
           disabled={!isLayoutChanged}
          >
            Save
            <CircularProgress
              className={classes.generateBtnCircularLoader}
              size={false ? 10 : 0}
            />
          </StyledQueryButton>

          <Dialog
        open={openDialog}
       onClose={() => setOpenDialog(false)}
      PaperProps={{
        style: dialogStyle,
       display: "flex",
        }}
  BackdropProps={{
    style: {
      backgroundColor: "transparent",
    
    },
    invisible: true,
  }}
>
  <img src={Images.success} alt="Alert" style={{ width: '55px', height: '55px', marginLeft: '5%',marginTop: '3%',}} />
  <DialogTitle style={dialogTitleStyle}>
    <div >Success</div>
    <div style={{ fontSize: '16px', marginTop: '4px', fontFamily: 'Nunito Sans, sans-serif' }}>
      Your dashboard changes have been saved!
    </div>
  </DialogTitle>
</Dialog>
          </div>
    <div style={{  display: "flex", height: gridHeight}}>
        <div  style={{
  width: "80%",
  height: "87vh",
  overflowY: "auto", 
  backgroundColor: "rgb(242,245,253)", 
}}>


        <ResponsiveGridLayout key={forceRerender}
          style={{ height: "100%", width: "100%" }}
          layouts={layouts}
          onBreakpointChange={onBreakpointChange}
          onLayoutChange={(newLayout, allLayouts) => onLayoutChange(newLayout, allLayouts)}
          onDrop={onDrop}
         
          isDroppable={true}
          isResizable={true}
          measureBeforeMount={false}
          useCSSTransforms={false}
         
          onDragStart={() => setShowGrid(true)}
          onDragStop={() => setShowGrid(false)}
          onResizeStart={() => setShowGrid(true)}
          onResizeStop=
          {
          onResizeStop
          }
          preventCollision={true}
          breakpoints={breakpoints}
        cols={cols}
          className={showGrid ? classes1.showGridBackgroundStyle : ""}
        >
          {layouts.lg.map((layoutItem, index) => (
            <div key={layoutItem.i || index} style={chartContainerStyle}>
             <div style={moduleNameStyle}>{layoutItem.chartsdata.name}</div>
            
              {ChartsData(layoutItem.chartsdata)}
              <CloseIcon 
                onClick={() => onCancelClick(layoutItem.i)}
                style={{
                  cursor: "pointer",
                  position: "absolute",
                  top: 10,
                  right: 10,
                }}
              />
            </div>
          ))}
         
        </ResponsiveGridLayout>
      </div>

      {isModuleListVisible && (
      <div
        style={{
          width: "20%",
          overflowY: "auto",
          margin: "20px",
          borderRadius:"2%",
          justifyContent: "center",
          height: "87vh",
          backgroundColor:"#fff",
        }}
      
      >
        {isLoading ? (
          <CircularProgress />
        ) : (
          moduleList.map((module) => (
            <Card
              key={module.id}
              draggable={true}
              onDragStart={(e) => onDragStart(e, module)}
              style={{ margin: "10px", padding: "10px" }}
              sx={{
                display: "flex",
                maxWidth: 250,
                minWidth: 240,
                minHeight: 160,
                maxHeight: 200,
                padding: 1.5,
                borderRadius: "10px",
                opacity: 0.9,
                background: `url(${Images.dashoboard_list_placeholder}) center center no-repeat`,
                backgroundSize: "cover",
                color: "white",
              }}
            >
              <CardContent>
                <Typography variant="h5" component="h2">
                  {module.name}
                </Typography>
              </CardContent>
            </Card>
          ))
        )}
      </div>
      )}
    </div>
    </div>
  );
};
export default EditDashboard;
