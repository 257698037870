import {
  makeStyles,
  Switch,
  TextField,
  styled,
  Button,
  InputBase,
  AppBar,
} from "../../../app.module";
import { AppBarProps } from "@mui/material";
import { Colors, Fonts } from "../../constants";
const drawerWidth = 160;

export const StyledInputBase = styled(InputBase)(({ theme }) => ({
  color: "inherit",
  "& .MuiInputBase-input": {
    padding: theme.spacing(1, 1, 1, 0),
    // vertical padding + font size from searchIcon
    //paddingLeft: `calc(1em + ${theme.spacing(4)})`,
    transition: theme.transitions.create("width"),
    color: "#000",
    // borderRadius: "20px",
    // backgroundColor: "#fff",
    // width: "100%",
    //padding: '8px 12px',
    height: "40px", // Adjust the height value as needed
    [theme.breakpoints.up("sm")]: {
      width: "30ch",
      "&:focus": {
        width: "35ch",
      },
    },
  },
}));
// export const StyledLogoBtn = styled(InputBase)(({ theme }) => ({
//   "&:hover": {
//     backgroundColor: "transparent", // Set the hover background color to transparent
//   },
// }));
export const useStyle = makeStyles()((theme?: any) => {
  return {
    MuiDrawerPaper: {
      //position: "sticky",
      //top: 0,
      display: "flex",
      flex: 1,
      //backgroundColor: Colors?.background.backgroundColorTheme2,
    },
    appBarStyle: {
      boxShadow: "none",
      backgroundColor: Colors?.background.backgroundColorTheme2,
      height: "65px",
      //zIndex: theme.zIndex.drawer + 1, // add a higher zIndex than the Drawer
      //borderRadius: "40px 0px 0px 0px",
      //margin: 0,
    },
    toolBarStyle: {
      boxShadow: "none",
      backgroundColor: Colors?.background.backgroundColorTheme3,
      display: "flex",
      //flexDirection: "column",
      //justifyContent: "space-between",
      flex: 1,
      //Colors?.background.backgroundColorTheme3,
      height: "90px",
      borderRadius: "20px 0px 0px 0px",
      //margin: 0,
    },
    dasboardContainer: {
      backgroundColor: "#fff", // Scrollable view background color
      margin: "10px 20px 10px 20px", // top, right, bottom, left
      //margin: "10px 20px 20px 20px", // top, right, bottom, left
      borderRadius: "10px",
      //Colors?.background.backgroundColorTheme3,
      padding: 0,
      //Colors?.background.backgroundColorTheme3,
    },
    appBarSearch: {
      display: "flex",
      borderRadius: "30px",
      height: "40px",
      backgroundColor: "#FFF",
      marginLeft :"80px",
      marginBottom:"5px",
    },
    searchInput: {
      fontFamily: theme.typography.fontFamily,
      fontStyle: 'normal',
      fontWeight: 400,
      fontSize: '16.6957px',
      lineHeight: '19px',
      color: theme.palette.text.primary, // Adjust the color based on your theme
    },
    searchInputRight: {
      marginLeft: 'auto', // This will push the input to the right
    },
    dashboardPath: {
      fontFamily: Fonts.NunitoSans.regular,
      fontStyle: "normal",
      fontWeight: "700",
      fontSize: "14",
      lineHeight: "19px",
      color: Colors?.path.light,
    },
    userProfileContainer: {
      display: "flex",
      //backgroundColor: "pink",
      justifyContent: "space-evenly",
      alignItems: "center",
    },
    userProfileImg: {
      height: "35px",
      width: "35px",
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      borderRadius: "18px",
      marginRight: "10px",
      background: "linear-gradient(202.64deg, #E24A95 21.95%, #4A2BA3 92.07%)",
    },
    userProfileTxt: {
      fontSize: "18px",
      marginTop: "1px",
      fontFamily: Fonts.NunitoSans.regular,
    },
    scrol: {
      overflowX: "hidden",
      scrollbarWidth: 'none', // Firefox
      '-ms-overflow-style': 'none', // IE and Edge
      '&::-webkit-scrollbar': {
        width: '0',
        height: '0',
      },
    },
    userNameStyle: {
      fontFamily: Fonts.NunitoSans.regular,
      fontStyle: "normal",
      fontWeight: "700",
      fontSize: "17px",
      lineHeight: "26px",
      textAlign: "left",
      color: Colors?.palette.label.main,
    },
    userActionTitle: {
      fontFamily: Fonts.Inter.regular,
      fontStyle: "normal",
      fontWeight: "400",
      fontSize: "16px",
      lineHeight: "19px",
      textTransform: "initial",
      color: Colors?.palette.label.main,
      padding: 5,
    },
    modalOverlay: {
      backgroundColor: "rgba(0, 0, 0, 0)", // Completely transparent overlay
      zIndex: theme.zIndex.modal + 1, // Ensure the modal is above other elements
    },
    userRoleTitle: {
      fontFamily: Fonts.Inter.regular,
      fontStyle: "normal",
      fontWeight: "400",
      fontSize: "14px",
      lineHeight: "17px",
      color: Colors?.palette.label.main,
    },
    userProfileDetailsCollaps: {
      fontFamily: Fonts.Inter.regular,
      fontStyle: "normal",
      fontWeight: "600",
      fontSize: "20px",
      lineHeight: "16px",
      color: Colors?.palette.label.main,
    },
    searchIconWraper: {
      // padding: theme.spacing(0, 2),
      // //backgroundColor: "red",
      // //height: "100%",
      // //position: "absolute",
      // pointerEvents: "none",
      // display: "flex",
      // alignItems: "center",
      // justifyContent: "center",
      padding: "8px",
      height: "100%",
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
    },
    headerModal: {
      position: "fixed",
      top: theme.spacing(2),
      right: theme.spacing(2),
      backgroundColor: "#fff",
      padding: theme.spacing(2),
      outline: "none",
      borderRadius: theme.spacing(1),
      boxShadow: theme.shadows[5],
      width: 300,
    },
    closeModalButton: {
      position: "absolute",
      top: theme.spacing(1),
      right: theme.spacing(1),
    },
    DrawerCmpList: {
      //backgroundColor: Colors?.background.backgroundColorTheme2,
    },
    drawer: {
      width: "150px",
      flexShrink: 0,
    },
    drawerPaper1: {
      width: "240px",
    },
    content: {
      flexGrow: 1,
      padding: theme.spacing(3),
      transition: theme.transitions.create("margin", {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.leavingScreen,
      }),
    },
    contentShift: {
      marginLeft: "240px",
      transition: theme.transitions.create("margin", {
        easing: theme.transitions.easing.easeOut,
        duration: theme.transitions.duration.enteringScreen,
      }),
    },
    drawerPaper: {
      width: "150px",
      border: "0px solid #A0A8B5",
      backgroundColor: Colors?.background.backgroundColorTheme2, // set your desired background color here
    },
    logoStyle: {
      height: "60px",
      width: "125px",
      objectFit: "contain",
      //marginBottom: "20px",
    },
    logoCloseStyle: {
      // height: "50px",
      // width: "70px",
      // height: "50px",
      // width: "60px"
      objectFit: "contain",
      marginTop: "1px",
      height: "40px",
      width: "55px",
    },
    logoTxtStyle: {
      marginTop: "5px",
      fontFamily: Fonts.Inter.regular,
      fontSize: "22px",
      color: "#1F2354",
      fontWeight: "bolder",
      //textAlign: "center",
      //marginTop: "20px",
      cursor: "pointer",
      textTransform: "initial",
    },
    logoBtnStyle: {
      "&:hover": {
        backgroundColor: "transparent", // Set the hover background color to transparent
      },
    },
    logoContainer: {
      justifyContent: "center",
      alignItems: "center",
      display: "flex",
      marginTop: "0px",
    },
    buttonEffect: {
      border: "none",
      backgroundColor: "transparent",
      cursor: "pointer",
    },
    imageEffect: {
      filter: "grayscale(100%)",
      opacity: "0.5",
    },
    menuIcons: {
      height: "20px",
      width: "20px",
      marginRight: "5px",
    },
    listItemContainer: {
      display: "flex",
      padding: "15px",
      paddingLeft: "25px",
      //border: "1px solid #A0A8B5",
      // borderRadius: "55px 0px 0px 55px",
    },
    listItemContainerBorderRadius: {
      display: "flex",
      padding: "15px",

      //border: "1px solid #A0A8B5",
      // borderRadius: "55px 0px 0px 55px",
    },
    listItemTextContainer: {
      fontFamily: Fonts.NunitoSans.regular,
      fontStyle: "normal",
      fontWeight: "600",
      fontSize: "16px",
      lineHeight: "22px",
      color: "#A0A8B5",
    },
    drawerTitleColor: {
      color: "#000000",
    },
  };
});
export const Main = styled("main", {
  shouldForwardProp: (prop) => prop !== "open",
})<{
  open?: boolean;
}>(({ theme, open }) => ({
  flexGrow: 1,
  padding: theme.spacing(3),
  transition: theme.transitions.create("margin", {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  marginLeft: 0, //This margin will affect the scrollabel area of dashboard
  ...(open && {
    transition: theme.transitions.create("margin", {
      easing: theme.transitions.easing.easeOut,
      duration: theme.transitions.duration.enteringScreen,
    }),
    //marginLeft: 0,
  }),
}));

interface WingsBiAppBarProps extends AppBarProps {
  open?: boolean;
}

export const WingsBIAppBar = styled(AppBar, {
  shouldForwardProp: (prop) => prop !== "open",
})<WingsBiAppBarProps>(({ theme, open }) => ({
  transition: theme.transitions.create(["margin", "width"], {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  ...(open && {
    width: `calc(100% - ${drawerWidth}px)`,
    marginLeft: `${drawerWidth}px`,
    transition: theme.transitions.create(["margin", "width"], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
  }),
  ...(!open && {
    width: `calc(100% - ${drawerWidth - 91}px)`, //we can able to set the space between drawer and Appbar  when drawer is closed
    //marginLeft: `${drawerWidth}px`,
    transition: theme.transitions.create(["width"], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
  }),
}));

export const DrawerHeader = styled("div")(({ theme }) => ({
  display: "flex",
  alignItems: "center",
  padding: theme.spacing(0, 1),
  // necessary for content to be below app bar
  ...theme.mixins.toolbar,
  justifyContent: "flex-end",
}));

export const popOutStyle = {
  transform: 'scale(1.1)', // Increase the scale on hover
  boxShadow: '0 0 10px rgba(0, 0, 0, 0.2)', // Add a shadow on hover
  transition: 'transform 0.2s, box-shadow 0.2s', // Add a smooth transition
};