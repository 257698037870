import {
  React,
  Paper,
  useMemo,
  Tabs,
  Tab,
  useState,
  Box,
  Typography,
  Button,
  Select,
  MenuItem,
  clsx,
  useEffect,
  ListItemIcon,
  FiberManualRecord,
  Rectangle,
  Backdrop,
  CircularProgress,
  useNavigate,
  useLocation,
} from "../../../../app.module";
import { ResponsiveBar } from "@nivo/bar";
import { ResponsiveLine } from "@nivo/line";
import { useStyle } from "./BarChartStyle";
import {
  getVisualizationData,
  getVisualizationType,
  getPossibalVisualization,
  getLineChartVisualizationData,
  getVisualizationState,
} from "./ChartsService";
import {
  getUserDetailsFromCookies,
  getQueryIdFromCookies,
} from "../../../services";
import { Colors, Images } from "../../../constants";

const LineChart = React.memo((props: any) => {
  const [lineChartJson, setLineChartJson] = useState<any>([]);
  const [isLoading, setIsLoading] = useState(true);
  const [activeTab, setActiveTab] = React.useState(0);
  const { classes } = useStyle({ isYaxis: false, isXaxis: true, activeTab });
  const [isScrolling, setIsScrolling] = useState(false);

  useEffect(() => {
    let { data } = props.data;
    if (!Array.isArray(data) || data.length === 0 || !Array.isArray(data[0].data)) {
      console.error('Invalid data structure provided to LineChart component:', data);
      // Handle the error appropriately, perhaps set a default state or an error state
    } else {
      setLineChartJson(data); // Only set the state if the data is structured correctly
    }
  }, [props.data]);

  let scrollTimeout: NodeJS.Timeout | undefined;

  
  const handleMouseMove = () => {
    setIsScrolling(true);
    if (scrollTimeout) clearTimeout(scrollTimeout);
  };
  
  const handleMouseLeave = () => {
    if (scrollTimeout) clearTimeout(scrollTimeout);
    scrollTimeout = setTimeout(() => setIsScrolling(false), 1000) as NodeJS.Timeout;
  };

  const isStringIndexForAreaBump = () => {
    const data = lineChartJson;
  
    if (data && data.length > 0 && data[0].data && data[0].data.length > 0) {
      return typeof data[0].data[0].x === 'string';
    }
  
    return false;
  };

  interface DataPoint {
    x: string | number;
    // Add other properties that a point might have, for example:
    // y: number;
  }

  const theme = {
    
    axis: {
      ticks: {
        text: {
          fontSize: 10, // Decrease the font size of the axis ticks
        }
      }
    },
    // ... rest of the theme
  };
  
  
  // Define the types for your data structures
  type Point = {
    x: number | string;
    y: number | string;
  };
  
  type Series = {
    data: Point[];
  };
  
 
  const calculateLeftMarginIncludingLegend = (data: Series[]): number => {
    // Determine the width of your axis legend text here, similar to how you measure text width for the labels
    const axisLegendWidth = 50; // This is an example value, calculate based on your actual legend text
    const padding = 25; // Additional padding to ensure the legend doesn't touch the axis
    return axisLegendWidth + padding;
  
    
  };
  
  // In your component where you define the chart margins
  const leftMarginForLegend = calculateLeftMarginIncludingLegend(lineChartJson);
  
  const additionalOffset = 50;
  
  
  const calculateBottomMarginIncludingLegend = (data: Series[]): number => {
    // This value should be adjusted based on your specific font size and chart style
    if (!Array.isArray(data) || data.length === 0 || !Array.isArray(data[0].data)) {
      return 60; // Return a default margin if data is not structured correctly
    }
  
    // This value should be adjusted based on your specific font size and chart style
    const approximateCharWidth = 8; // Example value
  
    // Calculate the maximum label length
    const maxLabelLength = data.reduce((acc, series) => {
      if (!series.data) return acc; // Check if data is present in the series
      const maxLengthInSeries = Math.max(...series.data.map((point) => point.x.toString().length));
      return Math.max(maxLengthInSeries, acc);
    }, 0);
  
    // Estimate the space needed for the longest label, given the character width
    const estimatedLabelWidth = maxLabelLength * approximateCharWidth;
  
    // Use a trigonometric calculation if the labels are rotated (e.g., -45 degrees)
    const labelRotationAngle = -45; // Adjust angle based on your actual label rotation
    const radians = (Math.PI / 180) * labelRotationAngle;
    const rotatedLabelWidth = Math.cos(radians) * estimatedLabelWidth;
  
    // Calculate the bottom margin needed
    const bottomMargin = rotatedLabelWidth + 15; // Add some padding
    return Math.max(bottomMargin, 60); // Ensure a minimum margin size
  };
  
  // In your component where you define the chart margins
  const bottomMarginForLegend = calculateBottomMarginIncludingLegend(lineChartJson);

  const additionalOffsetbottom = 10;




  return (
    <div
      key="CHART101"
      style={{
        backgroundColor: Colors?.background.backgroundColorTheme3,
        height: "87vh",
        width: "75%",
        // overflowX: "auto",
        overflowX: isScrolling ? "auto" : "hidden",
        overflowY: isScrolling ? "auto" : "hidden",
        boxShadow: "0px 0px 4px 0px #00000040",
        borderRadius: "10px 0px 0px 10px",
        overflow: "scroll",
        top: 0,
      }}
      onMouseMove={handleMouseMove}
      onMouseLeave={handleMouseLeave}
    >
      <div
        style={{
          display: "flex",
          justifyItems: "right",
          marginLeft: "10px",
          marginTop: "10px",
        }}
      >
        Datasource name :<label>{props?.dbsource}</label>
      </div>
      {props?.pageError !== "" ? (
        <div
          key="TAB101"
          style={{
            display: "flex",
            height: "87vh",
            width: "100%",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <img
            style={{
              height: "auto",
              width: "300px",
            }}
            src={Images.no_data_img}
          />
        </div>
      ) : (
        <div
          key="CHART102"
          style={{
        
            width: `${
              100 *
              (lineChartJson[0]?.data.length < 15
                ? 20
                : lineChartJson[0]?.data.length)
            }px`,
            height: "80vh",
            // overflow: "scroll",
          }}
        >
          <ResponsiveLine
            data={
              lineChartJson
              //pieChartJson?.data ? pieChartJson?.data : []
            }
         

      

            margin={{
              top: 50, // Space at the top
              right: 50, // Space on the right
            
              left: calculateLeftMarginIncludingLegend(lineChartJson) + additionalOffset,
              bottom: bottomMarginForLegend, // Add the additional offset to the left margin 
            }}
            axisBottom={{
              tickSize: 5,
              tickPadding: 5,
              tickRotation: -45,
              legend: lineChartJson[0]?.axis_bottom_legend ? lineChartJson[0]?.axis_bottom_legend : "",
              legendPosition: 'middle', // or 'end' to align with the edge
              // legendOffset: isStringIndexForAreaBump() ? 140 : 60, // Increase legendOffset if labels are cut off
              legendOffset: bottomMarginForLegend / 2,
            }}



            theme={theme}
            xScale={{ type: "point" }}
            yScale={{
              type: "linear",
              min: "auto",
              max: "auto",
              stacked: true,
              reverse: false,
            }}
            yFormat=" >-.2f"
            axisTop={null}
            axisRight={null}
          
           
            axisLeft={{
              tickSize: 5,
              tickPadding: 5,
              tickRotation: 0,
              legend: lineChartJson[0]?.axis_left_legend
                ? lineChartJson[0]?.axis_left_legend
                : "",
              // legendOffset: -80, // Adjust if necessary
              legendOffset: -calculateLeftMarginIncludingLegend(lineChartJson) / 2 - additionalOffset, // Use the additional offset here
              legendPosition: "middle",
            }}
            pointSize={10}
            pointColor={{ theme: "background" }}
            pointBorderWidth={2}
            pointBorderColor={{ from: "serieColor" }}
            pointLabelYOffset={-12}
            useMesh={true}
            legends={[
              {
                anchor: "top-left",
                direction: "column",
                justify: false,
                translateX: 0, // Adjust based on dynamicLeftMargin if necessary
                translateY: -40,
                itemsSpacing: 0,
                itemDirection: "left-to-right",
                itemWidth: 80,
                itemHeight: 20,
                itemOpacity: 0.75,
                symbolSize: 12,
                symbolShape: "circle",
                symbolBorderColor: "rgba(0, 0, 0, .5)",
                effects: [
                  {
                    on: "hover",
                    style: {
                      itemBackground: "rgba(0, 0, 0, .03)",
                      itemOpacity: 1,
                    },
                  },
                ],
              },
            ]}
          />
        </div>
      )}
    </div>
  );
});

export default LineChart;
