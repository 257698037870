import React, { ReactNode } from 'react';

interface MessageParserProps {
  children: ReactNode;
  actions: any; // Define a more specific type for 'actions' if possible
}

const MessageParser: React.FC<MessageParserProps> = ({ children, actions }) => {
  const parse = (message: string) => {
    console.log(message);
    // Store the user's initial question
    const lowerCaseMessage = message.toLowerCase();

    if (lowerCaseMessage.includes("option1") || lowerCaseMessage.includes("option2")) {
      actions.handleDataSourceSelection(lowerCaseMessage);
    } else {
      actions.fetchData(lowerCaseMessage);
    }

  };

  return (
    <div>
      {React.Children.map(children, (child) => {
        return React.cloneElement(child as React.ReactElement, {
          parse: parse,
          actions: actions,
        });
      })}
    </div>
  );
};
 export default MessageParser;