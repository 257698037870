import { BASE_URL } from "../../config/WingsbiConfig";
import { getUserAccessTokenFromCookies } from "../cookie/cookies";
import { refreshTokenBeforeExpire } from "../msal/RefreshToken";
export const deleteData = async (path: string) => {
  //await refreshTokenBeforeExpire();
  const response = fetch(BASE_URL + path, {
    method: "DELETE",
    headers: {
      "Content-Type": "application/json",
      Authorization: "Bearer " + getUserAccessTokenFromCookies(),
    },
  })
    .then((response) => {
      if (response.status === 204) {
        // 204 No Content indicates a successful deletion
        return null; // No data to parse
      } else {
        return response.json();
      }
    })
    .then((responseJson) => {
      return responseJson;
    })
    .catch((error) => console.log(error)); // to catch errors if any
  console.log("sdjfjads", response);
  return response;
};
