import {
  React,
  InputLabel,
  Box,
  Typography,
  Select,
  MenuItem,
  useState,
  Button,
  useEffect,
  CircularProgress,
  Backdrop,
  Autocomplete,
  useNavigate,
  Cookies,
  useMsal,
} from "../../../app.module";
import "./company_details.module.css";
import { CssTextField, useStyles } from "./CompanyStyle";
import {
  getCompanyTypes,
  getIndustrySize,
  useCompanyService,
  getCountries,
  getState,
  getCities,
  setCompanyDetails,
} from "./CompanyService";
import { getUserDetailsFromCookies } from "../../services";
import { Images, Fonts } from "../../constants";
import { polyfillCountryFlagEmojis } from 'country-flag-emoji-polyfill';
import clsx from "clsx";
const CompanyDetails = (props: any) => {
  const { classes } = useStyles();
  const navigate = useNavigate();
  const [value, setValue] = useState("none");
  const [state, setState] = useState([{ name: "Not Available" }]);
  const [cities, setCities] = useState([{ cityName: "Not Available" }]);
  const [industryType, setIndustryType] = useState([]);
  const [countries, setCountries] = useState([]);
  const [flag, setFlag] = useState("🇮🇳");
  const [country_code, setCountryCode] = useState("+91");
  const [industrySize, setIndustrySize] = useState([]);
  const [showPlaceholder, setShowPlaceholder] = useState(value === "none");
  const [fetch] = useCompanyService();
  const [open, setIsLoading] = useState(true);
  const [test, setTest] = useState(true);
  const [guidedTourName, setGuidedTourName] = useState("guide1");
  const [currentCompanyDetails, setCurrentCompanyDetails] = useState(null);
  const [formValues, setFormValues] = useState({
    organization_name: {
      value: "",
      error: false,
      errorMessage: "You must enter a organization name",
    },
    domain_name: {
      value: "",
      error: false,
      errorMessage: "You must enter domain_name",
    },
    industry_type: {
      value: "none",
      id: 0,
      error: false,
      errorMessage: "Select Industry Type",
    },
    industry_size: {
      value: "none",
      id: 0,
      error: false,
      errorMessage: "Select Industry Size",
    },
    country: {
      value: "",
      phoneCode: "",
      id: 0,
      length: 0,
      error: false,
      errorMessage: "Select your Country",
    },
    state: {
      value: "",
      id: 0,
      error: false,
      errorMessage: "Select your State",
    },
    city: {
      value: "",
      id: 0,
      error: false,
      errorMessage: "Select your City",
    },
    email: {
      value: "",
      error: false,
      errorMessage: "Enter your Email Address",
    },
    pincode: {
      value: "",
      error: false,
      errorMessage: "",
    },
    address: {
      value: "",
      error: false,
      errorMessage: "Enter your Address",
    },
    contact: {
      value: "",
      error: false,
      errorMessage: "Enter your Contact Number",
    },
  });
  const handleCountry = (value: string) => {
    let country: any = countries.find(
      (country: any) => country.countryName === value
    );
    getState(country.id).then((state: any) => {
      state.length > 0
        ? setState(state)
        : setState([{ name: "Not Available" }]);
    });
    setFlag(country.flag);
    setCountryCode(country.phoneCode);
    setFormValues({
      ...formValues,
      ["country"]: {
        ...formValues["country"],
        ["value"]: value,
        ["error"]: false,
        ["errorMessage"]: "",
        phoneCode: country.phoneCode,
        id: country.id,
      },
      ["state"]: {
        ...formValues["state"],
        value: "",
        id: 0,
      },
      ["city"]: {
        ...formValues["city"],
        value: "",
        id: 0,
      },
    });
  };
  const handleStates = (value: string) => {
    let selected_state: any = state.find(
      (states: any) => states.name === value
    );
    setFormValues({
      ...formValues,
      ["state"]: {
        ...formValues["state"],
        value,
        error: false,
        errorMessage: "",
        id: selected_state.id,
      },
      ["city"]: {
        ...formValues["city"],
        value: "",
        id: 0,
      },
    });
    getCities(selected_state.id).then((city: any) => {
      city.length > 0
        ? setCities(city)
        : setCities([{ cityName: "Not Available" }]);
    });
  };
  const handleCities = (value: string) => {
    let selected_city: any = cities.find(
      (city: any) => city.cityName === value
    );

    setFormValues({
      ...formValues,
      ["city"]: {
        ...formValues["city"],
        value,
        error: false,
        errorMessage: "",
        id: selected_city?.id ? selected_city?.id : 0,
      },
    });
  };
  const handleStateChange = (e: any) => {
    const { name, value } = e.target;
    switch (name) {
      case "organization_name":
        setFormValues({
          ...formValues,
          ["organization_name"]: {
            value,
            error: false,
            errorMessage: "",
          },
        });
        break;
      case "domain_name":
        setFormValues({
          ...formValues,
          ["domain_name"]: {
            value,
            error: false,
            errorMessage: "",
          },
        });
        break;
      case "industry_type":
        let selected_industry: any = industryType.find(
          (type: any) => type.industryType === value
        );
        setFormValues({
          ...formValues,
          ["industry_type"]: {
            value,
            error: false,
            errorMessage: "",
            id: selected_industry.id,
          },
        });
        break;
      case "industry_size":
        let selected_industry_size: any = industrySize.find(
          (size: any) => size.industrySize === value
        );
        setFormValues({
          ...formValues,
          ["industry_size"]: {
            value,
            error: false,
            errorMessage: "",
            id: selected_industry_size.id,
          },
        });
        break;
      case "address":
        setFormValues({
          ...formValues,
          ["address"]: {
            value,
            error: false,
            errorMessage: "",
          },
        });
        break;
      case "email":
        setFormValues({
          ...formValues,
          ["email"]: {
            value,
            error: false,
            errorMessage: "",
          },
        });

        break;
      case "pincode":
        setFormValues({
          ...formValues,
          ["pincode"]: {
            value,
            error: false,
            errorMessage: "",
          },
        });
        break;
      case "contact":
        setFormValues({
          ...formValues,
          ["contact"]: {
            value,
            error: false,
            errorMessage: "",
          },
        });
        break;
    }
  };

  const validateCompanyDetails = () => {
    let validate_data = formValues;
    let isError = false;
    Object.entries(validate_data).forEach(([key, value]) => {
      let cmp_key = key as keyof typeof validate_data;
      if (value.value === "" || value.value === "none") {
        validate_data[cmp_key].error = true;
        isError = cmp_key === "pincode" ? false : true;
      } else {
        validate_data[cmp_key].error = false;
      }
    });
    setFormValues({ ...validate_data });
    return isError;
  };
  console.log("userdercookies",getUserDetailsFromCookies());
  const createOrganization = async () => {
    console.log("formValues", formValues);
    if (!validateCompanyDetails()) {
      setIsLoading(true);
      let company_details = {
        name: formValues.organization_name.value,
        orgLegalName: formValues.organization_name.value,
        domainName: formValues.domain_name.value,
        industryTypeId: formValues.industry_type.id,
        industrySizeId: formValues.industry_size.id,
        address: formValues.address.value,
        city: formValues.city.value,
        stateId: formValues.state.id,
        countryId: formValues.country.id,
        pinCode:
          formValues.pincode.value === "" ? null : formValues.pincode.value,
        emailAddress: formValues.email.value,
        contactNumber: country_code + formValues.contact.value,
        createdBy: getUserDetailsFromCookies().id,
      };
      console.log("Req body", company_details);
      setCompanyDetails(company_details).then((resp: any) => {
        console.log("Req body resp", resp);
        if (!resp.isError) {
          let user_details = getUserDetailsFromCookies();
          //user_details.identifier = resp.result.identifier;
          user_details.createdBy = resp.result.createdBy;
          user_details.companyId = resp.result.id;
          Cookies.set("user_details", JSON.stringify(user_details));
          console.log(
            "Req body resp getUserDetailsFromCookies",
            getUserDetailsFromCookies()
          );
          handleClose();
          //navigate("/plan");
          navigate("/plan", { replace: true });
        } else {
          setIsLoading(false);
        }
      });
    } else {
    }
  };
  useEffect(() => {
    handleClose();
    getCompanyTypes().then((company_type: any) => {
      setIndustryType(company_type);
    });
    getIndustrySize().then((industry_size: any) => {
      setIndustrySize(industry_size);
    });
    handleClose();
    getCountries().then((countries: any) => {
      setCountries(countries);
    });
    polyfillCountryFlagEmojis();
    
  }, []);

  useEffect(() => {
    polyfillCountryFlagEmojis();
  }, []);

  useEffect(() => { }, [formValues, country_code, test, guidedTourName]);
  const handleClose = () => {
    setIsLoading(false);
  };

  useEffect(() => {
    polyfillCountryFlagEmojis();
  }, []);
  const getCountryCodeLength = () => {
    let countryCodeLength = country_code.length;
    let isSingleDigitCountryCode = countryCodeLength === 1;
    let codeLength =
      25 *
      (isSingleDigitCountryCode ? countryCodeLength : countryCodeLength - 1);
    let pixelValue = 65 + codeLength;

    return `${pixelValue}px`;
  };
  return (
    <Typography>
      <div style={{ backgroundColor: "#F8FAFF", padding: "0px" }}>
        <Backdrop
          className={classes.cicularContainer}
          open={open}
          onClick={handleClose}
        >
          <CircularProgress className={classes.cicularView} />
        </Backdrop>
        <div
          style={{
            justifyContent: "center",
            alignItems: "center",
            display: "flex",
          }}
        >
          <img
            className={classes.companyHeaderCodeStyle}
            src={Images.logomark_svg}
            alt="mySvgImage"
          />
        </div>
        <Box
          sx={{
            display: "flex",
            flex: 1,

            maxWidth: "100%",

            marginLeft: "20%",
            marginRight: "20%",
            padding: "20px",
            borderRadius: "10px",

            flexDirection: "column",
            backgroundColor: "#fff",
          }}
        >
          <h2 className={classes.CmpDetailsHeading}>
            Add your organization details
          </h2>
          <div className={classes.twoFieldsContainer}>
            <div className={classes.textFiledLabelContainer}>
              <InputLabel className={classes.labelStyle}>
                Organization name <span className={classes.asterix}> *</span>
              </InputLabel>
              <CssTextField
                required
                fullWidth
                onChange={handleStateChange}
                name="organization_name"
                value={formValues.organization_name.value}
                error={formValues.organization_name.error}
                helperText={
                  formValues.organization_name.error
                    ? "Please enter organization name"
                    : ""
                }
                placeholder="Organization name"
                inputProps={{
                  maxLength: 50,
                }}
                InputProps={{
                  classes: { input: classes.input },
                }}
                id="custom-css-standard-input"
              />
            </div>

            <div className={classes.textFiledLabelContainerRight}>
              <InputLabel className={classes.labelStyle}>
                Domain name <span className={classes.asterix}> *</span>
              </InputLabel>
              <CssTextField
                fullWidth
                name="domain_name"
                onChange={handleStateChange}
                value={formValues.domain_name.value}
                error={formValues.domain_name.error}
                helperText={
                  formValues.domain_name.error ? "Please enter domain name" : ""
                }
                placeholder="Domain name"
                inputProps={{
                  maxLength: 253,
                }}
                InputProps={{
                  classes: { input: classes.input },
                }}
                id="custom-css-standard-input"
              />
            </div>
          </div>
          <div className={classes.twoFieldsContainer}>
            <div
              className={clsx(
                classes.selectFlex,
                classes.textFiledLabelContainer
              )}
            >
              <InputLabel
                className={classes.labelStyle}
                id="demo-simple-select-helper-label"
              >
                Industry type <span className={classes.asterix}> *</span>
              </InputLabel>
              <Select
                id="demo-simple-select-helper-type"
                value={formValues.industry_type.value}
                error={formValues.industry_type.error}
                name="industry_type"
                className={clsx(
                  classes.select,

                  formValues.industry_type.value === "none"
                    ? classes.selectdisabled
                    : null
                )}
                onFocus={(e) => setShowPlaceholder(false)}
                onChange={handleStateChange}
                onClose={(e) => {
                  setShowPlaceholder(
                    (e.target as HTMLInputElement).value === undefined
                  );
                }}
                defaultValue="none"
              >
                <MenuItem
                  className={clsx(
                    classes.menuitem,
                    !showPlaceholder ? classes.menuitemhidden : null
                  )}
                  key="0"
                  disabled
                  value="none"
                >
                  Industry type
                </MenuItem>

                {industryType.map((item: any) => (
                  <MenuItem
                    key={item?.id}
                    className={classes.menuitem}
                    value={item?.industryType}
                  >
                    {item?.industryType}
                  </MenuItem>
                ))}
              </Select>
              <InputLabel className={classes.dropdownErrorStyle}>
                {formValues.industry_type.error
                  ? "Please select industry type"
                  : ""}
              </InputLabel>
            </div>
            <div className={classes.textFiledLabelContainerRight}>
              <InputLabel
                className={classes.labelStyle}
                id="demo-simple-select-helper-label"
              >
                Industry size <span className={classes.asterix}> *</span>
              </InputLabel>
              <Select
                id="demo-simple-select-helper-size"
                error={formValues.industry_size.error}
                value={formValues.industry_size.value}
                name="industry_size"
                className={clsx(
                  classes.select,
                  formValues.industry_size.value === "none"
                    ? classes.selectdisabled
                    : null
                )}
                onFocus={(e) => setShowPlaceholder(false)}
                onChange={handleStateChange}
                onClose={(e) => {
                  setShowPlaceholder(
                    (e.target as HTMLInputElement).value === undefined
                  );
                }}
                defaultValue="none"
              >
                <MenuItem
                  className={clsx(
                    classes.menuitem,
                    !showPlaceholder ? classes.menuitemhidden : null
                  )}
                  key="0"
                  disabled
                  value="none"
                >
                  Industry size
                </MenuItem>

                {industrySize.map((item: any) => (
                  <MenuItem
                    key={item?.id}
                    className={classes.menuitem}
                    value={item?.industrySize}
                  >
                    {item?.industrySize}
                  </MenuItem>
                ))}
              </Select>
              <InputLabel className={classes.dropdownErrorStyle}>
                {formValues.industry_size.error
                  ? "Please select industry size"
                  : ""}
              </InputLabel>
            </div>
          </div>
          <div className={classes.twoFieldsContainer}>
            <div className={classes.textFiledLabelContainerRight}>
              <InputLabel className={classes.labelStyle}>
                Address <span className={classes.asterix}> *</span>
              </InputLabel>
              <CssTextField
                fullWidth
                onChange={(e: any) => handleStateChange(e)}
                error={formValues.address.error}
                helperText={
                  formValues.address.error ? "Please enter address" : ""
                }
                value={formValues.address.value}
                name="address"
                placeholder="Address"
                inputProps={{
                  maxLength: 255,
                }}
                InputProps={{
                  classes: { input: classes.input },
                }}
                id="custom-css-standard-input-address"
              />
            </div>
          </div>
          <div className={classes.twoFieldsContainer}>
            <div className={classes.textFiledLabelContainer}>
              <InputLabel className={classes.labelStyle}>
                Country <span className={classes.asterix}> *</span>
              </InputLabel>

              <Autocomplete
                disablePortal
                id="combo-box-demo2"
                className={
                  formValues.country.error
                    ? clsx(classes.dropdownStyle, classes.dropdownBorder)
                    : classes.dropdownStyle
                }
                value={formValues.country.value}
                onChange={(event, value) => {
                  value
                    ? handleCountry(value)
                    : setFormValues({
                      ...formValues,
                      ["country"]: {
                        ...formValues["country"],
                        value: "",
                        id: 0,
                      },
                    });
                }}
                options={countries?.map((option: any) => option.countryName)}
                renderInput={(params) => (
                  <CssTextField
                    {...params}
                    placeholder="Country"
                    className={
                      formValues.country.error ? classes.countryInput : ""
                    }
                    InputProps={{
                      ...params.InputProps,

                      style: {
                        height: "53px",
                        fontFamily: Fonts.NunitoSans.regular,
                        fontWeight: "400",
                        fontSize: "18px",
                        lineHeight: "21px",
                        borderRadius: "10px",
                        color: "#000",
                      },
                    }}
                    id="custom-css-standard-input-country"
                  />
                )}
              />
              <InputLabel
                className={
                  formValues.state.error || formValues.country.error
                    ? clsx(classes.dropdownFlex, classes.dropdownErrorStyle)
                    : classes.dropdownErrorStyle
                }
              >
                {formValues.country.error ? "Please select country" : ""}
              </InputLabel>
            </div>

            <div className={classes.textFiledLabelContainerRight}>
              <InputLabel
                className={classes.labelStyle}
                id="demo-simple-select-helper-label"
              >
                State <span className={classes.asterix}> *</span>
              </InputLabel>
              <Autocomplete
                disablePortal
                id="combo-box-demo-state"
                disableClearable={false}
                className={
                  formValues.state.error
                    ? clsx(classes.dropdownStyle, classes.dropdownBorder)
                    : classes.dropdownStyle
                }
                value={formValues.state.value}
                onChange={(event, value) => {
                  value
                    ? handleStates(value)
                    : setFormValues({
                      ...formValues,
                      ["state"]: {
                        ...formValues["state"],
                        value: "",
                        id: 0,
                      },
                    });
                }}
                options={state?.map((option: any) => option.name)}
                renderInput={(params) => (
                  <CssTextField
                    {...params}
                    placeholder="State"
                    className={
                      formValues.state.error ? classes.countryInput : ""
                    }
                    InputProps={{
                      ...params.InputProps,

                      style: {
                        height: "53px",
                        fontFamily: Fonts.NunitoSans.regular,
                        fontWeight: "400",
                        fontSize: "18px",
                        lineHeight: "21px",
                        borderRadius: "10px",
                        color: "#000",
                      },
                    }}
                    id="custom-css-standard-input-state"
                  />
                )}
              />

              <InputLabel
                className={
                  formValues.state.error || formValues.country.error
                    ? clsx(classes.dropdownFlex, classes.dropdownErrorStyle)
                    : classes.dropdownErrorStyle
                }
              >
                {formValues.state.error ? "Please select state" : ""}
              </InputLabel>
            </div>
          </div>
          <div className={classes.twoFieldsContainer}>
            <div className={classes.textFiledLabelContainer}>
              <InputLabel className={classes.labelStyle}>
                City/Town <span className={classes.asterix}> *</span>
              </InputLabel>

              <Autocomplete
                disablePortal
                defaultValue={"City/Town"}
                id="combo-box-demo1"
                className={
                  formValues.city.error
                    ? clsx(classes.dropdownStyle, classes.dropdownBorder)
                    : classes.dropdownStyle
                }
                value={formValues.city.value}
                onChange={(event, value) => {
                  value
                    ? handleCities(value)
                    : setFormValues({
                      ...formValues,
                      ["city"]: {
                        ...formValues["city"],
                        value: "",
                        id: 0,
                      },
                    });
                }}
                options={cities?.map((option: any) => option.cityName)}
                renderInput={(params) => (
                  <CssTextField
                    {...params}
                    placeholder="City/Town"
                    className={
                      formValues.city.error ? classes.countryInput : ""
                    }
                    onChange={(event: { target: { value: string } }) =>
                      handleCities(event.target.value)
                    }
                    InputProps={{
                      ...params.InputProps,

                      style: {
                        height: "53px",
                        fontFamily: Fonts.NunitoSans.regular,
                        fontWeight: "400",
                        fontSize: "18px",
                        lineHeight: "21px",
                        borderRadius: "10px",
                        color: "#000",
                      },
                    }}
                    id="custom-css-standard-input-city"
                  />
                )}
              />
              <InputLabel
                className={
                  formValues.city.error
                    ? clsx(classes.dropdownFlex, classes.dropdownErrorStyle)
                    : classes.dropdownErrorStyle
                }
              >
                {formValues.city.error ? "Please select city" : ""}
              </InputLabel>
            </div>

            <div className={classes.textFiledLabelContainerRight}>
              <InputLabel className={classes.labelStyle}>Pincode </InputLabel>
              <CssTextField
                fullWidth
                name="pincode"
                value={formValues.pincode.value}
                onChange={(e: any) => handleStateChange(e)}
                placeholder="Pincode"
                inputProps={{
                  maxLength: 7,
                }}
                InputProps={{
                  classes: { input: classes.input },
                }}
                id="custom-css-standard-input"
              />
            </div>
          </div>
          <div className={classes.twoFieldsContainer}>
            <div className={classes.textFiledLabelContainer}>
              <InputLabel className={classes.labelStyle}>
                Email <span className={classes.asterix}> *</span>
              </InputLabel>
              <CssTextField
                fullWidth
                value={formValues.email.value}
                error={formValues.email.error}
                onChange={(e: any) => handleStateChange(e)}
                helperText={formValues.email.error ? "Please enter email" : ""}
                name="email"
                placeholder="Email"
                inputProps={{
                  maxLength: 225,
                }}
                InputProps={{
                  classes: { input: classes.input },
                }}
                id="custom-css-standard-input"
              />
            </div>

            <div className={classes.textFiledLabelContainerRight}>
              <InputLabel className={classes.labelStyle}>
                Contact number <span className={classes.asterix}> *</span>
              </InputLabel>
              <div style={{ display: "flex", flexDirection: "row" }}>
                <CssTextField
                  fullWidth
                  value={flag + " " + country_code}
                  InputProps={{
                    classes: { input: classes.flagInput },
                  }}
                  id="outlined-adornment-password"
                  style={{ marginRight: "10px", width: getCountryCodeLength() }}
                />
                <CssTextField
                  fullWidth
                  placeholder="Contact number"
                  name="contact"
                  value={formValues.contact.value}
                  error={formValues.contact.error}
                  onChange={(e: any) => handleStateChange(e)}
                  helperText={
                    formValues.contact.error
                      ? "Please enter contact number"
                      : ""
                  }
                  inputProps={{
                    maxLength: 10,
                  }}
                  InputProps={{
                    classes: { input: classes.input },
                  }}
                  id="outlined-adornment-password"
                />
              </div>
            </div>
          </div>
          <div className={classes.btnConfirmContainer}>
            <div className={classes.btnConfirmContainer}>
              <Button
                onClick={() => {
                  createOrganization();
                }}
                style={{
                  background: formValues.organization_name.value === "" ||
                    formValues.domain_name.value === "" ||
                    formValues.industry_type.value === "none" ||
                    formValues.industry_size.value === "none" ||
                    formValues.country.value === "none" ||
                    formValues.state.value === "none" ||
                    formValues.city.value === "none" ||
                    formValues.email.value === "" ||
                    formValues.address.value === "" ||
                    formValues.contact.value === ""
                    ? "#ecedf2" 
                    : "linear-gradient(90deg, #5EB0FA 0%, #1157CD 50.54%, #9C90FA 100%)", 
                  color: formValues.organization_name.value === "" ||
                    formValues.domain_name.value === "" ||
                    formValues.industry_type.value === "none" ||
                    formValues.industry_size.value === "none" ||
                    formValues.country.value === "none" ||
                    formValues.state.value === "none" ||
                    formValues.city.value === "none" ||
                    formValues.email.value === "" ||
                    formValues.address.value === "" ||
                    formValues.contact.value === ""
                    ? "#cccdd2" 
                    : "#fff", 
                  textTransform: "capitalize",
                  height: "50px",
                  marginTop: "10px",
                }}
                className={classes.btnLabelStyle}
                fullWidth
                variant="contained"
                size="large"
                disabled={
                  formValues.organization_name.value === "" ||
                  formValues.domain_name.value === "" ||
                  formValues.industry_type.value === "none" ||
                  formValues.industry_size.value === "none" ||
                  formValues.country.value === "none" ||
                  formValues.state.value === "none" ||
                  formValues.city.value === "none" ||
                  formValues.email.value === "" ||
                  formValues.address.value === "" ||
                  formValues.contact.value === ""
                }
              >
                Confirm Details
              </Button>
            </div>

          </div>
        </Box>
      </div>
    </Typography>
  );
};

export default CompanyDetails;
