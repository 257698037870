import { BASE_URL } from "../../config/WingsbiConfig";
import { getUserAccessTokenFromCookies } from "../cookie/cookies";
import { refreshTokenBeforeExpire } from "../msal/RefreshToken";

export const getData = async (path: string) => {
  //await refreshTokenBeforeExpire();
  return fetch(BASE_URL + path, {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
      Authorization: "Bearer " + getUserAccessTokenFromCookies(),
    },
  })
    .then((response) => response.json())
    .then((responseData) => {
      if (!responseData?.isError) {
        return responseData?.result;
      } else {
        return responseData;
      }

      //re-try use cases
    })
    .catch((error) => console.warn(error));
};

export const getEncodedUrl = (path: string) => {
  return fetch(BASE_URL + path, {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
      //AUTHORIZATION: "Bearer " + accessToken,
    },
  })
    .then((response) => response.json())
    .then((responseData) => {
      return responseData?.result;
      //re-try use cases
    })
    .catch((error) => console.warn(error));
};
