import React, { useEffect, useState } from 'react';
import {
  RadialBarChart,
  RadialBar,
  Legend,
  ResponsiveContainer,
  Tooltip,
} from 'recharts';
// import { useStyle } from "./ScatterPlotStyle";
import { Colors, Images } from '../../../constants';
import { active } from 'd3-transition';

const data =  [
    {
      "b.branch_name": "Chicago Branch",
      "a.balance": 6300,
      "fill": "#ff4ca1"
    },
    {
      "b.branch_name": "Downtown Branch",
      "a.balance": 16100,
      "fill": "#6c5e5c"
    },
    {
      "b.branch_name": "Houston Branch",
      "a.balance": 14300,
      "fill": "#dff155"
    },
    {
      "b.branch_name": "Los Angeles Branch",
      "a.balance": 10800,
      "fill": "#7e1885"
    },
    {
      "b.branch_name": "Main Branch",
      "a.balance": 26800,
      "fill": "#432f33"
    },
    {
      "b.branch_name": "Miami Branch",
      "a.balance": 9900,
      "fill": "#ed8764"
    },
    {
      "b.branch_name": "New York Branch",
      "a.balance": 17500,
      "fill": "#b5afe7"
    },
    {
      "b.branch_name": "North Side Branch",
      "a.balance": 13700,
      "fill": "#27add1"
    },
    {
      "b.branch_name": "West End Branch",
      "a.balance": 5600,
      "fill": "#f5d10e"
    }
  ];

const style = {
  top: '50%',
  right: 0,
  transform: 'translate(0, -50%)',
  lineHeight: '24px',
};
interface CustomTooltipProps {
  active?: boolean;
  payload?: Array<{ value: number; payload: any }>;
}
// This interface will allow any string as a key and the value can be any type.
// Adjust the types as necessary based on your actual data structure.
interface DataItem {
  [key: string]: any; // Use a more specific type instead of `any` if possible.
}


const CustomTooltip: React.FC<CustomTooltipProps> = ({ active, payload }) => {
  if (active && payload && payload.length) {
    const dataItem = payload[0].payload;

    // Define the tooltip style here
    const tooltipStyle = {
      backgroundColor: dataItem.fill,
      padding: '15px',
      border: '1px solid #ccc',
      borderRadius: '5px',
      boxShadow: '0px 0px 10px rgba(0, 0, 0, 0.1)',
      color: 'white'
    };

    // Dynamically generate parameter names and their values
    const parameterNames = Object.keys(dataItem)
      .filter(key => key !== 'fill'); // Exclude the 'fill' key
    
    return (
      <div style={tooltipStyle}>
        {parameterNames.map((paramName, index) => (
          <p key={index}>{`${paramName}: ${dataItem[paramName]}`}</p>
        ))}
      </div>
    );
  }

  return null;
};



 

const RadialChart = React.memo((props: any) => { 
  const [RadialChartJson, setRadialChartJson] = useState<any>([]);
  const [isLoading, setIsLoading] = useState(true);
  const [chartDimensions, setChartDimensions] = useState({ width: '100%', height: 300 });
  const [activeTab, setActiveTab] = React.useState(0);
  // const { classes } = useStyle({ isYaxis: false, isXaxis: true, activeTab });
  console.log("props",props);
  console.log("datakey_Y",RadialChartJson.datakey_Y)
  console.log("datakey_X",RadialChartJson.datakey_X)

  useEffect(() => {
    let  bdata  = props.data;
    setRadialChartJson(bdata);
    setIsLoading(false);
  }, [props.data]);
  

  useEffect(() => {
    const disableScrolling = () => {
      document.documentElement.style.overflow = 'hidden';
      document.body.style.overflow = 'hidden';
    };

    disableScrolling();

    // Re-enable scrolling when the component unmounts
    return () => {
      document.documentElement.style.overflow = 'auto';
      document.body.style.overflow = 'auto';
    };
  }, []);


const chartSize = 500; // This should be dynamic or based on the available space
const dataLength = RadialChartJson && RadialChartJson.data ? RadialChartJson.data.length : 0;

// Now use dataLength to compute barSize and other related properties
// Note: The rest of the code assumes that RadialChartJson.data is an array
const maxBarThickness = 40; // Set this to your preference
const minBarThickness = 10; // Set this to your preference
const barSize = dataLength > 0 ? Math.max(minBarThickness, maxBarThickness / Math.log(dataLength + 1)) : 10;

// Adjust the outerRadius to make the rings larger when there is more data
const maxOuterRadius = chartSize / 2 - 10 + dataLength * 5; // Increase the size based on data length
const outerRadius = Math.min(maxOuterRadius, chartSize / 2 - barSize * dataLength);

// Adjust the innerRadius to allow more space for the bars to be thicker
const innerRadius = Math.max(10, outerRadius - barSize * dataLength);

// Calculate the font size for labels dynamically (optional)
const labelFontSize = dataLength > 20 ? 8 : 12; 


  const style = {
    top: '50%',
    right: 0,
    transform: 'translate(0, -50%)',
    lineHeight: '24px',
  };
  

  return (
    <div
      key="CHART101"
      style={{
        backgroundColor: Colors?.background.backgroundColorTheme3,
        height: "87vh",
        width: "75%",
        // overflowX: "auto",
        boxShadow: "0px 0px 4px 0px #00000040",
        borderRadius: "10px 0px 0px 10px",
        // overflow: "scroll",
        top: 0,
      }}
    >
       Datasource name :<label>{props?.dbsource}</label>
      {props?.pageError !== "" ? (
        <div
          key="TAB101"
          style={{
            display: "flex",
            height: "87vh",
            width: "100%",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <img
            style={{
              height: "auto",
              width: "300px",
            }}
            src={Images.no_data_img}
          />
        </div>
      ) : (
        <div
          key="CHART1005"
          style={{
            width: "100%",
            // `${80 * scatterPlotJson?.data?.length}px?`,
            height: "75vh",
          }}
        >
           {/* <div style={moduleLoadingViewStyle}> */}
           <ResponsiveContainer width="100%" height="100%">
      <RadialBarChart
      
        cx="50%"
        cy="51%"
        
        width={chartSize}
        height={chartSize}
        innerRadius={innerRadius}
        outerRadius="104%"
        barSize={barSize}
        data={RadialChartJson.data}
        
      >
        <RadialBar
          label={{ fill: '#222', fontSize: labelFontSize }}
          background
          dataKey={RadialChartJson.datakey_Y}
          
        />
        <Tooltip content={<CustomTooltip />} />
      </RadialBarChart>
    </ResponsiveContainer>
    </div>
      )}
    </div>
  );
  })


export default RadialChart;

