import {
  React,
  Tabs,
  Tab,
  useState,
  Box,
  Typography,
  Button,
  Select,
  MenuItem,
  clsx,
  useEffect,
  Backdrop,
  CircularProgress,
  useNavigate,
  useLocation,
} from "../../../../app.module";
import BarChart from "./BarChart";
import LineChart from "./LineChart";
import TableChart from "./TableChart";
import AreaChart from "./AreaChart";
import PieChart from "./PieChart";
import ScatterPlot from "./ScatterPlot";
import AreaBump from "./AreaBump";
import Bump from "./Bump";
import ToggleButton from "@mui/material/ToggleButton";
import Switch from "@mui/material/Switch";
import ToggleButtonGroup from "@mui/material/ToggleButtonGroup";
import CardChart from "./CardChart";
import { useStyle } from "./VisualizationStyle";
import dayjs, { Dayjs } from "dayjs";
import { DemoContainer } from "@mui/x-date-pickers/internals/demo";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import {
  getVisualizationPayLoad,
  getDates,
  getMonths,
  getDays,
  getrecurrencetype,
} from "./ChartsService";
import { getSubscription } from "../../Organization_Details/OrganizationService";
import { Colors, Fonts, Images } from "../../../constants";
import {
  getUserDetailsFromCookies,
  getQueryIdFromCookies,
  postData,
} from "../../../services";
import Dialog from "@mui/material/Dialog";
import RadarrChart from "../../modules/nivocharts/RadarrChart";
import RadialChart from "./RadialChart";
import {
  DialogTitle,
  SelectChangeEvent,
} from "@mui/material";
import { dogetdatabase } from "../../Main_Project/datasource/database/DatabaseService";
interface TabPanelProps {
  children?: React.ReactNode;
  index: number;
  indexAxisFlag: number;
  value: number;
  xAxis: string;
  yAxis: string;
  visualizationTypeList: any[];
  selectedVisulaizationType: string;
  visualizationChartDetails: any;
  visualizationChartState: any;
  isY: boolean;
  classes: any;
  handleChartType: (name: string) => void; // Function type now expects a string parameter
  handleAxisChanged: (x: any, y: any) => void;
}

interface Day {
  id: number;
  day: string;
}
interface RecurrenceType {
  id: number;
  scheduletype: string;
}

const CustomTabPanel = (props: TabPanelProps) => {
  const {
    children,
    handleChartType,
    handleAxisChanged,
    visualizationTypeList,
    selectedVisulaizationType,
    visualizationChartDetails,
    visualizationChartState,
    value,
    classes,
    index,
    isY,
    indexAxisFlag,
    xAxis,
    yAxis,
    ...other
  } = props;
  console.log(
    "show visualizationChartDetails",
    indexAxisFlag,
    visualizationChartDetails?.chartParameter[1]?.parameterValue[
      parseInt(visualizationChartState?.chartParameter.split(",")[1])
    ]
      ? indexAxisFlag === 0
        ? visualizationChartDetails?.chartParameter[1]?.parameterValue[
            parseInt(visualizationChartState?.chartParameter.split(",")[1])
          ]
        : visualizationChartDetails?.chartParameter[1]?.parameterValue[0]
      : "none"

    //visualizationChartState,
    //visualizationChartDetails
  );
  const [yaxis, setYaxis] = useState("none"); // we have set the none if there is empty visualization
  const [xaxis, setXaxis] = useState("none");
  const [isMounted, setIsMounted] = useState<boolean>(false);

  const [lastYAxisValue, setLastYAxisValue] = useState();

  const [moduleType, setModuleType] = useState(selectedVisulaizationType);
  const [showPlaceholder, setShowPlaceholder] = useState(moduleType === "none");
  const [moduleTypeList, setModuleTypeList] = useState<any>([]);
  const [selectedUpdateModule, setSelectedUpdateModule] = useState("");
  const [moduleColumnHeaders, setModuleColumnHeaders] = useState([
    { name: "Donuts", id: 1 },
    { name: "Fries", id: 2 },
    { name: "Kebab", id: 3 },
    { name: "Sandwich", id: 4 },
  ]);
  const [colorType, setColorType] = useState("none");
  const [colorTypeList, setColorTypeList] = useState([
    { color: "red", id: 1, name: "##89D280" },
    { color: "blue", id: 2, name: "##FBDB38" },
    { color: "green", id: 3, name: "##EF6C6C" },
    { color: "yellow", id: 4, name: "###66C8FF" },
  ]);
  const [moduleHeaderType, setModuleHeaderType] = useState("Fries");
  const navigate = useNavigate();
  useEffect(() => {
    console.log(
      "Panel UseEffect from child",
      visualizationChartDetails?.chartParameter[0]?.parameterValue[0],
      indexAxisFlag,
      xAxis,
      yAxis
    );
    setXaxis(xAxis);
    if (moduleType !== "CardChart") {
      setYaxis(yAxis);
    } else if (lastYAxisValue) {
      // If the module type is CardChart, we might want to keep the last known Y-axis value
      setYaxis(lastYAxisValue);
    }
    setIsMounted(true);
  }, [moduleType, yAxis, xAxis]);

  const handleModuleStateChange = (e: any) => {
    const { value } = e.target;
    handleChartType(value);
    setModuleType(value);
  };
  const handleXaxisStateChange = (e: any) => {
    const { name, value } = e.target;
    setXaxis(value);
  };
  // const handleYaxisStateChange = (e: any) => {
  //   const { name, value } = e.target;
  //   setYaxis(value);
  // };

  useEffect(() => {
    if (lastYAxisValue && moduleType !== "CardChart") {
      setYaxis(lastYAxisValue);
    }
  }, []);

  const handleYaxisStateChange = (e: any) => {
    const { value } = e.target;
    setYaxis(value);
    if (moduleType !== "CardChart") {
      setLastYAxisValue(value);
    }
  };
  const handleColumnHeaderStateChange = (e: any) => {
    const { name, value } = e.target;
    //handleChartType(value);
    setModuleHeaderType(value);
  };

  const handleColorStateChange = (e: any) => {
    const { name, value } = e.target;
    setColorType(value);
  };


  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      <Box sx={{ p: 3 }}>
        {/* {isY && <Typography>{children}</Typography>} */}
        <Typography className={classes.visualizationLable}>
          Module type
        </Typography>

        <Select
          id="demo-simple-select-helper-type"
          value={moduleType}
          // error={formValues.database_type.error}
          name="module_type"
          className={clsx(
            classes.visualizationSelect

            // formValues.database_type.value === "none"
            //   ? classes.projectSelectdisabled
            //   : null
          )}
          onFocus={(e: any) => setShowPlaceholder(false)}
          onChange={handleModuleStateChange}
          onClose={(e: any) => {
            setShowPlaceholder(
              (e.target as HTMLInputElement).value === undefined
            );
          }}
          //defaultValue="none"
        >
          <MenuItem
            // className={clsx(
            //   classes.projectMenuitem,
            //   !showPlaceholder ? classes.projectMenuitemhidden : null
            // )}
            key="0"
            disabled
            value={moduleType}
          >
            Select module
          </MenuItem>

          {visualizationTypeList?.map((item: any) => (
            <MenuItem
              key={item?.chartType + index}
              className={classes.visualizationMenuitem}
              value={item?.chartType}
            >
              {item?.chartType}
            </MenuItem>
          ))}
        </Select>
        <Typography className={classes.visualizationLable}>
          {visualizationChartDetails?.chartParameter[0]?.parameterName}
        </Typography>
        <Select
          id="demo-simple-select-helper-type"
          value={xaxis}
          // error={formValues.database_type.error}
          name="xaxis"
          className={clsx(
            classes.visualizationSelect

            // formValues.database_type.value === "none"
            //   ? classes.projectSelectdisabled
            //   : null
          )}
          onFocus={(e: any) => setShowPlaceholder(false)}
          onChange={handleXaxisStateChange}
          // onClose={(e) => {
          //   setShowPlaceholder(
          //     (e.target as HTMLInputElement).value === undefined
          //   );
          //}}
          defaultValue={xaxis ? xaxis : ""}
        >
          <MenuItem
            // className={clsx(
            //   classes.projectMenuitem,
            //   !showPlaceholder ? classes.projectMenuitemhidden : null
            // )}
            key="0"
            disabled
            value={xaxis}
          >
            Select
          </MenuItem>

          {isMounted &&
            visualizationChartDetails?.chartParameter[0]?.parameterValue?.map(
              (item: any, index: number) => (
                <MenuItem
                  key={index + ""}
                  className={classes.visualizationMenuitem}
                  value={item}
                >
                  {item}
                </MenuItem>
              )
            )}
        </Select>

        {moduleType !== "CardChart" && (
          <>
            <Typography className={classes.visualizationLable}>
              {visualizationChartDetails?.chartParameter[1]?.parameterName}
            </Typography>
            <Select
              id="demo-simple-select-helper-type"
              value={yaxis}
              // error={formValues.database_type.error}
              name="yaxis"
              className={clsx(
                classes.visualizationSelect

                // formValues.database_type.value === "none"
                //   ? classes.projectSelectdisabled
                //   : null
              )}
              onFocus={(e: any) => setShowPlaceholder(false)}
              onChange={handleYaxisStateChange}
              // onClose={(e) => {
              //   setShowPlaceholder(
              //     (e.target as HTMLInputElement).value === undefined
              //   );
              //}}
              defaultValue="none"
            >
              <MenuItem
                // className={clsx(
                //   classes.projectMenuitem,
                //   !showPlaceholder ? classes.projectMenuitemhidden : null
                // )}
                key="0"
                disabled
                value={yaxis}
              >
                Select
              </MenuItem>

              {isMounted &&
                visualizationChartDetails?.chartParameter[1]?.parameterValue?.map(
                  (item: any, index: number) => (
                    <MenuItem
                      key={index + ""}
                      className={classes.visualizationMenuitem}
                      value={item}
                    >
                      {item}
                    </MenuItem>
                  )
                )}
            </Select>
          </>
        )}
        <Button
          onClick={() => {
            handleAxisChanged(xaxis, yaxis);
          }}
          variant="contained"
          className={classes.saveTableBtn}
        >
          Analyze
        </Button>
        <div
          style={{
            fontFamily: Fonts.NunitoSans.regular,
            fontSize: "16px",
            color: "#333",
            marginTop: "20px",
            padding: "10px",
            backgroundColor: "#f3f3f3",
            borderRadius: "4px",
            boxShadow: "0 2px 4px rgba(0, 0, 0, 0.1)",
          }}
        >
          <b>Note:</b> Click on Analyze button to save the changes.
        </div>
      </Box>
    </div>
  );
};

const CustomTabPanel1 = (props: TabPanelProps) => {
  const location = useLocation();
  const { children, value, classes, index, isY, ...other } = props;
  const [updateModue, setUpdateModue] = useState("Daily");
  const [updateType, setUpdateType] = useState("none");
  // const [selectedUpdateModule, setSelectedUpdateModule] = useState('');
  const [selectedUpdateModule, setSelectedUpdateModule] = useState("Daily");
  const [recurrenceTypeId, setRecurrenceTypeId] = useState(1);
  const [reccuranceList, setReccuranceList] = useState([]);
  const [recurranceDays, setRecurranceDays] = useState<Day[]>([]);
  const [selectedDays, setSelectedDays] = useState<string[]>([]);
  // const [reccuranceType, setReccuranceType] = useState([]);
  const [reccuranceType, setReccuranceType] = useState<RecurrenceType[]>([]);
  const [selectedTypeId, setSelectedTypeId] = useState<number | null>(1);

  // const [nextRenewal, setNextRenewal] = useState('');
  const [nextRenewal, setNextRenewal] = useState<string>(""); // State for the next renewal date as a string
  const [endDate, setEndDate] = useState<Dayjs | null>(null);
  const [startDate, setStartDate] = useState<Dayjs | null>(null);

  useEffect(() => {
    const fetchSubscription = async () => {
      const subscriptionData = await getSubscription();
      if (subscriptionData && subscriptionData.nextRenewal) {
        const dateOnly = subscriptionData.nextRenewal.split("T")[0];
        const dateWithOneDaySubtracted = dayjs(dateOnly)
          .subtract(1, "day")
          .format("YYYY-MM-DD"); // Format as string
        setNextRenewal(dateWithOneDaySubtracted); // Assuming setNextRenewal expects a string
        setEndDate(dayjs(dateWithOneDaySubtracted)); // If setEndDate expects a Dayjs object
        setStartDate(dayjs(dateWithOneDaySubtracted));
      }
    };

    fetchSubscription();
  }, []);

  const [selectedModuleId, setSelectedModuleId] = useState(
    location?.state?.moduleId
  );

  useEffect(() => {
    getDays().then((days: any) => {
      setRecurranceDays(days);
      console.log("Days", days);
    });
    getMonths().then((months: any) => {
      console.log("Months", months);
    });
    getDates().then((dates: any) => {
      setReccuranceList(dates);
      console.log("Dates", dates);
    });
    getrecurrencetype().then((type: any) => {
      setReccuranceType(type);
      console.log("Types", type);
    });
  }, []);

  const handleDayChange = (
    event: React.MouseEvent<HTMLElement>,
    newSelectedDay: string
  ) => {
    // Update the selected day in state
    setSelectedDays([newSelectedDay]); // Since 'exclusive' is true, 'newSelectedDay' is a string

    // Find the day object that matches the selected day
    const selectedDayObject = recurranceDays.find(
      (day) => day.day === newSelectedDay
    );

    // Log the ID of the selected day
    if (selectedDayObject) {
      console.log("Selected Day ID:", selectedDayObject.id);
    }
  };

  const [reccurance, setReccurance] = useState("1");
  const [showPlaceholder, setShowPlaceholder] = useState(
    updateModue === "none"
  );

  useEffect(() => {}, [updateModue]);
  
  useEffect(() => {
    const idMapping: { [key: string]: number } = {
      Daily: 1,
      Weekly: 2,
      Monthly: 4,
    };
    const typeId = idMapping[selectedUpdateModule];
    if (typeId !== undefined) {
      setRecurrenceTypeId(typeId);
    } else {
      console.warn(`Unexpected module name: ${selectedUpdateModule}`);
    }
  }, [selectedUpdateModule]);
 
  const handleModuleStateChange = (event: SelectChangeEvent<string>) => {
    const value = event.target.value; // This is the selected schedule type's name
    console.log("Selected module:", value);

    // Update the state for the selected schedule type's name
    setUpdateModue(value);
    setSelectedUpdateModule(value);

    // Find the corresponding type object to get the ID
    const typeObject = reccuranceType.find(
      (type) => type.scheduletype === value
    );
    const typeId = typeObject ? typeObject.id : null;

    // Update the state for the selected schedule type's ID
    setSelectedTypeId(typeId);
  };

  const handleUpdatesStateChange = (e: any) => {
    const { name, value } = e.target;
    //handleChartType(value);
    setUpdateType(value);
  };
  // const handleReccuranceStateChange = (e: any) => {
  //   const { name, value } = e.target;
  //   setReccurance(e.target.value);
  // };
  const handleReccuranceStateChange = (e: any) => {
    const { value } = e.target; // You can also destructure 'name' if you need it
    console.log("Selected Date:", value); // Log the selected date
    setReccurance(value); // Update state
  };

  const [showDetails, setShowDetails] = useState(true);
  const [isSwitchOn, setIsSwitchOn] = useState(false);
  const handleToggleChange = (event: any) => {
    setIsSwitchOn(event.target.checked);
  };

  const handleFinishClick = () => {
    console.log("Finish button clicked!");
  };
  const [age, setAge] = React.useState("");

  const handleChange = (event: any) => {
    setAge(event.target.value);
  };

  const [isRecurrenceEnabled, setIsRecurrenceEnabled] = useState(false);
  const [selectedDate, setSelectedDate] = useState<Dayjs | null>(
    dayjs().add(1, "day")
  );
  const [maxEndDate, setMaxEndDate] = React.useState<Dayjs>(dayjs());
  const [minDate, setMinDate] = useState<Dayjs>(dayjs().add(1, "day"));
  const [minEndDate, setMinEndDate] = useState<Dayjs>(dayjs().add(1, "day"));

  const sendRecurrence = async (requestBody: any) => {
    return await postData(
      `/${getUserDetailsFromCookies().identifier}/api/project/${
        getUserDetailsFromCookies().projectId
      }/recurrence/recurrence`,
      requestBody
    ).then((result: any) => {
      return result;
    });
  };

  const fetchAPI = async () => {
    const formattedStartDate = startDate ? startDate.toISOString() : null;
    // const formattedEndDate = endDate ? endDate.toISOString() : null;
    const formattedEndDate = endDate
      ? endDate.hour(23).minute(59).second(59).millisecond(999).toISOString()
      : null;

    const payload = {
      recurrenceStartDate: formattedStartDate,
      recurrenceEndDate: formattedEndDate,
      recurrenceTypeId: selectedTypeId,
      recurrenceFrequency:
        selectedUpdateModule === "Weekly"
          ? selectedDays.length > 0
            ? String(
                recurranceDays.find(
                  (day: { day: string }) => day.day === selectedDays[0]
                )?.id
              )
            : null
          : selectedUpdateModule === "Monthly"
          ? reccurance // Use the state variable holding the selected date for monthly recurrence
          : null,
      projectId: getUserDetailsFromCookies().projectId,
      moduleId: selectedModuleId,
      queryId: getQueryIdFromCookies(),
      createdBy: getUserDetailsFromCookies().createdBy,
      createdDate: getUserDetailsFromCookies().createdDate,
      modifiedBy: getUserDetailsFromCookies().modifiedBy,
      modifiedDate: getUserDetailsFromCookies().modifiedDate,
    };

    console.log("Payload::", payload);

    try {
      const result = await sendRecurrence(payload);
      console.log("API Response::", result);
      return result;
    } catch (error) {
      console.error("Failed to send recurrence data:", error);
    }
  };

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
      style={{ marginRight: "6%" }}
    >
<div style={{ display: 'flex', alignItems: 'center' }}>
  <Typography
    className={classes.recurranceLable}
    style={{ marginLeft: '20px' }} // Space to the right of the text
  >
    <b>Enable Recurrence</b>
  </Typography>
  <div style={{ position: 'relative', left: '10%', top: '4px' }}> {/* Adjust left and top to set the position of the switch */}
    <Switch
      checked={isSwitchOn}
      onChange={handleToggleChange}
      inputProps={{ 'aria-label': 'controlled' }}
    />
  </div>
</div>
      <Typography
        className={classes.visualizationLable}
        style={{ marginLeft: "20px", marginBottom: "10px", marginTop: "15px" }}
      >
        <b style={{ marginBottom: "5px" }}>Recurrence Frequency</b>
      </Typography>
      <Select
        style={{ marginLeft: "20px" }}
        //  id="demo-simple-select-helper-type"
        // value={isRecurrenceEnabled ? recurrenceFrequency : ''}
        // onChange={(e) => setRecurrenceFrequency(e.target.value)}
        // name="recurrenceFrequency"
        id="recurrence-frequency-select"
        //  disabled={!isSwitchOn}
        value={updateModue}
        onChange={handleModuleStateChange}
        disabled={!isSwitchOn}
        name="module_type"
        className={clsx(classes.visualizationSelect)}
        onFocus={(e: any) => setShowPlaceholder(false)}
        //  onChange={handleModuleStateChange}
        onClose={(e: any) => {
          setShowPlaceholder(
            (e.target as HTMLInputElement).value === undefined
          );
        }}
        defaultValue="none"
      >
        <MenuItem
          // className={clsx(
          //   classes.projectMenuitem,
          //   !showPlaceholder ? classes.projectMenuitemhidden : null
          // )}
          key="0"
          disabled
          value="none"
        >
          Select update module type
        </MenuItem>

        {reccuranceType.map((item: any) => (
          <MenuItem
            key={item?.id}
            className={classes.visualizationMenuitem}
            value={item?.scheduletype}
          >
            {item?.scheduletype}
          </MenuItem>
        ))}
      </Select>

      {/* {isRecurrenceEnabled && ( */}
      {isSwitchOn && (
        <>
          {value === index && (
            <Box sx={{ marginLeft: "20px" }}>
              {selectedUpdateModule === "Monthly" && (
                <Typography
                  className={classes.recurranceLable}
                  style={{ marginBottom: "-10px" }}
                >
                  {" "}
                  <b> Repeat every</b>{" "}
                </Typography>
              )}
              <div className={classes.twoDropDownContainer}>
                <div
                  className={clsx(
                    classes.visualizationRecuuranceSelectFlex,
                    classes.dropDownFiledLabelContainer
                  )}
                >
                  {selectedUpdateModule == "Monthly" && (
                    <Select
                      id="demo-simple-select-helper-type"
                      value={reccurance}
                      // error={formValues.database_type.error}
                      name="module_type"
                      className={clsx(
                        classes.visualizationSelect

                        // formValues.database_type.value === "none"
                        //   ? classes.projectSelectdisabled
                        //   : null
                      )}
                      onFocus={(e: any) => setShowPlaceholder(false)}
                      onChange={handleReccuranceStateChange}
                      onClose={(e: any) => {
                        setShowPlaceholder(
                          (e.target as HTMLInputElement).value === undefined
                        );
                      }}
                      defaultValue="none"
                    >
                      <MenuItem
                        // className={clsx(
                        //   classes.projectMenuitem,
                        //   !showPlaceholder ? classes.projectMenuitemhidden : null
                        // )}
                        key="0"
                        disabled
                        value="none"
                      >
                        Reccurance
                      </MenuItem>

                      {reccuranceList.map((number: any) => (
                        <MenuItem
                          key={number?.id}
                          value={number.date}
                          className={classes.noScrollbar}
                          style={{ overflowY: "auto", maxHeight: "200px" }}
                        >
                          {number.date}
                        </MenuItem>
                      ))}
                    </Select>
                  )}

                  {
                    <Typography
                      className={classes.recurranceLable}
                      style={{ marginTop: "-10px", marginBottom: "8px" }}
                    >
                      <b>Set Interval</b>
                    </Typography>
                  }
                  <ToggleButtonGroup
                    style={{ marginBottom: "20px" }}
                    value={selectedDays}
                    exclusive
                    onChange={handleDayChange}
                    aria-label="day selection"
                    className={classes.toggleButtonGroup}
                  >
                    {selectedUpdateModule === "Weekly" &&
                      recurranceDays.map((day: any) => (
                        <ToggleButton
                          key={day.id}
                          value={day.day} // Using the full day name as the value now
                          aria-label={day.day} // Using the full day name for accessibility
                          className={classes.toggleButton}
                          style={{
                            border: "none",
                            borderRadius: "50%",
                            width: "20px",
                            height: "20px",
                            margin: "2px",
                            color: selectedDays.includes(day.day)
                              ? "#fff"
                              : "#000", // Check against the full day name
                            backgroundColor: selectedDays.includes(day.day)
                              ? "#3f51b5"
                              : "#e0e0e0",
                          }}
                        >
                          {day.day.charAt(0)}
                        </ToggleButton>
                      ))}
                  </ToggleButtonGroup>
                </div>
                <div
                  className={classes.dropDownRecurranceFiledLabelContainerRight}
                ></div>
              </div>

              <div style={{ marginLeft: "-2%", marginTop: "-20px",paddingBottom: '200px' }}>
                <LocalizationProvider dateAdapter={AdapterDayjs}>
                  <DemoContainer components={["DatePicker", "DatePicker"]}>
                    <div style={{ maxWidth: "240px" }}>
                      {/* <DatePicker  

          label="Start Date"
          value={selectedDate}
          // onChange={handleStartDateChange}
          onChange={(newDate) => {
            setStartDate(dayjs(newDate)); // Wrap with dayjs if newDate is not already a Dayjs object
          }}
         minDate={minDate} 
        /> */}
                      <DatePicker
                        label="Start Date"
                        value={selectedDate}
                        onChange={(newDate) => {
                          setStartDate(dayjs(newDate)); // Update end date
                        }}
                        minDate={minDate}
                        shouldDisableDate={(date) => {
                          // This will disable all dates after `nextRenewal`
                          return date.isAfter(dayjs(nextRenewal));
                        }}
                      />
                    </div>
                    <div style={{ maxWidth: "240px" }}>
                      <DatePicker
                        label="End Date"
                        value={endDate}
                        onChange={(newDate) => {
                          setEndDate(dayjs(newDate)); // Update end date
                        }}
                        minDate={minDate}
                        shouldDisableDate={(date) => {
                          // This will disable all dates after `nextRenewal`
                          return date.isAfter(dayjs(nextRenewal));
                        }}
                
                      />
                    </div>
                  </DemoContainer>
                </LocalizationProvider>
              </div>


            </Box>
          )}
          <Button
            variant="contained"
            className={classes.nextProBtn}
            style={{
              background:
                "linear-gradient(90deg, #5EB0FA 0%, #1157CD 50.54%, #9C90FA 100%)",
              //  fontFamily: Fonts.NunitoSans.regular,
              fontStyle: "normal",
              fontWeight: 700,
              fontSize: "18px",
              lineHeight: "25px",
              borderRadius: "5px",
              textTransform: "capitalize",
              height: "50px",
              width: "140px",
              marginBottom: "-250px",
              left: "87%",
              top: "80%",
              position: "fixed",
            }}
            onClick={fetchAPI}
          >
            Save
          </Button>
        </>
      )}
    </div>
  );
};

const a11yProps = (index: number) => {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
};

const AnalyzeModules = React.memo((props: any) => {
  const location = useLocation();
  const [indexFlag, setIndexFlag] = useState<number>(0);
  const [value, setValue] = useState<number>(0); // Add type annotation for the state value
  const [isYaxis, setIsYaxis] = useState<boolean>(false);
  const [isXaxis, setIsXaxis] = useState<boolean>(false);
  const [visualizationChartJson, setVisualizationChartJson] = useState<any>([]);
  const [customVisualizationColors, setCustomVisualizationColors] =
    useState<any>([]);
  const [visualizationIndexValue, setVisualizationIndexValue] = useState("");
  const [isLoading, setIsLoading] = useState(true);
  const [isAxisChanged, setIsAxisChanged] = useState(true);
  const [selectedModuleType, setSelectedModuleType] = useState(
    location?.state?.moduleType
  );
  const [selectedModuleId, setSelectedModuleId] = useState(
    location?.state?.moduleId
  );
  const [pageError, setPageError] = useState("");
  const [visualizationTypes, setVisualizationTypes] = useState<any>([
    ...location?.state?.possible_visualization,
  ]);
  const [visualizationVisualization, setVisualizationVisualization] =
    useState<any>(location?.state?.selected_visualization);
  const [visualizationVisualizationState, setVisualizationVisualizationState] =
    useState<any>(location?.state?.states);
  const [defsColor, setDefsColor] = useState("red");
  const [activeTab, setActiveTab] = React.useState(0);
  const [xValue, setXValue] = useState<any>(location?.state?.x);
  const [yValue, setYValue] = useState<any>(location?.state?.y);
  const { classes } = useStyle({ isYaxis: false, isXaxis: true, activeTab });
  const [openDialog, setOpenDialog] = useState(false);
  const [selectedDays, setSelectedDays] = useState<string[]>([]);

  const [showRecurrence, setShowRecurrence] = useState(false);
  useEffect(() => {
    // Check the environment variable to determine if the recurrence tab should be shown
    setShowRecurrence(process.env.REACT_APP_RECURRENCE === 'true');
  }, []);

  const handleDayChange = (
    _event: React.MouseEvent<HTMLElement>,
    newDays: string[]
  ) => {
    setSelectedDays(newDays);
  };

  const [selecteddb, setselecteddb] = useState("");
  useEffect(() => {
    dogetdatabase().then((database_list: any) => {
      if (!database_list?.isError) {
        setselecteddb(database_list[0].name);
      }
    });
  });

  useEffect(() => {
    if (visualizationTypes.length === 0) {
    }
  }, []);

  useEffect(() => {
    //  if (visualizationTypes.length > 0) {
    console.log("selectedModuleType SELECTED TYPE", selectedModuleType);
    console.log("selectedModuleType VTYPES", visualizationTypes);
    console.log("selectedModuleType VTYPES", visualizationVisualizationState);
    console.log("selectedmoduleId", selectedModuleId);
    console.log("Siddesh", getQueryIdFromCookies());
    setIsLoading(true);
    setPageError("");
    if (visualizationTypes.length > 0) {
      console.log("selected module type is: ", selectedModuleType);
      switch (selectedModuleType) {
        case "BarChart":
          getVisualizationPayLoad(
            "BarChart",
            selectedModuleId,
            visualizationTypes,
            xValue,
            yValue,
            visualizationVisualizationState,
            indexFlag
          ).then((resp: any) => {
            //setVisualizationVisualization(response?.possibleVisualization);
            console.log(
              "viz x and y value"
              //visualizationVisualizationState,
              //resp?.possibleVisualization?.chartParameter[1]?.parameterValue[0]
            );
            setSelectedModuleType("BarChart");
            // getBarChartVisualizationData(
            //   "BarChart",
            //   response?.visulaization_type_payload
            // ).then((resp) => {
            if (!resp.isError) {
              // const { result } = resp.response;
              // const visualizationChart = JSON.parse(result);
              const resultString =
                typeof resp.response.result === "string"
                  ? resp.response.result
                  : JSON.stringify(resp.response.result);

              // Now, parse the stringified JSON data
              const visualizationChart = JSON.parse(resultString);
              let customColors = visualizationChart.data.map(
                (item: any) => item.balanceColor
              );
              setCustomVisualizationColors([...customColors]);
              setVisualizationChartJson(visualizationChart);
              setVisualizationVisualization(resp.possibleVisualization);
              setVisualizationIndexValue(visualizationChart.indexBy);
              //setTimeout(() => {
              setIsLoading(false);
              //}, 1000);
            } else {
              setPageError("Data not found");
              //setTimeout(() => {
              setIsLoading(false);
              //}, 1000);
            }
            // });
          });
          break;
        case "LineChart":
          getVisualizationPayLoad(
            "LineChart",
            selectedModuleId,
            visualizationTypes,
            xValue,
            yValue,
            visualizationVisualizationState,
            indexFlag
          ).then((data: any) => {
            if (!data?.isError) {
              const resultString =
                typeof data.response.result === "string"
                  ? data.response.result
                  : JSON.stringify(data.response.result);

              // Now, parse the stringified JSON data
              const chartData = JSON.parse(resultString);
              setVisualizationChartJson(chartData);
              setVisualizationVisualization(data.possibleVisualization);
              console.log("LINE CHART", chartData);
              //setTimeout(() => {
              setIsLoading(false);
              //}, 1000);
            } else {
              setPageError("Data not found");
              //setTimeout(() => {
              setIsLoading(false);
              //}, 1000);
            }
          });
          break;
        case "TableChart":
          getVisualizationPayLoad(
            "TableChart",
            selectedModuleId,
            visualizationTypes,
            xValue,
            yValue,
            visualizationVisualizationState,
            indexFlag
          ).then((data: any) => {
            if (!data?.isError) {
              const resultString =
                typeof data.response.result === "string"
                  ? data.response.result
                  : JSON.stringify(data.response.result);

              // Now, parse the stringified JSON data
              const chartData = JSON.parse(resultString);
              setVisualizationChartJson(chartData);
              setVisualizationVisualization(data.possibleVisualization);
              console.log("TABLE CHART", chartData);
              //setTimeout(() => {
              setIsLoading(false);
              //}, 1000);
            } else {
              setPageError("Data not found");
              //setTimeout(() => {
              setIsLoading(false);
              //}, 1000);
            }
          });
          break;
        case "RadarChart":
          getVisualizationPayLoad(
            "RadarChart",
            selectedModuleId,
            visualizationTypes,
            xValue,
            yValue,
            visualizationVisualizationState,
            indexFlag
          ).then((data: any) => {
            if (!data?.isError) {
              const resultString =
                typeof data.response.result === "string"
                  ? data.response.result
                  : JSON.stringify(data.response.result);

              // Now, parse the stringified JSON data
              const chartData = JSON.parse(resultString);
              setVisualizationChartJson(chartData);
              console.log("RadarChart datadata", chartData.data.length);
              setVisualizationVisualization(data.possibleVisualization);
              setIsLoading(false);
            } else {
              setPageError("Data not found");
              setIsLoading(false);
            }
          });
          break;
        case "AreaChart":
          getVisualizationPayLoad(
            "AreaChart",
            selectedModuleId,
            visualizationTypes,
            xValue,
            yValue,
            visualizationVisualizationState,
            indexFlag
          ).then((data: any) => {
            if (!data?.isError) {
              const resultString =
                typeof data.response.result === "string"
                  ? data.response.result
                  : JSON.stringify(data.response.result);

              // Now, parse the stringified JSON data
              const chartData = JSON.parse(resultString);
              setVisualizationChartJson(chartData);
              console.log("AreaChart datadata", chartData.data.length);
              setVisualizationVisualization(data.possibleVisualization);
              setIsLoading(false);
            } else {
              setPageError("Data not found");
              setIsLoading(false);
            }
          });
          break;
        case "RadialBarChart":
          console.log("Radial Visulization");
          getVisualizationPayLoad(
            "RadialBarChart",
            selectedModuleId,
            visualizationTypes,
            xValue,
            yValue,
            visualizationVisualizationState,
            indexFlag
          ).then((data: any) => {
            console.log("data", data);
            if (!data.isError) {
              const resultString =
                typeof data.response.result === "string"
                  ? data.response.result
                  : JSON.stringify(data.response.result);

              // Now, parse the stringified JSON data
              const chartData = JSON.parse(resultString);
              setVisualizationChartJson(chartData);
              setVisualizationVisualization(data.possibleVisualization);
              console.log("Radial CHART", chartData);
              setIsLoading(false);
            } else {
              setPageError("Data not found");
              setIsLoading(false);
            }
          });
          break;
        case "CardChart":
          console.log("CardChart Visulization");
          getVisualizationPayLoad(
            "CardChart",
            selectedModuleId,
            visualizationTypes,
            xValue,
            yValue,
            visualizationVisualizationState,
            indexFlag
          ).then((data: any) => {
            console.log("data", data);
            if (!data.isError) {
              const resultString =
                typeof data.response.result === "string"
                  ? data.response.result
                  : JSON.stringify(data.response.result);

              // Now, parse the stringified JSON data
              const chartData = JSON.parse(resultString);
              setVisualizationChartJson(chartData);
              setVisualizationVisualization(data.possibleVisualization);
              console.log("CARD CHART", chartData);
              setIsLoading(false);
            } else {
              setPageError("Data not found");
              setIsLoading(false);
            }
          });
          break;
        case "PieChart":
          getVisualizationPayLoad(
            "PieChart",
            selectedModuleId,
            visualizationTypes,
            xValue,
            yValue,
            visualizationVisualizationState,
            indexFlag
          ).then((data: any) => {
            if (!data?.isError) {
              const resultString =
                typeof data.response.result === "string"
                  ? data.response.result
                  : JSON.stringify(data.response.result);

              // Now, parse the stringified JSON data
              const chartData = JSON.parse(resultString);
              setVisualizationChartJson(chartData);
              setVisualizationVisualization(data.possibleVisualization);
              console.log("Pie CHART", chartData);
              setIsLoading(false);
            } else {
              setPageError("Data not found");
              setIsLoading(false);
            }
          });
          break;
        case "ScatterPlot":
          getVisualizationPayLoad(
            "ScatterPlot",
            selectedModuleId,
            visualizationTypes,
            xValue,
            yValue,
            visualizationVisualizationState,
            indexFlag
          ).then((data: any) => {
            if (!data?.isError) {
              const resultString =
                typeof data.response.result === "string"
                  ? data.response.result
                  : JSON.stringify(data.response.result);

              // Now, parse the stringified JSON data
              const chartData = JSON.parse(resultString);
              setVisualizationChartJson(chartData);
              setVisualizationVisualization(data.possibleVisualization);
              setIsLoading(false);
            } else {
              setPageError("Data not found");
              setIsLoading(false);
            }
          });
          break;
        case "AreaBumpChart":
          console.log("visualizationTypes AreaBumpChart", visualizationTypes);
          getVisualizationPayLoad(
            "AreaBumpChart",
            selectedModuleId,
            visualizationTypes,
            xValue,
            yValue,
            visualizationVisualizationState,
            indexFlag
          ).then((data: any) => {
            console.log("data", data);
            if (!data?.isError) {
              const resultString =
                typeof data.response.result === "string"
                  ? data.response.result
                  : JSON.stringify(data.response.result);

              // Now, parse the stringified JSON data
              const chartData = JSON.parse(resultString);
              setVisualizationChartJson(chartData);
              setVisualizationVisualization(data.possibleVisualization);
              setIsLoading(false);
            } else {
              setPageError("Data not found");
              setIsLoading(false);
            }
          });
          break;
        case "BumpChart":
          getVisualizationPayLoad(
            "BumpChart",
            selectedModuleId,
            visualizationTypes,
            xValue,
            yValue,
            visualizationVisualizationState,
            indexFlag
          ).then((data: any) => {
            if (!data?.isError) {
              const resultString =
                typeof data.response.result === "string"
                  ? data.response.result
                  : JSON.stringify(data.response.result);

              // Now, parse the stringified JSON data
              const chartData = JSON.parse(resultString);
              setVisualizationChartJson(chartData);
              console.log("BumpChart datadata", chartData.data.length);
              setVisualizationVisualization(data.possibleVisualization);
              setIsLoading(false);
            } else {
              setPageError("Data not found");
              setIsLoading(false);
            }
          });
          break;
      }
    }
    // }
  }, [selectedModuleType, isAxisChanged]);
  const handleTabChange = (event: React.SyntheticEvent, newValue: number) => {
    setValue(newValue);
    setIsYaxis(!isYaxis);
    setActiveTab(newValue);
  };
  const getSelectedChartFromPossibleVisualization = (name: string) => {
    return visualizationTypes.find((obj: any) => obj.chartType === name);
  };
  const handleChartType = (name: string) => {
    let selectedChartAxisValue =
      getSelectedChartFromPossibleVisualization(name);
    console.log("selectedChartAxisValue data", selectedChartAxisValue);
    setXValue(selectedChartAxisValue?.chartParameter[0]?.parameterValue[0]);
    //parseInt(visualizationVisualizationState?.chartParameter.split(",")[0])
    setYValue(selectedChartAxisValue?.chartParameter[1]?.parameterValue[0]);
    //parseInt(visualizationVisualizationState?.chartParameter.split(",")[1])
    setSelectedModuleType(name);
    setIndexFlag(1);
    setDefsColor("green");
  };
  const getCharts = () => {
    switch (selectedModuleType) {
      case "BarChart":
        return (
          <BarChart
            dbsource={selecteddb}
            data={visualizationChartJson}
            pageError={pageError}
            bardata={visualizationChartJson.data}
          />
        );
      case "LineChart":
        return (
          <LineChart
            dbsource={selecteddb}
            data={visualizationChartJson}
            pageError={pageError}
            bardata={visualizationChartJson.data}
          />
        );
      case "TableChart":
        return (
          <TableChart
            dbsource={selecteddb}
            data={visualizationChartJson}
            pageError={pageError}
            bardata={visualizationChartJson.data}
          />
        );
      case "RadialBarChart":
        return (
          <RadialChart
            dbsource={selecteddb}
            data={visualizationChartJson}
            pageError={pageError}
            bardata={visualizationChartJson.data}
          />
        );
      case "CardChart":
        return (
          <CardChart
            dbsource={selecteddb}
            data={visualizationChartJson}
            pageError={pageError}
            bardata={visualizationChartJson.data}
          />
        );
      case "PieChart":
        return (
          <PieChart
            dbsource={selecteddb}
            data={visualizationChartJson}
            pageError={pageError}
            bardata={visualizationChartJson.data}
          />
        );

      case "ScatterPlot":
        return (
          <ScatterPlot
            dbsource={selecteddb}
            data={visualizationChartJson}
            pageError={pageError}
            bardata={visualizationChartJson.data}
          />
        );
      case "AreaBumpChart":
        return (
          <AreaBump
            dbsource={selecteddb}
            data={visualizationChartJson}
            pageError={pageError}
            bardata={visualizationChartJson.data}
          />
        );

      case "RadarChart":
        return (
          <RadarrChart
            dbsource={selecteddb}
            data={visualizationChartJson}
            pageError={pageError}
            bardata={visualizationChartJson.data}
          />
        );
      case "AreaChart":
        return (
          <AreaChart
            dbsource={selecteddb}
            data={visualizationChartJson}
            pageError={pageError}
            bardata={visualizationChartJson.data}
          />
        );

      case "BumpChart":
        return (
          <Bump
            dbsource={selecteddb}
            data={visualizationChartJson}
            pageError={pageError}
            bardata={visualizationChartJson.data}
          />
        );

      default:
        return <></>;
    }
  };

  const handleOpenDialog = () => {
    setOpenDialog(true);
    setTimeout(() => {
      setOpenDialog(false);
    }, 4000); // Closes the dialog after 2 seconds
  };

  const handleAxisChanged = (x: any, y: any) => {
    if (x !== y) {
      if (x !== "none") {
        setXValue(x);
      }
      if (y !== "none") {
        setYValue(y);
      }
      setIsLoading(true);
      setIsAxisChanged(!isAxisChanged);
      setIndexFlag(2);
      // getVisualizationState().then((states: any) => {
      //   setVisualizationVisualizationState(states);
      //   console.log(
      //     "STATE IS CHANGED IN CHART",
      //     states,
      //     visualizationVisualization,
      //     visualizationTypes
      //   );
      //   setIsAxisChanged(!isAxisChanged);
      // });
    } else {
      // alert("X and Y axis should not be same.");
      handleOpenDialog();
    }
  };

  // const dialogStyle = {
  //   width: "500px",
  //   height: "99px",
  //   borderRadius: "10px",
  //   border: "1px solid #F69E49",
  //   backgroundColor: "#F4DAB2",
  //   overflow: "hidden",
  //   display: "flex",
  // };
  const dialogStyle: React.CSSProperties = {
    width: "500px",
    height: "99px",
    borderRadius: "10px",
    border: "1px solid #F69E49",
    backgroundColor: "#F4DAB2",
    overflow: "hidden",
    display: "flex",
    position: "relative", // Add this line
    top: "-30%", // Adjust this value as needed
    left: "-8%",
  };

  const dialogTitleStyle: React.CSSProperties = {
    display: "flex",
    flexDirection: "column",
    alignItems: "flex-start",
    position: "absolute",
    top: "50%",
    transform: "translateY(-50%)",
    zIndex: 999,
    marginLeft: "90px",
    fontSize: "24px",
    fontFamily: "Nunito Sans, sans-serif",
  };

  const alertIconStyle = {
    // alignSelf: 'center' as 'center',
    marginLeft: "-35%",
    marginBottom: "8px",
  };

  const contentStyle = {
    flex: 1,
    padding: "8px",
  };

  const handleClose = () => {
    setIsLoading(false);
  };
  if (visualizationTypes.length === 0 && xValue === "") {
    return (
      <div
        style={{
          backgroundColor: Colors?.background.backgroundColorTheme3,
          display: "flex",
          flexDirection: "row",
        }}
      >
        <div
          key="TAB101"
          style={{
            //backgroundColor: "yellow",
            height: "87vh",
            width: "100%",
            boxShadow: "0 2px 8px rgba(0, 0, 0, 0.1)",
            //borderRadius: "8px",
            //padding: "16px",
            backgroundColor: "#fff",
            transition: "box-shadow 0.3s ease-in-out",
            borderRadius: "0px 10px 10px 0px",
            overflow: "scroll",
            top: 0,
          }}
        >
          <Backdrop
            className={classes.circularPlanContainer}
            open={isLoading}
            onClick={handleClose}
          >
            <CircularProgress className={classes.circularPlanView} />
          </Backdrop>
        </div>
      </div>
    );
  }
  return (
    <div
      style={{
        backgroundColor: Colors?.background.backgroundColorTheme3,
        display: "flex",
        flexDirection: "row",
      }}
    >
      <Backdrop
        className={classes.circularPlanContainer}
        open={isLoading}
        onClick={handleClose}
      >
        <CircularProgress className={classes.circularPlanView} />
      </Backdrop>
      {!isLoading && getCharts()}
      {isLoading && (
        <div
          key="CHART101"
          style={{
            backgroundColor: Colors?.background.backgroundColorTheme3,
            height: "87vh",
            width: "75%",
            overflowX: "auto",
            boxShadow: "0px 0px 4px 0px #00000040",
            borderRadius: "10px 0px 0px 10px",
            overflow: "scroll",
            top: 0,
          }}
        >
          <Backdrop
            className={classes.circularPlanContainer}
            open={isLoading}
            onClick={handleClose}
          >
            <CircularProgress className={classes.circularPlanView} />
          </Backdrop>
        </div>
      )}
      <div
        key="TAB101"
        style={{
          //backgroundColor: "yellow",
          height: "87vh",
          width: "25%",
          boxShadow: "0 2px 8px rgba(0, 0, 0, 0.1)",
          //borderRadius: "8px",
          //padding: "16px",
          backgroundColor: "#fff",
          transition: "box-shadow 0.3s ease-in-out",
          borderRadius: "0px 10px 10px 0px",
          overflow: "hidden",
          top: 0,
        }}
      >
        <div
          style={{
            // width: "100%", // Set the Tabs component width to 100%
            // overflowX: "auto", // Enable horizontal scrolling if tabs exceed the container's width
            backgroundColor: "transparent",
            // display: "flex",
            width: "100%",
            //minWidth: "400px",
            // display: "flex",
            //flex: 1,
            //justifyContent: "",
          }}
        >
          <Tabs
            value={value}
            indicatorColor="primary"
            textColor="primary"
            onChange={handleTabChange}
            aria-label="disabled tabs example"
            classes={{ root: classes.root, indicator: classes.indicator }}
          >
            <Tab
              className={classes.tabLabel}
              style={{
                width: "50%",
                color: activeTab === 0 ? "#000" : "#DADADA",
                backgroundColor:
                  activeTab === 0
                    ? "transparent"
                    : Colors?.background.backgroundColorTheme3,
                padding: "8px 16px",
              }}
              {...a11yProps(0)}
              label="Visaulization"
            />
           {showRecurrence && (
          <Tab
            className={classes.tabLabel}
            style={{
              width: "50%",
              color: activeTab === 1 ? "#000" : "#DADADA",
              backgroundColor:
                activeTab === 1
                  ? "transparent"
                  : Colors?.background.backgroundColorTheme3,
              padding: "8px 16px",
            }}
            {...a11yProps(1)}
            label="Recurrence"
          />
        )}
          </Tabs>
        </div>
        <CustomTabPanel
          handleChartType={handleChartType}
          handleAxisChanged={handleAxisChanged}
          classes={classes}
          isY={isYaxis}
          value={value}
          visualizationTypeList={visualizationTypes}
          selectedVisulaizationType={selectedModuleType}
          visualizationChartDetails={visualizationVisualization}
          visualizationChartState={visualizationVisualizationState}
          indexAxisFlag={indexFlag}
          xAxis={
            indexFlag === 1
              ? visualizationVisualization?.chartParameter[0]?.parameterValue[0]
              : xValue
          }
          yAxis={
            indexFlag === 1
              ? visualizationVisualization?.chartParameter[1]?.parameterValue[0]
              : yValue
          }
          index={0}
        ></CustomTabPanel>

        <CustomTabPanel1
          handleChartType={handleChartType}
          handleAxisChanged={handleAxisChanged}
          classes={classes}
          isY={isYaxis}
          value={value}
          visualizationTypeList={visualizationTypes}
          selectedVisulaizationType={selectedModuleType}
          visualizationChartDetails={visualizationVisualization}
          visualizationChartState={visualizationVisualizationState}
          index={1}
          indexAxisFlag={0}
          xAxis={""}
          yAxis={""}
        ></CustomTabPanel1>
      </div>
      <Dialog
        open={openDialog}
        onClose={() => setOpenDialog(false)} // Optional: allows the dialog to be closed manually
        PaperProps={{
          style: dialogStyle,
          display: "flex", // Make the dialog content flex container
        }}
        BackdropProps={{
          style: {
            backgroundColor: "transparent", // This will make the backdrop transparent
          },
          invisible: true, // This will make the backdrop completely invisible
        }}
      >
        <img
          src={Images.alert}
          alt="Alert"
          style={{
            width: "65px",
            height: "65px",
            marginLeft: "5%",
            marginTop: "3%",
          }}
        />
        <DialogTitle style={dialogTitleStyle}>
          <div>Alert</div>
          <div
            style={{
              fontSize: "16px",
              marginTop: "4px",
              fontFamily: "Nunito Sans, sans-serif",
            }}
          >
            X and Y axis could not be the same
          </div>
        </DialogTitle>
      </Dialog>
    </div>
  );
});

export default AnalyzeModules;
