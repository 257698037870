import {
  React,
  useState,
  useEffect,

  Backdrop,
  CircularProgress,
  useNavigate,
  useLocation,
} from "../../../../app.module";
import { ResponsiveBar } from "@nivo/bar";
import { ResponsivePie } from "@nivo/pie";
import { useStyle } from "./BarChartStyle";
import {
  getVisualizationData,
  getVisualizationType,
  getPossibalVisualization,
  getBarChartVisualizationData,
  getVisualizationState,
} from "./ChartsService";
import { Colors, Images } from "../../../constants";
import {
  getUserDetailsFromCookies,
  getQueryIdFromCookies,
} from "../../../services";
const BarChart = React.memo((props: any) => {
  const [barChartJson, setBarChartJson] = useState<any>([]);
  const [isLoading, setIsLoading] = useState(true);
  const [isScrolling, setIsScrolling] = useState(false);
  const [activeTab, setActiveTab] = React.useState(0);
  const { classes } = useStyle({ isYaxis: false, isXaxis: true, activeTab });
  const [chartWidth, setChartWidth] = useState('100%');

  useEffect(() => {
    let bdata = props.data;
    setBarChartJson(bdata);
    setIsLoading(false);
    console.log("BAR CHART DATA PROPS", bdata);
  }, [barChartJson]);

  useEffect(() => {
    if (barChartJson?.data) {
      const calculatedWidth = `${100 * barChartJson.data.length}px`;
      setChartWidth(calculatedWidth);
    }
  }, [barChartJson?.data]);
  if (isLoading && props?.pageError === "") {
    return (
      <div
        style={{
          backgroundColor: Colors?.background.backgroundColorTheme3,
          display: "flex",
          flexDirection: "row",
        }}
      >
        <Backdrop
          className={classes.circularPlanContainer}
          open={isLoading}
        >
          <CircularProgress className={classes.circularPlanView} />
        </Backdrop>
      </div>
    );
  }
  const theme = {
    legends: {
          text: {
            fontSize: 15, 
          },
        },
    axis: {
      ticks: {
        text: {
          fontSize: 10, 
        }
      }
    },
  };
  
  let scrollTimeout: NodeJS.Timeout | undefined;
  const handleMouseMove = () => {
    setIsScrolling(true);
    if (scrollTimeout) clearTimeout(scrollTimeout);
  };
  
  const handleMouseLeave = () => {
    if (scrollTimeout) clearTimeout(scrollTimeout);
    scrollTimeout = setTimeout(() => setIsScrolling(false), 1000) as NodeJS.Timeout;
  };
  const getMaxLabelLength = (data: any[], indexBy: string): number => {
    return data.reduce((max, item) => {
      const labelLength = String(item[indexBy]).length;
      return labelLength > max ? labelLength : max;
    }, 0);
  };
  
  const getDynamicTickPadding = (data: any[], indexBy: string): number => {
    const maxLabelLength = getMaxLabelLength(data, indexBy);
    if (maxLabelLength < 5) {
      return 10;
    } else if (maxLabelLength >= 5 && maxLabelLength <= 10) {
      return 5; 
    } else {
      return 2; 
    }
  };
  const getDynamicPadding = (dataLength: number): number => {
    if (dataLength <= 10) {
      return 0.1; 
    } else if (dataLength > 10 && dataLength <= 20) {
      return 0.4;
    } else {
      return 0.5;
    }
  };
  const isStringIndex = () => {
    const data = barChartJson?.data;
    if (data && data.length > 0 && barChartJson?.indexBy) {
      return typeof data[0][barChartJson.indexBy] === 'string';
    }
    return false;
  };

  return (

      <div
        key="CHART101"
        style={{
          backgroundColor: Colors?.background.backgroundColorTheme3,
          height: "87vh",
          width: "75%",
          overflowX: isScrolling ? "auto" : "hidden",
          overflowY: isScrolling ? "auto" : "hidden",
          boxShadow: "0px 0px 4px 0px #00000040",
          borderRadius: "10px 0px 0px 10px",
          top: 0,
        }}
        onMouseMove={handleMouseMove}
        onMouseLeave={handleMouseLeave}
      >
         <div style={{display:"flex",justifyItems:"right",marginLeft:"10px",marginTop:"10px"}}>
        Datasource name :
        <label>
          {props?.dbsource}
        </label> 
      </div>
        {props?.pageError !== "" ? (
          <div
            key="TAB101"
            style={{
              display: "flex",
              height: "87vh",
              width: "90%",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <img
              style={{
                height: "auto",
                width: "300px",
              }}
              src={Images.no_data_img}
            />
          </div>
        ) : (
          <div
            key="CHART102"
            style={{
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'stretch',
              height: '75vh',
              width: barChartJson?.data?.length > 10 ? `${100 * barChartJson?.data?.length}px` : '100%',
            }}
          >
            <ResponsiveBar
              data={barChartJson?.data ? barChartJson?.data : []}
              keys={barChartJson?.keys ? barChartJson.keys : []}
              indexBy={barChartJson?.indexBy}
              margin={{ top: 50, right: 130, bottom: 160, left: 90 }} 
              padding={getDynamicPadding(barChartJson?.data?.length ?? 0)}
              layout="vertical"
              enableGridY={true}
              enableGridX={false}
              valueScale={{ type: "linear" }}
              indexScale={{ type: "band", round: true }}
              colors={{ scheme: "nivo" }}
              theme={theme}
              axisTop={null}
              axisRight={null}
              axisBottom={{
                tickSize: 5,
                tickPadding: getDynamicTickPadding(barChartJson?.data ?? [], barChartJson?.indexBy ?? ''),
                tickRotation: isStringIndex() ? -45 : 0,
                legend: barChartJson?.axis_bottom_legend,
                legendPosition: "middle",
                legendOffset: 140,
              }}
              axisLeft={{
                tickSize: 1,
                tickPadding: getDynamicTickPadding(barChartJson?.data ?? [], barChartJson?.indexBy ?? ''),
                tickRotation: 0,
                legend: barChartJson?.axis_left_legend,
                legendPosition: 'middle',
                legendOffset: -70,
              }}
              labelSkipWidth={12}
              labelSkipHeight={12}
              legends={[
                {
                  dataFrom: "keys",
                  anchor: "top-left",
                  direction: "row",
                  justify: false,
                  translateX: -40,
                  translateY: -100,
                  itemsSpacing: 2,
                  itemWidth: 100,
                  itemHeight: 70,
                  itemDirection: "left-to-right",
                  itemOpacity: 0.85,
                  symbolSize: 20,
                  effects: [
                    {
                      on: "hover",
                      style: {
                        itemOpacity: 1,
                      },
                    },
                  ],
                },
              ]}
              role="application"
              ariaLabel="Nivo bar chart demo"
              barAriaLabel={(e) =>
                e.id + ": " + e.formattedValue + " in country: " + e.indexValue
              }
            />
          </div>
        )}
      </div>
  );
  
});

export default BarChart;
