import {
  AppBar,
  Toolbar,
  IconButton,
  ChevronLeft,
  Avatar,
  Box,
  Search,
} from "../../app.module";
import { Images, Colors } from "../constants";
import { useStyle, StyledInputBase } from "./Drawer/CustomDrawerStyle";
import { CustomDrawer } from "../components";
const Header = () => {
  const { classes } = useStyle();
  return (
    <>
      <AppBar
        position="sticky"
        style={{
          boxShadow: "none",
          backgroundColor: Colors?.background.backgroundColorTheme3,
        }}
      >
        <Toolbar>
          
          <div className={classes.appBarSearch}>
            <div className={classes.searchIconWraper}>
              <Search
                style={{
                  color: "#000",
                }}
              />
            </div>
            <StyledInputBase
              placeholder="Search…"
              inputProps={{ "aria-label": "search" }}
            />
          </div>
        </Toolbar>
      </AppBar>
      <CustomDrawer />
    </>
  );
};

export default Header;
