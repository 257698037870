import {
  Box,
  Typography,
  Grid,
  Card,
  CardActions,
  CardContent,
  IconButton,
  clsx,
  InputLabel,
  useState,
  Avatar,
  useEffect,
  useMsal,
  Modal,
  useLocation,
  parse,
  ExpandLess,
  ExpandMore,
  Collapse,
  Checkbox,
  useNavigate,
  Cookies,
  React,
  Toolbar,
  styled,
  Drawer,
  Button,
  useContext,
  AppStatesContainer,
  CircularProgress,
  Dialog,
  DialogContent,
  useRef,
} from "../../../app.module";
import DialogTitle from "@mui/material/DialogTitle";
import {
  useStyle,
  CssOrderTextField,
  StyledOrderButton,
} from "./OrderSummaryStyle";
import { applyCoupon, purschasePlan } from "./OrderSummaryService";
import { getUserDetailsFromCookies } from "../../services";
// import OrderSummaryCart from './OrderSummaryCart'; // Adjust the path as necessary

// import OrderSummaryCart from './OrderSummaryCart'; // Adjust the path as necessary

import { Colors, Images } from "../../constants";
import {
  Main,
  DrawerHeader,
  WingsBIAppBar,
  StyledInputBase,
} from "../../shared_component/Drawer/CustomDrawerStyle";

import {
  StyledButton,
  CssPlanTextField,
  CustomSwitch,
} from "../../components/plan/PlanStyle";

import Razorpay from "razorpay";

import {
  getPlanAddons,
  getSubscriptionPlan,
  subscribeToPlan,
} from "../../components/plan/PlanService";

import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import ExpandLessIcon from "@mui/icons-material/ExpandLess";

import OrderSummaryCart from "./OrderSummaryCart";

import { Theme, CSSObject, AppBarProps, Menu } from "@mui/material";
import { InteractionRequiredAuthError } from "@azure/msal-browser";

interface Addon {
  addonName: string;
  addonprice: number;
  addonDescription?: string;
}
// src/global.d.ts
declare global {
  interface Window {
    Razorpay: any; // You can replace `any` with a more specific type if you have the type definitions
  }
}
interface PurchasePayload {
  companyId: any; // Consider specifying more precise types instead of 'any'
  planId: any;
  orderId: number;
  startDate: string;
  endDate: string;
  nextPaymentDate: string;
  createdBy: any;
  createdDate: string;
  name: any;
  description: any;
  duration: any;
  transactionId: string | undefined;
  addOns: any[]; // Specify the structure of addOn items if possible
  totalPrice: any;
  couponCode?: string; // Optional property
}


interface Quantities {
  [key: number]: number;
}

const OrderSummary = (props: any) => {
  const { classes } = useStyle();
  const { instance, accounts } = useMsal();
  const [couponId, setCouponId] = useState(0);
  const [couponCode, setCouponCode] = useState("");
  const [couponCodeError, setCouponCodeError] = useState(false);
  const [expanded, setExpanded] = useState(0);
  const [isChecked, setIsChecked] = useState(false);
  const location = useLocation();
  const { planDetails } = location?.state;
  const orderSummaryDetails = location?.state?.data;
  const [orderDetails, setOrderDetails] = useState(orderSummaryDetails);
  const [currentIndex, setCurrentIndex] = useState(2);
  const navigate = useNavigate();
  const [open, setOpen] = React.useState(false);
  const [isPurchased, setIsPurchased] = useState(false);
  const [couponDiscount, setCouponDiscount] = useState<number | null>(null);
  const [isErrorDialogOpen, setIsErrorDialogOpen] = useState(false);
  const [isAddonsVisible, setIsAddonsVisible] = useState(false);
  const scrollRef = useRef<HTMLDivElement>(null);
  const [displayValues, setDisplayValues] = useState<{ [key: number]: string }>(
    {}
  );

  const [productPlanAddons, setProductPlanAddons] = useState<Addon[]>([]);
  const [monthly, setMonthly] = useState(true);
  const [addonQuantities, setAddonQuantities] = useState<{
    [key: number]: number;
  }>({});
  const [isLoading, setIsLoading] = useState(true);
  const [productFeatures, setProductFeatures] = useState([]);
  const [productFeaturesCnt, setProductFeaturesCnt] = useState<number[]>([]);
  const [productPlan, setProductPlan] = useState([]);
  const [formattedAddonQuantities, setFormattedAddonQuantities] = useState([
    { Id: 1, Count: 2 },
    { Id: 4, Count: 3 },
  ]);

  console.log("Orderdetails Siddesh",orderDetails)
  console.log("Order summary siddesh", orderSummaryDetails);

  const [errorMessage, setErrorMessage] = useState("");
  const handleCouponCode = (event: any) => {
    setCouponCode(event.target.value);
    if (event.target.value === "") {
      setCouponCodeError(false);
    }
  };

  useEffect(() => {
    if (scrollRef.current) {
      const halfHeight = scrollRef.current.scrollHeight / 2;
      scrollRef.current.scrollTop = halfHeight;
      scrollRef.current.style.overflowY = isAddonsVisible ? "hidden" : "hidden";
    }
  }, [isAddonsVisible]);

  const openRazorpay = () => {
    if (orderDetails?.plans?.id === 4) {
      // For free plan, directly call handleProceedToPay without Razorpay
      handleProceedToPay(undefined);
      return;
    }
    if (!window.Razorpay) {
      console.error("Razorpay SDK failed to load");
      return;
    }

    const options = {
      key: "rzp_test_YEN3uIPyMxp7DG",
      amount: orderDetails?.totalAmountPayable * 100,
      currency: "INR",
      name: "WingsBI",
      description: "Transaction Description",
      image: Images.wingsbi_log,
      handler: function (response: { razorpay_payment_id: string }) {
        console.log("Payment Successful: " + response.razorpay_payment_id);
        handleProceedToPay(response.razorpay_payment_id);
      },
      prefill: {
        name: "",
        email: "",
        contact: "",
      },
      notes: {
        address: "Razorpay Corporate Office",
      },
      theme: {
        color: "#1157CD",
      },
    };

    var rzp = new window.Razorpay(options);
    rzp.open();
    rzp.on(
      "payment.failed",
      function (response: { error: { description: string } }) {
        alert("Payment Failed: " + response.error.description);
      }
    );
  };

  console.log("Siddesh Plan details", planDetails);

  const [modalOpen, setModalOpen] = useState(false);
  const contentStyle = {
    flex: 1,
    padding: "8px",
  };
  const dialogStyle = {
    width: "622px",
    height: "99px",
    borderRadius: "10px",
    border: "1px solid #F69E49",
    backgroundColor: "#F4DAB2",
    overflow: "hidden",
    display: "flex",
  };
  const imageStyle = {
    marginLeft: "16px",
    marginTop: "20px",
  };
  const [error, setError] = useState({
    error: false,
    errormessage:
      "Please tick on the Tearms and Conditions button to move on next page",
  });
  const handleExpandClick = () => {
    expanded === 0 ? setExpanded(1) : setExpanded(0);
  };

  const handleToggleAddonsVisibility = () => {
    setIsAddonsVisible((prev) => !prev);
  };

  const handleExpandClick1 = (index: number) => {
    expanded === index + 1 ? setExpanded(0) : setExpanded(index + 1);
  };

  useEffect(() => {
    getSubscriptionPlan().then((resp) => {
      let plan_result = resp?.data?.plan;
      console.log("plan_name:", plan_result);
      let f_arr = [];
      for (let feature = 0; feature < plan_result.length; feature++) {
        f_arr.push(feature + 1);
      }
      setProductFeaturesCnt([...f_arr]);
      //console.log("DATAFD array", f_arr);
      setProductPlan(plan_result);
      setProductFeatures(resp.data.features);
      getPlanAddons().then((resp) => {
        console.log("addadd", resp);
        setProductPlanAddons(resp);
        setIsLoading(false);
      });
      const orderSummaryElement = document.querySelector(
        ".orderSummaryContainer"
      );
      if (orderSummaryElement) {
        orderSummaryElement.scrollTop = 150; // Adjust this value as needed
      }
    });
  }, []);

  useEffect(() => {
    const orderSummaryElement = document.querySelector(
      ".orderSummaryContainer"
    );
    if (orderSummaryElement) {
      // Set the initial scroll position, adjust '150' to the desired scroll position
      orderSummaryElement.scrollTop = 150;
    }
  }, []);

  const handleCheckboxChange = (event: {
    target: { checked: boolean | ((prevState: boolean) => boolean) };
  }) => {
    setIsChecked(event.target.checked);
  };

  useEffect(() => {
    if (isErrorDialogOpen) {
      const timeoutId = setTimeout(() => {
        setIsErrorDialogOpen(false);
      }, 7000);
      return () => {
        clearTimeout(timeoutId);
      };
    }
  }, [isErrorDialogOpen]);

  useEffect(() => {
    const formattedAddons = Object.entries(addonQuantities).map(
      ([key, value]) => ({
        Id: parseInt(key, 10),
        Count: value,
      })
    );
    setFormattedAddonQuantities(formattedAddons);
  }, [addonQuantities]);

  useEffect(() => {
    console.log("Addons Siddesh Updated", formattedAddonQuantities);
  });

  const formatAddonsForSubmission = (quantities: Quantities) => {
    return Object.entries(quantities)
      .filter(([_, count]) => count > 0)
      .map(([id, count]) => ({
        Id: parseInt(id, 10),
        Count: count as number, // TypeScript now understands `count` is a number
      }));
  };

  const updateAddonQuantity = (addonId: number, change: number) => {
    console.log("addonId", addonId);
    setAddonQuantities((prev) => {
      let currentQuantity = prev[addonId] || 0;
      let newCount = currentQuantity + change;

      if (newCount < 0) newCount = 0; // Ensure it doesn't go negative
      if (newCount > 100) newCount = 100; // Cap the maximum count

      const newQuantities = { ...prev, [addonId]: newCount };
      if (newCount === 0) delete newQuantities[addonId]; // Remove entry if count is 0

      // Update right away
      const formattedAddons = formatAddonsForSubmission(newQuantities);
      setFormattedAddonQuantities(formattedAddons); // Set formatted addons immediately
      prepareAndSendPayload(
        { ...planDetails, AddonIds: formattedAddons },
        setOrderDetails
      ); // Pass setOrderDetails here

      return newQuantities;
    });
  };

  const applyUserCoupon = () => {
    let couponPayload = {
      planId: orderDetails?.plans?.id,
      userId: getUserDetailsFromCookies().id,
      addOnIds: formattedAddonQuantities, // This should now correctly reference the updated formatted quantities
      couponcode: couponCode,
      planType: "Monthly",
    };

    applyCoupon(couponPayload).then((response) => {
      if (response?.couponCodeDescription === "Invalid Coupon Code") {
        setCouponCodeError(true);
        setCouponDiscount(null);
      } else {
        setCouponId(response.id);
        setCouponCodeError(false);
        setCouponDiscount(response.discountAmount);
      }
      setOrderDetails({
        ...orderDetails,
        ...response,
      });
    });
  };

  const generateArrayFromQuantities = () => {
    const result = [];
    for (const [id, count] of Object.entries(addonQuantities)) {
      for (let i = 0; i < count; i++) {
        result.push(Number(id));
      }
    }
    return result;
  };

  const currentAddonArray = generateArrayFromQuantities();
  console.log("Siddesh id are", currentAddonArray);

  const prepareAndSendPayload = async (payload: any, setOrderDetails: any) => {
    try {
      const response = await subscribeToPlan(payload);
      console.log("Response from API:", response);
      setOrderDetails(response); // Update the state with the response
    } catch (error) {
      console.error("Error submitting plan with addons:", error);
    }
  };

  console.log("orderdetails siddesh", orderDetails);

  const handleQuantityChange = (event: any) => {
    const addonId = parseInt(event.target.dataset.addonId, 10); // Extract addonId from input dataset
    const newCount = parseInt(event.target.value, 10); // Parse the new count as an integer

    if (!isNaN(newCount) && newCount >= 1 && newCount <= 100) {
      // Validate the new count
      setAddonQuantities((prev) => {
        const updatedQuantities = { ...prev, [addonId]: newCount }; // Update the quantity for the specific addon
        const formattedAddons = formatAddonsForSubmission(updatedQuantities); // Format the updated quantities for submission
        // prepareAndSendPayload({ ...planDetails, AddonIds: formattedAddons }); // Prepare and send the updated payload
        prepareAndSendPayload(
          { ...planDetails, AddonIds: formattedAddons },
          setOrderDetails
        ); // Pass setOrderDetails here
        return updatedQuantities; // Return the updated state
      });
    }
  };

  console.log("Orderdetails siddesh", orderDetails);

  const handleSignOut = () => {
    const cookieNames = Object.keys(Cookies.get());
    cookieNames.forEach((cookieName) => {
      console.log("Cookies resets", cookieNames);
      Cookies.remove(cookieName);
    });
    instance.logoutRedirect();
    Cookies.remove("user_details");
    navigate("/");
  };
  const removeCoupon = () => {
    setOrderDetails(location?.state?.data);
    setCouponCode("");
  };
  const handleClose = () => {
    navigate("/plan");
  };

  // const handleProceedToPay = (transactionId: string | undefined) => {
  //   if (isChecked) {
  //     setIsErrorDialogOpen(true);
  //     setErrorMessage("Please login again to continue");
  //     let purchasePayload = {
  //       companyId: getUserDetailsFromCookies().companyId,
  //       planId: orderDetails?.plans?.id,
  //       orderId: 1,  // Here we use the transaction ID
  //       startDate: "2023-07-29T04:10:52.449Z",
  //       endDate: "2023-07-29T04:10:52.449Z",
  //       nextPaymentDate: "2023-07-29T04:10:52.449Z",
  //       createdBy: getUserDetailsFromCookies().id,
  //       createdDate: "2023-07-29T04:10:52.449Z",
  //       name: getUserDetailsFromCookies().firstName,
  //       description: orderDetails.plans.description,
  //       couponCode: couponCode,
  //       duration: orderDetails.plans.duration,
  //       transactionId:transactionId,
  //       addOns:formattedAddonQuantities,
  //       totalPrice:orderDetails?.totalAmountPayable,
  //     };
  //     console.log("orderSummaryDetails ", purchasePayload);
  //     setIsPurchased(true);
  //     purschasePlan(purchasePayload).then((resp: any) => {
  //       console.log("order Response", resp);
  //       let user_details = getUserDetailsFromCookies();
  //       user_details.identifier = resp.identifier;
  //       user_details.subscriptionId = resp.id;
  //       Cookies.set("user_details", JSON.stringify(user_details));
  //       setIsPurchased(false);
  //       handleSignOut();
  //     });
  //   } else {
  //     setIsPurchased(false);
  //     setError({
  //       ...error,
  //       error: false,
  //       errormessage: "",
  //     });
  //     alert("Please agree to terms and condition");
  //   }
  // };
  // const handleProceedToPay = (transactionId: string | undefined) => {
  //   if (orderDetails?.plans?.id === 4) {
  //     handleSignOut();
  //   }
  //   if (isChecked) {
  //     setIsErrorDialogOpen(true);
  //     setErrorMessage("Please login again to continue");

  //     // Define the payload with the interface
  //     let purchasePayload: PurchasePayload = {
  //       companyId: getUserDetailsFromCookies().companyId,
  //       planId: orderDetails?.plans?.id,
  //       orderId: 1, // Assuming you use the transaction ID here for some reason
  //       startDate: "2023-07-29T04:10:52.449Z",
  //       endDate: "2023-07-29T04:10:52.449Z",
  //       nextPaymentDate: "2023-07-29T04:10:52.449Z",
  //       createdBy: getUserDetailsFromCookies().id,
  //       createdDate: "2023-07-29T04:10:52.449Z",
  //       name: getUserDetailsFromCookies().firstName,
  //       description: orderDetails.plans.description,
  //       duration: orderDetails.plans.duration,
  //       transactionId: transactionId,
  //       addOns: formattedAddonQuantities,
  //       totalPrice: orderDetails?.totalAmountPayable,
  //     };

  //     // Conditionally add couponCode if it has a value
  //     if (couponCode && couponCode.trim() !== "") {
  //       purchasePayload.couponCode = couponCode;
  //     }

  //     console.log("orderSummaryDetails ", purchasePayload);
  //     setIsPurchased(true);

  //     purschasePlan(purchasePayload).then((resp: any) => {
  //       console.log("order Response", resp);
  //       let user_details = getUserDetailsFromCookies();
  //       user_details.identifier = resp.identifier;
  //       user_details.subscriptionId = resp.id;
  //       Cookies.set("user_details", JSON.stringify(user_details));
  //       setIsPurchased(false);
  //       handleSignOut();
  //     });
  //   } else {
  //     setIsPurchased(false);
  //     setError({
  //       ...error,
  //       error: false,
  //       errormessage: "",
  //     });
  //     alert("Please agree to terms and condition");
  //   }
  // };

  const handleProceedToPay = (transactionId: string | undefined) => {
    if (!isChecked) {
      alert("Please agree to terms and conditions");
      return;
    }
  
    setIsPurchased(true);
  
    let purchasePayload: PurchasePayload = {
      companyId: getUserDetailsFromCookies().companyId,
      planId: orderDetails?.plans?.id,
      orderId: 1,
      startDate: "2023-07-29T04:10:52.449Z",
      endDate: "2023-07-29T04:10:52.449Z",
      nextPaymentDate: "2023-07-29T04:10:52.449Z",
      createdBy: getUserDetailsFromCookies().id,
      createdDate: "2023-07-29T04:10:52.449Z",
      name: getUserDetailsFromCookies().firstName,
      description: orderDetails.plans.description,
      duration: orderDetails.plans.duration,
      transactionId: transactionId,
      addOns: formattedAddonQuantities,
      // totalPrice: orderDetails?.totalAmountPayable,
      totalPrice: Math.round(orderDetails?.totalAmountPayable),
    };
  
    if (couponCode && couponCode.trim() !== "") {
      purchasePayload.couponCode = couponCode;
    }
  
    console.log("orderSummaryDetails ", purchasePayload);
  
    purschasePlan(purchasePayload).then((resp: any) => {
      console.log("order Response", resp);
      let user_details = getUserDetailsFromCookies();
      user_details.identifier = resp.identifier;
      user_details.subscriptionId = resp.id;
      Cookies.set("user_details", JSON.stringify(user_details));
      setIsPurchased(false);
      
      if (orderDetails?.plans?.id === 4) {
        handleSignOut();
      } else {
        setIsErrorDialogOpen(true);
        setErrorMessage("Please login again to continue");
        // You might want to add a delay or user action before signing out
        setTimeout(handleSignOut, 3000); // Sign out after 3 seconds, for example
      }
    }).catch(error => {
      console.error("Purchase failed:", error);
      setIsPurchased(false);
      // Handle error (show message to user, etc.)
    });
  };
  
  


  const path = useContext(AppStatesContainer);
  const handleModalClose = () => {
    setModalOpen(false);
  };

  const handleModalOpen = () => {
    setModalOpen(true);
  };
  const SwipableDrawer = styled(Drawer, {
    shouldForwardProp: (prop) => prop !== "open",
  })(({ theme, open }) => ({
    width: drawerWidth,
    flexShrink: 0,
    whiteSpace: "nowrap",
    boxSizing: "border-box",
    ...(open && {
      ...openedMixin(theme),
      "& .MuiDrawer-paper": openedMixin(theme),
    }),
    ...(!open && {
      ...closedMixin(theme),
      "& .MuiDrawer-paper": closedMixin(theme),
    }),
  }));
  const drawerWidth = 160;
  const openedMixin = (theme: Theme): CSSObject => ({
    width: drawerWidth,
    transition: theme.transitions.create("width", {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
    overflow: "auto",
  });
  const closedMixin = (theme: Theme): CSSObject => ({
    transition: theme.transitions.create("width", {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    overflow: "auto",
    width: `calc(${theme.spacing(8.5)} + 1px)`,
    [theme.breakpoints.up("sm")]: {
      width: `calc(${theme.spacing(8.5)} + 1px)`,
    },
  });

  return (
    // <div ref={scrollRef} style={{ overflowY: 'auto', maxHeight: '100vh' }}>
    <>
      <style>
        {`
          .hide-scrollbar {
            overflow-y: auto;
            max-height: 100vh;
            scrollbar-width: none; /* Firefox */
            -ms-overflow-style: none; /* IE and Edge */
          }

          .hide-scrollbar::-webkit-scrollbar {
            display: none; /* Chrome, Safari, Opera */
          }
        `}
      </style>
      <div
        ref={scrollRef}
        className="hide-scrollbar"
        style={{ maxHeight: "100vh", overflowY: "hidden" }}
      >
        <Modal
          keepMounted
          open={modalOpen}
          onClose={handleModalClose}
          closeAfterTransition
          aria-labelledby="keep-mounted-modal-title"
          aria-describedby="keep-mounted-modal-description"
        >
          <Box
            sx={{
              position: "absolute" as "absolute",
              top: "10ch",
              left: "93%",
              transform: "translate(-50%, -50%)",
              width: 120,
              backgroundColor: "#fff",
              borderRadius: "10px",
              border: `1px solid ${Colors?.border.plan.borderColor}`,
              boxShadow: "0px 0px 4px rgba(0, 0, 0, 0.25)",
              p: 0.8,
            }}
          >
            <Button
              onClick={() => {
                handleSignOut();
              }}
              className={classes.userActionTitle}
            >
              Sign Out
            </Button>
          </Box>
        </Modal>
        <Main className={classes.dasboardContainer} open={open}>
          <WingsBIAppBar className={classes.appBarStyle} open={open}>
            <Toolbar className={classes.toolBarStyle}>
              <div
                style={{
                  display: "flex",
                  flex: 1,
                  flexDirection: "column",
                }}
              >
                <div
                  style={{
                    display: "flex",
                    flex: 1,
                    justifyContent: "space-between",
                  }}
                >
                  <div className={classes.userProfileContainer}>
                    <div className={classes.userProfileImg}>
                      <div className={classes.userProfileTxt}>
                        {getUserDetailsFromCookies().firstName[0].toUpperCase()}
                        {getUserDetailsFromCookies().lastName[0].toUpperCase()}
                      </div>
                    </div>

                    <div>
                      <Typography className={classes.userNameStyle}>
                        {getUserDetailsFromCookies().firstName}{" "}
                        {getUserDetailsFromCookies().lastName}
                      </Typography>
                      <Typography className={classes.userRoleTitle}>
                        {getUserDetailsFromCookies().roleName ||
                          "Company Admin"}
                      </Typography>
                    </div>
                    <IconButton sx={{ padding: 0, paddingLeft: "2px" }}>
                      <ExpandMore
                        onClick={() => {
                          handleModalOpen();
                        }}
                        className={classes.userProfileDetailsCollaps}
                      />
                    </IconButton>
                  </div>
                </div>
              </div>
            </Toolbar>
          </WingsBIAppBar>
          <SwipableDrawer
            classes={{
              paper: classes.drawerPaper,
            }}
            variant="permanent"
            open={open}
          >
            <div
              style={{
                backgroundColor: Colors?.background.backgroundColorTheme2,
              }}
              className={classes.logoContainer}
            >
              <div
                style={{
                  width: drawerWidth,
                  borderRadius: currentIndex === 0 ? "0px 0px 20px 0px" : "",
                  backgroundColor: Colors?.background.backgroundColorTheme2,
                }}
              >
                <img
                  className={classes.logoCloseStyle}
                  src={Images.logomark_svg}
                  alt="mySvgImage"
                />
              </div>
            </div>
          </SwipableDrawer>
          <Box className={classes.OrderContainer}>
            <Box className={classes.OrderBoxContainer}>
              <Typography className={classes.OrderTopLabel}></Typography>
            </Box>
            <Box className={classes.OrderSummaryBoxContainer}>
              <Typography className={classes.OrderTopLabel} gutterBottom>
                Order Summary
              </Typography>
              <Grid container spacing={3}>
                <Grid
                  sx={{ minWidth: "400px" }}
                  item
                  xs={8}
                  className={classes.orderSummaryContainer}
                >
                  <Card
                    sx={{
                      borderBottom: 1,
                    }}
                    className={classes.OrderHeaderContainer}
                  >
                    <Typography
                      className={clsx(
                        classes.OrderHeaderTitlel,
                        classes.ItemHeaderWidth
                      )}
                    >
                      {"Subscription"}
                    </Typography>

                    {/* <Typography className={clsx(classes.OrderHeaderTitlel)}>
                    {"Users"}
                  </Typography> */}

                    <Typography
                      className={clsx(
                        classes.OrderHeaderTitlel,
                        classes.PriceHeader
                      )}
                    >
                      {"Price"}
                    </Typography>
                  </Card>

                  <Card className={classes.OrderListIteamContainer}>
                    <CardContent
                      className={classes.OrderSummaryCardContentLeftItems}
                    >
                      <CardContent className={classes.OrderSummaryCardContent}>
                        <div className={classes.PlanTypeColor}></div>
                      </CardContent>
                      <CardContent className={classes.OrderSummaryCardContent}>
                        <Typography
                          className={clsx(
                            classes.OrderSummaryPlanTitle,
                            classes.OrderSummaryPlanNameFont
                          )}
                        >
                          {orderDetails?.plans?.name}
                        </Typography>
                        <Typography
                          className={classes.OrderSummaryPlanDescriptionTitle}
                        ></Typography>
                      </CardContent>
                    </CardContent>
                    <CardContent
                      className={classes.OrderSummaryCardContentRight}
                    >
                      {couponDiscount !== null ? (
                        <Typography className={classes.OrderSummaryPlanTitle}>
                          ₹
                          {orderDetails?.plans?.planType === "Monthly plan"
                            ? orderDetails?.plans?.monthlyPrice 
                            : orderDetails?.plans?.annualPrice}
                          {orderDetails?.plans?.planType !== "Free Plan" && (
                            <sub>{location?.state?.planType}</sub>
                          )}
                        </Typography>
                      ) : (
                        <Typography className={classes.OrderSummaryPlanTitle}>
                          ₹
                          {orderDetails?.plans?.planType === "Monthly plan"
                            ? orderDetails?.plans?.monthlyPrice
                            : orderDetails?.plans?.annualPrice}
                          {orderDetails?.plans?.planType !== "Free" && (
                            <sub>{location?.state?.planType}</sub>
                          )}
                        </Typography>
                      )}
                    </CardContent>
                  </Card>

                  <Grid style={{ marginTop: "0px" }} item xs={12}>
                    <Box
                      className={clsx(
                        classes.OrderAddonsDetails,
                        classes.OrderAddonsDirection
                      )}
                      onClick={() => {
                        handleExpandClick();
                      }}
                    ></Box>
                    <Collapse in={expanded === 1 ? true : false}>
                      {orderSummaryDetails?.addOns?.map(
                        (addons: any, index: number) => (
                          <Card className={classes.OrderListIteamContainer}>
                            <CardContent
                              className={
                                classes.OrderSummaryCardContentLeftItems
                              }
                            >
                              <CardContent
                                className={classes.OrderSummaryCardContent}
                              >
                                <Avatar
                                  style={{
                                    marginRight: "10px",
                                    marginLeft: "50px",
                                    width: "10px",
                                    height: "auto",
                                  }}
                                  src={Images.order_summary_circle}
                                />
                              </CardContent>
                              <CardContent
                                className={classes.OrderSummaryCardContent}
                              >
                                <Typography
                                  className={clsx(
                                    classes.OrderSummaryPlanTitle,
                                    classes.OrderSummaryPlanTitleFont
                                  )}
                                >
                                  {addons?.addonName}
                                </Typography>
                              </CardContent>
                            </CardContent>
                            <CardContent>
                              <Typography
                                className={clsx(
                                  classes.OrderSummaryPlanTitle,
                                  classes.OrderSummaryPlanTitleFont
                                )}
                              ></Typography>
                            </CardContent>
                            <CardContent
                              className={classes.OrderSummaryCardContentRight}
                            >
                              <Typography
                                className={clsx(
                                  classes.OrderSummaryPlanTitle,
                                  classes.OrderSummaryPlanTitleFont
                                )}
                              >
                                ₹ {`${addons?.addonPrice}/year`}
                              </Typography>
                            </CardContent>
                          </Card>
                        )
                      )}
                    </Collapse>
                    <Typography
                      style={{
                        marginTop: "30px",
                      }}
                      className={clsx(
                        classes.FeatureTitlel,
                        classes.PriceHeader,
                        classes.BillingTitle
                      )}
                    >
                      {`Features included in ${orderDetails?.plans?.name}`}
                    </Typography>
                    <Typography
                      style={{
                        width: "80%",
                      }}
                      className={clsx(classes.FeatureDescriptionTitlel)}
                    >
                      {orderDetails?.plans?.description}
                    </Typography>
                  </Grid>

                  <Box
                    className={classes.PlanAddonsBoxContainer}
                    id="addons"
                    style={{ marginLeft: "10px" }}
                  >
                    {/* <Typography className={classes.planTopLabel} gutterBottom>
              Add ons
            </Typography>  */}
                    {orderDetails?.plans?.name !== "Free" && (
                      <>
                        <Box
                          className={classes.planTopLabel}
                          onClick={handleToggleAddonsVisibility}
                          style={{
                            display: "flex",
                            alignItems: "center",
                            cursor: "pointer",
                          }}
                        >
                          <Typography
                            className={classes.planTopLabel}
                            gutterBottom
                          >
                            Add ons
                          </Typography>
                          <IconButton style={{ marginTop: "-15px" }}>
                            {isAddonsVisible ? (
                              <ExpandLessIcon />
                            ) : (
                              <ExpandMoreIcon />
                            )}
                          </IconButton>
                        </Box>

                        {isAddonsVisible && (
                          <Grid flexDirection={"column"} container>
                            {productPlanAddons
                              ?.filter(
                                (addon) =>
                                  addon.addonName === "Add users" ||
                                  addon.addonName === "Add Query"
                              )
                              .map((addons: any, index: number) => (
                                <Card className={classes.AddonsContainer}>
                                  <CardContent style={{}}>
                                    <Box
                                      className={classes.PlanAddonsDetails}
                                      onClick={() => {
                                        handleExpandClick1(index);
                                      }}
                                    >
                                      <Typography
                                        className={classes.planAddonsTitlel}
                                      >
                                        {addons?.addonName}
                                      </Typography>
                                      <Box
                                        className={clsx(
                                          classes.PlanAddonsDetails,
                                          classes.planAddonsDirection
                                        )}
                                        // onClick={() => {
                                        //   handleExpandClick1(index);
                                        // }}
                                      ></Box>
                                    </Box>
                                  </CardContent>

                                  <CardContent
                                    style={{
                                      display: "flex",
                                      justifyContent: "flex-start",
                                      alignItems: "flex-start",
                                      width: "150px",
                                    }}
                                  >
                                    <Typography
                                      className={classes.planAddonsPrice}
                                    >
                                      ₹
                                      {addonQuantities[addons.addonId]
                                        ? addons.addonprice *
                                          addonQuantities[addons.addonId]
                                        : addons.addonprice}
                                      {/* <sub>{monthly ? "/month" : "/year"}</sub> */}
                                    </Typography>
                                  </CardContent>

                                  <CardContent style={{ marginRight: "10px" }}>
                                    <CardActions
                                      className={
                                        classes.CardContentWithoutPadding
                                      }
                                      style={{ justifyContent: "flex-end" }}
                                    >
                                      {addonQuantities[addons.addonId] > 0 ? (
                                        <Button
                                          variant="contained"
                                          size="medium"
                                          style={{
                                            display: "flex",
                                            alignItems: "center",
                                            justifyContent: "space-between",
                                            minWidth: "184px",
                                            backgroundColor: "white",
                                            color: "#000",
                                            padding: "10.2px 16px",
                                            border: "1px solid #007bff",
                                            boxShadow: "none",
                                            borderRadius: "11px",
                                          }}
                                          onClick={(e) => e.stopPropagation()}
                                        >
                                          {/* <span onClick={() => updateAddonQuantity(addons.addonId, -1)} style={{ cursor: 'pointer', userSelect: 'none', fontSize: '16px', padding: '2px 5px' }}>
        &minus;
      </span> */}
                                          <span
                                            onClick={() => {
                                              updateAddonQuantity(
                                                addons.addonId,
                                                -1
                                              );
                                            }}
                                            style={{
                                              cursor: "pointer",
                                              userSelect: "none",
                                              fontSize: "16px",
                                              padding: "2px 5px",
                                            }}
                                          >
                                            &minus;
                                          </span>

                                          {addonQuantities[addons.addonId] >
                                          0 ? (
                                            <input
                                              type="text"
                                              value={
                                                addonQuantities[addons.addonId]
                                              }
                                              onChange={handleQuantityChange}
                                              data-addon-id={addons.addonId}
                                              style={{
                                                width: "50px",
                                                textAlign: "center",
                                                fontSize: "16px",
                                              }}
                                              max="100"
                                            />
                                          ) : (
                                            <Button
                                              onClick={() =>
                                                updateAddonQuantity(
                                                  addons.addonId,
                                                  1
                                                )
                                              }
                                            >
                                              Add
                                            </Button>
                                          )}

                                          <span
                                            onClick={() => {
                                              updateAddonQuantity(
                                                addons.addonId,
                                                1
                                              );
                                            }}
                                            style={{
                                              cursor: "pointer",
                                              userSelect: "none",
                                              fontSize: "16px",
                                              padding: "2px 8px",
                                            }}
                                          >
                                            +
                                          </span>
                                        </Button>
                                      ) : (
                                        <Button
                                          onClick={() => {
                                            updateAddonQuantity(
                                              addons.addonId,
                                              1
                                            );
                                          }}
                                          className={
                                            classes.btnPlanAddonLabelStyle
                                          }
                                          variant="contained"
                                          size="medium"
                                          style={{
                                            backgroundColor: "white",
                                            boxShadow: "none",
                                            minWidth: "120px",
                                          }}
                                        >
                                          Add
                                        </Button>
                                      )}
                                    </CardActions>
                                  </CardContent>
                                </Card>
                              ))}
                          </Grid>
                        )}
                      </>
                    )}
                  </Box>
                  <Grid style={{ marginTop: "5px" }} item xs={10}>
                    <Typography
                      className={clsx(
                        classes.FeatureTitlel,
                        classes.PriceHeader,
                        classes.BillingTitle
                      )}
                    >
                      {" Billing details"}
                    </Typography>
                    <Typography
                      style={{ width: "95%" }}
                      className={clsx(classes.FeatureDescriptionTitlel)}
                    >
                      {
                        "WingsBI Pvt. Ltd. Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.Lorem ipsum dolor sit amet, consectetur adipiscing elit, se. Lorem ipsum dolor sit amet, consectetur adipiscing "
                      }
                    </Typography>
                  </Grid>
                  <Box className={classes.OrderSummaryTermsContainer}>
                    <Box className={classes.OrderSummaryTermsCheckContainer}>
                      <Checkbox
                        checked={isChecked}
                        onChange={() => setIsChecked(!isChecked)}
                        style={{
                          color: Colors?.main,
                          padding: 0,
                        }}
                      />
                      <Typography
                        className={clsx(classes.FeatureDescriptionTitlel)}
                      >
                        {parse(
                          "I have read and agree to website <b>Terms & Conditions</b>"
                        )}
                        <span className={classes.asterix}> *</span>
                      </Typography>
                    </Box>
                  </Box>
                </Grid>

                {/* Write code for ordersummary cart */}
                <Grid
                  sx={{ minWidth: "350px" }}
                  item
                  xs={4}
                  className={classes.cartSummaryContainer}
                >
                  {orderDetails?.plans?.name !== "Trial" && (
                    <>
                      <Grid
                        sx={{
                          borderLeft: 1,
                          borderLeftColor: Colors?.border?.plan.borderColor,
                        }}
                        item
                      >
                        <Card
                          style={{ marginTop: "0px" }}
                          className={classes.OrderListIteamContainer}
                        >
                          <CardContent
                            className={classes.CouponCardContentHeader}
                          >
                            <Typography
                              className={classes.ApplyCouponHeaderTitlel}
                            >
                              Apply Coupon
                            </Typography>
                          </CardContent>
                        </Card>
                      </Grid>

                      <Card
                        sx={{
                          borderLeft: 1,
                        }}
                        className={classes.OrderHeaderRightContainer}
                      >
                        <Box
                          style={{
                            display: "flex",
                            flex: 1,
                            justifyContent: "flex-start",
                            alignItems: "center",
                            flexWrap: "wrap",
                          }}
                        >
                          <div className={classes.OrderTextFiledLabelContainer}>
                            <CssOrderTextField
                              name="users"
                              placeholder=""
                              inputProps={{
                                maxLength: 253,
                                userSelect: "none",
                              }}
                              InputProps={{
                                classes: { input: classes.OrderInput },
                              }}
                              id="custom-css-order-input"
                              onChange={(e) => setCouponCode(e.target.value)}
                              value={couponCode}
                              error={couponCodeError}
                              helperText={
                                couponCodeError ? "Invalid coupon code" : ""
                              }
                            />
                          </div>

                          <CardActions
                            style={{
                              marginBottom: couponCodeError ? "30px" : "",
                            }}
                          >
                            <StyledOrderButton
                              onClick={() => {
                                applyUserCoupon();
                              }}
                              className={classes.btnApplyCoupon}
                              variant="contained"
                              size="medium"
                              disabled={couponCode.trim().length === 0}
                              sx={{
                                opacity:
                                  couponCode.trim().length === 0 ? 0.7 : 1,
                                background:
                                  couponCode.trim().length === 0
                                    ? "#ecedf2" // Gray background for disabled state
                                    : "linear-gradient(90deg, #5EB0FA -8.73%, #1157CD 51.67%, #9C90FA 110.24%)", // Blue background for enabled state
                              }}
                            >
                              Apply
                            </StyledOrderButton>
                          </CardActions>
                        </Box>
                      </Card>
                    </>
                  )}
                  <Card
                    sx={{
                      borderLeft: 1,
                    }}
                    className={classes.OrderCouponContainer}
                  >
                    {orderDetails?.discount ? (
                      <div>
                        <InputLabel className={classes.appliedStatus}>
                          Applied coupon successfully!
                        </InputLabel>

                        <Button
                          onClick={removeCoupon}
                          variant="outlined"
                          size="small"
                          className={`${classes.removeCouponButton} ${
                            couponCodeError
                              ? classes.removeCouponButtonHover
                              : ""
                          }`}
                          style={{
                            borderColor: "transparent",
                            opacity: 1,
                            transition: "opacity 0.3s",
                          }}
                        >
                          Remove
                        </Button>
                      </div>
                    ) : (
                      ""
                    )}
                  </Card>

                  {/* write code here code for order summary total addons */}
                  {/* <OrderSummaryCart /> */}
                  <OrderSummaryCart orderDetails={orderDetails} />
                  <Box className={classes.OrderSummaryBtnContainer}>
                    <StyledOrderButton
                      onClick={() => {
                        handleClose();
                      }}
                      className={classes.btnInactiveOrderLabelStyle}
                      variant="contained"
                      size="medium"
                    >
                      Cancel
                    </StyledOrderButton>

                    <StyledOrderButton
                      onClick={openRazorpay}
                      className={`${classes.btnOrderLabelStyle} ${
                        !isChecked ? classes.disabledButton : ""
                      }`}
                      size="medium"
                      disabled={!isChecked}
                    >
                      {planDetails.planId == 4
                        ? "Proceed"
                        : "Proceed to Pay"}
                      <CircularProgress
                        style={{
                          color: "#fff",
                          marginLeft: "5px",
                        }}
                        size={isPurchased ? 10 : 0}
                      />
                    </StyledOrderButton>

                    <Dialog
                      open={isErrorDialogOpen}
                      onClose={() => {
                        setIsErrorDialogOpen(false);
                      }}
                      PaperProps={{
                        style: dialogStyle,
                        display: "flex",
                      }}
                      BackdropProps={{
                        style: {
                          backgroundColor: "rgba(0, 0, 0, 0.5)",
                        },
                      }}
                    >
                      <div style={{ flex: 1 }}>
                        <img
                          src={Images.alert}
                          alt="Alert"
                          style={imageStyle}
                        />
                        <DialogTitle
                          style={{
                            marginLeft: "90px",
                            position: "absolute",
                            top: "-4px",
                            zIndex: "999",
                            fontSize: "24px",
                            fontFamily: "Nunito Sans, sans-serif",
                          }}
                        >
                          Alert
                        </DialogTitle>

                        <DialogContent
                          style={{
                            ...contentStyle,
                            marginTop: "40px",
                            marginLeft: "105px",
                            position: "absolute",
                            top: "0",
                          }}
                        >
                          {errorMessage}
                        </DialogContent>
                      </div>
                    </Dialog>
                  </Box>
                </Grid>
                {/* <OrderSummaryCart /> */}
              </Grid>
            </Box>
          </Box>
        </Main>
      </div>
    </>
  );
};
export default OrderSummary;
