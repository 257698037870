import {
  React,
  useState,
  useEffect,
  Cookies,
  useNavigate,
  Backdrop,
  CircularProgress,
  useLocation,
  useMsal,
} from "../../../app.module";
import { getUserDetailsFromCookies } from "../../services";
import { getUserDetails } from "../marketing/MarketingService";
import { MarketingStyle } from "../marketing/MarketingStyle";
import { InteractionRequiredAuthError } from "@azure/msal-browser";
import { getUserAccessTokenFromCookies } from "../../services/cookie/cookies";
import { jwtDecode } from "jwt-decode";

const ProtectedRoute = (props: any) => {
  const { Component } = props;
  const [isAuthUser, setIsAuthUser] = useState<boolean | undefined>(undefined);
  const [isLoading, setIsLoading] = useState(false);
  const navigate = useNavigate();
  const location = useLocation();
  const { instance, accounts } = useMsal();
  const { classes } = MarketingStyle();
  const REFRESH_THRESHOLD = 240; // 5 minutes in seconds


  useEffect(() => {
    const fetchUserDetails = async () => {
      console.log("location.pathname", location.pathname);
      if (location.pathname === "/") {
        //setTimeout(async () => {
        instance
          .handleRedirectPromise()
          .then(async (response: any) => {
            console.log("Test token", response);
            const user = response?.account;
            const userEmail = user.idTokenClaims.emails[0];
            let emailId = userEmail;

            if (emailId) {
              try {
                if (getUserDetailsFromCookies()) {
                  console.log(
                    "Protected route emailId and getUserDetailsFromCookies ",
                    emailId,
                    getUserDetailsFromCookies()
                  );
                  if (getUserDetailsFromCookies()?.identifier?.length > 0) {
                    navigate(
                      `/${getUserDetailsFromCookies()?.identifier}/projects`
                      // ,
                      // { replace: true }
                    );
                  } else if (getUserDetailsFromCookies()?.companyId) {
                    navigate("/plan", { replace: true });
                  } else {
                    navigate("/company", { replace: true });
                  }
                  setIsLoading(false);
                } else {
                  // Get an array of all cookie names
                  const cookieNames = Object.keys(Cookies.get());

                  // Iterate through the cookie names and remove each cookie
                  cookieNames.forEach((cookieName) => {
                    Cookies.remove(cookieName);
                  });
                  const tokenRequest = {
                    account: accounts[0], // This is an example - Select account based on your app's requirements
                    scopes: [
                      "openid",
                      "profile",
                      process.env.REACT_APP_B2C_SCOPES as string,
                    ],
                  };
                  instance
                    .acquireTokenSilent(tokenRequest)
                    .then((response: any) => {
                      // Call your API with the access token and return the data you need to save in state
                      console.log(
                        "Protected route getUserDetails resp",
                        response
                      );
                      Cookies.set(
                        "access_token",
                        JSON.stringify(response.accessToken)
                      );
                      getUserDetails(emailId, response.accessToken).then(
                        (resp: any) => {
                          // console.log(
                          //   "Protected route getUserDetails resp",
                          //   resp
                          // );
                          //Cookies.remove("identifier");
                          Cookies.set("user_details", JSON.stringify(resp));
                          setIsLoading(false);
                          if (resp?.identifier?.length > 0) {
                            navigate(
                              `/${
                                getUserDetailsFromCookies()?.identifier
                              }/projects`
                              //,
                              //{ replace: true }
                            );
                          } else if (resp?.companyId) {
                            navigate("/plan", { replace: true });
                          } else if (
                            getUserDetailsFromCookies()?.emailAddress
                          ) {
                            navigate("/company", { replace: true });
                          } else {
                            instance.logoutRedirect();
                            navigate("/", { replace: true });
                          }
                        }
                      );
                    })
                    .catch(async (e) => {
                      // Catch interaction_required errors and call interactive method to resolve
                      if (e instanceof InteractionRequiredAuthError) {
                        await instance.acquireTokenRedirect(tokenRequest);
                      }

                      throw e;
                    });
                }
              } catch (error) {
                console.error("Error parsing userDetails cookie:", error);
              }
            } else {
              setIsLoading(false);
              if (getUserDetailsFromCookies()) {
                //console.log("Protected route else emailId ", emailId);
                if (getUserDetailsFromCookies()?.identifier?.length > 0) {
                  navigate(
                    `/${getUserDetailsFromCookies()?.identifier}/projects`
                    //,
                    //{ replace: true }
                  );
                } else if (getUserDetailsFromCookies()?.companyId) {
                  navigate("/plan", { replace: true });
                } else {
                  navigate("/company", { replace: true });
                }
                //navigate("/company", { replace: true });
              } else {
                navigate("/");
              }
            }
          })
          .catch((error) => {
            console.log("handleRedirectPromise, Error", error);
            setIsLoading(false);
          });
      } else {
        setIsLoading(false);
        navigate("/");
      }
    };
    const setRoutes = async () => {
      console.log("Protected setRoutes", getUserDetailsFromCookies());
      if (getUserDetailsFromCookies()?.identifier?.length > 0) {
        navigate(
          `/${getUserDetailsFromCookies()?.identifier}/projects`
        );
      } else if (getUserDetailsFromCookies()?.companyId) {
        navigate("/plan", { replace: true });
      } else if (getUserDetailsFromCookies()?.emailAddress) {
        navigate("/company", { replace: true });
      } else {
        navigate("/", { replace: true });
      }
      setIsLoading(false);
    };
    setIsLoading(true);
    console.log(
      "getUserDetailsFromCookies() before dara",
      getUserDetailsFromCookies()
    );
    getUserDetailsFromCookies() ? setRoutes() : fetchUserDetails();
  }, [isAuthUser]);

  const refreshTokenSilently = async () => {
    await instance
      .acquireTokenSilent({
        scopes: [
          "openid",
          "profile",
          process.env.REACT_APP_B2C_SCOPES as string,
        ],
      })
      .then((response: any) => {
        Cookies.set("access_token", JSON.stringify(response.accessToken));
        console.log("acquireTokenSilent for response", response);
      })
      .catch((error) => {
        console.log("Error In MSAL ", error);
      });
  };

  const acquireMSALToken = async () => {
    let currentTime = Math.floor(Date.now() / 1000);
    let tokenExpTime: any = 0;
    if (getUserAccessTokenFromCookies()) {
      tokenExpTime = jwtDecode(getUserAccessTokenFromCookies()).exp;
    }

    let timeUntilExp = tokenExpTime - currentTime;
    if (timeUntilExp <= REFRESH_THRESHOLD) {
      refreshTokenSilently();
    }
    console.log("Token get response", timeUntilExp <= REFRESH_THRESHOLD);
  };

  const handleClose = () => {
    setIsLoading(false);
  };

  return (
    <div>
      <Backdrop
        className={classes.marketingCicularContainer}
        open={isLoading}
        onClick={handleClose}
      >
        <CircularProgress className={classes.marketingCicularView} />
      </Backdrop>
      <Component />
    </div>
  );
  // <>
  //   <div>Hello</div>
  //   {/* {nav?.map((r: NavItem, i: number) => {
  //     if (r.isPrivate && isUser && identifier) {
  //       return <Route key={i} path={r.path} element={r.element} />;
  //     } else if (r.isPrivate && isUser) {
  //       return <Route key={i} path={r.path} element={r.element} />;
  //     } else if (!r.isPrivate) {
  //       return <Route key={i} path={r.path} element={r.element} />;
  //     } else return null;
  //   })} */}
  // </>
};

export default ProtectedRoute;
