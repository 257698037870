// @ts-nocheck
import Chatbot from 'react-chatbot-kit';
import config from './config';
import ActionProvider from './ActionProvider';
import MessageParser from './MessageParser';
import EndChatButton from './EndChat';
import React, { useState } from 'react';
import 'react-chatbot-kit/build/main.css';
import './ChatBotStyle.css';
const ChatBot: React.FC = () => {
    const [chatEnded, setChatEnded] = useState(false);
    const [key, setKey] = useState(0);
    const handleEndChat = () => {
        setKey(prevKey => prevKey + 1); // Change the key to remount the Chatbot
    };
    const modifiedConfig = {
        ...config,
        initialMessages: chatEnded ? [] : config.initialMessages,
    };

    return (
        <div style={{ position: "relative", height: "87vh" }}>
            <EndChatButton onEndChat={handleEndChat} />
            {!chatEnded ? (
                <>
                    <Chatbot
                        key={key}
                        // config={config}
                        config={modifiedConfig}
                        actionProvider={ActionProvider}
                        messageParser={MessageParser}
                    />
                </>
            ) : (
                <div>Chat has ended. You can show a message or redirect here.</div>
            )}
        </div>

    );
};

export default ChatBot;
