import { getData, postData, getUserDetailsFromCookies } from "../../services";
import {
    GenericBot,
    DataSourceBot
} from "../../constants/Endpoints";

export const genericBot = async (botgen_payload: any) => {
    return await postData(
        `/${getUserDetailsFromCookies().identifier}/api/project/${getUserDetailsFromCookies().projectId}
      /chatbot/genericchatbot`,botgen_payload).then((result) => {
        console.log("generic bot resp", result);
        console.log("hello")
        return result;
    });
};
export const datasrcBot = async (botdatasrc_payload: any) => {
    return await postData(
        `/${getUserDetailsFromCookies().identifier}/api/project/${getUserDetailsFromCookies().projectId}
      /chatbot/datasourcechatbot`,botdatasrc_payload).then((result) => {
        console.log("data src bot resp", result);
        return result;
    });
};
