import {
  React,
  Paper,
  useMemo,
  Tabs,
  Tab,
  useState,
  Box,
  Typography,
  Button,
  Select,
  MenuItem,
  clsx,
  useEffect,
  ListItemIcon,
  FiberManualRecord,
  Rectangle,
  Backdrop,
  CircularProgress,
  useNavigate,
  useLocation,
} from "../../../../app.module";
import {  ResponsivePie } from "@nivo/pie";
import { useStyle } from "./DashboardBarChartStyle";

import { Colors, Images } from "../../../constants";
//import PieChartLocalJson from "./PiechartResponseJsonData.json";


const PieChartForDashboard = React.memo((props: any) => {
  const [pieChartJson, setPieChartJson] = useState<any>([]);


  useEffect(() => {
    let { data, pageError } = props;
    console.log("PIE COMPONENT DATA", props);
    setPieChartJson(data);
  }, [pieChartJson]);

  const containerHeight =   '100%';
  const containerWidth =  '100%';



  return (
    <div
      key="PIE01"
      style={{
        // backgroundColor: "rgb(242,245,253)",
        // // height: "300px",
        // // width: "95%",
        // height: "100%", // Set to 100%
        // width: "100%",
        // // overflowX: "auto",
        // // overflow: "scroll",
        // overflow: "hidden",
        // justifyContent: "center",
        // alignItems: "center",
        // display: "flex",
        // top: 0,
        position: 'relative', // This ensures the absolute positioning is relative to this container
        height: containerHeight, // Adjusted height using moduleHeight
        width: containerWidth,
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        overflow: 'hidden', 
      }}
    >
      {props?.pageError !== "" ? (
        <div
          key="TAB101"
          style={{
            display: "flex",
            // height: "300px",
            // width: "100%",
            width: "100%", // Adjusted to 100%
            height: "100%", //
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <img
            style={{
              height: "auto",
              width: "300px",
            }}
            src={Images.no_data_img}
          />
        </div>
      ) : (
        <div
          key="PIE102"
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            // overflow: "scroll",
            // width:
            //   pieChartJson?.data?.length >= 10
            //     ? `${50 * pieChartJson?.data?.length}px`
            //     : "100%",
            // height: "300px", //40vh
            width: "100%", // Adjusted to 100%
            height: "100%", //
          }}
        >
          {props.name}
          <ResponsivePie
            data={pieChartJson?.data ? pieChartJson?.data : []}
            // margin={{ top: 40, right: 80, bottom: 80, left: 80 }}
            margin={{ top: 20, right: 20, bottom: 20, left: 20 }} 
            innerRadius={0.5} // If we will not give this to chart it will become pie
            padAngle={0.7} // If we will not give this to chart it will become pie
            cornerRadius={3}
            activeOuterRadiusOffset={8}
            borderWidth={1}
            borderColor={{
              from: "color",
              modifiers: [["darker", 0.2]],
            }}
            arcLinkLabelsSkipAngle={10}
            arcLinkLabelsTextColor="#333333"
            arcLinkLabelsThickness={2}
            enableArcLinkLabels={false}
            arcLinkLabelsColor={{ from: "color" }}
            arcLabelsSkipAngle={10}
            arcLabelsTextColor={{
              from: "color",
              modifiers: [["darker", 2]],
            }}
            defs={[
              {
                id: "dots",
                type: "patternDots",
                background: "inherit",
                color: "rgba(255, 255, 255, 0.3)",
                size: 4,
                padding: 1,
                stagger: true,
              },
              {
                id: "lines",
                type: "patternLines",
                background: "inherit",
                color: "rgba(255, 255, 255, 0.3)",
                rotation: -45,
                lineWidth: 6,
                spacing: 10,
              },
            ]}
            // legends={[
            //   {
            //     anchor: "bottom", //legendas position
            //     direction: "row",
            //     justify: false,
            //     translateX: 56,
            //     translateY: 60,
            //     itemsSpacing: 2,
            //     itemWidth: 100,
            //     itemHeight: 20,
            //     itemTextColor: "#999",
            //     itemDirection: "left-to-right",
            //     itemOpacity: 1,
            //     symbolSize: 18,
            //     symbolShape: "circle",
            //     effects: [
            //       {
            //         on: "hover",
            //         style: {
            //           itemTextColor: "#000",
            //         },
            //       },
            //     ],
            //   },
            // ]}
          />
        </div>
      )}
    </div>
  );
});

export default PieChartForDashboard;
