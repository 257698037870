export const GetUser = "/api/user";
export const IndustryType = "/api/common/industry-type";
export const IndustrySize = "/api/common/industry-size";
export const Countries = "/api/common/countries";
export const States = "/api/common/state/"; // need to concat curreent state id
export const Cities = "/api/common/cities/"; // need to concat curreent city id
export const CreateCompany = "/api/company";
export const GetCompany="/api/company/companyId"
export const DatabaseType = "/api/common/databasetype";
export const DatabaseProvider = "/api/common/databaseprovider";
export const ConnectionString = "/api/schema/validate?ConnectionString=";
export const CreateProject = "/api/project";
export const CreateDatabase = "/api/project/"; //identifier/api/project/projectid/database
export const ValidateConnection = "/api/project"; //"nRgL0ZP1/api/project/387/schema/validate";
export const Subscriptionplans = "/api/common/subscriptionplans";
export const PlanAddons = "/api/common/addons";
export const ApplyCoupon = "/api/subscription/pricing";
export const CreateQuery = "/api/project/"; //identifier/api/project/projectid/query
export const GetQuery = "/api/project/"; //identifier/api/project/projectid/query/queryID
export const UpdateQuery = "/api/project/projectId/query/queryId"; //identifier/api/project/projectid/query/queryID
export const Purschase = "/api/subscription/purchase";
export const GetVisualizationType = "/api/common/visualization-type";
export const CommonProject = "/api/project/"; //identifier/api/project/projectid/module
export const CreateModule = "/module"; //identifier/api/project/projectid/module
export const GetModules = "/api/module"; //identifier/api/project/projectid/module
export const GetModule = "/api/module"; //identifier/api/project/projectid/module/moduleID
export const GetPossibleVisualization = "/visualization/possible"; //identifier/api/project/projectid/visualization/possible
export const GetVisualizationState = "/visualization/state"; //identifier/api/project/projectid/visualization/state
export const GetVisualizationResponse = "/visualization/response";
export const GetDB = "/api/project/projectid/database/databseID"; //
export const GetDatabase = "/api/project/projectId/database/databaseID"; //identifier/api/project/projectid/database?Id="dbid"
export const GetProject = "/api/project";
export const UpdateDatabase="/api/project/projectId/database/databaseID";
export const UpdateProject="/api/project/projectId";
export const GetUsers="/api/userinvite";
export const InviteUser="/api/userinvite";
export const UserRole="/api/common/userrole";
export const DeleteProject="/api/project/projectId";
export const DeleteDatabase="/api/project/projectId/database/databaseID";
export const SetDefaultProject="/api/project/usermanagement/users/id/setdefaultproject/projectId";
export const ShowDashboard="/api/project/projectId/dashboard/dashboardId";
export const QuerySuggestion="/api/project/projectId/database/database/databaseId";
export const GenericBot="/api/project/projectId/chatbot/genericchatbot";
export const DataSourceBot="/api/project/projectId/chatbot/datasourcechatbot";
export const Subscription="/api/subscriptiondetails";
export const Date="/api/common/dates";
export const Month = "/api/common/months";
export const Day = "/api/common/days";
export const recurrencetype = "/api/common/recurrencetype";
export const fileupload = "/file_upload";
export const datasource = "/api/common/datasources";
export const filedatasource = "/datasource";