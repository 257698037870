import { React, makeStyles } from "../../../app.module";
import { AppThemes, Fonts, Colors } from "../../constants";
export const MarketingStyle = makeStyles()((theme?: any) => {
  console.log("AppThemes", AppThemes);
  return {
    marketingHeading: {
      height: "160px",
      width: "500px",
      marginBottom: "20px",
    },
    marketingCicularContainer: {
      color: Colors?.main,
      zIndex: parseInt(`${Colors?.zIndex?.drawer}+${1}`),
    },
    marketingCicularView: {
      color: Colors?.main,
    },
    marketingTitleStyle: {
      fontFamily: Fonts.NunitoSans.regular,
      fontStyle: "normal",
      fontWeight: "bold",
      fontSize: "40px",
      lineHeight: "45px",
      textAlign: "center",
      color: "rgb(28, 31, 72)",
      marginBottom: "10px",
      //cursor: "pointer",
    },
    wingsStyle: {
      fontFamily: "'Arial'",
      fontStyle: "normal",
      fontWeight: "700",
      fontSize: "36px",
      lineHeight: "45px",
      textAlign: "center",
      color: "rgb(28, 31, 72)",
      marginBottom: "40px",
      marginLeft: "30px",
      //cursor: "pointer",
    },
    marketingMenuStyle: {
      fontFamily: Fonts.regular,
      fontStyle: "normal",
      fontWeight: "700",
      fontSize: "16px",
      lineHeight: "18px",
      color: "rgb(27, 27, 27)",
      marginTop: "10px",
      //textDecorationColor: "rgb(27, 27, 27)",
      //  textDecorationLine: location.pathname === "/" ? "underline" : "",
      //border: "solid 1px",
      // textDecorationThickness: "2px",
      // textUnderlineOffset: "20px",
      //cursor: "pointer",
    },
    marketingMenuLineStyle: {
      fontFamily: "'Arial'",
      fontStyle: "normal",
      fontWeight: "700",
      fontSize: "16px",
      lineHeight: "18px",
      color: "rgb(27, 27, 27)",
      textDecorationColor: "rgb(27, 27, 27)",
      cursor: "pointer",

      //textDecorationLine: location.pathname === "/" ? "underline" : "",
      //border: "solid 1px",

      //cursor: "pointer",
    },
    marketingContactustyle: {
      fontFamily: "NunitoSansRegular",
      fontStyle: "normal",
      fontWeight: "100",
      fontSize: "16px",

      lineHeight: "18px",
      color: "rgb(84, 84, 84)",
      cursor: "pointer",
      textDecorationColor: "rgb(28, 31, 72)",
      marginTop: "10px",
      //textDecorationLine: location.pathname === "contactus" ? "overline" : "",
      //cursor: "pointer",
    },
    marketingHeader: {
      display: "flex",
      flex: 1,
      marginTop: "30px",
      height: "70px",
      justifyContent: "center",
      alignItems: "center",
    },
    marketingMargin: {
      marginRight: "10px",
    },
    marketingBar: {
      //marginRight: "10px",
      width: "50px",
      height: "2px",
      backgroundColor: "rgb(27, 27, 27)",
    },
    barWidth: {
      width: "100px",
    },
    aiTitleStyle: {
      fontFamily: "'Arial'",
      fontStyle: "normal",
      fontWeight: "700",
      fontSize: "16px",
      lineHeight: "18px",
      color: "#fff",

      //cursor: "pointer",
    },
    container: {
      justifyContent: "center",
      alignItems: "center",
      display: "flex",
    },
    vcontainer: {
      justifyContent: "center",
      alignItems: "center",
      display: "flex",
      backgroundColor: "rgb(28, 31, 72)",
      marginBottom: "50px",
    },
    direction: {
      flexDirection: "column",
      marginRight: "50px",
    },
    marketingBtn: {
      border: "1px solid #1158CE",
    },
    marketingContainer: {
      backgroundColor: "#f9f9f9",
      display: "flex",
      justifyContent: "space-between",
      alignItems: "center",
      paddingLeft: "100px",
      paddingRight: "100px",
      marginBottom: "50px",
    },
    rightImg: {
      width: "70%",
      marginLeft: "40px",
    },
    videoDiv: {
      width: "100%",
      height: "600px",
      paddingLeft: "150px",
      paddingRight: "150px",
      marginTop: "30px",
      marginBottom: "50px",
    },
    iframeDiv: {
      width: "100%",
      height: "100%",
    },
  };
});
