import {
  getData,
  putData,
  postData,
  deleteData,
  getUserDetailsFromCookies,
} from "../../../services";
import { CreateProject } from "../../../constants/Endpoints";
import { useMsal } from "../../../../app.module";
import { InteractionRequiredAuthError } from "@azure/msal-browser";

let TOKEN: string = "";
let msalDetails: any = "";
export function useGetToken() {
  const { instance, accounts } = useMsal();
  if (TOKEN === "") {
    const tokenRequest = {
      account: accounts[0],
      scopes: ["openid", "profile", process.env.REACT_APP_B2C_SCOPES as string],
    };
    instance
      .acquireTokenSilent(tokenRequest)
      .then((response) => {
        TOKEN = response.accessToken;
      })
      .catch(async (e) => {
        if (e instanceof InteractionRequiredAuthError) {
          await instance.acquireTokenRedirect(tokenRequest);
        }

        throw e;
      });
  }
  const fetcher = {};
  return [fetcher];
}

export const dogetproject = async () => {
  return await getData(
    `/${getUserDetailsFromCookies().identifier}/api/project?UserId=${
      getUserDetailsFromCookies().id
    }`
  ).then((result) => {
    return result;
  });
};

export const createNewProject = async (project_details: any) => {
  try {
    const result = await postData(
      `/${getUserDetailsFromCookies().identifier}${CreateProject}`,
      project_details
    );
    return result;
  } catch (error) {
    console.error("Error creating new project:", error);
    throw error;
  }
};

export const doDeleteProject = async (projectId: number | undefined) => {
  return await deleteData(
    `/${getUserDetailsFromCookies().identifier}/api/project/${projectId}`
  ).then((result) => {
    return result;
  });
};

export const updateProject = async (project_details: any) => {
  return await putData(
    `/${getUserDetailsFromCookies().identifier}/api/project/${
      project_details.id
    }`,
    project_details
  ).then((result) => {
    return result;
  });
};

export const defaultProject = async (project_details: any) => {
  console.log("project details..............", project_details);
  return await postData(
    `/${
      getUserDetailsFromCookies().identifier
    }/api/project/usermanagement/users/${
      getUserDetailsFromCookies().id
    }/setdefaultproject/${project_details.projectId}`,
    project_details
  ).then((result) => {
    return result;
  });
};
export class ProjectResult {
  id: number | undefined;
  industrySize: string | undefined;
  isActive: boolean | undefined;
  industryType: string | undefined;
  countryName: string | undefined;
  phoneCode: string | undefined;
  name: string | undefined;
  flag: string | undefined;
  cityName: string | undefined;
  stateId: string | undefined;
  constructor(data: any) {
    Object.assign(this, {
      id: data.id,
      isActive: data.isActive,
      industrySize: data.industrySize,
      industryType: data.industryType,
      countryName: data.countryName,
      phoneCode: data.phoneCode,
      name: data.name,
      flag: data.flag,
      cityName: data.cityName,
      stateId: data.stateId,
    });
  }
}

export class GetProjectsResults {
  id: number | undefined;
  name: string | undefined;
  description: string | undefined;
  isAvailable: boolean | undefined;
  isActive: boolean | undefined;
  createdBy: string | undefined;
  createdDate: Date | undefined;
  constructor(data: any) {
    this.createdDate = new Date(data.createdDate);
    Object.assign(this, {
      id: data.id,
      name: data.name,
      description: data.description,
      isAvailable: data.isAvailable,
      isActive: data.isActive,
      createdBy: data.createdBy,
      createdDate: data.createdDate,
    });
  }
  getFormattedCreatedDate(): string {
    const options = { day: "2-digit", month: "long", year: "2-digit" };
    return this.createdDate?.toLocaleDateString(undefined) || "";
  }
}


