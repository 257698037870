import {
  React,
  Paper,
  useMemo,
  Tabs,
  Tab,
  useState,
  Box,
  Typography,
  Button,
  Select,
  MenuItem,
  clsx,
  useEffect,
  ListItemIcon,
  FiberManualRecord,
  Rectangle,
  Backdrop,
  CircularProgress,
  useNavigate,
  useLocation,
} from "../../../../app.module";
import {
  GetDatabasesResults,
  dogetdatabase,
} from "../../Main_Project/datasource/database/DatabaseService";
import { ResponsiveBar } from "@nivo/bar";
import { ResponsiveLine } from "@nivo/line";
import { useStyle } from "./BarChartStyle";
import {
  getVisualizationData,
  getVisualizationType,
  getPossibalVisualization,
  getLineChartVisualizationData,
  getVisualizationState,
} from "./ChartsService";
import {
  getUserDetailsFromCookies,
  getQueryIdFromCookies,
} from "../../../services";
import { Colors } from "../../../constants";
interface TabPanelProps {
  children?: React.ReactNode;
  index: number;
  value: number;
  visualizationTypeList: any[];
  lineChartDetails: any;
  lineChartState: any;
  isY: boolean;
  classes: any;
  handleChartType: (name: string) => void; // Function type now expects a string parameter
  handleAxisChanged: (x: any, y: any) => void;
}

const CustomTabPanel = (props: TabPanelProps) => {
  const {
    children,
    handleChartType,
    handleAxisChanged,
    visualizationTypeList,
    lineChartDetails,
    lineChartState,
    value,
    classes,
    index,
    isY,
    ...other
  } = props;
  const location = useLocation();
  console.log(
    "XAXIXXXXX"
    //lineChartDetails
    // lineChartDetails?.chartParameter
    // lineChartDetails?.chartParameter[0]?.parameterValue[
    //   parseInt(lineChartState?.chartParameter.split(",")[0])
    // ]

    // lineChartDetails?.chartParameter[0]?.parameterValue[
    //   parseInt(lineChartState?.chartParameter.split(",")[0])
    // ]
  );
  const [yaxis, setYaxis] = useState(
    lineChartDetails?.chartParameter[1]?.parameterValue[
      parseInt(lineChartState?.chartParameter.split(",")[1])
    ]
      ? lineChartDetails?.chartParameter[1]?.parameterValue[
          parseInt(lineChartState?.chartParameter.split(",")[1])
        ]
      : "none"
  ); // we have set the none is there is empty visualization
  const [xaxis, setXaxis] = useState(
    lineChartDetails?.chartParameter[0]?.parameterValue[
      parseInt(lineChartState?.chartParameter.split(",")[0])
    ]
      ? lineChartDetails?.chartParameter[0]?.parameterValue[
          parseInt(lineChartState?.chartParameter.split(",")[0])
        ]
      : "none"
  );
  const [isMounted, setIsMounted] = useState<boolean>(false);
  const [moduleType, setModuleType] = useState(
    location.state?.moduleType ? location.state?.moduleType : "none"
  );

  const [showPlaceholder, setShowPlaceholder] = useState(moduleType === "none");
  const [moduleTypeList, setModuleTypeList] = useState<any>([]);
  const [moduleColumnHeaders, setModuleColumnHeaders] = useState([
    { name: "Donuts", id: 1 },
    { name: "Fries", id: 2 },
    { name: "Kebab", id: 3 },
    { name: "Sandwich", id: 4 },
  ]);
  const [colorType, setColorType] = useState("none");
  const [colorTypeList, setColorTypeList] = useState([
    { color: "red", id: 1, name: "##89D280" },
    { color: "blue", id: 2, name: "##FBDB38" },
    { color: "green", id: 3, name: "##EF6C6C" },
    { color: "yellow", id: 4, name: "###66C8FF" },
  ]);
  const [moduleHeaderType, setModuleHeaderType] = useState("Fries");
  const navigate = useNavigate();
  useEffect(() => {

    setTimeout(() => {
      setXaxis(
        lineChartDetails?.chartParameter[0]?.parameterValue[
          parseInt(lineChartState?.chartParameter.split(",")[0])
        ]
      );
      setYaxis(
        lineChartDetails?.chartParameter[1]?.parameterValue[
          parseInt(lineChartState?.chartParameter.split(",")[1])
        ]
      );
      setIsMounted(true);
    }, 1000);
  }, [moduleType, isMounted]);
  const handleModuleStateChange = (e: any) => {
    const { name, value } = e.target;
    handleChartType(value);
    setModuleType(value);
    switch (value) {
      case "BarChart":
        navigate(
          `/${getUserDetailsFromCookies()?.identifier}/projects/barchart`,
          { state: { moduleType: "BarChart" } }
        );
        break;
      case "LineChart":
        navigate(
          `/${getUserDetailsFromCookies()?.identifier}/projects/linechart`,
          { state: { moduleType: "LineChart" } }
        );
        break;
      case "PieChart":
        navigate(
          `/${getUserDetailsFromCookies()?.identifier}/projects/piechart`,
          { state: { moduleType: "PieChart" } }
        );
        break;
      case "Heatmap":
        navigate(
          `/${getUserDetailsFromCookies()?.identifier}/projects/heatmap`,
          { state: { moduleType: "Heatmap" } }
        );
        break;
    }
  };
  const handleXaxisStateChange = (e: any) => {
    const { name, value } = e.target;
    setXaxis(value);
  };
  const handleYaxisStateChange = (e: any) => {
    const { name, value } = e.target;
    setYaxis(value);
  };
  const handleColumnHeaderStateChange = (e: any) => {
    const { name, value } = e.target;

    setModuleHeaderType(value);
  };

  const handleColorStateChange = (e: any) => {
    const { name, value } = e.target;
    setColorType(value);
  };

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      <Box sx={{ p: 3 }}>
        <Typography className={classes.visualizationLable}>
          Module type
        </Typography>
        <Select
          id="demo-simple-select-helper-type"
          value={moduleType}
          name="module_type"
          className={clsx(
            classes.visualizationSelect

          )}
          onFocus={(e) => setShowPlaceholder(false)}
          onChange={handleModuleStateChange}
          onClose={(e) => {
            setShowPlaceholder(
              (e.target as HTMLInputElement).value === undefined
            );
          }}
        >
          <MenuItem
            key="0"
            disabled
            value={moduleType}
          >
            Select module
          </MenuItem>

          {visualizationTypeList?.map((item: any) => (
            <MenuItem
              key={item?.chartType + index}
              className={classes.visualizationMenuitem}
              value={item?.chartType}
            >
              {item?.chartType}
            </MenuItem>
          ))}
        </Select>
        <Typography className={classes.visualizationLable}>X-Axis</Typography>
        <Select
          id="demo-simple-select-helper-type"
          value={xaxis}
          // error={formValues.database_type.error}
          name="xaxis"
          className={clsx(
            classes.visualizationSelect

            // formValues.database_type.value === "none"
            //   ? classes.projectSelectdisabled
            //   : null
          )}
          onFocus={(e) => setShowPlaceholder(false)}
          onChange={handleXaxisStateChange}
          // onClose={(e) => {
          //   setShowPlaceholder(
          //     (e.target as HTMLInputElement).value === undefined
          //   );
          //}}
          defaultValue={xaxis ? xaxis : ""}
        >
          <MenuItem
            // className={clsx(
            //   classes.projectMenuitem,
            //   !showPlaceholder ? classes.projectMenuitemhidden : null
            // )}
            key="0"
            disabled
            value={xaxis}
          >
            Select
          </MenuItem>

          {isMounted &&
            lineChartDetails?.chartParameter[0]?.parameterValue?.map(
              (item: any, index: number) => (
                <MenuItem
                  key={index + ""}
                  className={classes.visualizationMenuitem}
                  value={item}
                >
                  {item}
                </MenuItem>
              )
            )}
        </Select>

        <Typography className={classes.visualizationLable}>Y-Axis</Typography>
        <Select
          id="demo-simple-select-helper-type"
          value={yaxis}
          // error={formValues.database_type.error}
          name="yaxis"
          className={clsx(
            classes.visualizationSelect

            // formValues.database_type.value === "none"
            //   ? classes.projectSelectdisabled
            //   : null
          )}
          onFocus={(e) => setShowPlaceholder(false)}
          onChange={handleYaxisStateChange}
          // onClose={(e) => {
          //   setShowPlaceholder(
          //     (e.target as HTMLInputElement).value === undefined
          //   );
          //}}
          defaultValue="none"
        >
          <MenuItem
            // className={clsx(
            //   classes.projectMenuitem,
            //   !showPlaceholder ? classes.projectMenuitemhidden : null
            // )}
            key="0"
            disabled
            value={yaxis}
          >
            Select
          </MenuItem>

          {isMounted &&
            lineChartDetails?.chartParameter[1]?.parameterValue?.map(
              (item: any, index: number) => (
                <MenuItem
                  key={index + ""}
                  className={classes.visualizationMenuitem}
                  value={item}
                >
                  {item}
                </MenuItem>
              )
            )}
        </Select>

        <Button
          onClick={() => {
            handleAxisChanged(xaxis, yaxis);
          }}
          variant="contained"
          className={classes.saveTableBtn}
        >
          Analyze
        </Button>
      </Box>
    </div>
  );
};
const CustomTabPanel1 = (props: TabPanelProps) => {
  const { children, value, classes, index, isY, ...other } = props;
  const [updateModue, setUpdateModue] = useState("Every week");
  const [updateType, setUpdateType] = useState("none");
  const [updateTypeList, setUpdateTypeList] = useState([
    { id: 1, name: "Week" },
    { id: 2, name: "Month" },
    { id: 3, name: "Year" },
    { id: 4, name: "Daily" },
  ]);
  const [reccuranceList, setReccuranceList] = useState([
    { id: 1, name: "1" },
    { id: 2, name: "2" },
    { id: 3, name: "3" },
    { id: 4, name: "4" },
  ]);
  const [reccurance, setReccurance] = useState("1");
  const [showPlaceholder, setShowPlaceholder] = useState(
    updateModue === "none"
  );
  const [updateModueTypeList, setUpdateModueTypeList] = useState([
    { name: "Every week", id: 1 },
    { name: "Every month", id: 2 },
  ]);
  useEffect(() => {}, [updateModue]);
  const handleModuleStateChange = (e: any) => {
    const { name, value } = e.target;
    setUpdateModue(value);
  };
  const handleUpdatesStateChange = (e: any) => {
    const { name, value } = e.target;
    //handleChartType(value);
    setUpdateType(value);
  };
  const handleReccuranceStateChange = (e: any) => {
    const { name, value } = e.target;
    setReccurance(value);
  };
  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>

          <Typography className={classes.visualizationLable}>
            Update module
          </Typography>
          <Select
            id="demo-simple-select-helper-type"
            value={updateModue}
            name="module_type"
            className={clsx(
              classes.visualizationSelect
            )}
            onFocus={(e) => setShowPlaceholder(false)}
            onChange={handleModuleStateChange}
            onClose={(e) => {
              setShowPlaceholder(
                (e.target as HTMLInputElement).value === undefined
              );
            }}
            defaultValue="none"
          >
            <MenuItem
              key="0"
              disabled
              value="none"
            >
              Select update module type
            </MenuItem>

            {updateModueTypeList.map((item: any) => (
              <MenuItem
                key={item?.id}
                className={classes.visualizationMenuitem}
                value={item?.name}
              >
                {item?.name}
              </MenuItem>
            ))}
          </Select>
          <Typography className={classes.recurranceLable}>
            Repeat every
          </Typography>
          <div className={classes.twoDropDownContainer}>
            <div
              className={clsx(
                classes.visualizationRecuuranceSelectFlex,
                classes.dropDownFiledLabelContainer
              )}
            >
              <Select
                id="demo-simple-select-helper-type"
                value={reccurance}
                name="module_type"
                className={clsx(
                  classes.visualizationSelect
                )}
                onFocus={(e) => setShowPlaceholder(false)}
                onChange={handleReccuranceStateChange}
                onClose={(e) => {
                  setShowPlaceholder(
                    (e.target as HTMLInputElement).value === undefined
                  );
                }}
                defaultValue="none"
              >
                <MenuItem
  
                  key="0"
                  disabled
                  value="none"
                >
                  Reccurance
                </MenuItem>

                {reccuranceList.map((item: any) => (
                  <MenuItem
                    key={item?.id}
                    className={classes.visualizationMenuitem}
                    value={item?.name}
                  >
                    {item?.name}
                  </MenuItem>
                ))}
              </Select>
            </div>
            <div className={classes.dropDownRecurranceFiledLabelContainerRight}>
              <Select
                id="demo-simple-select-helper-type"
                value={updateType}
                // error={formValues.database_type.error}
                name="module_type"
                className={clsx(
                  classes.visualizationSelect
                )}
                style={{
                  height: "54px",
                }}
                onFocus={(e) => setShowPlaceholder(false)}
                onChange={handleUpdatesStateChange}
                onClose={(e) => {
                  setShowPlaceholder(
                    (e.target as HTMLInputElement).value === undefined
                  );
                }}
                defaultValue="none"
              >
                <MenuItem
                  key="0"
                  disabled
                  value="none"
                >
                  Select Type
                </MenuItem>
                {updateTypeList.map((item: any) => (
                  <MenuItem
                    key={item?.id}
                    className={classes.visualizationMenuitem}
                    value={item?.name}
                  >
                    {item?.name}
                  </MenuItem>
                ))}
              </Select>
            </div>
          </div>
        </Box>
      )}
    </div>
  );
};
const a11yProps = (index: number) => {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
};

const LineChartSeries = React.memo((props: any) => {
  const [value, setValue] = useState<number>(0);
  const [isYaxis, setIsYaxis] = useState<boolean>(false);
  const [isXaxis, setIsXaxis] = useState<boolean>(false);
  const [lineChartJson, setLineChartJson] = useState<any>([]);
  const [isAxisChanged, setIsAxisChanged] = useState(true);
  const [lineVisualizationState, setLineVisualizationState] = useState<any>({});
  const [lineVisualization, setLineVisualization] = useState<any>({});
  const [pageError, setPageError] = useState("");
  const [xValue, setXValue] = useState<any>("");
  const [yValue, setYValue] = useState<any>("");
  const [zValue, setzValue] = useState<any>("");
  const [customBarColors, setCustomBarColors] = useState<any>([]);
  const [barIndexValue, setBarIndexValue] = useState("");
  const [isLoading, setIsLoading] = useState(true);
  const [selectedModuleType, setSelectedModuleType] = useState("Bar Chart");
  const [visualizationTypes, setVisualizationTypes] = useState<any>([]);
  const [defsColor, setDefsColor] = useState("red");
  const [activeTab, setActiveTab] = React.useState(0);
  const { classes } = useStyle({ isYaxis: false, isXaxis: true, activeTab });
  const [databaselist, setDatabaselist] = useState<GetDatabasesResults[]>([]);

  useEffect(() => {
    dogetdatabase().then((database_list: any) => {
      if (!database_list?.isError) {
        setDatabaselist(database_list);
      }
    });
  });
  useEffect(() => {
    const getLineChartDatat = (outputArray: any) => {
      getLineChartVisualizationData("SeriesLineChart", outputArray).then(
        (resp) => {
          if (!resp.isError) {
            const { result } = resp;
            const lineChart = JSON.parse(result);
            setLineChartJson(lineChart);
            setTimeout(() => {
              setIsLoading(false);
            }, 1000);

            console.log("lineChart data", lineChart);
          } else {
            setPageError("Data not found");
            setTimeout(() => {
              setIsLoading(false);
            }, 1000);
          }
        }
      );
    };
    //if (barIndexValue === "") {
    getVisualizationState().then((states: any) => {
      console.log("V STATE", states);
      setLineVisualizationState(states)
      getPossibalVisualization(getQueryIdFromCookies()).then((resp: any) => {
        setVisualizationTypes([...resp]);
        const foundObject = resp.find(
          (obj: any) => obj.chartType === "SeriesLineChart"
        );
        console.log("LINE OBJECT", foundObject);
        let parameterXIdx =
          foundObject?.chartParameter[0]?.parameterValue.indexOf(xValue);
        let parameterYIdx =
          foundObject?.chartParameter[1]?.parameterValue.indexOf(yValue);
        console.log("Xvalue of axis", xValue);
        let payload_visulaization = {
          id: getQueryIdFromCookies(),
          userId: getUserDetailsFromCookies().id,
          chartTypeId: foundObject?.chartTypeId,
          chartType: foundObject?.chartType,
          chartParameter: [
            {
              parameterId: 1,
              parameterValueIndex: 0,
              parameterType: "X_Axis",
              parameterValue: "first_name",
            },
            {
              parameterId: 2,
              parameterValueIndex: 1,
              parameterType: "Y_Axis",
              parameterValue: "credit_limit",
            },
            {
              parameterId: 3,
              parameterValueIndex: 4,
              parameterType: "Legend",
              parameterValue: "branch_name",
            },
          ],
        };
        console.log("Tyles Data", payload_visulaization);

        setLineVisualization(foundObject);
        setSelectedModuleType(foundObject?.chartType);
        getLineChartDatat(payload_visulaization);
      });
    });
  }, [isLoading]);
  const handleTabChange = (event: React.SyntheticEvent, newValue: number) => {
    setValue(newValue);
    setIsYaxis(!isYaxis);
    setActiveTab(newValue);
  };
  const handleChartType = (name: string) => {
    setSelectedModuleType(name);
    setDefsColor("green");
    console.log("Name: ", name);
  };
  const handleAxisChanged = (x: any, y: any) => {
    console.log("XAxis, YAxis", x, y);
    if (x !== y) {
      if (x !== "none") {
        setXValue(x);
      }
      if (y !== "none") {
        setYValue(y);
      }

      setIsLoading(true);
      setIsAxisChanged(!isAxisChanged);
    } else {
      alert("X and Y axis should not be same.");
    }
  };
  const chartsComponent = () => {
    console.log("lineChartJson?.data.length", lineChartJson);
    return (
      <div
        key="CHART101"
        style={{
          backgroundColor: Colors?.background.backgroundColorTheme3,
          height: "87vh",
          width: "75%",
          overflowX: "auto",
          boxShadow: "0px 0px 4px 0px #00000040",
          borderRadius: "10px 0px 0px 10px",
          overflow: "scroll",
          top: 0,
        }}
      >
        <div
          style={{
            display: "flex",
            justifyItems: "right",
            marginLeft: "10px",
            marginTop: "10px",
          }}
        >
          Datasource name :
          {databaselist.map((item: any) => (
            <label key={item?.id}>{item?.name}</label>
          ))}
        </div>
        <div
          key="CHART102"
          style={{
            width: `${
              400 *
              (lineChartJson?.data[0]?.data.length < 15
                ? 20
                : lineChartJson?.data[0]?.data.length)
            }px`,
            height: "75vh",
            overflow: "scroll",
          }}
        >
          {!isLoading ? (
            <ResponsiveLine
              data={
                lineChartJson?.data
              }
              margin={{ top: 50, right: 110, bottom: 50, left: 60 }}
              xScale={{ type: "point" }}
              yScale={{
                type: "linear",
                min: "auto",
                max: "auto",
                stacked: true,
                reverse: false,
              }}
              yFormat=" >-.2f"
              axisTop={null}
              axisRight={null}
              axisBottom={{
                tickSize: 5,
                tickPadding: 5,
                tickRotation: 0,
                legend: lineChartJson?.data[0]?.axis_bottom_legend
                  ? lineChartJson?.data[0]?.axis_bottom_legend
                  : "",
                legendOffset: 36,
                legendPosition: "middle",
              }}
              axisLeft={{
                tickSize: 5,
                tickPadding: 5,
                tickRotation: 0,
                legend: lineChartJson?.data[0]?.axis_left_legend
                  ? lineChartJson?.data[0]?.axis_left_legend
                  : "",
                legendOffset: -40,
                legendPosition: "middle",
              }}
              pointSize={10}
              pointColor={{ theme: "background" }}
              pointBorderWidth={2}
              pointBorderColor={{ from: "serieColor" }}
              pointLabelYOffset={-12}
              useMesh={true}
              legends={[
                {
                  anchor: "bottom-right",
                  direction: "column",
                  justify: false,
                  translateX: 100,
                  translateY: 0,
                  itemsSpacing: 0,
                  itemDirection: "left-to-right",
                  itemWidth: 80,
                  itemHeight: 20,
                  itemOpacity: 0.75,
                  symbolSize: 12,
                  symbolShape: "circle",
                  symbolBorderColor: "rgba(0, 0, 0, .5)",
                  effects: [
                    {
                      on: "hover",
                      style: {
                        itemBackground: "rgba(0, 0, 0, .03)",
                        itemOpacity: 1,
                      },
                    },
                  ],
                },
              ]}
            />
          ) : (
            <div>Loading</div>
          )}
        </div>
      </div>
    );
  };
  const handleClose = () => {
    setIsLoading(false);
  };
  if (isLoading && xValue === "") {
    return (
      <div
        style={{
          backgroundColor: Colors?.background.backgroundColorTheme3,
          display: "flex",
          flexDirection: "row",
        }}
      >
        <Backdrop
          className={classes.circularPlanContainer}
          open={isLoading}
          onClick={handleClose}
        >
          <CircularProgress className={classes.circularPlanView} />
        </Backdrop>
        <div
          key="TAB101"
          style={{

            height: "87vh",
            width: "100%",
            boxShadow: "0 2px 8px rgba(0, 0, 0, 0.1)",
            backgroundColor: "#fff",
            transition: "box-shadow 0.3s ease-in-out",
            borderRadius: "0px 10px 10px 0px",
            overflow: "scroll",
            top: 0,
          }}
        ></div>
      </div>
    );
  }
  return (
    <div
      style={{
        backgroundColor: Colors?.background.backgroundColorTheme3,
        display: "flex",
        flexDirection: "row",
      }}
    >
      <Backdrop
        className={classes.circularPlanContainer}
        open={isLoading}
        onClick={handleClose}
      >
        <CircularProgress className={classes.circularPlanView} />
      </Backdrop>
      {chartsComponent()}
      <div
        key="TAB101"
        style={{
          height: "87vh",
          width: "25%",
          boxShadow: "0 2px 8px rgba(0, 0, 0, 0.1)",
          backgroundColor: "#fff",
          transition: "box-shadow 0.3s ease-in-out",
          borderRadius: "0px 10px 10px 0px",
          overflow: "scroll",
          top: 0,
        }}
      >
        <div
          style={{
            backgroundColor: "transparent",

            width: "100%",

          }}
        >
          <Tabs
            value={value}
            indicatorColor="primary"
            textColor="primary"
            onChange={handleTabChange}
            aria-label="disabled tabs example"
            classes={{ root: classes.root, indicator: classes.indicator }}
          >
            <Tab
              className={classes.tabLabel}
              style={{
                width: "50%",
                color: activeTab === 0 ? "#000" : "#DADADA",
                backgroundColor:
                  activeTab === 0
                    ? "transparent"
                    : Colors?.background.backgroundColorTheme3,
                padding: "8px 16px",
              }}
              {...a11yProps(0)}
              label="Visulization"
            />
            <Tab
              className={classes.tabLabel}
              style={{
                width: "50%",
                color: activeTab === 1 ? "#000" : "#DADADA",
                backgroundColor:
                  activeTab === 1
                    ? "transparent"
                    : Colors?.background.backgroundColorTheme3,
                padding: "8px 16px",
              }}
              {...a11yProps(1)}
              label="Reccurence"

            />
          </Tabs>
        </div>
        <CustomTabPanel
          handleChartType={handleChartType}
          handleAxisChanged={handleAxisChanged}
          classes={classes}
          isY={isYaxis}
          value={value}
          visualizationTypeList={visualizationTypes}
          lineChartDetails={lineVisualization}
          lineChartState={lineVisualizationState}
          index={0}
        ></CustomTabPanel>
        <CustomTabPanel1
          handleChartType={handleChartType}
          handleAxisChanged={handleAxisChanged}
          classes={classes}
          isY={isYaxis}
          value={value}
          visualizationTypeList={visualizationTypes}
          lineChartDetails={lineVisualization}
          lineChartState={lineVisualizationState}
          index={1}
        ></CustomTabPanel1>
      </div>
    </div>
  );
});

export default LineChartSeries;
