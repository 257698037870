
import {
  React,
  Paper,
  useMemo,
  Tabs,
  Tab,
  useState,
  useEffect,
  Backdrop,
  CircularProgress,
} from "../../../../app.module";
import { ResponsiveBar } from "@nivo/bar";
import { useStyle } from "./DashboardBarChartStyle";
import { Colors, Images } from "../../../constants";



const BarChartForDashboard = React.memo((props: any) => {
  const [barChartJson, setBarChartJson] = useState<any>([]);
  const [isLoading, setIsLoading] = useState(true);
  const [activeTab, setActiveTab] = React.useState(0);
  const { classes } = useStyle({ isYaxis: false, isXaxis: true, activeTab });

  useEffect(() => {
    let  data  = props.data;
  
    //setIsLoading(true);
    // setTimeout(() => {
    setBarChartJson(data);
    setIsLoading(false);
    //}, 500);
    console.log("BAR CHART DATA PROPS", props);
  }, [barChartJson]);

 
  useEffect(() => {
    // Inject custom scrollbar styles
    const style = document.createElement('style');
    style.type = 'text/css';
    style.innerHTML = `
      .BarChartScroll::-webkit-scrollbar {
        width: 12px;
        height: 12px;
        background-color: transparent; /* make scrollbar background transparent */
      }
      .BarChartScroll:hover::-webkit-scrollbar-thumb {
        border-radius: 10px;
        -webkit-box-shadow: inset 0 0 6px rgba(0,0,0,.3);
        background-color: #D3D3D3; /* only show scrollbar thumb on hover */
      }
    `;
    document.head.appendChild(style);
  
    // Ensure that we remove the style on cleanup
    return () => {
      document.head.removeChild(style);
    };
  }, []);
  
  const BarChartScrollClassName = "BarChartScroll";

  if (isLoading && props?.pageError === "") {
    return (
      <div
        style={{
          backgroundColor: Colors?.background.backgroundColorTheme3,
          display: "flex",
          flexDirection: "row",
        }}
      >
        <Backdrop
          className={classes.circularPlanContainer}
          open={isLoading}
          //onClick={handleClose}
        >
          <CircularProgress className={classes.circularPlanView} />
        </Backdrop>
       
      </div>
    );
  }

  type Tick = {
    value: string | number;
    x: number;
    y: number;
  };
  
  // Custom left axis tick component
  const CustomLeftTick = (tick: Tick) => {
    // Convert the value to string in case it's a number
    const labelValue = tick.value.toString();
    const MAX_LENGTH = 5; // Maximum number of characters before truncating
    const label = labelValue.length > MAX_LENGTH ? `${labelValue.substring(0, MAX_LENGTH)}...` : labelValue;
  
    return (
      <g transform={`translate(${tick.x - 5},${tick.y})`}>
        <text
          textAnchor="end"
          dominantBaseline="middle"
          style={{
            fill: '#333',
            fontSize: '12px',
          }}
        >
          <title>{labelValue}</title> {/* Tooltip for the full label */}
          {label}
        </text>
      </g>
    );
  };
  

  const labelRotationThreshold = 10; // This is an arbitrary value, you need to adjust it based on your actual labels

  const labels = barChartJson?.data.map((item: { [x: string]: any; }) => item[barChartJson?.indexBy]);
  const shouldRotateLabels = labels.some((label: string) => {
    const labelLengthExceedsThreshold = label.length > labelRotationThreshold;
    const hasMixedContent = /[a-zA-Z]/.test(label) && /\d/.test(label);
    return labelLengthExceedsThreshold || hasMixedContent;
  });

const maxLabelLength = Math.max(...barChartJson?.data.map((item: { [x: string]: string | any[]; }) => item[barChartJson?.indexBy].length));

// Define a base offset
const baseOffset = 12;

// Estimate additional offset needed if labels are rotated (you may need to adjust these constants)
const additionalOffset = shouldRotateLabels ? maxLabelLength * 6 : 0;

const baseMarginBottom = 50; // Base bottom margin, you might need to increase this
const marginBottom = baseMarginBottom + additionalOffset;   


const truncateLabel = (label: string) => {
  const maxLength = 5; // Maximum number of characters to show before truncating
  // Truncate the label if it's too long, regardless of content
  return label.length > maxLength ? `${label.substring(0, maxLength)}...` : label;
};



const renderTick = (tick: any) => {
  const { x, y, value } = tick;
  const isRotated = shouldRotateLabels;

  // Use truncateLabel function to handle tick value
  const truncatedValue = truncateLabel(value);

  return (
    <g transform={`translate(${x},${y})`}>
      <text
        textAnchor={isRotated ? "end" : "middle"}
        dominantBaseline="middle"
        style={{
          fill: "#333333",
          fontSize: "12px",
        }}
        transform={`translate(${isRotated ? -5 : 0},${isRotated ? 10 : 0}) rotate(${isRotated ? -45 : 0})`}
      >
        {truncatedValue}
        <title>{value}</title> {/* SVG title tag for tooltip */}
      </text>
    </g>
  );
};


const calculateBottomMarginIncludingLegend = (data: any[]): number => {
  
  // Determine the width of your axis legend text here, similar to how you measure text width for the labels
  const axisLegendWidth = 10; // This is an example value, calculate based on your actual legend text
  const padding = 10; // Additional padding to ensure the legend doesn't touch the axis

  if (maxLabelLength > 16) { // Arbitrary length threshold
    return 200 + axisLegendWidth + padding; // Larger bottom margin for long labels
  } else {
    return 80 + axisLegendWidth + padding; // Default bottom margin for short labels
  }
};
const additionalOffsetbottom = -15;


const chartWidth = barChartJson?.data?.length >= 10
  ? `${50 * barChartJson?.data?.length}px`
  : "200%";
const minWidth = "300px";



  return (
    <div
    key="BarChartWrapper"
  
    style={{
        height: "100%", 
        width: "100%", 
        overflow: "hidden", 
        overflowY:"hidden",
      }}
     
   
  >
  
     <div
  className={BarChartScrollClassName}
  key="BarChart01"
  style={{
    height: "100%",
    width: "100%", // Ensure the chart stretches to the full width and height of its container
    overflowX: "hidden", // Hide horizontal scrollbar by default
  }}
  onMouseOver={(e) => {
    // Add scrollbars when hovered
    e.currentTarget.style.overflowX = "auto";
    e.currentTarget.style.overflowY = "auto";
  }}
  onMouseOut={(e) => {
    // Remove scrollbars when not hovered
    e.currentTarget.style.overflowX = "hidden";
    e.currentTarget.style.overflowY = "hidden";
  }}
>
      
      {props?.pageError !== "" ? (
        <div
          key="TAB101"
          style={{
            display: "flex",
            height: "300px",
            width: "100%",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <img
            style={{
              height: "auto",
              width: "300px",
            }}
            src={Images.no_data_img}
          />
        </div>
      ) : (
        <div
          key="CHART102"
          style={{
  

          width: chartWidth, // Use the calculated chart width
      minWidth: minWidth, // Ensure the chart has a minimum width
      height: "100%", //40vh
          
          overflowY:"hidden",
       


          }}
        >
          {props.name}
          <ResponsiveBar
            data={barChartJson?.data ? barChartJson?.data : []}
            keys={barChartJson?.keys ? barChartJson.keys : []}
            indexBy={barChartJson?.indexBy}
           
             margin={{ top: 50, right: 130, bottom: shouldRotateLabels ? baseOffset + additionalOffset + -20 : 20, left: 60 }}
            padding={0.3}
            layout="vertical"
            enableGridY={true}
            enableGridX={false}
            valueScale={{ type: "linear" }}
            indexScale={{ type: "band", round: true }}
            
            colors={{ scheme: "nivo" }}
           
            axisTop={null}
            axisRight={null}
          
         
            axisBottom={{
              tickSize: 5,
              tickPadding: 5,
             
              tickRotation: shouldRotateLabels ? -45 : 0,
              legend: barChartJson?.axis_bottom_legend,
              legendPosition: "middle",
              legendOffset: calculateBottomMarginIncludingLegend(barChartJson?.data) / 2 + additionalOffsetbottom,
              renderTick: renderTick,
            }}
            axisLeft={{
              tickSize: 0,
              tickPadding: 0,
              tickRotation: 0,
              legend: barChartJson?.axis_left_legend,
              legendPosition: "middle",
              legendOffset: -56,
              renderTick: CustomLeftTick,
            }}
            labelSkipWidth={12}
            labelSkipHeight={12}
           
            // legends={[
            //   {
            //     dataFrom: "keys",
            //     anchor: "bottom-right", // Adjust anchor to move legend
            //     direction: "row",
            //     justify: false,
            //     translateX: -350, // Adjust this to move legend left or right
            //     translateY: -320, // Adjust this to move legend up or down
            //     itemsSpacing: 2,
            //     itemWidth: 100,
            //     itemHeight: 20,
            //     itemDirection: "left-to-right",
            //     itemOpacity: 0.85,
            //     symbolSize: 20,
            //     // ... other props ...
            //   },
            // ]}
            role="application"
            ariaLabel="Nivo bar chart demo"
            barAriaLabel={(e) =>
              e.id + ": " + e.formattedValue + " in country: " + e.indexValue
            }
          />
        </div>
      )}
    </div>
  </div>
  );
});

export default BarChartForDashboard;