//@ts-nocheck
import { React, useState, useEffect, useRef  } from "../../../../app.module";
import { ResponsiveBump } from "@nivo/bump";
import { useStyle } from "./ScatterPlotStyle";
import { Colors, Images } from "../../../constants";
interface AxisBottom {
  orient: any;
  tickSize: any;
  tickPadding: any;
  tickRotation: any;
  legend: any;
  legendPosition: any;
  legendOffset: any;
}

const Bump = React.memo((props: any) => {
  const [bumpJson, setBumpJson] = useState<any>([]);
  const [isLoading, setIsLoading] = useState(true);
  const [activeTab, setActiveTab] = React.useState(0);
  const { classes } = useStyle({ isYaxis: false, isXaxis: true, activeTab });
  const [isScrolling, setIsScrolling] = useState(false);

  const [bottomLegend, setBottomLegend] = useState("");
  const [leftLegend, setLeftLegend] = useState("");


  const graphContainerRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    let { data } = props.data;
    console.log("Bump Bump CHART", props.data);
   
      setBumpJson(data);

    if (graphContainerRef.current) {
      const { scrollHeight, clientHeight } = graphContainerRef.current;
      const scrollPosition = scrollHeight - clientHeight;
      graphContainerRef.current.scrollTop = scrollPosition;
    }

  }, []);

  useEffect(() => {
    let { data, axis_bottom_legend, axis_left_legend } = props.data;
    const bottomLegend = axis_bottom_legend;
    const leftLegend = axis_left_legend;
  
    setBumpJson(data);
    setBottomLegend(bottomLegend);
    setLeftLegend(leftLegend);
  }, [props.data]);
  let scrollTimeout: NodeJS.Timeout | undefined;
  
  const handleMouseMove = () => {
    setIsScrolling(true);
    if (scrollTimeout) clearTimeout(scrollTimeout);
  };
  
  const handleMouseLeave = () => {
    if (scrollTimeout) clearTimeout(scrollTimeout);
    scrollTimeout = setTimeout(() => setIsScrolling(false), 1000) as NodeJS.Timeout;
  };

  const isStringIndexForAreaBump = () => {
    const data = bumpJson;
  
    if (data && data.length > 0 && data[0].data && data[0].data.length > 0) {
      return typeof data[0].data[0].x === 'string';
    }
  
    return false;
  };

  const theme = {
    
    axis: {
      ticks: {
        text: {
          fontSize: 11, 
        }
      }
    },

  };

const calculateLeftMarginIncludingLegend = (data: Series[]): number => {
  const axisLegendWidth = 50; 
  const padding = 20;
  return axisLegendWidth + padding;

};

const leftMarginForLegend = calculateLeftMarginIncludingLegend(bumpJson);

const additionalOffset = 50;

const calculateBottomMarginIncludingLegend = (data: Series[]): number => {
    const axisLegendWidth = 10; 
    const padding = 10; 
    const maxLabelLength = data.reduce((acc, series) => {
      const maxLengthInSeries = Math.max(...series.data.map((point: { x: { toString: () => string; }; }) => point.x.toString().length));
      return Math.max(maxLengthInSeries, acc);
    }, 0);
    if (maxLabelLength > 16) { 
      return 200 + axisLegendWidth + padding; 
    } else {
      return 80 + axisLegendWidth + padding; 
    }
};
const bottomMarginForLegend = calculateBottomMarginIncludingLegend(bumpJson);

const additionalOffsetbottom = 10;

console.log("legendleft",bumpJson)


  return (
    <div
      key="CHART101"
      ref={graphContainerRef}
      style={{
        backgroundColor: Colors?.background.backgroundColorTheme3,
        height: "87vh",
        width: "75%",
        // overflowX: "auto",
        overflowX: isScrolling ? "auto" : "hidden",
        overflowY: isScrolling ? "auto" : "hidden",
        boxShadow: "0px 0px 4px 0px #00000040",
        borderRadius: "10px 0px 0px 10px",
        overflow: "scroll",
        top: 0,
      }}
      onMouseMove={handleMouseMove}
      onMouseLeave={handleMouseLeave}
    >
     
     <div style={{display:"flex",justifyItems:"right",marginLeft:"10px",marginTop:"10px"}}>
        Datasource name :
        <label>
          {props?.dbsource}
        </label> 
      </div>
      {props?.pageError !== "" ? (
        <div
          key="TAB101"
          style={{
            display: "flex",
            height: "87vh",
            width: "100%",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <img
            style={{
              height: "auto",
              width: "300px",
            }}
            src={Images.no_data_img}
          />
        </div>
      ) : (
        <div
          key="CHART1005"
          style={{
            width:
              bumpJson[0]?.data?.length > 10
                ? `${100 * bumpJson[0]?.data?.length}px`
                : "100%",
            height: "120vh",
          }}
        >
          <ResponsiveBump
            data={bumpJson}
            colors={{ scheme: "spectral" }}
            lineWidth={3}
            activeLineWidth={6}
            inactiveLineWidth={3}
            inactiveOpacity={0.15}
            axisTop={null}
            pointSize={10}
            theme={theme}
            activePointSize={16}
            inactivePointSize={0}
            pointColor={{ theme: "background" }}
            pointBorderWidth={3}
            activePointBorderWidth={3}
            pointBorderColor={{ from: "serie.color" }}
        
            axisLeft={{
              tickSize: 5,
              tickPadding: 5,
              tickRotation: 0,
              legend:  leftLegend,
              legendPosition: "middle",
              legendOffset: -calculateLeftMarginIncludingLegend(bumpJson) / 2 - additionalOffset,
            }}

            margin={{
              top: 50,
              right: 50,
              left: calculateLeftMarginIncludingLegend(bumpJson) + additionalOffset,
              bottom: calculateBottomMarginIncludingLegend(bumpJson) + additionalOffsetbottom, 
            }}
            axisBottom={{
              tickSize: 5,
              tickPadding: 5,
              tickRotation: isStringIndexForAreaBump() ? -45 : 0,
              legend:  bottomLegend,
              legendPosition: 'middle', 
              legendOffset: calculateBottomMarginIncludingLegend(bumpJson) / 2 + additionalOffsetbottom
            }}
            

            axisRight={null}
          />
        </div>
      )}
    </div>
  );
});

export default Bump;

