import { React, useState, useEffect } from "../../../../app.module";
import { ResponsiveLine } from "@nivo/line";
import { useStyle } from "./DashboardBarChartStyle";
import { Colors, Images } from "../../../constants";




const LineChartForDashboard = React.memo((props: any) => {
  const [lineChartJson, setLineChartJson] = useState<any>([]);
  const [isLoading, setIsLoading] = useState(true);
  const [activeTab, setActiveTab] = React.useState(0);
  const { classes } = useStyle({ isYaxis: false, isXaxis: true, activeTab });
  const [shouldRotateLabels, setShouldRotateLabels] = useState(false);


  useEffect(() => {
    let bdata = props.data;
    setLineChartJson(bdata);

    // Determine if any label exceeds a certain length
    const maxLabelLength = Math.max(...bdata.map((item: any) => item.id.length));
    const threshold = 5; // for example, rotate labels if they are longer than 10 characters
    if (maxLabelLength > threshold) {
      setShouldRotateLabels(true);
    }
  }, [props.data]);

  const chartWidth = lineChartJson?.data?.length >= 10
  ? `${50 * lineChartJson?.data?.length}px`
  : "500%";
const minWidth = "300px";


const truncateLabel = (label: string) => {
  const maxLength = 10; // Maximum number of characters to show before truncating
  if (label.length > maxLength) {
    return `${label.substring(0, maxLength)}...`; // Truncate the label
  }
  return label;
};
const renderTick = (tick: any) => {
  const { x, y, value } = tick;
  const isRotated = shouldRotateLabels;

  return (
    <g transform={`translate(${x},${y})`}>
      <text
        textAnchor={isRotated ? "end" : "middle"}
        dominantBaseline="middle"
        style={{
          fill: "#333333",
          fontSize: "12px",
        }}
        transform={`translate(${isRotated ? -5 : 0},${isRotated ? 10 : 0}) rotate(${isRotated ? -45 : 0})`}
      >
        {truncateLabel(value)}
        <title>{value}</title> {/* SVG title tag for tooltip */}
      </text>
    </g>
  );
};

// Custom left axis tick component
// Define a type for the tick parameter
// Define a type for the tick parameter
type Tick = {
  value: string | number;
  x: number;
  y: number;
};

// Custom left axis tick component
const CustomLeftTick = (tick: Tick) => {
  // Convert the value to string in case it's a number
  const labelValue = tick.value.toString();
  const MAX_LENGTH = 5; // Maximum number of characters before truncating
  const label = labelValue.length > MAX_LENGTH ? `${labelValue.substring(0, MAX_LENGTH)}...` : labelValue;

  return (
    <g transform={`translate(${tick.x - 20},${tick.y})`}>
      <text
        textAnchor="end"
        dominantBaseline="middle"
        style={{
          fill: '#333',
          fontSize: '12px',
        }}
      >
        <title>{labelValue}</title> {/* Tooltip for the full label */}
        {label}
      </text>
    </g>
  );
};

useEffect(() => {
  // Inject custom scrollbar styles
  const style = document.createElement('style');
  style.type = 'text/css';
  style.innerHTML = `
    .LineChartScroll::-webkit-scrollbar {
      width: 12px;
      height: 12px;
      background-color: transparent; /* make scrollbar background transparent */
    }
    .LineChartScroll:hover::-webkit-scrollbar-thumb {
      border-radius: 10px;
      -webkit-box-shadow: inset 0 0 6px rgba(0,0,0,.3);
      background-color: #D3D3D3; /* only show scrollbar thumb on hover */
    }
  `;
  document.head.appendChild(style);

  // Ensure that we remove the style on cleanup
  return () => {
    document.head.removeChild(style);
  };
}, []);

const lineChartScrollClassName = "LineChartScroll";

const chartMargin = {
  top: 30,
  right: 110,
  bottom: shouldRotateLabels ? 80 : 80, // Increase bottom margin if labels are rotated
  left: 80,
};

  return (
    <div
    key="LineChartWrapper"
  
    style={{
        height: "100%", 
        width: "100%", 
        overflow: "hidden", 
        overflowY:"hidden",
      }}
   
  >
    
    <div
  className={lineChartScrollClassName}
  key="LineChart01"
  style={{
    height: "100%",
    width: "100%", // Ensure the chart stretches to the full width and height of its container
    overflowX: "hidden", // Hide horizontal scrollbar by default
  }}
  onMouseOver={(e) => {
    // Add scrollbars when hovered
    e.currentTarget.style.overflowX = "auto";
    e.currentTarget.style.overflowY = "auto";
  }}
  onMouseOut={(e) => {
    // Remove scrollbars when not hovered
    e.currentTarget.style.overflowX = "hidden";
    e.currentTarget.style.overflowY = "hidden";
  }}
>
      {props?.pageError !== "" ? (
        <div
          key="TAB101"
          style={{
            display: "flex",
            height: "300px",
            width: "100%",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <img
            style={{
              height: "auto",
              width: "300px",
            }}
            src={Images.no_data_img}
          />
        </div>
      ) : (
       
        <div
          key="CHART102"
          style={{
        

          width: chartWidth, // Use the calculated chart width
      minWidth: minWidth, // Ensure the chart has a minimum width
      height: "100%", //40vh
          
          overflowY:"hidden",
       


          }}
        >
          {props.name}
          <ResponsiveLine
            data={
              lineChartJson
              // lineChartJson?.data ? lineChartJson?.data : []
            }
            margin={chartMargin}
            // margin={{ top: 30, right: 110, bottom: 70, left: 80 }}
            xScale={{ type: "point" }}
            yScale={{
              type: "linear",
              min: "auto",
              max: "auto",
              stacked: true,
              reverse: false,
            }}
            yFormat=" >-.2f"
            axisTop={null}
            axisRight={null}
            axisBottom={{
              tickSize: 5,
              tickPadding: 5,
              // tickRotation: 0,
              tickRotation: shouldRotateLabels ? -45 : 0,
              legend: lineChartJson[0]?.axis_bottom_legend
                ? lineChartJson[0]?.axis_bottom_legend
                : "",
              // legendOffset: 60,
              legendOffset: shouldRotateLabels ? 70 : 60,
              legendPosition: "middle",
              renderTick: renderTick,
            }}
            axisLeft={{
              tickSize: 5,
              tickPadding: 0,
              tickRotation: 0,
              legend: lineChartJson[0]?.axis_left_legend
                ? lineChartJson[0]?.axis_left_legend
                : "",
              // legendOffset: -65,
              legendOffset: shouldRotateLabels ? -75 : 60,
              legendPosition: "middle",
              renderTick: CustomLeftTick,
              
            }}
            pointSize={10}
            pointColor={{ theme: "background" }}
            pointBorderWidth={2}
            pointBorderColor={{ from: "serieColor" }}
            pointLabelYOffset={-12}
            useMesh={true}
            // legends={[
            //   {
            //     anchor: "bottom-right",
            //     direction: "column",
            //     justify: false,
            //     translateX: 100,
            //     translateY: 0,
            //     itemsSpacing: 0,
            //     itemDirection: "left-to-right",
            //     itemWidth: 80,
            //     itemHeight: 20,
            //     itemOpacity: 0.75,
            //     symbolSize: 12,
            //     symbolShape: "circle",
            //     symbolBorderColor: "rgba(0, 0, 0, .5)",
            //     effects: [
            //       {
            //         on: "hover",
            //         style: {
            //           itemBackground: "rgba(0, 0, 0, .03)",
            //           itemOpacity: 1,
            //         },
            //       },
            //     ],
            //   },
            // ]}
          />
        </div>
      )}
    </div>
    </div>
  );
});

export default LineChartForDashboard;
