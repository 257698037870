import React, { useState } from 'react';

interface OptionsComponentProps {
    selectOption: (option: string) => void;
}

const OptionsComponent: React.FC<OptionsComponentProps> = ({ selectOption }) => {
    const [selectedOption, setSelectedOption] = useState<string | null>(null);
    const [hoverIndex, setHoverIndex] = useState<number | null>(null);

    const buttonContainerStyle: React.CSSProperties = {
        display: 'flex',
        marginLeft: '45px',
    };

    const defaultButtonStyle: React.CSSProperties = {
        padding: '10px 20px',
        borderRadius: '20px',
        color: '#ffffff',
        fontSize: '16px',
        marginLeft: '25px',
        cursor: 'pointer',
        width: '175px',
        height: 'auto',
        border: 'none',
        background: '#5B2EA1',
        boxShadow: '0px 2px 5px rgba(0, 0, 0, 0.2)',
        transition: 'transform 0.2s ease-in-out',
    };

    const hoverButtonStyle: React.CSSProperties = {
        ...defaultButtonStyle,
        transform: 'scale(1.05)',
    };

    const disabledButtonStyle: React.CSSProperties = {
        ...defaultButtonStyle,
        opacity: 0.2,
        cursor: 'not-allowed',
        background: '#grey',
    };

    const selectedButtonStyle: React.CSSProperties = {
        ...defaultButtonStyle,
        background: '#5B2EA1',
        transform: 'scale(1.1)',
    };

    const handleButtonClick = (option: string) => {
        if (selectedOption === option) {
        } else {
            setSelectedOption(option);
            selectOption(option);
        }
    };
    const getButtonStyle = (option: string, index: number): React.CSSProperties => {
        if (option === selectedOption) {
            return selectedButtonStyle;
        } else if (hoverIndex === index) {
            return hoverButtonStyle;
        } else if (selectedOption !== null) {
            return disabledButtonStyle;
        }
        return defaultButtonStyle;
    };

    return (
        <div style={buttonContainerStyle}>
            <button
                style={getButtonStyle('general', 0)}
                onMouseEnter={() => setHoverIndex(0)}
                onMouseLeave={() => setHoverIndex(null)}
                onClick={() => handleButtonClick('general')}
                disabled={selectedOption !== null && selectedOption !== 'general'}
            >
                Generic Search
            </button>
            <button
                style={getButtonStyle('datasource', 1)}
                onMouseEnter={() => setHoverIndex(1)}
                onMouseLeave={() => setHoverIndex(null)}
                onClick={() => handleButtonClick('datasource')}
                disabled={selectedOption !== null && selectedOption !== 'datasource'}
            >
                Datasource Search
            </button>
        </div>
    );
};

export default OptionsComponent;


