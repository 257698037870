import { Route, Routes } from "../../app.module";
import { CustomDrawer } from "../components";
const AppNavigation = () => {
  return (
    <>
      <Routes>
        <Route path="/*" element={<CustomDrawer />} />
      </Routes>
    </>
  );
};

export default AppNavigation;
