import {
  styled,
  Button,
  TextareaAutosize,
  makeStyles,
  TextField,
} from "../../../app.module";
import { Fonts, Colors } from "../../constants";

export const useProjectStyle = (userRole: any) =>
  makeStyles()((theme?: any) => {
    const isCompanyOrProjectAdmin = ["Company Admin", "Project Admin"].includes(
      userRole
    );
    return {
      parent: {
        height: "80%",
        scrollbar: "none",
        scrollbarWidth: "none",
        "-ms-overflow-style": "none",
        "&::-webkit-scrollbar": {
          width: "0",
          height: "0",
        },
      },
      datahead: {
        fontWeight: 700,
        fontSize: 20,
        marginLeft: "30px",
        marginTop: isCompanyOrProjectAdmin ? "-12px" : "8px",
        marginBottom: isCompanyOrProjectAdmin ? "-10px" : "11px",
        display: "flex",
        alignItems: "center",
        justifyContent: "space-between",
      },
      DbContainer: {
        scrollbarWidth: "none",
        "&::-webkit-scrollbar": {
          width: "0.5rem",
        },
        "&::-webkit-scrollbar-track": {
          backgroundColor: "transparent",
        },
        "&::-webkit-scrollbar-thumb": {
          backgroundColor: "transparent",
        },
      },
      forproject: {
        height: "42vh",
        overflowX: "hidden",
        scrollbarWidth: "none",
        "-ms-overflow-style": "none",
        "&::-webkit-scrollbar": {
          width: "0.5em",
          height: "0.5em",
          opacity: 0,
          transition: "opacity 0.2s",
        },
        "&:hover ::-webkit-scrollbar": {
          opacity: 1,
        },
      },
      fordatabase: {
        height: "42vh",
        overflowX: "hidden",
        scrollbarWidth: "none",
        "-ms-overflow-style": "none",
        "&::-webkit-scrollbar": {
          width: "0",
          height: "0",
        },
      },
      middle: {
        height: "4vh",
        backgroundColor: "#F6F8FF",
      },
      selectedRow: {
        backgroundColor: "#cce6ff",
      },
      createProjectBoxContainer: {
        position: "absolute" as "absolute",
        top: "50%",
        left: "50%",
        transform: "translate(-50%, -50%)",
        width: "100%",
        boxShadow: "24px",
        p: 4,
        padding: "10%",
        outline: "none",
      },
      searchInput: {
        fontFamily: theme.typography.fontFamily,
        fontStyle: "normal",
        fontWeight: 400,
        fontSize: "16.6957px",
        lineHeight: "19px",
        color: theme.palette.text.primary,
      },
      updateProjectBoxContainer: {
        position: "absolute" as "absolute",
        top: "50%",
        left: "50%",
        transform: "translate(-50%, -50%)",
        width: "100%",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        boxShadow: "24px",
        p: 4,
        padding: "10%",
        outline: "none",
      },
      asterix: {
        color: "red",
      },
      projectCicularView: {
        color: Colors?.main,
        marginRight: "5px",
      },
      projectLeftGrid: {
        backgroundColor: "#fff",
        display: "flex",
        flex: 1,
        flexDirection: "column",
        justifyContent: "flex-start",
        alignItems: "flex-start",
        paddingTop: "70px",
        paddingLeft: "60px",
      },
      checkedProkectImgStyle: {
        maxWidth: "20px",
        height: "20px",
        padding: "1px",
        marginRight: "15px",
        marginTop: "2px",
      },
      leftProjectPointsContainer: {
        display: "flex",
        flex: 1,
        flexDirection: "row",
      },
      leftProjectPoints: {
        display: "flex",
        justifyContent: "flex-start",
        alignItems: "flex-start",
        flex: 0.1,
        flexDirection: "row",
      },
      twoFieldsContainer: {
        display: "flex",
        flex: 1,
        justifyContent: "space-between",
      },
      testConnContainer: {
        display: "flex",
        flex: 2,
        alignItems: "center",
        marginBottom: "4px",
      },

      textConLabelStyle: {
        fontFamily: Fonts.NunitoSans.regular,
        fontStyle: "normal",
        fontWeight: 400,
        fontSize: "10px",
        lineHeight: "15px",
        color: Colors?.main,
      },
      errorColor: {
        color: Colors?.palette?.error?.main,
        marginRight: "5px",
      },
      successColor: {
        color: Colors?.palette?.success?.main,
        marginRight: "5px",
      },
      testConFailLabelStyle: {
        fontFamily: Fonts.NunitoSans.regular,
        fontStyle: "normal",
        fontWeight: 400,
        fontSize: "10px",
        lineHeight: "15px",
        color: Colors?.main,
      },
      textFiledLabelContainer: {
        display: "flex",

        marginRight: "20px",
        flex: 1,
        flexDirection: "column",
        marginBottom: "10px",
      },
      textFiledProviderContainer: {
        display: "flex",
        flex: 0.5,
        flexDirection: "column",
        marginBottom: "10px",
      },
      singleItem: {
        marginRight: 0,
      },
      createProjectLabelContainer: {
        height: "420px",
      },
      updateProjectLabelContainer: {
        height: "420px",
        width: "400px",
        marginLeft: "50px",
      },
      startedProjectLabelContainer: {
        height: "1px",
      },
      projectThanksStyle: {
        fontFamily: Fonts.Inter.regular,
        fontStyle: "normal",
        fontWeight: "400",
        fontSize: "22px",
        lineHeight: "29px",
        color: "#000000",
        width: "90%",
      },
      projectCreatedLabelStyle: {
        fontFamily: "'Nunito Sans'",
        fontStyle: "normal",
        fontWeight: "700",
        fontSize: "36px",
        lineHeight: "49px",
        color: "#000000",
      },
      projectLabelStyle: {
        fontFamily: Fonts.NunitoSans.regular,
        fontStyle: "normal",
        fontWeight: 700,
        fontSize: "18px",
        lineHeight: "25px",
        color: "#000",
        marginBottom: "10px",
      },
      projectLeftLabelStyle: {
        fontFamily: Fonts.NunitoSans.regular,
        fontStyle: "normal",
        fontWeight: 700,
        fontSize: "18px",
        lineHeight: "25px",
        color: "#000",
        marginBottom: "10px",
      },
      testBtnLabel: {
        fontFamily: Fonts.NunitoSans.regular,
        fontStyle: "normal",
        fontWeight: 700,
        fontSize: "18px",
        lineHeight: "25px",
        color: "#fff",
        marginBottom: "10px",
      },
      proInput: {
        backgroundColor: "#f8faff",
        fontFamily: Fonts.NunitoSans.regular,
        fontStyle: "normal",
        fontWeight: "400",
        fontSize: "18px",
        lineHeight: "21px",
        borderRadius: "10px",
      },
      proTextareaInput: {
        backgroundColor: "#f8faff",
        fontFamily: Fonts.NunitoSans.regular,
        fontStyle: "normal",
        fontWeight: "400",
        fontSize: "18px",
        lineHeight: "21px",
        padding: "10px",
        borderColor: "#f9f9f9",
        borderRadius: "10px",
        color: "#000000",
        overflow: "scroll",
        maxHeight: "130px",
        minHeight: "130px",

        "&::placeholder": {
          color: "#ADADAD",
        },

        "&::-webkit-scrollbar": {
          display: "none",
        },
        "&:hover": {
          outline: "none",
          borderColor: Colors?.main,
          borderWidth: "1px",
        },

        "&:focus": {
          outline: "none",
          borderColor: Colors?.main,
          borderWidth: "1px",
        },
      },
      txtLabelProjectContainerRight: {
        display: "flex",
        flex: 1,
        flexDirection: "column",
      },
      testDbConnContainerRight: {
        display: "flex",
        flex: 3,
        flexDirection: "column",
      },
      portNumberContainerRight: {
        display: "flex",
        flex: 0.5,
        flexDirection: "column",
      },
      btnProjectTestContainer: {
        display: "flex",
        justifyContent: "center",
      },
      marginNewPro: {
        marginRight: 0,
        marginTop: "20px",
      },
      btnProjectTestLabelStyle: {
        fontFamily: Fonts.NunitoSans.regular,
        fontStyle: "normal",
        fontWeight: 700,
        fontSize: "14px",
        lineHeight: "25px",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        backgroundColor: "#fff",
        color: "#000",
        textTransform: "capitalize",
        height: "36px",
        width: "136px",
        borderRadius: "5px",
        marginRight: "15px",
        marginBottom: "20px",
        "&:hover": {
          background: "#fff",
        },
        "&:focus": {
          background: "#fff",
        },
        "&:disabled": {
          backgroundColor: "#ecedf2",
        },
      },
      btnDatasourceTestLabelStyle: {
        fontFamily: '"Nunito Sans", sans-serif', // Ensure the font is wrapped in quotes and has a generic font family fallback
        fontStyle: "normal",
        fontWeight: 700,
        fontSize: "14px",
        lineHeight: "25px",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        backgroundColor: "#fff",
        color: "#000",
        textTransform: "capitalize",
        height: "40px",
        width: "146px",
        borderRadius: "5px",
        marginRight: "15px",
        marginBottom: "20px",
        "-webkit-box-align": "center", // Safari uses a different property for flex alignment
        "-webkit-justify-content": "center", // Same as above for justify-content
        "-webkit-box-pack": "center", // Same as above for flex-pack
        "&:hover": {
          background: "#fff",
        },
        "&:focus": {
          background: "#fff",
        },
        "&:disabled": {
          backgroundColor: "#ecedf2",
        },
      },

      projectCancelBtnLabelStyle: {
        fontFamily: Fonts.NunitoSans.regular,
        fontStyle: "normal",
        fontWeight: 700,
        fontSize: "18px",
        lineHeight: "25px",
        backgroundCo: "#fff",
        color: "#000",
        textTransform: "capitalize",
        height: "57px",
        width: "155px",
        borderRadius: "5px",
        marginRight: "15px",
        marginBottom: "20px",
        marginLeft: "35px",
        "&:hover": {
          background: "#fff",
        },
        "&:focus": {
          background: "#fff",
        },
      },
      threebtns: {
        fontFamily: Fonts.NunitoSans.regular,
        fontStyle: "normal",
        fontWeight: 700,
        fontSize: "18px",
        lineHeight: "25px",
        backgroundCo: "#fff",
        color: "#000",
        textTransform: "capitalize",
        height: "50px",
        width: "130px",
        marginBottom: "20px",
        "&:hover": {
          background: "#fff",
        },
        "&:focus": {
          background: "#fff",
        },
      },
      textbx: {
        fontFamily: "Fonts.NunitoSans.regular",
        fontStyle: "normal",
        fontWeight: 400,
        fontSize: "12px",
        lineHeight: "20px",
        backgroundColor: "#fff",
        color: "#000",
        textTransform: "capitalize",
        height: "24px",
        width: "247px",
        position: "relative",
        left: "0px",
        marginBottom: "30px",
        padding: "0px",
        "&:hover": {
          background: "#fff",
        },
        "&:focus": {
          background: "#fff",
        },
      },

      horizontal: {
        marginRight: "250px",
        marginTop: "10px",
        fontWeight: "bold",
        alignContent: "center",
        fontSize: 25,
      },
      nextProBtn: {
        background:
          "linear-gradient(90deg, #5EB0FA 0%, #1157CD 50.54%, #9C90FA 100%)",
        fontFamily: Fonts.NunitoSans.regular,
        fontStyle: "normal",
        fontWeight: 700,
        fontSize: "18px",
        lineHeight: "25px",
        borderRadius: "5px",
        textTransform: "capitalize",
        height: "57px",
        width: "155px",
        marginBottom: "20px",
        "&:disabled": {
          background: "#ecedf2",
          color: "#cccdd2",
        },
      },

      projectSelectFlex: {
        flex: 2,
      },
      enabledButton: {
        background:
          "linear-gradient(90deg, #5EB0FA 0%, #1157CD 50.54%, #9C90FA 100%)",
        fontFamily: Fonts.NunitoSans.regular,
        fontStyle: "normal",
        fontWeight: 700,
        fontSize: "18px",
        lineHeight: "25px",
        borderRadius: "5px",
        textTransform: "capitalize",
        height: "57px",
        width: "155px",
        marginBottom: "20px",
        "&:hover": {
          background:
            "linear-gradient(90deg, #5EB0FA 0%, #1157CD 50.54%, #9C90FA 100%)",
        },
      },
      disabledButton: {
        background: "gray",
        color: "#fff",
        fontFamily: Fonts.NunitoSans.regular,
        fontStyle: "normal",
        fontWeight: 700,
        fontSize: "18px",
        lineHeight: "25px",
        borderRadius: "5px",
        textTransform: "capitalize",
        height: "57px",
        width: "155px",
        marginBottom: "20px",
        cursor: "not-allowed",
      },
      projectSelect: {
        backgroundColor: "#f8faff",
        fontFamily: Fonts.NunitoSans.regular,
        fontStyle: "normal",
        fontWeight: "400",
        fontSize: "18px",
        lineHeight: "21px",
        borderRadius: "10px",
        marginBottom: "10px",

        ".MuiOutlinedInput-notchedOutline": {
          border: "1px solid #f9f9f9",
        },
        "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
          border: `1px solid ${Colors?.main}`,
        },
        "&:hover .MuiOutlinedInput-notchedOutline": {
          border: `1px solid ${Colors?.main}`,
        },
      },
      projectSelectdisabled: {
        fontFamily: Fonts.NunitoSans.regular,
        fontStyle: "normal",
        fontWeight: "400",
        fontSize: "18px",
        lineHeight: "21px",
        color: "#ADADAD",
      },
      projectMenuitem: {
        fontFamily: Fonts.NunitoSans.regular,
        fontStyle: "normal",
        fontWeight: "400",
        fontSize: "18px",
        lineHeight: "21px",
        color: "#000",
      },
      projectMenuitemhidden: {
        display: "none",
        fontFamily: Fonts.NunitoSans.regular,
        fontStyle: "normal",
        fontWeight: "400",
        fontSize: "18px",
        lineHeight: "21px",
        color: "#ADADAD",
      },
      prjDropdownErrorStyle: {
        fontFamily: Fonts.NunitoSans.regular,
        fontStyle: "normal",
        fontWeight: 400,
        fontSize: "12px",
        lineHeight: "19px",
        color: Colors?.palette.error.main,
        marginLeft: "15px",
        marginTop: "3px",
        display: "flex",
      },
      proBtncontainer: {
        display: "flex",
        justifyContent: "flex-end",
        alignItems: "flex-end",
      },
      columnDeleteBtncontainer: {
        display: "flex",
        flexDirection: "row",
        justifyContent: "center",
        marginTop: "10px",
      },
      columnDeleteBtnYes: {
        background:
          "linear-gradient(90deg, #5EB0FA 0%, #1157CD 50.54%, #9C90FA 100%)",
        color: "#fff",
        fontFamily: Fonts.NunitoSans.regular,
        fontStyle: "normal",
        fontWeight: 700,
        fontSize: "14px",
        lineHeight: "25px",
        marginLeft: "15px",
        textTransform: "capitalize",
        "&:hover": {
          background: "#9ecffc",
        },
        "&:focus": {
          background: "#9ecffc",
        },
      },
      columnDeleteBtnLabelStyle: {
        fontFamily: Fonts.NunitoSans.regular,
        fontStyle: "normal",
        fontWeight: 700,
        fontSize: "14px",
        lineHeight: "25px",
        backgroundColor: "#fff",
        color: "#000",
        textTransform: "capitalize",
        "&:hover": {
          background: "#fff",
        },
        "&:focus": {
          background: "#fff",
        },
      },
      testConnection: {
        fontFamily: Fonts.NunitoSans.regular,
        fontStyle: "normal",
        fontWeight: 700,
        fontSize: "18px",
        lineHeight: "25px",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        backgroundColor: "#fff",
        color: "#000",
        textTransform: "capitalize",
        height: "57px",
        width: "185px",
        borderRadius: "5px",
        marginRight: "15px",
        marginBottom: "20px",
        "&:hover": {
          background: "#fff",
        },
        "&:focus": {
          background: "#fff",
        },
        "&:disabled": {
          backgroundColor: "#ecedf2",
        },
      },
      prolist: {
        maxHeight: "400px",
        overflowY: "auto",
        "&::-webkit-scrollbar": {
          width: "0.5em",
        },
        "&::-webkit-scrollbar-thumb": {
          backgroundColor: "rgba(0,0,0,.1)",
          borderRadius: "4px",
        },
      },
      dblist: {
        maxHeight: "400px",
        overflowY: "auto",
        "&::-webkit-scrollbar": {
          width: "0.5em",
        },
        "&::-webkit-scrollbar-thumb": {
          backgroundColor: "rgba(0,0,0,.1)",
          borderRadius: "4px",
        },
      },
    };
  });

export const StyledMainProjectButton = styled(Button)({
  backgroundColor: "#fff",
  color: "#fff",
  padding: "6px 12px",
  "&:hover": {
    backgroundColor: "#fff",
  },
});
