import { SET_PATH } from "./AppActionConst";
export const PathReducers = (prevState: any, action: any) => {
  switch (action.type) {
    case SET_PATH:
      return {
        ...prevState,
        path: action.payload,
      };
  }
};
