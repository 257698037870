import React, { useEffect, useState } from 'react';
import {
  AreaChart, Area, XAxis, YAxis, CartesianGrid, Tooltip, ResponsiveContainer,
} from 'recharts';
import { Colors, Images, Fonts } from '../../../constants';


type CustomTooltipProps = {
    active?: boolean;
    payload?: any[];
    label?: string;
  };

const AreaChartComponent = React.memo((props:any) => {
    const [areaChartJson, setAreaChartJson] = useState<any>([]);
    const [isLoading, setIsLoading] = useState(true);
    const [isScrolling, setIsScrolling] = useState(false);

    const formatYAxisTick = (value: number | string) => {
      // Convert all values to string to handle both cases
      const valueStr = value.toString();
      return valueStr.length > 4 ? `${valueStr.substring(0, 4)}...` : valueStr;
  };
  
    useEffect(() => {
      if (props.data !== areaChartJson) {
        setAreaChartJson(props.data);
        setIsLoading(false); // Data is loaded, set loading to false
      }
    }, [props.data]);

  if (!areaChartJson || areaChartJson.length === 0) {
    return (
      <div style={{ display: "flex", height: "87vh", width: "75%", justifyContent: "center", alignItems: "center", backgroundColor: Colors?.background.backgroundColorTheme3, boxShadow: "0px 0px 4px 0px #00000040", borderRadius: "10px 0px 0px 10px", }}>
        <img style={{ height: "auto", width: "300px" }} src={Images.no_data_img} alt="No data" />
      </div>
    );
  }

  
  const CustomTooltip: React.FC<CustomTooltipProps> = ({ active, payload }) => {
    if (active && payload && payload.length) {
      // Extract the dynamic payload data that the tooltip will use
      const tooltipData = payload[0].payload; // This accesses the nested payload object which contains your data
  
      return (
        <div className="custom-tooltip" style={{
          backgroundColor: '#fff',
          padding: '10px',
          border: '1px solid #ccc',
          boxShadow: '0px 0px 10px 0px rgba(0, 0, 0, 0.25)',
          borderRadius: '5px',
          fontFamily: Fonts.NunitoSans.regular,
        }}>
            
          {/* Iterate over the payload object's own properties to display them */}
          {Object.keys(tooltipData).map((key, index) => (
            <p key={`item-${index}`} className="intro">
              {`${key}: ${tooltipData[key]}`}
            </p>
              ))}
         
        </div>
      );
    }
  
    return null;
  };
  
  

  const CustomTick = (props:any) => {
    const { x, y, payload } = props;


    return (
      <g transform={`translate(${x},${y})`}>
        <text
          x={0}
          y={0}
          dy={16} // Adjust this value to position your label appropriately
          textAnchor="end"
          transform="rotate(-35)" // This rotates the tick labels
        //   style={{ cursor: 'default' }} // Changes the cursor to indicate hoverable text
        style={{ 
            cursor: 'default', 
            fontFamily: Fonts.NunitoSans.regular, // Set the font family here
          }}
        >
          {/* Wrap the displayed text in a tspan to enable the tooltip */}
          <tspan>
            {payload.value.length > 5 ? `${payload.value.substring(0, 5)}...` : payload.value}
            {/* Tooltip */}
            <title>{payload.value}</title>
          </tspan>
        </text>
      </g>
    );
  };

  let scrollTimeout: NodeJS.Timeout | undefined;
  
  const handleMouseMove = () => {
    setIsScrolling(true);
    if (scrollTimeout) clearTimeout(scrollTimeout);
  };
  
  const handleMouseLeave = () => {
    if (scrollTimeout) clearTimeout(scrollTimeout);
    scrollTimeout = setTimeout(() => setIsScrolling(false), 1000) as NodeJS.Timeout;
  };

  return (
    <div
      key="CHART112"
      style={{
        backgroundColor: Colors?.background.backgroundColorTheme3,
        height: "87vh",
        width: "75%",
        // overflowY:"hidden",
        // overflowX: "scroll",
        overflowX: isScrolling ? "auto" : "hidden",
        overflowY: isScrolling ? "auto" : "hidden",
        overflow: "scroll",
        boxShadow: "0px 0px 4px 0px #00000040",
        borderRadius: "10px 0px 0px 10px",
        top: 0,
      }}
      onMouseMove={handleMouseMove}
      onMouseLeave={handleMouseLeave}
    >
      Datasource name: <label>{props?.dbsource}</label>
      <div
        key="CHART1005"
        style={{
          width: "200%",
          height: "75vh",
        }}
      >
        <ResponsiveContainer width="100%" height="100%">
          <AreaChart data={areaChartJson.data} margin={{ top: 30, right: 30, left: 0, bottom: 0 }}>
            <defs>
              <linearGradient id="colorCredit" x1="0" y1="0" x2="0" y2="1">
                <stop offset="5%" stopColor="#8884d8" stopOpacity={0.8}/>
                <stop offset="95%" stopColor="#8884d8" stopOpacity={0}/>
              </linearGradient>
            </defs>
            <CartesianGrid strokeDasharray="3 3" />
            {/* <XAxis dataKey={areaChartJson.datakey_X} /> */}
            <XAxis
                  dataKey={areaChartJson.datakey_X}
                  height={60} // Adjust height to accommodate rotated labels
                  tick={<CustomTick />}
                  
                />
           <YAxis
                tickFormatter={formatYAxisTick}
                // Add a tick component for custom rendering
                tick={({ x, y, payload }) => {
                  // Prepare the full label as a title for the tooltip on hover
                  const fullLabel = payload.value.toString();
                  return (
                    <g transform={`translate(${x},${y})`}>
                      <text  textAnchor="end">
                       {formatYAxisTick(payload.value)}
                        <title>{fullLabel}</title>
                      </text>
                    </g>
                  );
                }}
              />
            {/* <Tooltip /> */}
            <YAxis tickFormatter={formatYAxisTick} />
            <YAxis
            // tick={CustomYAxisTick} // Use the custom tick component
              tickLine={false}
              axisLine={false}
              width={70} // Adjust this width as needed to fit your labels
            />
            <Tooltip content={CustomTooltip} />
            <Area type="monotone" dataKey={areaChartJson.datakey_Y} stroke="#8884d8" fillOpacity={1} fill="url(#colorCredit)" />
          </AreaChart>
        </ResponsiveContainer>
      </div>
    </div>
  );
});



export default AreaChartComponent;

// const AreaChartComponent = (props:any) =>{
//     return (
//       <div style={{
//         backgroundColor: '#fff',
//         height: "87vh",
//         width: "80%",
//         overflowX: "auto",
//       }}>
//         <div style={{ width: "100%", height: "75vh" }}>
//           <ResponsiveContainer width="100%" height="100%">
//             <AreaChart data={data} margin={{ top: 10, right: 30, left: 0, bottom: 0 }}>
//               <defs>
//                 <linearGradient id="colorUv" x1="0" y1="0" x2="0" y2="1">
//                   <stop offset="5%" stopColor="#8884d8" stopOpacity={0.8}/>
//                   <stop offset="95%" stopColor="#8884d8" stopOpacity={0}/>
//                 </linearGradient>
//                 <linearGradient id="colorPv" x1="0" y1="0" x2="0" y2="1">
//                   <stop offset="5%" stopColor="#82ca9d" stopOpacity={0.8}/>
//                   <stop offset="95%" stopColor="#82ca9d" stopOpacity={0}/>
//                 </linearGradient>
//               </defs>
//               <CartesianGrid strokeDasharray="3 3" />
//               <XAxis dataKey="name" />
//               <YAxis />
//               <Tooltip />
//               <Area type="monotone" dataKey="uv" stroke="#8884d8" fillOpacity={1} fill="url(#colorUv)" />
//               <Area type="monotone" dataKey="pv" stroke="#82ca9d" fillOpacity={1} fill="url(#colorPv)" />
//             </AreaChart>
//           </ResponsiveContainer>
//         </div>
//       </div>
//     );
//   };
  

// export default AreaChartComponent;