import {
  makeStyles,
  Switch,
  TextField,
  styled,
  Button,
} from "../../../app.module";
import { Colors, Fonts } from "../../constants";
interface DashboardProps {
  moduleListLength: number;
}
export const StyledQueryButton = styled(Button)({
  backgroundColor: "#fff",
  color: "#fff",
  padding: "6px 12px",
  "&:hover": {
    backgroundColor: "#fff",
  },
});
export const CssQueryTextField = styled(TextField)({
  "& label.Mui-focused": {
    color: "#000",
    borderRadius: "10px",
  },
  "& .MuiInput-underline:after": {
    borderBottomColor: "red",
    borderRadius: "10px",
  },

  "& .MuiOutlinedInput-root": {
    "& fieldset": {
      borderColor: "#F9F9F9",
      borderRadius: "10px",
    },
    "&:hover fieldset": {
      border: `1px solid ${Colors?.main}`,
      borderRadius: "10px",
    },
    "&.Mui-disabled:hover fieldset": {
      border: "1px solid #F9F9F9",
      borderRadius: "10px",
    },
    "&.Mui-disabled fieldset": {
      border: "1px solid #F9F9F9",
      borderRadius: "10px",
    },
    "&.Mui-focused fieldset": {
      border: `1px solid ${Colors?.main}`,
      borderRadius: "10px",
    },
    "& .MuiInputBase-input": {
      height: "16px",
    },
  },
});
export const CssModuleTextField = styled(TextField)({
  "& label.Mui-focused": {
    color: "#000",
  },
  "& .MuiInput-underline:after": {
    borderBottomColor: "#fff",
    borderRadius: "0px",
  },
  "& .MuiOutlinedInput-root": {
    "& fieldset": {
      borderColor: "#fff",
      borderRadius: "0px",
      borderBottom: `1px solid ${Colors?.border.plan.borderColor}`,
    },
    "&:hover fieldset": {
      border: `0px solid #fff`,
      borderRadius: "0px",
    },
    "&.Mui-focused fieldset": {
      border: `0px solid #fff`,
      borderRadius: "0px",
      borderBottom: `1px solid ${Colors?.border.plan.borderColor}`,
    },
    "& .MuiInputBase-input": {
      height: "16px", 
      paddingLeft: "5px",
    },
  },
});

export const useStyle1 = makeStyles()(
  (_theme) => ({
    showGridBackgroundStyle: {
      background: "url('https://react-dashboard-demo.cube.dev/static/media/drag-background.faaee4c4.svg') 0px -4px / 100% 50px repeat-y",
    backgroundSize: 'calc(210% / 2) 90px',
    },
  })
);



export const useStyle = makeStyles<DashboardProps>()(
  (_theme, { moduleListLength }) => ({
    circularPlanContainer: {
      color: Colors?.main,
      zIndex: parseInt(`${Colors?.zIndex?.drawer}+${1}`),
    },
    generateModal: {
     backgroundColor: "rgba(255, 255, 255, 0.9)",
    },
    generateModalBtncontainer: {
      display: "flex",
      flexDirection: "row",
      justifyContent: "center",
      marginTop: "10px",
    },
    generateModalBtn: {
      background:
        "linear-gradient(90deg, #5EB0FA 0%, #1157CD 50.54%, #9C90FA 100%)",
      color: "#fff",
      fontFamily: Fonts.NunitoSans.regular,
      fontStyle: "normal",
      fontWeight: 700,
      fontSize: "14px",
      lineHeight: "25px",
      marginLeft: "15px",
      textTransform: "capitalize",
    },
    circularPlanView: {
      color: Colors?.main,
    },
    generateBtnCircularLoader: {
      color: Colors?.main,
      marginLeft: "5px",
    },
    dashBoardContainer:
      moduleListLength < 9
        ? {
            height: "100vh",
          }
        : {
            borderRadius: "10px",
            minHeight: "87vh",
            top: 0,
          },  
    dashBoardListTopLabel: {
      fontFamily: Fonts.NunitoSans.regular,
      fontStyle: "normal",
      fontWeight: "700",
      fontSize: "20px",
     lineHeight: "28px",
      color: "#fff",
      textAlign:"left",
     
    },
    closeModalImgStyle: {
      maxWidth: "30px",
      height: "30px",
      padding: "10px",
      marginRight: "10px",
      marginTop: "5px",
      borderBottom: "0px",
    },
    modalStyle: {
      display: "flex",
      alignItems: "flex-start", 
      justifyContent: "flex-start",
    overflow: "auto", // Enable scrolling
      marginTop: "-100px",
    },
    createQueryCicularView: {
      color: Colors?.main,
      marginRight: "5px",
    },
    tableModalContainer: {
      overflow: "auto",
      maxHeight: "98vh", 
      minHeight: "98vh",
      position: "absolute" as "absolute",
      top: "50%",
      left: "50%",
      transform: "translate(-50%, -50%)",
      width: "60%",
      boxShadow: "24px",
    },
    tableQueryInput: {
      backgroundColor: "#f9f9f9",
      fontFamily: Fonts.NunitoSans.regular,
      fontStyle: "normal",
      fontWeight: "400",
      fontSize: "18px",
      lineHeight: "21px",
      borderRadius: "10px", 
      color: "#000000",
      "&::placeholder": {
        color: "#ADADAD",
      },
    },

    txtLabelTableContainerRight: {
      display: "flex",
      flex: 1,
      flexDirection: "column",
   
    },
    generateBtnLabel: {
      fontFamily: Fonts.NunitoSans.regular,
      fontStyle: "normal",
      fontWeight: 700,
      fontSize: "18px",
      lineHeight: "25px",
      color: "#fff",
      marginBottom: "10px",
    },
    tableRowCntLabel: {
      fontFamily: Fonts.NunitoSans.regular,
      fontStyle: "normal",
      fontWeight: 700,
      fontSize: "18px",
      lineHeight: "25px",
      color: "#000",
      marginBottom: "10px",
    },
    queryGenerateBtnLabelStyle: {
      fontFamily: Fonts.NunitoSans.regular,
      fontStyle: "normal",
      fontWeight: 700,
      fontSize: "18px",
      lineHeight: "25px",
      backgroundCo: "#fff",
     
      color: "#000",
      textTransform: "capitalize",
      height: "47px",
      width: "182px",
      marginLeft: "13px",
      marginRight: "22px",
      marginBottom: "20px",
      "&:hover": {
        background: "#fff",
      },
      "&:focus": {
        background: "#fff",
      },
      "&:disabled": {
        background: "#ecedf2",
      },
    },
    saveTableBtn: {
      background:
        "linear-gradient(90deg, #5EB0FA 0%, #1157CD 50.54%, #9C90FA 100%)",
      color: "#fff",
      fontFamily: "Nunito Sans, sans-serif",
      fontStyle: "normal",
      fontWeight: 700,
      fontSize: "18px",
      lineHeight: "25px",
      textTransform: "capitalize",
      height: "47px",
      width: "185px",
      marginBottom: "20px",
      "&:disabled": {
        background: "#ecedf2", 
        color: "##cccdd2", 
      },
    },
    queryBtnTestContainer: {
      display: "flex",
      justifyContent: "center",
      marginRight: "18%",
    
    },
    queryBtncontainer: {
      display: "flex",
      marginTop: "20px",
      backgroundColor: "#fff",
      justifyContent: "flex-end",
      alignItems: "flex-end",
      paddingRight: "40px",
    },
    moduleTitle: {
      color: "#000",
    },
    twoTableFieldsContainer: {
      display: "flex",
      flex: 1,
      backgroundColor: "#fff",
      paddingLeft: "25px",
      justifyContent: "space-between",
    },
    textTableFiledLabelContainer: {
      display: "flex",
      marginRight: "20px",
      flex: 4,
      flexDirection: "column",
      marginBottom: "10px",
    },
    moduleFiledLabelContainer: {
      display: "flex",
      flexDirection: "row",
      justifyContent: "space-between", 
      alignItems: "center",
      marginBottom: "10px",
      width: "84%",
      flex: 1,
      marginLeft: "25px",
    
    },
    moduleInput: {
      backgroundColor: "#fff",
      fontFamily: Fonts.NunitoSans.regular,
      fontStyle: "normal",
      fontWeight: "400",
      fontSize: "18px",
      color: "#000000",
      "&::placeholder": {
        color: "#ADADAD",
      },
    },
    moduleTableLabelStyle: {
      fontFamily: Fonts.NunitoSans.regular,
      fontStyle: "normal",
      fontWeight: 700,
      fontSize: "16px",
      lineHeight: "25px",
      color: "#000",
      marginBottom: "10px",
    },
    moduleTableNoteLabelStyle: {
      fontSize: "10px",
    },
    moduleButton: {
      textTransform: "initial",
      padding: 0,
      borderRadius: "10px",
      "&:hover": {
        backgroundColor: "transparent",
      },
    },
   
    datasourceStyle: {
      backgroundColor: "#fff",
      fontFamily: Fonts.NunitoSans.regular,
      fontStyle: "normal",
      fontWeight: "400",
      fontSize: "18px",
      lineHeight: "21px",
      borderRadius: "10px",
      height: "47px",
      marginLeft: "26%",
      width: "122%",
      ".MuiOutlinedInput-notchedOutline": {
        border: "1px solid #f9f9f9",
      },
      "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
        border: `1px solid ${Colors?.main}`,
      },
      "&:hover .MuiOutlinedInput-notchedOutline": {
        border: `1px solid ${Colors?.main}`,
      },
    },  
  })
);
