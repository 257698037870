import React, { PureComponent, useEffect, useState } from 'react';
import {
  Radar, RadarChart, PolarGrid, PolarAngleAxis,
  PolarRadiusAxis, ResponsiveContainer, Tooltip
} from 'recharts';
// import { useStyle } from "./ScatterPlotStyle";
// import { Colors, Images } from '../../../constants';
import { active } from 'd3-transition';
import { Colors, Images } from '../../../constants';
// import { Tooltip } from '@mui/material';


type CustomTooltipProps = {
  active?: boolean;
  payload?: any[];
  label?: string;
};

const CustomTooltip: React.FC<CustomTooltipProps> = ({ active, payload }) => {
  if (active && payload && payload.length) {
    // Extract the dynamic payload data that the tooltip will use
    const tooltipData = payload[0].payload; // This accesses the nested payload object which contains your data

    return (
      <div className="custom-tooltip" style={{
        backgroundColor: '#fff',
        padding: '5px', // Reduce padding to make it smaller
        border: '1px solid #ccc',
        boxShadow: '0px 0px 5px 0px rgba(0, 0, 0, 0.25)', // Reduce box shadow
        borderRadius: '3px', // Reduce border radius for a sharper look
        fontSize: '12px', // Reduce font size
      }}>
        {/* Iterate over the payload object's own properties to display them */}
        {Object.keys(tooltipData).map((key, index) => (
          <p key={`item-${index}`} className="intro">
            {`${key}: ${tooltipData[key]}`}
          </p>
        ))}
      </div>
    );
  }

  return null;
};

const RadarChartForDashboard = React.memo((props: any) => {
  const [radarChartJson, setRadarChartJson] = useState<any>([]);
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    if (props.data !== radarChartJson) {
      setRadarChartJson(props.data);
      setIsLoading(false); // Data is loaded, set loading to false
    }
    
  }, [props.data]);

   useEffect(() => {
    // Ensure this useEffect is directly inside the component and not nested in any other function or callback
    console.log('moduleHeight:', props.moduleHeight);
    console.log('moduleWidth:', props.moduleWidth);
    console.log('xaxis:', props.xaxis);
    console.log('yaxis:', props.yaxis);
  }, [props.moduleHeight, props.moduleWidth, props.xaxis, props.yaxis]);

  if (isLoading) {
    return <div>Loading...</div>;
  }

  

  const containerHeight =   '100%';
  const containerWidth =  '100%';

  return (
    
    <div
      key="PIE01"
      style={{
        // backgroundColor: "rgb(242,245,253)",
        // height: "300px",
        // width: "95%",
        // overflowX: "auto",
        // // overflow: "scroll",
        // justifyContent: "center",
        // alignItems: "center",
        // display: "flex",
        // top: 0,
        position: 'relative', // This ensures the absolute positioning is relative to this container
        // height: '100%', // Fill the parent
        // width: '100%', // Fill the parent
        height: containerHeight, // Adjusted height using moduleHeight
        width: containerWidth, 
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        overflow: 'hidden',
        
      }}
    >
      {props?.pageError !== "" ? (
        <div
          key="TAB101"
          style={{
            display: "flex",
            height: "300px",
            width: "100%",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <img
            style={{
              height: "auto",
              width: "300px",
            }}
            src={Images.no_data_img}
          />
        </div>
      ) : (
        <div
          key="RADAR108"
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            // width:
            //   radarChartJson?.data?.length >= 10
            //     ? `${50 * radarChartJson?.data?.length}px`
            //     : "100%",
            // height: "300px", //40vh
            width: "100%", // Adjusted to 100%
            height: "100%",
          }}
        >
          {props.name}
          <ResponsiveContainer width="100%" height="100%">
            <RadarChart cx="50%" cy="50%" outerRadius="80%" data={radarChartJson.data}>
              <PolarGrid stroke="#777" /> {/* Slightly lighter grid lines */}
              <PolarAngleAxis
                dataKey={radarChartJson.datakey_X}
                stroke="#777"
                tick={{ dy: 4, fontSize: 10 }} // Reduce font size for tick labels
              />
              <PolarRadiusAxis
                axisLine={{ stroke: '#777' }}
                tick={{ fill: '#555', fontSize: 10 }} // Reduce font size for tick labels
              />
              <Radar
                name={radarChartJson.datakey_Y}
                dataKey={radarChartJson.datakey_Y}
                stroke="#8884d8"
                fill="#8884d8"
                fillOpacity={0.8}
                // Custom hover effect
                onMouseOver={(event) => {
                  const target = event.target as SVGElement; // Type cast to SVGElement
                  target.setAttribute('fill-opacity', '0.9');
                }}
                onMouseOut={(event) => {
                  const target = event.target as SVGElement; // Type cast to SVGElement
                  target.setAttribute('fill-opacity', '0.8');
                }}
              />
              {/* Custom Tooltip */}
              {/* <Tooltip content={CustomTooltip} /> */}
              <Tooltip content={CustomTooltip} wrapperStyle={{ fontSize: 10 }} /> {/* Reduce font size for tooltip */}

            </RadarChart>
          </ResponsiveContainer>
        </div>
      )}
    </div>
  );
})

export default RadarChartForDashboard;