import {  Images } from "../../constants";
const Avatar = () => {
  return (
    <div>
      <img
        src={Images.Wingsbi_Logo}
        alt="Logo"
        style={{
          width: "60px",
          height: "50px",
          display: "grid",
          placeItems: "center",
          borderRadius: "100%",
        //   boxShadow: "0px 0px 5px rgba(0, 0, 0, 0.5)",
        }}
      />
    </div>
  )
}

export default Avatar;
